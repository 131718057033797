import React from 'react'
import { ContemberImage } from '../../utils/contember/types'
import { Image } from '../Image'
import s from './BonusProduct.module.sass'

export const BonusProduct = React.memo(function BonusProduct(props: {
	image?: ContemberImage
	title: React.ReactNode
	subtitle: React.ReactNode
}) {
	return (
		<div className={s.Container}>
			{props.image && (
				<div className={s.Image}>
					<Image
						className={s.ImageImg}
						src={props.image.url}
						alt={props.image.alt ?? 'Bonus'}
						title={props.image.title}
						width={80}
						height={80}
					/>
					<span className={s.Indicator}>1</span>
				</div>
			)}
			<div className={s.Description}>
				<span>{props.title}</span> <strong>{props.subtitle}</strong>
			</div>
		</div>
	)
})
