export type GAProduct = {
	name: string
	id: string | number
	price: string
	brand?: string
	category?: string
	variant?: string
}

export type GALineItem = GAProduct & { quantity: number }

export const googleAnalytics = (...args: unknown[]) => {
	if (process.browser) {
		if (window.gtag) {
			console.trace('gtag', args)
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			window.gtag.apply(undefined, args as any)
		}
	}
}

export function sendToDataLayer(arg: unknown) {
	if (process.browser) {
		if ('dataLayer' in window && window.dataLayer) {
			console.log('sendToDataLayer', arg)
			window.dataLayer.push(arg)
		}
	}
}

export const pixelAnalytics: facebook.Pixel.Event = (...args: unknown[]) => {
	if (process.browser) {
		if ('fbq' in window) {
			console.trace('fbq', args)
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			window.fbq.apply(undefined, args as any)
		}
	}
}

export type ProductTrackingInfo = {
	currency: string
	sku: string
	title: string
	price: number
	variantName: string
	iconName?: string | undefined
	category: string
	quantity: number
}

export function trackAddToCart(activityLocation: string, data: ProductTrackingInfo) {
	pixelAnalytics('track', 'AddToCart', { content_ids: [data.sku], content_name: data.title })
	sendToDataLayer({
		event: 'addToCart',
		ecommerce: {
			currencyCode: data.currency.toUpperCase(),
			add: {
				actionField: { list: activityLocation },
				products: [
					{
						name: data.iconName ? `${data.category}-${data.iconName}` : data.category,
						id: data.iconName ? `${data.sku}-${data.iconName}` : data.sku,
						price: String(data.price),
						brand: 'clotino',
						category: data.category,
						motive: data.iconName,
						variant: data.variantName,
						quantity: data.quantity,
					},
				],
			},
		},
	})
}
export function trackRemoveFromCart(activityLocation: string, data: ProductTrackingInfo) {
	sendToDataLayer({
		event: 'removeFromCart',
		ecommerce: {
			currencyCode: data.currency.toUpperCase(),
			remove: {
				actionField: { list: activityLocation },
				products: [
					{
						name: data.iconName ? `${data.category}-${data.iconName}` : data.category,
						id: data.iconName ? `${data.sku}-${data.iconName}` : data.sku,
						price: String(data.price),
						brand: 'clotino',
						category: data.category,
						motive: data.iconName,
						variant: data.variantName,
						quantity: data.quantity,
					},
				],
			},
		},
	})
}

export function trackShowCart(products: ProductTrackingInfo[]) {
	sendToDataLayer({
		event: 'checkout',
		ecommerce: {
			currencyCode: products[0]?.currency.toUpperCase(),
			checkout: {
				actionField: { step: 1 },
				products: products.map((p) => ({
					name: p.iconName ? `${p.category}-${p.iconName}` : p.category,
					id: p.iconName ? `${p.sku}-${p.iconName}` : p.sku,
					price: String(p.price),
					brand: 'clotino',
					category: p.category,
					motive: p.iconName,
					variant: p.variantName,
					quantity: p.quantity,
				})),
			},
		},
	})
}

export function trackInitiateCheckout() {
	pixelAnalytics('track', 'InitiateCheckout')
}

export function trackChangeIcon() {
	throw new Error('Not implemented')
}

export function trackChangeLabel() {
	throw new Error('Not implemented')
}

export function trackExpressCheckout() {
	throw new Error('Not implemented')
}
