import React from 'react'
import { useMutation } from 'react-query'
import { detectFreeShipping } from '../clotino/detectFreeShipping'
import { useActiveStorefront } from '../hooks/useActiveStorefront'
import { CustomStorefrontCart, useCustomShopifyCart } from '../hooks/useCustomShopifyCart'
import { useDefaultShipping } from '../hooks/useDefaultShipping'
import {
	ProductTrackingInfo,
	trackAddToCart,
	trackRemoveFromCart,
} from '../utils/analytics/analytics'
import { unifiedParseCustomization } from '../utils/customization/customization'
import { queryClient } from '../utils/react-query'
import { Cart } from './Cart'
import { Button } from './clotino/Button'
import { useCloseCartModal } from './clotino/CartModal'
import { Part } from './clotino/Part'
import s from './ConnectedCart.module.sass'
import { Tr } from './Tr'

export const CartContext = React.createContext<null | CustomStorefrontCart>(null)

export type OptimisticValue<V = number> = { dirty: boolean; value: V; confirmed: V | null }

export type OptimisticLineItem = {
	id: string
	title: string
	quantity: OptimisticValue
	pricing: CustomStorefrontCart['lineItems']['edges'][number]['node']['pricing']
	variant: CustomStorefrontCart['lineItems']['edges'][number]['node']['variant']
	customAttributes: CustomStorefrontCart['lineItems']['edges'][number]['node']['customAttributes']
	productType: string | null
	dirty: boolean
}

const increment = (x: number) => x + 1

function useOptimisticCart() {
	const [patchQuantity, setPatchQuantity] = React.useState<Record<string, number>>({})
	const cart = useCustomShopifyCart()
	const defaultShipping = useDefaultShipping()
	const storefront = useActiveStorefront()

	const checkoutId = cart.data?.id

	const count = cart?.data?.lineItems.edges.length ?? 0

	const [patchKey, setPatchKey] = React.useState(1)

	React.useEffect(() => {
		setPatchKey(increment)
	}, [count])

	const mutation = useMutation(async (data: { id: string; quantity: number }) => {
		if (checkoutId) {
			if (data.quantity <= 0) {
				storefront?.checkout.removeLineItems(checkoutId, [data.id]).then(() => {
					queryClient.invalidateQueries(['cart'])
				})
			} else {
				storefront?.checkout
					.updateLineItems(checkoutId, [{ id: data.id, quantity: data.quantity }])
					.then(() => {
						queryClient.invalidateQueries(['cart'])
					})
			}
		}
	})

	const cartData = cart.data
	const shippingData = defaultShipping.data

	const handleChangeQuantity = React.useCallback(
		(data: { diff: number; lineItem: OptimisticLineItem }) => {
			const motive = unifiedParseCustomization(data.lineItem.customAttributes ?? [])

			const payload: ProductTrackingInfo = {
				quantity: Math.abs(data.diff),

				sku: data.lineItem.variant?.sku ?? '',
				title: data.lineItem.variant?.title ?? '',
				currency: (data.lineItem.pricing.currencyCode ?? 'czk').toUpperCase(),
				category: data.lineItem.variant?.product.handle ?? '',
				variantName: data.lineItem.variant?.title ?? '',
				iconName: motive?.icon ?? '',
				price: Number(data.lineItem.pricing.singleAmount),
			}

			if (data.diff > 0) {
				trackAddToCart('unknown', payload)
			} else if (data.diff < 0) {
				trackRemoveFromCart('unknown', payload)
			}
		},
		[]
	)

	const setQuantity = React.useCallback(
		(data: {
			id: string
			quantity: number
			oldQuantity: number
			lineItem: OptimisticLineItem
		}) => {
			const { id, quantity, oldQuantity } = data
			setPatchQuantity((old) => {
				handleChangeQuantity({ diff: quantity - oldQuantity, lineItem: data.lineItem })
				return { ...old, [patchKey + id]: quantity }
			})
			// TODO: throttle would be nice
			mutation.mutate({ id, quantity })
		},
		[handleChangeQuantity, mutation, patchKey]
	)

	return React.useMemo(() => {
		if (!cartData || !shippingData) {
			return null
		}

		let dirty = false

		const subtotalCents = cartData.totalPriceV2.amount * 100
		const freeShipping = detectFreeShipping(
			cartData.totalPriceV2.currencyCode.toLowerCase(),
			Number(cartData.totalPriceV2.amount)
		)
		const shippingCents = freeShipping ? 0 : shippingData.amount

		const lineItems: OptimisticLineItem[] = []
		const lineItemsRefs: Record<string, OptimisticLineItem> = {}

		cartData.lineItems.edges.forEach(({ node }) => {
			lineItemsRefs[patchKey + node.id] = {
				id: node.id,
				dirty: false,
				productType: node.variant?.product?.productType ?? null,
				quantity: { dirty: false, value: node.quantity, confirmed: node.quantity },
				title: node.title,
				pricing: node.pricing,
				variant: node.variant,
				customAttributes: node.customAttributes,
			}
			lineItems.push(lineItemsRefs[patchKey + node.id])
		})

		const patchIds = Object.keys(patchQuantity)

		patchIds.forEach((id: keyof typeof patchQuantity) => {
			if (lineItemsRefs[id] && lineItemsRefs[id].quantity.value !== patchQuantity[id]) {
				lineItemsRefs[id].quantity.value = patchQuantity[id]
				lineItemsRefs[id].quantity.dirty = true
				dirty = true
			}
		})

		return {
			patchQuantity,
			id: cartData.id,
			currencyCode: cartData.currencyCode,
			webUrl: cartData.webUrl,
			subtotalCents,
			shippingCents,
			totalCents: subtotalCents + shippingCents,
			lineItems,
			dirty,
			setQuantity,
		}
	}, [cartData, shippingData, patchQuantity, setQuantity, patchKey])
}

export type OptimisticCart = NonNullable<ReturnType<typeof useOptimisticCart>>

export function ConnectedCart() {
	const cart = useCustomShopifyCart()

	const closeCartModal = useCloseCartModal()

	const optimistic = useOptimisticCart()

	const cartRef = React.useRef(optimistic)

	cartRef.current = optimistic

	const isEmpty =
		cart.isSuccess &&
		(!cart.data || cart.data.completedAt || cart.data.lineItems.edges.length === 0)

	return (
		<CartContext.Provider value={cart.data ?? null}>
			{cart.data &&
			!cart.data.completedAt &&
			!!cart.data.lineItems.edges.length &&
			optimistic ? (
				<Cart cart={optimistic} />
			) : (
				isEmpty && (
					<Part textCenter>
						{cart.isSuccess && (
							<div className={s.Empty}>
								<h1 className={s.Title}>
									<Tr ns="cart" t="cart_is_empty" />
								</h1>
								<Button onClick={closeCartModal}>
									<Tr ns="cart" t="close_cart" />
								</Button>
							</div>
						)}
					</Part>
				)
			)}
		</CartContext.Provider>
	)
}
