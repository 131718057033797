import React from 'react'
import { triggerSyntheticInputChange } from '../utils/triggerSyntheticInputChange'
import s from './QuantityInput.module.sass'

const DEFAULT_VALUE = '0'

// @TODO: React.forwardRef - https://itnext.io/reusing-the-ref-from-forwardref-with-react-hooks-4ce9df693dd
export const QuantityInput: React.FunctionComponent<
	Omit<
		React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
		'className' | 'type'
	>
> = ({ value, onChange, ...props }) => {
	const ref = React.useRef<HTMLInputElement>(null)
	const [innerValue, setInnerValue] = React.useState(value || DEFAULT_VALUE)
	const numberValue = Number(innerValue) || 0

	React.useEffect(() => {
		setInnerValue(value || DEFAULT_VALUE)
	}, [value])

	const updateValue = (newValue: number) => {
		if (ref.current) {
			triggerSyntheticInputChange(ref.current, newValue.toString())
		}
	}

	return (
		<div className={s.QuantityInput}>
			<button
				className={s.Substract}
				type="button"
				disabled={props.disabled}
				onClick={() => updateValue(Math.max(0, numberValue - 1))}>
				-
			</button>
			<input
				ref={ref}
				className={s.Input}
				type="number"
				value={innerValue}
				onChange={(event) => {
					if (onChange) {
						onChange(event)
					} else {
						setInnerValue(event.target.value)
					}
				}}
				{...props}
			/>
			<button
				className={s.Add}
				type="button"
				disabled={props.disabled}
				onClick={() => updateValue(numberValue + 1)}>
				+
			</button>
		</div>
	)
}
