import { CustomStorefrontClient } from './customStorefront'

export async function checkoutQuery(storefront: CustomStorefrontClient, checkoutId: string) {
	return storefront
		.query({
			node: [
				{ id: checkoutId },
				{
					id: true,
					__typename: true,
					'...on Checkout': {
						id: true,
						// TODO: slevy
						// shippingDiscountAllocations: { allocatedAmount: { amount: true } },
						// discountApplications: [
						// 	{ first: 5 },
						// 	{ edges: { node: { value: { amount: true } } } },
						// ],
						lineItems: [
							{
								first: 250,
							},
							{
								pageInfo: {
									hasNextPage: true,
									hasPreviousPage: true,
								},
								edges: {
									cursor: true,
									node: {
										id: true,
										title: true,
										discountAllocations: {
											allocatedAmount: { amount: true, currencyCode: true },
										},
										variant: {
											id: true,
											title: true,
											image: [
												{},
												{
													altText: true,
													transformedSrc: [
														{
															maxHeight: 200,
															maxWidth: 200,
														},
														true,
													],
												},
											],
											priceV2: {
												amount: true,
												currencyCode: true,
											},
											availableForSale: true,
											sku: true,
											compareAtPriceV2: {
												amount: true,
												currencyCode: true,
											},
											product: {
												id: true,
												productType: true,
												handle: true,
												variants: [
													{ first: 2 },
													{ edges: { cursor: true } },
												],
											},
										},
										quantity: true,
										customAttributes: {
											key: true,
											value: true,
										},
									},
								},
							},
						],
						ready: true,
						requiresShipping: true,
						paymentDueV2: {
							amount: true,
							currencyCode: true,
						},
						webUrl: true,
						orderStatusUrl: true,
						taxExempt: true,
						taxesIncluded: true,
						currencyCode: true,
						totalTaxV2: {
							amount: true,
							currencyCode: true,
						},
						lineItemsSubtotalPrice: {
							amount: true,
							currencyCode: true,
						},
						subtotalPriceV2: {
							amount: true,
							currencyCode: true,
						},
						totalPriceV2: {
							amount: true,
							currencyCode: true,
						},
						completedAt: true,
						createdAt: true,
						updatedAt: true,
					},
				},
			],
		})
		.then((result) => {
			if (result.node && result.node.id) {
				return {
					...result.node,
					lineItems: processLineItemsPricing(fixLineItems(result.node?.lineItems)),
				}
			}
			return null
		})
}

function fixLineItems(lineItems: unknown) {
	return lineItems as {
		pageInfo: {
			hasNextPage: boolean
			hasPreviousPage: boolean
		}
		edges: {
			cursor: string
			node: {
				id: string
				title: string
				discountAllocations?: {
					allocatedAmount?: { amount: string; currencyCode: string }
				}[]
				variant?: {
					id: string
					title: string
					image?: {
						altText: string
						transformedSrc: string
					}
					priceV2: {
						amount: string
						currencyCode: string
					}
					availableForSale: boolean
					sku: string
					compareAtPriceV2?: {
						amount: string
						currencyCode: string
					}
					product: {
						id: string
						productType: string
						handle: string
					}
				}
				quantity: number
				customAttributes: {
					key: string
					value: string
				}[]
			}
		}[]
	}
}

function processLineItemsPricing(lineItems: ReturnType<typeof fixLineItems>) {
	return {
		...lineItems,
		edges: lineItems.edges.map(({ node }) => {
			return { node: { ...node, pricing: countLineItemPricing(node) } }
		}),
	}
}

function countLineItemPricing(node: ReturnType<typeof fixLineItems>['edges'][number]['node']) {
	const singleAmount = Number(node.variant?.priceV2.amount ?? 0)
	const singleCompareAmount = node.variant?.compareAtPriceV2?.amount
		? Number(node.variant.compareAtPriceV2.amount)
		: null
	const compareAmount = singleCompareAmount ? node.quantity * singleCompareAmount : null
	const beforeDiscount = node.quantity * singleAmount
	const discount =
		node.discountAllocations?.reduce(
			(sum, discount) => sum + Number(discount.allocatedAmount?.amount ?? 0),
			0
		) ?? 0
	const amount = beforeDiscount - discount
	return {
		currencyCode: node.variant?.priceV2.currencyCode,
		quantity: node.quantity,
		singleAmount,
		beforeDiscount,
		discount,
		amount,
		compareAmount,
	}
}
