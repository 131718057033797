import dynamic from 'next/dynamic'
import React from 'react'
import { ConnectedCart } from '../ConnectedCart'
import s from './CartModal.module.sass'

const DynamicModal = dynamic<ReactModal.Props>(
	() =>
		import('react-modal').then((module) => {
			module.default.setAppElement('#__next')
			return module.default
		}),
	{
		ssr: false,
	}
)

const CartModalContext = React.createContext({
	open: () => {
		// Do nothing
	},
	close: () => {
		// Do nothing
	},
})

const customStyles: ReactModal.Styles = {
	content: {},
	overlay: {},
}

export const useOpenCartModal = () => React.useContext(CartModalContext).open
export const useCloseCartModal = () => React.useContext(CartModalContext).close

function CloseCartButton(props: { onClick: () => void }) {
	return (
		<span className={s.CloseCartButton}>
			<img src="/clotino/assets/close-cross.svg" alt="close icon" onClick={props.onClick} />
		</span>
	)
}

export const CartModal: React.FunctionComponent = ({ children }) => {
	const [isOpen, setIsOpen] = React.useState(false)
	const open = React.useCallback(() => setIsOpen(true), [])
	const close = React.useCallback(() => setIsOpen(false), [])

	return (
		<CartModalContext.Provider value={{ open, close }}>
			<div className={s.NormalContent}>{children}</div>
			<DynamicModal
				contentLabel=""
				isOpen={isOpen}
				onRequestClose={close}
				style={customStyles}
				className={s.Modal}
				closeTimeoutMS={300}
				overlayClassName={s.Overlay}>
				<CloseCartButton onClick={close} />
				<ConnectedCart />
			</DynamicModal>
		</CartModalContext.Provider>
	)
}
