import cn from 'clsx'
import React from 'react'
import { useAllImagesLoadedInside } from '../hooks/useAllImagesLoadedInside'
import { ImageResult } from '../loaders/loadShop'
import { AspectRatio } from './AspectRatio'
import { ConnectedMotive } from './clotino/Motive'
import { Image } from './Image'
import s from './ImageWithOverlay.module.sass'

export type Layer = {
	name?: string
	className?: string
	content: React.ReactNode
	style?: React.CSSProperties
	big?: boolean
	burn?: boolean
}

export type OverlayPreset = {
	layers?: Layer[]
}

// translate(50%, 50%) scale(0.25) rotateZ(0deg) rotateX(0deg) rotateY(0deg)
export const OverlayPresets = {
	sp_foodbox_1: {
		layers: [
			{
				content: <ConnectedMotive type="sticker" />,
				style: {
					opacity: 0.8,
					transform:
						'translate(41%, 54%) scale(0.125) rotateZ(-45.9deg) rotateX(0deg) rotateY(0deg)',
				},
			},
		],
	},
	sp_bottle_1: {
		layers: [
			{
				content: <ConnectedMotive type="sticker" />,
				style: {
					opacity: 0.8,
					transform:
						'translate(61.3%, 65.8%) scale(0.105) rotateZ(13deg) rotateX(0deg) rotateY(0deg)',
				},
			},
		],
	},
	sp_backpack_1: {
		layers: [
			{
				content: <ConnectedMotive type="sticker" />,
				style: {
					opacity: 0.8,
					transform:
						'translate(72.6%, 54%) scale(0.088) rotateZ(-3.2deg) rotateX(0deg) rotateY(0deg)',
				},
			},
		],
	},
	sp_ink_1: {
		layers: [
			{
				content: <ConnectedMotive type="stamp" />,
				style: {
					opacity: 0.8,
					transform:
						'translate(19.7%, 43.5%) scale(0.25) rotateZ(-29deg) rotateX(0deg) rotateY(0deg)',
				},
			},
		],
	},
	sp_tshirt_2: {
		layers: [
			{
				content: <ConnectedMotive type="stamp" />,
				burn: true,
				style: {
					opacity: 0.7,
					transform:
						'translate(43%, 42.2%) scale(0.15) rotateZ(3.5deg) rotateX(0deg) rotateY(0deg)',
				},
			},
			{
				content: <ConnectedMotive type="stamp" />,
				style: {
					opacity: 0.8,
					transform:
						'translate(82.9%, 62.6%) scale(0.13) rotateZ(49.5deg) rotateX(0deg) rotateY(0deg)',
				},
			},
		],
	},
	sp_tshirt_1: {
		layers: [
			{
				content: <ConnectedMotive type="stamp" />,
				burn: true,
				style: {
					opacity: 0.7,
					transform:
						'translate(49%, 56.5%) scale(0.25) rotateZ(-6.6deg) rotateX(0deg) rotateY(0deg)',
				},
			},
		],
	},
	sp_stampBlock_1: {
		layers: [
			{
				content: <ConnectedMotive type="stamp" />,
				burn: true,
				style: {
					opacity: 0.6,
					transform:
						'translate(51.5%, 41.5%) scale(0.35) rotateX(31deg) rotateY(4deg) rotateZ(342deg) skewX(6deg)',
				},
			},
		],
	},
	sp_stamp_2: {
		layers: [
			{
				content: <ConnectedMotive type="stamp" />,
				style: {
					opacity: 0.8,
					transform:
						'translate(19.7%, 43.5%) scale(0.25) rotateZ(-29deg) rotateX(0deg) rotateY(0deg)',
				},
			},
		],
	},
	sp_stamp_1: {
		layers: [
			{
				content: <ConnectedMotive type="stamp" />,
				style: {
					opacity: 0.8,
					transform:
						'translate(65.3%, 50%) scale(0.328) rotateZ(357.9deg) rotateX(-5deg) rotateY(21.3deg)',
				},
			},
		],
	},
	sp_stamp_3: {
		layers: [
			{
				content: <ConnectedMotive type="stamp" />,
				style: {
					opacity: 0.8,
					transform:
						'translate(55.3%, 42%) scale(0.36) rotateZ(357.9deg) rotateX(-5deg) rotateY(21.3deg)',
				},
			},
		],
	},
	// old
	stamp_stamp1: {
		layers: [
			{
				content: <ConnectedMotive type="stamp" />,
				style: {
					opacity: 0.8,
					transform:
						'translate(53.5%, 42%) scale(0.35) rotateZ(2deg) rotateX(-18deg) rotateY(26deg)',
				},
			},
		],
	},
	stamp_tshirt1: {
		layers: [
			{
				content: <ConnectedMotive type="stamp" />,
				burn: true,
				style: {
					opacity: 0.7,
					transform: 'translate(57.5%, 37%) scale(0.25) rotateZ(13deg)',
				},
			},
		],
	},
	stamp_backpack1: {
		layers: [
			{
				content: <ConnectedMotive type="stamp" />,
				style: {
					opacity: 0.8,
					transform: 'translate(50.5%, 20%) scale(0.15)',
				},
			},
		],
	},
	stamp_diaries1: {
		layers: [
			{
				content: <ConnectedMotive type="stamp" />,
				style: {
					opacity: 0.8,
					transform: 'translate(46.5%, 64%) scale(0.12) rotateZ(25deg)',
				},
			},
		],
	},
	sticker_stickerpack1: {
		layers: [
			{
				big: true,
				content: <ConnectedMotive type="sticker" dimensions decorations />,
				style: {
					transform: 'translate(66%, 80%) scale(0.35)',
				},
			},
			{
				big: false,
				content: <ConnectedMotive type="sticker" dimensions decorations />,
				style: {
					transform: 'translate(55%, 78%) scale(0.6)',
				},
			},
		],
	},
	sticker_diaries2: {
		layers: [
			{
				content: <ConnectedMotive type="sticker" decorations />,
				style: {
					opacity: 0.8,
					transform: 'translate(46.5%, 64%) scale(0.12) rotateZ(25deg)',
				},
			},
		],
	},
}

export const ImageWithOverlayBigContext = React.createContext(false)

export const ImageWithOverlay = React.memo(function ImageWithOverlay(props: {
	big?: boolean
	ratio?: number
	image?: Partial<ImageResult>
	layers?: Layer[]
	preset?: OverlayPreset
	refresh?: number
}) {
	const big = React.useContext(ImageWithOverlayBigContext) || (props.big ?? false)
	const ratio = props.ratio ?? 1

	const allLayers = props.layers ?? props.preset?.layers

	const layers = React.useMemo(() => {
		if (allLayers) {
			return allLayers.filter((layer) => {
				if (typeof layer.big === 'boolean') {
					return layer.big === big
				}
				return true
			})
		}
		return allLayers ?? []
	}, [allLayers, big])

	const [ref, layersLoaded] = useAllImagesLoadedInside()
	const [imageLoaded, setImageLoded] = React.useState(false)

	const loaded = (layersLoaded || !layers || layers.length === 0) && imageLoaded

	const renderedLayers = React.useMemo(() => {
		return layers.map((layer, l) => (
			<div
				key={l}
				className={cn(
					s.Layer,
					layer.name && s[layer.name],
					layer.className && s[layer.className]
				)}>
				<div className={cn(s.Override, 'overlayOverride')} style={layer.style}>
					{layer.content}
				</div>
			</div>
		))
	}, [layers])

	const burnedLayers = React.useMemo(() => {
		return layers
			.filter((layer) => layer.burn)
			.map((layer, l) => (
				<div
					key={l}
					className={cn(
						s.Layer,
						layer.name && s[layer.name],
						layer.className && s[layer.className]
					)}>
					<div
						className={cn(s.Override, 'overlayOverride')}
						style={{ ...layer.style, opacity: 1 }}>
						{layer.content}
					</div>
				</div>
			))
	}, [layers])

	return (
		<AspectRatio ratio={ratio}>
			<div className={cn(s.ImageWithOverlay, loaded ? s.loaded : s.loading)}>
				{props.image?.url && (
					<div className={s.Image}>
						<Image
							title={props.image.title}
							alt={props.image.alt}
							src={props.image.url}
							layout="fill"
							objectFit="cover"
							onLoad={() => setImageLoded(true)}
						/>
					</div>
				)}
				{layers && !!layers.length && (
					<>
						{burnedLayers.length > 0 && (
							<div
								className={cn(s.Overlay, s['view-burn'])}
								key={`overlay-burn-${props.refresh}`}>
								{burnedLayers}
							</div>
						)}
						<div className={s.Overlay} key={`overlay-${props.refresh}`} ref={ref}>
							{renderedLayers}
						</div>
					</>
				)}
			</div>
		</AspectRatio>
	)
})
