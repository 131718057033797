import getConfig from 'next/config'
import React from 'react'
import { Thunder } from '../../../generated/storefront-czk'
import { fromThunder } from '../../utils/zeus/zeusFetch'
import { ClotinoLocale } from '../clotino/Locales'
import { CLOTINO_CURRENCY } from '../locales/locales'

export function createCustomStorefrontClient(currency: CLOTINO_CURRENCY, locale: ClotinoLocale) {
	const config = getConfig().publicRuntimeConfig
	const service = config.shopify[currency]
	if (!service) {
		throw new Error(`Missing config for Shopify ${currency}.`)
	}
	const thunder = fromThunder(Thunder, `https://${service.domain}/api/2021-04/graphql?custom`, {
		'x-shopify-storefront-access-token': service.storefrontAccessToken,
		'Accept-Language': `${locale}`,
	})
	return { ...thunder, currency }
}

const shopifyMemo: Partial<
	Record<CLOTINO_CURRENCY, ReturnType<typeof createCustomStorefrontClient>>
> = {}

export function getCustomStorefrontClient(currency: CLOTINO_CURRENCY, locale: ClotinoLocale) {
	if (!shopifyMemo[currency]) {
		shopifyMemo[currency] = createCustomStorefrontClient(currency, locale)
	}
	return shopifyMemo[currency]
}

export type CustomStorefrontClient = NonNullable<ReturnType<typeof getCustomStorefrontClient>>

export function useCustomStorefrontClient(
	currency: CLOTINO_CURRENCY | null,
	locale: ClotinoLocale | null
) {
	return React.useMemo(() => {
		return currency && locale ? createCustomStorefrontClient(currency, locale) : null
	}, [currency, locale])
}
