import { PaymentRequestButtonElement, useStripe } from '@stripe/react-stripe-js'
import {
	PaymentRequest,
	PaymentRequestOptions,
	PaymentRequestPaymentMethodEvent,
	PaymentRequestShippingOption,
	PaymentRequestUpdateDetails,
	StripePaymentRequestButtonElementOptions,
} from '@stripe/stripe-js'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useClotino } from '../../hooks/useClotino'
import { StorefrontContext } from '../../utils/shopify/storefront'
import { useCloseCartModal } from '../clotino/CartModal'
import { useTranslation } from '../Tr'
import s from './ExpressCheckout.module.sass'

const buttonStyle: StripePaymentRequestButtonElementOptions['style'] = {
	paymentRequestButton: {
		type: 'buy',
		// One of 'default', 'book', 'buy', or 'donate'
		// Defaults to 'default'

		theme: 'dark',
		// One of 'dark', 'light', or 'light-outline'
		// Defaults to 'dark'

		height: '50px',
	},
}

export async function fetchShippingOptions(
	locale: string,
	store: string,
	amountCents: number,
	country: string
) {
	return await fetch(
		`/api/shipping-options?store=${store}&country=${
			country ?? ''
		}&locale=${locale}&amountCents=${amountCents ?? 0}`
	)
		.then((res) => res.json())
		.then((data: { shippingOptions: { id: number; name: string; price: string }[] }) => {
			return data.shippingOptions.map((option) => {
				return {
					amount: parseFloat(option.price) * 100,
					label: option.name,
					// detail: option.name,
					id: String(option.id),
				} as PaymentRequestShippingOption
			})
		})
}

const CheckoutForm = (props: {
	payment: PaymentRequestOptions
	onAvailability?: (available: boolean) => void
	requirePaymentIntent?: (paymentMethod: PaymentRequestPaymentMethodEvent) => Promise<string>
}) => {
	const storefrontContext = React.useContext(StorefrontContext)
	const stripe = useStripe()
	const { locale } = useClotino()
	const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(null)

	const something_went_wrong = useTranslation('cart', 'apple_pay:something_went_wrong')
	const thank_you = useTranslation('cart', 'apple_pay:thank_you')

	const onAvailability = React.useRef(props.onAvailability)
	onAvailability.current = props.onAvailability

	const { payment, requirePaymentIntent } = props

	const totalLabel = useTranslation('cart', 'total')

	const composePayment = React.useCallback(
		(payment: PaymentRequestOptions, shipping?: PaymentRequestShippingOption) => {
			console.log('composePayment', payment)
			const result: PaymentRequestUpdateDetails = {
				total: {
					...payment.total,
					label: totalLabel ?? payment.total.label,
					amount: payment.total.amount,
					pending: !shipping,
				},
				displayItems: payment.displayItems,
			}
			if (shipping) {
				if (result.total) {
					result.total.amount += shipping.amount
				}
				result.displayItems = [...(payment.displayItems ?? []), shipping]
				result.status = 'success'
			}
			if (result.total) {
				result.total.amount = Number(result.total.amount.toFixed(2))
			}
			console.log(result)
			return result
		},
		[totalLabel]
	)

	console.log(payment)

	const closeCartModal = useCloseCartModal()

	useEffect(() => {
		if (stripe) {
			const pr = stripe.paymentRequest(payment)

			pr.on('shippingoptionchange', (e) => {
				e.updateWith(composePayment(payment, e.shippingOption))
			})

			pr.on('paymentmethod', async (ev) => {
				console.log('paymentmethod', ev)

				if (!requirePaymentIntent || window.location.hash === '#fail') {
					console.log(ev.complete('success'))
					return
				}

				const clientSecret = await requirePaymentIntent(ev)

				const confirmResponse = await stripe.confirmCardPayment(
					clientSecret,
					{ payment_method: ev.paymentMethod.id },
					{ handleActions: false }
				)

				console.log({ confirmResponse })
				const { paymentIntent, error: confirmError } = confirmResponse

				if (confirmError) {
					console.log('before fail')
					console.log(ev.complete('fail'))
					console.log('fail')
				} else {
					console.log('before success')
					console.log(ev.complete('success'), { confirmResponse, confirmError })

					console.log('success')
					if (paymentIntent && paymentIntent?.status === 'requires_action') {
						console.log(-1)
						try {
							console.log(1)
							const confirmResponse2 = await stripe.confirmCardPayment(clientSecret)
							console.log(2)
							console.log({ confirmResponse2 })
							console.log(3)
							const { error } = confirmResponse2
							if (error) {
								console.error(error)
								console.log('alert Something went wrong')
								setTimeout(() => {
									alert(something_went_wrong)
								}, 1000)
							} else {
								console.log('alert Thanks')
								setTimeout(() => {
									closeCartModal()
									storefrontContext?.cancelCheckout()
								}, 1000)
							}
						} catch (e) {
							console.error(e)
							setTimeout(() => {
								alert(something_went_wrong)
							}, 1000)
						}
					} else {
						console.log(-2)
						setTimeout(() => {
							closeCartModal()
							storefrontContext?.cancelCheckout()
						}, 1000)
					}
				}
			})

			//pr.on('cancel', () => console.log('cancel'))

			//pr.on('source', (e) => console.log('source', e))

			// pr.on('token', (e) => console.log('token', e))

			pr.on('shippingaddresschange', async (ev) => {
				console.log(ev)
				const shippingOptions = await fetchShippingOptions(
					locale,
					payment.currency.toLowerCase(),
					payment.total.amount,
					ev.shippingAddress.country ?? ''
				)

				if (shippingOptions.length) {
					ev.updateWith({
						...composePayment(payment, shippingOptions[0]),
						shippingOptions,
					})
				} else {
					ev.updateWith({
						status: 'invalid_shipping_address',
					})
				}
			})

			pr.canMakePayment().then((result) => {
				if (onAvailability.current) {
					onAvailability.current(!!result)
				}
				if (result) {
					setPaymentRequest(pr)
				}
			})
		}
	}, [
		closeCartModal,
		composePayment,
		locale,
		payment,
		requirePaymentIntent,
		something_went_wrong,
		storefrontContext,
		stripe,
		thank_you,
	])

	if (paymentRequest) {
		return (
			<PaymentRequestButtonElement
				key={JSON.stringify({ payment, locale })}
				options={{ paymentRequest, style: buttonStyle }}
			/>
		)
	}

	return <></>
}

async function fetchPaymentInfo(
	locale: string,
	currency: string,
	checkoutId: string | number,
	paymentMethod?: PaymentRequestPaymentMethodEvent
): Promise<{
	paymentRequest: PaymentRequestOptions
	paymentIntent?: { client_secret: string }
	clotinoOrderStatusKey: string
}> {
	return fetch('/api/stripe-payment-intent', {
		method: 'POST',
		body: JSON.stringify({
			currency,
			checkoutId,
			paymentMethod,
			locale,
		}),
		headers: { 'Content-Type': 'application/json;charset=utf-8' },
	}).then((res) => res.json())
}

export function ExpressCheckout(props: {
	onAvailability?: (available: boolean) => void
	cart: { id: string }
}) {
	const router = useRouter()
	const { store, locale } = useClotino()
	const [orderStatusKey, setOrderStatusKey] = React.useState('')

	React.useEffect(() => {
		if (orderStatusKey) {
			console.log('redirecting to order status page')
			router.push(`/order/${orderStatusKey}`, undefined)
		}
	}, [orderStatusKey, router])

	const checkout = props.cart

	const payment = useQuery([checkout, store, locale], async () => {
		if (store && checkout && checkout.id) {
			const req = await fetchPaymentInfo(locale, store, checkout.id)
			return req.paymentRequest
		}
	})

	const requirePaymentIntent = React.useCallback(
		async (paymentMethod: PaymentRequestPaymentMethodEvent) => {
			console.log(JSON.stringify(paymentMethod, null, 2))
			if (store && checkout && checkout.id) {
				const req = await fetchPaymentInfo(locale, store, checkout.id, paymentMethod)

				if (req.clotinoOrderStatusKey) {
					setOrderStatusKey(req.clotinoOrderStatusKey)
				}

				if (req.paymentIntent) {
					return req.paymentIntent.client_secret
				}
			}
			throw new Error()
		},
		[checkout, store, locale]
	)

	return (
		<div className={s.ExpressCheckout}>
			{payment.data && (
				<CheckoutForm
					key={locale}
					payment={payment.data}
					requirePaymentIntent={requirePaymentIntent}
					onAvailability={props.onAvailability}
				/>
			)}
			<div className={s.Placeholder}></div>
		</div>
	)
}
