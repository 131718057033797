import clsx from 'clsx'
import Fuse from 'fuse.js'
import React from 'react'
import { ClotinoIcon } from '../../../generated/clotino-icons'
import { IconSetContext } from '../../pages/[...fallback]'
import { useTranslation } from '../Tr'
import s from './ClotinoIconPicker.module.sass'

const options = {
	isCaseSensitive: false,
	// includeScore: false,
	// shouldSort: true,
	// includeMatches: false,
	// findAllMatches: false,
	minMatchCharLength: 1,
	// location: 0,
	threshold: 0.2,
	// distance: 100,
	// useExtendedSearch: false,
	// ignoreLocation: false,
	// ignoreFieldNorm: false,
	keys: ['title', 'key', 'alt', 'group.key', 'group.title'],
}

export type ClotinoIconPickerProps = {
	value: ClotinoIcon | null
	onChange: (value: ClotinoIcon | null) => void
}

export const ClotinoIconPicker = React.memo(function ClotinoIconPicker(
	props: ClotinoIconPickerProps
) {
	const handleChange = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			props.onChange(e.currentTarget.value ? (e.currentTarget.value as ClotinoIcon) : null)
		},
		[props]
	)
	const [query, setQuery] = React.useState('')

	const iconSet = React.useContext(IconSetContext)

	const iconsList = React.useMemo(() => {
		return iconSet ?? []
	}, [iconSet])

	const fuse = React.useMemo(() => {
		return new Fuse(iconsList, options)
	}, [iconsList])

	const searchResult = React.useMemo(() => {
		return fuse.search(query)
	}, [fuse, query])

	const groups = React.useMemo(() => {
		const result: {
			key: string
			title: string
			icons: typeof iconsList
		}[] = []

		const repo: Record<string, typeof result[number]> = {}

		if (query) {
			searchResult.forEach((r) => {
				if (!repo[r.item.group.key]) {
					repo[r.item.group.key] = {
						key: r.item.group.key,
						title: r.item.group.title,
						icons: [],
					}
					result.push(repo[r.item.group.key])
				}
				repo[r.item.group.key].icons.push(r.item)
			})
		} else {
			iconsList.forEach((r) => {
				if (!repo[r.group.key]) {
					repo[r.group.key] = {
						key: r.group.key,
						title: r.group.title,
						icons: [],
					}
					result.push(repo[r.group.key])
				}
				repo[r.group.key].icons.push(r)
			})
		}

		return result
	}, [iconsList, query, searchResult])

	const icon_not_found = useTranslation('common', 'icon_not_found')
	const search_icon = useTranslation('common', 'search_icon')

	return (
		<div className={s.ClotinoIconPicker}>
			<div className={s.Groups}>
				{groups.length === 0 ? (
					<div className={s.noMatch}>{icon_not_found.replace('{query}', query)}</div>
				) : (
					groups.map((group) => (
						<section key={group.key} className={s.OptionsGroup}>
							<h1 className={s.OptionsGroupTitle}>{group.title}</h1>
							<div className={s.Options}>
								{group.icons.map((option) => {
									const isActive = props.value === option.key
									return (
										<label
											key={option.id}
											className={clsx(s.Option, isActive && s.isActive)}>
											<input
												checked={isActive}
												className={s.RadioInput}
												type="radio"
												name="icon"
												value={option.id ?? ''}
												onChange={handleChange}
											/>{' '}
											{option ? (
												<img
													loading="lazy"
													width={32}
													height={32}
													className={s.IconImg}
													src={option.image.url}
													alt={`motive ${option.title}`}
													title={option.unique}
												/>
											) : (
												'Bez obrázku'
											)}
										</label>
									)
								})}
							</div>
						</section>
					))
				)}
			</div>
			<div className={s.Search}>
				<div className={s.SearchIn}>
					<div className={s.SearchIcon} />
					<input
						type="search"
						className={s.SearchInput}
						placeholder={search_icon}
						value={query}
						onChange={(event) => setQuery(event.target.value)}
					/>
				</div>
			</div>
		</div>
	)
})
