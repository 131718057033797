import React from 'react'
import s from './PaymentProviderList.module.sass'

export function PaymentProviderList() {
	const items = [
		{
			file: 'visa',
			alt: 'Visa',
		},
		{
			file: 'mastercard',
			alt: 'MasterCard',
		},
		{
			file: 'apple',
			alt: 'Apple Pay',
		},
		{
			file: 'googlepay',
			alt: 'Google Pay',
		},
	]
	return (
		<div className={s.PaymentProviderList}>
			{items.map((item) => (
				<img
					key={item.file}
					src={`/clotino/assets/payment/${item.file}.svg`}
					alt={item.alt}
					className={s.Logo}
				/>
			))}
		</div>
	)
}
