import cn from 'clsx'
import React from 'react'

export const Part: React.FunctionComponent<{
	style?: React.CSSProperties
	textCenter?: boolean
	viewWide?: boolean
	viewBox?: boolean
	viewNoSpace?: boolean
	viewNoHorizontalSpace?: boolean
}> = ({ style, children, textCenter, viewWide, viewBox, viewNoSpace, viewNoHorizontalSpace }) => {
	return (
		<div
			style={style}
			className={cn(
				'Part',
				viewWide && 'view-wide',
				viewBox && 'view-box',
				viewNoSpace && 'view-noSpace',
				viewNoHorizontalSpace && 'view-noHorizontalSpace'
			)}>
			<div className={cn('Part-in', textCenter && 'text-center')}>{children}</div>
		</div>
	)
}
