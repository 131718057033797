export const CLOTINO_MOTIVE = {
	WIDTH: 38,
	HEIGHT: 14,
} as const

export const CLOTINO_STAMP = {
	WIDTH: 48.5,
	HEIGHT: 25.7,
	PADDING: 1,
	RADIUS: 1,
} as const

export const CLOTINO_STICKER = {
	WIDTH: 32,
	HEIGHT: 14,
	RADIUS: 1,
	PADDING: 1,
} as const
