import React from 'react'
import { ServerSideProduct } from '../../loaders/loadShop'
import { Tag, TagsList } from './Tag'
import s from './VariantPicker.module.sass'

export function VariantPicker(props: {
	variants: ServerSideProduct['productVariants']
	selectedVariant: null | ServerSideProduct['productVariants'][number]
	setSelectedVariant: React.Dispatch<
		React.SetStateAction<ServerSideProduct['productVariants'][number]>
	>
}) {
	return (
		<div className={s.VariantPicker}>
			<TagsList>
				{props.variants.map((v) => (
					<Tag
						key={v.shopifySku}
						active={props.selectedVariant?.shopifySku === v.shopifySku}
						onClick={() => props.setSelectedVariant(v)}>
						{v.localesByLocale?.title ?? v.title ?? v.shopifySku}
					</Tag>
				))}
			</TagsList>
		</div>
	)
}
