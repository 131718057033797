import React from 'react'
import { cdnUrl } from '../../utils/cdnUrl'
import s from './Features.module.sass'
import { Part } from './Part'

export const Features = React.memo(function Features(props: {
	items: { label?: React.ReactNode; icon?: string }[]
}) {
	const items = props.items ?? []
	return (
		<div className={s.Features}>
			<Part>
				<div className={s.FeaturesList}>
					{items.map(({ icon, label }, i) => (
						<div key={i} className={s.FeaturesListItem}>
							<div className={s.Icon}>
								{icon && (
									<img
										role="presentation"
										loading="lazy"
										width={72}
										height={72}
										src={cdnUrl(icon)}
										alt={''}
									/>
								)}
							</div>
							<div className={s.Label}>{label}</div>
						</div>
					))}
				</div>
			</Part>
		</div>
	)
})
