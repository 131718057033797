import React from 'react'
import { useProductVariantInfo } from '../../pages/[...fallback]'
import { ProductTitle, useMotiveFromCustomAttributes } from '../CartItems'
import { ImageWithOverlay, OverlayPresets } from '../ImageWithOverlay'
import { PriceV2 } from '../PriceV2'
import { Tr } from '../Tr'
import { Button } from './Button'
import { MotiveContext } from './Motive'
import { Tag } from './Tag'
import s from './UpsellItem.module.sass'

export interface UpsellItemProps {
	label: string
	tag?: string
	sku: string
	description?: string
	customAttributes?: { key: string; value: string }[]
	productType?: string
	image?: string
	price?: ShopifyBuy.PriceV2
	onClick?: (data: { sku: string; customAttributes?: { key: string; value: string }[] }) => void
}

export function UpsellItem(props: UpsellItemProps) {
	const { onClick } = props

	const variant = useProductVariantInfo(props.sku)

	const tag = props.tag ?? (!variant?.product?.isSingleton && variant?.title)

	const clickHandler = React.useCallback(() => {
		if (onClick) {
			onClick({ sku: props.sku, customAttributes: props.customAttributes })
		}
	}, [onClick, props.customAttributes, props.sku])

	const customization = useMotiveFromCustomAttributes(props.customAttributes ?? [])
	const value =
		customization?.icon && customization.text
			? { icon: customization.icon, label: customization.text }
			: null

	const img = variant?.image ?? variant?.product?.gallery?.[0]?.image
	const preset = img?.decorationPreset as keyof typeof OverlayPresets | undefined
	const image = img && (
		<ImageWithOverlay
			image={img}
			preset={preset && preset in OverlayPresets ? OverlayPresets[preset] : undefined}
		/>
	)

	return (
		<section className={s.UpsellItem}>
			<MotiveContext.Provider value={value}>
				<div className={s.Image}>{image}</div>
				<h1 className={s.Label}>
					<ProductTitle
						title={variant?.product.title ?? props.label}
						titleTemplate={variant?.titleTemplate ?? variant?.product?.titleTemplate}
					/>
				</h1>
				{tag && (
					<div className={s.Tag}>
						<Tag active>{tag}</Tag>
					</div>
				)}
				<div className={s.Description}>
					<p>{variant?.description}</p>
				</div>
				{props.price && (
					<div className={s.Price}>
						<PriceV2 price={props.price} />
					</div>
				)}
				<Button color="blue" block sharp outline onClick={clickHandler}>
					<Tr ns="commerce" t="add_to_cart" />
				</Button>
			</MotiveContext.Provider>
		</section>
	)
}
