import { useQuery } from 'react-query'
import { useStorefrontFetcher } from '../utils/shopify/storefront'
import { useClotino } from './useClotino'
import React from 'react'

type ProductShortcut = {
	id: string
	title: string
	handle: string
	storefrontId: string
	image?: string
	priceCents: number
	price?: {
		amount: string
		currencyCode: string
	}
	compareAtPrice?: {
		amount: string
		currencyCode: string
	}
	compareAtPriceCents?: number
	productType: string
}

export function useStorefrontProductsInfo() {
	const store = useClotino().store
	const storefrontFetch = useStorefrontFetcher(store)

	return useQuery(['useStorefrontProductsInfo', store], () => {
		if (storefrontFetch) {
			return storefrontFetch(/* GraphQL */ `
				query {
					products(first: 4) {
						edges {
							node {
								id
								title
								handle
								productType
								images(first: 1) {
									edges {
										node {
											transformedSrc(maxWidth: 600, maxHeight: 600)
										}
									}
								}

								variants(first: 4) {
									edges {
										node {
											id
											sku
											compareAtPrice
											price
										}
									}
								}
							}
						}
					}
				}
			`).then((data) => {
				const byProductType: Record<string, ProductShortcut> = {}
				const byHandle: Record<string, ProductShortcut> = {}
				const bySku: Record<
					string,
					ProductShortcut & { sku: string; compareAtPrice?: ShopifyBuy.PriceV2 }
				> = {}
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				data.data?.products?.edges.map(({ node }: { node: any }) => {
					const variant = node.variants?.edges[0]?.node

					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					node.variants?.edges.map((edge: any) => {
						bySku[edge.node.sku] = {
							id: edge.node.id,
							title: edge.node.title,
							handle: node.handle,
							sku: edge.node.sku,
							productType: node.productType,
							storefrontId: edge.node.id,
							image: node.images?.edges?.[0]?.node?.transformedSrc,
							priceCents: Number(edge.node.price) * 100,
							price: edge.node
								? {
										amount: edge.node.price,
										currencyCode: store,
								  }
								: undefined,
							compareAtPrice: edge.node
								? {
										amount: edge.node.compareAtPrice,
										currencyCode: store,
								  }
								: undefined,
							compareAtPriceCents: edge.node.compareAtPrice
								? Number(edge.node.compareAtPrice) * 100
								: undefined,
						}
					})
					byProductType[node.productType as string] = byHandle[node.handle as string] = {
						id: node.id,
						title: node.title,
						handle: node.handle,
						productType: node.productType,
						storefrontId: variant?.id,
						image: node.images?.edges?.[0]?.node?.transformedSrc,
						priceCents: Number(variant?.price) * 100,
						price: variant
							? {
									amount: variant.price,
									currencyCode: store,
							  }
							: undefined,
						compareAtPriceCents: variant?.compareAtPrice
							? Number(variant?.compareAtPrice) * 100
							: undefined,
					}
				})
				return { byHandle, byProductType, bySku }
			})
		}
	})
}

export function useStorefrontProductInfo(handle: string) {
	const productsInfo = useStorefrontProductsInfo()

	return productsInfo.data?.byHandle[handle] ?? null
}

export function useStorefrontProductVariantInfo(sku?: string) {
	const productsInfo = useStorefrontProductsInfo()

	if (!sku) {
		return null
	}

	return productsInfo.data?.bySku[sku] ?? null
}

export function useStorefrontProductVariantsInfo() {
	const productsInfo = useStorefrontProductsInfo()
	const bySku = productsInfo.data?.bySku
	return React.useCallback(
		(sku: string) => {
			return bySku?.[sku]
		},
		[bySku]
	)
}
