import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import getConfig from 'next/config'
import React from 'react'
import { useClotino } from '../../hooks/useClotino'

let stripePromise: null | ReturnType<typeof loadStripe> = null

const loadStripeHelper = () => import('@stripe/stripe-js').then((m) => m.loadStripe)

function getStripe() {
	if (!stripePromise) {
		const config = getConfig().publicRuntimeConfig
		const stripe = config?.stripe as { publicKey: string }
		stripePromise = loadStripeHelper().then((loadStripe) => loadStripe(stripe.publicKey ?? ''))
	}
	return stripePromise
}

export function StripeProvider({ children }: { children: React.ReactNode }) {
	const locale = useClotino().locale
	return (
		<Elements options={{ locale }} stripe={getStripe()}>
			{children}
		</Elements>
	)
}
