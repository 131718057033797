import React from 'react'
import s from './Tag.module.sass'
import cn from 'clsx'

export function Tag(props: {
	children: React.ReactNode
	active?: boolean
	onClick?: (event: React.MouseEvent<HTMLLabelElement, MouseEvent>) => void
}) {
	return (
		<label className={cn(s.Tag, props.active && s.active)} onClick={props.onClick}>
			<span className={s.Text}>{props.children}</span>
		</label>
	)
}

export function TagsList(props: { children: React.ReactNode }) {
	return (
		<div className={s.TagsList}>
			<div className={s.Items}>{props.children}</div>
		</div>
	)
}
