import chalk from 'chalk'
import fetch from 'isomorphic-fetch'
import { PromiseValue } from 'type-fest'

const logging = 0

interface GraphQLResponse {
	data?: Record<string, unknown>
	extensions?: Record<string, unknown>
	errors?: Array<{
		message: string
	}>
}

export class GraphQLError extends Error {
	constructor(public response: GraphQLResponse) {
		super('GraphQL Response Error')
		console.error(response)
	}
	toString() {
		return `${this.message}\n${JSON.stringify(this.response, null, 2)}`
	}
}

const handleFetchResponse = (
	response: PromiseValue<ReturnType<typeof fetch>>
): Promise<GraphQLResponse> => {
	if (!response.ok) {
		return new Promise((_, reject) => {
			response
				.text()
				.then((text) => {
					try {
						reject(JSON.parse(text))
					} catch (err) {
						reject(text)
					}
				})
				.catch(reject)
		})
	}
	return response.json()
}

type FetchParameters = Parameters<typeof fetch>

const zeusFetch =
	(...options: FetchParameters) =>
	(query: string, variables: Record<string, unknown> = {}) => {
		if (logging >= 1) {
			console.log('<graphql>')
			console.log(chalk.blue(query))
			console.log('</graphql>')
		}
		const fetchFunction = fetch
		let queryString = query
		const fetchOptions = options[1] ?? {}
		if (fetchOptions.method && fetchOptions.method === 'GET') {
			queryString = encodeURIComponent(query)
			return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
				.then(handleFetchResponse)
				.then((response: GraphQLResponse) => {
					// console.log('<graphql-response>')
					// console.log(chalk.green(dump(response)))
					// console.log('</graphql-response>')
					if (response.errors) {
						throw new GraphQLError(response)
					}
					if ('extensions' in response && logging >= 2) {
						console.log('<graphql>')
						console.log(chalk.red(JSON.stringify(response.extensions, null, 2)))
						console.log('</graphql>')
					}
					return response.data
				})
		}
		return fetchFunction(`${options[0]}`, {
			body: JSON.stringify({ query: queryString, variables }),
			method: 'POST',
			...fetchOptions,
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				...fetchOptions.headers,
			},
		})
			.then(handleFetchResponse)
			.then((response: GraphQLResponse) => {
				// console.log('<graphql-response>')
				// console.log(chalk.green(dump(response)))
				// console.log('</graphql-response>')
				if (response.errors) {
					throw new GraphQLError(response)
				}
				if ('extensions' in response && logging >= 2) {
					console.log('<graphql>')
					console.log(chalk.red(JSON.stringify(response.extensions, null, 2)))
					console.log('</graphql>')
				}
				return response.data
			})
	}

type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<unknown>

type Thunder<T> = (fn: FetchFunction) => T

export function fromThunder<T>(thunder: Thunder<T>, url: string, headers?: Record<string, string>) {
	return thunder(
		zeusFetch(url, {
			headers,
		})
	)
}
