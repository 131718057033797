import { useRouter } from 'next/router'
import React from 'react'
import { ImageResult } from '../../loaders/loadShop'
import { PageType, PageTypeContext } from '../../utils/PageTypeContext'
import { Image } from '../Image'
import { Button } from './Button'
import s from './Hero.module.sass'
import { TextContent } from './TextContent'

const scrollUp = () => {
	const target = document.querySelector<HTMLDivElement>('#main')
	const headerOffset = 100
	const elementPosition = target?.offsetTop ?? 0
	const offsetPosition = elementPosition - headerOffset

	window.scrollTo({
		top: offsetPosition,
		behavior: 'smooth',
	})
}

export const Hero = React.memo(function Hero(props: {
	title?: React.ReactNode
	note?: React.ReactNode
	buttonLabel?: React.ReactNode
	image?: ImageResult
}) {
	const { title, note, buttonLabel } = props
	const router = useRouter()
	const pageType = React.useContext(PageTypeContext)

	const handleClick = React.useCallback(() => {
		if (pageType === PageType.PRODUCT) {
			console.log('no redirect')
			scrollUp()
		} else {
			console.log('redirect')
			router.push('/', undefined, { scroll: false }).then(() => {
				scrollUp()
			})
		}
	}, [pageType, router])

	return (
		<section className={s.Hero}>
			{props.image && (
				<div className={s.Bg}>
					<Image
						role="presentation"
						src={props.image.url}
						alt={props.image.alt ?? ''}
						title={props.image.title}
						layout="fill"
						objectFit="cover"
					/>
				</div>
			)}
			<div className="Part">
				<div className="Part-in">
					<h1 className={s.Title}>{title}</h1>
					<div className={s.Note}>
						<TextContent strongs="red">{note}</TextContent>
					</div>
					<div className={s.Action}>
						<Button onClick={handleClick} emphasized>
							{buttonLabel}
						</Button>
					</div>
				</div>
			</div>
		</section>
	)
})
