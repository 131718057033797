import React from 'react'
import { MotiveContext } from '../components/clotino/Motive'
import { useClotinoMotive } from './useClotinoMotive'

export function useTitleTemplate(title: string, titleTemplate?: string) {
	const customization = useClotinoMotive()
	const motive = React.useContext(MotiveContext)
	if (!titleTemplate) {
		return title
	}
	const label = motive?.label ?? customization.label
	if (label) {
		return titleTemplate.replace('{text}', label)
	}
	return title
}
