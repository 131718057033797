import React from 'react'
import { OptimisticValue } from './ConnectedCart'
import { QuantityInput } from './QuantityInput'

export function LineItemQuantity<
	LineItem extends {
		id: string
		quantity: OptimisticValue
	}
>(props: {
	lineItem: LineItem
	onChange: (data: {
		id: string
		quantity: number
		oldQuantity: number
		lineItem: LineItem
	}) => void
}) {
	const lineItemId = props.lineItem.id

	return (
		<QuantityInput
			value={props.lineItem.quantity.value}
			onChange={(e) =>
				props.onChange({
					id: lineItemId,
					quantity: Number(e.currentTarget.value),
					oldQuantity: props.lineItem.quantity.value,
					lineItem: props.lineItem,
				})
			}
		/>
	)
}
