import Link from 'next/link'
import React from 'react'
import { PageType } from '../../../generated/content'
import { MutationLinks, SiteLinks } from '../../loaders/loadShop'
import { PaymentProviderList } from '../PaymentProviderList'
import { SocialNetworkLinks } from '../SocialNetworkLinks'
import { Tr } from '../Tr'
import s from './Footer.module.sass'
import { LangSwitcher } from './LangSwitcher'
import { Part } from './Part'

export const Footer = React.memo(function Footer(props: {
	links?: SiteLinks
	mutations?: MutationLinks
}) {
	const Y = new Date().getFullYear()
	return (
		<div className={s.Footer}>
			<Part>
				<div className={s.LangSwitcher}>
					<LangSwitcher mutations={props.mutations} />
				</div>
				<div className={s.Nav}>
					<section className={s.SocialNetworks}>
						<h1 className={s.Title}>
							<Tr ns="common" t="Follow us online" />
						</h1>
						<SocialNetworkLinks />
					</section>
					<section className={s.NavGroup}>
						<h1 className={s.Title}>
							<Tr ns="common" t="Legal" />
						</h1>
						<ul className={s.NavList}>
							<li className={s.NavListItem}>
								<Link href="/privacy-policy">
									<a className={s.NavListLink}>
										<Tr ns="common" t="Privacy policy" />
									</a>
								</Link>
							</li>
							<li className={s.NavListItem}>
								<Link href="/terms-of-service">
									<a className={s.NavListLink}>
										<Tr ns="common" t="Terms of service" />
									</a>
								</Link>
							</li>
							<li className={s.NavListItem}>
								<Link href="/legal-notice">
									<a className={s.NavListLink}>
										<Tr ns="common" t="Legal notice" />
									</a>
								</Link>
							</li>
							<li className={s.NavListItem}>
								<Link href="/refund-policy">
									<a className={s.NavListLink}>
										<Tr ns="common" t="Refund policy" />
									</a>
								</Link>
							</li>
							<li className={s.NavListItem}>
								<Link href="/shipping-policy">
									<a className={s.NavListLink}>
										<Tr ns="common" t="Shipping policy" />
									</a>
								</Link>
							</li>
						</ul>
					</section>
					<section className={s.NavGroup}>
						<h1 className={s.Title}>
							<Tr ns="common" t="About" />
						</h1>
						<ul className={s.NavList}>
							{props.links?.[PageType.howitworks] && (
								<li className={s.NavListItem}>
									<Link href={props.links?.[PageType.howitworks]?.link ?? '#'}>
										<a className={s.NavListLink}>
											{props.links?.[PageType.howitworks]?.title}
										</a>
									</Link>
								</li>
							)}
							{props.links?.[PageType.contact] && (
								<li className={s.NavListItem}>
									<Link href={props.links?.[PageType.contact]?.link ?? '#'}>
										<a className={s.NavListLink}>
											{props.links?.[PageType.contact]?.title}
										</a>
									</Link>
								</li>
							)}
						</ul>
					</section>
					<div className={s.NavGroup}></div>
				</div>
				<div className={s.PaymentProviders}>
					<PaymentProviderList />
				</div>
				<div className={s.Copyright}>
					<p>&copy; 2020 - {Y} Clotino | Brdy Ventures s.r.o.</p>
				</div>
			</Part>
		</div>
	)
})
