import accept from '@hapi/accept'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import cn from 'clsx'
import isArray from 'lodash/isArray'
import { InferGetServerSidePropsType } from 'next'
import { NextSeo } from 'next-seo'
import getConfig from 'next/config'
import nookies from 'nookies'
import React from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { ContentBlockType } from '../../generated/content'
import { AddToCartButton } from '../components/AddToCartButton'
import { Button } from '../components/clotino/Button'
import { ConfigurableProduct } from '../components/clotino/ConfigurableProduct'
import { FAQBlock } from '../components/clotino/FAQBlock'
import { Features } from '../components/clotino/Features'
import { Hero } from '../components/clotino/Hero'
import { JsonContent } from '../components/clotino/JsonContent'
import { MediaBlock } from '../components/clotino/MediaBlock'
import { OnClientSide } from '../components/clotino/OnClientSide'
import { PageLead } from '../components/clotino/PageLead'
import { renderClotinoLayout } from '../components/clotino/renderLayout'
import { Reviews } from '../components/clotino/Reviews'
import { TipsBlock } from '../components/clotino/TipsBlock'
import { InfoBanner } from '../components/InfoBanner'
import { CompareAtPriceV2 } from '../components/PriceV2'
import { Tr } from '../components/Tr'
import { useClotino } from '../hooks/useClotino'
import { useClotinoMotive } from '../hooks/useClotinoMotive'
import { useShopifyProduct } from '../hooks/useShopifyProduct'
import { ContentResult, IconSetResult, loadShop } from '../loaders/loadShop'
import { contextInfo } from '../utils/clotino/contextInfo'
import isCustomizableProductType from '../utils/clotino/ProductTypes'
import { inArray } from '../utils/inArray'
import {
	getServerSidePropsMemoHandler,
	RedirectDirective,
} from '../utils/next.js/getServerSideProps'
import { PageType, PageTypeContext } from '../utils/PageTypeContext'
import { prepareProductsInfo } from '../utils/prepareProductsInfo'
import s from './page.module.sass'

const RenderBlock = React.memo(function RenderBlock(props: {
	block?: ContentResult['blocks'][number]
	position?: number
}) {
	const { block, position } = props

	// const { mutate } = useAddToCart()

	// const products = useStorefrontProductsInfo()

	// const handleUpsellClick = React.useCallback(
	// 	(data: { handle: string; customAttributes?: { key: string; value: string }[] }) => {
	// 		const { handle, customAttributes } = data
	// 		const variantId = products.data?.byHandle[handle]?.storefrontId
	// 		if (variantId) {
	// 			return mutate({ variantId, customAttributes })
	// 		}
	// 	},
	// 	[mutate, products.data]
	// )

	if (!block) {
		return null
	}

	switch (block.type) {
		case ContentBlockType.header:
			return block.primaryText ? (
				<PageLead
					title={block.primaryText}
					content={
						block.jsonContent ? (
							<JsonContent jsonContent={block.jsonContent} />
						) : undefined
					}
				/>
			) : null
		case ContentBlockType.product:
			return (
				<div className={cn(s.ProductPart, position === 0 && s['view-first'])}>
					<div className={s.in}>
						{block.product && (
							<ConfigurableProduct
								block={block}
								mediaPosition={position === 0 ? 'left' : 'right'}
								isPrimary={position === 0}
							/>
						)}
					</div>
				</div>
			)
		case ContentBlockType.icons:
			return (
				<Features
					items={
						block.iconList?.items.map((item) => ({
							icon: item.icon?.url,
							label: <JsonContent jsonContent={item.text ?? ''} />,
						})) ?? []
					}
				/>
			)
		case ContentBlockType.sideBySide:
			return (
				<MediaBlock
					mediaSide={block.sideBySideView === 'left' ? 'right' : 'left'}
					title={block.primaryText}
					content={<JsonContent jsonContent={String(block.jsonContent)} />}
					image={block?.media}
					imageCaption={block?.media?.caption}
					action={
						block.link?.externalLink ? (
							<Button href={block.link.externalLink} block emphasized>
								<span>{block.link.title}</span>
							</Button>
						) : undefined
					}
				/>
			)
		case ContentBlockType.reviews:
			return (
				<OnClientSide>
					{block.reviewList && (
						<Reviews title={block.primaryText} items={block.reviewList} />
					)}
				</OnClientSide>
			)
		case ContentBlockType.cta:
			return (
				<Hero
					title={block.primaryText}
					note={<JsonContent jsonContent={block.jsonContent ?? ''} />}
					buttonLabel={block.link?.title}
					image={block.image}
				/>
			)
		case ContentBlockType.faq:
			return (
				<FAQBlock
					title={block.primaryText}
					items={
						block.faq?.items.map((faqItem) => ({
							label: faqItem.question,
							content: <JsonContent jsonContent={faqItem.answer ?? ''} />,
						})) ?? []
					}
				/>
			)
		case ContentBlockType.products:
			return null
		// return block.productList ? (
		// 	<Upsell
		// 		title={block.primaryText}
		// 		items={block.productList.map((pl) => ({
		// 			handle: pl.shopifyHandle,
		// 			title: pl.localesByLocale?.title ?? '',
		// 			productType: pl.productType ?? '',
		// 			price: pl.productVariants[0].storesByStore?.price
		// 				? {
		// 						amount: String(pl.productVariants[0].storesByStore.price),
		// 						currencyCode: 'czk',
		// 				  }
		// 				: undefined,
		// 			variants: pl.productVariants.map((v) => ({
		// 				storefrontId: v.storesByStore?.storefrontId ?? '',
		// 			})),
		// 		}))}
		// 		onItemClick={handleUpsellClick}
		// 	/>
		// ) : null
		case ContentBlockType.features:
			return (
				<TipsBlock
					items={
						block.featureList?.items.map((item) => ({
							title: item.headline,
							content: item.text && <JsonContent jsonContent={item.text} />,
						})) ?? []
					}
				/>
			)
	}

	return (
		<>
			<React.Fragment>
				<pre>{JSON.stringify({ block, position }, null, 2)}</pre>
			</React.Fragment>
		</>
	)
})

export const RenderBlocks = React.memo(function RenderBlocks(props: {
	blocks?: ContentResult['blocks']
	blacklist?: ContentBlockType[]
	contentRef?: (node: Element | null) => void
}) {
	const { blacklist } = props
	const blocks = React.useMemo(() => {
		return blacklist
			? props.blocks?.filter((block) => !inArray(blacklist, block.type))
			: props.blocks
	}, [blacklist, props.blocks])

	if (!blocks) {
		return null
	}

	return (
		<div className={s.Blocks} ref={props.contentRef}>
			{blocks.map((block, b) => (
				<div className={cn(s.Block, s[`type-${block.type}`])} key={b}>
					<RenderBlock block={block} position={b} />
				</div>
			))}
		</div>
	)
})

const blacklist = [ContentBlockType.header]

function PersonalizableProduct(props: InferGetServerSidePropsType<typeof getServerSideProps>) {
	const product = props.product

	if (!product) {
		throw new Error('missing product')
	}

	const handle = product.shopifyHandle

	const { label, motive } = useClotinoMotive()

	const debounced = useDebouncedCallback((value: string[]) => {
		const hash = location.hash ? `${location.hash}` : ''

		if (value.length === 1 && value[0] === 'clothing-stamp') {
			console.log('WOULD_REDIRECT', `/${hash}`, undefined, {
				shallow: true,
				scroll: false,
			})
		} else {
			console.log(
				'WOULD_REDIRECT',
				`/product/${value.map(encodeURIComponent).join('/')}${hash}`,
				undefined,
				{
					shallow: true,
					scroll: false,
				}
			)
		}
	}, 1000)

	const iconKey = motive?.icon.unique

	React.useEffect(() => {
		debounced([handle, iconKey ?? (label ? '-' : ''), label ?? ''].filter(Boolean))
	}, [debounced, iconKey, label, handle])

	const title = props.page.seo?.localesByLocale?.title ?? props.product?.title

	const description = props.page.seo?.localesByLocale?.description

	const [hideBanner, setHideBanner] = React.useState(true)

	useScrollPosition(({ currPos }) => {
		setHideBanner(currPos.y > -300)
	})

	return (
		<>
			<NextSeo
				title={title}
				description={description}
				openGraph={{
					title,
					description,
					images: props.page.seo?.ogImage ? [props.page.seo?.ogImage] : [],
				}}
				noindex={props.page.seo?.noindex ?? false}
				nofollow={props.page.seo?.noindex ?? false}
			/>

			{props.page?.localesByLocale?.bannerText && (
				<InfoBanner
					hidden={hideBanner}
					text={props.page.localesByLocale.bannerText}
					color={props.page.localesByLocale.bannerColor}
					bgColor={props.page.localesByLocale.bannerBgColor}
				/>
			)}

			<RenderBlocks
				blocks={props.page?.localesByLocale?.content?.blocks}
				blacklist={blacklist}
			/>
		</>
	)
}

function RegularProduct(props: InferGetServerSidePropsType<typeof getServerSideProps>) {
	const config = getConfig().publicRuntimeConfig

	const product = useShopifyProduct(useClotino().store, props.product?.shopifyHandle)

	const title =
		props.page.seo?.localesByLocale?.title ?? product.data?.title ?? props.product?.title

	const description = props.page.seo?.localesByLocale?.description

	return (
		<>
			<NextSeo
				title={title}
				description={description}
				openGraph={{
					title,
					description,
					images: [
						props.page.seo?.ogImage ?? {
							url: `${config?.baseUrl}/api/image?target=og&view=product`,
							alt: title,
						},
					],
				}}
				noindex={props.page.seo?.noindex ?? false}
				nofollow={props.page.seo?.noindex ?? false}
			/>
			<div className={s.ProductPart}>
				<div className={s.in}>
					<h1>{title}</h1>
					{product.data && (
						<>
							<p>{product.data.description}</p>
							{product.data.images.map((image) => (
								<img key={image.id} src={image.src} alt={image.altText} />
							))}
							{product.data.variants.map((variant) => (
								<div key={variant.id}>
									<p>
										{variant.title}{' '}
										<CompareAtPriceV2
											price={variant.priceV2}
											compareAtPrice={variant.compareAtPriceV2}
										/>
										<AddToCartButton sku={variant.sku} variantId={variant.id}>
											<Tr ns="commerce" t="add_to_cart" />
										</AddToCartButton>
									</p>
								</div>
							))}
						</>
					)}
				</div>
			</div>
		</>
	)
}

export const IconSetContext = React.createContext<null | IconSetResult>(null)

export type ProductsInfoSource = InferGetServerSidePropsType<
	typeof getServerSideProps
>['productsInfo']

export const ProductInfoContext = React.createContext<null | ReturnType<
	typeof usePrepareProductsInfo
>>(null)

export function usePrepareProductsInfo(productsInfo?: ProductsInfoSource) {
	return React.useMemo(() => {
		return prepareProductsInfo(productsInfo)
	}, [productsInfo])
}

export function useProductInfo(handle: string) {
	const data = React.useContext(ProductInfoContext)

	return React.useMemo(() => {
		if (data) {
			const result = data.byHandle[handle]
			if (result) {
				return {
					...result,
					variants: result.variants.map((sku) => data.bySku[sku]).filter(Boolean),
					image: result.gallery?.[0]?.image ?? null,
				}
			}
		}
		return null
	}, [data, handle])
}

export function useProductVariantsInfo() {
	const data = React.useContext(ProductInfoContext)

	const findBySku = React.useCallback(
		(sku: string) => {
			if (data && sku) {
				const result = data.bySku[sku]
				if (result) {
					const product = data.byHandle[result.product ?? ''] ?? null
					return {
						...result,
						product,
						image: result.gallery?.[0]?.image ?? product.gallery?.[0]?.image ?? null,
					}
				}
			}
			return null
		},
		[data]
	)

	return { findBySku }
}

export function useProductVariantInfo(sku: string | null | undefined) {
	const { findBySku } = useProductVariantsInfo()

	return React.useMemo(() => {
		return sku ? findBySku(sku) : null
	}, [findBySku, sku])
}

export default function CommonPage(props: InferGetServerSidePropsType<typeof getServerSideProps>) {
	const pageType = isCustomizableProductType(props.product?.productType ?? '')
		? PageType.PRODUCT
		: null
	return (
		<>
			<PageTypeContext.Provider value={pageType}>
				<NextSeo
					title={
						props.page.seo?.localesByLocale?.title ?? props.page.localesByLocale?.title
					}
					noindex={props.page.seo?.noindex ?? false}
					nofollow={props.page.seo?.noindex ?? false}
				/>
				<IconSetContext.Provider value={props.iconSet}>
					{props.product ? (
						<>
							{isCustomizableProductType(props.product?.productType ?? '') ? (
								<PersonalizableProduct {...props} />
							) : (
								<RegularProduct {...props} />
							)}
						</>
					) : (
						<RenderBlocks blocks={props.page?.localesByLocale?.content?.blocks} />
					)}
				</IconSetContext.Provider>
			</PageTypeContext.Provider>
		</>
	)
}

CommonPage.renderLayout = renderClotinoLayout

function detectLanguage(header: string, context: { defaultLocale?: string; locales?: string[] }) {
	const a = accept.language(header, context.locales ?? [])
	const b = accept.language(header.replace(/-[a-z]+/i, ''), context.locales ?? [])

	if (!a || a === context.defaultLocale) {
		return b
	}
	return a
}
export const getServerSideProps = getServerSidePropsMemoHandler(
	'fallback',
	async (context) => {
		const path = isArray(context.query.fallback) ? context.query.fallback.join('/') : ''

		const detected = detectLanguage(context.req.headers['accept-language'] ?? '', context)

		if ((context.locales ?? []).indexOf(detected) > -1) {
			if (context.locale !== detected) {
				const cookies = nookies.get(context)
				const fromCookies = cookies.NEXT_LOCALE
				if (!fromCookies) {
					nookies.set(context, 'NEXT_LOCALE', detected, {
						maxAge: 30 * 24 * 60 * 60,
						path: '/',
					})
					throw new RedirectDirective(
						detected === context.defaultLocale ? path : `${detected}/${path}`
					)
				}
			}
		}

		const badApiPath = /^([a-z]{2})api\/(.*)$/

		const match = badApiPath.exec(path)

		if (match) {
			throw new RedirectDirective(`/${match[1]}/api/${match[2]}`)
		}
		const info = contextInfo(context)

		return {
			...info,
			path,
		}
	},
	async (key) => {
		return { props: await loadShop(key.store, key.locale, key.path) }
	}
)
