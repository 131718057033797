import { useQuery } from 'react-query'
import { fetchShippingOptions } from '../components/stripe/ExpressCheckout'
import { useClotino } from './useClotino'

export function useDefaultShipping() {
	const { store, locale } = useClotino()
	const defaultShipping = useQuery(['shipping', store], async () => {
		const data = await fetchShippingOptions(locale, store, 0, '')
		return data[0]
	})

	return defaultShipping
}
