import React from 'react'

function useOnClientSide() {
	const [clientSide, setClientSide] = React.useState(false)

	React.useEffect(() => {
		const timeout = setTimeout(() => {
			setClientSide(process.browser)
		}, 100)
		return () => clearTimeout(timeout)
	}, [])

	return clientSide
}

export function OnClientSide(props: { children: JSX.Element }) {
	const onClientSide = useOnClientSide()
	return <>{onClientSide ? props.children : null}</>
}
