import React from 'react'
import s from './Upsell.module.sass'
import { UpsellItem } from './UpsellItem'

export type UpsellProduct = {
	title: string
	sku: string
	price?: ShopifyBuy.PriceV2
	customAttributes?: { key: string; value: string }[]
}

export const Upsell = React.memo(function Upsell(props: {
	title: React.ReactNode
	items: UpsellProduct[]
	raw?: boolean
	onItemClick?: (data: {
		sku: string
		customAttributes?: { key: string; value: string }[]
	}) => void
}) {
	const inner = (
		<>
			{props.items.map((item) => (
				<UpsellItem
					key={JSON.stringify([item.sku, item.customAttributes])}
					label={item.title}
					sku={item.sku}
					price={item.price}
					customAttributes={item.customAttributes}
					onClick={props.onItemClick}
				/>
			))}
		</>
	)

	if (props.raw) {
		return inner
	}

	return (
		<div className={s.Upsell}>
			<div className="Part">
				<div className="Part-in text-center">
					<h1 className={s.Title}>{props.title}</h1>
					<div className={s.Items}>{inner}</div>
				</div>
			</div>
		</div>
	)
})
