import React from 'react'
import s from './SocialNetworkLinks.module.sass'

export function SocialNetworkLinks() {
	return (
		<div className={s.SocialNetworkLinks}>
			<a href="https://www.facebook.com/clotinocom" className={s.Link}>
				<img
					src="/clotino/assets/facebook.svg"
					width={48}
					height={48}
					alt="Facebook logo"
					className={s.Logo}
				/>
				Facebook
			</a>
			<a href="https://www.instagram.com/clotino_com/" className={s.Link}>
				<img
					src="/clotino/assets/instagram.svg"
					width={48}
					height={48}
					alt="Instagram logo"
					className={s.Logo}
				/>
				Instagram
			</a>
			<a href="https://www.youtube.com/channel/UCaFFyOUi_6IhFS9GZpBQqgg" className={s.Link}>
				<img
					src="/clotino/assets/youtube.svg"
					width={48}
					height={48}
					alt="YouTube logo"
					className={s.Logo}
				/>
				YouTube
			</a>
		</div>
	)
}
