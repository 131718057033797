import cn from 'classnames'
import React from 'react'
import { Button } from './clotino/Button'
import { JsonContent } from './clotino/JsonContent'
import s from './InfoBanner.module.sass'

function useDismission(key: string, expirationSeconds?: number | null) {
	const lsKey = `dismiss:${key}`
	const [dismissed, setDismissed] = React.useState<boolean | null>(null)

	React.useEffect(() => {
		const value = window.localStorage.getItem(lsKey)
		if (value === null) {
			setDismissed(false)
			return
		}
		if (!expirationSeconds) {
			setDismissed(true)
			return
		}
		const numeric = Number(value)
		const expired = numeric + expirationSeconds * 1000 < new Date().getTime()

		setDismissed(!expired)
	}, [expirationSeconds, lsKey])

	const dismiss = React.useCallback(() => {
		setDismissed(true)
		window.localStorage.setItem(lsKey, new Date().getTime().toString())
	}, [lsKey])

	return [dismissed, dismiss] as const
}

export const InfoBanner = React.memo(function InfoBanner(props: {
	text: string
	color?: string
	bgColor?: string
	hidden?: boolean
}) {
	const [dismissed, dismiss] = useDismission(
		JSON.stringify([props.text, props.color, props.bgColor])
	)
	const open = dismissed === false
	return (
		<div
			className={cn(s.InfoBanner, open && !props.hidden && s.open)}
			style={{
				color: props.color ?? '#000000',
				backgroundColor: props.bgColor ?? '#000000',
			}}>
			<div className={s.In}>
				<div className={s.Actions}>
					<Button unstyled onClick={dismiss}>
						&times;
					</Button>
				</div>
				<div className={s.Text}>
					<JsonContent jsonContent={props.text} />
				</div>
			</div>
		</div>
	)
})
