import React from 'react'
import { useQuery } from 'react-query'
import { StorefrontContext, useStorefront } from '../utils/shopify/storefront'
import { useClotino } from './useClotino'

export function useStorefrontCheckoutId() {
	const storefrontContext = React.useContext(StorefrontContext)

	return storefrontContext?.checkoutId
}

export function useStorefrontCheckout() {
	const storefrontContext = React.useContext(StorefrontContext)
	const storefront = useStorefront(useClotino().store)

	return useQuery(
		['cart', storefrontContext?.currency, storefrontContext?.checkoutId, storefront],
		async () => {
			if (storefront && storefrontContext && storefrontContext?.checkoutId) {
				return storefront.checkout.fetch(storefrontContext.checkoutId)
			}
		},
		{
			refetchOnWindowFocus: false,
		}
	)
}

export function useCartIndicator() {
	const checkout = useStorefrontCheckout()
	return checkout.data?.lineItems.length ?? 0
}
