import clsx from 'clsx'
import { useRouter } from 'next/router'
import React from 'react'
import { storeFromString } from '../utils/clotino/storeFromString'
import { OptimisticCart } from './ConnectedCart'
import s from './PriceV2.module.sass'
import { Tr } from './Tr'

function normalizePricePart(value: string, type: string) {
	if (type === 'fraction' && value.length === 1) {
		return `${value}0`
	}
	return value
}

export const Price = React.memo(function Price(props: {
	cents: number | string
	currencyCode: string
	diff?: boolean
	label?: React.ReactNode
	locale?: string
}) {
	const { cents, currencyCode, diff, label } = props
	const router = useRouter()
	const locale = props.locale ?? router?.locale ?? 'en'
	const currencyFormatter = React.useMemo(() => {
		return new Intl.NumberFormat(locale, {
			style: 'currency',
			currency: currencyCode,
			maximumFractionDigits: 2,
			minimumFractionDigits: 0,
		})
	}, [currencyCode, locale])

	const formatted = React.useMemo(() => {
		let prefix: React.ReactNode = ''
		let showCents = Number(cents ?? 0)
		if (diff) {
			const isPositive = cents >= 0
			prefix = (
				<span className={clsx(s.Part, s[`type_sign`])} data-type={'sign'}>
					{isPositive ? '+ ' : '- '}
				</span>
			)
			showCents = Math.abs(showCents)
		}
		return (
			<>
				{prefix}{' '}
				{currencyFormatter.formatToParts(showCents / 100).map((part, i) => (
					<span
						key={i}
						className={clsx(s.Part, s[`type_${part.type}`])}
						data-type={part.type}>
						{normalizePricePart(part.value, part.type)}
					</span>
				))}
			</>
		)
	}, [cents, currencyFormatter, diff])

	const content = <span className={clsx(s.Price)}>{formatted}</span>

	if (label) {
		return (
			<div className={clsx(s.Wrap)}>
				<span className={clsx(s.Label)}>{label}</span> {content}
			</div>
		)
	}

	return content
})

export function PriceV2(props: {
	zeroAsFree?: boolean
	quantity?: number
	price: ShopifyBuy.PriceV2
	locale?: string
}) {
	const {
		price: { currencyCode },
	} = props

	const amount = React.useMemo(() => {
		if (typeof props.quantity === 'number') {
			const amount = Number(props.price.amount) * props.quantity
			return amount.toFixed(2)
		}
		return props.price.amount
	}, [props.price.amount, props.quantity])

	return (
		<span className={s.PriceV2}>
			{Number(amount) === 0 ? (
				<Tr ns="commerce" t="price_free" />
			) : (
				<Price
					cents={Number(amount) * 100}
					currencyCode={currencyCode}
					locale={props.locale}
				/>
			)}
		</span>
	)
}

export function CompareAtPriceV2(props: {
	zeroAsFree?: boolean
	price: ShopifyBuy.PriceV2
	compareAtPrice?: ShopifyBuy.PriceV2
	locale?: string
}) {
	if (
		!props.compareAtPrice ||
		!props.compareAtPrice?.amount ||
		Number(props.price.amount) >= Number(props.compareAtPrice.amount) ||
		props.price.currencyCode !== props.compareAtPrice.currencyCode
	) {
		return <PriceV2 zeroAsFree={props.zeroAsFree} price={props.price} locale={props.locale} />
	}
	return (
		<span className={s.CompareAtPriceV2}>
			<PriceV2 zeroAsFree={props.zeroAsFree} price={props.price} locale={props.locale} />{' '}
			{props.compareAtPrice && (
				<del>
					<PriceV2 price={props.compareAtPrice} locale={props.locale} />
				</del>
			)}
		</span>
	)
}
export function CompareAtPriceV2Placeholder() {
	return <span className={s.PriceV2}>&nbsp;</span>
}

export type CartItemsLineItem = OptimisticCart['lineItems'][number]

export function LineItemPrice(props: { lineItem: CartItemsLineItem }) {
	const { lineItem } = props
	const { pricing } = lineItem
	const currencyCode = storeFromString(pricing.currencyCode ?? '')

	if (pricing.discount) {
		return (
			<CompareAtPriceV2
				zeroAsFree
				compareAtPrice={{ amount: pricing.beforeDiscount.toFixed(2), currencyCode }}
				price={{ amount: pricing.amount.toFixed(2), currencyCode }}
			/>
		)
	}

	return (
		<CompareAtPriceV2 zeroAsFree price={{ amount: pricing.amount.toFixed(2), currencyCode }} />
	)
}

export function PriceRangeV2(props: {
	min: ShopifyBuy.PriceV2
	max: ShopifyBuy.PriceV2
	locale?: string
}) {
	if (
		props.min.amount === props.max.amount &&
		props.min.currencyCode === props.max.currencyCode
	) {
		return <PriceV2 price={props.min} locale={props.locale} />
	}
	return (
		<span className={s.PriceRangeV2}>
			<PriceV2 price={props.max} locale={props.locale} />
			{' - '}
			<PriceV2 price={props.min} locale={props.locale} />
		</span>
	)
}
