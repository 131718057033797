import { ClotinoIcon } from '../../../generated/clotino-icons'
import { CLOTINO_LANGUAGE } from '../locales/locales'

export const ClotinoPersonalizedProductsCustomAttributesTranslations = {
	cs: {
		icon: 'Obrázek',
		text: 'Jméno',
	},
	en: {
		icon: 'Picture',
		text: 'Name',
	},
	de: {
		icon: 'Bild',
		text: 'Name',
	},
	sk: {
		icon: 'Obrázok',
		text: 'Meno',
	},
	nl: {
		icon: 'Afbeelding',
		text: 'Naam',
	},
} as const

export function normalizePersonalizedProductCustomAttributes(
	normalizedCustomAttributes: Record<string, string | undefined>
) {
	const dict = ClotinoPersonalizedProductsCustomAttributesTranslations
	if (dict.cs.icon in normalizedCustomAttributes) {
		return {
			icon: normalizedCustomAttributes[dict.cs.icon] as ClotinoIcon,
			text: normalizedCustomAttributes[dict.cs.text],
		}
	}
	if (dict.de.icon in normalizedCustomAttributes) {
		return {
			icon: normalizedCustomAttributes[dict.de.icon] as ClotinoIcon,
			text: normalizedCustomAttributes[dict.de.text],
		}
	}
	if (dict.en.icon in normalizedCustomAttributes) {
		return {
			icon: normalizedCustomAttributes[dict.en.icon] as ClotinoIcon,
			text: normalizedCustomAttributes[dict.en.text],
		}
	}
	if ('text' in normalizedCustomAttributes) {
		return {
			icon: normalizedCustomAttributes.icon as ClotinoIcon,
			text: normalizedCustomAttributes.text,
		}
	}
	return null
}

export function denormalizePersonalProductCustomAttributesWiithLocale(
	locale: CLOTINO_LANGUAGE,
	attrs: { icon?: string; text?: string }
) {
	const dict = ClotinoPersonalizedProductsCustomAttributesTranslations
	return [
		{ key: dict[locale].icon ?? '', value: attrs.icon ?? '' },
		{ key: dict[locale].text ?? '', value: attrs.text ?? '' },
		{ key: JSON.stringify({ ...attrs, locale }), value: '' },
	]
}

export function denormalizePersonalProductCustomAttributes(attrs: {
	icon?: string
	text?: string
}) {
	return [
		{ key: 'icon', value: attrs.icon ?? '' },
		{ key: 'text', value: attrs.text ?? '' },
		{ key: JSON.stringify(attrs), value: '' },
	]
}
