import cn from 'clsx'
import React from 'react'
import { ClotinoIcon } from '../../../generated/clotino-icons'
import { ClotinoMotiveContext } from '../../hooks/useClotinoMotive'
import { CLOTINO_MOTIVE, CLOTINO_STAMP, CLOTINO_STICKER } from '../../utils/clotino/products'
import { AspectRatio } from '../AspectRatio'
import { StampImg } from '../StampImg'
import s from './Motive.module.sass'

type MotiveType = 'raw' | 'stamp' | 'sticker'

const typeOptions = {
	raw: CLOTINO_MOTIVE,
	stamp: CLOTINO_STAMP,
	sticker: CLOTINO_STICKER,
} as const

type MotiveProps = {
	type: MotiveType
	dimensions?: boolean
	decorations?: boolean
	debug?: boolean
}
export const MotiveContext = React.createContext<null | { icon: ClotinoIcon; label: string }>(null)

export const ConnectedMotive = React.memo(function ConnectedMotive(props: MotiveProps) {
	const { visibleIcon, visibleLabel } = React.useContext(ClotinoMotiveContext)
	const motive = React.useContext(MotiveContext)

	return (
		<Motive
			{...props}
			icon={motive ? motive.icon : visibleIcon}
			label={motive ? motive.label : visibleLabel}
		/>
	)
})

export const Motive = React.memo(function Motive(
	props: MotiveProps & { icon: ClotinoIcon; label: string }
) {
	const { type, dimensions, decorations, debug } = props
	const option = typeOptions[type]
	const ratio = option.WIDTH / option.HEIGHT

	const styles = React.useMemo((): React.CSSProperties => {
		const mmX = 100 / option.WIDTH
		const mmY = 100 / option.HEIGHT

		return {
			borderRadius:
				'RADIUS' in option
					? `${option.RADIUS * mmX}% / ${option.RADIUS * mmY}%`
					: undefined,
			padding:
				'PADDING' in option
					? `${option.PADDING * mmX}% ${option.PADDING * mmY}%`
					: undefined,
		}
	}, [option])

	return (
		<AspectRatio ratio={ratio}>
			<span
				style={styles}
				className={cn(
					s.Motive,
					s[`type-${type}`],
					dimensions && s.dimensions,
					decorations && s.decorations,
					debug && s.debug
				)}>
				{type === 'sticker' && dimensions ? (
					<span className={s.StickerDimensioins}>
						<img
							loading="lazy"
							src="/clotino/assets/sticker-dimensions.svg"
							alt="32 mm &times; 14 mm"
						/>
					</span>
				) : (
					<>
						<span className={s.Width}>
							<span className={s.Line}></span>
							<span className={s.Label}>{option.WIDTH}&nbsp;mm</span>
						</span>
						<span className={s.Height}>
							<span className={s.Line}></span>
							<span className={s.Label}>{option.HEIGHT}&nbsp;mm</span>
						</span>
					</>
				)}
				<StampImg icon={props.icon} label={props.label} />
			</span>
		</AspectRatio>
	)
})
