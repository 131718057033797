import React from 'react'
import { useTitleTemplate } from '../hooks/useTitleTemplate'
import { useProductVariantInfo } from '../pages/[...fallback]'
import { trackShowCart } from '../utils/analytics/analytics'
import { unifiedParseCustomization } from '../utils/customization/customization'
import s from './CartItems.module.sass'
import { MotiveContext } from './clotino/Motive'
import { Tag } from './clotino/Tag'
import { OptimisticCart, OptimisticLineItem } from './ConnectedCart'
import { ImageWithOverlay, OverlayPresets } from './ImageWithOverlay'
import { LineItemQuantity } from './LineItemQuantity'
import { LineItemPrice } from './PriceV2'

export type CartItemsLineItem = OptimisticCart['lineItems'][number]

export interface CartItemsProps {
	lineItems: CartItemsLineItem[]
	setQuantity: (data: {
		id: string
		quantity: number
		oldQuantity: number
		lineItem: OptimisticLineItem
	}) => void
}

const SKU_ORDER = ['clothing-stamp', 'sticker-pack-180', 'sticker-pack-90']

export function useMotiveFromCustomAttributes(attributes: { key: string; value: string }[]) {
	return unifiedParseCustomization(attributes)
}

const CartLineItem = React.memo(function CartLineItem(props: {
	lineItem: CartItemsLineItem
	setQuantity: (data: {
		id: string
		quantity: number
		oldQuantity: number
		lineItem: OptimisticLineItem
	}) => void
}) {
	const item = props.lineItem
	const setQuantity = props.setQuantity

	const motive = useMotiveFromCustomAttributes(item.customAttributes)

	const variant = useProductVariantInfo(item.variant?.sku)

	const preset = variant?.image?.decorationPreset as keyof typeof OverlayPresets | undefined
	const image = variant?.image && (
		<ImageWithOverlay
			image={variant.image}
			preset={preset && preset in OverlayPresets ? OverlayPresets[preset] : undefined}
		/>
	)

	if (!variant) {
		return null
	}

	return (
		<section key={item.id} className={s.Item}>
			<MotiveContext.Provider
				value={motive ? { icon: motive.icon ?? '-', label: motive.text ?? '' } : null}>
				<div className={s.Image}>{image}</div>
				<div>
					<h1 className={s.Label}>
						<ProductTitle
							title={variant?.product.title ?? ''}
							titleTemplate={
								variant?.titleTemplate ?? variant?.product?.titleTemplate
							}
						/>
					</h1>
					{!variant.product.isSingleton && <Tag active>{variant.title}</Tag>}
				</div>
				<div className={s.QuantityAndPrice}>
					<div className={s.Quantity}>
						<LineItemQuantity lineItem={item} onChange={setQuantity} />
					</div>
					{item.variant && (
						<div className={s.Price}>
							<LineItemPrice lineItem={item} />
						</div>
					)}
				</div>
			</MotiveContext.Provider>
		</section>
	)
})

export const CartItems: React.FunctionComponent<CartItemsProps> = ({ lineItems, setQuantity }) => {
	const sortedLineItems = React.useMemo(() => {
		const sortedLineItems = [...lineItems]
		sortedLineItems.sort((a, b) => {
			let aa = SKU_ORDER.indexOf(a.variant?.sku ?? '')
			let bb = SKU_ORDER.indexOf(b.variant?.sku ?? '')

			if (aa === -1) {
				aa = Infinity
			}
			if (bb === -1) {
				bb = Infinity
			}

			return aa - bb
		})
		return sortedLineItems
	}, [lineItems])

	const itemsRef = React.useRef(sortedLineItems)
	itemsRef.current = sortedLineItems

	React.useEffect(() => {
		trackShowCart(
			itemsRef.current.map((li) => {
				const motive = unifiedParseCustomization(li.customAttributes ?? [])
				return {
					quantity: li.quantity.value,
					sku: li.variant?.sku ?? '',
					title: li.variant?.title ?? '',
					currency: (li.pricing.currencyCode ?? 'czk').toUpperCase(),
					category: li.variant?.product.handle ?? '',
					variantName: li.variant?.title ?? '',
					iconName: motive?.icon ?? '',
					price: Number(li.pricing.singleAmount),
				}
			}) ?? []
		)
	}, [])

	return (
		<div className={s.CartItems}>
			{sortedLineItems.map((item) => (
				<CartLineItem key={item.id} lineItem={item} setQuantity={setQuantity} />
			))}
		</div>
	)
}

export function ProductTitle(props: { title: string; titleTemplate?: string }) {
	const t = useTitleTemplate(props.title, props.titleTemplate)
	return <>{t}</>
}
