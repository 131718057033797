import Link from 'next/link'
import { useRouter } from 'next/router'
import React from 'react'
import { MutationLinks } from '../../loaders/loadShop'
import { ClotinoLocale } from '../../utils/clotino/Locales'
import { cn } from '../../utils/cn'
import s from './LangSwitcher.module.sass'

function localeToLabel(code: string) {
	switch (code) {
		case 'en':
			return 'English'
		case 'cs':
			return 'Česky'
		case 'de':
			return 'Deutsch'
		case 'sk':
			return 'Slovenčina'
		case 'nl':
			return 'Nederlands'
	}
	return 'Unknown'
}

const flags: Record<string, string> = {
	cs: 'cz',
}

function ItemInner({ code }: { code: string }) {
	return (
		<span className={s.ItemInner}>
			<img
				src={`/clotino/assets/flags/${flags[code] ?? code}.svg`}
				width={22}
				height={22}
				alt={`country flag ${code}`}
				className={s.Flag}
				loading="lazy"
			/>
			{localeToLabel(code)}
		</span>
	)
}

export const LangSwitcher = React.memo(function LangSwitcher(props: { mutations?: MutationLinks }) {
	const router = useRouter()

	console.log('asPath', router?.asPath)

	let href = router?.asPath ?? '/'

	if (href.indexOf('/product/') > -1) {
		href = '/'
	}

	const locale = router?.locale ?? 'cs'
	const [currentLocal, ...otherLocales] = (router?.locales ?? ['en', 'cs', 'de']).sort((a, _) =>
		a === locale ? -1 : 0
	)

	const [open, setOpen] = React.useState(false)

	React.useEffect(() => {
		if (open) {
			const callback = () => setOpen(false)
			document.addEventListener('click', callback)
			return () => {
				document.removeEventListener('click', callback)
			}
		}
	}, [open])

	return (
		<div className={cn(s.LangSwitcher, open && s.Open)}>
			<button type="button" className={s.Current} onClick={() => setOpen(!open)}>
				<ItemInner code={currentLocal} key={currentLocal} />
				<span className={s.Chevron} />
			</button>
			<div className={s.Others}>
				{otherLocales.map((code) => (
					<Link
						key={code}
						href={props.mutations?.[code as ClotinoLocale]?.link ?? href}
						locale={code}>
						<a className={s.Link}>
							<ItemInner code={code} />
						</a>
					</Link>
				))}
			</div>
		</div>
	)
})
