import cn from 'clsx'
import Link from 'next/link'
import React from 'react'
import s from './Button.module.sass'

type BaseButtonProps = React.PropsWithChildren<{
	className?: string
	wrapperClassName?: string
	unstyled?: boolean
	outline?: boolean
	sharp?: boolean
	emphasized?: boolean
	small?: boolean
	color?: 'red' | 'blue' | 'green'
	link?: boolean
	block?: boolean
}>

type ButtonButtonProps = {
	type?: 'submit' | 'button'
	disabled?: boolean
	onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}
type LinkButtonProps = {
	href: string
	onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
}

export type ButtonProps = BaseButtonProps & (ButtonButtonProps | LinkButtonProps)

export function removeDomain(url: string) {
	url = url.replace('https://clotino.mgw.cz/', '/')
	url = url.replace('https://clotino.com/', '/')
	url = url.replace('https://www.clotino.com/', '/')
	return url
}

export function Button(props: ButtonProps) {
	let inside = props.children

	const defaultClass = props.unstyled ? null : s.Button

	const colorClass = s[props.color ?? 'red']

	const commonClasses = [
		s.Reset,
		defaultClass,
		colorClass,
		props.className,
		props.block && s.viewBlock,
		props.link && s.viewLink,
		props.outline && s.viewOutline,
		props.sharp && s.viewSharp,
		props.emphasized && s.viewEmphasized,
		props.small && s.viewSmall,
	]

	if ('href' in props) {
		inside = (
			<Link href={removeDomain(props.href)}>
				<a className={cn(commonClasses, s.asLink)} onClick={props.onClick}>
					{inside}
				</a>
			</Link>
		)
	} else {
		inside = (
			<button
				onClick={props.onClick}
				disabled={props.disabled}
				type={props.type ?? 'button'}
				className={cn(commonClasses, s.asButton)}>
				<span>{inside}</span>
			</button>
		)
	}

	return <span className={cn(s.Wrapper, props.wrapperClassName)}>{inside}</span>
}
