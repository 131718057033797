import cn from 'clsx'
import Link from 'next/link'
import React from 'react'
import { ImageResult } from '../../loaders/loadShop'
import { Image } from '../Image'
import { ClotinoLogo } from './ClotinoLogo'
import s from './Header.module.sass'

function MagicWord(props: { text: string }) {
	const parts = props.text.split(/([ij]{1})/)

	return (
		<span className={s.MagicWord}>
			{parts.map((str, i) => {
				if (!str) {
					return null
				}
				if (str === 'i' || str === 'j') {
					return (
						<span className={s.MagicLetter} key={i}>
							{str}
						</span>
					)
				}
				return str
			})}
		</span>
	)
}

const MagicText = React.memo(function MagicText(props: { text?: string }) {
	if (!props.text) {
		return null
	}

	const words = props.text.split(' ')

	return (
		<>
			{words.map((word, w) => (
				<React.Fragment key={w}>
					{w === 0 ? '' : ' '}
					<MagicWord text={word} />
				</React.Fragment>
			))}
		</>
	)
})

export const Header = React.memo(function Header(props: {
	large?: boolean
	title?: React.ReactNode
	line?: React.ReactNode
	image?: ImageResult
	mobileImage?: ImageResult
}) {
	return (
		<div className={cn(s.Header, props.large && s.isLarge)}>
			<div className={s.Bar}>
				<div className="Part view-wide">
					<div className="Part-in">
						<div className="f-space-between">
							<h1>
								<Link href="/">
									<a>
										<ClotinoLogo />
									</a>
								</Link>
							</h1>
						</div>
					</div>
				</div>
			</div>

			<div className={s.Main}>
				{props.large && (
					<div className={s.Lead}>
						<div className="Part ">
							<div className="Part-in">
								<div className={s.Box}>
									<div className={s.TitleContainer}>
										<h2 className={s.Title}>
											{typeof props.title === 'string' ? (
												<MagicText text={props.title} />
											) : (
												props.title
											)}
										</h2>
										<div className={s.Line}>
											{typeof props.line === 'string' ? (
												<p>{props.line}</p>
											) : (
												props.line
											)}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}

				{props.image ? (
					<div className={s.Background}>
						<div className={props.mobileImage ? s.onlyDesktop : ''}>
							<Image
								src={props.image.url}
								alt={props.image.alt}
								title={props.image.title}
								layout="fill"
								objectFit="cover"
								priority
							/>
						</div>
						{props.mobileImage && (
							<div className={s.onlyMobile}>
								<Image
									src={props.mobileImage.url}
									alt={props.mobileImage.alt}
									title={props.mobileImage.title}
									layout="fill"
									objectFit="cover"
									priority
								/>
							</div>
						)}
					</div>
				) : (
					<div
						className={s.Background}
						style={{
							backgroundImage: `url("/clotino/assets/hp-header.jpg")`,
						}}></div>
				)}
			</div>
		</div>
	)
})
