import React from 'react'
import s from './PageLead.module.sass'
import { TextContent } from './TextContent'

export const PageLead = React.memo(function PageLead(props: {
	title: string
	content: React.ReactNode
}) {
	return (
		<section className={s.PageLead}>
			<h1 className={s.Title}>{props.title}</h1>
			<div className={s.Content}>
				<TextContent>{props.content}</TextContent>
			</div>
		</section>
	)
})
