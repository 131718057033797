export function detectFreeShipping(currency: string, amount: number) {
	switch (currency) {
		case 'czk':
			return amount >= 1000
		case 'eur':
		case 'usd':
			return amount >= 30
	}
	return false
}
