import React from 'react'
import { ClotinoLocale } from '../utils/clotino/Locales'
import { ClotinoStore } from '../utils/clotino/Stores'

type ClotinoContextType = {
	store: ClotinoStore
	locale: ClotinoLocale
}

export const ClotinoContext = React.createContext<ClotinoContextType>({
	store: 'usd',
	locale: 'cs',
})

export function useClotino() {
	return React.useContext(ClotinoContext)
}
