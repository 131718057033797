import React from 'react'
import { useMutation } from 'react-query'
import { needCheckout } from '../components/AddToCartButton'
import { useOpenCartModal } from '../components/clotino/CartModal'
import { useProductVariantsInfo } from '../pages/[...fallback]'
import { trackAddToCart } from '../utils/analytics/analytics'
import { storeFromString } from '../utils/clotino/storeFromString'
import { unifiedParseCustomization } from '../utils/customization/customization'
import { queryClient } from '../utils/react-query'
import { StorefrontContext, useStorefront } from '../utils/shopify/storefront'
import { useClotino } from './useClotino'
import { useStorefrontProductVariantsInfo } from './useStorefrontProductsInfo'

export function useAddToCart() {
	const storefrontContext = React.useContext(StorefrontContext)
	const storefront = useStorefront(useClotino().store)
	const openCartModal = useOpenCartModal()
	const { findBySku } = useProductVariantsInfo()
	const pricingBySku = useStorefrontProductVariantsInfo()

	return useMutation(
		async (data: {
			sku: string
			variantId: number | string
			quantity?: number
			customAttributes?: ShopifyBuy.CustomAttribute[]
		}) => {
			if (storefront) {
				const store = storeFromString(storefrontContext?.currency ?? 'usd')
				const checkout = await needCheckout(store, storefront)
				const checkoutId = checkout.id as string
				const variant = findBySku(data.sku)
				const motive = unifiedParseCustomization(data.customAttributes ?? [])
				const pricing = pricingBySku(data.sku)

				if (variant && pricing?.price) {
					trackAddToCart(store, {
						sku: variant.shopifySku ?? '',
						title: variant.title ?? '',
						currency: pricing.price.currencyCode.toUpperCase(),
						category: variant.product.shopifyHandle ?? '',
						quantity: data.quantity ?? 1,
						variantName: variant.title ?? '',
						iconName: motive?.icon ?? '',
						price: Number(pricing.price.amount),
					})
				}

				await storefront.checkout.addLineItems(checkout.id, [
					{
						quantity: data.quantity ?? 1,
						variantId: data.variantId,
						customAttributes: data.customAttributes,
					},
				])
				storefrontContext?.setValue?.((old) => ({ ...old, checkoutId }))
				queryClient.invalidateQueries(['cart'])

				openCartModal()
			}
		}
	)
}
