import { useWindowWidth } from '@react-hook/window-size/throttled'
import { useEmblaCarousel } from 'embla-carousel/react'
import React from 'react'
import { ContentResult } from '../../loaders/loadShop'
import { AspectRatio } from '../AspectRatio'
import { Image } from '../Image'
import emblaStyles from './Embla.module.sass'
import { JsonContent } from './JsonContent'
import s from './Reviews.module.sass'

const Review = React.memo(function Review(props: {
	review: {
		image: { src: string; alt?: string; title?: string }
		text: React.ReactNode
		author: string
	}
}) {
	return (
		<div className={s.Review}>
			<div className={s.Image}>
				<AspectRatio ratio={1}>
					{props.review.image && (
						<Image
							src={props.review.image.src}
							alt={props.review.image.alt}
							title={props.review.image.title}
							layout="fill"
							objectFit="cover"
						/>
					)}
				</AspectRatio>
			</div>
			<div className={s.Text}>
				{props.review.text} <strong>{props.review.author}</strong>
			</div>
		</div>
	)
})

export const Reviews = React.memo(function Reviews(props: {
	title?: React.ReactNode
	items: ContentResult['blocks'][number]['reviewList']
}) {
	const width = useWindowWidth()

	const [emblaRef] = useEmblaCarousel({
		loop: true,
		align: 'center',
	})

	return (
		<section className={s.Reviews}>
			{props.title && (
				<div className="Part">
					<div className="Part-in text-center">
						<h1 className={s.Title}>{props.title}</h1>
					</div>
				</div>
			)}

			{width && (
				<div className={emblaStyles.Embla} ref={emblaRef}>
					<div className={emblaStyles.Container}>
						{props.items.map((r, i) => (
							<div key={i} className={emblaStyles.Slide}>
								<div className={s.Slide}>
									{r.localesByLocale?.image && (
										<Review
											review={{
												author: r.username,
												image: {
													src: r.localesByLocale.image.url ?? '',
													...r.localesByLocale.image,
												},
												text: (
													<JsonContent
														jsonContent={
															r.localesByLocale?.content ?? ''
														}
													/>
												),
											}}
										/>
									)}
								</div>
							</div>
						))}
					</div>
				</div>
			)}
		</section>
	)
})
