import React from 'react'
import { Accordion } from './Accordion'
import s from './FAQBlock.module.sass'

export const FAQBlock = React.memo(function FAQBlock(props: {
	title: React.ReactNode
	items: { label: React.ReactNode; content: React.ReactNode }[]
}) {
	return (
		<section className={s.FAQBlock}>
			<h1 className={s.Title}>{props.title}</h1>
			<Accordion items={props.items} />
		</section>
	)
})
