import isClotinoStore, { ClotinoStore } from './Stores'

export function storeFromString(str: string) {
	const result = nullableStoreFromString(str)
	if (!result) {
		throw new Error(`Invalid store key ${str}`)
	}
	return result
}
export function nullableStoreFromString(str: string) {
	str = str.toLowerCase()
	if (isClotinoStore(str)) {
		return str as ClotinoStore
	}
	return null
}

export function storeFromStringFallback(str: string) {
	return nullableStoreFromString(str) ?? 'usd'
}
