import fetch from 'isomorphic-fetch'
import getConfig from 'next/config'
import React from 'react'
import { useQuery } from 'react-query'
import { useClotino } from '../../hooks/useClotino'
import { CLOTINO_CURRENCY } from '../locales/locales'

const getStorefrontClient = () =>
	new Promise<typeof ShopifyBuy>((resolve) =>
		setTimeout(
			() => import('shopify-buy').then((m) => resolve(m.default)),
			process.browser ? 3000 : 0
		)
	)

export type StorefrontContextValue = {
	currency: CLOTINO_CURRENCY
	checkoutId: null | string
}

export const StorefrontContext = React.createContext<
	| null
	| (StorefrontContextValue & {
			setValue: React.Dispatch<React.SetStateAction<StorefrontContextValue>>
			cancelCheckout: () => void
	  })
>(null)

const StorefrontClientsMemory: Partial<Record<CLOTINO_CURRENCY, Promise<ShopifyBuy.Client>>> = {}

export async function createStorefrontClient(config: {
	domain: string
	storefrontAccessToken: string
	language: string
}) {
	console.log('creating Shopify Storefront client', config)
	const client = (await getStorefrontClient()).buildClient(config)

	return client
}

export function useStorefront(currency: CLOTINO_CURRENCY | null) {
	const config = getConfig().publicRuntimeConfig
	const clotino = useClotino()

	const q = useQuery(['shopify-buy-sdk', currency], async () => {
		if (!currency) {
			return null
		}

		const shopifyConfig = config.shopify[currency]

		if (typeof shopifyConfig === 'undefined') {
			throw new Error(`Missing shopify config for currency ${currency}.`)
		}

		StorefrontClientsMemory[currency] =
			StorefrontClientsMemory[currency] ??
			createStorefrontClient({ ...shopifyConfig, language: clotino.locale })

		return StorefrontClientsMemory[currency] as Promise<ShopifyBuy.Client>
	})

	return q.data
}

export function useStorefrontFetcher(currency: CLOTINO_CURRENCY | null) {
	return React.useMemo(() => {
		const config = getConfig().publicRuntimeConfig

		if (!config || !currency) {
			return null
		}

		const shopifyConfig = config.shopify[currency]

		if (typeof shopifyConfig === 'undefined') {
			throw new Error(`Missing shopify config for currency ${currency}.`)
		}

		return async (query: string, variables?: Record<string, unknown>) => {
			const result = await fetch(`https://${shopifyConfig.domain}/api/2021-04/graphql`, {
				headers: {
					accept: 'application/json',
					'accept-language': '*',
					'content-type': 'application/json',
					'sec-fetch-dest': 'empty',
					'sec-fetch-mode': 'cors',
					'sec-fetch-site': 'cross-site',
					'x-sdk-variant': 'javascript',
					'x-sdk-version': '2.11.0',
					'x-shopify-storefront-access-token': shopifyConfig.storefrontAccessToken,
				},
				referrerPolicy: 'strict-origin-when-cross-origin',
				body: JSON.stringify({ query, variables }),
				method: 'POST',
				mode: 'cors',
				credentials: 'omit',
			})

			return result.json()
		}
	}, [currency])
}
