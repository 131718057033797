import cn from 'clsx'
import React from 'react'
import { useMutation } from 'react-query'
import { useAddToCart } from '../hooks/useAddToCart'
import { useClotino } from '../hooks/useClotino'
import { ClotinoStore } from '../utils/clotino/Stores'
import { queryClient } from '../utils/react-query'
import { CLOTINO_LOCALSTORAGE_CHECKOUT_ID_KEY } from '../utils/shared'
import { useStorefront } from '../utils/shopify/storefront'
import s from './AddToCartButton.module.sass'
import { Button, ButtonProps } from './clotino/Button'

export async function needCheckout(store: ClotinoStore, storefront: ShopifyBuy.Client) {
	if (!process.browser) {
		throw new Error('Needed browser context')
	}
	const key = CLOTINO_LOCALSTORAGE_CHECKOUT_ID_KEY(store)

	const checkoutId = window.localStorage.getItem(key)

	let checkout: ShopifyBuy.Cart | null = null

	if (checkoutId) {
		checkout = await storefront.checkout.fetch(checkoutId)
		if (checkout && checkout.completedAt) {
			checkout = null
		}
	}

	if (!checkout) {
		checkout = await storefront.checkout.create()
		window.localStorage.setItem(key, String(checkout.id))
	}

	if (!checkout) {
		throw new Error('Cannot create Shopify checkout')
	}

	return checkout
}

export function AddToCartButton(
	props: React.PropsWithChildren<{
		sku: string
		disabled?: boolean
		variantId: string | number
		quantity?: number
		customAttributes?: { value: string; key: string }[]
		block?: ButtonProps['block']
	}>
) {
	const { mutate, isLoading } = useAddToCart()

	const { sku, variantId, quantity, customAttributes } = props

	const addToCart = React.useCallback(() => {
		mutate({
			sku,
			variantId,
			quantity,
			customAttributes,
		})
	}, [customAttributes, mutate, quantity, variantId, sku])

	return (
		<Button
			disabled={props.disabled || isLoading}
			block={props.block}
			emphasized
			color="blue"
			className={cn(s.AddToCartButton, isLoading && s.isLoading)}
			onClick={addToCart}>
			{props.children}
		</Button>
	)
}

export function RemoveFromCartButton(
	props: React.PropsWithChildren<{ lineItemId: string | number }>
) {
	const store = useClotino().store
	const storefront = useStorefront(store)

	const { mutate, isLoading } = useMutation(async () => {
		if (storefront) {
			const checkout = await needCheckout(store, storefront)
			await storefront.checkout.removeLineItems(checkout.id, [String(props.lineItemId)])
			queryClient.invalidateQueries(['cart'])
		}
	})

	return (
		<Button
			unstyled
			disabled={isLoading}
			className={s.AddToCartButton}
			onClick={() => mutate()}>
			{props.children}
		</Button>
	)
}
