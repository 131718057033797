import React from 'react'
import { useTranslation } from '../Tr'
import s from './CartIcon.module.sass'
import { useOpenCartModal } from './CartModal'

export function CartIcon(props: { count?: number }) {
	const { count } = props
	const openCartModal = useOpenCartModal()

	const label = useTranslation('commerce', 'open_cart')

	return (
		<div className={s.Container}>
			<button aria-label={label} className={s.CartIcon} type="button" onClick={openCartModal}>
				{typeof count === 'number' && !!count && (
					<span className={s.Indicator}>{count}</span>
				)}
			</button>
		</div>
	)
}
