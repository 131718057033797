// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
export const triggerSyntheticInputChange = (input: HTMLInputElement, value: string) => {
	const valueSetter = Object.getOwnPropertyDescriptor(input, 'value').set
	const prototype = Object.getPrototypeOf(input)
	const prototypeValueSetter = Object.getOwnPropertyDescriptor(prototype, 'value').set

	if (valueSetter && valueSetter !== prototypeValueSetter) {
		prototypeValueSetter.call(input, value)
	} else {
		valueSetter.call(input, value)
	}

	input.dispatchEvent(new Event('input', { bubbles: true }))
}
