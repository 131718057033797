import React from 'react'
import { Part } from './Part'
import { TextContent } from './TextContent'
import s from './TipsBlock.module.sass'

export const TipsBlock = React.memo(function TipsBlock(props: {
	items: { title: React.ReactNode; content: React.ReactNode }[]
}) {
	return (
		<section className={s.TipsBlock}>
			<Part>
				<div className={s.Cols}>
					{props.items.map((item, i) => (
						<div key={i} className={s.Col}>
							<div className={s.In}>
								<h1 className={s.Title}>{item.title}</h1>
								<TextContent>{item.content}</TextContent>
							</div>
						</div>
					))}
				</div>
			</Part>
		</section>
	)
})
