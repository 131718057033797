import React from 'react'
import { ContemberImage } from '../../utils/contember/types'
import { Image } from '../Image'
import s from './ProductBadge.module.sass'

export const ProductBadge = React.memo(function ProductBadge(props: { image?: ContemberImage }) {
	return (
		<div className={s.Container}>
			{props.image && (
				<div className={s.Image}>
					<Image
						className={s.ImageImg}
						src={props.image.url}
						alt={props.image.alt ?? ''}
						title={props.image.title}
						width={props.image.width ?? 1}
						height={props.image.height ?? 1}
					/>
				</div>
			)}
		</div>
	)
})
