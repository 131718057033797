import cn from 'clsx'
import React from 'react'
import s from './TextContent.module.sass'

export const TextContent = React.memo(function TextContent(props: {
	children: React.ReactNode
	text?: 'grey'
	strongs?: 'blue' | 'red'
}) {
	const strongs = props.strongs ?? 'blue'
	return (
		<div
			className={cn(
				s.TextContent,
				s[`strongs-${strongs}`],
				props.text && s[`text-${props.text}`]
			)}>
			{props.children}
		</div>
	)
})
