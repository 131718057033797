import { useQuery } from 'react-query'
import { CLOTINO_CURRENCY } from '../utils/locales/locales'
import { useStorefront } from '../utils/shopify/storefront'

export function useShopifyProduct(store: CLOTINO_CURRENCY | null, handle?: string | null) {
	const storefront = useStorefront(store)

	return useQuery(
		['useShopifyProduct', handle, storefront],
		() => {
			if (storefront && handle) {
				return storefront.product.fetchByHandle(handle)
			}
			return null
		},
		{
			refetchOnWindowFocus: false,
			enabled: Boolean(handle),
		}
	)
}
