import React from 'react'
import { useCartIndicator } from '../../hooks/useStorefrontCheckout'
import { AppLayoutRenderer } from '../../utils/customApp'
import { CartIcon } from './CartIcon'
import { Footer } from './Footer'
import { Header } from './Header'
import { JsonContent } from './JsonContent'

function ConnectedCartIcon() {
	const count = useCartIndicator()
	return <CartIcon count={count} />
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderClotinoLayout: AppLayoutRenderer = (children, props?: any) => {
	return (
		<div>
			<Header
				large={props?.header?.headerView === 'image'}
				title={props?.header?.primaryText}
				image={props?.header?.image}
				mobileImage={props?.header?.mobileImage}
				line={
					props?.header?.jsonContent ? (
						<JsonContent jsonContent={props.header.jsonContent} />
					) : undefined
				}
			/>
			<ConnectedCartIcon />
			{children}
			<Footer links={props?.links} mutations={props?.mutations} />
		</div>
	)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderMinimalClotinoLayout: AppLayoutRenderer = (children, props?: any) => {
	return (
		<div>
			<Header />
			<ConnectedCartIcon />
			{children}
			<Footer links={props?.links} mutations={props?.mutations} />
		</div>
	)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderMinimalClotinoLayoutWithoutFooter: AppLayoutRenderer = (children) => {
	return (
		<div>
			<Header />
			{children}
		</div>
	)
}
