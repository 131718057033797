import cn from 'clsx'
import { difference } from 'lodash'
import { useRouter } from 'next/router'
import React from 'react'
import { useAddToCart } from '../hooks/useAddToCart'
import { useClotinoMotive } from '../hooks/useClotinoMotive'
import { CustomStorefrontCart } from '../hooks/useCustomShopifyCart'
import { useStorefrontProductsInfo } from '../hooks/useStorefrontProductsInfo'
import { ProductInfoContext } from '../pages/[...fallback]'
import { denormalizePersonalProductCustomAttributes } from '../utils/clotino/normalizePersonalizedProductCustomAttributes'
import { storeFromString } from '../utils/clotino/storeFromString'
import {
	nameToKeyAttributes,
	unifiedParseCustomization,
} from '../utils/customization/customization'
import s from './Cart.module.sass'
import { CartItems } from './CartItems'
import { Button } from './clotino/Button'
import { useCloseCartModal } from './clotino/CartModal'
import { Upsell, UpsellProduct } from './clotino/Upsell'
import { OptimisticCart } from './ConnectedCart'
import { Summary } from './Summary'
import { Tr } from './Tr'
import { Upsell as UpsellContainer } from './Upsell'

export type ExpressCheckoutRenderer = (cart: CustomStorefrontCart) => JSX.Element

function getShopifyUrlWithLocale(url: string, locale: string) {
	const symbol = url.indexOf('?') > -1 ? '&' : '?'
	return `${url}${symbol}locale=${locale}`
}

function useUpsell(cart: OptimisticCart) {
	const products = useStorefrontProductsInfo()

	const productInfo = React.useContext(ProductInfoContext)

	const customizableSkus = Object.values(productInfo?.byHandle ?? {})
		.filter((p) => p.isCustomizable)
		.map((p) => p.variants)
		.reduce((list, l) => [...list, ...l], [] as string[])

	const nonCustomizableSkus = Object.values(productInfo?.byHandle ?? {})
		.filter((p) => !p.isCustomizable)
		.map((p) => p.variants)
		.reduce((list, l) => [...list, ...l], [] as string[])

	const cartSkus: string[] = []

	const result: (UpsellProduct & { productType?: string })[] = []

	if (!products.data) {
		return result
	}

	const motives: Record<string, string[]> = {}

	cart.lineItems.forEach((node) => {
		if (!node.variant) {
			throw new Error('Missing variant in lineItem')
		}
		cartSkus.push(node.variant.sku)
		const attrs = unifiedParseCustomization(nameToKeyAttributes(node.customAttributes))

		if (attrs) {
			const key = JSON.stringify(attrs)

			motives[key] = motives[key] ?? []

			motives[key].push(node.variant.sku)
		}
	})

	const uniqueMotives = Object.keys(motives) as (keyof typeof motives)[]

	uniqueMotives.forEach((uniqueMotive) => {
		const rest = difference(customizableSkus, motives[uniqueMotive])
		const attrs = JSON.parse(uniqueMotive)

		rest.forEach((sku) => {
			const p = products.data?.bySku[sku]
			if (p) {
				result.push({
					sku,
					title: p.title,
					price: p.price,
					customAttributes: denormalizePersonalProductCustomAttributes(attrs),
					productType: p.productType,
				})
			}
		})
	})
	const rest = difference(nonCustomizableSkus, cartSkus)

	rest.forEach((sku) => {
		const p = products.data?.bySku[sku]
		if (p) {
			result.push({
				sku,
				title: p.title,
				price: p.price,
				productType: p.productType,
			})
		}
	})

	result.sort((a, b) => {
		if (a.productType !== b.productType) {
			if (a.productType === 'Stamp') {
				return -1
			}
			if (b.productType === 'Stamp') {
				return 1
			}
		}
		return 0
	})

	return result
}

export function Cart({ cart }: { cart: OptimisticCart }) {
	const router = useRouter()
	const locale = router.locale ?? 'en'
	const closeCartModal = useCloseCartModal()

	const upsell = useUpsell(cart)

	const { mutate } = useAddToCart()

	const products = useStorefrontProductsInfo()

	const clotino = useClotinoMotive()

	const handleUpsellClick = React.useCallback(
		(data: { sku: string; customAttributes?: { key: string; value: string }[] }) => {
			const { sku, customAttributes } = data
			const variantId = products.data?.bySku[sku]?.storefrontId
			if (variantId) {
				return mutate({ sku, variantId, customAttributes })
			}
		},
		[mutate, products.data]
	)

	const handleCreateAnotherStamp = React.useCallback(
		(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			e.preventDefault()
			clotino.reset()
			router.push('/', undefined, { scroll: true })
			closeCartModal()
		},
		[closeCartModal, clotino, router]
	)

	return (
		<div className={s.Cart}>
			<div className={s.Items}>
				<CartItems lineItems={cart.lineItems} setQuantity={cart.setQuantity} />
			</div>
			<section className={s.Summary}>
				<Summary
					itemsCount={cart.lineItems.reduce((sum, node) => sum + node.quantity.value, 0)}
					shopifyUrl={getShopifyUrlWithLocale(cart.webUrl, locale)}
					totalCents={Number(cart.totalCents)}
					subtotalCents={Number(cart.subtotalCents)}
					shippingCents={cart.shippingCents ?? 0}
					currency={storeFromString(cart.currencyCode)}
					onDismiss={closeCartModal}
				/>
			</section>
			<section className={cn(s.Upsell, upsell.length ? s.hasUpsell : s.noUpsell)}>
				<div className={s.UpsellLead}>
					<div className={s.Action}>
						<Button onClick={handleCreateAnotherStamp} color="blue" outline sharp>
							<Tr ns="cart" t="create_another_stamp" />
						</Button>
					</div>
					{!!upsell.length && (
						<h1 className={s.UpsellTitle}>
							<Tr ns="cart" t="customers_also_add" />
						</h1>
					)}
				</div>
				{!!upsell.length && (
					<div className={s.UpsellItems}>
						<div className={s.UpsellItemsInside}>
							<UpsellContainer>
								<Upsell
									title={<Tr ns="cart" t="buy_more" />}
									items={upsell}
									raw
									onItemClick={handleUpsellClick}
								/>
							</UpsellContainer>
						</div>
					</div>
				)}
			</section>
		</div>
	)
}
