import cn from 'clsx'
import React from 'react'
import s from './Accordion.module.sass'
import { Part } from './Part'

export interface AccordionProps {
	items: Array<{
		label: React.ReactNode
		content: React.ReactNode
		preopen?: boolean
	}>
}

const Item: React.FunctionComponent<AccordionProps['items'][number]> = ({
	label,
	content,
	preopen = false,
}) => {
	const [isOpen, setIsOpen] = React.useState(preopen)

	return (
		<section className={cn(s.Item, isOpen && s.isOpen)}>
			<h1 className={s.ItemTitle}>
				<button className={s.ItemToggle} type="button" onClick={() => setIsOpen(!isOpen)}>
					{label} <span className={s.ItemToggleIcon} />
				</button>
			</h1>
			<div className={s.ItemContent}>{content}</div>
		</section>
	)
}

export const Accordion: React.FunctionComponent<AccordionProps> = ({ items }) => {
	return (
		<div className={s.Accordion}>
			<Part>
				{items.map((item, i) => (
					<Item key={i} {...item} preopen={i === 0 || item.preopen} />
				))}
			</Part>
		</div>
	)
}
