import Link from 'next/link'
import React from 'react'
import s from './JsonContent.module.sass'

type InlineChild = {
	text: string
	isBold?: boolean
	type?: 'anchor'
	href?: string
	children?: InlineChild[]
}

type JsonRendererChildren = {
	type: 'paragraph'
	children: InlineChild[]
}[]

function renderChildren(children: InlineChild[]) {
	return children.map((span, l) => {
		let el: React.ReactNode = span.children ? renderChildren(span.children) : span.text
		if (span.isBold) {
			el = <strong>{el}</strong>
		}
		if (span.type === 'anchor' && span.href) {
			el = (
				<Link href={span.href}>
					<a>{el}</a>
				</Link>
			)
		}
		return <React.Fragment key={l}>{el}</React.Fragment>
	})
}

export const JsonContent = React.memo(function JsonContent(props: { jsonContent: string }) {
	const { jsonContent } = props
	const blocks = React.useMemo(() => {
		try {
			const data = JSON.parse(jsonContent)

			return data as {
				formatVersion: number
				children: JsonRendererChildren
			}
		} catch (e) {}
	}, [jsonContent])

	if (blocks?.formatVersion !== 0) {
		return null
	}

	return (
		<div className={s.JsonContent}>
			{blocks?.children.map((block, b) => (
				<React.Fragment key={b}>
					{block.type === 'paragraph' && (
						<p key={b}>
							{block.children.length === 1 && block.children[0].text === '' ? (
								<>&nbsp;</>
							) : null}
							{renderChildren(block.children)}
						</p>
					)}
				</React.Fragment>
			))}
		</div>
	)
})
