import cn from 'clsx'
import React from 'react'
import { AspectRatio } from '../AspectRatio'
import { Image } from '../Image'
import { Tr } from '../Tr'
import s from './MediaBlock.module.sass'
import { Part } from './Part'
import { TextContent } from './TextContent'

const videoMimes = {
	opus: 'video/ogg',
	ogv: 'video/ogg',
	mp4: 'video/mp4',
	mov: 'video/mp4',
	qt: 'video/mp4',
	m4v: 'video/mp4',
	mkv: 'video/x-matroska',
	m4a: 'audio/mp4',
	mp3: 'audio/mpeg',
	aac: 'audio/aac',
	caf: 'audio/x-caf',
	flac: 'audio/flac',
	oga: 'audio/ogg',
	wav: 'audio/wav',
	m3u8: 'application/x-mpegURL',
	jpg: 'image/jpeg',
	jpeg: 'image/jpeg',
	gif: 'image/gif',
	png: 'image/png',
	svg: 'image/svg+xml',
	webp: 'image/webp',
}

const Video = React.memo(function Video(props: {
	url: string
	width: number
	height: number
	audio?: boolean
}) {
	const [muted, setMuted] = React.useState<null | boolean>(null)

	const url = props.url
	const audio = props.audio
	const type = React.useMemo(() => {
		const match = url.match(/\.([a-z0-9]+)$/i)
		if (match) {
			const ext = match[1] as keyof typeof videoMimes
			const mime = videoMimes[ext] ?? null
			return { ext, mime }
		}
	}, [url])

	return type ? (
		<AspectRatio ratio={props.width / props.height}>
			<video
				muted
				loop
				playsInline
				autoPlay
				className={s.Video}
				ref={(el) => {
					if (el) {
						setMuted(el.muted)
					}
				}}
				onClick={(e) => {
					const el = e.currentTarget
					const paused = el.paused

					if (paused) {
						el.play()
					} else {
						if (el.muted && audio) {
							el.muted = false
						} else {
							el.pause()
						}
					}
					setMuted(el.muted)
				}}>
				<source src={props.url} type={type.mime} />
			</video>
			{muted && audio && (
				<div className={s.UnmuteContainer}>
					<span className={s.Unmute}>
						<Tr ns="common" t="unmute" />
					</span>
				</div>
			)}
		</AspectRatio>
	) : null
})

export const MediaBlock = React.memo(function MediaBlock(props: {
	mediaSide?: 'left' | 'right'
	title: React.ReactNode
	imageCaption?: string
	content?: React.ReactNode
	action?: React.ReactNode
	image?: {
		width?: number
		height?: number
		url?: string
		videoUrl?: string
		type?: 'video' | 'image'
		alt?: string
		audio?: boolean
	}
}) {
	const mediaSide = props.mediaSide ?? 'right'

	return (
		<section className={cn(s.MediaBlock, s[mediaSide])}>
			<Part>
				<div className={s.Cols}>
					{props.image && (props.image.url || props.image.videoUrl) && (
						<div className={s.Col}>
							{props.image?.type === 'video' && props.image.videoUrl ? (
								<Video
									url={props.image.videoUrl}
									audio={props.image.audio}
									width={props.image.width ?? 600}
									height={props.image.height ?? 600}
								/>
							) : (
								props.image.url && (
									<Image
										src={props.image.url}
										loading="lazy"
										alt={props.imageCaption ?? props.image.alt ?? 'photo'}
										width={props.image.width ?? 600}
										height={props.image.height ?? 600}
										layout="responsive"
									/>
								)
							)}
						</div>
					)}
					<div className={s.Col}>
						<div className={s.Text}>
							<h1 className={s.Title}>{props.title}</h1>
							<TextContent strongs="red">{props.content}</TextContent>
						</div>
						{props.action && <div className={s.Action}>{props.action}</div>}
					</div>
				</div>
			</Part>
		</section>
	)
})
