import React from 'react'
import s from './AspectRatio.module.sass'
import cn from 'clsx'

export const AspectRatio = React.memo(function AspectRatio(props: {
	ratio: number
	className?: string
	children: React.ReactNode
	style?: React.CSSProperties
}) {
	const { ratio, className, children, style } = props
	const ratioStyle = React.useMemo((): React.CSSProperties => {
		return {
			paddingTop: `${(1 / ratio) * 100}%`,
		}
	}, [ratio])
	return (
		<div className={cn(s.Container, className)} style={style}>
			<div className={s.Root}>
				<div className={s.Ratio} style={ratioStyle}></div>
				<div className={s.Content}>{children}</div>
			</div>
		</div>
	)
})
