import cn from 'clsx'
import React from 'react'
import { useInView } from 'react-hook-inview'
import { useAllImagesLoadedInside } from '../../hooks/useAllImagesLoadedInside'
import { useClotinoMotive } from '../../hooks/useClotinoMotive'
import { useStorefrontProductVariantInfo } from '../../hooks/useStorefrontProductsInfo'
import { findMotive } from '../../loaders/findMotive'
import { ContentResult } from '../../loaders/loadShop'
import { IconSetContext } from '../../pages/[...fallback]'
import {
	createCustomization,
	customizationToAttributes,
} from '../../utils/customization/customization'
import { AddToCartButton } from '../AddToCartButton'
import { ImageSizesContext } from '../Image'
import { ImageWithOverlay, ImageWithOverlayBigContext, OverlayPresets } from '../ImageWithOverlay'
import { PaymentProviderList } from '../PaymentProviderList'
import { CompareAtPriceV2, CompareAtPriceV2Placeholder, PriceV2 } from '../PriceV2'
import { StampIcon } from '../StampImg'
import { Tr, useTranslation } from '../Tr'
import { BonusProduct } from './BonusProduct'
import { ClotinoIconPicker } from './ClotinoIconPicker'
import s from './ConfigurableProduct.module.sass'
import { JsonContent } from './JsonContent'
import { ProductBadge } from './ProductBadge'
import { TextContent } from './TextContent'
import { VariantPicker } from './VariantPicker'

const FadeInOnImagesLoad = React.memo(function FadeInOnImagesLoad(props: {
	children: React.ReactNode
}) {
	const [ref, loaded] = useAllImagesLoadedInside()

	const style = React.useMemo(() => {
		return {
			willChange: 'opacity',
			transition: 'opacity ease-out .4s',
			opacity: loaded ? 1 : 0,
		}
	}, [loaded])

	return (
		<div ref={ref} style={style}>
			{props.children}
		</div>
	)
})

function StampPreviews(props: {
	productType: string
	bonus?: React.ReactNode
	badge?: React.ReactNode
	gallery?: NonNullable<
		NonNullable<
			NonNullable<ContentResult['blocks'][number]['product']>['localesByLocale']
		>['gallery']
	>['items']
}) {
	const { gallery } = props
	const views: React.ReactNode[] = React.useMemo(() => {
		if (gallery?.length) {
			return gallery
				.map((image, i) => {
					if (!image?.image) {
						return null
					}
					const preset = image.image.decorationPreset as
						| keyof typeof OverlayPresets
						| undefined
					return (
						<ImageWithOverlay
							key={i}
							image={image.image}
							preset={
								preset && preset in OverlayPresets
									? OverlayPresets[preset]
									: undefined
							}
						/>
					)
				})
				.filter(Boolean)
		}
		return []
	}, [gallery])

	const [view, setView] = React.useState(0)

	const activeImage = views[Math.min(view, views.length - 1)]

	return (
		<div>
			<div className={s.Preview}>
				<div className={s.Image}>
					<ImageSizesContext.Provider
						value={`(min-width: 1334px) 640px, (min-width: 800px) 50vw, 100vw`}>
						<ImageWithOverlayBigContext.Provider value={true}>
							<FadeInOnImagesLoad key={view}>{activeImage}</FadeInOnImagesLoad>
						</ImageWithOverlayBigContext.Provider>
					</ImageSizesContext.Provider>
				</div>
				{props.badge && <div className={s.Badge}>{props.badge}</div>}
				{props.bonus && <div className={s.Bonus}>{props.bonus}</div>}
			</div>

			<ImageSizesContext.Provider
				value={`(min-width: 1334px) 144px, (min-width: 800px) 13vw, 25vw`}>
				{views.length > 1 && (
					<div className={s.PreviewsLibrary}>
						{views.map((image, v) => (
							<div key={v} className={s.Item} onClick={() => setView(v)}>
								<div className={s.Image}>{image}</div>
							</div>
						))}
					</div>
				)}
			</ImageSizesContext.Provider>
		</div>
	)
}

function useCustomizationAttributes(icon: string | null, text: string) {
	const iconSet = React.useContext(IconSetContext)
	const found = findMotive(iconSet ?? [], icon ?? '-', text)

	const iconKey = useTranslation('motive', 'icon')
	const labelKey = useTranslation('motive', 'label')

	if (!found) {
		return []
	}

	const customization = createCustomization(
		{ [iconKey]: found.icon.title, [labelKey]: text },
		found
	)

	return customizationToAttributes(customization, 'key') as { key: string; value: string }[]
}

export const ConfigurableProduct = React.memo(function ConfigurableProduct(props: {
	block: ContentResult['blocks'][number]
	mediaPosition?: 'left' | 'right'
	isPrimary?: boolean
}) {
	const { block } = props
	const { product } = block

	if (!product) {
		throw new Error()
	}

	const { setIcon, setLabel, icon, label, visibleIcon, placeholderLabel } = useClotinoMotive()

	const [isOpen, setIsOpen] = React.useState(props.isPrimary)

	const toggleOpen = React.useCallback(() => setIsOpen(true), [])

	const mediaPosition = props.mediaPosition ?? 'left'

	const productVariants = product.productVariants

	const sortedVariants = React.useMemo(() => {
		const sorted = [...productVariants]
		sorted.sort((a, b) => {
			return (a.storesByStore?.price ?? Infinity) - (b.storesByStore?.price ?? Infinity)
		})
		return sorted
	}, [productVariants])

	const [selectedVariant, setSelectedVariant] = React.useState(sortedVariants[0])

	const variantInfo = useStorefrontProductVariantInfo(selectedVariant?.shopifySku)

	const hasMultipleVariants = sortedVariants.length > 1

	const [ref, isVisible] = useInView({
		threshold: 1,
	})

	const inputRef = React.useRef<null | HTMLInputElement>(null)

	React.useEffect(() => {
		if (isVisible && inputRef.current) {
			inputRef.current.focus({ preventScroll: true })
			inputRef.current.setSelectionRange(999, 999, 'forward')
		}
	}, [isVisible])

	const combinedGallery = React.useMemo(() => {
		const gallery = [
			...(selectedVariant.localesByLocale?.gallery?.items ?? []),
			...(product.localesByLocale?.gallery?.items ?? []),
		]
		return gallery
	}, [product.localesByLocale?.gallery?.items, selectedVariant.localesByLocale?.gallery?.items])

	const customAttributes = useCustomizationAttributes(icon ?? null, label ?? '')

	return (
		<section
			id={variantInfo?.sku}
			className={cn(
				s.ConfigurableProduct,
				s[`view-mediaPosition-${mediaPosition}`],
				props.isPrimary ? s['view-isPrimary'] : s['view-isSecondary'],
				isOpen && s['view-isOpen'],
				block.secondaryText && block.tertiaryText && s['view-withBonus']
			)}>
			<div className={s.Cols} id={block.htmlId ? block.htmlId.replace('#', '') : undefined}>
				<div className={cn(s.Col, s.MediaCol)}>
					{product.productType && (
						<div className={s.ColPreview}>
							<StampPreviews
								gallery={combinedGallery}
								productType={product.productType}
								badge={
									block.image && !block.secondaryText && !block.tertiaryText ? (
										<ProductBadge image={block.image} />
									) : undefined
								}
								bonus={
									block.secondaryText &&
									block.tertiaryText && (
										<BonusProduct
											image={block.image}
											title={block.secondaryText}
											subtitle={block.tertiaryText}
										/>
									)
								}
							/>
						</div>
					)}
				</div>
				<div className={cn(s.Col, s.MainCol)}>
					<div className={s.Info}>
						<div className={s.Lead}>
							<h1 className={s.LeadTitle}>{block.primaryText}</h1>

							{block.jsonContent && (
								<div className={s.LeadText}>
									<TextContent>
										<JsonContent jsonContent={block.jsonContent} />
									</TextContent>
								</div>
							)}
						</div>

						{!props.isPrimary && product.productType && (
							<div className={s.MidPreview}>
								<StampPreviews
									gallery={combinedGallery}
									productType={product.productType}
									bonus={
										block.secondaryText &&
										block.tertiaryText && (
											<BonusProduct
												image={block.image}
												title={block.secondaryText}
												subtitle={block.tertiaryText}
											/>
										)
									}
								/>
							</div>
						)}
						<div ref={ref}>
							<div className={s.TextInputControl}>
								{visibleIcon && visibleIcon !== '-' && (
									<div className={s.Icon} onClick={toggleOpen}>
										<StampIcon icon={visibleIcon} />
									</div>
								)}
								<div className={s.Input}>
									<input
										type="text"
										ref={inputRef}
										className={s.TextInput}
										placeholder={placeholderLabel}
										value={label ?? ''}
										onChange={(e) => {
											toggleOpen()
											setLabel(e.currentTarget.value)
										}}
										onClick={toggleOpen}
									/>
								</div>
								{!isOpen && (
									<div className={s.ToggleEdit}>
										<span className={s.ToggleEditButton} onClick={toggleOpen}>
											edit
										</span>
									</div>
								)}
							</div>
							{isOpen && (
								<>
									<div className={s.IconPicker}>
										<ClotinoIconPicker
											value={icon ?? null}
											onChange={setIcon}
										/>
									</div>
								</>
							)}
						</div>
						{hasMultipleVariants && (
							<div>
								<h2 className={s.VariantsTitle}>
									<Tr ns="common" t="amount_of_stickers" />
								</h2>
								<VariantPicker
									variants={sortedVariants}
									setSelectedVariant={setSelectedVariant}
									selectedVariant={selectedVariant}
								/>
							</div>
						)}

						<div className={s.BuyOptions}>
							<div className={s.BuyControl}>
								<div className={s.Pricing}>
									<span className={s.Price}>
										{' '}
										{variantInfo && variantInfo.price ? (
											<CompareAtPriceV2
												price={variantInfo.price}
												compareAtPrice={variantInfo.compareAtPrice}
											/>
										) : (
											<CompareAtPriceV2Placeholder />
										)}
									</span>{' '}
									<span className={s.Vat}>
										<BelowPrice
											price={variantInfo?.price}
											compareAtPrice={variantInfo?.compareAtPrice}
										/>{' '}
										<span className={s.Note}>
											<Tr ns="commerce" t="price_note" />
										</span>
									</span>
								</div>

								{selectedVariant.storesByStore?.storefrontId && (
									<div className={s.ButtonAndPaymentProviders}>
										<div className={s.Button}>
											<AddToCartButton
												disabled={
													variantInfo?.price &&
													Boolean(!label || label.length === 0)
												}
												block
												sku={selectedVariant.shopifySku}
												variantId={
													selectedVariant.storesByStore.storefrontId
												}
												customAttributes={customAttributes}>
												<Tr ns="commerce" t="add_to_cart" />
											</AddToCartButton>
										</div>
										<div className={s.PaymentProviders}>
											<PaymentProviderList />
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
})

function BelowPrice(props: {
	price?: ShopifyBuy.PriceV2 | null
	compareAtPrice?: ShopifyBuy.PriceV2 | null
}) {
	const { price, compareAtPrice } = props
	const discount = React.useMemo(() => {
		if (price && compareAtPrice) {
			const diff = Number(compareAtPrice.amount) - Number(price.amount)
			if (diff > 0) {
				return {
					amount: diff.toFixed(2),
					currencyCode: price.currencyCode,
				}
			}
		}
	}, [price, compareAtPrice])

	const withDiscount = useTranslation('commerce', 'below_price_with_discount')

	return (
		<>
			{discount ? (
				<JoinChildren
					items={withDiscount.split('{discount}')}
					glue={<PriceV2 price={discount} />}
				/>
			) : (
				<Tr ns="commerce" t="below_price" />
			)}
		</>
	)
}

function JoinChildren(props: { items: React.ReactNode[]; glue: React.ReactNode }) {
	return (
		<>
			{props.items.map((item, i) => (
				<React.Fragment key={i}>
					{i !== 0 && props.glue}
					{item}
				</React.Fragment>
			))}
		</>
	)
}
