import cn from 'clsx'
import React from 'react'
import { trackInitiateCheckout } from '../utils/analytics/analytics'
import { ClotinoStore } from '../utils/clotino/Stores'
import { Button } from './clotino/Button'
import { CartContext } from './ConnectedCart'
import { PaymentProviderList } from './PaymentProviderList'
import { Price } from './PriceV2'
import { SectionTitle } from './SectionTitle'
import { ExpressCheckout } from './stripe/ExpressCheckout'
import { StripeProvider } from './stripe/StripeProvider'
import s from './Summary.module.sass'
import { Tr } from './Tr'

export interface SummaryProps {
	itemsCount: number
	currency: ClotinoStore
	subtotalCents: number
	shippingCents: number
	totalCents: number
	shopifyUrl: string
	expressCheckout?: React.ReactNode
	onDismiss?: () => void
}

function SummaryFinisher(props: { checkoutUrl: string; onDismiss?: () => void }) {
	const [hasExpressCheckout, setHasExpressCheckout] = React.useState<null | boolean>(null)
	const cart = React.useContext(CartContext)
	return (
		<div
			className={cn(
				s.SummaryFinisher,
				hasExpressCheckout === null
					? s.detectingExpressCheckout
					: hasExpressCheckout
					? s.withExpressCheckout
					: s.withoutExpressCheckout
			)}>
			<div className={s.ExpressCheckout}>
				<StripeProvider>
					{cart && <ExpressCheckout cart={cart} onAvailability={setHasExpressCheckout} />}
				</StripeProvider>
			</div>
			<div className={s.Return}>
				<Button block color="blue" outline sharp onClick={props.onDismiss}>
					<Tr ns="commerce" t="back_to_page" />
				</Button>
			</div>
			<div className={s.Pay}>
				<Button
					onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
						e.preventDefault()
						trackInitiateCheckout()
						const href = e.currentTarget.href
						setTimeout(() => {
							window.location.href = href
						}, 300)
					}}
					href={props.checkoutUrl}
					block
					color="green"
					emphasized
					sharp>
					<Tr ns="commerce" t="check_out_now" />
				</Button>
			</div>
			<div className={s.Icons}>
				<PaymentProviderList />
			</div>
		</div>
	)
}

export const Summary: React.FunctionComponent<SummaryProps> = ({
	currency,
	shippingCents,
	subtotalCents,
	totalCents,
	itemsCount,
	shopifyUrl,
	onDismiss,
}) => (
	<div className={s.Summary}>
		<div className={s.Title}>
			<SectionTitle>
				<Tr ns="commerce" t="your_cart" />: {itemsCount}
			</SectionTitle>
		</div>
		<dl className={s.Details}>
			<dt className={s.Label}>
				<Tr ns="commerce" t="order_subtotal" />:
			</dt>
			<dd className={s.Value}>
				<Price currencyCode={currency} cents={subtotalCents} />
			</dd>
			<dt className={s.Label}>
				<Tr ns="commerce" t="shipping_worldwide" />:
			</dt>
			<dd className={s.Value}>
				<Price currencyCode={currency} cents={shippingCents} />
			</dd>
			<dt className={s.Label}>
				<Tr ns="commerce" t="total" />{' '}
				<span className={s.Note}>
					(<Tr ns="commerce" t="including_vat" />)
				</span>
			</dt>
			<dd className={s.Value}>
				<Price currencyCode={currency} cents={totalCents} />
			</dd>
		</dl>
		<div className={s.Finisher}>
			<SummaryFinisher
				checkoutUrl={shopifyUrl}
				onDismiss={() => {
					console.log('dismis')
					onDismiss?.()
				}}
			/>
		</div>
	</div>
)
