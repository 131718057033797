/* tslint:disable */
/* eslint-disable */

export type ValueTypes = {
    ["_AnyValue"]: AliasType<{		["...on _IntValue"] : ValueTypes["_IntValue"],
		["...on _StringValue"] : ValueTypes["_StringValue"],
		["...on _BooleanValue"] : ValueTypes["_BooleanValue"],
		["...on _FloatValue"] : ValueTypes["_FloatValue"],
		["...on _UndefinedValue"] : ValueTypes["_UndefinedValue"]
		__typename?: true
}>;
	["_Argument"]: AliasType<{		["...on _ValidatorArgument"] : ValueTypes["_ValidatorArgument"],
		["...on _PathArgument"] : ValueTypes["_PathArgument"],
		["...on _LiteralArgument"] : ValueTypes["_LiteralArgument"]
		__typename?: true
}>;
	["_BooleanValue"]: AliasType<{
	booleanValue?:true,
		__typename?: true
}>;
	["_Entity"]: AliasType<{
	name?:true,
	fields?:ValueTypes["_Field"],
		__typename?: true
}>;
	["_Enum"]: AliasType<{
	name?:true,
	values?:true,
		__typename?: true
}>;
	["_Field"]: AliasType<{
	name?:true,
	type?:true,
	rules?:ValueTypes["_Rule"],
	validators?:ValueTypes["_Validator"],
		__typename?: true
}>;
	["_FieldPathFragment"]: AliasType<{
	field?:true,
		__typename?: true
}>;
	["_FloatValue"]: AliasType<{
	floatValue?:true,
		__typename?: true
}>;
	["_IndexPathFragment"]: AliasType<{
	index?:true,
	alias?:true,
		__typename?: true
}>;
	["_IntValue"]: AliasType<{
	intValue?:true,
		__typename?: true
}>;
	["_LiteralArgument"]: AliasType<{
	value?:ValueTypes["_AnyValue"],
		__typename?: true
}>;
	["_MutationError"]: AliasType<{
	path?:ValueTypes["_PathFragment"],
	type?:true,
	message?:true,
		__typename?: true
}>;
	["_MutationErrorType"]:_MutationErrorType;
	["_PathArgument"]: AliasType<{
	path?:true,
		__typename?: true
}>;
	["_PathFragment"]: AliasType<{		["...on _FieldPathFragment"] : ValueTypes["_FieldPathFragment"],
		["...on _IndexPathFragment"] : ValueTypes["_IndexPathFragment"]
		__typename?: true
}>;
	["_Rule"]: AliasType<{
	message?:ValueTypes["_RuleMessage"],
	validator?:true,
		__typename?: true
}>;
	["_RuleMessage"]: AliasType<{
	text?:true,
		__typename?: true
}>;
	["_Schema"]: AliasType<{
	enums?:ValueTypes["_Enum"],
	entities?:ValueTypes["_Entity"],
		__typename?: true
}>;
	["_StringValue"]: AliasType<{
	stringValue?:true,
		__typename?: true
}>;
	["_UndefinedValue"]: AliasType<{
	undefinedValue?:true,
		__typename?: true
}>;
	["_ValidationError"]: AliasType<{
	path?:ValueTypes["_PathFragment"],
	message?:ValueTypes["_ValidationMessage"],
		__typename?: true
}>;
	["_ValidationMessage"]: AliasType<{
	text?:true,
		__typename?: true
}>;
	["_ValidationResult"]: AliasType<{
	valid?:true,
	errors?:ValueTypes["_ValidationError"],
		__typename?: true
}>;
	["_Validator"]: AliasType<{
	operation?:true,
	arguments?:ValueTypes["_Argument"],
		__typename?: true
}>;
	["_ValidatorArgument"]: AliasType<{
	validator?:true,
		__typename?: true
}>;
	["AbandonedCheckoutNotification"]: AliasType<{
	_meta?:ValueTypes["AbandonedCheckoutNotificationMeta"],
	id?:true,
	createdAt?:true,
	sentAt?:true,
	tryCount?:true,
	store?:true,
	shopifyId?:true,
	orderJson?:true,
	metadata?:true,
	status?:true,
		__typename?: true
}>;
	["AbandonedCheckoutNotificationConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AbandonedCheckoutNotificationEdge"],
		__typename?: true
}>;
	["AbandonedCheckoutNotificationCreateInput"]: {
	createdAt?:ValueTypes["DateTime"],
	sentAt?:ValueTypes["DateTime"],
	tryCount?:number,
	store?:string,
	shopifyId?:string,
	orderJson?:string,
	metadata?:string,
	status?:string,
	_dummy_field_?:boolean
};
	["AbandonedCheckoutNotificationCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["AbandonedCheckoutNotification"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["AbandonedCheckoutNotificationDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["AbandonedCheckoutNotification"],
		__typename?: true
}>;
	["AbandonedCheckoutNotificationEdge"]: AliasType<{
	node?:ValueTypes["AbandonedCheckoutNotification"],
		__typename?: true
}>;
	["AbandonedCheckoutNotificationMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	sentAt?:ValueTypes["FieldMeta"],
	tryCount?:ValueTypes["FieldMeta"],
	store?:ValueTypes["FieldMeta"],
	shopifyId?:ValueTypes["FieldMeta"],
	orderJson?:ValueTypes["FieldMeta"],
	metadata?:ValueTypes["FieldMeta"],
	status?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["AbandonedCheckoutNotificationOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	createdAt?:ValueTypes["OrderDirection"],
	sentAt?:ValueTypes["OrderDirection"],
	tryCount?:ValueTypes["OrderDirection"],
	store?:ValueTypes["OrderDirection"],
	shopifyId?:ValueTypes["OrderDirection"],
	orderJson?:ValueTypes["OrderDirection"],
	metadata?:ValueTypes["OrderDirection"],
	status?:ValueTypes["OrderDirection"]
};
	["AbandonedCheckoutNotificationUniqueWhere"]: {
	id?:ValueTypes["UUID"]
};
	["AbandonedCheckoutNotificationUpdateInput"]: {
	createdAt?:ValueTypes["DateTime"],
	sentAt?:ValueTypes["DateTime"],
	tryCount?:number,
	store?:string,
	shopifyId?:string,
	orderJson?:string,
	metadata?:string,
	status?:string,
	_dummy_field_?:boolean
};
	["AbandonedCheckoutNotificationUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["AbandonedCheckoutNotification"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["AbandonedCheckoutNotificationWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	createdAt?:ValueTypes["DateTimeCondition"],
	sentAt?:ValueTypes["DateTimeCondition"],
	tryCount?:ValueTypes["IntCondition"],
	store?:ValueTypes["StringCondition"],
	shopifyId?:ValueTypes["StringCondition"],
	orderJson?:ValueTypes["StringCondition"],
	metadata?:ValueTypes["StringCondition"],
	status?:ValueTypes["StringCondition"],
	and?:ValueTypes["AbandonedCheckoutNotificationWhere"][],
	or?:ValueTypes["AbandonedCheckoutNotificationWhere"][],
	not?:ValueTypes["AbandonedCheckoutNotificationWhere"]
};
	["AbandonedCheckoutUnsubscription"]: AliasType<{
	_meta?:ValueTypes["AbandonedCheckoutUnsubscriptionMeta"],
	id?:true,
	createdAt?:true,
	canceledAt?:true,
	email?:true,
		__typename?: true
}>;
	["AbandonedCheckoutUnsubscriptionConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AbandonedCheckoutUnsubscriptionEdge"],
		__typename?: true
}>;
	["AbandonedCheckoutUnsubscriptionCreateInput"]: {
	createdAt?:ValueTypes["DateTime"],
	canceledAt?:ValueTypes["DateTime"],
	email?:string,
	_dummy_field_?:boolean
};
	["AbandonedCheckoutUnsubscriptionCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["AbandonedCheckoutUnsubscription"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["AbandonedCheckoutUnsubscriptionDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["AbandonedCheckoutUnsubscription"],
		__typename?: true
}>;
	["AbandonedCheckoutUnsubscriptionEdge"]: AliasType<{
	node?:ValueTypes["AbandonedCheckoutUnsubscription"],
		__typename?: true
}>;
	["AbandonedCheckoutUnsubscriptionMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	canceledAt?:ValueTypes["FieldMeta"],
	email?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["AbandonedCheckoutUnsubscriptionOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	createdAt?:ValueTypes["OrderDirection"],
	canceledAt?:ValueTypes["OrderDirection"],
	email?:ValueTypes["OrderDirection"]
};
	["AbandonedCheckoutUnsubscriptionUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	email?:string
};
	["AbandonedCheckoutUnsubscriptionUpdateInput"]: {
	createdAt?:ValueTypes["DateTime"],
	canceledAt?:ValueTypes["DateTime"],
	email?:string,
	_dummy_field_?:boolean
};
	["AbandonedCheckoutUnsubscriptionUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["AbandonedCheckoutUnsubscription"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["AbandonedCheckoutUnsubscriptionWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	createdAt?:ValueTypes["DateTimeCondition"],
	canceledAt?:ValueTypes["DateTimeCondition"],
	email?:ValueTypes["StringCondition"],
	and?:ValueTypes["AbandonedCheckoutUnsubscriptionWhere"][],
	or?:ValueTypes["AbandonedCheckoutUnsubscriptionWhere"][],
	not?:ValueTypes["AbandonedCheckoutUnsubscriptionWhere"]
};
	["AdminNote"]: AliasType<{
	_meta?:ValueTypes["AdminNoteMeta"],
	id?:true,
	createdAt?:true,
	name?:true,
	data?:true,
		__typename?: true
}>;
	["AdminNoteConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["AdminNoteEdge"],
		__typename?: true
}>;
	["AdminNoteCreateInput"]: {
	createdAt?:ValueTypes["DateTime"],
	name?:string,
	data?:string,
	_dummy_field_?:boolean
};
	["AdminNoteCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["AdminNote"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["AdminNoteDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["AdminNote"],
		__typename?: true
}>;
	["AdminNoteEdge"]: AliasType<{
	node?:ValueTypes["AdminNote"],
		__typename?: true
}>;
	["AdminNoteMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	data?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["AdminNoteOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	createdAt?:ValueTypes["OrderDirection"],
	name?:ValueTypes["OrderDirection"],
	data?:ValueTypes["OrderDirection"]
};
	["AdminNoteUniqueWhere"]: {
	id?:ValueTypes["UUID"]
};
	["AdminNoteUpdateInput"]: {
	createdAt?:ValueTypes["DateTime"],
	name?:string,
	data?:string,
	_dummy_field_?:boolean
};
	["AdminNoteUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["AdminNote"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["AdminNoteWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	createdAt?:ValueTypes["DateTimeCondition"],
	name?:ValueTypes["StringCondition"],
	data?:ValueTypes["StringCondition"],
	and?:ValueTypes["AdminNoteWhere"][],
	or?:ValueTypes["AdminNoteWhere"][],
	not?:ValueTypes["AdminNoteWhere"]
};
	["BooleanCondition"]: {
	and?:ValueTypes["BooleanCondition"][],
	or?:ValueTypes["BooleanCondition"][],
	not?:ValueTypes["BooleanCondition"],
	eq?:boolean,
	null?:boolean,
	isNull?:boolean,
	notEq?:boolean,
	in?:boolean[],
	notIn?:boolean[],
	lt?:boolean,
	lte?:boolean,
	gt?:boolean,
	gte?:boolean
};
	["Content"]: AliasType<{
	_meta?:ValueTypes["ContentMeta"],
	id?:true,
blocks?: [{	filter?:ValueTypes["ContentBlockWhere"],	orderBy?:ValueTypes["ContentBlockOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ContentBlock"]],
page?: [{	filter?:ValueTypes["PageLocaleWhere"]},ValueTypes["PageLocale"]],
		__typename?: true
}>;
	["ContentBlock"]: AliasType<{
	_meta?:ValueTypes["ContentBlockMeta"],
	id?:true,
	order?:true,
	type?:true,
	primaryText?:true,
	secondaryText?:true,
	jsonContent?:true,
	headerView?:true,
	reviewView?:true,
content?: [{	filter?:ValueTypes["ContentWhere"]},ValueTypes["Content"]],
link?: [{	filter?:ValueTypes["LinkWhere"]},ValueTypes["Link"]],
image?: [{	filter?:ValueTypes["ImageWhere"]},ValueTypes["Image"]],
media?: [{	filter?:ValueTypes["MediaWhere"]},ValueTypes["Media"]],
product?: [{	filter?:ValueTypes["ProductWhere"]},ValueTypes["Product"]],
featureList?: [{	filter?:ValueTypes["FeatureListWhere"]},ValueTypes["FeatureList"]],
iconList?: [{	filter?:ValueTypes["IconListBlockWhere"]},ValueTypes["IconListBlock"]],
reviewList?: [{	filter?:ValueTypes["ReviewWhere"],	orderBy?:ValueTypes["ReviewOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Review"]],
faq?: [{	filter?:ValueTypes["FaqWhere"]},ValueTypes["Faq"]],
productList?: [{	filter?:ValueTypes["ProductWhere"],	orderBy?:ValueTypes["ProductOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Product"]],
	tertiaryText?:true,
	sideBySideView?:true,
mobileImage?: [{	filter?:ValueTypes["ImageWhere"]},ValueTypes["Image"]],
	htmlId?:true,
		__typename?: true
}>;
	["ContentBlockConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentBlockEdge"],
		__typename?: true
}>;
	["ContentBlockCreateContentEntityRelationInput"]: {
	connect?:ValueTypes["ContentUniqueWhere"],
	create?:ValueTypes["ContentWithoutBlocksCreateInput"]
};
	["ContentBlockCreateFaqEntityRelationInput"]: {
	connect?:ValueTypes["FaqUniqueWhere"],
	create?:ValueTypes["FaqCreateInput"]
};
	["ContentBlockCreateFeatureListEntityRelationInput"]: {
	connect?:ValueTypes["FeatureListUniqueWhere"],
	create?:ValueTypes["FeatureListCreateInput"]
};
	["ContentBlockCreateIconListEntityRelationInput"]: {
	connect?:ValueTypes["IconListBlockUniqueWhere"],
	create?:ValueTypes["IconListBlockCreateInput"]
};
	["ContentBlockCreateImageEntityRelationInput"]: {
	connect?:ValueTypes["ImageUniqueWhere"],
	create?:ValueTypes["ImageCreateInput"]
};
	["ContentBlockCreateInput"]: {
	order?:number,
	type?:ValueTypes["ContentBlockType"],
	primaryText?:string,
	secondaryText?:string,
	jsonContent?:string,
	headerView?:ValueTypes["HeaderView"],
	reviewView?:ValueTypes["ReviewView"],
	content?:ValueTypes["ContentBlockCreateContentEntityRelationInput"],
	link?:ValueTypes["ContentBlockCreateLinkEntityRelationInput"],
	image?:ValueTypes["ContentBlockCreateImageEntityRelationInput"],
	media?:ValueTypes["ContentBlockCreateMediaEntityRelationInput"],
	product?:ValueTypes["ContentBlockCreateProductEntityRelationInput"],
	featureList?:ValueTypes["ContentBlockCreateFeatureListEntityRelationInput"],
	iconList?:ValueTypes["ContentBlockCreateIconListEntityRelationInput"],
	reviewList?:ValueTypes["ContentBlockCreateReviewListEntityRelationInput"][],
	faq?:ValueTypes["ContentBlockCreateFaqEntityRelationInput"],
	productList?:ValueTypes["ContentBlockCreateProductListEntityRelationInput"][],
	tertiaryText?:string,
	sideBySideView?:ValueTypes["SideBySideView"],
	mobileImage?:ValueTypes["ContentBlockCreateMobileImageEntityRelationInput"],
	htmlId?:string,
	_dummy_field_?:boolean
};
	["ContentBlockCreateLinkEntityRelationInput"]: {
	connect?:ValueTypes["LinkUniqueWhere"],
	create?:ValueTypes["LinkCreateInput"]
};
	["ContentBlockCreateMediaEntityRelationInput"]: {
	connect?:ValueTypes["MediaUniqueWhere"],
	create?:ValueTypes["MediaCreateInput"]
};
	["ContentBlockCreateMobileImageEntityRelationInput"]: {
	connect?:ValueTypes["ImageUniqueWhere"],
	create?:ValueTypes["ImageCreateInput"]
};
	["ContentBlockCreateProductEntityRelationInput"]: {
	connect?:ValueTypes["ProductUniqueWhere"],
	create?:ValueTypes["ProductCreateInput"]
};
	["ContentBlockCreateProductListEntityRelationInput"]: {
	connect?:ValueTypes["ProductUniqueWhere"],
	create?:ValueTypes["ProductCreateInput"],
	alias?:string
};
	["ContentBlockCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ContentBlock"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ContentBlockCreateReviewListEntityRelationInput"]: {
	connect?:ValueTypes["ReviewUniqueWhere"],
	create?:ValueTypes["ReviewCreateInput"],
	alias?:string
};
	["ContentBlockDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ContentBlock"],
		__typename?: true
}>;
	["ContentBlockEdge"]: AliasType<{
	node?:ValueTypes["ContentBlock"],
		__typename?: true
}>;
	["ContentBlockMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	primaryText?:ValueTypes["FieldMeta"],
	secondaryText?:ValueTypes["FieldMeta"],
	jsonContent?:ValueTypes["FieldMeta"],
	headerView?:ValueTypes["FieldMeta"],
	reviewView?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	media?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	featureList?:ValueTypes["FieldMeta"],
	iconList?:ValueTypes["FieldMeta"],
	reviewList?:ValueTypes["FieldMeta"],
	faq?:ValueTypes["FieldMeta"],
	productList?:ValueTypes["FieldMeta"],
	tertiaryText?:ValueTypes["FieldMeta"],
	sideBySideView?:ValueTypes["FieldMeta"],
	mobileImage?:ValueTypes["FieldMeta"],
	htmlId?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ContentBlockOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	order?:ValueTypes["OrderDirection"],
	type?:ValueTypes["OrderDirection"],
	primaryText?:ValueTypes["OrderDirection"],
	secondaryText?:ValueTypes["OrderDirection"],
	jsonContent?:ValueTypes["OrderDirection"],
	headerView?:ValueTypes["OrderDirection"],
	reviewView?:ValueTypes["OrderDirection"],
	content?:ValueTypes["ContentOrderBy"],
	link?:ValueTypes["LinkOrderBy"],
	image?:ValueTypes["ImageOrderBy"],
	media?:ValueTypes["MediaOrderBy"],
	product?:ValueTypes["ProductOrderBy"],
	featureList?:ValueTypes["FeatureListOrderBy"],
	iconList?:ValueTypes["IconListBlockOrderBy"],
	faq?:ValueTypes["FaqOrderBy"],
	tertiaryText?:ValueTypes["OrderDirection"],
	sideBySideView?:ValueTypes["OrderDirection"],
	mobileImage?:ValueTypes["ImageOrderBy"],
	htmlId?:ValueTypes["OrderDirection"]
};
	["ContentBlockType"]:ContentBlockType;
	["ContentBlockTypeEnumCondition"]: {
	and?:ValueTypes["ContentBlockTypeEnumCondition"][],
	or?:ValueTypes["ContentBlockTypeEnumCondition"][],
	not?:ValueTypes["ContentBlockTypeEnumCondition"],
	eq?:ValueTypes["ContentBlockType"],
	null?:boolean,
	isNull?:boolean,
	notEq?:ValueTypes["ContentBlockType"],
	in?:ValueTypes["ContentBlockType"][],
	notIn?:ValueTypes["ContentBlockType"][],
	lt?:ValueTypes["ContentBlockType"],
	lte?:ValueTypes["ContentBlockType"],
	gt?:ValueTypes["ContentBlockType"],
	gte?:ValueTypes["ContentBlockType"]
};
	["ContentBlockUniqueWhere"]: {
	id?:ValueTypes["UUID"]
};
	["ContentBlockUpdateContentEntityRelationInput"]: {
	create?:ValueTypes["ContentWithoutBlocksCreateInput"],
	update?:ValueTypes["ContentWithoutBlocksUpdateInput"],
	upsert?:ValueTypes["ContentBlockUpsertContentRelationInput"],
	connect?:ValueTypes["ContentUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["ContentBlockUpdateFaqEntityRelationInput"]: {
	create?:ValueTypes["FaqCreateInput"],
	update?:ValueTypes["FaqUpdateInput"],
	upsert?:ValueTypes["ContentBlockUpsertFaqRelationInput"],
	connect?:ValueTypes["FaqUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["ContentBlockUpdateFeatureListEntityRelationInput"]: {
	create?:ValueTypes["FeatureListCreateInput"],
	update?:ValueTypes["FeatureListUpdateInput"],
	upsert?:ValueTypes["ContentBlockUpsertFeatureListRelationInput"],
	connect?:ValueTypes["FeatureListUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["ContentBlockUpdateIconListEntityRelationInput"]: {
	create?:ValueTypes["IconListBlockCreateInput"],
	update?:ValueTypes["IconListBlockUpdateInput"],
	upsert?:ValueTypes["ContentBlockUpsertIconListRelationInput"],
	connect?:ValueTypes["IconListBlockUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["ContentBlockUpdateImageEntityRelationInput"]: {
	create?:ValueTypes["ImageCreateInput"],
	update?:ValueTypes["ImageUpdateInput"],
	upsert?:ValueTypes["ContentBlockUpsertImageRelationInput"],
	connect?:ValueTypes["ImageUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["ContentBlockUpdateInput"]: {
	order?:number,
	type?:ValueTypes["ContentBlockType"],
	primaryText?:string,
	secondaryText?:string,
	jsonContent?:string,
	headerView?:ValueTypes["HeaderView"],
	reviewView?:ValueTypes["ReviewView"],
	content?:ValueTypes["ContentBlockUpdateContentEntityRelationInput"],
	link?:ValueTypes["ContentBlockUpdateLinkEntityRelationInput"],
	image?:ValueTypes["ContentBlockUpdateImageEntityRelationInput"],
	media?:ValueTypes["ContentBlockUpdateMediaEntityRelationInput"],
	product?:ValueTypes["ContentBlockUpdateProductEntityRelationInput"],
	featureList?:ValueTypes["ContentBlockUpdateFeatureListEntityRelationInput"],
	iconList?:ValueTypes["ContentBlockUpdateIconListEntityRelationInput"],
	reviewList?:ValueTypes["ContentBlockUpdateReviewListEntityRelationInput"][],
	faq?:ValueTypes["ContentBlockUpdateFaqEntityRelationInput"],
	productList?:ValueTypes["ContentBlockUpdateProductListEntityRelationInput"][],
	tertiaryText?:string,
	sideBySideView?:ValueTypes["SideBySideView"],
	mobileImage?:ValueTypes["ContentBlockUpdateMobileImageEntityRelationInput"],
	htmlId?:string,
	_dummy_field_?:boolean
};
	["ContentBlockUpdateLinkEntityRelationInput"]: {
	create?:ValueTypes["LinkCreateInput"],
	update?:ValueTypes["LinkUpdateInput"],
	upsert?:ValueTypes["ContentBlockUpsertLinkRelationInput"],
	connect?:ValueTypes["LinkUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["ContentBlockUpdateMediaEntityRelationInput"]: {
	create?:ValueTypes["MediaCreateInput"],
	update?:ValueTypes["MediaUpdateInput"],
	upsert?:ValueTypes["ContentBlockUpsertMediaRelationInput"],
	connect?:ValueTypes["MediaUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["ContentBlockUpdateMobileImageEntityRelationInput"]: {
	create?:ValueTypes["ImageCreateInput"],
	update?:ValueTypes["ImageUpdateInput"],
	upsert?:ValueTypes["ContentBlockUpsertMobileImageRelationInput"],
	connect?:ValueTypes["ImageUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["ContentBlockUpdateProductEntityRelationInput"]: {
	create?:ValueTypes["ProductCreateInput"],
	update?:ValueTypes["ProductUpdateInput"],
	upsert?:ValueTypes["ContentBlockUpsertProductRelationInput"],
	connect?:ValueTypes["ProductUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["ContentBlockUpdateProductListEntityRelationInput"]: {
	create?:ValueTypes["ProductCreateInput"],
	update?:ValueTypes["ContentBlockUpdateProductListRelationInput"],
	upsert?:ValueTypes["ContentBlockUpsertProductListRelationInput"],
	connect?:ValueTypes["ProductUniqueWhere"],
	disconnect?:ValueTypes["ProductUniqueWhere"],
	delete?:ValueTypes["ProductUniqueWhere"],
	alias?:string
};
	["ContentBlockUpdateProductListRelationInput"]: {
	by?:ValueTypes["ProductUniqueWhere"],
	data?:ValueTypes["ProductUpdateInput"]
};
	["ContentBlockUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ContentBlock"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ContentBlockUpdateReviewListEntityRelationInput"]: {
	create?:ValueTypes["ReviewCreateInput"],
	update?:ValueTypes["ContentBlockUpdateReviewListRelationInput"],
	upsert?:ValueTypes["ContentBlockUpsertReviewListRelationInput"],
	connect?:ValueTypes["ReviewUniqueWhere"],
	disconnect?:ValueTypes["ReviewUniqueWhere"],
	delete?:ValueTypes["ReviewUniqueWhere"],
	alias?:string
};
	["ContentBlockUpdateReviewListRelationInput"]: {
	by?:ValueTypes["ReviewUniqueWhere"],
	data?:ValueTypes["ReviewUpdateInput"]
};
	["ContentBlockUpsertContentRelationInput"]: {
	update?:ValueTypes["ContentWithoutBlocksUpdateInput"],
	create?:ValueTypes["ContentWithoutBlocksCreateInput"]
};
	["ContentBlockUpsertFaqRelationInput"]: {
	update?:ValueTypes["FaqUpdateInput"],
	create?:ValueTypes["FaqCreateInput"]
};
	["ContentBlockUpsertFeatureListRelationInput"]: {
	update?:ValueTypes["FeatureListUpdateInput"],
	create?:ValueTypes["FeatureListCreateInput"]
};
	["ContentBlockUpsertIconListRelationInput"]: {
	update?:ValueTypes["IconListBlockUpdateInput"],
	create?:ValueTypes["IconListBlockCreateInput"]
};
	["ContentBlockUpsertImageRelationInput"]: {
	update?:ValueTypes["ImageUpdateInput"],
	create?:ValueTypes["ImageCreateInput"]
};
	["ContentBlockUpsertLinkRelationInput"]: {
	update?:ValueTypes["LinkUpdateInput"],
	create?:ValueTypes["LinkCreateInput"]
};
	["ContentBlockUpsertMediaRelationInput"]: {
	update?:ValueTypes["MediaUpdateInput"],
	create?:ValueTypes["MediaCreateInput"]
};
	["ContentBlockUpsertMobileImageRelationInput"]: {
	update?:ValueTypes["ImageUpdateInput"],
	create?:ValueTypes["ImageCreateInput"]
};
	["ContentBlockUpsertProductListRelationInput"]: {
	by?:ValueTypes["ProductUniqueWhere"],
	update?:ValueTypes["ProductUpdateInput"],
	create?:ValueTypes["ProductCreateInput"]
};
	["ContentBlockUpsertProductRelationInput"]: {
	update?:ValueTypes["ProductUpdateInput"],
	create?:ValueTypes["ProductCreateInput"]
};
	["ContentBlockUpsertReviewListRelationInput"]: {
	by?:ValueTypes["ReviewUniqueWhere"],
	update?:ValueTypes["ReviewUpdateInput"],
	create?:ValueTypes["ReviewCreateInput"]
};
	["ContentBlockWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	order?:ValueTypes["IntCondition"],
	type?:ValueTypes["ContentBlockTypeEnumCondition"],
	primaryText?:ValueTypes["StringCondition"],
	secondaryText?:ValueTypes["StringCondition"],
	jsonContent?:ValueTypes["StringCondition"],
	headerView?:ValueTypes["HeaderViewEnumCondition"],
	reviewView?:ValueTypes["ReviewViewEnumCondition"],
	content?:ValueTypes["ContentWhere"],
	link?:ValueTypes["LinkWhere"],
	image?:ValueTypes["ImageWhere"],
	media?:ValueTypes["MediaWhere"],
	product?:ValueTypes["ProductWhere"],
	featureList?:ValueTypes["FeatureListWhere"],
	iconList?:ValueTypes["IconListBlockWhere"],
	reviewList?:ValueTypes["ReviewWhere"],
	faq?:ValueTypes["FaqWhere"],
	productList?:ValueTypes["ProductWhere"],
	tertiaryText?:ValueTypes["StringCondition"],
	sideBySideView?:ValueTypes["SideBySideViewEnumCondition"],
	mobileImage?:ValueTypes["ImageWhere"],
	htmlId?:ValueTypes["StringCondition"],
	and?:ValueTypes["ContentBlockWhere"][],
	or?:ValueTypes["ContentBlockWhere"][],
	not?:ValueTypes["ContentBlockWhere"]
};
	["ContentBlockWithoutContentCreateInput"]: {
	order?:number,
	type?:ValueTypes["ContentBlockType"],
	primaryText?:string,
	secondaryText?:string,
	jsonContent?:string,
	headerView?:ValueTypes["HeaderView"],
	reviewView?:ValueTypes["ReviewView"],
	link?:ValueTypes["ContentBlockCreateLinkEntityRelationInput"],
	image?:ValueTypes["ContentBlockCreateImageEntityRelationInput"],
	media?:ValueTypes["ContentBlockCreateMediaEntityRelationInput"],
	product?:ValueTypes["ContentBlockCreateProductEntityRelationInput"],
	featureList?:ValueTypes["ContentBlockCreateFeatureListEntityRelationInput"],
	iconList?:ValueTypes["ContentBlockCreateIconListEntityRelationInput"],
	reviewList?:ValueTypes["ContentBlockCreateReviewListEntityRelationInput"][],
	faq?:ValueTypes["ContentBlockCreateFaqEntityRelationInput"],
	productList?:ValueTypes["ContentBlockCreateProductListEntityRelationInput"][],
	tertiaryText?:string,
	sideBySideView?:ValueTypes["SideBySideView"],
	mobileImage?:ValueTypes["ContentBlockCreateMobileImageEntityRelationInput"],
	htmlId?:string,
	_dummy_field_?:boolean
};
	["ContentBlockWithoutContentUpdateInput"]: {
	order?:number,
	type?:ValueTypes["ContentBlockType"],
	primaryText?:string,
	secondaryText?:string,
	jsonContent?:string,
	headerView?:ValueTypes["HeaderView"],
	reviewView?:ValueTypes["ReviewView"],
	link?:ValueTypes["ContentBlockUpdateLinkEntityRelationInput"],
	image?:ValueTypes["ContentBlockUpdateImageEntityRelationInput"],
	media?:ValueTypes["ContentBlockUpdateMediaEntityRelationInput"],
	product?:ValueTypes["ContentBlockUpdateProductEntityRelationInput"],
	featureList?:ValueTypes["ContentBlockUpdateFeatureListEntityRelationInput"],
	iconList?:ValueTypes["ContentBlockUpdateIconListEntityRelationInput"],
	reviewList?:ValueTypes["ContentBlockUpdateReviewListEntityRelationInput"][],
	faq?:ValueTypes["ContentBlockUpdateFaqEntityRelationInput"],
	productList?:ValueTypes["ContentBlockUpdateProductListEntityRelationInput"][],
	tertiaryText?:string,
	sideBySideView?:ValueTypes["SideBySideView"],
	mobileImage?:ValueTypes["ContentBlockUpdateMobileImageEntityRelationInput"],
	htmlId?:string,
	_dummy_field_?:boolean
};
	["ContentConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ContentEdge"],
		__typename?: true
}>;
	["ContentCreateBlocksEntityRelationInput"]: {
	connect?:ValueTypes["ContentBlockUniqueWhere"],
	create?:ValueTypes["ContentBlockWithoutContentCreateInput"],
	alias?:string
};
	["ContentCreateInput"]: {
	blocks?:ValueTypes["ContentCreateBlocksEntityRelationInput"][],
	page?:ValueTypes["ContentCreatePageEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ContentCreatePageEntityRelationInput"]: {
	connect?:ValueTypes["PageLocaleUniqueWhere"],
	create?:ValueTypes["PageLocaleWithoutContentCreateInput"]
};
	["ContentCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Content"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ContentDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Content"],
		__typename?: true
}>;
	["ContentEdge"]: AliasType<{
	node?:ValueTypes["Content"],
		__typename?: true
}>;
	["ContentMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	blocks?:ValueTypes["FieldMeta"],
	page?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ContentOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	page?:ValueTypes["PageLocaleOrderBy"]
};
	["ContentUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	blocks?:ValueTypes["ContentBlockUniqueWhere"],
	page?:ValueTypes["PageLocaleUniqueWhere"]
};
	["ContentUpdateBlocksEntityRelationInput"]: {
	create?:ValueTypes["ContentBlockWithoutContentCreateInput"],
	update?:ValueTypes["ContentUpdateBlocksRelationInput"],
	upsert?:ValueTypes["ContentUpsertBlocksRelationInput"],
	connect?:ValueTypes["ContentBlockUniqueWhere"],
	disconnect?:ValueTypes["ContentBlockUniqueWhere"],
	delete?:ValueTypes["ContentBlockUniqueWhere"],
	alias?:string
};
	["ContentUpdateBlocksRelationInput"]: {
	by?:ValueTypes["ContentBlockUniqueWhere"],
	data?:ValueTypes["ContentBlockWithoutContentUpdateInput"]
};
	["ContentUpdateInput"]: {
	blocks?:ValueTypes["ContentUpdateBlocksEntityRelationInput"][],
	page?:ValueTypes["ContentUpdatePageEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ContentUpdatePageEntityRelationInput"]: {
	create?:ValueTypes["PageLocaleWithoutContentCreateInput"],
	update?:ValueTypes["PageLocaleWithoutContentUpdateInput"],
	upsert?:ValueTypes["ContentUpsertPageRelationInput"],
	connect?:ValueTypes["PageLocaleUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["ContentUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Content"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ContentUpsertBlocksRelationInput"]: {
	by?:ValueTypes["ContentBlockUniqueWhere"],
	update?:ValueTypes["ContentBlockWithoutContentUpdateInput"],
	create?:ValueTypes["ContentBlockWithoutContentCreateInput"]
};
	["ContentUpsertPageRelationInput"]: {
	update?:ValueTypes["PageLocaleWithoutContentUpdateInput"],
	create?:ValueTypes["PageLocaleWithoutContentCreateInput"]
};
	["ContentWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	blocks?:ValueTypes["ContentBlockWhere"],
	page?:ValueTypes["PageLocaleWhere"],
	and?:ValueTypes["ContentWhere"][],
	or?:ValueTypes["ContentWhere"][],
	not?:ValueTypes["ContentWhere"]
};
	["ContentWithoutBlocksCreateInput"]: {
	page?:ValueTypes["ContentCreatePageEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ContentWithoutBlocksUpdateInput"]: {
	page?:ValueTypes["ContentUpdatePageEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ContentWithoutPageCreateInput"]: {
	blocks?:ValueTypes["ContentCreateBlocksEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["ContentWithoutPageUpdateInput"]: {
	blocks?:ValueTypes["ContentUpdateBlocksEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["DateTime"]:unknown;
	["DateTimeCondition"]: {
	and?:ValueTypes["DateTimeCondition"][],
	or?:ValueTypes["DateTimeCondition"][],
	not?:ValueTypes["DateTimeCondition"],
	eq?:ValueTypes["DateTime"],
	null?:boolean,
	isNull?:boolean,
	notEq?:ValueTypes["DateTime"],
	in?:ValueTypes["DateTime"][],
	notIn?:ValueTypes["DateTime"][],
	lt?:ValueTypes["DateTime"],
	lte?:ValueTypes["DateTime"],
	gt?:ValueTypes["DateTime"],
	gte?:ValueTypes["DateTime"]
};
	["Faq"]: AliasType<{
	_meta?:ValueTypes["FaqMeta"],
	id?:true,
items?: [{	filter?:ValueTypes["FaqItemWhere"],	orderBy?:ValueTypes["FaqItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["FaqItem"]],
		__typename?: true
}>;
	["FaqConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FaqEdge"],
		__typename?: true
}>;
	["FaqCreateInput"]: {
	items?:ValueTypes["FaqCreateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["FaqCreateItemsEntityRelationInput"]: {
	connect?:ValueTypes["FaqItemUniqueWhere"],
	create?:ValueTypes["FaqItemWithoutListCreateInput"],
	alias?:string
};
	["FaqCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Faq"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["FaqDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Faq"],
		__typename?: true
}>;
	["FaqEdge"]: AliasType<{
	node?:ValueTypes["Faq"],
		__typename?: true
}>;
	["FaqItem"]: AliasType<{
	_meta?:ValueTypes["FaqItemMeta"],
	id?:true,
	order?:true,
	question?:true,
	answer?:true,
list?: [{	filter?:ValueTypes["FaqWhere"]},ValueTypes["Faq"]],
		__typename?: true
}>;
	["FaqItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FaqItemEdge"],
		__typename?: true
}>;
	["FaqItemCreateInput"]: {
	order?:number,
	question?:string,
	answer?:string,
	list?:ValueTypes["FaqItemCreateListEntityRelationInput"],
	_dummy_field_?:boolean
};
	["FaqItemCreateListEntityRelationInput"]: {
	connect?:ValueTypes["FaqUniqueWhere"],
	create?:ValueTypes["FaqWithoutItemsCreateInput"]
};
	["FaqItemCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["FaqItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["FaqItemDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["FaqItem"],
		__typename?: true
}>;
	["FaqItemEdge"]: AliasType<{
	node?:ValueTypes["FaqItem"],
		__typename?: true
}>;
	["FaqItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	question?:ValueTypes["FieldMeta"],
	answer?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["FaqItemOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	order?:ValueTypes["OrderDirection"],
	question?:ValueTypes["OrderDirection"],
	answer?:ValueTypes["OrderDirection"],
	list?:ValueTypes["FaqOrderBy"]
};
	["FaqItemUniqueWhere"]: {
	id?:ValueTypes["UUID"]
};
	["FaqItemUpdateInput"]: {
	order?:number,
	question?:string,
	answer?:string,
	list?:ValueTypes["FaqItemUpdateListEntityRelationInput"],
	_dummy_field_?:boolean
};
	["FaqItemUpdateListEntityRelationInput"]: {
	create?:ValueTypes["FaqWithoutItemsCreateInput"],
	update?:ValueTypes["FaqWithoutItemsUpdateInput"],
	upsert?:ValueTypes["FaqItemUpsertListRelationInput"],
	connect?:ValueTypes["FaqUniqueWhere"]
};
	["FaqItemUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["FaqItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["FaqItemUpsertListRelationInput"]: {
	update?:ValueTypes["FaqWithoutItemsUpdateInput"],
	create?:ValueTypes["FaqWithoutItemsCreateInput"]
};
	["FaqItemWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	order?:ValueTypes["IntCondition"],
	question?:ValueTypes["StringCondition"],
	answer?:ValueTypes["StringCondition"],
	list?:ValueTypes["FaqWhere"],
	and?:ValueTypes["FaqItemWhere"][],
	or?:ValueTypes["FaqItemWhere"][],
	not?:ValueTypes["FaqItemWhere"]
};
	["FaqItemWithoutListCreateInput"]: {
	order?:number,
	question?:string,
	answer?:string,
	_dummy_field_?:boolean
};
	["FaqItemWithoutListUpdateInput"]: {
	order?:number,
	question?:string,
	answer?:string,
	_dummy_field_?:boolean
};
	["FaqMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["FaqOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"]
};
	["FaqUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	items?:ValueTypes["FaqItemUniqueWhere"]
};
	["FaqUpdateInput"]: {
	items?:ValueTypes["FaqUpdateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["FaqUpdateItemsEntityRelationInput"]: {
	create?:ValueTypes["FaqItemWithoutListCreateInput"],
	update?:ValueTypes["FaqUpdateItemsRelationInput"],
	upsert?:ValueTypes["FaqUpsertItemsRelationInput"],
	connect?:ValueTypes["FaqItemUniqueWhere"],
	disconnect?:ValueTypes["FaqItemUniqueWhere"],
	delete?:ValueTypes["FaqItemUniqueWhere"],
	alias?:string
};
	["FaqUpdateItemsRelationInput"]: {
	by?:ValueTypes["FaqItemUniqueWhere"],
	data?:ValueTypes["FaqItemWithoutListUpdateInput"]
};
	["FaqUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Faq"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["FaqUpsertItemsRelationInput"]: {
	by?:ValueTypes["FaqItemUniqueWhere"],
	update?:ValueTypes["FaqItemWithoutListUpdateInput"],
	create?:ValueTypes["FaqItemWithoutListCreateInput"]
};
	["FaqWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	items?:ValueTypes["FaqItemWhere"],
	and?:ValueTypes["FaqWhere"][],
	or?:ValueTypes["FaqWhere"][],
	not?:ValueTypes["FaqWhere"]
};
	["FaqWithoutItemsCreateInput"]: {
	_dummy_field_?:boolean
};
	["FaqWithoutItemsUpdateInput"]: {
	_dummy_field_?:boolean
};
	["FeatureList"]: AliasType<{
	_meta?:ValueTypes["FeatureListMeta"],
	id?:true,
items?: [{	filter?:ValueTypes["FeatureListItemWhere"],	orderBy?:ValueTypes["FeatureListItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["FeatureListItem"]],
		__typename?: true
}>;
	["FeatureListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FeatureListEdge"],
		__typename?: true
}>;
	["FeatureListCreateInput"]: {
	items?:ValueTypes["FeatureListCreateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["FeatureListCreateItemsEntityRelationInput"]: {
	connect?:ValueTypes["FeatureListItemUniqueWhere"],
	create?:ValueTypes["FeatureListItemWithoutListCreateInput"],
	alias?:string
};
	["FeatureListCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["FeatureList"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["FeatureListDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["FeatureList"],
		__typename?: true
}>;
	["FeatureListEdge"]: AliasType<{
	node?:ValueTypes["FeatureList"],
		__typename?: true
}>;
	["FeatureListItem"]: AliasType<{
	_meta?:ValueTypes["FeatureListItemMeta"],
	id?:true,
	order?:true,
	headline?:true,
	text?:true,
list?: [{	filter?:ValueTypes["FeatureListWhere"]},ValueTypes["FeatureList"]],
		__typename?: true
}>;
	["FeatureListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["FeatureListItemEdge"],
		__typename?: true
}>;
	["FeatureListItemCreateInput"]: {
	order?:number,
	headline?:string,
	text?:string,
	list?:ValueTypes["FeatureListItemCreateListEntityRelationInput"],
	_dummy_field_?:boolean
};
	["FeatureListItemCreateListEntityRelationInput"]: {
	connect?:ValueTypes["FeatureListUniqueWhere"],
	create?:ValueTypes["FeatureListWithoutItemsCreateInput"]
};
	["FeatureListItemCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["FeatureListItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["FeatureListItemDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["FeatureListItem"],
		__typename?: true
}>;
	["FeatureListItemEdge"]: AliasType<{
	node?:ValueTypes["FeatureListItem"],
		__typename?: true
}>;
	["FeatureListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	headline?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["FeatureListItemOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	order?:ValueTypes["OrderDirection"],
	headline?:ValueTypes["OrderDirection"],
	text?:ValueTypes["OrderDirection"],
	list?:ValueTypes["FeatureListOrderBy"]
};
	["FeatureListItemUniqueWhere"]: {
	id?:ValueTypes["UUID"]
};
	["FeatureListItemUpdateInput"]: {
	order?:number,
	headline?:string,
	text?:string,
	list?:ValueTypes["FeatureListItemUpdateListEntityRelationInput"],
	_dummy_field_?:boolean
};
	["FeatureListItemUpdateListEntityRelationInput"]: {
	create?:ValueTypes["FeatureListWithoutItemsCreateInput"],
	update?:ValueTypes["FeatureListWithoutItemsUpdateInput"],
	upsert?:ValueTypes["FeatureListItemUpsertListRelationInput"],
	connect?:ValueTypes["FeatureListUniqueWhere"]
};
	["FeatureListItemUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["FeatureListItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["FeatureListItemUpsertListRelationInput"]: {
	update?:ValueTypes["FeatureListWithoutItemsUpdateInput"],
	create?:ValueTypes["FeatureListWithoutItemsCreateInput"]
};
	["FeatureListItemWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	order?:ValueTypes["IntCondition"],
	headline?:ValueTypes["StringCondition"],
	text?:ValueTypes["StringCondition"],
	list?:ValueTypes["FeatureListWhere"],
	and?:ValueTypes["FeatureListItemWhere"][],
	or?:ValueTypes["FeatureListItemWhere"][],
	not?:ValueTypes["FeatureListItemWhere"]
};
	["FeatureListItemWithoutListCreateInput"]: {
	order?:number,
	headline?:string,
	text?:string,
	_dummy_field_?:boolean
};
	["FeatureListItemWithoutListUpdateInput"]: {
	order?:number,
	headline?:string,
	text?:string,
	_dummy_field_?:boolean
};
	["FeatureListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["FeatureListOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"]
};
	["FeatureListUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	items?:ValueTypes["FeatureListItemUniqueWhere"]
};
	["FeatureListUpdateInput"]: {
	items?:ValueTypes["FeatureListUpdateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["FeatureListUpdateItemsEntityRelationInput"]: {
	create?:ValueTypes["FeatureListItemWithoutListCreateInput"],
	update?:ValueTypes["FeatureListUpdateItemsRelationInput"],
	upsert?:ValueTypes["FeatureListUpsertItemsRelationInput"],
	connect?:ValueTypes["FeatureListItemUniqueWhere"],
	disconnect?:ValueTypes["FeatureListItemUniqueWhere"],
	delete?:ValueTypes["FeatureListItemUniqueWhere"],
	alias?:string
};
	["FeatureListUpdateItemsRelationInput"]: {
	by?:ValueTypes["FeatureListItemUniqueWhere"],
	data?:ValueTypes["FeatureListItemWithoutListUpdateInput"]
};
	["FeatureListUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["FeatureList"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["FeatureListUpsertItemsRelationInput"]: {
	by?:ValueTypes["FeatureListItemUniqueWhere"],
	update?:ValueTypes["FeatureListItemWithoutListUpdateInput"],
	create?:ValueTypes["FeatureListItemWithoutListCreateInput"]
};
	["FeatureListWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	items?:ValueTypes["FeatureListItemWhere"],
	and?:ValueTypes["FeatureListWhere"][],
	or?:ValueTypes["FeatureListWhere"][],
	not?:ValueTypes["FeatureListWhere"]
};
	["FeatureListWithoutItemsCreateInput"]: {
	_dummy_field_?:boolean
};
	["FeatureListWithoutItemsUpdateInput"]: {
	_dummy_field_?:boolean
};
	["FieldMeta"]: AliasType<{
	readable?:true,
	updatable?:true,
		__typename?: true
}>;
	["FloatCondition"]: {
	and?:ValueTypes["FloatCondition"][],
	or?:ValueTypes["FloatCondition"][],
	not?:ValueTypes["FloatCondition"],
	eq?:number,
	null?:boolean,
	isNull?:boolean,
	notEq?:number,
	in?:number[],
	notIn?:number[],
	lt?:number,
	lte?:number,
	gt?:number,
	gte?:number
};
	["Gallery"]: AliasType<{
	_meta?:ValueTypes["GalleryMeta"],
	id?:true,
items?: [{	filter?:ValueTypes["GalleryItemWhere"],	orderBy?:ValueTypes["GalleryItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["GalleryItem"]],
		__typename?: true
}>;
	["GalleryConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GalleryEdge"],
		__typename?: true
}>;
	["GalleryCreateInput"]: {
	items?:ValueTypes["GalleryCreateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["GalleryCreateItemsEntityRelationInput"]: {
	connect?:ValueTypes["GalleryItemUniqueWhere"],
	create?:ValueTypes["GalleryItemWithoutListCreateInput"],
	alias?:string
};
	["GalleryCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Gallery"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["GalleryDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Gallery"],
		__typename?: true
}>;
	["GalleryEdge"]: AliasType<{
	node?:ValueTypes["Gallery"],
		__typename?: true
}>;
	["GalleryItem"]: AliasType<{
	_meta?:ValueTypes["GalleryItemMeta"],
	id?:true,
	order?:true,
	caption?:true,
list?: [{	filter?:ValueTypes["GalleryWhere"]},ValueTypes["Gallery"]],
image?: [{	filter?:ValueTypes["ImageWhere"]},ValueTypes["Image"]],
		__typename?: true
}>;
	["GalleryItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["GalleryItemEdge"],
		__typename?: true
}>;
	["GalleryItemCreateImageEntityRelationInput"]: {
	connect?:ValueTypes["ImageUniqueWhere"],
	create?:ValueTypes["ImageCreateInput"]
};
	["GalleryItemCreateInput"]: {
	order?:number,
	caption?:string,
	list?:ValueTypes["GalleryItemCreateListEntityRelationInput"],
	image?:ValueTypes["GalleryItemCreateImageEntityRelationInput"],
	_dummy_field_?:boolean
};
	["GalleryItemCreateListEntityRelationInput"]: {
	connect?:ValueTypes["GalleryUniqueWhere"],
	create?:ValueTypes["GalleryWithoutItemsCreateInput"]
};
	["GalleryItemCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["GalleryItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["GalleryItemDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["GalleryItem"],
		__typename?: true
}>;
	["GalleryItemEdge"]: AliasType<{
	node?:ValueTypes["GalleryItem"],
		__typename?: true
}>;
	["GalleryItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	caption?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["GalleryItemOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	order?:ValueTypes["OrderDirection"],
	caption?:ValueTypes["OrderDirection"],
	list?:ValueTypes["GalleryOrderBy"],
	image?:ValueTypes["ImageOrderBy"]
};
	["GalleryItemUniqueWhere"]: {
	id?:ValueTypes["UUID"]
};
	["GalleryItemUpdateImageEntityRelationInput"]: {
	create?:ValueTypes["ImageCreateInput"],
	update?:ValueTypes["ImageUpdateInput"],
	upsert?:ValueTypes["GalleryItemUpsertImageRelationInput"],
	connect?:ValueTypes["ImageUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["GalleryItemUpdateInput"]: {
	order?:number,
	caption?:string,
	list?:ValueTypes["GalleryItemUpdateListEntityRelationInput"],
	image?:ValueTypes["GalleryItemUpdateImageEntityRelationInput"],
	_dummy_field_?:boolean
};
	["GalleryItemUpdateListEntityRelationInput"]: {
	create?:ValueTypes["GalleryWithoutItemsCreateInput"],
	update?:ValueTypes["GalleryWithoutItemsUpdateInput"],
	upsert?:ValueTypes["GalleryItemUpsertListRelationInput"],
	connect?:ValueTypes["GalleryUniqueWhere"]
};
	["GalleryItemUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["GalleryItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["GalleryItemUpsertImageRelationInput"]: {
	update?:ValueTypes["ImageUpdateInput"],
	create?:ValueTypes["ImageCreateInput"]
};
	["GalleryItemUpsertListRelationInput"]: {
	update?:ValueTypes["GalleryWithoutItemsUpdateInput"],
	create?:ValueTypes["GalleryWithoutItemsCreateInput"]
};
	["GalleryItemWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	order?:ValueTypes["IntCondition"],
	caption?:ValueTypes["StringCondition"],
	list?:ValueTypes["GalleryWhere"],
	image?:ValueTypes["ImageWhere"],
	and?:ValueTypes["GalleryItemWhere"][],
	or?:ValueTypes["GalleryItemWhere"][],
	not?:ValueTypes["GalleryItemWhere"]
};
	["GalleryItemWithoutListCreateInput"]: {
	order?:number,
	caption?:string,
	image?:ValueTypes["GalleryItemCreateImageEntityRelationInput"],
	_dummy_field_?:boolean
};
	["GalleryItemWithoutListUpdateInput"]: {
	order?:number,
	caption?:string,
	image?:ValueTypes["GalleryItemUpdateImageEntityRelationInput"],
	_dummy_field_?:boolean
};
	["GalleryMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["GalleryOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"]
};
	["GalleryUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	items?:ValueTypes["GalleryItemUniqueWhere"]
};
	["GalleryUpdateInput"]: {
	items?:ValueTypes["GalleryUpdateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["GalleryUpdateItemsEntityRelationInput"]: {
	create?:ValueTypes["GalleryItemWithoutListCreateInput"],
	update?:ValueTypes["GalleryUpdateItemsRelationInput"],
	upsert?:ValueTypes["GalleryUpsertItemsRelationInput"],
	connect?:ValueTypes["GalleryItemUniqueWhere"],
	disconnect?:ValueTypes["GalleryItemUniqueWhere"],
	delete?:ValueTypes["GalleryItemUniqueWhere"],
	alias?:string
};
	["GalleryUpdateItemsRelationInput"]: {
	by?:ValueTypes["GalleryItemUniqueWhere"],
	data?:ValueTypes["GalleryItemWithoutListUpdateInput"]
};
	["GalleryUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Gallery"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["GalleryUpsertItemsRelationInput"]: {
	by?:ValueTypes["GalleryItemUniqueWhere"],
	update?:ValueTypes["GalleryItemWithoutListUpdateInput"],
	create?:ValueTypes["GalleryItemWithoutListCreateInput"]
};
	["GalleryWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	items?:ValueTypes["GalleryItemWhere"],
	and?:ValueTypes["GalleryWhere"][],
	or?:ValueTypes["GalleryWhere"][],
	not?:ValueTypes["GalleryWhere"]
};
	["GalleryWithoutItemsCreateInput"]: {
	_dummy_field_?:boolean
};
	["GalleryWithoutItemsUpdateInput"]: {
	_dummy_field_?:boolean
};
	["HeaderView"]:HeaderView;
	["HeaderViewEnumCondition"]: {
	and?:ValueTypes["HeaderViewEnumCondition"][],
	or?:ValueTypes["HeaderViewEnumCondition"][],
	not?:ValueTypes["HeaderViewEnumCondition"],
	eq?:ValueTypes["HeaderView"],
	null?:boolean,
	isNull?:boolean,
	notEq?:ValueTypes["HeaderView"],
	in?:ValueTypes["HeaderView"][],
	notIn?:ValueTypes["HeaderView"][],
	lt?:ValueTypes["HeaderView"],
	lte?:ValueTypes["HeaderView"],
	gt?:ValueTypes["HeaderView"],
	gte?:ValueTypes["HeaderView"]
};
	["Icon"]: AliasType<{
	_meta?:ValueTypes["IconMeta"],
	id?:true,
	key?:true,
image?: [{	filter?:ValueTypes["ImageWhere"]},ValueTypes["Image"]],
locales?: [{	filter?:ValueTypes["IconLocaleWhere"],	orderBy?:ValueTypes["IconLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconLocale"]],
localesByLocale?: [{	by:ValueTypes["IconLocalesByLocaleUniqueWhere"],	filter?:ValueTypes["IconLocaleWhere"]},ValueTypes["IconLocale"]],
		__typename?: true
}>;
	["IconConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IconEdge"],
		__typename?: true
}>;
	["IconCreateImageEntityRelationInput"]: {
	connect?:ValueTypes["ImageUniqueWhere"],
	create?:ValueTypes["ImageCreateInput"]
};
	["IconCreateInput"]: {
	key?:string,
	image?:ValueTypes["IconCreateImageEntityRelationInput"],
	locales?:ValueTypes["IconCreateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["IconCreateLocalesEntityRelationInput"]: {
	connect?:ValueTypes["IconLocaleUniqueWhere"],
	create?:ValueTypes["IconLocaleWithoutRootCreateInput"],
	alias?:string
};
	["IconCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Icon"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Icon"],
		__typename?: true
}>;
	["IconEdge"]: AliasType<{
	node?:ValueTypes["Icon"],
		__typename?: true
}>;
	["IconGroup"]: AliasType<{
	_meta?:ValueTypes["IconGroupMeta"],
	id?:true,
icons?: [{	filter?:ValueTypes["IconListWhere"]},ValueTypes["IconList"]],
locales?: [{	filter?:ValueTypes["IconGroupLocaleWhere"],	orderBy?:ValueTypes["IconGroupLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconGroupLocale"]],
	title?:true,
localesByLocale?: [{	by:ValueTypes["IconGroupLocalesByLocaleUniqueWhere"],	filter?:ValueTypes["IconGroupLocaleWhere"]},ValueTypes["IconGroupLocale"]],
		__typename?: true
}>;
	["IconGroupConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IconGroupEdge"],
		__typename?: true
}>;
	["IconGroupCreateIconsEntityRelationInput"]: {
	connect?:ValueTypes["IconListUniqueWhere"],
	create?:ValueTypes["IconListCreateInput"]
};
	["IconGroupCreateInput"]: {
	icons?:ValueTypes["IconGroupCreateIconsEntityRelationInput"],
	locales?:ValueTypes["IconGroupCreateLocalesEntityRelationInput"][],
	title?:string,
	_dummy_field_?:boolean
};
	["IconGroupCreateLocalesEntityRelationInput"]: {
	connect?:ValueTypes["IconGroupLocaleUniqueWhere"],
	create?:ValueTypes["IconGroupLocaleWithoutRootCreateInput"],
	alias?:string
};
	["IconGroupCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconGroup"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconGroupDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconGroup"],
		__typename?: true
}>;
	["IconGroupEdge"]: AliasType<{
	node?:ValueTypes["IconGroup"],
		__typename?: true
}>;
	["IconGroupList"]: AliasType<{
	_meta?:ValueTypes["IconGroupListMeta"],
	id?:true,
items?: [{	filter?:ValueTypes["IconGroupListItemWhere"],	orderBy?:ValueTypes["IconGroupListItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconGroupListItem"]],
	title?:true,
		__typename?: true
}>;
	["IconGroupListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IconGroupListEdge"],
		__typename?: true
}>;
	["IconGroupListCreateInput"]: {
	items?:ValueTypes["IconGroupListCreateItemsEntityRelationInput"][],
	title?:string,
	_dummy_field_?:boolean
};
	["IconGroupListCreateItemsEntityRelationInput"]: {
	connect?:ValueTypes["IconGroupListItemUniqueWhere"],
	create?:ValueTypes["IconGroupListItemWithoutListCreateInput"],
	alias?:string
};
	["IconGroupListCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconGroupList"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconGroupListDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconGroupList"],
		__typename?: true
}>;
	["IconGroupListEdge"]: AliasType<{
	node?:ValueTypes["IconGroupList"],
		__typename?: true
}>;
	["IconGroupListItem"]: AliasType<{
	_meta?:ValueTypes["IconGroupListItemMeta"],
	id?:true,
	order?:true,
list?: [{	filter?:ValueTypes["IconGroupListWhere"]},ValueTypes["IconGroupList"]],
group?: [{	filter?:ValueTypes["IconGroupWhere"]},ValueTypes["IconGroup"]],
		__typename?: true
}>;
	["IconGroupListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IconGroupListItemEdge"],
		__typename?: true
}>;
	["IconGroupListItemCreateGroupEntityRelationInput"]: {
	connect?:ValueTypes["IconGroupUniqueWhere"],
	create?:ValueTypes["IconGroupCreateInput"]
};
	["IconGroupListItemCreateInput"]: {
	order?:number,
	list?:ValueTypes["IconGroupListItemCreateListEntityRelationInput"],
	group?:ValueTypes["IconGroupListItemCreateGroupEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconGroupListItemCreateListEntityRelationInput"]: {
	connect?:ValueTypes["IconGroupListUniqueWhere"],
	create?:ValueTypes["IconGroupListWithoutItemsCreateInput"]
};
	["IconGroupListItemCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconGroupListItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconGroupListItemDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconGroupListItem"],
		__typename?: true
}>;
	["IconGroupListItemEdge"]: AliasType<{
	node?:ValueTypes["IconGroupListItem"],
		__typename?: true
}>;
	["IconGroupListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	group?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["IconGroupListItemOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	order?:ValueTypes["OrderDirection"],
	list?:ValueTypes["IconGroupListOrderBy"],
	group?:ValueTypes["IconGroupOrderBy"]
};
	["IconGroupListItemUniqueWhere"]: {
	id?:ValueTypes["UUID"]
};
	["IconGroupListItemUpdateGroupEntityRelationInput"]: {
	create?:ValueTypes["IconGroupCreateInput"],
	update?:ValueTypes["IconGroupUpdateInput"],
	upsert?:ValueTypes["IconGroupListItemUpsertGroupRelationInput"],
	connect?:ValueTypes["IconGroupUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["IconGroupListItemUpdateInput"]: {
	order?:number,
	list?:ValueTypes["IconGroupListItemUpdateListEntityRelationInput"],
	group?:ValueTypes["IconGroupListItemUpdateGroupEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconGroupListItemUpdateListEntityRelationInput"]: {
	create?:ValueTypes["IconGroupListWithoutItemsCreateInput"],
	update?:ValueTypes["IconGroupListWithoutItemsUpdateInput"],
	upsert?:ValueTypes["IconGroupListItemUpsertListRelationInput"],
	connect?:ValueTypes["IconGroupListUniqueWhere"]
};
	["IconGroupListItemUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconGroupListItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconGroupListItemUpsertGroupRelationInput"]: {
	update?:ValueTypes["IconGroupUpdateInput"],
	create?:ValueTypes["IconGroupCreateInput"]
};
	["IconGroupListItemUpsertListRelationInput"]: {
	update?:ValueTypes["IconGroupListWithoutItemsUpdateInput"],
	create?:ValueTypes["IconGroupListWithoutItemsCreateInput"]
};
	["IconGroupListItemWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	order?:ValueTypes["IntCondition"],
	list?:ValueTypes["IconGroupListWhere"],
	group?:ValueTypes["IconGroupWhere"],
	and?:ValueTypes["IconGroupListItemWhere"][],
	or?:ValueTypes["IconGroupListItemWhere"][],
	not?:ValueTypes["IconGroupListItemWhere"]
};
	["IconGroupListItemWithoutListCreateInput"]: {
	order?:number,
	group?:ValueTypes["IconGroupListItemCreateGroupEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconGroupListItemWithoutListUpdateInput"]: {
	order?:number,
	group?:ValueTypes["IconGroupListItemUpdateGroupEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconGroupListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["IconGroupListOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	title?:ValueTypes["OrderDirection"]
};
	["IconGroupListUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	items?:ValueTypes["IconGroupListItemUniqueWhere"]
};
	["IconGroupListUpdateInput"]: {
	items?:ValueTypes["IconGroupListUpdateItemsEntityRelationInput"][],
	title?:string,
	_dummy_field_?:boolean
};
	["IconGroupListUpdateItemsEntityRelationInput"]: {
	create?:ValueTypes["IconGroupListItemWithoutListCreateInput"],
	update?:ValueTypes["IconGroupListUpdateItemsRelationInput"],
	upsert?:ValueTypes["IconGroupListUpsertItemsRelationInput"],
	connect?:ValueTypes["IconGroupListItemUniqueWhere"],
	disconnect?:ValueTypes["IconGroupListItemUniqueWhere"],
	delete?:ValueTypes["IconGroupListItemUniqueWhere"],
	alias?:string
};
	["IconGroupListUpdateItemsRelationInput"]: {
	by?:ValueTypes["IconGroupListItemUniqueWhere"],
	data?:ValueTypes["IconGroupListItemWithoutListUpdateInput"]
};
	["IconGroupListUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconGroupList"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconGroupListUpsertItemsRelationInput"]: {
	by?:ValueTypes["IconGroupListItemUniqueWhere"],
	update?:ValueTypes["IconGroupListItemWithoutListUpdateInput"],
	create?:ValueTypes["IconGroupListItemWithoutListCreateInput"]
};
	["IconGroupListWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	items?:ValueTypes["IconGroupListItemWhere"],
	title?:ValueTypes["StringCondition"],
	and?:ValueTypes["IconGroupListWhere"][],
	or?:ValueTypes["IconGroupListWhere"][],
	not?:ValueTypes["IconGroupListWhere"]
};
	["IconGroupListWithoutItemsCreateInput"]: {
	title?:string,
	_dummy_field_?:boolean
};
	["IconGroupListWithoutItemsUpdateInput"]: {
	title?:string,
	_dummy_field_?:boolean
};
	["IconGroupLocale"]: AliasType<{
	_meta?:ValueTypes["IconGroupLocaleMeta"],
	id?:true,
	title?:true,
root?: [{	filter?:ValueTypes["IconGroupWhere"]},ValueTypes["IconGroup"]],
locale?: [{	filter?:ValueTypes["LocaleWhere"]},ValueTypes["Locale"]],
		__typename?: true
}>;
	["IconGroupLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IconGroupLocaleEdge"],
		__typename?: true
}>;
	["IconGroupLocaleCreateInput"]: {
	title?:string,
	root?:ValueTypes["IconGroupLocaleCreateRootEntityRelationInput"],
	locale?:ValueTypes["IconGroupLocaleCreateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconGroupLocaleCreateLocaleEntityRelationInput"]: {
	connect?:ValueTypes["LocaleUniqueWhere"],
	create?:ValueTypes["LocaleWithoutIconGroupsCreateInput"]
};
	["IconGroupLocaleCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconGroupLocale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconGroupLocaleCreateRootEntityRelationInput"]: {
	connect?:ValueTypes["IconGroupUniqueWhere"],
	create?:ValueTypes["IconGroupWithoutLocalesCreateInput"]
};
	["IconGroupLocaleDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconGroupLocale"],
		__typename?: true
}>;
	["IconGroupLocaleEdge"]: AliasType<{
	node?:ValueTypes["IconGroupLocale"],
		__typename?: true
}>;
	["IconGroupLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["IconGroupLocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	title?:ValueTypes["OrderDirection"],
	root?:ValueTypes["IconGroupOrderBy"],
	locale?:ValueTypes["LocaleOrderBy"]
};
	["IconGroupLocalesByLocaleUniqueWhere"]: {
	locale?:ValueTypes["LocaleUniqueWhere"]
};
	["IconGroupLocaleUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	root?:ValueTypes["IconGroupUniqueWhere"],
	locale?:ValueTypes["LocaleUniqueWhere"]
};
	["IconGroupLocaleUpdateInput"]: {
	title?:string,
	root?:ValueTypes["IconGroupLocaleUpdateRootEntityRelationInput"],
	locale?:ValueTypes["IconGroupLocaleUpdateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconGroupLocaleUpdateLocaleEntityRelationInput"]: {
	create?:ValueTypes["LocaleWithoutIconGroupsCreateInput"],
	update?:ValueTypes["LocaleWithoutIconGroupsUpdateInput"],
	upsert?:ValueTypes["IconGroupLocaleUpsertLocaleRelationInput"],
	connect?:ValueTypes["LocaleUniqueWhere"]
};
	["IconGroupLocaleUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconGroupLocale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconGroupLocaleUpdateRootEntityRelationInput"]: {
	create?:ValueTypes["IconGroupWithoutLocalesCreateInput"],
	update?:ValueTypes["IconGroupWithoutLocalesUpdateInput"],
	upsert?:ValueTypes["IconGroupLocaleUpsertRootRelationInput"],
	connect?:ValueTypes["IconGroupUniqueWhere"]
};
	["IconGroupLocaleUpsertLocaleRelationInput"]: {
	update?:ValueTypes["LocaleWithoutIconGroupsUpdateInput"],
	create?:ValueTypes["LocaleWithoutIconGroupsCreateInput"]
};
	["IconGroupLocaleUpsertRootRelationInput"]: {
	update?:ValueTypes["IconGroupWithoutLocalesUpdateInput"],
	create?:ValueTypes["IconGroupWithoutLocalesCreateInput"]
};
	["IconGroupLocaleWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	title?:ValueTypes["StringCondition"],
	root?:ValueTypes["IconGroupWhere"],
	locale?:ValueTypes["LocaleWhere"],
	and?:ValueTypes["IconGroupLocaleWhere"][],
	or?:ValueTypes["IconGroupLocaleWhere"][],
	not?:ValueTypes["IconGroupLocaleWhere"]
};
	["IconGroupLocaleWithoutLocaleCreateInput"]: {
	title?:string,
	root?:ValueTypes["IconGroupLocaleCreateRootEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconGroupLocaleWithoutLocaleUpdateInput"]: {
	title?:string,
	root?:ValueTypes["IconGroupLocaleUpdateRootEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconGroupLocaleWithoutRootCreateInput"]: {
	title?:string,
	locale?:ValueTypes["IconGroupLocaleCreateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconGroupLocaleWithoutRootUpdateInput"]: {
	title?:string,
	locale?:ValueTypes["IconGroupLocaleUpdateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconGroupMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	icons?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["IconGroupOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	icons?:ValueTypes["IconListOrderBy"],
	title?:ValueTypes["OrderDirection"]
};
	["IconGroupUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	locales?:ValueTypes["IconGroupLocaleUniqueWhere"]
};
	["IconGroupUpdateIconsEntityRelationInput"]: {
	create?:ValueTypes["IconListCreateInput"],
	update?:ValueTypes["IconListUpdateInput"],
	upsert?:ValueTypes["IconGroupUpsertIconsRelationInput"],
	connect?:ValueTypes["IconListUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["IconGroupUpdateInput"]: {
	icons?:ValueTypes["IconGroupUpdateIconsEntityRelationInput"],
	locales?:ValueTypes["IconGroupUpdateLocalesEntityRelationInput"][],
	title?:string,
	_dummy_field_?:boolean
};
	["IconGroupUpdateLocalesEntityRelationInput"]: {
	create?:ValueTypes["IconGroupLocaleWithoutRootCreateInput"],
	update?:ValueTypes["IconGroupUpdateLocalesRelationInput"],
	upsert?:ValueTypes["IconGroupUpsertLocalesRelationInput"],
	connect?:ValueTypes["IconGroupLocaleUniqueWhere"],
	disconnect?:ValueTypes["IconGroupLocaleUniqueWhere"],
	delete?:ValueTypes["IconGroupLocaleUniqueWhere"],
	alias?:string
};
	["IconGroupUpdateLocalesRelationInput"]: {
	by?:ValueTypes["IconGroupLocaleUniqueWhere"],
	data?:ValueTypes["IconGroupLocaleWithoutRootUpdateInput"]
};
	["IconGroupUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconGroup"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconGroupUpsertIconsRelationInput"]: {
	update?:ValueTypes["IconListUpdateInput"],
	create?:ValueTypes["IconListCreateInput"]
};
	["IconGroupUpsertLocalesRelationInput"]: {
	by?:ValueTypes["IconGroupLocaleUniqueWhere"],
	update?:ValueTypes["IconGroupLocaleWithoutRootUpdateInput"],
	create?:ValueTypes["IconGroupLocaleWithoutRootCreateInput"]
};
	["IconGroupWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	icons?:ValueTypes["IconListWhere"],
	locales?:ValueTypes["IconGroupLocaleWhere"],
	title?:ValueTypes["StringCondition"],
	and?:ValueTypes["IconGroupWhere"][],
	or?:ValueTypes["IconGroupWhere"][],
	not?:ValueTypes["IconGroupWhere"]
};
	["IconGroupWithoutLocalesCreateInput"]: {
	icons?:ValueTypes["IconGroupCreateIconsEntityRelationInput"],
	title?:string,
	_dummy_field_?:boolean
};
	["IconGroupWithoutLocalesUpdateInput"]: {
	icons?:ValueTypes["IconGroupUpdateIconsEntityRelationInput"],
	title?:string,
	_dummy_field_?:boolean
};
	["IconList"]: AliasType<{
	_meta?:ValueTypes["IconListMeta"],
	id?:true,
items?: [{	filter?:ValueTypes["IconListItemWhere"],	orderBy?:ValueTypes["IconListItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconListItem"]],
		__typename?: true
}>;
	["IconListBlock"]: AliasType<{
	_meta?:ValueTypes["IconListBlockMeta"],
	id?:true,
items?: [{	filter?:ValueTypes["IconListBlockItemWhere"],	orderBy?:ValueTypes["IconListBlockItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconListBlockItem"]],
		__typename?: true
}>;
	["IconListBlockConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IconListBlockEdge"],
		__typename?: true
}>;
	["IconListBlockCreateInput"]: {
	items?:ValueTypes["IconListBlockCreateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["IconListBlockCreateItemsEntityRelationInput"]: {
	connect?:ValueTypes["IconListBlockItemUniqueWhere"],
	create?:ValueTypes["IconListBlockItemWithoutListCreateInput"],
	alias?:string
};
	["IconListBlockCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconListBlock"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconListBlockDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconListBlock"],
		__typename?: true
}>;
	["IconListBlockEdge"]: AliasType<{
	node?:ValueTypes["IconListBlock"],
		__typename?: true
}>;
	["IconListBlockItem"]: AliasType<{
	_meta?:ValueTypes["IconListBlockItemMeta"],
	id?:true,
	order?:true,
	text?:true,
list?: [{	filter?:ValueTypes["IconListBlockWhere"]},ValueTypes["IconListBlock"]],
icon?: [{	filter?:ValueTypes["ImageWhere"]},ValueTypes["Image"]],
		__typename?: true
}>;
	["IconListBlockItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IconListBlockItemEdge"],
		__typename?: true
}>;
	["IconListBlockItemCreateIconEntityRelationInput"]: {
	connect?:ValueTypes["ImageUniqueWhere"],
	create?:ValueTypes["ImageCreateInput"]
};
	["IconListBlockItemCreateInput"]: {
	order?:number,
	text?:string,
	list?:ValueTypes["IconListBlockItemCreateListEntityRelationInput"],
	icon?:ValueTypes["IconListBlockItemCreateIconEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconListBlockItemCreateListEntityRelationInput"]: {
	connect?:ValueTypes["IconListBlockUniqueWhere"],
	create?:ValueTypes["IconListBlockWithoutItemsCreateInput"]
};
	["IconListBlockItemCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconListBlockItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconListBlockItemDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconListBlockItem"],
		__typename?: true
}>;
	["IconListBlockItemEdge"]: AliasType<{
	node?:ValueTypes["IconListBlockItem"],
		__typename?: true
}>;
	["IconListBlockItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	text?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["IconListBlockItemOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	order?:ValueTypes["OrderDirection"],
	text?:ValueTypes["OrderDirection"],
	list?:ValueTypes["IconListBlockOrderBy"],
	icon?:ValueTypes["ImageOrderBy"]
};
	["IconListBlockItemUniqueWhere"]: {
	id?:ValueTypes["UUID"]
};
	["IconListBlockItemUpdateIconEntityRelationInput"]: {
	create?:ValueTypes["ImageCreateInput"],
	update?:ValueTypes["ImageUpdateInput"],
	upsert?:ValueTypes["IconListBlockItemUpsertIconRelationInput"],
	connect?:ValueTypes["ImageUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["IconListBlockItemUpdateInput"]: {
	order?:number,
	text?:string,
	list?:ValueTypes["IconListBlockItemUpdateListEntityRelationInput"],
	icon?:ValueTypes["IconListBlockItemUpdateIconEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconListBlockItemUpdateListEntityRelationInput"]: {
	create?:ValueTypes["IconListBlockWithoutItemsCreateInput"],
	update?:ValueTypes["IconListBlockWithoutItemsUpdateInput"],
	upsert?:ValueTypes["IconListBlockItemUpsertListRelationInput"],
	connect?:ValueTypes["IconListBlockUniqueWhere"]
};
	["IconListBlockItemUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconListBlockItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconListBlockItemUpsertIconRelationInput"]: {
	update?:ValueTypes["ImageUpdateInput"],
	create?:ValueTypes["ImageCreateInput"]
};
	["IconListBlockItemUpsertListRelationInput"]: {
	update?:ValueTypes["IconListBlockWithoutItemsUpdateInput"],
	create?:ValueTypes["IconListBlockWithoutItemsCreateInput"]
};
	["IconListBlockItemWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	order?:ValueTypes["IntCondition"],
	text?:ValueTypes["StringCondition"],
	list?:ValueTypes["IconListBlockWhere"],
	icon?:ValueTypes["ImageWhere"],
	and?:ValueTypes["IconListBlockItemWhere"][],
	or?:ValueTypes["IconListBlockItemWhere"][],
	not?:ValueTypes["IconListBlockItemWhere"]
};
	["IconListBlockItemWithoutListCreateInput"]: {
	order?:number,
	text?:string,
	icon?:ValueTypes["IconListBlockItemCreateIconEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconListBlockItemWithoutListUpdateInput"]: {
	order?:number,
	text?:string,
	icon?:ValueTypes["IconListBlockItemUpdateIconEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconListBlockMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["IconListBlockOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"]
};
	["IconListBlockUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	items?:ValueTypes["IconListBlockItemUniqueWhere"]
};
	["IconListBlockUpdateInput"]: {
	items?:ValueTypes["IconListBlockUpdateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["IconListBlockUpdateItemsEntityRelationInput"]: {
	create?:ValueTypes["IconListBlockItemWithoutListCreateInput"],
	update?:ValueTypes["IconListBlockUpdateItemsRelationInput"],
	upsert?:ValueTypes["IconListBlockUpsertItemsRelationInput"],
	connect?:ValueTypes["IconListBlockItemUniqueWhere"],
	disconnect?:ValueTypes["IconListBlockItemUniqueWhere"],
	delete?:ValueTypes["IconListBlockItemUniqueWhere"],
	alias?:string
};
	["IconListBlockUpdateItemsRelationInput"]: {
	by?:ValueTypes["IconListBlockItemUniqueWhere"],
	data?:ValueTypes["IconListBlockItemWithoutListUpdateInput"]
};
	["IconListBlockUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconListBlock"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconListBlockUpsertItemsRelationInput"]: {
	by?:ValueTypes["IconListBlockItemUniqueWhere"],
	update?:ValueTypes["IconListBlockItemWithoutListUpdateInput"],
	create?:ValueTypes["IconListBlockItemWithoutListCreateInput"]
};
	["IconListBlockWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	items?:ValueTypes["IconListBlockItemWhere"],
	and?:ValueTypes["IconListBlockWhere"][],
	or?:ValueTypes["IconListBlockWhere"][],
	not?:ValueTypes["IconListBlockWhere"]
};
	["IconListBlockWithoutItemsCreateInput"]: {
	_dummy_field_?:boolean
};
	["IconListBlockWithoutItemsUpdateInput"]: {
	_dummy_field_?:boolean
};
	["IconListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IconListEdge"],
		__typename?: true
}>;
	["IconListCreateInput"]: {
	items?:ValueTypes["IconListCreateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["IconListCreateItemsEntityRelationInput"]: {
	connect?:ValueTypes["IconListItemUniqueWhere"],
	create?:ValueTypes["IconListItemWithoutListCreateInput"],
	alias?:string
};
	["IconListCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconList"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconListDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconList"],
		__typename?: true
}>;
	["IconListEdge"]: AliasType<{
	node?:ValueTypes["IconList"],
		__typename?: true
}>;
	["IconListItem"]: AliasType<{
	_meta?:ValueTypes["IconListItemMeta"],
	id?:true,
	order?:true,
list?: [{	filter?:ValueTypes["IconListWhere"]},ValueTypes["IconList"]],
icon?: [{	filter?:ValueTypes["IconWhere"]},ValueTypes["Icon"]],
		__typename?: true
}>;
	["IconListItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IconListItemEdge"],
		__typename?: true
}>;
	["IconListItemCreateIconEntityRelationInput"]: {
	connect?:ValueTypes["IconUniqueWhere"],
	create?:ValueTypes["IconCreateInput"]
};
	["IconListItemCreateInput"]: {
	order?:number,
	list?:ValueTypes["IconListItemCreateListEntityRelationInput"],
	icon?:ValueTypes["IconListItemCreateIconEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconListItemCreateListEntityRelationInput"]: {
	connect?:ValueTypes["IconListUniqueWhere"],
	create?:ValueTypes["IconListWithoutItemsCreateInput"]
};
	["IconListItemCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconListItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconListItemDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconListItem"],
		__typename?: true
}>;
	["IconListItemEdge"]: AliasType<{
	node?:ValueTypes["IconListItem"],
		__typename?: true
}>;
	["IconListItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	order?:ValueTypes["FieldMeta"],
	list?:ValueTypes["FieldMeta"],
	icon?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["IconListItemOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	order?:ValueTypes["OrderDirection"],
	list?:ValueTypes["IconListOrderBy"],
	icon?:ValueTypes["IconOrderBy"]
};
	["IconListItemUniqueWhere"]: {
	id?:ValueTypes["UUID"]
};
	["IconListItemUpdateIconEntityRelationInput"]: {
	create?:ValueTypes["IconCreateInput"],
	update?:ValueTypes["IconUpdateInput"],
	upsert?:ValueTypes["IconListItemUpsertIconRelationInput"],
	connect?:ValueTypes["IconUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["IconListItemUpdateInput"]: {
	order?:number,
	list?:ValueTypes["IconListItemUpdateListEntityRelationInput"],
	icon?:ValueTypes["IconListItemUpdateIconEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconListItemUpdateListEntityRelationInput"]: {
	create?:ValueTypes["IconListWithoutItemsCreateInput"],
	update?:ValueTypes["IconListWithoutItemsUpdateInput"],
	upsert?:ValueTypes["IconListItemUpsertListRelationInput"],
	connect?:ValueTypes["IconListUniqueWhere"]
};
	["IconListItemUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconListItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconListItemUpsertIconRelationInput"]: {
	update?:ValueTypes["IconUpdateInput"],
	create?:ValueTypes["IconCreateInput"]
};
	["IconListItemUpsertListRelationInput"]: {
	update?:ValueTypes["IconListWithoutItemsUpdateInput"],
	create?:ValueTypes["IconListWithoutItemsCreateInput"]
};
	["IconListItemWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	order?:ValueTypes["IntCondition"],
	list?:ValueTypes["IconListWhere"],
	icon?:ValueTypes["IconWhere"],
	and?:ValueTypes["IconListItemWhere"][],
	or?:ValueTypes["IconListItemWhere"][],
	not?:ValueTypes["IconListItemWhere"]
};
	["IconListItemWithoutListCreateInput"]: {
	order?:number,
	icon?:ValueTypes["IconListItemCreateIconEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconListItemWithoutListUpdateInput"]: {
	order?:number,
	icon?:ValueTypes["IconListItemUpdateIconEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	items?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["IconListOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"]
};
	["IconListUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	items?:ValueTypes["IconListItemUniqueWhere"]
};
	["IconListUpdateInput"]: {
	items?:ValueTypes["IconListUpdateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["IconListUpdateItemsEntityRelationInput"]: {
	create?:ValueTypes["IconListItemWithoutListCreateInput"],
	update?:ValueTypes["IconListUpdateItemsRelationInput"],
	upsert?:ValueTypes["IconListUpsertItemsRelationInput"],
	connect?:ValueTypes["IconListItemUniqueWhere"],
	disconnect?:ValueTypes["IconListItemUniqueWhere"],
	delete?:ValueTypes["IconListItemUniqueWhere"],
	alias?:string
};
	["IconListUpdateItemsRelationInput"]: {
	by?:ValueTypes["IconListItemUniqueWhere"],
	data?:ValueTypes["IconListItemWithoutListUpdateInput"]
};
	["IconListUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconList"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconListUpsertItemsRelationInput"]: {
	by?:ValueTypes["IconListItemUniqueWhere"],
	update?:ValueTypes["IconListItemWithoutListUpdateInput"],
	create?:ValueTypes["IconListItemWithoutListCreateInput"]
};
	["IconListWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	items?:ValueTypes["IconListItemWhere"],
	and?:ValueTypes["IconListWhere"][],
	or?:ValueTypes["IconListWhere"][],
	not?:ValueTypes["IconListWhere"]
};
	["IconListWithoutItemsCreateInput"]: {
	_dummy_field_?:boolean
};
	["IconListWithoutItemsUpdateInput"]: {
	_dummy_field_?:boolean
};
	["IconLocale"]: AliasType<{
	_meta?:ValueTypes["IconLocaleMeta"],
	id?:true,
	title?:true,
	alt?:true,
root?: [{	filter?:ValueTypes["IconWhere"]},ValueTypes["Icon"]],
locale?: [{	filter?:ValueTypes["LocaleWhere"]},ValueTypes["Locale"]],
		__typename?: true
}>;
	["IconLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["IconLocaleEdge"],
		__typename?: true
}>;
	["IconLocaleCreateInput"]: {
	title?:string,
	alt?:string,
	root?:ValueTypes["IconLocaleCreateRootEntityRelationInput"],
	locale?:ValueTypes["IconLocaleCreateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconLocaleCreateLocaleEntityRelationInput"]: {
	connect?:ValueTypes["LocaleUniqueWhere"],
	create?:ValueTypes["LocaleWithoutIconsCreateInput"]
};
	["IconLocaleCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconLocale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconLocaleCreateRootEntityRelationInput"]: {
	connect?:ValueTypes["IconUniqueWhere"],
	create?:ValueTypes["IconWithoutLocalesCreateInput"]
};
	["IconLocaleDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconLocale"],
		__typename?: true
}>;
	["IconLocaleEdge"]: AliasType<{
	node?:ValueTypes["IconLocale"],
		__typename?: true
}>;
	["IconLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	alt?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["IconLocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	title?:ValueTypes["OrderDirection"],
	alt?:ValueTypes["OrderDirection"],
	root?:ValueTypes["IconOrderBy"],
	locale?:ValueTypes["LocaleOrderBy"]
};
	["IconLocalesByLocaleUniqueWhere"]: {
	locale?:ValueTypes["LocaleUniqueWhere"]
};
	["IconLocaleUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	root?:ValueTypes["IconUniqueWhere"],
	locale?:ValueTypes["LocaleUniqueWhere"]
};
	["IconLocaleUpdateInput"]: {
	title?:string,
	alt?:string,
	root?:ValueTypes["IconLocaleUpdateRootEntityRelationInput"],
	locale?:ValueTypes["IconLocaleUpdateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconLocaleUpdateLocaleEntityRelationInput"]: {
	create?:ValueTypes["LocaleWithoutIconsCreateInput"],
	update?:ValueTypes["LocaleWithoutIconsUpdateInput"],
	upsert?:ValueTypes["IconLocaleUpsertLocaleRelationInput"],
	connect?:ValueTypes["LocaleUniqueWhere"]
};
	["IconLocaleUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["IconLocale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconLocaleUpdateRootEntityRelationInput"]: {
	create?:ValueTypes["IconWithoutLocalesCreateInput"],
	update?:ValueTypes["IconWithoutLocalesUpdateInput"],
	upsert?:ValueTypes["IconLocaleUpsertRootRelationInput"],
	connect?:ValueTypes["IconUniqueWhere"]
};
	["IconLocaleUpsertLocaleRelationInput"]: {
	update?:ValueTypes["LocaleWithoutIconsUpdateInput"],
	create?:ValueTypes["LocaleWithoutIconsCreateInput"]
};
	["IconLocaleUpsertRootRelationInput"]: {
	update?:ValueTypes["IconWithoutLocalesUpdateInput"],
	create?:ValueTypes["IconWithoutLocalesCreateInput"]
};
	["IconLocaleWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	title?:ValueTypes["StringCondition"],
	alt?:ValueTypes["StringCondition"],
	root?:ValueTypes["IconWhere"],
	locale?:ValueTypes["LocaleWhere"],
	and?:ValueTypes["IconLocaleWhere"][],
	or?:ValueTypes["IconLocaleWhere"][],
	not?:ValueTypes["IconLocaleWhere"]
};
	["IconLocaleWithoutLocaleCreateInput"]: {
	title?:string,
	alt?:string,
	root?:ValueTypes["IconLocaleCreateRootEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconLocaleWithoutLocaleUpdateInput"]: {
	title?:string,
	alt?:string,
	root?:ValueTypes["IconLocaleUpdateRootEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconLocaleWithoutRootCreateInput"]: {
	title?:string,
	alt?:string,
	locale?:ValueTypes["IconLocaleCreateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconLocaleWithoutRootUpdateInput"]: {
	title?:string,
	alt?:string,
	locale?:ValueTypes["IconLocaleUpdateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	key?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["IconOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	key?:ValueTypes["OrderDirection"],
	image?:ValueTypes["ImageOrderBy"]
};
	["IconUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	locales?:ValueTypes["IconLocaleUniqueWhere"]
};
	["IconUpdateImageEntityRelationInput"]: {
	create?:ValueTypes["ImageCreateInput"],
	update?:ValueTypes["ImageUpdateInput"],
	upsert?:ValueTypes["IconUpsertImageRelationInput"],
	connect?:ValueTypes["ImageUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["IconUpdateInput"]: {
	key?:string,
	image?:ValueTypes["IconUpdateImageEntityRelationInput"],
	locales?:ValueTypes["IconUpdateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["IconUpdateLocalesEntityRelationInput"]: {
	create?:ValueTypes["IconLocaleWithoutRootCreateInput"],
	update?:ValueTypes["IconUpdateLocalesRelationInput"],
	upsert?:ValueTypes["IconUpsertLocalesRelationInput"],
	connect?:ValueTypes["IconLocaleUniqueWhere"],
	disconnect?:ValueTypes["IconLocaleUniqueWhere"],
	delete?:ValueTypes["IconLocaleUniqueWhere"],
	alias?:string
};
	["IconUpdateLocalesRelationInput"]: {
	by?:ValueTypes["IconLocaleUniqueWhere"],
	data?:ValueTypes["IconLocaleWithoutRootUpdateInput"]
};
	["IconUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Icon"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["IconUpsertImageRelationInput"]: {
	update?:ValueTypes["ImageUpdateInput"],
	create?:ValueTypes["ImageCreateInput"]
};
	["IconUpsertLocalesRelationInput"]: {
	by?:ValueTypes["IconLocaleUniqueWhere"],
	update?:ValueTypes["IconLocaleWithoutRootUpdateInput"],
	create?:ValueTypes["IconLocaleWithoutRootCreateInput"]
};
	["IconWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	key?:ValueTypes["StringCondition"],
	image?:ValueTypes["ImageWhere"],
	locales?:ValueTypes["IconLocaleWhere"],
	and?:ValueTypes["IconWhere"][],
	or?:ValueTypes["IconWhere"][],
	not?:ValueTypes["IconWhere"]
};
	["IconWithoutLocalesCreateInput"]: {
	key?:string,
	image?:ValueTypes["IconCreateImageEntityRelationInput"],
	_dummy_field_?:boolean
};
	["IconWithoutLocalesUpdateInput"]: {
	key?:string,
	image?:ValueTypes["IconUpdateImageEntityRelationInput"],
	_dummy_field_?:boolean
};
	["Image"]: AliasType<{
	_meta?:ValueTypes["ImageMeta"],
	id?:true,
	url?:true,
	width?:true,
	height?:true,
	size?:true,
	type?:true,
	title?:true,
	alt?:true,
	decorationPreset?:true,
		__typename?: true
}>;
	["ImageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageEdge"],
		__typename?: true
}>;
	["ImageCreateInput"]: {
	url?:string,
	width?:number,
	height?:number,
	size?:number,
	type?:string,
	title?:string,
	alt?:string,
	decorationPreset?:string,
	_dummy_field_?:boolean
};
	["ImageCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Image"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ImageDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Image"],
		__typename?: true
}>;
	["ImageEdge"]: AliasType<{
	node?:ValueTypes["Image"],
		__typename?: true
}>;
	["ImageList"]: AliasType<{
	_meta?:ValueTypes["ImageListMeta"],
	id?:true,
images?: [{	filter?:ValueTypes["ImageXImageListWhere"],	orderBy?:ValueTypes["ImageXImageListOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ImageXImageList"]],
		__typename?: true
}>;
	["ImageListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageListEdge"],
		__typename?: true
}>;
	["ImageListCreateImagesEntityRelationInput"]: {
	connect?:ValueTypes["ImageXImageListUniqueWhere"],
	create?:ValueTypes["ImageXImageListWithoutImageListCreateInput"],
	alias?:string
};
	["ImageListCreateInput"]: {
	images?:ValueTypes["ImageListCreateImagesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["ImageListCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ImageList"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ImageListDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ImageList"],
		__typename?: true
}>;
	["ImageListEdge"]: AliasType<{
	node?:ValueTypes["ImageList"],
		__typename?: true
}>;
	["ImageListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	images?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ImageListOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"]
};
	["ImageListUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	images?:ValueTypes["ImageXImageListUniqueWhere"]
};
	["ImageListUpdateImagesEntityRelationInput"]: {
	create?:ValueTypes["ImageXImageListWithoutImageListCreateInput"],
	update?:ValueTypes["ImageListUpdateImagesRelationInput"],
	upsert?:ValueTypes["ImageListUpsertImagesRelationInput"],
	connect?:ValueTypes["ImageXImageListUniqueWhere"],
	disconnect?:ValueTypes["ImageXImageListUniqueWhere"],
	delete?:ValueTypes["ImageXImageListUniqueWhere"],
	alias?:string
};
	["ImageListUpdateImagesRelationInput"]: {
	by?:ValueTypes["ImageXImageListUniqueWhere"],
	data?:ValueTypes["ImageXImageListWithoutImageListUpdateInput"]
};
	["ImageListUpdateInput"]: {
	images?:ValueTypes["ImageListUpdateImagesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["ImageListUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ImageList"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ImageListUpsertImagesRelationInput"]: {
	by?:ValueTypes["ImageXImageListUniqueWhere"],
	update?:ValueTypes["ImageXImageListWithoutImageListUpdateInput"],
	create?:ValueTypes["ImageXImageListWithoutImageListCreateInput"]
};
	["ImageListWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	images?:ValueTypes["ImageXImageListWhere"],
	and?:ValueTypes["ImageListWhere"][],
	or?:ValueTypes["ImageListWhere"][],
	not?:ValueTypes["ImageListWhere"]
};
	["ImageListWithoutImagesCreateInput"]: {
	_dummy_field_?:boolean
};
	["ImageListWithoutImagesUpdateInput"]: {
	_dummy_field_?:boolean
};
	["ImageLocale"]: AliasType<{
	_meta?:ValueTypes["ImageLocaleMeta"],
	id?:true,
	url?:true,
	width?:true,
	height?:true,
	size?:true,
	type?:true,
	title?:true,
	alt?:true,
	decorationPreset?:true,
		__typename?: true
}>;
	["ImageLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageLocaleEdge"],
		__typename?: true
}>;
	["ImageLocaleCreateInput"]: {
	url?:string,
	width?:number,
	height?:number,
	size?:number,
	type?:string,
	title?:string,
	alt?:string,
	decorationPreset?:string,
	_dummy_field_?:boolean
};
	["ImageLocaleCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ImageLocale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ImageLocaleDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ImageLocale"],
		__typename?: true
}>;
	["ImageLocaleEdge"]: AliasType<{
	node?:ValueTypes["ImageLocale"],
		__typename?: true
}>;
	["ImageLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	width?:ValueTypes["FieldMeta"],
	height?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	alt?:ValueTypes["FieldMeta"],
	decorationPreset?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ImageLocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	url?:ValueTypes["OrderDirection"],
	width?:ValueTypes["OrderDirection"],
	height?:ValueTypes["OrderDirection"],
	size?:ValueTypes["OrderDirection"],
	type?:ValueTypes["OrderDirection"],
	title?:ValueTypes["OrderDirection"],
	alt?:ValueTypes["OrderDirection"],
	decorationPreset?:ValueTypes["OrderDirection"]
};
	["ImageLocaleUniqueWhere"]: {
	id?:ValueTypes["UUID"]
};
	["ImageLocaleUpdateInput"]: {
	url?:string,
	width?:number,
	height?:number,
	size?:number,
	type?:string,
	title?:string,
	alt?:string,
	decorationPreset?:string,
	_dummy_field_?:boolean
};
	["ImageLocaleUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ImageLocale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ImageLocaleWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	url?:ValueTypes["StringCondition"],
	width?:ValueTypes["IntCondition"],
	height?:ValueTypes["IntCondition"],
	size?:ValueTypes["IntCondition"],
	type?:ValueTypes["StringCondition"],
	title?:ValueTypes["StringCondition"],
	alt?:ValueTypes["StringCondition"],
	decorationPreset?:ValueTypes["StringCondition"],
	and?:ValueTypes["ImageLocaleWhere"][],
	or?:ValueTypes["ImageLocaleWhere"][],
	not?:ValueTypes["ImageLocaleWhere"]
};
	["ImageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	width?:ValueTypes["FieldMeta"],
	height?:ValueTypes["FieldMeta"],
	size?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	alt?:ValueTypes["FieldMeta"],
	decorationPreset?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ImageOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	url?:ValueTypes["OrderDirection"],
	width?:ValueTypes["OrderDirection"],
	height?:ValueTypes["OrderDirection"],
	size?:ValueTypes["OrderDirection"],
	type?:ValueTypes["OrderDirection"],
	title?:ValueTypes["OrderDirection"],
	alt?:ValueTypes["OrderDirection"],
	decorationPreset?:ValueTypes["OrderDirection"]
};
	["ImageUniqueWhere"]: {
	id?:ValueTypes["UUID"]
};
	["ImageUpdateInput"]: {
	url?:string,
	width?:number,
	height?:number,
	size?:number,
	type?:string,
	title?:string,
	alt?:string,
	decorationPreset?:string,
	_dummy_field_?:boolean
};
	["ImageUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Image"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ImageWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	url?:ValueTypes["StringCondition"],
	width?:ValueTypes["IntCondition"],
	height?:ValueTypes["IntCondition"],
	size?:ValueTypes["IntCondition"],
	type?:ValueTypes["StringCondition"],
	title?:ValueTypes["StringCondition"],
	alt?:ValueTypes["StringCondition"],
	decorationPreset?:ValueTypes["StringCondition"],
	and?:ValueTypes["ImageWhere"][],
	or?:ValueTypes["ImageWhere"][],
	not?:ValueTypes["ImageWhere"]
};
	["ImageXImageList"]: AliasType<{
	_meta?:ValueTypes["ImageXImageListMeta"],
	id?:true,
	imagePosition?:true,
imageList?: [{	filter?:ValueTypes["ImageListWhere"]},ValueTypes["ImageList"]],
image?: [{	filter?:ValueTypes["ImageWhere"]},ValueTypes["Image"]],
		__typename?: true
}>;
	["ImageXImageListConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ImageXImageListEdge"],
		__typename?: true
}>;
	["ImageXImageListCreateImageEntityRelationInput"]: {
	connect?:ValueTypes["ImageUniqueWhere"],
	create?:ValueTypes["ImageCreateInput"]
};
	["ImageXImageListCreateImageListEntityRelationInput"]: {
	connect?:ValueTypes["ImageListUniqueWhere"],
	create?:ValueTypes["ImageListWithoutImagesCreateInput"]
};
	["ImageXImageListCreateInput"]: {
	imagePosition?:number,
	imageList?:ValueTypes["ImageXImageListCreateImageListEntityRelationInput"],
	image?:ValueTypes["ImageXImageListCreateImageEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ImageXImageListCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ImageXImageList"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ImageXImageListDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ImageXImageList"],
		__typename?: true
}>;
	["ImageXImageListEdge"]: AliasType<{
	node?:ValueTypes["ImageXImageList"],
		__typename?: true
}>;
	["ImageXImageListMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	imagePosition?:ValueTypes["FieldMeta"],
	imageList?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ImageXImageListOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	imagePosition?:ValueTypes["OrderDirection"],
	imageList?:ValueTypes["ImageListOrderBy"],
	image?:ValueTypes["ImageOrderBy"]
};
	["ImageXImageListUniqueWhere"]: {
	id?:ValueTypes["UUID"]
};
	["ImageXImageListUpdateImageEntityRelationInput"]: {
	create?:ValueTypes["ImageCreateInput"],
	update?:ValueTypes["ImageUpdateInput"],
	upsert?:ValueTypes["ImageXImageListUpsertImageRelationInput"],
	connect?:ValueTypes["ImageUniqueWhere"]
};
	["ImageXImageListUpdateImageListEntityRelationInput"]: {
	create?:ValueTypes["ImageListWithoutImagesCreateInput"],
	update?:ValueTypes["ImageListWithoutImagesUpdateInput"],
	upsert?:ValueTypes["ImageXImageListUpsertImageListRelationInput"],
	connect?:ValueTypes["ImageListUniqueWhere"]
};
	["ImageXImageListUpdateInput"]: {
	imagePosition?:number,
	imageList?:ValueTypes["ImageXImageListUpdateImageListEntityRelationInput"],
	image?:ValueTypes["ImageXImageListUpdateImageEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ImageXImageListUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ImageXImageList"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ImageXImageListUpsertImageListRelationInput"]: {
	update?:ValueTypes["ImageListWithoutImagesUpdateInput"],
	create?:ValueTypes["ImageListWithoutImagesCreateInput"]
};
	["ImageXImageListUpsertImageRelationInput"]: {
	update?:ValueTypes["ImageUpdateInput"],
	create?:ValueTypes["ImageCreateInput"]
};
	["ImageXImageListWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	imagePosition?:ValueTypes["IntCondition"],
	imageList?:ValueTypes["ImageListWhere"],
	image?:ValueTypes["ImageWhere"],
	and?:ValueTypes["ImageXImageListWhere"][],
	or?:ValueTypes["ImageXImageListWhere"][],
	not?:ValueTypes["ImageXImageListWhere"]
};
	["ImageXImageListWithoutImageListCreateInput"]: {
	imagePosition?:number,
	image?:ValueTypes["ImageXImageListCreateImageEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ImageXImageListWithoutImageListUpdateInput"]: {
	imagePosition?:number,
	image?:ValueTypes["ImageXImageListUpdateImageEntityRelationInput"],
	_dummy_field_?:boolean
};
	["Info"]: AliasType<{
	description?:true,
		__typename?: true
}>;
	["IntCondition"]: {
	and?:ValueTypes["IntCondition"][],
	or?:ValueTypes["IntCondition"][],
	not?:ValueTypes["IntCondition"],
	eq?:number,
	null?:boolean,
	isNull?:boolean,
	notEq?:number,
	in?:number[],
	notIn?:number[],
	lt?:number,
	lte?:number,
	gt?:number,
	gte?:number
};
	["Link"]: AliasType<{
	_meta?:ValueTypes["LinkMeta"],
	id?:true,
	title?:true,
	externalLink?:true,
internalLink?: [{	filter?:ValueTypes["LinkableWhere"]},ValueTypes["Linkable"]],
		__typename?: true
}>;
	["Linkable"]: AliasType<{
	_meta?:ValueTypes["LinkableMeta"],
	id?:true,
	url?:true,
page?: [{	filter?:ValueTypes["PageLocaleWhere"]},ValueTypes["PageLocale"]],
		__typename?: true
}>;
	["LinkableConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkableEdge"],
		__typename?: true
}>;
	["LinkableCreateInput"]: {
	url?:string,
	page?:ValueTypes["LinkableCreatePageEntityRelationInput"],
	_dummy_field_?:boolean
};
	["LinkableCreatePageEntityRelationInput"]: {
	connect?:ValueTypes["PageLocaleUniqueWhere"],
	create?:ValueTypes["PageLocaleWithoutLinkCreateInput"]
};
	["LinkableCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Linkable"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["LinkableDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Linkable"],
		__typename?: true
}>;
	["LinkableEdge"]: AliasType<{
	node?:ValueTypes["Linkable"],
		__typename?: true
}>;
	["LinkableMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	page?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["LinkableOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	url?:ValueTypes["OrderDirection"],
	page?:ValueTypes["PageLocaleOrderBy"]
};
	["LinkableUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	url?:string,
	page?:ValueTypes["PageLocaleUniqueWhere"]
};
	["LinkableUpdateInput"]: {
	url?:string,
	page?:ValueTypes["LinkableUpdatePageEntityRelationInput"],
	_dummy_field_?:boolean
};
	["LinkableUpdatePageEntityRelationInput"]: {
	create?:ValueTypes["PageLocaleWithoutLinkCreateInput"],
	update?:ValueTypes["PageLocaleWithoutLinkUpdateInput"],
	upsert?:ValueTypes["LinkableUpsertPageRelationInput"],
	connect?:ValueTypes["PageLocaleUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["LinkableUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Linkable"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["LinkableUpsertPageRelationInput"]: {
	update?:ValueTypes["PageLocaleWithoutLinkUpdateInput"],
	create?:ValueTypes["PageLocaleWithoutLinkCreateInput"]
};
	["LinkableWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	url?:ValueTypes["StringCondition"],
	page?:ValueTypes["PageLocaleWhere"],
	and?:ValueTypes["LinkableWhere"][],
	or?:ValueTypes["LinkableWhere"][],
	not?:ValueTypes["LinkableWhere"]
};
	["LinkableWithoutPageCreateInput"]: {
	url?:string,
	_dummy_field_?:boolean
};
	["LinkableWithoutPageUpdateInput"]: {
	url?:string,
	_dummy_field_?:boolean
};
	["LinkConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LinkEdge"],
		__typename?: true
}>;
	["LinkCreateInput"]: {
	title?:string,
	externalLink?:string,
	internalLink?:ValueTypes["LinkCreateInternalLinkEntityRelationInput"],
	_dummy_field_?:boolean
};
	["LinkCreateInternalLinkEntityRelationInput"]: {
	connect?:ValueTypes["LinkableUniqueWhere"],
	create?:ValueTypes["LinkableCreateInput"]
};
	["LinkCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Link"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["LinkDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Link"],
		__typename?: true
}>;
	["LinkEdge"]: AliasType<{
	node?:ValueTypes["Link"],
		__typename?: true
}>;
	["LinkMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	externalLink?:ValueTypes["FieldMeta"],
	internalLink?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["LinkOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	title?:ValueTypes["OrderDirection"],
	externalLink?:ValueTypes["OrderDirection"],
	internalLink?:ValueTypes["LinkableOrderBy"]
};
	["LinkUniqueWhere"]: {
	id?:ValueTypes["UUID"]
};
	["LinkUpdateInput"]: {
	title?:string,
	externalLink?:string,
	internalLink?:ValueTypes["LinkUpdateInternalLinkEntityRelationInput"],
	_dummy_field_?:boolean
};
	["LinkUpdateInternalLinkEntityRelationInput"]: {
	create?:ValueTypes["LinkableCreateInput"],
	update?:ValueTypes["LinkableUpdateInput"],
	upsert?:ValueTypes["LinkUpsertInternalLinkRelationInput"],
	connect?:ValueTypes["LinkableUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["LinkUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Link"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["LinkUpsertInternalLinkRelationInput"]: {
	update?:ValueTypes["LinkableUpdateInput"],
	create?:ValueTypes["LinkableCreateInput"]
};
	["LinkWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	title?:ValueTypes["StringCondition"],
	externalLink?:ValueTypes["StringCondition"],
	internalLink?:ValueTypes["LinkableWhere"],
	and?:ValueTypes["LinkWhere"][],
	or?:ValueTypes["LinkWhere"][],
	not?:ValueTypes["LinkWhere"]
};
	["Locale"]: AliasType<{
	_meta?:ValueTypes["LocaleMeta"],
	id?:true,
	code?:true,
iconGroups?: [{	filter?:ValueTypes["IconGroupLocaleWhere"],	orderBy?:ValueTypes["IconGroupLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconGroupLocale"]],
icons?: [{	filter?:ValueTypes["IconLocaleWhere"],	orderBy?:ValueTypes["IconLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconLocale"]],
productVariants?: [{	filter?:ValueTypes["ProductVariantLocaleWhere"],	orderBy?:ValueTypes["ProductVariantLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductVariantLocale"]],
seos?: [{	filter?:ValueTypes["SeoLocaleWhere"],	orderBy?:ValueTypes["SeoLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["SeoLocale"]],
pages?: [{	filter?:ValueTypes["PageLocaleWhere"],	orderBy?:ValueTypes["PageLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["PageLocale"]],
products?: [{	filter?:ValueTypes["ProductLocaleWhere"],	orderBy?:ValueTypes["ProductLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductLocale"]],
iconGroupsByRoot?: [{	by:ValueTypes["LocaleIconGroupsByRootUniqueWhere"],	filter?:ValueTypes["IconGroupLocaleWhere"]},ValueTypes["IconGroupLocale"]],
iconsByRoot?: [{	by:ValueTypes["LocaleIconsByRootUniqueWhere"],	filter?:ValueTypes["IconLocaleWhere"]},ValueTypes["IconLocale"]],
productVariantsByRoot?: [{	by:ValueTypes["LocaleProductVariantsByRootUniqueWhere"],	filter?:ValueTypes["ProductVariantLocaleWhere"]},ValueTypes["ProductVariantLocale"]],
seosByRoot?: [{	by:ValueTypes["LocaleSeosByRootUniqueWhere"],	filter?:ValueTypes["SeoLocaleWhere"]},ValueTypes["SeoLocale"]],
pagesByRoot?: [{	by:ValueTypes["LocalePagesByRootUniqueWhere"],	filter?:ValueTypes["PageLocaleWhere"]},ValueTypes["PageLocale"]],
pagesByLink?: [{	by:ValueTypes["LocalePagesByLinkUniqueWhere"],	filter?:ValueTypes["PageLocaleWhere"]},ValueTypes["PageLocale"]],
pagesByContent?: [{	by:ValueTypes["LocalePagesByContentUniqueWhere"],	filter?:ValueTypes["PageLocaleWhere"]},ValueTypes["PageLocale"]],
productsByRoot?: [{	by:ValueTypes["LocaleProductsByRootUniqueWhere"],	filter?:ValueTypes["ProductLocaleWhere"]},ValueTypes["ProductLocale"]],
		__typename?: true
}>;
	["LocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["LocaleEdge"],
		__typename?: true
}>;
	["LocaleCreateIconGroupsEntityRelationInput"]: {
	connect?:ValueTypes["IconGroupLocaleUniqueWhere"],
	create?:ValueTypes["IconGroupLocaleWithoutLocaleCreateInput"],
	alias?:string
};
	["LocaleCreateIconsEntityRelationInput"]: {
	connect?:ValueTypes["IconLocaleUniqueWhere"],
	create?:ValueTypes["IconLocaleWithoutLocaleCreateInput"],
	alias?:string
};
	["LocaleCreateInput"]: {
	code?:string,
	iconGroups?:ValueTypes["LocaleCreateIconGroupsEntityRelationInput"][],
	icons?:ValueTypes["LocaleCreateIconsEntityRelationInput"][],
	productVariants?:ValueTypes["LocaleCreateProductVariantsEntityRelationInput"][],
	seos?:ValueTypes["LocaleCreateSeosEntityRelationInput"][],
	pages?:ValueTypes["LocaleCreatePagesEntityRelationInput"][],
	products?:ValueTypes["LocaleCreateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["LocaleCreatePagesEntityRelationInput"]: {
	connect?:ValueTypes["PageLocaleUniqueWhere"],
	create?:ValueTypes["PageLocaleWithoutLocaleCreateInput"],
	alias?:string
};
	["LocaleCreateProductsEntityRelationInput"]: {
	connect?:ValueTypes["ProductLocaleUniqueWhere"],
	create?:ValueTypes["ProductLocaleWithoutLocaleCreateInput"],
	alias?:string
};
	["LocaleCreateProductVariantsEntityRelationInput"]: {
	connect?:ValueTypes["ProductVariantLocaleUniqueWhere"],
	create?:ValueTypes["ProductVariantLocaleWithoutLocaleCreateInput"],
	alias?:string
};
	["LocaleCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Locale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["LocaleCreateSeosEntityRelationInput"]: {
	connect?:ValueTypes["SeoLocaleUniqueWhere"],
	create?:ValueTypes["SeoLocaleWithoutLocaleCreateInput"],
	alias?:string
};
	["LocaleDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Locale"],
		__typename?: true
}>;
	["LocaleEdge"]: AliasType<{
	node?:ValueTypes["Locale"],
		__typename?: true
}>;
	["LocaleIconGroupsByRootUniqueWhere"]: {
	root?:ValueTypes["IconGroupUniqueWhere"]
};
	["LocaleIconsByRootUniqueWhere"]: {
	root?:ValueTypes["IconUniqueWhere"]
};
	["LocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	iconGroups?:ValueTypes["FieldMeta"],
	icons?:ValueTypes["FieldMeta"],
	productVariants?:ValueTypes["FieldMeta"],
	seos?:ValueTypes["FieldMeta"],
	pages?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["LocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	code?:ValueTypes["OrderDirection"]
};
	["LocalePagesByContentUniqueWhere"]: {
	content?:ValueTypes["ContentUniqueWhere"]
};
	["LocalePagesByLinkUniqueWhere"]: {
	link?:ValueTypes["LinkableUniqueWhere"]
};
	["LocalePagesByRootUniqueWhere"]: {
	root?:ValueTypes["PageUniqueWhere"]
};
	["LocaleProductsByRootUniqueWhere"]: {
	root?:ValueTypes["ProductUniqueWhere"]
};
	["LocaleProductVariantsByRootUniqueWhere"]: {
	root?:ValueTypes["ProductVariantUniqueWhere"]
};
	["LocaleSeosByRootUniqueWhere"]: {
	root?:ValueTypes["SeoUniqueWhere"]
};
	["LocaleUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	code?:string,
	iconGroups?:ValueTypes["IconGroupLocaleUniqueWhere"],
	icons?:ValueTypes["IconLocaleUniqueWhere"],
	productVariants?:ValueTypes["ProductVariantLocaleUniqueWhere"],
	seos?:ValueTypes["SeoLocaleUniqueWhere"],
	pages?:ValueTypes["PageLocaleUniqueWhere"],
	products?:ValueTypes["ProductLocaleUniqueWhere"]
};
	["LocaleUpdateIconGroupsEntityRelationInput"]: {
	create?:ValueTypes["IconGroupLocaleWithoutLocaleCreateInput"],
	update?:ValueTypes["LocaleUpdateIconGroupsRelationInput"],
	upsert?:ValueTypes["LocaleUpsertIconGroupsRelationInput"],
	connect?:ValueTypes["IconGroupLocaleUniqueWhere"],
	disconnect?:ValueTypes["IconGroupLocaleUniqueWhere"],
	delete?:ValueTypes["IconGroupLocaleUniqueWhere"],
	alias?:string
};
	["LocaleUpdateIconGroupsRelationInput"]: {
	by?:ValueTypes["IconGroupLocaleUniqueWhere"],
	data?:ValueTypes["IconGroupLocaleWithoutLocaleUpdateInput"]
};
	["LocaleUpdateIconsEntityRelationInput"]: {
	create?:ValueTypes["IconLocaleWithoutLocaleCreateInput"],
	update?:ValueTypes["LocaleUpdateIconsRelationInput"],
	upsert?:ValueTypes["LocaleUpsertIconsRelationInput"],
	connect?:ValueTypes["IconLocaleUniqueWhere"],
	disconnect?:ValueTypes["IconLocaleUniqueWhere"],
	delete?:ValueTypes["IconLocaleUniqueWhere"],
	alias?:string
};
	["LocaleUpdateIconsRelationInput"]: {
	by?:ValueTypes["IconLocaleUniqueWhere"],
	data?:ValueTypes["IconLocaleWithoutLocaleUpdateInput"]
};
	["LocaleUpdateInput"]: {
	code?:string,
	iconGroups?:ValueTypes["LocaleUpdateIconGroupsEntityRelationInput"][],
	icons?:ValueTypes["LocaleUpdateIconsEntityRelationInput"][],
	productVariants?:ValueTypes["LocaleUpdateProductVariantsEntityRelationInput"][],
	seos?:ValueTypes["LocaleUpdateSeosEntityRelationInput"][],
	pages?:ValueTypes["LocaleUpdatePagesEntityRelationInput"][],
	products?:ValueTypes["LocaleUpdateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["LocaleUpdatePagesEntityRelationInput"]: {
	create?:ValueTypes["PageLocaleWithoutLocaleCreateInput"],
	update?:ValueTypes["LocaleUpdatePagesRelationInput"],
	upsert?:ValueTypes["LocaleUpsertPagesRelationInput"],
	connect?:ValueTypes["PageLocaleUniqueWhere"],
	disconnect?:ValueTypes["PageLocaleUniqueWhere"],
	delete?:ValueTypes["PageLocaleUniqueWhere"],
	alias?:string
};
	["LocaleUpdatePagesRelationInput"]: {
	by?:ValueTypes["PageLocaleUniqueWhere"],
	data?:ValueTypes["PageLocaleWithoutLocaleUpdateInput"]
};
	["LocaleUpdateProductsEntityRelationInput"]: {
	create?:ValueTypes["ProductLocaleWithoutLocaleCreateInput"],
	update?:ValueTypes["LocaleUpdateProductsRelationInput"],
	upsert?:ValueTypes["LocaleUpsertProductsRelationInput"],
	connect?:ValueTypes["ProductLocaleUniqueWhere"],
	disconnect?:ValueTypes["ProductLocaleUniqueWhere"],
	delete?:ValueTypes["ProductLocaleUniqueWhere"],
	alias?:string
};
	["LocaleUpdateProductsRelationInput"]: {
	by?:ValueTypes["ProductLocaleUniqueWhere"],
	data?:ValueTypes["ProductLocaleWithoutLocaleUpdateInput"]
};
	["LocaleUpdateProductVariantsEntityRelationInput"]: {
	create?:ValueTypes["ProductVariantLocaleWithoutLocaleCreateInput"],
	update?:ValueTypes["LocaleUpdateProductVariantsRelationInput"],
	upsert?:ValueTypes["LocaleUpsertProductVariantsRelationInput"],
	connect?:ValueTypes["ProductVariantLocaleUniqueWhere"],
	disconnect?:ValueTypes["ProductVariantLocaleUniqueWhere"],
	delete?:ValueTypes["ProductVariantLocaleUniqueWhere"],
	alias?:string
};
	["LocaleUpdateProductVariantsRelationInput"]: {
	by?:ValueTypes["ProductVariantLocaleUniqueWhere"],
	data?:ValueTypes["ProductVariantLocaleWithoutLocaleUpdateInput"]
};
	["LocaleUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Locale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["LocaleUpdateSeosEntityRelationInput"]: {
	create?:ValueTypes["SeoLocaleWithoutLocaleCreateInput"],
	update?:ValueTypes["LocaleUpdateSeosRelationInput"],
	upsert?:ValueTypes["LocaleUpsertSeosRelationInput"],
	connect?:ValueTypes["SeoLocaleUniqueWhere"],
	disconnect?:ValueTypes["SeoLocaleUniqueWhere"],
	delete?:ValueTypes["SeoLocaleUniqueWhere"],
	alias?:string
};
	["LocaleUpdateSeosRelationInput"]: {
	by?:ValueTypes["SeoLocaleUniqueWhere"],
	data?:ValueTypes["SeoLocaleWithoutLocaleUpdateInput"]
};
	["LocaleUpsertIconGroupsRelationInput"]: {
	by?:ValueTypes["IconGroupLocaleUniqueWhere"],
	update?:ValueTypes["IconGroupLocaleWithoutLocaleUpdateInput"],
	create?:ValueTypes["IconGroupLocaleWithoutLocaleCreateInput"]
};
	["LocaleUpsertIconsRelationInput"]: {
	by?:ValueTypes["IconLocaleUniqueWhere"],
	update?:ValueTypes["IconLocaleWithoutLocaleUpdateInput"],
	create?:ValueTypes["IconLocaleWithoutLocaleCreateInput"]
};
	["LocaleUpsertPagesRelationInput"]: {
	by?:ValueTypes["PageLocaleUniqueWhere"],
	update?:ValueTypes["PageLocaleWithoutLocaleUpdateInput"],
	create?:ValueTypes["PageLocaleWithoutLocaleCreateInput"]
};
	["LocaleUpsertProductsRelationInput"]: {
	by?:ValueTypes["ProductLocaleUniqueWhere"],
	update?:ValueTypes["ProductLocaleWithoutLocaleUpdateInput"],
	create?:ValueTypes["ProductLocaleWithoutLocaleCreateInput"]
};
	["LocaleUpsertProductVariantsRelationInput"]: {
	by?:ValueTypes["ProductVariantLocaleUniqueWhere"],
	update?:ValueTypes["ProductVariantLocaleWithoutLocaleUpdateInput"],
	create?:ValueTypes["ProductVariantLocaleWithoutLocaleCreateInput"]
};
	["LocaleUpsertSeosRelationInput"]: {
	by?:ValueTypes["SeoLocaleUniqueWhere"],
	update?:ValueTypes["SeoLocaleWithoutLocaleUpdateInput"],
	create?:ValueTypes["SeoLocaleWithoutLocaleCreateInput"]
};
	["LocaleWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	code?:ValueTypes["StringCondition"],
	iconGroups?:ValueTypes["IconGroupLocaleWhere"],
	icons?:ValueTypes["IconLocaleWhere"],
	productVariants?:ValueTypes["ProductVariantLocaleWhere"],
	seos?:ValueTypes["SeoLocaleWhere"],
	pages?:ValueTypes["PageLocaleWhere"],
	products?:ValueTypes["ProductLocaleWhere"],
	and?:ValueTypes["LocaleWhere"][],
	or?:ValueTypes["LocaleWhere"][],
	not?:ValueTypes["LocaleWhere"]
};
	["LocaleWithoutIconGroupsCreateInput"]: {
	code?:string,
	icons?:ValueTypes["LocaleCreateIconsEntityRelationInput"][],
	productVariants?:ValueTypes["LocaleCreateProductVariantsEntityRelationInput"][],
	seos?:ValueTypes["LocaleCreateSeosEntityRelationInput"][],
	pages?:ValueTypes["LocaleCreatePagesEntityRelationInput"][],
	products?:ValueTypes["LocaleCreateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["LocaleWithoutIconGroupsUpdateInput"]: {
	code?:string,
	icons?:ValueTypes["LocaleUpdateIconsEntityRelationInput"][],
	productVariants?:ValueTypes["LocaleUpdateProductVariantsEntityRelationInput"][],
	seos?:ValueTypes["LocaleUpdateSeosEntityRelationInput"][],
	pages?:ValueTypes["LocaleUpdatePagesEntityRelationInput"][],
	products?:ValueTypes["LocaleUpdateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["LocaleWithoutIconsCreateInput"]: {
	code?:string,
	iconGroups?:ValueTypes["LocaleCreateIconGroupsEntityRelationInput"][],
	productVariants?:ValueTypes["LocaleCreateProductVariantsEntityRelationInput"][],
	seos?:ValueTypes["LocaleCreateSeosEntityRelationInput"][],
	pages?:ValueTypes["LocaleCreatePagesEntityRelationInput"][],
	products?:ValueTypes["LocaleCreateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["LocaleWithoutIconsUpdateInput"]: {
	code?:string,
	iconGroups?:ValueTypes["LocaleUpdateIconGroupsEntityRelationInput"][],
	productVariants?:ValueTypes["LocaleUpdateProductVariantsEntityRelationInput"][],
	seos?:ValueTypes["LocaleUpdateSeosEntityRelationInput"][],
	pages?:ValueTypes["LocaleUpdatePagesEntityRelationInput"][],
	products?:ValueTypes["LocaleUpdateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["LocaleWithoutPagesCreateInput"]: {
	code?:string,
	iconGroups?:ValueTypes["LocaleCreateIconGroupsEntityRelationInput"][],
	icons?:ValueTypes["LocaleCreateIconsEntityRelationInput"][],
	productVariants?:ValueTypes["LocaleCreateProductVariantsEntityRelationInput"][],
	seos?:ValueTypes["LocaleCreateSeosEntityRelationInput"][],
	products?:ValueTypes["LocaleCreateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["LocaleWithoutPagesUpdateInput"]: {
	code?:string,
	iconGroups?:ValueTypes["LocaleUpdateIconGroupsEntityRelationInput"][],
	icons?:ValueTypes["LocaleUpdateIconsEntityRelationInput"][],
	productVariants?:ValueTypes["LocaleUpdateProductVariantsEntityRelationInput"][],
	seos?:ValueTypes["LocaleUpdateSeosEntityRelationInput"][],
	products?:ValueTypes["LocaleUpdateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["LocaleWithoutProductsCreateInput"]: {
	code?:string,
	iconGroups?:ValueTypes["LocaleCreateIconGroupsEntityRelationInput"][],
	icons?:ValueTypes["LocaleCreateIconsEntityRelationInput"][],
	productVariants?:ValueTypes["LocaleCreateProductVariantsEntityRelationInput"][],
	seos?:ValueTypes["LocaleCreateSeosEntityRelationInput"][],
	pages?:ValueTypes["LocaleCreatePagesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["LocaleWithoutProductsUpdateInput"]: {
	code?:string,
	iconGroups?:ValueTypes["LocaleUpdateIconGroupsEntityRelationInput"][],
	icons?:ValueTypes["LocaleUpdateIconsEntityRelationInput"][],
	productVariants?:ValueTypes["LocaleUpdateProductVariantsEntityRelationInput"][],
	seos?:ValueTypes["LocaleUpdateSeosEntityRelationInput"][],
	pages?:ValueTypes["LocaleUpdatePagesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["LocaleWithoutProductVariantsCreateInput"]: {
	code?:string,
	iconGroups?:ValueTypes["LocaleCreateIconGroupsEntityRelationInput"][],
	icons?:ValueTypes["LocaleCreateIconsEntityRelationInput"][],
	seos?:ValueTypes["LocaleCreateSeosEntityRelationInput"][],
	pages?:ValueTypes["LocaleCreatePagesEntityRelationInput"][],
	products?:ValueTypes["LocaleCreateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["LocaleWithoutProductVariantsUpdateInput"]: {
	code?:string,
	iconGroups?:ValueTypes["LocaleUpdateIconGroupsEntityRelationInput"][],
	icons?:ValueTypes["LocaleUpdateIconsEntityRelationInput"][],
	seos?:ValueTypes["LocaleUpdateSeosEntityRelationInput"][],
	pages?:ValueTypes["LocaleUpdatePagesEntityRelationInput"][],
	products?:ValueTypes["LocaleUpdateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["LocaleWithoutSeosCreateInput"]: {
	code?:string,
	iconGroups?:ValueTypes["LocaleCreateIconGroupsEntityRelationInput"][],
	icons?:ValueTypes["LocaleCreateIconsEntityRelationInput"][],
	productVariants?:ValueTypes["LocaleCreateProductVariantsEntityRelationInput"][],
	pages?:ValueTypes["LocaleCreatePagesEntityRelationInput"][],
	products?:ValueTypes["LocaleCreateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["LocaleWithoutSeosUpdateInput"]: {
	code?:string,
	iconGroups?:ValueTypes["LocaleUpdateIconGroupsEntityRelationInput"][],
	icons?:ValueTypes["LocaleUpdateIconsEntityRelationInput"][],
	productVariants?:ValueTypes["LocaleUpdateProductVariantsEntityRelationInput"][],
	pages?:ValueTypes["LocaleUpdatePagesEntityRelationInput"][],
	products?:ValueTypes["LocaleUpdateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["Media"]: AliasType<{
	_meta?:ValueTypes["MediaMeta"],
	id?:true,
	url?:true,
	videoUrl?:true,
	width?:true,
	height?:true,
	poster?:true,
	type?:true,
	caption?:true,
	audio?:true,
		__typename?: true
}>;
	["MediaConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["MediaEdge"],
		__typename?: true
}>;
	["MediaCreateInput"]: {
	url?:string,
	videoUrl?:string,
	width?:number,
	height?:number,
	poster?:string,
	type?:ValueTypes["MediaItemType"],
	caption?:string,
	audio?:boolean,
	_dummy_field_?:boolean
};
	["MediaCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Media"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["MediaDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Media"],
		__typename?: true
}>;
	["MediaEdge"]: AliasType<{
	node?:ValueTypes["Media"],
		__typename?: true
}>;
	["MediaItemType"]:MediaItemType;
	["MediaItemTypeEnumCondition"]: {
	and?:ValueTypes["MediaItemTypeEnumCondition"][],
	or?:ValueTypes["MediaItemTypeEnumCondition"][],
	not?:ValueTypes["MediaItemTypeEnumCondition"],
	eq?:ValueTypes["MediaItemType"],
	null?:boolean,
	isNull?:boolean,
	notEq?:ValueTypes["MediaItemType"],
	in?:ValueTypes["MediaItemType"][],
	notIn?:ValueTypes["MediaItemType"][],
	lt?:ValueTypes["MediaItemType"],
	lte?:ValueTypes["MediaItemType"],
	gt?:ValueTypes["MediaItemType"],
	gte?:ValueTypes["MediaItemType"]
};
	["MediaMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	url?:ValueTypes["FieldMeta"],
	videoUrl?:ValueTypes["FieldMeta"],
	width?:ValueTypes["FieldMeta"],
	height?:ValueTypes["FieldMeta"],
	poster?:ValueTypes["FieldMeta"],
	type?:ValueTypes["FieldMeta"],
	caption?:ValueTypes["FieldMeta"],
	audio?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["MediaOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	url?:ValueTypes["OrderDirection"],
	videoUrl?:ValueTypes["OrderDirection"],
	width?:ValueTypes["OrderDirection"],
	height?:ValueTypes["OrderDirection"],
	poster?:ValueTypes["OrderDirection"],
	type?:ValueTypes["OrderDirection"],
	caption?:ValueTypes["OrderDirection"],
	audio?:ValueTypes["OrderDirection"]
};
	["MediaUniqueWhere"]: {
	id?:ValueTypes["UUID"]
};
	["MediaUpdateInput"]: {
	url?:string,
	videoUrl?:string,
	width?:number,
	height?:number,
	poster?:string,
	type?:ValueTypes["MediaItemType"],
	caption?:string,
	audio?:boolean,
	_dummy_field_?:boolean
};
	["MediaUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Media"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["MediaWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	url?:ValueTypes["StringCondition"],
	videoUrl?:ValueTypes["StringCondition"],
	width?:ValueTypes["IntCondition"],
	height?:ValueTypes["IntCondition"],
	poster?:ValueTypes["StringCondition"],
	type?:ValueTypes["MediaItemTypeEnumCondition"],
	caption?:ValueTypes["StringCondition"],
	audio?:ValueTypes["BooleanCondition"],
	and?:ValueTypes["MediaWhere"][],
	or?:ValueTypes["MediaWhere"][],
	not?:ValueTypes["MediaWhere"]
};
	["Mutation"]: AliasType<{
createAbandonedCheckoutUnsubscription?: [{	data:ValueTypes["AbandonedCheckoutUnsubscriptionCreateInput"]},ValueTypes["AbandonedCheckoutUnsubscriptionCreateResult"]],
deleteAbandonedCheckoutUnsubscription?: [{	by:ValueTypes["AbandonedCheckoutUnsubscriptionUniqueWhere"],	filter?:ValueTypes["AbandonedCheckoutUnsubscriptionWhere"]},ValueTypes["AbandonedCheckoutUnsubscriptionDeleteResult"]],
updateAbandonedCheckoutUnsubscription?: [{	by:ValueTypes["AbandonedCheckoutUnsubscriptionUniqueWhere"],	filter?:ValueTypes["AbandonedCheckoutUnsubscriptionWhere"],	data:ValueTypes["AbandonedCheckoutUnsubscriptionUpdateInput"]},ValueTypes["AbandonedCheckoutUnsubscriptionUpdateResult"]],
createAbandonedCheckoutNotification?: [{	data:ValueTypes["AbandonedCheckoutNotificationCreateInput"]},ValueTypes["AbandonedCheckoutNotificationCreateResult"]],
deleteAbandonedCheckoutNotification?: [{	by:ValueTypes["AbandonedCheckoutNotificationUniqueWhere"],	filter?:ValueTypes["AbandonedCheckoutNotificationWhere"]},ValueTypes["AbandonedCheckoutNotificationDeleteResult"]],
updateAbandonedCheckoutNotification?: [{	by:ValueTypes["AbandonedCheckoutNotificationUniqueWhere"],	filter?:ValueTypes["AbandonedCheckoutNotificationWhere"],	data:ValueTypes["AbandonedCheckoutNotificationUpdateInput"]},ValueTypes["AbandonedCheckoutNotificationUpdateResult"]],
createShopifyLineItem?: [{	data:ValueTypes["ShopifyLineItemCreateInput"]},ValueTypes["ShopifyLineItemCreateResult"]],
deleteShopifyLineItem?: [{	by:ValueTypes["ShopifyLineItemUniqueWhere"],	filter?:ValueTypes["ShopifyLineItemWhere"]},ValueTypes["ShopifyLineItemDeleteResult"]],
updateShopifyLineItem?: [{	by:ValueTypes["ShopifyLineItemUniqueWhere"],	filter?:ValueTypes["ShopifyLineItemWhere"],	data:ValueTypes["ShopifyLineItemUpdateInput"]},ValueTypes["ShopifyLineItemUpdateResult"]],
createShopifyOrder?: [{	data:ValueTypes["ShopifyOrderCreateInput"]},ValueTypes["ShopifyOrderCreateResult"]],
deleteShopifyOrder?: [{	by:ValueTypes["ShopifyOrderUniqueWhere"],	filter?:ValueTypes["ShopifyOrderWhere"]},ValueTypes["ShopifyOrderDeleteResult"]],
updateShopifyOrder?: [{	by:ValueTypes["ShopifyOrderUniqueWhere"],	filter?:ValueTypes["ShopifyOrderWhere"],	data:ValueTypes["ShopifyOrderUpdateInput"]},ValueTypes["ShopifyOrderUpdateResult"]],
createTranslationValue?: [{	data:ValueTypes["TranslationValueCreateInput"]},ValueTypes["TranslationValueCreateResult"]],
deleteTranslationValue?: [{	by:ValueTypes["TranslationValueUniqueWhere"],	filter?:ValueTypes["TranslationValueWhere"]},ValueTypes["TranslationValueDeleteResult"]],
updateTranslationValue?: [{	by:ValueTypes["TranslationValueUniqueWhere"],	filter?:ValueTypes["TranslationValueWhere"],	data:ValueTypes["TranslationValueUpdateInput"]},ValueTypes["TranslationValueUpdateResult"]],
createTranslationKey?: [{	data:ValueTypes["TranslationKeyCreateInput"]},ValueTypes["TranslationKeyCreateResult"]],
deleteTranslationKey?: [{	by:ValueTypes["TranslationKeyUniqueWhere"],	filter?:ValueTypes["TranslationKeyWhere"]},ValueTypes["TranslationKeyDeleteResult"]],
updateTranslationKey?: [{	by:ValueTypes["TranslationKeyUniqueWhere"],	filter?:ValueTypes["TranslationKeyWhere"],	data:ValueTypes["TranslationKeyUpdateInput"]},ValueTypes["TranslationKeyUpdateResult"]],
createTranslationCatalogue?: [{	data:ValueTypes["TranslationCatalogueCreateInput"]},ValueTypes["TranslationCatalogueCreateResult"]],
deleteTranslationCatalogue?: [{	by:ValueTypes["TranslationCatalogueUniqueWhere"],	filter?:ValueTypes["TranslationCatalogueWhere"]},ValueTypes["TranslationCatalogueDeleteResult"]],
updateTranslationCatalogue?: [{	by:ValueTypes["TranslationCatalogueUniqueWhere"],	filter?:ValueTypes["TranslationCatalogueWhere"],	data:ValueTypes["TranslationCatalogueUpdateInput"]},ValueTypes["TranslationCatalogueUpdateResult"]],
createTranslationDomain?: [{	data:ValueTypes["TranslationDomainCreateInput"]},ValueTypes["TranslationDomainCreateResult"]],
deleteTranslationDomain?: [{	by:ValueTypes["TranslationDomainUniqueWhere"],	filter?:ValueTypes["TranslationDomainWhere"]},ValueTypes["TranslationDomainDeleteResult"]],
updateTranslationDomain?: [{	by:ValueTypes["TranslationDomainUniqueWhere"],	filter?:ValueTypes["TranslationDomainWhere"],	data:ValueTypes["TranslationDomainUpdateInput"]},ValueTypes["TranslationDomainUpdateResult"]],
createProductLocale?: [{	data:ValueTypes["ProductLocaleCreateInput"]},ValueTypes["ProductLocaleCreateResult"]],
deleteProductLocale?: [{	by:ValueTypes["ProductLocaleUniqueWhere"],	filter?:ValueTypes["ProductLocaleWhere"]},ValueTypes["ProductLocaleDeleteResult"]],
updateProductLocale?: [{	by:ValueTypes["ProductLocaleUniqueWhere"],	filter?:ValueTypes["ProductLocaleWhere"],	data:ValueTypes["ProductLocaleUpdateInput"]},ValueTypes["ProductLocaleUpdateResult"]],
createMedia?: [{	data:ValueTypes["MediaCreateInput"]},ValueTypes["MediaCreateResult"]],
deleteMedia?: [{	by:ValueTypes["MediaUniqueWhere"],	filter?:ValueTypes["MediaWhere"]},ValueTypes["MediaDeleteResult"]],
updateMedia?: [{	by:ValueTypes["MediaUniqueWhere"],	filter?:ValueTypes["MediaWhere"],	data:ValueTypes["MediaUpdateInput"]},ValueTypes["MediaUpdateResult"]],
createIconListBlockItem?: [{	data:ValueTypes["IconListBlockItemCreateInput"]},ValueTypes["IconListBlockItemCreateResult"]],
deleteIconListBlockItem?: [{	by:ValueTypes["IconListBlockItemUniqueWhere"],	filter?:ValueTypes["IconListBlockItemWhere"]},ValueTypes["IconListBlockItemDeleteResult"]],
updateIconListBlockItem?: [{	by:ValueTypes["IconListBlockItemUniqueWhere"],	filter?:ValueTypes["IconListBlockItemWhere"],	data:ValueTypes["IconListBlockItemUpdateInput"]},ValueTypes["IconListBlockItemUpdateResult"]],
createIconListBlock?: [{	data:ValueTypes["IconListBlockCreateInput"]},ValueTypes["IconListBlockCreateResult"]],
deleteIconListBlock?: [{	by:ValueTypes["IconListBlockUniqueWhere"],	filter?:ValueTypes["IconListBlockWhere"]},ValueTypes["IconListBlockDeleteResult"]],
updateIconListBlock?: [{	by:ValueTypes["IconListBlockUniqueWhere"],	filter?:ValueTypes["IconListBlockWhere"],	data:ValueTypes["IconListBlockUpdateInput"]},ValueTypes["IconListBlockUpdateResult"]],
createFeatureListItem?: [{	data:ValueTypes["FeatureListItemCreateInput"]},ValueTypes["FeatureListItemCreateResult"]],
deleteFeatureListItem?: [{	by:ValueTypes["FeatureListItemUniqueWhere"],	filter?:ValueTypes["FeatureListItemWhere"]},ValueTypes["FeatureListItemDeleteResult"]],
updateFeatureListItem?: [{	by:ValueTypes["FeatureListItemUniqueWhere"],	filter?:ValueTypes["FeatureListItemWhere"],	data:ValueTypes["FeatureListItemUpdateInput"]},ValueTypes["FeatureListItemUpdateResult"]],
createFeatureList?: [{	data:ValueTypes["FeatureListCreateInput"]},ValueTypes["FeatureListCreateResult"]],
deleteFeatureList?: [{	by:ValueTypes["FeatureListUniqueWhere"],	filter?:ValueTypes["FeatureListWhere"]},ValueTypes["FeatureListDeleteResult"]],
updateFeatureList?: [{	by:ValueTypes["FeatureListUniqueWhere"],	filter?:ValueTypes["FeatureListWhere"],	data:ValueTypes["FeatureListUpdateInput"]},ValueTypes["FeatureListUpdateResult"]],
createFaqItem?: [{	data:ValueTypes["FaqItemCreateInput"]},ValueTypes["FaqItemCreateResult"]],
deleteFaqItem?: [{	by:ValueTypes["FaqItemUniqueWhere"],	filter?:ValueTypes["FaqItemWhere"]},ValueTypes["FaqItemDeleteResult"]],
updateFaqItem?: [{	by:ValueTypes["FaqItemUniqueWhere"],	filter?:ValueTypes["FaqItemWhere"],	data:ValueTypes["FaqItemUpdateInput"]},ValueTypes["FaqItemUpdateResult"]],
createFaq?: [{	data:ValueTypes["FaqCreateInput"]},ValueTypes["FaqCreateResult"]],
deleteFaq?: [{	by:ValueTypes["FaqUniqueWhere"],	filter?:ValueTypes["FaqWhere"]},ValueTypes["FaqDeleteResult"]],
updateFaq?: [{	by:ValueTypes["FaqUniqueWhere"],	filter?:ValueTypes["FaqWhere"],	data:ValueTypes["FaqUpdateInput"]},ValueTypes["FaqUpdateResult"]],
createContentBlock?: [{	data:ValueTypes["ContentBlockCreateInput"]},ValueTypes["ContentBlockCreateResult"]],
deleteContentBlock?: [{	by:ValueTypes["ContentBlockUniqueWhere"],	filter?:ValueTypes["ContentBlockWhere"]},ValueTypes["ContentBlockDeleteResult"]],
updateContentBlock?: [{	by:ValueTypes["ContentBlockUniqueWhere"],	filter?:ValueTypes["ContentBlockWhere"],	data:ValueTypes["ContentBlockUpdateInput"]},ValueTypes["ContentBlockUpdateResult"]],
createContent?: [{	data:ValueTypes["ContentCreateInput"]},ValueTypes["ContentCreateResult"]],
deleteContent?: [{	by:ValueTypes["ContentUniqueWhere"],	filter?:ValueTypes["ContentWhere"]},ValueTypes["ContentDeleteResult"]],
updateContent?: [{	by:ValueTypes["ContentUniqueWhere"],	filter?:ValueTypes["ContentWhere"],	data:ValueTypes["ContentUpdateInput"]},ValueTypes["ContentUpdateResult"]],
createLink?: [{	data:ValueTypes["LinkCreateInput"]},ValueTypes["LinkCreateResult"]],
deleteLink?: [{	by:ValueTypes["LinkUniqueWhere"],	filter?:ValueTypes["LinkWhere"]},ValueTypes["LinkDeleteResult"]],
updateLink?: [{	by:ValueTypes["LinkUniqueWhere"],	filter?:ValueTypes["LinkWhere"],	data:ValueTypes["LinkUpdateInput"]},ValueTypes["LinkUpdateResult"]],
createReviewLocale?: [{	data:ValueTypes["ReviewLocaleCreateInput"]},ValueTypes["ReviewLocaleCreateResult"]],
deleteReviewLocale?: [{	by:ValueTypes["ReviewLocaleUniqueWhere"],	filter?:ValueTypes["ReviewLocaleWhere"]},ValueTypes["ReviewLocaleDeleteResult"]],
updateReviewLocale?: [{	by:ValueTypes["ReviewLocaleUniqueWhere"],	filter?:ValueTypes["ReviewLocaleWhere"],	data:ValueTypes["ReviewLocaleUpdateInput"]},ValueTypes["ReviewLocaleUpdateResult"]],
createReview?: [{	data:ValueTypes["ReviewCreateInput"]},ValueTypes["ReviewCreateResult"]],
deleteReview?: [{	by:ValueTypes["ReviewUniqueWhere"],	filter?:ValueTypes["ReviewWhere"]},ValueTypes["ReviewDeleteResult"]],
updateReview?: [{	by:ValueTypes["ReviewUniqueWhere"],	filter?:ValueTypes["ReviewWhere"],	data:ValueTypes["ReviewUpdateInput"]},ValueTypes["ReviewUpdateResult"]],
createGalleryItem?: [{	data:ValueTypes["GalleryItemCreateInput"]},ValueTypes["GalleryItemCreateResult"]],
deleteGalleryItem?: [{	by:ValueTypes["GalleryItemUniqueWhere"],	filter?:ValueTypes["GalleryItemWhere"]},ValueTypes["GalleryItemDeleteResult"]],
updateGalleryItem?: [{	by:ValueTypes["GalleryItemUniqueWhere"],	filter?:ValueTypes["GalleryItemWhere"],	data:ValueTypes["GalleryItemUpdateInput"]},ValueTypes["GalleryItemUpdateResult"]],
createGallery?: [{	data:ValueTypes["GalleryCreateInput"]},ValueTypes["GalleryCreateResult"]],
deleteGallery?: [{	by:ValueTypes["GalleryUniqueWhere"],	filter?:ValueTypes["GalleryWhere"]},ValueTypes["GalleryDeleteResult"]],
updateGallery?: [{	by:ValueTypes["GalleryUniqueWhere"],	filter?:ValueTypes["GalleryWhere"],	data:ValueTypes["GalleryUpdateInput"]},ValueTypes["GalleryUpdateResult"]],
createProductVariantStore?: [{	data:ValueTypes["ProductVariantStoreCreateInput"]},ValueTypes["ProductVariantStoreCreateResult"]],
deleteProductVariantStore?: [{	by:ValueTypes["ProductVariantStoreUniqueWhere"],	filter?:ValueTypes["ProductVariantStoreWhere"]},ValueTypes["ProductVariantStoreDeleteResult"]],
updateProductVariantStore?: [{	by:ValueTypes["ProductVariantStoreUniqueWhere"],	filter?:ValueTypes["ProductVariantStoreWhere"],	data:ValueTypes["ProductVariantStoreUpdateInput"]},ValueTypes["ProductVariantStoreUpdateResult"]],
createProductStore?: [{	data:ValueTypes["ProductStoreCreateInput"]},ValueTypes["ProductStoreCreateResult"]],
deleteProductStore?: [{	by:ValueTypes["ProductStoreUniqueWhere"],	filter?:ValueTypes["ProductStoreWhere"]},ValueTypes["ProductStoreDeleteResult"]],
updateProductStore?: [{	by:ValueTypes["ProductStoreUniqueWhere"],	filter?:ValueTypes["ProductStoreWhere"],	data:ValueTypes["ProductStoreUpdateInput"]},ValueTypes["ProductStoreUpdateResult"]],
createIconListItem?: [{	data:ValueTypes["IconListItemCreateInput"]},ValueTypes["IconListItemCreateResult"]],
deleteIconListItem?: [{	by:ValueTypes["IconListItemUniqueWhere"],	filter?:ValueTypes["IconListItemWhere"]},ValueTypes["IconListItemDeleteResult"]],
updateIconListItem?: [{	by:ValueTypes["IconListItemUniqueWhere"],	filter?:ValueTypes["IconListItemWhere"],	data:ValueTypes["IconListItemUpdateInput"]},ValueTypes["IconListItemUpdateResult"]],
createIconList?: [{	data:ValueTypes["IconListCreateInput"]},ValueTypes["IconListCreateResult"]],
deleteIconList?: [{	by:ValueTypes["IconListUniqueWhere"],	filter?:ValueTypes["IconListWhere"]},ValueTypes["IconListDeleteResult"]],
updateIconList?: [{	by:ValueTypes["IconListUniqueWhere"],	filter?:ValueTypes["IconListWhere"],	data:ValueTypes["IconListUpdateInput"]},ValueTypes["IconListUpdateResult"]],
createIconGroupListItem?: [{	data:ValueTypes["IconGroupListItemCreateInput"]},ValueTypes["IconGroupListItemCreateResult"]],
deleteIconGroupListItem?: [{	by:ValueTypes["IconGroupListItemUniqueWhere"],	filter?:ValueTypes["IconGroupListItemWhere"]},ValueTypes["IconGroupListItemDeleteResult"]],
updateIconGroupListItem?: [{	by:ValueTypes["IconGroupListItemUniqueWhere"],	filter?:ValueTypes["IconGroupListItemWhere"],	data:ValueTypes["IconGroupListItemUpdateInput"]},ValueTypes["IconGroupListItemUpdateResult"]],
createIconGroupList?: [{	data:ValueTypes["IconGroupListCreateInput"]},ValueTypes["IconGroupListCreateResult"]],
deleteIconGroupList?: [{	by:ValueTypes["IconGroupListUniqueWhere"],	filter?:ValueTypes["IconGroupListWhere"]},ValueTypes["IconGroupListDeleteResult"]],
updateIconGroupList?: [{	by:ValueTypes["IconGroupListUniqueWhere"],	filter?:ValueTypes["IconGroupListWhere"],	data:ValueTypes["IconGroupListUpdateInput"]},ValueTypes["IconGroupListUpdateResult"]],
createProductVariantLocale?: [{	data:ValueTypes["ProductVariantLocaleCreateInput"]},ValueTypes["ProductVariantLocaleCreateResult"]],
deleteProductVariantLocale?: [{	by:ValueTypes["ProductVariantLocaleUniqueWhere"],	filter?:ValueTypes["ProductVariantLocaleWhere"]},ValueTypes["ProductVariantLocaleDeleteResult"]],
updateProductVariantLocale?: [{	by:ValueTypes["ProductVariantLocaleUniqueWhere"],	filter?:ValueTypes["ProductVariantLocaleWhere"],	data:ValueTypes["ProductVariantLocaleUpdateInput"]},ValueTypes["ProductVariantLocaleUpdateResult"]],
createProductVariant?: [{	data:ValueTypes["ProductVariantCreateInput"]},ValueTypes["ProductVariantCreateResult"]],
deleteProductVariant?: [{	by:ValueTypes["ProductVariantUniqueWhere"],	filter?:ValueTypes["ProductVariantWhere"]},ValueTypes["ProductVariantDeleteResult"]],
updateProductVariant?: [{	by:ValueTypes["ProductVariantUniqueWhere"],	filter?:ValueTypes["ProductVariantWhere"],	data:ValueTypes["ProductVariantUpdateInput"]},ValueTypes["ProductVariantUpdateResult"]],
createProduct?: [{	data:ValueTypes["ProductCreateInput"]},ValueTypes["ProductCreateResult"]],
deleteProduct?: [{	by:ValueTypes["ProductUniqueWhere"],	filter?:ValueTypes["ProductWhere"]},ValueTypes["ProductDeleteResult"]],
updateProduct?: [{	by:ValueTypes["ProductUniqueWhere"],	filter?:ValueTypes["ProductWhere"],	data:ValueTypes["ProductUpdateInput"]},ValueTypes["ProductUpdateResult"]],
createImageLocale?: [{	data:ValueTypes["ImageLocaleCreateInput"]},ValueTypes["ImageLocaleCreateResult"]],
deleteImageLocale?: [{	by:ValueTypes["ImageLocaleUniqueWhere"],	filter?:ValueTypes["ImageLocaleWhere"]},ValueTypes["ImageLocaleDeleteResult"]],
updateImageLocale?: [{	by:ValueTypes["ImageLocaleUniqueWhere"],	filter?:ValueTypes["ImageLocaleWhere"],	data:ValueTypes["ImageLocaleUpdateInput"]},ValueTypes["ImageLocaleUpdateResult"]],
createStore?: [{	data:ValueTypes["StoreCreateInput"]},ValueTypes["StoreCreateResult"]],
deleteStore?: [{	by:ValueTypes["StoreUniqueWhere"],	filter?:ValueTypes["StoreWhere"]},ValueTypes["StoreDeleteResult"]],
updateStore?: [{	by:ValueTypes["StoreUniqueWhere"],	filter?:ValueTypes["StoreWhere"],	data:ValueTypes["StoreUpdateInput"]},ValueTypes["StoreUpdateResult"]],
createAdminNote?: [{	data:ValueTypes["AdminNoteCreateInput"]},ValueTypes["AdminNoteCreateResult"]],
deleteAdminNote?: [{	by:ValueTypes["AdminNoteUniqueWhere"],	filter?:ValueTypes["AdminNoteWhere"]},ValueTypes["AdminNoteDeleteResult"]],
updateAdminNote?: [{	by:ValueTypes["AdminNoteUniqueWhere"],	filter?:ValueTypes["AdminNoteWhere"],	data:ValueTypes["AdminNoteUpdateInput"]},ValueTypes["AdminNoteUpdateResult"]],
createPageLocale?: [{	data:ValueTypes["PageLocaleCreateInput"]},ValueTypes["PageLocaleCreateResult"]],
deletePageLocale?: [{	by:ValueTypes["PageLocaleUniqueWhere"],	filter?:ValueTypes["PageLocaleWhere"]},ValueTypes["PageLocaleDeleteResult"]],
updatePageLocale?: [{	by:ValueTypes["PageLocaleUniqueWhere"],	filter?:ValueTypes["PageLocaleWhere"],	data:ValueTypes["PageLocaleUpdateInput"]},ValueTypes["PageLocaleUpdateResult"]],
createPage?: [{	data:ValueTypes["PageCreateInput"]},ValueTypes["PageCreateResult"]],
deletePage?: [{	by:ValueTypes["PageUniqueWhere"],	filter?:ValueTypes["PageWhere"]},ValueTypes["PageDeleteResult"]],
updatePage?: [{	by:ValueTypes["PageUniqueWhere"],	filter?:ValueTypes["PageWhere"],	data:ValueTypes["PageUpdateInput"]},ValueTypes["PageUpdateResult"]],
createImageXImageList?: [{	data:ValueTypes["ImageXImageListCreateInput"]},ValueTypes["ImageXImageListCreateResult"]],
deleteImageXImageList?: [{	by:ValueTypes["ImageXImageListUniqueWhere"],	filter?:ValueTypes["ImageXImageListWhere"]},ValueTypes["ImageXImageListDeleteResult"]],
updateImageXImageList?: [{	by:ValueTypes["ImageXImageListUniqueWhere"],	filter?:ValueTypes["ImageXImageListWhere"],	data:ValueTypes["ImageXImageListUpdateInput"]},ValueTypes["ImageXImageListUpdateResult"]],
createIconGroupLocale?: [{	data:ValueTypes["IconGroupLocaleCreateInput"]},ValueTypes["IconGroupLocaleCreateResult"]],
deleteIconGroupLocale?: [{	by:ValueTypes["IconGroupLocaleUniqueWhere"],	filter?:ValueTypes["IconGroupLocaleWhere"]},ValueTypes["IconGroupLocaleDeleteResult"]],
updateIconGroupLocale?: [{	by:ValueTypes["IconGroupLocaleUniqueWhere"],	filter?:ValueTypes["IconGroupLocaleWhere"],	data:ValueTypes["IconGroupLocaleUpdateInput"]},ValueTypes["IconGroupLocaleUpdateResult"]],
createIconLocale?: [{	data:ValueTypes["IconLocaleCreateInput"]},ValueTypes["IconLocaleCreateResult"]],
deleteIconLocale?: [{	by:ValueTypes["IconLocaleUniqueWhere"],	filter?:ValueTypes["IconLocaleWhere"]},ValueTypes["IconLocaleDeleteResult"]],
updateIconLocale?: [{	by:ValueTypes["IconLocaleUniqueWhere"],	filter?:ValueTypes["IconLocaleWhere"],	data:ValueTypes["IconLocaleUpdateInput"]},ValueTypes["IconLocaleUpdateResult"]],
createIconGroup?: [{	data:ValueTypes["IconGroupCreateInput"]},ValueTypes["IconGroupCreateResult"]],
deleteIconGroup?: [{	by:ValueTypes["IconGroupUniqueWhere"],	filter?:ValueTypes["IconGroupWhere"]},ValueTypes["IconGroupDeleteResult"]],
updateIconGroup?: [{	by:ValueTypes["IconGroupUniqueWhere"],	filter?:ValueTypes["IconGroupWhere"],	data:ValueTypes["IconGroupUpdateInput"]},ValueTypes["IconGroupUpdateResult"]],
createIcon?: [{	data:ValueTypes["IconCreateInput"]},ValueTypes["IconCreateResult"]],
deleteIcon?: [{	by:ValueTypes["IconUniqueWhere"],	filter?:ValueTypes["IconWhere"]},ValueTypes["IconDeleteResult"]],
updateIcon?: [{	by:ValueTypes["IconUniqueWhere"],	filter?:ValueTypes["IconWhere"],	data:ValueTypes["IconUpdateInput"]},ValueTypes["IconUpdateResult"]],
createLocale?: [{	data:ValueTypes["LocaleCreateInput"]},ValueTypes["LocaleCreateResult"]],
deleteLocale?: [{	by:ValueTypes["LocaleUniqueWhere"],	filter?:ValueTypes["LocaleWhere"]},ValueTypes["LocaleDeleteResult"]],
updateLocale?: [{	by:ValueTypes["LocaleUniqueWhere"],	filter?:ValueTypes["LocaleWhere"],	data:ValueTypes["LocaleUpdateInput"]},ValueTypes["LocaleUpdateResult"]],
createSeoLocale?: [{	data:ValueTypes["SeoLocaleCreateInput"]},ValueTypes["SeoLocaleCreateResult"]],
deleteSeoLocale?: [{	by:ValueTypes["SeoLocaleUniqueWhere"],	filter?:ValueTypes["SeoLocaleWhere"]},ValueTypes["SeoLocaleDeleteResult"]],
updateSeoLocale?: [{	by:ValueTypes["SeoLocaleUniqueWhere"],	filter?:ValueTypes["SeoLocaleWhere"],	data:ValueTypes["SeoLocaleUpdateInput"]},ValueTypes["SeoLocaleUpdateResult"]],
createSeo?: [{	data:ValueTypes["SeoCreateInput"]},ValueTypes["SeoCreateResult"]],
deleteSeo?: [{	by:ValueTypes["SeoUniqueWhere"],	filter?:ValueTypes["SeoWhere"]},ValueTypes["SeoDeleteResult"]],
updateSeo?: [{	by:ValueTypes["SeoUniqueWhere"],	filter?:ValueTypes["SeoWhere"],	data:ValueTypes["SeoUpdateInput"]},ValueTypes["SeoUpdateResult"]],
createLinkable?: [{	data:ValueTypes["LinkableCreateInput"]},ValueTypes["LinkableCreateResult"]],
deleteLinkable?: [{	by:ValueTypes["LinkableUniqueWhere"],	filter?:ValueTypes["LinkableWhere"]},ValueTypes["LinkableDeleteResult"]],
updateLinkable?: [{	by:ValueTypes["LinkableUniqueWhere"],	filter?:ValueTypes["LinkableWhere"],	data:ValueTypes["LinkableUpdateInput"]},ValueTypes["LinkableUpdateResult"]],
createImageList?: [{	data:ValueTypes["ImageListCreateInput"]},ValueTypes["ImageListCreateResult"]],
deleteImageList?: [{	by:ValueTypes["ImageListUniqueWhere"],	filter?:ValueTypes["ImageListWhere"]},ValueTypes["ImageListDeleteResult"]],
updateImageList?: [{	by:ValueTypes["ImageListUniqueWhere"],	filter?:ValueTypes["ImageListWhere"],	data:ValueTypes["ImageListUpdateInput"]},ValueTypes["ImageListUpdateResult"]],
createImage?: [{	data:ValueTypes["ImageCreateInput"]},ValueTypes["ImageCreateResult"]],
deleteImage?: [{	by:ValueTypes["ImageUniqueWhere"],	filter?:ValueTypes["ImageWhere"]},ValueTypes["ImageDeleteResult"]],
updateImage?: [{	by:ValueTypes["ImageUniqueWhere"],	filter?:ValueTypes["ImageWhere"],	data:ValueTypes["ImageUpdateInput"]},ValueTypes["ImageUpdateResult"]],
	transaction?:ValueTypes["MutationTransaction"],
generateUploadUrl?: [{	contentType:string,	expiration?:number,	prefix?:string},ValueTypes["S3SignedUpload"]],
generateReadUrl?: [{	objectKey:string,	expiration?:number},ValueTypes["S3SignedRead"]],
		__typename?: true
}>;
	["MutationTransaction"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	validation?:ValueTypes["_ValidationResult"],
createAbandonedCheckoutUnsubscription?: [{	data:ValueTypes["AbandonedCheckoutUnsubscriptionCreateInput"]},ValueTypes["AbandonedCheckoutUnsubscriptionCreateResult"]],
deleteAbandonedCheckoutUnsubscription?: [{	by:ValueTypes["AbandonedCheckoutUnsubscriptionUniqueWhere"],	filter?:ValueTypes["AbandonedCheckoutUnsubscriptionWhere"]},ValueTypes["AbandonedCheckoutUnsubscriptionDeleteResult"]],
updateAbandonedCheckoutUnsubscription?: [{	by:ValueTypes["AbandonedCheckoutUnsubscriptionUniqueWhere"],	filter?:ValueTypes["AbandonedCheckoutUnsubscriptionWhere"],	data:ValueTypes["AbandonedCheckoutUnsubscriptionUpdateInput"]},ValueTypes["AbandonedCheckoutUnsubscriptionUpdateResult"]],
createAbandonedCheckoutNotification?: [{	data:ValueTypes["AbandonedCheckoutNotificationCreateInput"]},ValueTypes["AbandonedCheckoutNotificationCreateResult"]],
deleteAbandonedCheckoutNotification?: [{	by:ValueTypes["AbandonedCheckoutNotificationUniqueWhere"],	filter?:ValueTypes["AbandonedCheckoutNotificationWhere"]},ValueTypes["AbandonedCheckoutNotificationDeleteResult"]],
updateAbandonedCheckoutNotification?: [{	by:ValueTypes["AbandonedCheckoutNotificationUniqueWhere"],	filter?:ValueTypes["AbandonedCheckoutNotificationWhere"],	data:ValueTypes["AbandonedCheckoutNotificationUpdateInput"]},ValueTypes["AbandonedCheckoutNotificationUpdateResult"]],
createShopifyLineItem?: [{	data:ValueTypes["ShopifyLineItemCreateInput"]},ValueTypes["ShopifyLineItemCreateResult"]],
deleteShopifyLineItem?: [{	by:ValueTypes["ShopifyLineItemUniqueWhere"],	filter?:ValueTypes["ShopifyLineItemWhere"]},ValueTypes["ShopifyLineItemDeleteResult"]],
updateShopifyLineItem?: [{	by:ValueTypes["ShopifyLineItemUniqueWhere"],	filter?:ValueTypes["ShopifyLineItemWhere"],	data:ValueTypes["ShopifyLineItemUpdateInput"]},ValueTypes["ShopifyLineItemUpdateResult"]],
createShopifyOrder?: [{	data:ValueTypes["ShopifyOrderCreateInput"]},ValueTypes["ShopifyOrderCreateResult"]],
deleteShopifyOrder?: [{	by:ValueTypes["ShopifyOrderUniqueWhere"],	filter?:ValueTypes["ShopifyOrderWhere"]},ValueTypes["ShopifyOrderDeleteResult"]],
updateShopifyOrder?: [{	by:ValueTypes["ShopifyOrderUniqueWhere"],	filter?:ValueTypes["ShopifyOrderWhere"],	data:ValueTypes["ShopifyOrderUpdateInput"]},ValueTypes["ShopifyOrderUpdateResult"]],
createTranslationValue?: [{	data:ValueTypes["TranslationValueCreateInput"]},ValueTypes["TranslationValueCreateResult"]],
deleteTranslationValue?: [{	by:ValueTypes["TranslationValueUniqueWhere"],	filter?:ValueTypes["TranslationValueWhere"]},ValueTypes["TranslationValueDeleteResult"]],
updateTranslationValue?: [{	by:ValueTypes["TranslationValueUniqueWhere"],	filter?:ValueTypes["TranslationValueWhere"],	data:ValueTypes["TranslationValueUpdateInput"]},ValueTypes["TranslationValueUpdateResult"]],
createTranslationKey?: [{	data:ValueTypes["TranslationKeyCreateInput"]},ValueTypes["TranslationKeyCreateResult"]],
deleteTranslationKey?: [{	by:ValueTypes["TranslationKeyUniqueWhere"],	filter?:ValueTypes["TranslationKeyWhere"]},ValueTypes["TranslationKeyDeleteResult"]],
updateTranslationKey?: [{	by:ValueTypes["TranslationKeyUniqueWhere"],	filter?:ValueTypes["TranslationKeyWhere"],	data:ValueTypes["TranslationKeyUpdateInput"]},ValueTypes["TranslationKeyUpdateResult"]],
createTranslationCatalogue?: [{	data:ValueTypes["TranslationCatalogueCreateInput"]},ValueTypes["TranslationCatalogueCreateResult"]],
deleteTranslationCatalogue?: [{	by:ValueTypes["TranslationCatalogueUniqueWhere"],	filter?:ValueTypes["TranslationCatalogueWhere"]},ValueTypes["TranslationCatalogueDeleteResult"]],
updateTranslationCatalogue?: [{	by:ValueTypes["TranslationCatalogueUniqueWhere"],	filter?:ValueTypes["TranslationCatalogueWhere"],	data:ValueTypes["TranslationCatalogueUpdateInput"]},ValueTypes["TranslationCatalogueUpdateResult"]],
createTranslationDomain?: [{	data:ValueTypes["TranslationDomainCreateInput"]},ValueTypes["TranslationDomainCreateResult"]],
deleteTranslationDomain?: [{	by:ValueTypes["TranslationDomainUniqueWhere"],	filter?:ValueTypes["TranslationDomainWhere"]},ValueTypes["TranslationDomainDeleteResult"]],
updateTranslationDomain?: [{	by:ValueTypes["TranslationDomainUniqueWhere"],	filter?:ValueTypes["TranslationDomainWhere"],	data:ValueTypes["TranslationDomainUpdateInput"]},ValueTypes["TranslationDomainUpdateResult"]],
createProductLocale?: [{	data:ValueTypes["ProductLocaleCreateInput"]},ValueTypes["ProductLocaleCreateResult"]],
deleteProductLocale?: [{	by:ValueTypes["ProductLocaleUniqueWhere"],	filter?:ValueTypes["ProductLocaleWhere"]},ValueTypes["ProductLocaleDeleteResult"]],
updateProductLocale?: [{	by:ValueTypes["ProductLocaleUniqueWhere"],	filter?:ValueTypes["ProductLocaleWhere"],	data:ValueTypes["ProductLocaleUpdateInput"]},ValueTypes["ProductLocaleUpdateResult"]],
createMedia?: [{	data:ValueTypes["MediaCreateInput"]},ValueTypes["MediaCreateResult"]],
deleteMedia?: [{	by:ValueTypes["MediaUniqueWhere"],	filter?:ValueTypes["MediaWhere"]},ValueTypes["MediaDeleteResult"]],
updateMedia?: [{	by:ValueTypes["MediaUniqueWhere"],	filter?:ValueTypes["MediaWhere"],	data:ValueTypes["MediaUpdateInput"]},ValueTypes["MediaUpdateResult"]],
createIconListBlockItem?: [{	data:ValueTypes["IconListBlockItemCreateInput"]},ValueTypes["IconListBlockItemCreateResult"]],
deleteIconListBlockItem?: [{	by:ValueTypes["IconListBlockItemUniqueWhere"],	filter?:ValueTypes["IconListBlockItemWhere"]},ValueTypes["IconListBlockItemDeleteResult"]],
updateIconListBlockItem?: [{	by:ValueTypes["IconListBlockItemUniqueWhere"],	filter?:ValueTypes["IconListBlockItemWhere"],	data:ValueTypes["IconListBlockItemUpdateInput"]},ValueTypes["IconListBlockItemUpdateResult"]],
createIconListBlock?: [{	data:ValueTypes["IconListBlockCreateInput"]},ValueTypes["IconListBlockCreateResult"]],
deleteIconListBlock?: [{	by:ValueTypes["IconListBlockUniqueWhere"],	filter?:ValueTypes["IconListBlockWhere"]},ValueTypes["IconListBlockDeleteResult"]],
updateIconListBlock?: [{	by:ValueTypes["IconListBlockUniqueWhere"],	filter?:ValueTypes["IconListBlockWhere"],	data:ValueTypes["IconListBlockUpdateInput"]},ValueTypes["IconListBlockUpdateResult"]],
createFeatureListItem?: [{	data:ValueTypes["FeatureListItemCreateInput"]},ValueTypes["FeatureListItemCreateResult"]],
deleteFeatureListItem?: [{	by:ValueTypes["FeatureListItemUniqueWhere"],	filter?:ValueTypes["FeatureListItemWhere"]},ValueTypes["FeatureListItemDeleteResult"]],
updateFeatureListItem?: [{	by:ValueTypes["FeatureListItemUniqueWhere"],	filter?:ValueTypes["FeatureListItemWhere"],	data:ValueTypes["FeatureListItemUpdateInput"]},ValueTypes["FeatureListItemUpdateResult"]],
createFeatureList?: [{	data:ValueTypes["FeatureListCreateInput"]},ValueTypes["FeatureListCreateResult"]],
deleteFeatureList?: [{	by:ValueTypes["FeatureListUniqueWhere"],	filter?:ValueTypes["FeatureListWhere"]},ValueTypes["FeatureListDeleteResult"]],
updateFeatureList?: [{	by:ValueTypes["FeatureListUniqueWhere"],	filter?:ValueTypes["FeatureListWhere"],	data:ValueTypes["FeatureListUpdateInput"]},ValueTypes["FeatureListUpdateResult"]],
createFaqItem?: [{	data:ValueTypes["FaqItemCreateInput"]},ValueTypes["FaqItemCreateResult"]],
deleteFaqItem?: [{	by:ValueTypes["FaqItemUniqueWhere"],	filter?:ValueTypes["FaqItemWhere"]},ValueTypes["FaqItemDeleteResult"]],
updateFaqItem?: [{	by:ValueTypes["FaqItemUniqueWhere"],	filter?:ValueTypes["FaqItemWhere"],	data:ValueTypes["FaqItemUpdateInput"]},ValueTypes["FaqItemUpdateResult"]],
createFaq?: [{	data:ValueTypes["FaqCreateInput"]},ValueTypes["FaqCreateResult"]],
deleteFaq?: [{	by:ValueTypes["FaqUniqueWhere"],	filter?:ValueTypes["FaqWhere"]},ValueTypes["FaqDeleteResult"]],
updateFaq?: [{	by:ValueTypes["FaqUniqueWhere"],	filter?:ValueTypes["FaqWhere"],	data:ValueTypes["FaqUpdateInput"]},ValueTypes["FaqUpdateResult"]],
createContentBlock?: [{	data:ValueTypes["ContentBlockCreateInput"]},ValueTypes["ContentBlockCreateResult"]],
deleteContentBlock?: [{	by:ValueTypes["ContentBlockUniqueWhere"],	filter?:ValueTypes["ContentBlockWhere"]},ValueTypes["ContentBlockDeleteResult"]],
updateContentBlock?: [{	by:ValueTypes["ContentBlockUniqueWhere"],	filter?:ValueTypes["ContentBlockWhere"],	data:ValueTypes["ContentBlockUpdateInput"]},ValueTypes["ContentBlockUpdateResult"]],
createContent?: [{	data:ValueTypes["ContentCreateInput"]},ValueTypes["ContentCreateResult"]],
deleteContent?: [{	by:ValueTypes["ContentUniqueWhere"],	filter?:ValueTypes["ContentWhere"]},ValueTypes["ContentDeleteResult"]],
updateContent?: [{	by:ValueTypes["ContentUniqueWhere"],	filter?:ValueTypes["ContentWhere"],	data:ValueTypes["ContentUpdateInput"]},ValueTypes["ContentUpdateResult"]],
createLink?: [{	data:ValueTypes["LinkCreateInput"]},ValueTypes["LinkCreateResult"]],
deleteLink?: [{	by:ValueTypes["LinkUniqueWhere"],	filter?:ValueTypes["LinkWhere"]},ValueTypes["LinkDeleteResult"]],
updateLink?: [{	by:ValueTypes["LinkUniqueWhere"],	filter?:ValueTypes["LinkWhere"],	data:ValueTypes["LinkUpdateInput"]},ValueTypes["LinkUpdateResult"]],
createReviewLocale?: [{	data:ValueTypes["ReviewLocaleCreateInput"]},ValueTypes["ReviewLocaleCreateResult"]],
deleteReviewLocale?: [{	by:ValueTypes["ReviewLocaleUniqueWhere"],	filter?:ValueTypes["ReviewLocaleWhere"]},ValueTypes["ReviewLocaleDeleteResult"]],
updateReviewLocale?: [{	by:ValueTypes["ReviewLocaleUniqueWhere"],	filter?:ValueTypes["ReviewLocaleWhere"],	data:ValueTypes["ReviewLocaleUpdateInput"]},ValueTypes["ReviewLocaleUpdateResult"]],
createReview?: [{	data:ValueTypes["ReviewCreateInput"]},ValueTypes["ReviewCreateResult"]],
deleteReview?: [{	by:ValueTypes["ReviewUniqueWhere"],	filter?:ValueTypes["ReviewWhere"]},ValueTypes["ReviewDeleteResult"]],
updateReview?: [{	by:ValueTypes["ReviewUniqueWhere"],	filter?:ValueTypes["ReviewWhere"],	data:ValueTypes["ReviewUpdateInput"]},ValueTypes["ReviewUpdateResult"]],
createGalleryItem?: [{	data:ValueTypes["GalleryItemCreateInput"]},ValueTypes["GalleryItemCreateResult"]],
deleteGalleryItem?: [{	by:ValueTypes["GalleryItemUniqueWhere"],	filter?:ValueTypes["GalleryItemWhere"]},ValueTypes["GalleryItemDeleteResult"]],
updateGalleryItem?: [{	by:ValueTypes["GalleryItemUniqueWhere"],	filter?:ValueTypes["GalleryItemWhere"],	data:ValueTypes["GalleryItemUpdateInput"]},ValueTypes["GalleryItemUpdateResult"]],
createGallery?: [{	data:ValueTypes["GalleryCreateInput"]},ValueTypes["GalleryCreateResult"]],
deleteGallery?: [{	by:ValueTypes["GalleryUniqueWhere"],	filter?:ValueTypes["GalleryWhere"]},ValueTypes["GalleryDeleteResult"]],
updateGallery?: [{	by:ValueTypes["GalleryUniqueWhere"],	filter?:ValueTypes["GalleryWhere"],	data:ValueTypes["GalleryUpdateInput"]},ValueTypes["GalleryUpdateResult"]],
createProductVariantStore?: [{	data:ValueTypes["ProductVariantStoreCreateInput"]},ValueTypes["ProductVariantStoreCreateResult"]],
deleteProductVariantStore?: [{	by:ValueTypes["ProductVariantStoreUniqueWhere"],	filter?:ValueTypes["ProductVariantStoreWhere"]},ValueTypes["ProductVariantStoreDeleteResult"]],
updateProductVariantStore?: [{	by:ValueTypes["ProductVariantStoreUniqueWhere"],	filter?:ValueTypes["ProductVariantStoreWhere"],	data:ValueTypes["ProductVariantStoreUpdateInput"]},ValueTypes["ProductVariantStoreUpdateResult"]],
createProductStore?: [{	data:ValueTypes["ProductStoreCreateInput"]},ValueTypes["ProductStoreCreateResult"]],
deleteProductStore?: [{	by:ValueTypes["ProductStoreUniqueWhere"],	filter?:ValueTypes["ProductStoreWhere"]},ValueTypes["ProductStoreDeleteResult"]],
updateProductStore?: [{	by:ValueTypes["ProductStoreUniqueWhere"],	filter?:ValueTypes["ProductStoreWhere"],	data:ValueTypes["ProductStoreUpdateInput"]},ValueTypes["ProductStoreUpdateResult"]],
createIconListItem?: [{	data:ValueTypes["IconListItemCreateInput"]},ValueTypes["IconListItemCreateResult"]],
deleteIconListItem?: [{	by:ValueTypes["IconListItemUniqueWhere"],	filter?:ValueTypes["IconListItemWhere"]},ValueTypes["IconListItemDeleteResult"]],
updateIconListItem?: [{	by:ValueTypes["IconListItemUniqueWhere"],	filter?:ValueTypes["IconListItemWhere"],	data:ValueTypes["IconListItemUpdateInput"]},ValueTypes["IconListItemUpdateResult"]],
createIconList?: [{	data:ValueTypes["IconListCreateInput"]},ValueTypes["IconListCreateResult"]],
deleteIconList?: [{	by:ValueTypes["IconListUniqueWhere"],	filter?:ValueTypes["IconListWhere"]},ValueTypes["IconListDeleteResult"]],
updateIconList?: [{	by:ValueTypes["IconListUniqueWhere"],	filter?:ValueTypes["IconListWhere"],	data:ValueTypes["IconListUpdateInput"]},ValueTypes["IconListUpdateResult"]],
createIconGroupListItem?: [{	data:ValueTypes["IconGroupListItemCreateInput"]},ValueTypes["IconGroupListItemCreateResult"]],
deleteIconGroupListItem?: [{	by:ValueTypes["IconGroupListItemUniqueWhere"],	filter?:ValueTypes["IconGroupListItemWhere"]},ValueTypes["IconGroupListItemDeleteResult"]],
updateIconGroupListItem?: [{	by:ValueTypes["IconGroupListItemUniqueWhere"],	filter?:ValueTypes["IconGroupListItemWhere"],	data:ValueTypes["IconGroupListItemUpdateInput"]},ValueTypes["IconGroupListItemUpdateResult"]],
createIconGroupList?: [{	data:ValueTypes["IconGroupListCreateInput"]},ValueTypes["IconGroupListCreateResult"]],
deleteIconGroupList?: [{	by:ValueTypes["IconGroupListUniqueWhere"],	filter?:ValueTypes["IconGroupListWhere"]},ValueTypes["IconGroupListDeleteResult"]],
updateIconGroupList?: [{	by:ValueTypes["IconGroupListUniqueWhere"],	filter?:ValueTypes["IconGroupListWhere"],	data:ValueTypes["IconGroupListUpdateInput"]},ValueTypes["IconGroupListUpdateResult"]],
createProductVariantLocale?: [{	data:ValueTypes["ProductVariantLocaleCreateInput"]},ValueTypes["ProductVariantLocaleCreateResult"]],
deleteProductVariantLocale?: [{	by:ValueTypes["ProductVariantLocaleUniqueWhere"],	filter?:ValueTypes["ProductVariantLocaleWhere"]},ValueTypes["ProductVariantLocaleDeleteResult"]],
updateProductVariantLocale?: [{	by:ValueTypes["ProductVariantLocaleUniqueWhere"],	filter?:ValueTypes["ProductVariantLocaleWhere"],	data:ValueTypes["ProductVariantLocaleUpdateInput"]},ValueTypes["ProductVariantLocaleUpdateResult"]],
createProductVariant?: [{	data:ValueTypes["ProductVariantCreateInput"]},ValueTypes["ProductVariantCreateResult"]],
deleteProductVariant?: [{	by:ValueTypes["ProductVariantUniqueWhere"],	filter?:ValueTypes["ProductVariantWhere"]},ValueTypes["ProductVariantDeleteResult"]],
updateProductVariant?: [{	by:ValueTypes["ProductVariantUniqueWhere"],	filter?:ValueTypes["ProductVariantWhere"],	data:ValueTypes["ProductVariantUpdateInput"]},ValueTypes["ProductVariantUpdateResult"]],
createProduct?: [{	data:ValueTypes["ProductCreateInput"]},ValueTypes["ProductCreateResult"]],
deleteProduct?: [{	by:ValueTypes["ProductUniqueWhere"],	filter?:ValueTypes["ProductWhere"]},ValueTypes["ProductDeleteResult"]],
updateProduct?: [{	by:ValueTypes["ProductUniqueWhere"],	filter?:ValueTypes["ProductWhere"],	data:ValueTypes["ProductUpdateInput"]},ValueTypes["ProductUpdateResult"]],
createImageLocale?: [{	data:ValueTypes["ImageLocaleCreateInput"]},ValueTypes["ImageLocaleCreateResult"]],
deleteImageLocale?: [{	by:ValueTypes["ImageLocaleUniqueWhere"],	filter?:ValueTypes["ImageLocaleWhere"]},ValueTypes["ImageLocaleDeleteResult"]],
updateImageLocale?: [{	by:ValueTypes["ImageLocaleUniqueWhere"],	filter?:ValueTypes["ImageLocaleWhere"],	data:ValueTypes["ImageLocaleUpdateInput"]},ValueTypes["ImageLocaleUpdateResult"]],
createStore?: [{	data:ValueTypes["StoreCreateInput"]},ValueTypes["StoreCreateResult"]],
deleteStore?: [{	by:ValueTypes["StoreUniqueWhere"],	filter?:ValueTypes["StoreWhere"]},ValueTypes["StoreDeleteResult"]],
updateStore?: [{	by:ValueTypes["StoreUniqueWhere"],	filter?:ValueTypes["StoreWhere"],	data:ValueTypes["StoreUpdateInput"]},ValueTypes["StoreUpdateResult"]],
createAdminNote?: [{	data:ValueTypes["AdminNoteCreateInput"]},ValueTypes["AdminNoteCreateResult"]],
deleteAdminNote?: [{	by:ValueTypes["AdminNoteUniqueWhere"],	filter?:ValueTypes["AdminNoteWhere"]},ValueTypes["AdminNoteDeleteResult"]],
updateAdminNote?: [{	by:ValueTypes["AdminNoteUniqueWhere"],	filter?:ValueTypes["AdminNoteWhere"],	data:ValueTypes["AdminNoteUpdateInput"]},ValueTypes["AdminNoteUpdateResult"]],
createPageLocale?: [{	data:ValueTypes["PageLocaleCreateInput"]},ValueTypes["PageLocaleCreateResult"]],
deletePageLocale?: [{	by:ValueTypes["PageLocaleUniqueWhere"],	filter?:ValueTypes["PageLocaleWhere"]},ValueTypes["PageLocaleDeleteResult"]],
updatePageLocale?: [{	by:ValueTypes["PageLocaleUniqueWhere"],	filter?:ValueTypes["PageLocaleWhere"],	data:ValueTypes["PageLocaleUpdateInput"]},ValueTypes["PageLocaleUpdateResult"]],
createPage?: [{	data:ValueTypes["PageCreateInput"]},ValueTypes["PageCreateResult"]],
deletePage?: [{	by:ValueTypes["PageUniqueWhere"],	filter?:ValueTypes["PageWhere"]},ValueTypes["PageDeleteResult"]],
updatePage?: [{	by:ValueTypes["PageUniqueWhere"],	filter?:ValueTypes["PageWhere"],	data:ValueTypes["PageUpdateInput"]},ValueTypes["PageUpdateResult"]],
createImageXImageList?: [{	data:ValueTypes["ImageXImageListCreateInput"]},ValueTypes["ImageXImageListCreateResult"]],
deleteImageXImageList?: [{	by:ValueTypes["ImageXImageListUniqueWhere"],	filter?:ValueTypes["ImageXImageListWhere"]},ValueTypes["ImageXImageListDeleteResult"]],
updateImageXImageList?: [{	by:ValueTypes["ImageXImageListUniqueWhere"],	filter?:ValueTypes["ImageXImageListWhere"],	data:ValueTypes["ImageXImageListUpdateInput"]},ValueTypes["ImageXImageListUpdateResult"]],
createIconGroupLocale?: [{	data:ValueTypes["IconGroupLocaleCreateInput"]},ValueTypes["IconGroupLocaleCreateResult"]],
deleteIconGroupLocale?: [{	by:ValueTypes["IconGroupLocaleUniqueWhere"],	filter?:ValueTypes["IconGroupLocaleWhere"]},ValueTypes["IconGroupLocaleDeleteResult"]],
updateIconGroupLocale?: [{	by:ValueTypes["IconGroupLocaleUniqueWhere"],	filter?:ValueTypes["IconGroupLocaleWhere"],	data:ValueTypes["IconGroupLocaleUpdateInput"]},ValueTypes["IconGroupLocaleUpdateResult"]],
createIconLocale?: [{	data:ValueTypes["IconLocaleCreateInput"]},ValueTypes["IconLocaleCreateResult"]],
deleteIconLocale?: [{	by:ValueTypes["IconLocaleUniqueWhere"],	filter?:ValueTypes["IconLocaleWhere"]},ValueTypes["IconLocaleDeleteResult"]],
updateIconLocale?: [{	by:ValueTypes["IconLocaleUniqueWhere"],	filter?:ValueTypes["IconLocaleWhere"],	data:ValueTypes["IconLocaleUpdateInput"]},ValueTypes["IconLocaleUpdateResult"]],
createIconGroup?: [{	data:ValueTypes["IconGroupCreateInput"]},ValueTypes["IconGroupCreateResult"]],
deleteIconGroup?: [{	by:ValueTypes["IconGroupUniqueWhere"],	filter?:ValueTypes["IconGroupWhere"]},ValueTypes["IconGroupDeleteResult"]],
updateIconGroup?: [{	by:ValueTypes["IconGroupUniqueWhere"],	filter?:ValueTypes["IconGroupWhere"],	data:ValueTypes["IconGroupUpdateInput"]},ValueTypes["IconGroupUpdateResult"]],
createIcon?: [{	data:ValueTypes["IconCreateInput"]},ValueTypes["IconCreateResult"]],
deleteIcon?: [{	by:ValueTypes["IconUniqueWhere"],	filter?:ValueTypes["IconWhere"]},ValueTypes["IconDeleteResult"]],
updateIcon?: [{	by:ValueTypes["IconUniqueWhere"],	filter?:ValueTypes["IconWhere"],	data:ValueTypes["IconUpdateInput"]},ValueTypes["IconUpdateResult"]],
createLocale?: [{	data:ValueTypes["LocaleCreateInput"]},ValueTypes["LocaleCreateResult"]],
deleteLocale?: [{	by:ValueTypes["LocaleUniqueWhere"],	filter?:ValueTypes["LocaleWhere"]},ValueTypes["LocaleDeleteResult"]],
updateLocale?: [{	by:ValueTypes["LocaleUniqueWhere"],	filter?:ValueTypes["LocaleWhere"],	data:ValueTypes["LocaleUpdateInput"]},ValueTypes["LocaleUpdateResult"]],
createSeoLocale?: [{	data:ValueTypes["SeoLocaleCreateInput"]},ValueTypes["SeoLocaleCreateResult"]],
deleteSeoLocale?: [{	by:ValueTypes["SeoLocaleUniqueWhere"],	filter?:ValueTypes["SeoLocaleWhere"]},ValueTypes["SeoLocaleDeleteResult"]],
updateSeoLocale?: [{	by:ValueTypes["SeoLocaleUniqueWhere"],	filter?:ValueTypes["SeoLocaleWhere"],	data:ValueTypes["SeoLocaleUpdateInput"]},ValueTypes["SeoLocaleUpdateResult"]],
createSeo?: [{	data:ValueTypes["SeoCreateInput"]},ValueTypes["SeoCreateResult"]],
deleteSeo?: [{	by:ValueTypes["SeoUniqueWhere"],	filter?:ValueTypes["SeoWhere"]},ValueTypes["SeoDeleteResult"]],
updateSeo?: [{	by:ValueTypes["SeoUniqueWhere"],	filter?:ValueTypes["SeoWhere"],	data:ValueTypes["SeoUpdateInput"]},ValueTypes["SeoUpdateResult"]],
createLinkable?: [{	data:ValueTypes["LinkableCreateInput"]},ValueTypes["LinkableCreateResult"]],
deleteLinkable?: [{	by:ValueTypes["LinkableUniqueWhere"],	filter?:ValueTypes["LinkableWhere"]},ValueTypes["LinkableDeleteResult"]],
updateLinkable?: [{	by:ValueTypes["LinkableUniqueWhere"],	filter?:ValueTypes["LinkableWhere"],	data:ValueTypes["LinkableUpdateInput"]},ValueTypes["LinkableUpdateResult"]],
createImageList?: [{	data:ValueTypes["ImageListCreateInput"]},ValueTypes["ImageListCreateResult"]],
deleteImageList?: [{	by:ValueTypes["ImageListUniqueWhere"],	filter?:ValueTypes["ImageListWhere"]},ValueTypes["ImageListDeleteResult"]],
updateImageList?: [{	by:ValueTypes["ImageListUniqueWhere"],	filter?:ValueTypes["ImageListWhere"],	data:ValueTypes["ImageListUpdateInput"]},ValueTypes["ImageListUpdateResult"]],
createImage?: [{	data:ValueTypes["ImageCreateInput"]},ValueTypes["ImageCreateResult"]],
deleteImage?: [{	by:ValueTypes["ImageUniqueWhere"],	filter?:ValueTypes["ImageWhere"]},ValueTypes["ImageDeleteResult"]],
updateImage?: [{	by:ValueTypes["ImageUniqueWhere"],	filter?:ValueTypes["ImageWhere"],	data:ValueTypes["ImageUpdateInput"]},ValueTypes["ImageUpdateResult"]],
		__typename?: true
}>;
	["OrderDirection"]:OrderDirection;
	["Page"]: AliasType<{
	_meta?:ValueTypes["PageMeta"],
	id?:true,
	internalName?:true,
	template?:true,
seo?: [{	filter?:ValueTypes["SeoWhere"]},ValueTypes["Seo"]],
locales?: [{	filter?:ValueTypes["PageLocaleWhere"],	orderBy?:ValueTypes["PageLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["PageLocale"]],
localesByLocale?: [{	by:ValueTypes["PageLocalesByLocaleUniqueWhere"],	filter?:ValueTypes["PageLocaleWhere"]},ValueTypes["PageLocale"]],
localesByLink?: [{	by:ValueTypes["PageLocalesByLinkUniqueWhere"],	filter?:ValueTypes["PageLocaleWhere"]},ValueTypes["PageLocale"]],
localesByContent?: [{	by:ValueTypes["PageLocalesByContentUniqueWhere"],	filter?:ValueTypes["PageLocaleWhere"]},ValueTypes["PageLocale"]],
		__typename?: true
}>;
	["PageConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PageEdge"],
		__typename?: true
}>;
	["PageCreateInput"]: {
	internalName?:string,
	template?:ValueTypes["PageType"],
	seo?:ValueTypes["PageCreateSeoEntityRelationInput"],
	locales?:ValueTypes["PageCreateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["PageCreateLocalesEntityRelationInput"]: {
	connect?:ValueTypes["PageLocaleUniqueWhere"],
	create?:ValueTypes["PageLocaleWithoutRootCreateInput"],
	alias?:string
};
	["PageCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Page"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["PageCreateSeoEntityRelationInput"]: {
	connect?:ValueTypes["SeoUniqueWhere"],
	create?:ValueTypes["SeoCreateInput"]
};
	["PageDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Page"],
		__typename?: true
}>;
	["PageEdge"]: AliasType<{
	node?:ValueTypes["Page"],
		__typename?: true
}>;
	["PageInfo"]: AliasType<{
	totalCount?:true,
		__typename?: true
}>;
	["PageLocale"]: AliasType<{
	_meta?:ValueTypes["PageLocaleMeta"],
	id?:true,
	title?:true,
locale?: [{	filter?:ValueTypes["LocaleWhere"]},ValueTypes["Locale"]],
link?: [{	filter?:ValueTypes["LinkableWhere"]},ValueTypes["Linkable"]],
root?: [{	filter?:ValueTypes["PageWhere"]},ValueTypes["Page"]],
content?: [{	filter?:ValueTypes["ContentWhere"]},ValueTypes["Content"]],
	bannerColor?:true,
	bannerBgColor?:true,
	bannerText?:true,
		__typename?: true
}>;
	["PageLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["PageLocaleEdge"],
		__typename?: true
}>;
	["PageLocaleCreateContentEntityRelationInput"]: {
	connect?:ValueTypes["ContentUniqueWhere"],
	create?:ValueTypes["ContentWithoutPageCreateInput"]
};
	["PageLocaleCreateInput"]: {
	title?:string,
	locale?:ValueTypes["PageLocaleCreateLocaleEntityRelationInput"],
	link?:ValueTypes["PageLocaleCreateLinkEntityRelationInput"],
	root?:ValueTypes["PageLocaleCreateRootEntityRelationInput"],
	content?:ValueTypes["PageLocaleCreateContentEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
};
	["PageLocaleCreateLinkEntityRelationInput"]: {
	connect?:ValueTypes["LinkableUniqueWhere"],
	create?:ValueTypes["LinkableWithoutPageCreateInput"]
};
	["PageLocaleCreateLocaleEntityRelationInput"]: {
	connect?:ValueTypes["LocaleUniqueWhere"],
	create?:ValueTypes["LocaleWithoutPagesCreateInput"]
};
	["PageLocaleCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["PageLocale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["PageLocaleCreateRootEntityRelationInput"]: {
	connect?:ValueTypes["PageUniqueWhere"],
	create?:ValueTypes["PageWithoutLocalesCreateInput"]
};
	["PageLocaleDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["PageLocale"],
		__typename?: true
}>;
	["PageLocaleEdge"]: AliasType<{
	node?:ValueTypes["PageLocale"],
		__typename?: true
}>;
	["PageLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	link?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	bannerColor?:ValueTypes["FieldMeta"],
	bannerBgColor?:ValueTypes["FieldMeta"],
	bannerText?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["PageLocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	title?:ValueTypes["OrderDirection"],
	locale?:ValueTypes["LocaleOrderBy"],
	link?:ValueTypes["LinkableOrderBy"],
	root?:ValueTypes["PageOrderBy"],
	content?:ValueTypes["ContentOrderBy"],
	bannerColor?:ValueTypes["OrderDirection"],
	bannerBgColor?:ValueTypes["OrderDirection"],
	bannerText?:ValueTypes["OrderDirection"]
};
	["PageLocalesByContentUniqueWhere"]: {
	content?:ValueTypes["ContentUniqueWhere"]
};
	["PageLocalesByLinkUniqueWhere"]: {
	link?:ValueTypes["LinkableUniqueWhere"]
};
	["PageLocalesByLocaleUniqueWhere"]: {
	locale?:ValueTypes["LocaleUniqueWhere"]
};
	["PageLocaleUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	root?:ValueTypes["PageUniqueWhere"],
	locale?:ValueTypes["LocaleUniqueWhere"],
	link?:ValueTypes["LinkableUniqueWhere"],
	content?:ValueTypes["ContentUniqueWhere"]
};
	["PageLocaleUpdateContentEntityRelationInput"]: {
	create?:ValueTypes["ContentWithoutPageCreateInput"],
	update?:ValueTypes["ContentWithoutPageUpdateInput"],
	upsert?:ValueTypes["PageLocaleUpsertContentRelationInput"],
	connect?:ValueTypes["ContentUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["PageLocaleUpdateInput"]: {
	title?:string,
	locale?:ValueTypes["PageLocaleUpdateLocaleEntityRelationInput"],
	link?:ValueTypes["PageLocaleUpdateLinkEntityRelationInput"],
	root?:ValueTypes["PageLocaleUpdateRootEntityRelationInput"],
	content?:ValueTypes["PageLocaleUpdateContentEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
};
	["PageLocaleUpdateLinkEntityRelationInput"]: {
	create?:ValueTypes["LinkableWithoutPageCreateInput"],
	update?:ValueTypes["LinkableWithoutPageUpdateInput"],
	upsert?:ValueTypes["PageLocaleUpsertLinkRelationInput"],
	connect?:ValueTypes["LinkableUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["PageLocaleUpdateLocaleEntityRelationInput"]: {
	create?:ValueTypes["LocaleWithoutPagesCreateInput"],
	update?:ValueTypes["LocaleWithoutPagesUpdateInput"],
	upsert?:ValueTypes["PageLocaleUpsertLocaleRelationInput"],
	connect?:ValueTypes["LocaleUniqueWhere"]
};
	["PageLocaleUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["PageLocale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["PageLocaleUpdateRootEntityRelationInput"]: {
	create?:ValueTypes["PageWithoutLocalesCreateInput"],
	update?:ValueTypes["PageWithoutLocalesUpdateInput"],
	upsert?:ValueTypes["PageLocaleUpsertRootRelationInput"],
	connect?:ValueTypes["PageUniqueWhere"]
};
	["PageLocaleUpsertContentRelationInput"]: {
	update?:ValueTypes["ContentWithoutPageUpdateInput"],
	create?:ValueTypes["ContentWithoutPageCreateInput"]
};
	["PageLocaleUpsertLinkRelationInput"]: {
	update?:ValueTypes["LinkableWithoutPageUpdateInput"],
	create?:ValueTypes["LinkableWithoutPageCreateInput"]
};
	["PageLocaleUpsertLocaleRelationInput"]: {
	update?:ValueTypes["LocaleWithoutPagesUpdateInput"],
	create?:ValueTypes["LocaleWithoutPagesCreateInput"]
};
	["PageLocaleUpsertRootRelationInput"]: {
	update?:ValueTypes["PageWithoutLocalesUpdateInput"],
	create?:ValueTypes["PageWithoutLocalesCreateInput"]
};
	["PageLocaleWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	title?:ValueTypes["StringCondition"],
	locale?:ValueTypes["LocaleWhere"],
	link?:ValueTypes["LinkableWhere"],
	root?:ValueTypes["PageWhere"],
	content?:ValueTypes["ContentWhere"],
	bannerColor?:ValueTypes["StringCondition"],
	bannerBgColor?:ValueTypes["StringCondition"],
	bannerText?:ValueTypes["StringCondition"],
	and?:ValueTypes["PageLocaleWhere"][],
	or?:ValueTypes["PageLocaleWhere"][],
	not?:ValueTypes["PageLocaleWhere"]
};
	["PageLocaleWithoutContentCreateInput"]: {
	title?:string,
	locale?:ValueTypes["PageLocaleCreateLocaleEntityRelationInput"],
	link?:ValueTypes["PageLocaleCreateLinkEntityRelationInput"],
	root?:ValueTypes["PageLocaleCreateRootEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
};
	["PageLocaleWithoutContentUpdateInput"]: {
	title?:string,
	locale?:ValueTypes["PageLocaleUpdateLocaleEntityRelationInput"],
	link?:ValueTypes["PageLocaleUpdateLinkEntityRelationInput"],
	root?:ValueTypes["PageLocaleUpdateRootEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
};
	["PageLocaleWithoutLinkCreateInput"]: {
	title?:string,
	locale?:ValueTypes["PageLocaleCreateLocaleEntityRelationInput"],
	root?:ValueTypes["PageLocaleCreateRootEntityRelationInput"],
	content?:ValueTypes["PageLocaleCreateContentEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
};
	["PageLocaleWithoutLinkUpdateInput"]: {
	title?:string,
	locale?:ValueTypes["PageLocaleUpdateLocaleEntityRelationInput"],
	root?:ValueTypes["PageLocaleUpdateRootEntityRelationInput"],
	content?:ValueTypes["PageLocaleUpdateContentEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
};
	["PageLocaleWithoutLocaleCreateInput"]: {
	title?:string,
	link?:ValueTypes["PageLocaleCreateLinkEntityRelationInput"],
	root?:ValueTypes["PageLocaleCreateRootEntityRelationInput"],
	content?:ValueTypes["PageLocaleCreateContentEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
};
	["PageLocaleWithoutLocaleUpdateInput"]: {
	title?:string,
	link?:ValueTypes["PageLocaleUpdateLinkEntityRelationInput"],
	root?:ValueTypes["PageLocaleUpdateRootEntityRelationInput"],
	content?:ValueTypes["PageLocaleUpdateContentEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
};
	["PageLocaleWithoutRootCreateInput"]: {
	title?:string,
	locale?:ValueTypes["PageLocaleCreateLocaleEntityRelationInput"],
	link?:ValueTypes["PageLocaleCreateLinkEntityRelationInput"],
	content?:ValueTypes["PageLocaleCreateContentEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
};
	["PageLocaleWithoutRootUpdateInput"]: {
	title?:string,
	locale?:ValueTypes["PageLocaleUpdateLocaleEntityRelationInput"],
	link?:ValueTypes["PageLocaleUpdateLinkEntityRelationInput"],
	content?:ValueTypes["PageLocaleUpdateContentEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
};
	["PageMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	internalName?:ValueTypes["FieldMeta"],
	template?:ValueTypes["FieldMeta"],
	seo?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["PageOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	internalName?:ValueTypes["OrderDirection"],
	template?:ValueTypes["OrderDirection"],
	seo?:ValueTypes["SeoOrderBy"]
};
	["PageType"]:PageType;
	["PageTypeEnumCondition"]: {
	and?:ValueTypes["PageTypeEnumCondition"][],
	or?:ValueTypes["PageTypeEnumCondition"][],
	not?:ValueTypes["PageTypeEnumCondition"],
	eq?:ValueTypes["PageType"],
	null?:boolean,
	isNull?:boolean,
	notEq?:ValueTypes["PageType"],
	in?:ValueTypes["PageType"][],
	notIn?:ValueTypes["PageType"][],
	lt?:ValueTypes["PageType"],
	lte?:ValueTypes["PageType"],
	gt?:ValueTypes["PageType"],
	gte?:ValueTypes["PageType"]
};
	["PageUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	seo?:ValueTypes["SeoUniqueWhere"],
	locales?:ValueTypes["PageLocaleUniqueWhere"]
};
	["PageUpdateInput"]: {
	internalName?:string,
	template?:ValueTypes["PageType"],
	seo?:ValueTypes["PageUpdateSeoEntityRelationInput"],
	locales?:ValueTypes["PageUpdateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["PageUpdateLocalesEntityRelationInput"]: {
	create?:ValueTypes["PageLocaleWithoutRootCreateInput"],
	update?:ValueTypes["PageUpdateLocalesRelationInput"],
	upsert?:ValueTypes["PageUpsertLocalesRelationInput"],
	connect?:ValueTypes["PageLocaleUniqueWhere"],
	disconnect?:ValueTypes["PageLocaleUniqueWhere"],
	delete?:ValueTypes["PageLocaleUniqueWhere"],
	alias?:string
};
	["PageUpdateLocalesRelationInput"]: {
	by?:ValueTypes["PageLocaleUniqueWhere"],
	data?:ValueTypes["PageLocaleWithoutRootUpdateInput"]
};
	["PageUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Page"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["PageUpdateSeoEntityRelationInput"]: {
	create?:ValueTypes["SeoCreateInput"],
	update?:ValueTypes["SeoUpdateInput"],
	upsert?:ValueTypes["PageUpsertSeoRelationInput"],
	connect?:ValueTypes["SeoUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["PageUpsertLocalesRelationInput"]: {
	by?:ValueTypes["PageLocaleUniqueWhere"],
	update?:ValueTypes["PageLocaleWithoutRootUpdateInput"],
	create?:ValueTypes["PageLocaleWithoutRootCreateInput"]
};
	["PageUpsertSeoRelationInput"]: {
	update?:ValueTypes["SeoUpdateInput"],
	create?:ValueTypes["SeoCreateInput"]
};
	["PageWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	internalName?:ValueTypes["StringCondition"],
	template?:ValueTypes["PageTypeEnumCondition"],
	seo?:ValueTypes["SeoWhere"],
	locales?:ValueTypes["PageLocaleWhere"],
	and?:ValueTypes["PageWhere"][],
	or?:ValueTypes["PageWhere"][],
	not?:ValueTypes["PageWhere"]
};
	["PageWithoutLocalesCreateInput"]: {
	internalName?:string,
	template?:ValueTypes["PageType"],
	seo?:ValueTypes["PageCreateSeoEntityRelationInput"],
	_dummy_field_?:boolean
};
	["PageWithoutLocalesUpdateInput"]: {
	internalName?:string,
	template?:ValueTypes["PageType"],
	seo?:ValueTypes["PageUpdateSeoEntityRelationInput"],
	_dummy_field_?:boolean
};
	["Product"]: AliasType<{
	_meta?:ValueTypes["ProductMeta"],
	id?:true,
	title?:true,
	shopifyHandle?:true,
	productType?:true,
stores?: [{	filter?:ValueTypes["ProductStoreWhere"],	orderBy?:ValueTypes["ProductStoreOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductStore"]],
productVariants?: [{	filter?:ValueTypes["ProductVariantWhere"],	orderBy?:ValueTypes["ProductVariantOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductVariant"]],
locales?: [{	filter?:ValueTypes["ProductLocaleWhere"],	orderBy?:ValueTypes["ProductLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductLocale"]],
storesByStore?: [{	by:ValueTypes["ProductStoresByStoreUniqueWhere"],	filter?:ValueTypes["ProductStoreWhere"]},ValueTypes["ProductStore"]],
storesByShopifyId?: [{	by:ValueTypes["ProductStoresByShopifyIdUniqueWhere"],	filter?:ValueTypes["ProductStoreWhere"]},ValueTypes["ProductStore"]],
productVariantsByShopifySku?: [{	by:ValueTypes["ProductProductVariantsByShopifySkuUniqueWhere"],	filter?:ValueTypes["ProductVariantWhere"]},ValueTypes["ProductVariant"]],
productVariantsByLocales?: [{	by:ValueTypes["ProductProductVariantsByLocalesUniqueWhere"],	filter?:ValueTypes["ProductVariantWhere"]},ValueTypes["ProductVariant"]],
productVariantsByStores?: [{	by:ValueTypes["ProductProductVariantsByStoresUniqueWhere"],	filter?:ValueTypes["ProductVariantWhere"]},ValueTypes["ProductVariant"]],
localesByLocale?: [{	by:ValueTypes["ProductLocalesByLocaleUniqueWhere"],	filter?:ValueTypes["ProductLocaleWhere"]},ValueTypes["ProductLocale"]],
		__typename?: true
}>;
	["ProductConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductEdge"],
		__typename?: true
}>;
	["ProductCreateInput"]: {
	title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:ValueTypes["ProductCreateStoresEntityRelationInput"][],
	productVariants?:ValueTypes["ProductCreateProductVariantsEntityRelationInput"][],
	locales?:ValueTypes["ProductCreateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["ProductCreateLocalesEntityRelationInput"]: {
	connect?:ValueTypes["ProductLocaleUniqueWhere"],
	create?:ValueTypes["ProductLocaleWithoutRootCreateInput"],
	alias?:string
};
	["ProductCreateProductVariantsEntityRelationInput"]: {
	connect?:ValueTypes["ProductVariantUniqueWhere"],
	create?:ValueTypes["ProductVariantWithoutProductCreateInput"],
	alias?:string
};
	["ProductCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Product"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ProductCreateStoresEntityRelationInput"]: {
	connect?:ValueTypes["ProductStoreUniqueWhere"],
	create?:ValueTypes["ProductStoreWithoutRootCreateInput"],
	alias?:string
};
	["ProductDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Product"],
		__typename?: true
}>;
	["ProductEdge"]: AliasType<{
	node?:ValueTypes["Product"],
		__typename?: true
}>;
	["ProductLocale"]: AliasType<{
	_meta?:ValueTypes["ProductLocaleMeta"],
	id?:true,
	title?:true,
	description?:true,
	descriptionSecondary?:true,
root?: [{	filter?:ValueTypes["ProductWhere"]},ValueTypes["Product"]],
locale?: [{	filter?:ValueTypes["LocaleWhere"]},ValueTypes["Locale"]],
gallery?: [{	filter?:ValueTypes["GalleryWhere"]},ValueTypes["Gallery"]],
	titleTemplate?:true,
		__typename?: true
}>;
	["ProductLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductLocaleEdge"],
		__typename?: true
}>;
	["ProductLocaleCreateGalleryEntityRelationInput"]: {
	connect?:ValueTypes["GalleryUniqueWhere"],
	create?:ValueTypes["GalleryCreateInput"]
};
	["ProductLocaleCreateInput"]: {
	title?:string,
	description?:string,
	descriptionSecondary?:string,
	root?:ValueTypes["ProductLocaleCreateRootEntityRelationInput"],
	locale?:ValueTypes["ProductLocaleCreateLocaleEntityRelationInput"],
	gallery?:ValueTypes["ProductLocaleCreateGalleryEntityRelationInput"],
	titleTemplate?:string,
	_dummy_field_?:boolean
};
	["ProductLocaleCreateLocaleEntityRelationInput"]: {
	connect?:ValueTypes["LocaleUniqueWhere"],
	create?:ValueTypes["LocaleWithoutProductsCreateInput"]
};
	["ProductLocaleCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ProductLocale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ProductLocaleCreateRootEntityRelationInput"]: {
	connect?:ValueTypes["ProductUniqueWhere"],
	create?:ValueTypes["ProductWithoutLocalesCreateInput"]
};
	["ProductLocaleDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ProductLocale"],
		__typename?: true
}>;
	["ProductLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductLocale"],
		__typename?: true
}>;
	["ProductLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	descriptionSecondary?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	gallery?:ValueTypes["FieldMeta"],
	titleTemplate?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ProductLocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	title?:ValueTypes["OrderDirection"],
	description?:ValueTypes["OrderDirection"],
	descriptionSecondary?:ValueTypes["OrderDirection"],
	root?:ValueTypes["ProductOrderBy"],
	locale?:ValueTypes["LocaleOrderBy"],
	gallery?:ValueTypes["GalleryOrderBy"],
	titleTemplate?:ValueTypes["OrderDirection"]
};
	["ProductLocalesByLocaleUniqueWhere"]: {
	locale?:ValueTypes["LocaleUniqueWhere"]
};
	["ProductLocaleUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	root?:ValueTypes["ProductUniqueWhere"],
	locale?:ValueTypes["LocaleUniqueWhere"]
};
	["ProductLocaleUpdateGalleryEntityRelationInput"]: {
	create?:ValueTypes["GalleryCreateInput"],
	update?:ValueTypes["GalleryUpdateInput"],
	upsert?:ValueTypes["ProductLocaleUpsertGalleryRelationInput"],
	connect?:ValueTypes["GalleryUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["ProductLocaleUpdateInput"]: {
	title?:string,
	description?:string,
	descriptionSecondary?:string,
	root?:ValueTypes["ProductLocaleUpdateRootEntityRelationInput"],
	locale?:ValueTypes["ProductLocaleUpdateLocaleEntityRelationInput"],
	gallery?:ValueTypes["ProductLocaleUpdateGalleryEntityRelationInput"],
	titleTemplate?:string,
	_dummy_field_?:boolean
};
	["ProductLocaleUpdateLocaleEntityRelationInput"]: {
	create?:ValueTypes["LocaleWithoutProductsCreateInput"],
	update?:ValueTypes["LocaleWithoutProductsUpdateInput"],
	upsert?:ValueTypes["ProductLocaleUpsertLocaleRelationInput"],
	connect?:ValueTypes["LocaleUniqueWhere"]
};
	["ProductLocaleUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ProductLocale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ProductLocaleUpdateRootEntityRelationInput"]: {
	create?:ValueTypes["ProductWithoutLocalesCreateInput"],
	update?:ValueTypes["ProductWithoutLocalesUpdateInput"],
	upsert?:ValueTypes["ProductLocaleUpsertRootRelationInput"],
	connect?:ValueTypes["ProductUniqueWhere"]
};
	["ProductLocaleUpsertGalleryRelationInput"]: {
	update?:ValueTypes["GalleryUpdateInput"],
	create?:ValueTypes["GalleryCreateInput"]
};
	["ProductLocaleUpsertLocaleRelationInput"]: {
	update?:ValueTypes["LocaleWithoutProductsUpdateInput"],
	create?:ValueTypes["LocaleWithoutProductsCreateInput"]
};
	["ProductLocaleUpsertRootRelationInput"]: {
	update?:ValueTypes["ProductWithoutLocalesUpdateInput"],
	create?:ValueTypes["ProductWithoutLocalesCreateInput"]
};
	["ProductLocaleWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	title?:ValueTypes["StringCondition"],
	description?:ValueTypes["StringCondition"],
	descriptionSecondary?:ValueTypes["StringCondition"],
	root?:ValueTypes["ProductWhere"],
	locale?:ValueTypes["LocaleWhere"],
	gallery?:ValueTypes["GalleryWhere"],
	titleTemplate?:ValueTypes["StringCondition"],
	and?:ValueTypes["ProductLocaleWhere"][],
	or?:ValueTypes["ProductLocaleWhere"][],
	not?:ValueTypes["ProductLocaleWhere"]
};
	["ProductLocaleWithoutLocaleCreateInput"]: {
	title?:string,
	description?:string,
	descriptionSecondary?:string,
	root?:ValueTypes["ProductLocaleCreateRootEntityRelationInput"],
	gallery?:ValueTypes["ProductLocaleCreateGalleryEntityRelationInput"],
	titleTemplate?:string,
	_dummy_field_?:boolean
};
	["ProductLocaleWithoutLocaleUpdateInput"]: {
	title?:string,
	description?:string,
	descriptionSecondary?:string,
	root?:ValueTypes["ProductLocaleUpdateRootEntityRelationInput"],
	gallery?:ValueTypes["ProductLocaleUpdateGalleryEntityRelationInput"],
	titleTemplate?:string,
	_dummy_field_?:boolean
};
	["ProductLocaleWithoutRootCreateInput"]: {
	title?:string,
	description?:string,
	descriptionSecondary?:string,
	locale?:ValueTypes["ProductLocaleCreateLocaleEntityRelationInput"],
	gallery?:ValueTypes["ProductLocaleCreateGalleryEntityRelationInput"],
	titleTemplate?:string,
	_dummy_field_?:boolean
};
	["ProductLocaleWithoutRootUpdateInput"]: {
	title?:string,
	description?:string,
	descriptionSecondary?:string,
	locale?:ValueTypes["ProductLocaleUpdateLocaleEntityRelationInput"],
	gallery?:ValueTypes["ProductLocaleUpdateGalleryEntityRelationInput"],
	titleTemplate?:string,
	_dummy_field_?:boolean
};
	["ProductMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	shopifyHandle?:ValueTypes["FieldMeta"],
	productType?:ValueTypes["FieldMeta"],
	stores?:ValueTypes["FieldMeta"],
	productVariants?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ProductOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	title?:ValueTypes["OrderDirection"],
	shopifyHandle?:ValueTypes["OrderDirection"],
	productType?:ValueTypes["OrderDirection"]
};
	["ProductProductVariantsByLocalesUniqueWhere"]: {
	locales?:ValueTypes["ProductVariantLocaleUniqueWhere"]
};
	["ProductProductVariantsByShopifySkuUniqueWhere"]: {
	shopifySku?:string
};
	["ProductProductVariantsByStoresUniqueWhere"]: {
	stores?:ValueTypes["ProductVariantStoreUniqueWhere"]
};
	["ProductStore"]: AliasType<{
	_meta?:ValueTypes["ProductStoreMeta"],
	id?:true,
	shopifyId?:true,
root?: [{	filter?:ValueTypes["ProductWhere"]},ValueTypes["Product"]],
store?: [{	filter?:ValueTypes["StoreWhere"]},ValueTypes["Store"]],
		__typename?: true
}>;
	["ProductStoreConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductStoreEdge"],
		__typename?: true
}>;
	["ProductStoreCreateInput"]: {
	shopifyId?:string,
	root?:ValueTypes["ProductStoreCreateRootEntityRelationInput"],
	store?:ValueTypes["ProductStoreCreateStoreEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ProductStoreCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ProductStore"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ProductStoreCreateRootEntityRelationInput"]: {
	connect?:ValueTypes["ProductUniqueWhere"],
	create?:ValueTypes["ProductWithoutStoresCreateInput"]
};
	["ProductStoreCreateStoreEntityRelationInput"]: {
	connect?:ValueTypes["StoreUniqueWhere"],
	create?:ValueTypes["StoreWithoutProductsCreateInput"]
};
	["ProductStoreDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ProductStore"],
		__typename?: true
}>;
	["ProductStoreEdge"]: AliasType<{
	node?:ValueTypes["ProductStore"],
		__typename?: true
}>;
	["ProductStoreMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	shopifyId?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	store?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ProductStoreOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	shopifyId?:ValueTypes["OrderDirection"],
	root?:ValueTypes["ProductOrderBy"],
	store?:ValueTypes["StoreOrderBy"]
};
	["ProductStoresByShopifyIdUniqueWhere"]: {
	shopifyId?:string
};
	["ProductStoresByStoreUniqueWhere"]: {
	store?:ValueTypes["StoreUniqueWhere"]
};
	["ProductStoreUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	root?:ValueTypes["ProductUniqueWhere"],
	store?:ValueTypes["StoreUniqueWhere"],
	shopifyId?:string
};
	["ProductStoreUpdateInput"]: {
	shopifyId?:string,
	root?:ValueTypes["ProductStoreUpdateRootEntityRelationInput"],
	store?:ValueTypes["ProductStoreUpdateStoreEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ProductStoreUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ProductStore"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ProductStoreUpdateRootEntityRelationInput"]: {
	create?:ValueTypes["ProductWithoutStoresCreateInput"],
	update?:ValueTypes["ProductWithoutStoresUpdateInput"],
	upsert?:ValueTypes["ProductStoreUpsertRootRelationInput"],
	connect?:ValueTypes["ProductUniqueWhere"]
};
	["ProductStoreUpdateStoreEntityRelationInput"]: {
	create?:ValueTypes["StoreWithoutProductsCreateInput"],
	update?:ValueTypes["StoreWithoutProductsUpdateInput"],
	upsert?:ValueTypes["ProductStoreUpsertStoreRelationInput"],
	connect?:ValueTypes["StoreUniqueWhere"]
};
	["ProductStoreUpsertRootRelationInput"]: {
	update?:ValueTypes["ProductWithoutStoresUpdateInput"],
	create?:ValueTypes["ProductWithoutStoresCreateInput"]
};
	["ProductStoreUpsertStoreRelationInput"]: {
	update?:ValueTypes["StoreWithoutProductsUpdateInput"],
	create?:ValueTypes["StoreWithoutProductsCreateInput"]
};
	["ProductStoreWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	shopifyId?:ValueTypes["StringCondition"],
	root?:ValueTypes["ProductWhere"],
	store?:ValueTypes["StoreWhere"],
	and?:ValueTypes["ProductStoreWhere"][],
	or?:ValueTypes["ProductStoreWhere"][],
	not?:ValueTypes["ProductStoreWhere"]
};
	["ProductStoreWithoutRootCreateInput"]: {
	shopifyId?:string,
	store?:ValueTypes["ProductStoreCreateStoreEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ProductStoreWithoutRootUpdateInput"]: {
	shopifyId?:string,
	store?:ValueTypes["ProductStoreUpdateStoreEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ProductStoreWithoutStoreCreateInput"]: {
	shopifyId?:string,
	root?:ValueTypes["ProductStoreCreateRootEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ProductStoreWithoutStoreUpdateInput"]: {
	shopifyId?:string,
	root?:ValueTypes["ProductStoreUpdateRootEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ProductUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	shopifyHandle?:string,
	stores?:ValueTypes["ProductStoreUniqueWhere"],
	productVariants?:ValueTypes["ProductVariantUniqueWhere"],
	locales?:ValueTypes["ProductLocaleUniqueWhere"]
};
	["ProductUpdateInput"]: {
	title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:ValueTypes["ProductUpdateStoresEntityRelationInput"][],
	productVariants?:ValueTypes["ProductUpdateProductVariantsEntityRelationInput"][],
	locales?:ValueTypes["ProductUpdateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["ProductUpdateLocalesEntityRelationInput"]: {
	create?:ValueTypes["ProductLocaleWithoutRootCreateInput"],
	update?:ValueTypes["ProductUpdateLocalesRelationInput"],
	upsert?:ValueTypes["ProductUpsertLocalesRelationInput"],
	connect?:ValueTypes["ProductLocaleUniqueWhere"],
	disconnect?:ValueTypes["ProductLocaleUniqueWhere"],
	delete?:ValueTypes["ProductLocaleUniqueWhere"],
	alias?:string
};
	["ProductUpdateLocalesRelationInput"]: {
	by?:ValueTypes["ProductLocaleUniqueWhere"],
	data?:ValueTypes["ProductLocaleWithoutRootUpdateInput"]
};
	["ProductUpdateProductVariantsEntityRelationInput"]: {
	create?:ValueTypes["ProductVariantWithoutProductCreateInput"],
	update?:ValueTypes["ProductUpdateProductVariantsRelationInput"],
	upsert?:ValueTypes["ProductUpsertProductVariantsRelationInput"],
	connect?:ValueTypes["ProductVariantUniqueWhere"],
	disconnect?:ValueTypes["ProductVariantUniqueWhere"],
	delete?:ValueTypes["ProductVariantUniqueWhere"],
	alias?:string
};
	["ProductUpdateProductVariantsRelationInput"]: {
	by?:ValueTypes["ProductVariantUniqueWhere"],
	data?:ValueTypes["ProductVariantWithoutProductUpdateInput"]
};
	["ProductUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Product"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ProductUpdateStoresEntityRelationInput"]: {
	create?:ValueTypes["ProductStoreWithoutRootCreateInput"],
	update?:ValueTypes["ProductUpdateStoresRelationInput"],
	upsert?:ValueTypes["ProductUpsertStoresRelationInput"],
	connect?:ValueTypes["ProductStoreUniqueWhere"],
	disconnect?:ValueTypes["ProductStoreUniqueWhere"],
	delete?:ValueTypes["ProductStoreUniqueWhere"],
	alias?:string
};
	["ProductUpdateStoresRelationInput"]: {
	by?:ValueTypes["ProductStoreUniqueWhere"],
	data?:ValueTypes["ProductStoreWithoutRootUpdateInput"]
};
	["ProductUpsertLocalesRelationInput"]: {
	by?:ValueTypes["ProductLocaleUniqueWhere"],
	update?:ValueTypes["ProductLocaleWithoutRootUpdateInput"],
	create?:ValueTypes["ProductLocaleWithoutRootCreateInput"]
};
	["ProductUpsertProductVariantsRelationInput"]: {
	by?:ValueTypes["ProductVariantUniqueWhere"],
	update?:ValueTypes["ProductVariantWithoutProductUpdateInput"],
	create?:ValueTypes["ProductVariantWithoutProductCreateInput"]
};
	["ProductUpsertStoresRelationInput"]: {
	by?:ValueTypes["ProductStoreUniqueWhere"],
	update?:ValueTypes["ProductStoreWithoutRootUpdateInput"],
	create?:ValueTypes["ProductStoreWithoutRootCreateInput"]
};
	["ProductVariant"]: AliasType<{
	_meta?:ValueTypes["ProductVariantMeta"],
	id?:true,
	shopifySku?:true,
product?: [{	filter?:ValueTypes["ProductWhere"]},ValueTypes["Product"]],
locales?: [{	filter?:ValueTypes["ProductVariantLocaleWhere"],	orderBy?:ValueTypes["ProductVariantLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductVariantLocale"]],
stores?: [{	filter?:ValueTypes["ProductVariantStoreWhere"],	orderBy?:ValueTypes["ProductVariantStoreOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductVariantStore"]],
	title?:true,
	heurekaId?:true,
localesByLocale?: [{	by:ValueTypes["ProductVariantLocalesByLocaleUniqueWhere"],	filter?:ValueTypes["ProductVariantLocaleWhere"]},ValueTypes["ProductVariantLocale"]],
storesByStore?: [{	by:ValueTypes["ProductVariantStoresByStoreUniqueWhere"],	filter?:ValueTypes["ProductVariantStoreWhere"]},ValueTypes["ProductVariantStore"]],
storesByShopifyId?: [{	by:ValueTypes["ProductVariantStoresByShopifyIdUniqueWhere"],	filter?:ValueTypes["ProductVariantStoreWhere"]},ValueTypes["ProductVariantStore"]],
storesByStorefrontId?: [{	by:ValueTypes["ProductVariantStoresByStorefrontIdUniqueWhere"],	filter?:ValueTypes["ProductVariantStoreWhere"]},ValueTypes["ProductVariantStore"]],
		__typename?: true
}>;
	["ProductVariantConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductVariantEdge"],
		__typename?: true
}>;
	["ProductVariantCreateInput"]: {
	shopifySku?:string,
	product?:ValueTypes["ProductVariantCreateProductEntityRelationInput"],
	locales?:ValueTypes["ProductVariantCreateLocalesEntityRelationInput"][],
	stores?:ValueTypes["ProductVariantCreateStoresEntityRelationInput"][],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
};
	["ProductVariantCreateLocalesEntityRelationInput"]: {
	connect?:ValueTypes["ProductVariantLocaleUniqueWhere"],
	create?:ValueTypes["ProductVariantLocaleWithoutRootCreateInput"],
	alias?:string
};
	["ProductVariantCreateProductEntityRelationInput"]: {
	connect?:ValueTypes["ProductUniqueWhere"],
	create?:ValueTypes["ProductWithoutProductVariantsCreateInput"]
};
	["ProductVariantCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ProductVariant"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ProductVariantCreateStoresEntityRelationInput"]: {
	connect?:ValueTypes["ProductVariantStoreUniqueWhere"],
	create?:ValueTypes["ProductVariantStoreWithoutRootCreateInput"],
	alias?:string
};
	["ProductVariantDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ProductVariant"],
		__typename?: true
}>;
	["ProductVariantEdge"]: AliasType<{
	node?:ValueTypes["ProductVariant"],
		__typename?: true
}>;
	["ProductVariantLocale"]: AliasType<{
	_meta?:ValueTypes["ProductVariantLocaleMeta"],
	id?:true,
	title?:true,
	description?:true,
root?: [{	filter?:ValueTypes["ProductVariantWhere"]},ValueTypes["ProductVariant"]],
locale?: [{	filter?:ValueTypes["LocaleWhere"]},ValueTypes["Locale"]],
gallery?: [{	filter?:ValueTypes["GalleryWhere"]},ValueTypes["Gallery"]],
	descriptionSecondary?:true,
	titleTemplate?:true,
		__typename?: true
}>;
	["ProductVariantLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductVariantLocaleEdge"],
		__typename?: true
}>;
	["ProductVariantLocaleCreateGalleryEntityRelationInput"]: {
	connect?:ValueTypes["GalleryUniqueWhere"],
	create?:ValueTypes["GalleryCreateInput"]
};
	["ProductVariantLocaleCreateInput"]: {
	title?:string,
	description?:string,
	root?:ValueTypes["ProductVariantLocaleCreateRootEntityRelationInput"],
	locale?:ValueTypes["ProductVariantLocaleCreateLocaleEntityRelationInput"],
	gallery?:ValueTypes["ProductVariantLocaleCreateGalleryEntityRelationInput"],
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
};
	["ProductVariantLocaleCreateLocaleEntityRelationInput"]: {
	connect?:ValueTypes["LocaleUniqueWhere"],
	create?:ValueTypes["LocaleWithoutProductVariantsCreateInput"]
};
	["ProductVariantLocaleCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ProductVariantLocale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ProductVariantLocaleCreateRootEntityRelationInput"]: {
	connect?:ValueTypes["ProductVariantUniqueWhere"],
	create?:ValueTypes["ProductVariantWithoutLocalesCreateInput"]
};
	["ProductVariantLocaleDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ProductVariantLocale"],
		__typename?: true
}>;
	["ProductVariantLocaleEdge"]: AliasType<{
	node?:ValueTypes["ProductVariantLocale"],
		__typename?: true
}>;
	["ProductVariantLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	gallery?:ValueTypes["FieldMeta"],
	descriptionSecondary?:ValueTypes["FieldMeta"],
	titleTemplate?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ProductVariantLocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	title?:ValueTypes["OrderDirection"],
	description?:ValueTypes["OrderDirection"],
	root?:ValueTypes["ProductVariantOrderBy"],
	locale?:ValueTypes["LocaleOrderBy"],
	gallery?:ValueTypes["GalleryOrderBy"],
	descriptionSecondary?:ValueTypes["OrderDirection"],
	titleTemplate?:ValueTypes["OrderDirection"]
};
	["ProductVariantLocalesByLocaleUniqueWhere"]: {
	locale?:ValueTypes["LocaleUniqueWhere"]
};
	["ProductVariantLocaleUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	root?:ValueTypes["ProductVariantUniqueWhere"],
	locale?:ValueTypes["LocaleUniqueWhere"]
};
	["ProductVariantLocaleUpdateGalleryEntityRelationInput"]: {
	create?:ValueTypes["GalleryCreateInput"],
	update?:ValueTypes["GalleryUpdateInput"],
	upsert?:ValueTypes["ProductVariantLocaleUpsertGalleryRelationInput"],
	connect?:ValueTypes["GalleryUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["ProductVariantLocaleUpdateInput"]: {
	title?:string,
	description?:string,
	root?:ValueTypes["ProductVariantLocaleUpdateRootEntityRelationInput"],
	locale?:ValueTypes["ProductVariantLocaleUpdateLocaleEntityRelationInput"],
	gallery?:ValueTypes["ProductVariantLocaleUpdateGalleryEntityRelationInput"],
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
};
	["ProductVariantLocaleUpdateLocaleEntityRelationInput"]: {
	create?:ValueTypes["LocaleWithoutProductVariantsCreateInput"],
	update?:ValueTypes["LocaleWithoutProductVariantsUpdateInput"],
	upsert?:ValueTypes["ProductVariantLocaleUpsertLocaleRelationInput"],
	connect?:ValueTypes["LocaleUniqueWhere"]
};
	["ProductVariantLocaleUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ProductVariantLocale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ProductVariantLocaleUpdateRootEntityRelationInput"]: {
	create?:ValueTypes["ProductVariantWithoutLocalesCreateInput"],
	update?:ValueTypes["ProductVariantWithoutLocalesUpdateInput"],
	upsert?:ValueTypes["ProductVariantLocaleUpsertRootRelationInput"],
	connect?:ValueTypes["ProductVariantUniqueWhere"]
};
	["ProductVariantLocaleUpsertGalleryRelationInput"]: {
	update?:ValueTypes["GalleryUpdateInput"],
	create?:ValueTypes["GalleryCreateInput"]
};
	["ProductVariantLocaleUpsertLocaleRelationInput"]: {
	update?:ValueTypes["LocaleWithoutProductVariantsUpdateInput"],
	create?:ValueTypes["LocaleWithoutProductVariantsCreateInput"]
};
	["ProductVariantLocaleUpsertRootRelationInput"]: {
	update?:ValueTypes["ProductVariantWithoutLocalesUpdateInput"],
	create?:ValueTypes["ProductVariantWithoutLocalesCreateInput"]
};
	["ProductVariantLocaleWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	title?:ValueTypes["StringCondition"],
	description?:ValueTypes["StringCondition"],
	root?:ValueTypes["ProductVariantWhere"],
	locale?:ValueTypes["LocaleWhere"],
	gallery?:ValueTypes["GalleryWhere"],
	descriptionSecondary?:ValueTypes["StringCondition"],
	titleTemplate?:ValueTypes["StringCondition"],
	and?:ValueTypes["ProductVariantLocaleWhere"][],
	or?:ValueTypes["ProductVariantLocaleWhere"][],
	not?:ValueTypes["ProductVariantLocaleWhere"]
};
	["ProductVariantLocaleWithoutLocaleCreateInput"]: {
	title?:string,
	description?:string,
	root?:ValueTypes["ProductVariantLocaleCreateRootEntityRelationInput"],
	gallery?:ValueTypes["ProductVariantLocaleCreateGalleryEntityRelationInput"],
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
};
	["ProductVariantLocaleWithoutLocaleUpdateInput"]: {
	title?:string,
	description?:string,
	root?:ValueTypes["ProductVariantLocaleUpdateRootEntityRelationInput"],
	gallery?:ValueTypes["ProductVariantLocaleUpdateGalleryEntityRelationInput"],
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
};
	["ProductVariantLocaleWithoutRootCreateInput"]: {
	title?:string,
	description?:string,
	locale?:ValueTypes["ProductVariantLocaleCreateLocaleEntityRelationInput"],
	gallery?:ValueTypes["ProductVariantLocaleCreateGalleryEntityRelationInput"],
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
};
	["ProductVariantLocaleWithoutRootUpdateInput"]: {
	title?:string,
	description?:string,
	locale?:ValueTypes["ProductVariantLocaleUpdateLocaleEntityRelationInput"],
	gallery?:ValueTypes["ProductVariantLocaleUpdateGalleryEntityRelationInput"],
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
};
	["ProductVariantMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	shopifySku?:ValueTypes["FieldMeta"],
	product?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	stores?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	heurekaId?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ProductVariantOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	shopifySku?:ValueTypes["OrderDirection"],
	product?:ValueTypes["ProductOrderBy"],
	title?:ValueTypes["OrderDirection"],
	heurekaId?:ValueTypes["OrderDirection"]
};
	["ProductVariantStore"]: AliasType<{
	_meta?:ValueTypes["ProductVariantStoreMeta"],
	id?:true,
	price?:true,
	shopifyId?:true,
root?: [{	filter?:ValueTypes["ProductVariantWhere"]},ValueTypes["ProductVariant"]],
store?: [{	filter?:ValueTypes["StoreWhere"]},ValueTypes["Store"]],
	compareAtPrice?:true,
	storefrontId?:true,
		__typename?: true
}>;
	["ProductVariantStoreConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ProductVariantStoreEdge"],
		__typename?: true
}>;
	["ProductVariantStoreCreateInput"]: {
	price?:number,
	shopifyId?:string,
	root?:ValueTypes["ProductVariantStoreCreateRootEntityRelationInput"],
	store?:ValueTypes["ProductVariantStoreCreateStoreEntityRelationInput"],
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
};
	["ProductVariantStoreCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ProductVariantStore"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ProductVariantStoreCreateRootEntityRelationInput"]: {
	connect?:ValueTypes["ProductVariantUniqueWhere"],
	create?:ValueTypes["ProductVariantWithoutStoresCreateInput"]
};
	["ProductVariantStoreCreateStoreEntityRelationInput"]: {
	connect?:ValueTypes["StoreUniqueWhere"],
	create?:ValueTypes["StoreWithoutProductVariantsCreateInput"]
};
	["ProductVariantStoreDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ProductVariantStore"],
		__typename?: true
}>;
	["ProductVariantStoreEdge"]: AliasType<{
	node?:ValueTypes["ProductVariantStore"],
		__typename?: true
}>;
	["ProductVariantStoreMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	price?:ValueTypes["FieldMeta"],
	shopifyId?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	store?:ValueTypes["FieldMeta"],
	compareAtPrice?:ValueTypes["FieldMeta"],
	storefrontId?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ProductVariantStoreOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	price?:ValueTypes["OrderDirection"],
	shopifyId?:ValueTypes["OrderDirection"],
	root?:ValueTypes["ProductVariantOrderBy"],
	store?:ValueTypes["StoreOrderBy"],
	compareAtPrice?:ValueTypes["OrderDirection"],
	storefrontId?:ValueTypes["OrderDirection"]
};
	["ProductVariantStoresByShopifyIdUniqueWhere"]: {
	shopifyId?:string
};
	["ProductVariantStoresByStorefrontIdUniqueWhere"]: {
	storefrontId?:string
};
	["ProductVariantStoresByStoreUniqueWhere"]: {
	store?:ValueTypes["StoreUniqueWhere"]
};
	["ProductVariantStoreUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	root?:ValueTypes["ProductVariantUniqueWhere"],
	store?:ValueTypes["StoreUniqueWhere"],
	shopifyId?:string,
	storefrontId?:string
};
	["ProductVariantStoreUpdateInput"]: {
	price?:number,
	shopifyId?:string,
	root?:ValueTypes["ProductVariantStoreUpdateRootEntityRelationInput"],
	store?:ValueTypes["ProductVariantStoreUpdateStoreEntityRelationInput"],
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
};
	["ProductVariantStoreUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ProductVariantStore"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ProductVariantStoreUpdateRootEntityRelationInput"]: {
	create?:ValueTypes["ProductVariantWithoutStoresCreateInput"],
	update?:ValueTypes["ProductVariantWithoutStoresUpdateInput"],
	upsert?:ValueTypes["ProductVariantStoreUpsertRootRelationInput"],
	connect?:ValueTypes["ProductVariantUniqueWhere"]
};
	["ProductVariantStoreUpdateStoreEntityRelationInput"]: {
	create?:ValueTypes["StoreWithoutProductVariantsCreateInput"],
	update?:ValueTypes["StoreWithoutProductVariantsUpdateInput"],
	upsert?:ValueTypes["ProductVariantStoreUpsertStoreRelationInput"],
	connect?:ValueTypes["StoreUniqueWhere"]
};
	["ProductVariantStoreUpsertRootRelationInput"]: {
	update?:ValueTypes["ProductVariantWithoutStoresUpdateInput"],
	create?:ValueTypes["ProductVariantWithoutStoresCreateInput"]
};
	["ProductVariantStoreUpsertStoreRelationInput"]: {
	update?:ValueTypes["StoreWithoutProductVariantsUpdateInput"],
	create?:ValueTypes["StoreWithoutProductVariantsCreateInput"]
};
	["ProductVariantStoreWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	price?:ValueTypes["FloatCondition"],
	shopifyId?:ValueTypes["StringCondition"],
	root?:ValueTypes["ProductVariantWhere"],
	store?:ValueTypes["StoreWhere"],
	compareAtPrice?:ValueTypes["FloatCondition"],
	storefrontId?:ValueTypes["StringCondition"],
	and?:ValueTypes["ProductVariantStoreWhere"][],
	or?:ValueTypes["ProductVariantStoreWhere"][],
	not?:ValueTypes["ProductVariantStoreWhere"]
};
	["ProductVariantStoreWithoutRootCreateInput"]: {
	price?:number,
	shopifyId?:string,
	store?:ValueTypes["ProductVariantStoreCreateStoreEntityRelationInput"],
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
};
	["ProductVariantStoreWithoutRootUpdateInput"]: {
	price?:number,
	shopifyId?:string,
	store?:ValueTypes["ProductVariantStoreUpdateStoreEntityRelationInput"],
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
};
	["ProductVariantStoreWithoutStoreCreateInput"]: {
	price?:number,
	shopifyId?:string,
	root?:ValueTypes["ProductVariantStoreCreateRootEntityRelationInput"],
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
};
	["ProductVariantStoreWithoutStoreUpdateInput"]: {
	price?:number,
	shopifyId?:string,
	root?:ValueTypes["ProductVariantStoreUpdateRootEntityRelationInput"],
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
};
	["ProductVariantUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	shopifySku?:string,
	locales?:ValueTypes["ProductVariantLocaleUniqueWhere"],
	stores?:ValueTypes["ProductVariantStoreUniqueWhere"]
};
	["ProductVariantUpdateInput"]: {
	shopifySku?:string,
	product?:ValueTypes["ProductVariantUpdateProductEntityRelationInput"],
	locales?:ValueTypes["ProductVariantUpdateLocalesEntityRelationInput"][],
	stores?:ValueTypes["ProductVariantUpdateStoresEntityRelationInput"][],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
};
	["ProductVariantUpdateLocalesEntityRelationInput"]: {
	create?:ValueTypes["ProductVariantLocaleWithoutRootCreateInput"],
	update?:ValueTypes["ProductVariantUpdateLocalesRelationInput"],
	upsert?:ValueTypes["ProductVariantUpsertLocalesRelationInput"],
	connect?:ValueTypes["ProductVariantLocaleUniqueWhere"],
	disconnect?:ValueTypes["ProductVariantLocaleUniqueWhere"],
	delete?:ValueTypes["ProductVariantLocaleUniqueWhere"],
	alias?:string
};
	["ProductVariantUpdateLocalesRelationInput"]: {
	by?:ValueTypes["ProductVariantLocaleUniqueWhere"],
	data?:ValueTypes["ProductVariantLocaleWithoutRootUpdateInput"]
};
	["ProductVariantUpdateProductEntityRelationInput"]: {
	create?:ValueTypes["ProductWithoutProductVariantsCreateInput"],
	update?:ValueTypes["ProductWithoutProductVariantsUpdateInput"],
	upsert?:ValueTypes["ProductVariantUpsertProductRelationInput"],
	connect?:ValueTypes["ProductUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["ProductVariantUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ProductVariant"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ProductVariantUpdateStoresEntityRelationInput"]: {
	create?:ValueTypes["ProductVariantStoreWithoutRootCreateInput"],
	update?:ValueTypes["ProductVariantUpdateStoresRelationInput"],
	upsert?:ValueTypes["ProductVariantUpsertStoresRelationInput"],
	connect?:ValueTypes["ProductVariantStoreUniqueWhere"],
	disconnect?:ValueTypes["ProductVariantStoreUniqueWhere"],
	delete?:ValueTypes["ProductVariantStoreUniqueWhere"],
	alias?:string
};
	["ProductVariantUpdateStoresRelationInput"]: {
	by?:ValueTypes["ProductVariantStoreUniqueWhere"],
	data?:ValueTypes["ProductVariantStoreWithoutRootUpdateInput"]
};
	["ProductVariantUpsertLocalesRelationInput"]: {
	by?:ValueTypes["ProductVariantLocaleUniqueWhere"],
	update?:ValueTypes["ProductVariantLocaleWithoutRootUpdateInput"],
	create?:ValueTypes["ProductVariantLocaleWithoutRootCreateInput"]
};
	["ProductVariantUpsertProductRelationInput"]: {
	update?:ValueTypes["ProductWithoutProductVariantsUpdateInput"],
	create?:ValueTypes["ProductWithoutProductVariantsCreateInput"]
};
	["ProductVariantUpsertStoresRelationInput"]: {
	by?:ValueTypes["ProductVariantStoreUniqueWhere"],
	update?:ValueTypes["ProductVariantStoreWithoutRootUpdateInput"],
	create?:ValueTypes["ProductVariantStoreWithoutRootCreateInput"]
};
	["ProductVariantWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	shopifySku?:ValueTypes["StringCondition"],
	product?:ValueTypes["ProductWhere"],
	locales?:ValueTypes["ProductVariantLocaleWhere"],
	stores?:ValueTypes["ProductVariantStoreWhere"],
	title?:ValueTypes["StringCondition"],
	heurekaId?:ValueTypes["StringCondition"],
	and?:ValueTypes["ProductVariantWhere"][],
	or?:ValueTypes["ProductVariantWhere"][],
	not?:ValueTypes["ProductVariantWhere"]
};
	["ProductVariantWithoutLocalesCreateInput"]: {
	shopifySku?:string,
	product?:ValueTypes["ProductVariantCreateProductEntityRelationInput"],
	stores?:ValueTypes["ProductVariantCreateStoresEntityRelationInput"][],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
};
	["ProductVariantWithoutLocalesUpdateInput"]: {
	shopifySku?:string,
	product?:ValueTypes["ProductVariantUpdateProductEntityRelationInput"],
	stores?:ValueTypes["ProductVariantUpdateStoresEntityRelationInput"][],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
};
	["ProductVariantWithoutProductCreateInput"]: {
	shopifySku?:string,
	locales?:ValueTypes["ProductVariantCreateLocalesEntityRelationInput"][],
	stores?:ValueTypes["ProductVariantCreateStoresEntityRelationInput"][],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
};
	["ProductVariantWithoutProductUpdateInput"]: {
	shopifySku?:string,
	locales?:ValueTypes["ProductVariantUpdateLocalesEntityRelationInput"][],
	stores?:ValueTypes["ProductVariantUpdateStoresEntityRelationInput"][],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
};
	["ProductVariantWithoutStoresCreateInput"]: {
	shopifySku?:string,
	product?:ValueTypes["ProductVariantCreateProductEntityRelationInput"],
	locales?:ValueTypes["ProductVariantCreateLocalesEntityRelationInput"][],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
};
	["ProductVariantWithoutStoresUpdateInput"]: {
	shopifySku?:string,
	product?:ValueTypes["ProductVariantUpdateProductEntityRelationInput"],
	locales?:ValueTypes["ProductVariantUpdateLocalesEntityRelationInput"][],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
};
	["ProductWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	title?:ValueTypes["StringCondition"],
	shopifyHandle?:ValueTypes["StringCondition"],
	productType?:ValueTypes["StringCondition"],
	stores?:ValueTypes["ProductStoreWhere"],
	productVariants?:ValueTypes["ProductVariantWhere"],
	locales?:ValueTypes["ProductLocaleWhere"],
	and?:ValueTypes["ProductWhere"][],
	or?:ValueTypes["ProductWhere"][],
	not?:ValueTypes["ProductWhere"]
};
	["ProductWithoutLocalesCreateInput"]: {
	title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:ValueTypes["ProductCreateStoresEntityRelationInput"][],
	productVariants?:ValueTypes["ProductCreateProductVariantsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["ProductWithoutLocalesUpdateInput"]: {
	title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:ValueTypes["ProductUpdateStoresEntityRelationInput"][],
	productVariants?:ValueTypes["ProductUpdateProductVariantsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["ProductWithoutProductVariantsCreateInput"]: {
	title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:ValueTypes["ProductCreateStoresEntityRelationInput"][],
	locales?:ValueTypes["ProductCreateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["ProductWithoutProductVariantsUpdateInput"]: {
	title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:ValueTypes["ProductUpdateStoresEntityRelationInput"][],
	locales?:ValueTypes["ProductUpdateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["ProductWithoutStoresCreateInput"]: {
	title?:string,
	shopifyHandle?:string,
	productType?:string,
	productVariants?:ValueTypes["ProductCreateProductVariantsEntityRelationInput"][],
	locales?:ValueTypes["ProductCreateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["ProductWithoutStoresUpdateInput"]: {
	title?:string,
	shopifyHandle?:string,
	productType?:string,
	productVariants?:ValueTypes["ProductUpdateProductVariantsEntityRelationInput"][],
	locales?:ValueTypes["ProductUpdateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["Query"]: AliasType<{
getAbandonedCheckoutUnsubscription?: [{	by:ValueTypes["AbandonedCheckoutUnsubscriptionUniqueWhere"]},ValueTypes["AbandonedCheckoutUnsubscription"]],
listAbandonedCheckoutUnsubscription?: [{	filter?:ValueTypes["AbandonedCheckoutUnsubscriptionWhere"],	orderBy?:ValueTypes["AbandonedCheckoutUnsubscriptionOrderBy"][],	offset?:number,	limit?:number},ValueTypes["AbandonedCheckoutUnsubscription"]],
paginateAbandonedCheckoutUnsubscription?: [{	filter?:ValueTypes["AbandonedCheckoutUnsubscriptionWhere"],	orderBy?:ValueTypes["AbandonedCheckoutUnsubscriptionOrderBy"][],	skip?:number,	first?:number},ValueTypes["AbandonedCheckoutUnsubscriptionConnection"]],
validateCreateAbandonedCheckoutUnsubscription?: [{	data:ValueTypes["AbandonedCheckoutUnsubscriptionCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateAbandonedCheckoutUnsubscription?: [{	by:ValueTypes["AbandonedCheckoutUnsubscriptionUniqueWhere"],	data:ValueTypes["AbandonedCheckoutUnsubscriptionUpdateInput"]},ValueTypes["_ValidationResult"]],
getAbandonedCheckoutNotification?: [{	by:ValueTypes["AbandonedCheckoutNotificationUniqueWhere"]},ValueTypes["AbandonedCheckoutNotification"]],
listAbandonedCheckoutNotification?: [{	filter?:ValueTypes["AbandonedCheckoutNotificationWhere"],	orderBy?:ValueTypes["AbandonedCheckoutNotificationOrderBy"][],	offset?:number,	limit?:number},ValueTypes["AbandonedCheckoutNotification"]],
paginateAbandonedCheckoutNotification?: [{	filter?:ValueTypes["AbandonedCheckoutNotificationWhere"],	orderBy?:ValueTypes["AbandonedCheckoutNotificationOrderBy"][],	skip?:number,	first?:number},ValueTypes["AbandonedCheckoutNotificationConnection"]],
validateCreateAbandonedCheckoutNotification?: [{	data:ValueTypes["AbandonedCheckoutNotificationCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateAbandonedCheckoutNotification?: [{	by:ValueTypes["AbandonedCheckoutNotificationUniqueWhere"],	data:ValueTypes["AbandonedCheckoutNotificationUpdateInput"]},ValueTypes["_ValidationResult"]],
getShopifyLineItem?: [{	by:ValueTypes["ShopifyLineItemUniqueWhere"]},ValueTypes["ShopifyLineItem"]],
listShopifyLineItem?: [{	filter?:ValueTypes["ShopifyLineItemWhere"],	orderBy?:ValueTypes["ShopifyLineItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ShopifyLineItem"]],
paginateShopifyLineItem?: [{	filter?:ValueTypes["ShopifyLineItemWhere"],	orderBy?:ValueTypes["ShopifyLineItemOrderBy"][],	skip?:number,	first?:number},ValueTypes["ShopifyLineItemConnection"]],
validateCreateShopifyLineItem?: [{	data:ValueTypes["ShopifyLineItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateShopifyLineItem?: [{	by:ValueTypes["ShopifyLineItemUniqueWhere"],	data:ValueTypes["ShopifyLineItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getShopifyOrder?: [{	by:ValueTypes["ShopifyOrderUniqueWhere"]},ValueTypes["ShopifyOrder"]],
listShopifyOrder?: [{	filter?:ValueTypes["ShopifyOrderWhere"],	orderBy?:ValueTypes["ShopifyOrderOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ShopifyOrder"]],
paginateShopifyOrder?: [{	filter?:ValueTypes["ShopifyOrderWhere"],	orderBy?:ValueTypes["ShopifyOrderOrderBy"][],	skip?:number,	first?:number},ValueTypes["ShopifyOrderConnection"]],
validateCreateShopifyOrder?: [{	data:ValueTypes["ShopifyOrderCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateShopifyOrder?: [{	by:ValueTypes["ShopifyOrderUniqueWhere"],	data:ValueTypes["ShopifyOrderUpdateInput"]},ValueTypes["_ValidationResult"]],
getTranslationValue?: [{	by:ValueTypes["TranslationValueUniqueWhere"]},ValueTypes["TranslationValue"]],
listTranslationValue?: [{	filter?:ValueTypes["TranslationValueWhere"],	orderBy?:ValueTypes["TranslationValueOrderBy"][],	offset?:number,	limit?:number},ValueTypes["TranslationValue"]],
paginateTranslationValue?: [{	filter?:ValueTypes["TranslationValueWhere"],	orderBy?:ValueTypes["TranslationValueOrderBy"][],	skip?:number,	first?:number},ValueTypes["TranslationValueConnection"]],
validateCreateTranslationValue?: [{	data:ValueTypes["TranslationValueCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateTranslationValue?: [{	by:ValueTypes["TranslationValueUniqueWhere"],	data:ValueTypes["TranslationValueUpdateInput"]},ValueTypes["_ValidationResult"]],
getTranslationKey?: [{	by:ValueTypes["TranslationKeyUniqueWhere"]},ValueTypes["TranslationKey"]],
listTranslationKey?: [{	filter?:ValueTypes["TranslationKeyWhere"],	orderBy?:ValueTypes["TranslationKeyOrderBy"][],	offset?:number,	limit?:number},ValueTypes["TranslationKey"]],
paginateTranslationKey?: [{	filter?:ValueTypes["TranslationKeyWhere"],	orderBy?:ValueTypes["TranslationKeyOrderBy"][],	skip?:number,	first?:number},ValueTypes["TranslationKeyConnection"]],
validateCreateTranslationKey?: [{	data:ValueTypes["TranslationKeyCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateTranslationKey?: [{	by:ValueTypes["TranslationKeyUniqueWhere"],	data:ValueTypes["TranslationKeyUpdateInput"]},ValueTypes["_ValidationResult"]],
getTranslationCatalogue?: [{	by:ValueTypes["TranslationCatalogueUniqueWhere"]},ValueTypes["TranslationCatalogue"]],
listTranslationCatalogue?: [{	filter?:ValueTypes["TranslationCatalogueWhere"],	orderBy?:ValueTypes["TranslationCatalogueOrderBy"][],	offset?:number,	limit?:number},ValueTypes["TranslationCatalogue"]],
paginateTranslationCatalogue?: [{	filter?:ValueTypes["TranslationCatalogueWhere"],	orderBy?:ValueTypes["TranslationCatalogueOrderBy"][],	skip?:number,	first?:number},ValueTypes["TranslationCatalogueConnection"]],
validateCreateTranslationCatalogue?: [{	data:ValueTypes["TranslationCatalogueCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateTranslationCatalogue?: [{	by:ValueTypes["TranslationCatalogueUniqueWhere"],	data:ValueTypes["TranslationCatalogueUpdateInput"]},ValueTypes["_ValidationResult"]],
getTranslationDomain?: [{	by:ValueTypes["TranslationDomainUniqueWhere"]},ValueTypes["TranslationDomain"]],
listTranslationDomain?: [{	filter?:ValueTypes["TranslationDomainWhere"],	orderBy?:ValueTypes["TranslationDomainOrderBy"][],	offset?:number,	limit?:number},ValueTypes["TranslationDomain"]],
paginateTranslationDomain?: [{	filter?:ValueTypes["TranslationDomainWhere"],	orderBy?:ValueTypes["TranslationDomainOrderBy"][],	skip?:number,	first?:number},ValueTypes["TranslationDomainConnection"]],
validateCreateTranslationDomain?: [{	data:ValueTypes["TranslationDomainCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateTranslationDomain?: [{	by:ValueTypes["TranslationDomainUniqueWhere"],	data:ValueTypes["TranslationDomainUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductLocale?: [{	by:ValueTypes["ProductLocaleUniqueWhere"]},ValueTypes["ProductLocale"]],
listProductLocale?: [{	filter?:ValueTypes["ProductLocaleWhere"],	orderBy?:ValueTypes["ProductLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductLocale"]],
paginateProductLocale?: [{	filter?:ValueTypes["ProductLocaleWhere"],	orderBy?:ValueTypes["ProductLocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["ProductLocaleConnection"]],
validateCreateProductLocale?: [{	data:ValueTypes["ProductLocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateProductLocale?: [{	by:ValueTypes["ProductLocaleUniqueWhere"],	data:ValueTypes["ProductLocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getMedia?: [{	by:ValueTypes["MediaUniqueWhere"]},ValueTypes["Media"]],
listMedia?: [{	filter?:ValueTypes["MediaWhere"],	orderBy?:ValueTypes["MediaOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Media"]],
paginateMedia?: [{	filter?:ValueTypes["MediaWhere"],	orderBy?:ValueTypes["MediaOrderBy"][],	skip?:number,	first?:number},ValueTypes["MediaConnection"]],
validateCreateMedia?: [{	data:ValueTypes["MediaCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateMedia?: [{	by:ValueTypes["MediaUniqueWhere"],	data:ValueTypes["MediaUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconListBlockItem?: [{	by:ValueTypes["IconListBlockItemUniqueWhere"]},ValueTypes["IconListBlockItem"]],
listIconListBlockItem?: [{	filter?:ValueTypes["IconListBlockItemWhere"],	orderBy?:ValueTypes["IconListBlockItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconListBlockItem"]],
paginateIconListBlockItem?: [{	filter?:ValueTypes["IconListBlockItemWhere"],	orderBy?:ValueTypes["IconListBlockItemOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconListBlockItemConnection"]],
validateCreateIconListBlockItem?: [{	data:ValueTypes["IconListBlockItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconListBlockItem?: [{	by:ValueTypes["IconListBlockItemUniqueWhere"],	data:ValueTypes["IconListBlockItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconListBlock?: [{	by:ValueTypes["IconListBlockUniqueWhere"]},ValueTypes["IconListBlock"]],
listIconListBlock?: [{	filter?:ValueTypes["IconListBlockWhere"],	orderBy?:ValueTypes["IconListBlockOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconListBlock"]],
paginateIconListBlock?: [{	filter?:ValueTypes["IconListBlockWhere"],	orderBy?:ValueTypes["IconListBlockOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconListBlockConnection"]],
validateCreateIconListBlock?: [{	data:ValueTypes["IconListBlockCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconListBlock?: [{	by:ValueTypes["IconListBlockUniqueWhere"],	data:ValueTypes["IconListBlockUpdateInput"]},ValueTypes["_ValidationResult"]],
getFeatureListItem?: [{	by:ValueTypes["FeatureListItemUniqueWhere"]},ValueTypes["FeatureListItem"]],
listFeatureListItem?: [{	filter?:ValueTypes["FeatureListItemWhere"],	orderBy?:ValueTypes["FeatureListItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["FeatureListItem"]],
paginateFeatureListItem?: [{	filter?:ValueTypes["FeatureListItemWhere"],	orderBy?:ValueTypes["FeatureListItemOrderBy"][],	skip?:number,	first?:number},ValueTypes["FeatureListItemConnection"]],
validateCreateFeatureListItem?: [{	data:ValueTypes["FeatureListItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateFeatureListItem?: [{	by:ValueTypes["FeatureListItemUniqueWhere"],	data:ValueTypes["FeatureListItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getFeatureList?: [{	by:ValueTypes["FeatureListUniqueWhere"]},ValueTypes["FeatureList"]],
listFeatureList?: [{	filter?:ValueTypes["FeatureListWhere"],	orderBy?:ValueTypes["FeatureListOrderBy"][],	offset?:number,	limit?:number},ValueTypes["FeatureList"]],
paginateFeatureList?: [{	filter?:ValueTypes["FeatureListWhere"],	orderBy?:ValueTypes["FeatureListOrderBy"][],	skip?:number,	first?:number},ValueTypes["FeatureListConnection"]],
validateCreateFeatureList?: [{	data:ValueTypes["FeatureListCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateFeatureList?: [{	by:ValueTypes["FeatureListUniqueWhere"],	data:ValueTypes["FeatureListUpdateInput"]},ValueTypes["_ValidationResult"]],
getFaqItem?: [{	by:ValueTypes["FaqItemUniqueWhere"]},ValueTypes["FaqItem"]],
listFaqItem?: [{	filter?:ValueTypes["FaqItemWhere"],	orderBy?:ValueTypes["FaqItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["FaqItem"]],
paginateFaqItem?: [{	filter?:ValueTypes["FaqItemWhere"],	orderBy?:ValueTypes["FaqItemOrderBy"][],	skip?:number,	first?:number},ValueTypes["FaqItemConnection"]],
validateCreateFaqItem?: [{	data:ValueTypes["FaqItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateFaqItem?: [{	by:ValueTypes["FaqItemUniqueWhere"],	data:ValueTypes["FaqItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getFaq?: [{	by:ValueTypes["FaqUniqueWhere"]},ValueTypes["Faq"]],
listFaq?: [{	filter?:ValueTypes["FaqWhere"],	orderBy?:ValueTypes["FaqOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Faq"]],
paginateFaq?: [{	filter?:ValueTypes["FaqWhere"],	orderBy?:ValueTypes["FaqOrderBy"][],	skip?:number,	first?:number},ValueTypes["FaqConnection"]],
validateCreateFaq?: [{	data:ValueTypes["FaqCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateFaq?: [{	by:ValueTypes["FaqUniqueWhere"],	data:ValueTypes["FaqUpdateInput"]},ValueTypes["_ValidationResult"]],
getContentBlock?: [{	by:ValueTypes["ContentBlockUniqueWhere"]},ValueTypes["ContentBlock"]],
listContentBlock?: [{	filter?:ValueTypes["ContentBlockWhere"],	orderBy?:ValueTypes["ContentBlockOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?:ValueTypes["ContentBlockWhere"],	orderBy?:ValueTypes["ContentBlockOrderBy"][],	skip?:number,	first?:number},ValueTypes["ContentBlockConnection"]],
validateCreateContentBlock?: [{	data:ValueTypes["ContentBlockCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateContentBlock?: [{	by:ValueTypes["ContentBlockUniqueWhere"],	data:ValueTypes["ContentBlockUpdateInput"]},ValueTypes["_ValidationResult"]],
getContent?: [{	by:ValueTypes["ContentUniqueWhere"]},ValueTypes["Content"]],
listContent?: [{	filter?:ValueTypes["ContentWhere"],	orderBy?:ValueTypes["ContentOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Content"]],
paginateContent?: [{	filter?:ValueTypes["ContentWhere"],	orderBy?:ValueTypes["ContentOrderBy"][],	skip?:number,	first?:number},ValueTypes["ContentConnection"]],
validateCreateContent?: [{	data:ValueTypes["ContentCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateContent?: [{	by:ValueTypes["ContentUniqueWhere"],	data:ValueTypes["ContentUpdateInput"]},ValueTypes["_ValidationResult"]],
getLink?: [{	by:ValueTypes["LinkUniqueWhere"]},ValueTypes["Link"]],
listLink?: [{	filter?:ValueTypes["LinkWhere"],	orderBy?:ValueTypes["LinkOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Link"]],
paginateLink?: [{	filter?:ValueTypes["LinkWhere"],	orderBy?:ValueTypes["LinkOrderBy"][],	skip?:number,	first?:number},ValueTypes["LinkConnection"]],
validateCreateLink?: [{	data:ValueTypes["LinkCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateLink?: [{	by:ValueTypes["LinkUniqueWhere"],	data:ValueTypes["LinkUpdateInput"]},ValueTypes["_ValidationResult"]],
getReviewLocale?: [{	by:ValueTypes["ReviewLocaleUniqueWhere"]},ValueTypes["ReviewLocale"]],
listReviewLocale?: [{	filter?:ValueTypes["ReviewLocaleWhere"],	orderBy?:ValueTypes["ReviewLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ReviewLocale"]],
paginateReviewLocale?: [{	filter?:ValueTypes["ReviewLocaleWhere"],	orderBy?:ValueTypes["ReviewLocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["ReviewLocaleConnection"]],
validateCreateReviewLocale?: [{	data:ValueTypes["ReviewLocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateReviewLocale?: [{	by:ValueTypes["ReviewLocaleUniqueWhere"],	data:ValueTypes["ReviewLocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getReview?: [{	by:ValueTypes["ReviewUniqueWhere"]},ValueTypes["Review"]],
listReview?: [{	filter?:ValueTypes["ReviewWhere"],	orderBy?:ValueTypes["ReviewOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Review"]],
paginateReview?: [{	filter?:ValueTypes["ReviewWhere"],	orderBy?:ValueTypes["ReviewOrderBy"][],	skip?:number,	first?:number},ValueTypes["ReviewConnection"]],
validateCreateReview?: [{	data:ValueTypes["ReviewCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateReview?: [{	by:ValueTypes["ReviewUniqueWhere"],	data:ValueTypes["ReviewUpdateInput"]},ValueTypes["_ValidationResult"]],
getGalleryItem?: [{	by:ValueTypes["GalleryItemUniqueWhere"]},ValueTypes["GalleryItem"]],
listGalleryItem?: [{	filter?:ValueTypes["GalleryItemWhere"],	orderBy?:ValueTypes["GalleryItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["GalleryItem"]],
paginateGalleryItem?: [{	filter?:ValueTypes["GalleryItemWhere"],	orderBy?:ValueTypes["GalleryItemOrderBy"][],	skip?:number,	first?:number},ValueTypes["GalleryItemConnection"]],
validateCreateGalleryItem?: [{	data:ValueTypes["GalleryItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateGalleryItem?: [{	by:ValueTypes["GalleryItemUniqueWhere"],	data:ValueTypes["GalleryItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getGallery?: [{	by:ValueTypes["GalleryUniqueWhere"]},ValueTypes["Gallery"]],
listGallery?: [{	filter?:ValueTypes["GalleryWhere"],	orderBy?:ValueTypes["GalleryOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Gallery"]],
paginateGallery?: [{	filter?:ValueTypes["GalleryWhere"],	orderBy?:ValueTypes["GalleryOrderBy"][],	skip?:number,	first?:number},ValueTypes["GalleryConnection"]],
validateCreateGallery?: [{	data:ValueTypes["GalleryCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateGallery?: [{	by:ValueTypes["GalleryUniqueWhere"],	data:ValueTypes["GalleryUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductVariantStore?: [{	by:ValueTypes["ProductVariantStoreUniqueWhere"]},ValueTypes["ProductVariantStore"]],
listProductVariantStore?: [{	filter?:ValueTypes["ProductVariantStoreWhere"],	orderBy?:ValueTypes["ProductVariantStoreOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductVariantStore"]],
paginateProductVariantStore?: [{	filter?:ValueTypes["ProductVariantStoreWhere"],	orderBy?:ValueTypes["ProductVariantStoreOrderBy"][],	skip?:number,	first?:number},ValueTypes["ProductVariantStoreConnection"]],
validateCreateProductVariantStore?: [{	data:ValueTypes["ProductVariantStoreCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateProductVariantStore?: [{	by:ValueTypes["ProductVariantStoreUniqueWhere"],	data:ValueTypes["ProductVariantStoreUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductStore?: [{	by:ValueTypes["ProductStoreUniqueWhere"]},ValueTypes["ProductStore"]],
listProductStore?: [{	filter?:ValueTypes["ProductStoreWhere"],	orderBy?:ValueTypes["ProductStoreOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductStore"]],
paginateProductStore?: [{	filter?:ValueTypes["ProductStoreWhere"],	orderBy?:ValueTypes["ProductStoreOrderBy"][],	skip?:number,	first?:number},ValueTypes["ProductStoreConnection"]],
validateCreateProductStore?: [{	data:ValueTypes["ProductStoreCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateProductStore?: [{	by:ValueTypes["ProductStoreUniqueWhere"],	data:ValueTypes["ProductStoreUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconListItem?: [{	by:ValueTypes["IconListItemUniqueWhere"]},ValueTypes["IconListItem"]],
listIconListItem?: [{	filter?:ValueTypes["IconListItemWhere"],	orderBy?:ValueTypes["IconListItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconListItem"]],
paginateIconListItem?: [{	filter?:ValueTypes["IconListItemWhere"],	orderBy?:ValueTypes["IconListItemOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconListItemConnection"]],
validateCreateIconListItem?: [{	data:ValueTypes["IconListItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconListItem?: [{	by:ValueTypes["IconListItemUniqueWhere"],	data:ValueTypes["IconListItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconList?: [{	by:ValueTypes["IconListUniqueWhere"]},ValueTypes["IconList"]],
listIconList?: [{	filter?:ValueTypes["IconListWhere"],	orderBy?:ValueTypes["IconListOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconList"]],
paginateIconList?: [{	filter?:ValueTypes["IconListWhere"],	orderBy?:ValueTypes["IconListOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconListConnection"]],
validateCreateIconList?: [{	data:ValueTypes["IconListCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconList?: [{	by:ValueTypes["IconListUniqueWhere"],	data:ValueTypes["IconListUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconGroupListItem?: [{	by:ValueTypes["IconGroupListItemUniqueWhere"]},ValueTypes["IconGroupListItem"]],
listIconGroupListItem?: [{	filter?:ValueTypes["IconGroupListItemWhere"],	orderBy?:ValueTypes["IconGroupListItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconGroupListItem"]],
paginateIconGroupListItem?: [{	filter?:ValueTypes["IconGroupListItemWhere"],	orderBy?:ValueTypes["IconGroupListItemOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconGroupListItemConnection"]],
validateCreateIconGroupListItem?: [{	data:ValueTypes["IconGroupListItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconGroupListItem?: [{	by:ValueTypes["IconGroupListItemUniqueWhere"],	data:ValueTypes["IconGroupListItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconGroupList?: [{	by:ValueTypes["IconGroupListUniqueWhere"]},ValueTypes["IconGroupList"]],
listIconGroupList?: [{	filter?:ValueTypes["IconGroupListWhere"],	orderBy?:ValueTypes["IconGroupListOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconGroupList"]],
paginateIconGroupList?: [{	filter?:ValueTypes["IconGroupListWhere"],	orderBy?:ValueTypes["IconGroupListOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconGroupListConnection"]],
validateCreateIconGroupList?: [{	data:ValueTypes["IconGroupListCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconGroupList?: [{	by:ValueTypes["IconGroupListUniqueWhere"],	data:ValueTypes["IconGroupListUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductVariantLocale?: [{	by:ValueTypes["ProductVariantLocaleUniqueWhere"]},ValueTypes["ProductVariantLocale"]],
listProductVariantLocale?: [{	filter?:ValueTypes["ProductVariantLocaleWhere"],	orderBy?:ValueTypes["ProductVariantLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductVariantLocale"]],
paginateProductVariantLocale?: [{	filter?:ValueTypes["ProductVariantLocaleWhere"],	orderBy?:ValueTypes["ProductVariantLocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["ProductVariantLocaleConnection"]],
validateCreateProductVariantLocale?: [{	data:ValueTypes["ProductVariantLocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateProductVariantLocale?: [{	by:ValueTypes["ProductVariantLocaleUniqueWhere"],	data:ValueTypes["ProductVariantLocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductVariant?: [{	by:ValueTypes["ProductVariantUniqueWhere"]},ValueTypes["ProductVariant"]],
listProductVariant?: [{	filter?:ValueTypes["ProductVariantWhere"],	orderBy?:ValueTypes["ProductVariantOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductVariant"]],
paginateProductVariant?: [{	filter?:ValueTypes["ProductVariantWhere"],	orderBy?:ValueTypes["ProductVariantOrderBy"][],	skip?:number,	first?:number},ValueTypes["ProductVariantConnection"]],
validateCreateProductVariant?: [{	data:ValueTypes["ProductVariantCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateProductVariant?: [{	by:ValueTypes["ProductVariantUniqueWhere"],	data:ValueTypes["ProductVariantUpdateInput"]},ValueTypes["_ValidationResult"]],
getProduct?: [{	by:ValueTypes["ProductUniqueWhere"]},ValueTypes["Product"]],
listProduct?: [{	filter?:ValueTypes["ProductWhere"],	orderBy?:ValueTypes["ProductOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Product"]],
paginateProduct?: [{	filter?:ValueTypes["ProductWhere"],	orderBy?:ValueTypes["ProductOrderBy"][],	skip?:number,	first?:number},ValueTypes["ProductConnection"]],
validateCreateProduct?: [{	data:ValueTypes["ProductCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateProduct?: [{	by:ValueTypes["ProductUniqueWhere"],	data:ValueTypes["ProductUpdateInput"]},ValueTypes["_ValidationResult"]],
getImageLocale?: [{	by:ValueTypes["ImageLocaleUniqueWhere"]},ValueTypes["ImageLocale"]],
listImageLocale?: [{	filter?:ValueTypes["ImageLocaleWhere"],	orderBy?:ValueTypes["ImageLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ImageLocale"]],
paginateImageLocale?: [{	filter?:ValueTypes["ImageLocaleWhere"],	orderBy?:ValueTypes["ImageLocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["ImageLocaleConnection"]],
validateCreateImageLocale?: [{	data:ValueTypes["ImageLocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateImageLocale?: [{	by:ValueTypes["ImageLocaleUniqueWhere"],	data:ValueTypes["ImageLocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getStore?: [{	by:ValueTypes["StoreUniqueWhere"]},ValueTypes["Store"]],
listStore?: [{	filter?:ValueTypes["StoreWhere"],	orderBy?:ValueTypes["StoreOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Store"]],
paginateStore?: [{	filter?:ValueTypes["StoreWhere"],	orderBy?:ValueTypes["StoreOrderBy"][],	skip?:number,	first?:number},ValueTypes["StoreConnection"]],
validateCreateStore?: [{	data:ValueTypes["StoreCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateStore?: [{	by:ValueTypes["StoreUniqueWhere"],	data:ValueTypes["StoreUpdateInput"]},ValueTypes["_ValidationResult"]],
getAdminNote?: [{	by:ValueTypes["AdminNoteUniqueWhere"]},ValueTypes["AdminNote"]],
listAdminNote?: [{	filter?:ValueTypes["AdminNoteWhere"],	orderBy?:ValueTypes["AdminNoteOrderBy"][],	offset?:number,	limit?:number},ValueTypes["AdminNote"]],
paginateAdminNote?: [{	filter?:ValueTypes["AdminNoteWhere"],	orderBy?:ValueTypes["AdminNoteOrderBy"][],	skip?:number,	first?:number},ValueTypes["AdminNoteConnection"]],
validateCreateAdminNote?: [{	data:ValueTypes["AdminNoteCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateAdminNote?: [{	by:ValueTypes["AdminNoteUniqueWhere"],	data:ValueTypes["AdminNoteUpdateInput"]},ValueTypes["_ValidationResult"]],
getPageLocale?: [{	by:ValueTypes["PageLocaleUniqueWhere"]},ValueTypes["PageLocale"]],
listPageLocale?: [{	filter?:ValueTypes["PageLocaleWhere"],	orderBy?:ValueTypes["PageLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["PageLocale"]],
paginatePageLocale?: [{	filter?:ValueTypes["PageLocaleWhere"],	orderBy?:ValueTypes["PageLocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["PageLocaleConnection"]],
validateCreatePageLocale?: [{	data:ValueTypes["PageLocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdatePageLocale?: [{	by:ValueTypes["PageLocaleUniqueWhere"],	data:ValueTypes["PageLocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getPage?: [{	by:ValueTypes["PageUniqueWhere"]},ValueTypes["Page"]],
listPage?: [{	filter?:ValueTypes["PageWhere"],	orderBy?:ValueTypes["PageOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Page"]],
paginatePage?: [{	filter?:ValueTypes["PageWhere"],	orderBy?:ValueTypes["PageOrderBy"][],	skip?:number,	first?:number},ValueTypes["PageConnection"]],
validateCreatePage?: [{	data:ValueTypes["PageCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdatePage?: [{	by:ValueTypes["PageUniqueWhere"],	data:ValueTypes["PageUpdateInput"]},ValueTypes["_ValidationResult"]],
getImageXImageList?: [{	by:ValueTypes["ImageXImageListUniqueWhere"]},ValueTypes["ImageXImageList"]],
listImageXImageList?: [{	filter?:ValueTypes["ImageXImageListWhere"],	orderBy?:ValueTypes["ImageXImageListOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ImageXImageList"]],
paginateImageXImageList?: [{	filter?:ValueTypes["ImageXImageListWhere"],	orderBy?:ValueTypes["ImageXImageListOrderBy"][],	skip?:number,	first?:number},ValueTypes["ImageXImageListConnection"]],
validateCreateImageXImageList?: [{	data:ValueTypes["ImageXImageListCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateImageXImageList?: [{	by:ValueTypes["ImageXImageListUniqueWhere"],	data:ValueTypes["ImageXImageListUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconGroupLocale?: [{	by:ValueTypes["IconGroupLocaleUniqueWhere"]},ValueTypes["IconGroupLocale"]],
listIconGroupLocale?: [{	filter?:ValueTypes["IconGroupLocaleWhere"],	orderBy?:ValueTypes["IconGroupLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconGroupLocale"]],
paginateIconGroupLocale?: [{	filter?:ValueTypes["IconGroupLocaleWhere"],	orderBy?:ValueTypes["IconGroupLocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconGroupLocaleConnection"]],
validateCreateIconGroupLocale?: [{	data:ValueTypes["IconGroupLocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconGroupLocale?: [{	by:ValueTypes["IconGroupLocaleUniqueWhere"],	data:ValueTypes["IconGroupLocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconLocale?: [{	by:ValueTypes["IconLocaleUniqueWhere"]},ValueTypes["IconLocale"]],
listIconLocale?: [{	filter?:ValueTypes["IconLocaleWhere"],	orderBy?:ValueTypes["IconLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconLocale"]],
paginateIconLocale?: [{	filter?:ValueTypes["IconLocaleWhere"],	orderBy?:ValueTypes["IconLocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconLocaleConnection"]],
validateCreateIconLocale?: [{	data:ValueTypes["IconLocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconLocale?: [{	by:ValueTypes["IconLocaleUniqueWhere"],	data:ValueTypes["IconLocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconGroup?: [{	by:ValueTypes["IconGroupUniqueWhere"]},ValueTypes["IconGroup"]],
listIconGroup?: [{	filter?:ValueTypes["IconGroupWhere"],	orderBy?:ValueTypes["IconGroupOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconGroup"]],
paginateIconGroup?: [{	filter?:ValueTypes["IconGroupWhere"],	orderBy?:ValueTypes["IconGroupOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconGroupConnection"]],
validateCreateIconGroup?: [{	data:ValueTypes["IconGroupCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconGroup?: [{	by:ValueTypes["IconGroupUniqueWhere"],	data:ValueTypes["IconGroupUpdateInput"]},ValueTypes["_ValidationResult"]],
getIcon?: [{	by:ValueTypes["IconUniqueWhere"]},ValueTypes["Icon"]],
listIcon?: [{	filter?:ValueTypes["IconWhere"],	orderBy?:ValueTypes["IconOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Icon"]],
paginateIcon?: [{	filter?:ValueTypes["IconWhere"],	orderBy?:ValueTypes["IconOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconConnection"]],
validateCreateIcon?: [{	data:ValueTypes["IconCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIcon?: [{	by:ValueTypes["IconUniqueWhere"],	data:ValueTypes["IconUpdateInput"]},ValueTypes["_ValidationResult"]],
getLocale?: [{	by:ValueTypes["LocaleUniqueWhere"]},ValueTypes["Locale"]],
listLocale?: [{	filter?:ValueTypes["LocaleWhere"],	orderBy?:ValueTypes["LocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Locale"]],
paginateLocale?: [{	filter?:ValueTypes["LocaleWhere"],	orderBy?:ValueTypes["LocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["LocaleConnection"]],
validateCreateLocale?: [{	data:ValueTypes["LocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateLocale?: [{	by:ValueTypes["LocaleUniqueWhere"],	data:ValueTypes["LocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getSeoLocale?: [{	by:ValueTypes["SeoLocaleUniqueWhere"]},ValueTypes["SeoLocale"]],
listSeoLocale?: [{	filter?:ValueTypes["SeoLocaleWhere"],	orderBy?:ValueTypes["SeoLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["SeoLocale"]],
paginateSeoLocale?: [{	filter?:ValueTypes["SeoLocaleWhere"],	orderBy?:ValueTypes["SeoLocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["SeoLocaleConnection"]],
validateCreateSeoLocale?: [{	data:ValueTypes["SeoLocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateSeoLocale?: [{	by:ValueTypes["SeoLocaleUniqueWhere"],	data:ValueTypes["SeoLocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getSeo?: [{	by:ValueTypes["SeoUniqueWhere"]},ValueTypes["Seo"]],
listSeo?: [{	filter?:ValueTypes["SeoWhere"],	orderBy?:ValueTypes["SeoOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Seo"]],
paginateSeo?: [{	filter?:ValueTypes["SeoWhere"],	orderBy?:ValueTypes["SeoOrderBy"][],	skip?:number,	first?:number},ValueTypes["SeoConnection"]],
validateCreateSeo?: [{	data:ValueTypes["SeoCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateSeo?: [{	by:ValueTypes["SeoUniqueWhere"],	data:ValueTypes["SeoUpdateInput"]},ValueTypes["_ValidationResult"]],
getLinkable?: [{	by:ValueTypes["LinkableUniqueWhere"]},ValueTypes["Linkable"]],
listLinkable?: [{	filter?:ValueTypes["LinkableWhere"],	orderBy?:ValueTypes["LinkableOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Linkable"]],
paginateLinkable?: [{	filter?:ValueTypes["LinkableWhere"],	orderBy?:ValueTypes["LinkableOrderBy"][],	skip?:number,	first?:number},ValueTypes["LinkableConnection"]],
validateCreateLinkable?: [{	data:ValueTypes["LinkableCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateLinkable?: [{	by:ValueTypes["LinkableUniqueWhere"],	data:ValueTypes["LinkableUpdateInput"]},ValueTypes["_ValidationResult"]],
getImageList?: [{	by:ValueTypes["ImageListUniqueWhere"]},ValueTypes["ImageList"]],
listImageList?: [{	filter?:ValueTypes["ImageListWhere"],	orderBy?:ValueTypes["ImageListOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ImageList"]],
paginateImageList?: [{	filter?:ValueTypes["ImageListWhere"],	orderBy?:ValueTypes["ImageListOrderBy"][],	skip?:number,	first?:number},ValueTypes["ImageListConnection"]],
validateCreateImageList?: [{	data:ValueTypes["ImageListCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateImageList?: [{	by:ValueTypes["ImageListUniqueWhere"],	data:ValueTypes["ImageListUpdateInput"]},ValueTypes["_ValidationResult"]],
getImage?: [{	by:ValueTypes["ImageUniqueWhere"]},ValueTypes["Image"]],
listImage?: [{	filter?:ValueTypes["ImageWhere"],	orderBy?:ValueTypes["ImageOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Image"]],
paginateImage?: [{	filter?:ValueTypes["ImageWhere"],	orderBy?:ValueTypes["ImageOrderBy"][],	skip?:number,	first?:number},ValueTypes["ImageConnection"]],
validateCreateImage?: [{	data:ValueTypes["ImageCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateImage?: [{	by:ValueTypes["ImageUniqueWhere"],	data:ValueTypes["ImageUpdateInput"]},ValueTypes["_ValidationResult"]],
	transaction?:ValueTypes["QueryTransaction"],
	_info?:ValueTypes["Info"],
	schema?:ValueTypes["_Schema"],
	s3DummyQuery?:true,
		__typename?: true
}>;
	["QueryTransaction"]: AliasType<{
getAbandonedCheckoutUnsubscription?: [{	by:ValueTypes["AbandonedCheckoutUnsubscriptionUniqueWhere"]},ValueTypes["AbandonedCheckoutUnsubscription"]],
listAbandonedCheckoutUnsubscription?: [{	filter?:ValueTypes["AbandonedCheckoutUnsubscriptionWhere"],	orderBy?:ValueTypes["AbandonedCheckoutUnsubscriptionOrderBy"][],	offset?:number,	limit?:number},ValueTypes["AbandonedCheckoutUnsubscription"]],
paginateAbandonedCheckoutUnsubscription?: [{	filter?:ValueTypes["AbandonedCheckoutUnsubscriptionWhere"],	orderBy?:ValueTypes["AbandonedCheckoutUnsubscriptionOrderBy"][],	skip?:number,	first?:number},ValueTypes["AbandonedCheckoutUnsubscriptionConnection"]],
validateCreateAbandonedCheckoutUnsubscription?: [{	data:ValueTypes["AbandonedCheckoutUnsubscriptionCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateAbandonedCheckoutUnsubscription?: [{	by:ValueTypes["AbandonedCheckoutUnsubscriptionUniqueWhere"],	data:ValueTypes["AbandonedCheckoutUnsubscriptionUpdateInput"]},ValueTypes["_ValidationResult"]],
getAbandonedCheckoutNotification?: [{	by:ValueTypes["AbandonedCheckoutNotificationUniqueWhere"]},ValueTypes["AbandonedCheckoutNotification"]],
listAbandonedCheckoutNotification?: [{	filter?:ValueTypes["AbandonedCheckoutNotificationWhere"],	orderBy?:ValueTypes["AbandonedCheckoutNotificationOrderBy"][],	offset?:number,	limit?:number},ValueTypes["AbandonedCheckoutNotification"]],
paginateAbandonedCheckoutNotification?: [{	filter?:ValueTypes["AbandonedCheckoutNotificationWhere"],	orderBy?:ValueTypes["AbandonedCheckoutNotificationOrderBy"][],	skip?:number,	first?:number},ValueTypes["AbandonedCheckoutNotificationConnection"]],
validateCreateAbandonedCheckoutNotification?: [{	data:ValueTypes["AbandonedCheckoutNotificationCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateAbandonedCheckoutNotification?: [{	by:ValueTypes["AbandonedCheckoutNotificationUniqueWhere"],	data:ValueTypes["AbandonedCheckoutNotificationUpdateInput"]},ValueTypes["_ValidationResult"]],
getShopifyLineItem?: [{	by:ValueTypes["ShopifyLineItemUniqueWhere"]},ValueTypes["ShopifyLineItem"]],
listShopifyLineItem?: [{	filter?:ValueTypes["ShopifyLineItemWhere"],	orderBy?:ValueTypes["ShopifyLineItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ShopifyLineItem"]],
paginateShopifyLineItem?: [{	filter?:ValueTypes["ShopifyLineItemWhere"],	orderBy?:ValueTypes["ShopifyLineItemOrderBy"][],	skip?:number,	first?:number},ValueTypes["ShopifyLineItemConnection"]],
validateCreateShopifyLineItem?: [{	data:ValueTypes["ShopifyLineItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateShopifyLineItem?: [{	by:ValueTypes["ShopifyLineItemUniqueWhere"],	data:ValueTypes["ShopifyLineItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getShopifyOrder?: [{	by:ValueTypes["ShopifyOrderUniqueWhere"]},ValueTypes["ShopifyOrder"]],
listShopifyOrder?: [{	filter?:ValueTypes["ShopifyOrderWhere"],	orderBy?:ValueTypes["ShopifyOrderOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ShopifyOrder"]],
paginateShopifyOrder?: [{	filter?:ValueTypes["ShopifyOrderWhere"],	orderBy?:ValueTypes["ShopifyOrderOrderBy"][],	skip?:number,	first?:number},ValueTypes["ShopifyOrderConnection"]],
validateCreateShopifyOrder?: [{	data:ValueTypes["ShopifyOrderCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateShopifyOrder?: [{	by:ValueTypes["ShopifyOrderUniqueWhere"],	data:ValueTypes["ShopifyOrderUpdateInput"]},ValueTypes["_ValidationResult"]],
getTranslationValue?: [{	by:ValueTypes["TranslationValueUniqueWhere"]},ValueTypes["TranslationValue"]],
listTranslationValue?: [{	filter?:ValueTypes["TranslationValueWhere"],	orderBy?:ValueTypes["TranslationValueOrderBy"][],	offset?:number,	limit?:number},ValueTypes["TranslationValue"]],
paginateTranslationValue?: [{	filter?:ValueTypes["TranslationValueWhere"],	orderBy?:ValueTypes["TranslationValueOrderBy"][],	skip?:number,	first?:number},ValueTypes["TranslationValueConnection"]],
validateCreateTranslationValue?: [{	data:ValueTypes["TranslationValueCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateTranslationValue?: [{	by:ValueTypes["TranslationValueUniqueWhere"],	data:ValueTypes["TranslationValueUpdateInput"]},ValueTypes["_ValidationResult"]],
getTranslationKey?: [{	by:ValueTypes["TranslationKeyUniqueWhere"]},ValueTypes["TranslationKey"]],
listTranslationKey?: [{	filter?:ValueTypes["TranslationKeyWhere"],	orderBy?:ValueTypes["TranslationKeyOrderBy"][],	offset?:number,	limit?:number},ValueTypes["TranslationKey"]],
paginateTranslationKey?: [{	filter?:ValueTypes["TranslationKeyWhere"],	orderBy?:ValueTypes["TranslationKeyOrderBy"][],	skip?:number,	first?:number},ValueTypes["TranslationKeyConnection"]],
validateCreateTranslationKey?: [{	data:ValueTypes["TranslationKeyCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateTranslationKey?: [{	by:ValueTypes["TranslationKeyUniqueWhere"],	data:ValueTypes["TranslationKeyUpdateInput"]},ValueTypes["_ValidationResult"]],
getTranslationCatalogue?: [{	by:ValueTypes["TranslationCatalogueUniqueWhere"]},ValueTypes["TranslationCatalogue"]],
listTranslationCatalogue?: [{	filter?:ValueTypes["TranslationCatalogueWhere"],	orderBy?:ValueTypes["TranslationCatalogueOrderBy"][],	offset?:number,	limit?:number},ValueTypes["TranslationCatalogue"]],
paginateTranslationCatalogue?: [{	filter?:ValueTypes["TranslationCatalogueWhere"],	orderBy?:ValueTypes["TranslationCatalogueOrderBy"][],	skip?:number,	first?:number},ValueTypes["TranslationCatalogueConnection"]],
validateCreateTranslationCatalogue?: [{	data:ValueTypes["TranslationCatalogueCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateTranslationCatalogue?: [{	by:ValueTypes["TranslationCatalogueUniqueWhere"],	data:ValueTypes["TranslationCatalogueUpdateInput"]},ValueTypes["_ValidationResult"]],
getTranslationDomain?: [{	by:ValueTypes["TranslationDomainUniqueWhere"]},ValueTypes["TranslationDomain"]],
listTranslationDomain?: [{	filter?:ValueTypes["TranslationDomainWhere"],	orderBy?:ValueTypes["TranslationDomainOrderBy"][],	offset?:number,	limit?:number},ValueTypes["TranslationDomain"]],
paginateTranslationDomain?: [{	filter?:ValueTypes["TranslationDomainWhere"],	orderBy?:ValueTypes["TranslationDomainOrderBy"][],	skip?:number,	first?:number},ValueTypes["TranslationDomainConnection"]],
validateCreateTranslationDomain?: [{	data:ValueTypes["TranslationDomainCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateTranslationDomain?: [{	by:ValueTypes["TranslationDomainUniqueWhere"],	data:ValueTypes["TranslationDomainUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductLocale?: [{	by:ValueTypes["ProductLocaleUniqueWhere"]},ValueTypes["ProductLocale"]],
listProductLocale?: [{	filter?:ValueTypes["ProductLocaleWhere"],	orderBy?:ValueTypes["ProductLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductLocale"]],
paginateProductLocale?: [{	filter?:ValueTypes["ProductLocaleWhere"],	orderBy?:ValueTypes["ProductLocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["ProductLocaleConnection"]],
validateCreateProductLocale?: [{	data:ValueTypes["ProductLocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateProductLocale?: [{	by:ValueTypes["ProductLocaleUniqueWhere"],	data:ValueTypes["ProductLocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getMedia?: [{	by:ValueTypes["MediaUniqueWhere"]},ValueTypes["Media"]],
listMedia?: [{	filter?:ValueTypes["MediaWhere"],	orderBy?:ValueTypes["MediaOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Media"]],
paginateMedia?: [{	filter?:ValueTypes["MediaWhere"],	orderBy?:ValueTypes["MediaOrderBy"][],	skip?:number,	first?:number},ValueTypes["MediaConnection"]],
validateCreateMedia?: [{	data:ValueTypes["MediaCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateMedia?: [{	by:ValueTypes["MediaUniqueWhere"],	data:ValueTypes["MediaUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconListBlockItem?: [{	by:ValueTypes["IconListBlockItemUniqueWhere"]},ValueTypes["IconListBlockItem"]],
listIconListBlockItem?: [{	filter?:ValueTypes["IconListBlockItemWhere"],	orderBy?:ValueTypes["IconListBlockItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconListBlockItem"]],
paginateIconListBlockItem?: [{	filter?:ValueTypes["IconListBlockItemWhere"],	orderBy?:ValueTypes["IconListBlockItemOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconListBlockItemConnection"]],
validateCreateIconListBlockItem?: [{	data:ValueTypes["IconListBlockItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconListBlockItem?: [{	by:ValueTypes["IconListBlockItemUniqueWhere"],	data:ValueTypes["IconListBlockItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconListBlock?: [{	by:ValueTypes["IconListBlockUniqueWhere"]},ValueTypes["IconListBlock"]],
listIconListBlock?: [{	filter?:ValueTypes["IconListBlockWhere"],	orderBy?:ValueTypes["IconListBlockOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconListBlock"]],
paginateIconListBlock?: [{	filter?:ValueTypes["IconListBlockWhere"],	orderBy?:ValueTypes["IconListBlockOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconListBlockConnection"]],
validateCreateIconListBlock?: [{	data:ValueTypes["IconListBlockCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconListBlock?: [{	by:ValueTypes["IconListBlockUniqueWhere"],	data:ValueTypes["IconListBlockUpdateInput"]},ValueTypes["_ValidationResult"]],
getFeatureListItem?: [{	by:ValueTypes["FeatureListItemUniqueWhere"]},ValueTypes["FeatureListItem"]],
listFeatureListItem?: [{	filter?:ValueTypes["FeatureListItemWhere"],	orderBy?:ValueTypes["FeatureListItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["FeatureListItem"]],
paginateFeatureListItem?: [{	filter?:ValueTypes["FeatureListItemWhere"],	orderBy?:ValueTypes["FeatureListItemOrderBy"][],	skip?:number,	first?:number},ValueTypes["FeatureListItemConnection"]],
validateCreateFeatureListItem?: [{	data:ValueTypes["FeatureListItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateFeatureListItem?: [{	by:ValueTypes["FeatureListItemUniqueWhere"],	data:ValueTypes["FeatureListItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getFeatureList?: [{	by:ValueTypes["FeatureListUniqueWhere"]},ValueTypes["FeatureList"]],
listFeatureList?: [{	filter?:ValueTypes["FeatureListWhere"],	orderBy?:ValueTypes["FeatureListOrderBy"][],	offset?:number,	limit?:number},ValueTypes["FeatureList"]],
paginateFeatureList?: [{	filter?:ValueTypes["FeatureListWhere"],	orderBy?:ValueTypes["FeatureListOrderBy"][],	skip?:number,	first?:number},ValueTypes["FeatureListConnection"]],
validateCreateFeatureList?: [{	data:ValueTypes["FeatureListCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateFeatureList?: [{	by:ValueTypes["FeatureListUniqueWhere"],	data:ValueTypes["FeatureListUpdateInput"]},ValueTypes["_ValidationResult"]],
getFaqItem?: [{	by:ValueTypes["FaqItemUniqueWhere"]},ValueTypes["FaqItem"]],
listFaqItem?: [{	filter?:ValueTypes["FaqItemWhere"],	orderBy?:ValueTypes["FaqItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["FaqItem"]],
paginateFaqItem?: [{	filter?:ValueTypes["FaqItemWhere"],	orderBy?:ValueTypes["FaqItemOrderBy"][],	skip?:number,	first?:number},ValueTypes["FaqItemConnection"]],
validateCreateFaqItem?: [{	data:ValueTypes["FaqItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateFaqItem?: [{	by:ValueTypes["FaqItemUniqueWhere"],	data:ValueTypes["FaqItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getFaq?: [{	by:ValueTypes["FaqUniqueWhere"]},ValueTypes["Faq"]],
listFaq?: [{	filter?:ValueTypes["FaqWhere"],	orderBy?:ValueTypes["FaqOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Faq"]],
paginateFaq?: [{	filter?:ValueTypes["FaqWhere"],	orderBy?:ValueTypes["FaqOrderBy"][],	skip?:number,	first?:number},ValueTypes["FaqConnection"]],
validateCreateFaq?: [{	data:ValueTypes["FaqCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateFaq?: [{	by:ValueTypes["FaqUniqueWhere"],	data:ValueTypes["FaqUpdateInput"]},ValueTypes["_ValidationResult"]],
getContentBlock?: [{	by:ValueTypes["ContentBlockUniqueWhere"]},ValueTypes["ContentBlock"]],
listContentBlock?: [{	filter?:ValueTypes["ContentBlockWhere"],	orderBy?:ValueTypes["ContentBlockOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ContentBlock"]],
paginateContentBlock?: [{	filter?:ValueTypes["ContentBlockWhere"],	orderBy?:ValueTypes["ContentBlockOrderBy"][],	skip?:number,	first?:number},ValueTypes["ContentBlockConnection"]],
validateCreateContentBlock?: [{	data:ValueTypes["ContentBlockCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateContentBlock?: [{	by:ValueTypes["ContentBlockUniqueWhere"],	data:ValueTypes["ContentBlockUpdateInput"]},ValueTypes["_ValidationResult"]],
getContent?: [{	by:ValueTypes["ContentUniqueWhere"]},ValueTypes["Content"]],
listContent?: [{	filter?:ValueTypes["ContentWhere"],	orderBy?:ValueTypes["ContentOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Content"]],
paginateContent?: [{	filter?:ValueTypes["ContentWhere"],	orderBy?:ValueTypes["ContentOrderBy"][],	skip?:number,	first?:number},ValueTypes["ContentConnection"]],
validateCreateContent?: [{	data:ValueTypes["ContentCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateContent?: [{	by:ValueTypes["ContentUniqueWhere"],	data:ValueTypes["ContentUpdateInput"]},ValueTypes["_ValidationResult"]],
getLink?: [{	by:ValueTypes["LinkUniqueWhere"]},ValueTypes["Link"]],
listLink?: [{	filter?:ValueTypes["LinkWhere"],	orderBy?:ValueTypes["LinkOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Link"]],
paginateLink?: [{	filter?:ValueTypes["LinkWhere"],	orderBy?:ValueTypes["LinkOrderBy"][],	skip?:number,	first?:number},ValueTypes["LinkConnection"]],
validateCreateLink?: [{	data:ValueTypes["LinkCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateLink?: [{	by:ValueTypes["LinkUniqueWhere"],	data:ValueTypes["LinkUpdateInput"]},ValueTypes["_ValidationResult"]],
getReviewLocale?: [{	by:ValueTypes["ReviewLocaleUniqueWhere"]},ValueTypes["ReviewLocale"]],
listReviewLocale?: [{	filter?:ValueTypes["ReviewLocaleWhere"],	orderBy?:ValueTypes["ReviewLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ReviewLocale"]],
paginateReviewLocale?: [{	filter?:ValueTypes["ReviewLocaleWhere"],	orderBy?:ValueTypes["ReviewLocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["ReviewLocaleConnection"]],
validateCreateReviewLocale?: [{	data:ValueTypes["ReviewLocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateReviewLocale?: [{	by:ValueTypes["ReviewLocaleUniqueWhere"],	data:ValueTypes["ReviewLocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getReview?: [{	by:ValueTypes["ReviewUniqueWhere"]},ValueTypes["Review"]],
listReview?: [{	filter?:ValueTypes["ReviewWhere"],	orderBy?:ValueTypes["ReviewOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Review"]],
paginateReview?: [{	filter?:ValueTypes["ReviewWhere"],	orderBy?:ValueTypes["ReviewOrderBy"][],	skip?:number,	first?:number},ValueTypes["ReviewConnection"]],
validateCreateReview?: [{	data:ValueTypes["ReviewCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateReview?: [{	by:ValueTypes["ReviewUniqueWhere"],	data:ValueTypes["ReviewUpdateInput"]},ValueTypes["_ValidationResult"]],
getGalleryItem?: [{	by:ValueTypes["GalleryItemUniqueWhere"]},ValueTypes["GalleryItem"]],
listGalleryItem?: [{	filter?:ValueTypes["GalleryItemWhere"],	orderBy?:ValueTypes["GalleryItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["GalleryItem"]],
paginateGalleryItem?: [{	filter?:ValueTypes["GalleryItemWhere"],	orderBy?:ValueTypes["GalleryItemOrderBy"][],	skip?:number,	first?:number},ValueTypes["GalleryItemConnection"]],
validateCreateGalleryItem?: [{	data:ValueTypes["GalleryItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateGalleryItem?: [{	by:ValueTypes["GalleryItemUniqueWhere"],	data:ValueTypes["GalleryItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getGallery?: [{	by:ValueTypes["GalleryUniqueWhere"]},ValueTypes["Gallery"]],
listGallery?: [{	filter?:ValueTypes["GalleryWhere"],	orderBy?:ValueTypes["GalleryOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Gallery"]],
paginateGallery?: [{	filter?:ValueTypes["GalleryWhere"],	orderBy?:ValueTypes["GalleryOrderBy"][],	skip?:number,	first?:number},ValueTypes["GalleryConnection"]],
validateCreateGallery?: [{	data:ValueTypes["GalleryCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateGallery?: [{	by:ValueTypes["GalleryUniqueWhere"],	data:ValueTypes["GalleryUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductVariantStore?: [{	by:ValueTypes["ProductVariantStoreUniqueWhere"]},ValueTypes["ProductVariantStore"]],
listProductVariantStore?: [{	filter?:ValueTypes["ProductVariantStoreWhere"],	orderBy?:ValueTypes["ProductVariantStoreOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductVariantStore"]],
paginateProductVariantStore?: [{	filter?:ValueTypes["ProductVariantStoreWhere"],	orderBy?:ValueTypes["ProductVariantStoreOrderBy"][],	skip?:number,	first?:number},ValueTypes["ProductVariantStoreConnection"]],
validateCreateProductVariantStore?: [{	data:ValueTypes["ProductVariantStoreCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateProductVariantStore?: [{	by:ValueTypes["ProductVariantStoreUniqueWhere"],	data:ValueTypes["ProductVariantStoreUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductStore?: [{	by:ValueTypes["ProductStoreUniqueWhere"]},ValueTypes["ProductStore"]],
listProductStore?: [{	filter?:ValueTypes["ProductStoreWhere"],	orderBy?:ValueTypes["ProductStoreOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductStore"]],
paginateProductStore?: [{	filter?:ValueTypes["ProductStoreWhere"],	orderBy?:ValueTypes["ProductStoreOrderBy"][],	skip?:number,	first?:number},ValueTypes["ProductStoreConnection"]],
validateCreateProductStore?: [{	data:ValueTypes["ProductStoreCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateProductStore?: [{	by:ValueTypes["ProductStoreUniqueWhere"],	data:ValueTypes["ProductStoreUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconListItem?: [{	by:ValueTypes["IconListItemUniqueWhere"]},ValueTypes["IconListItem"]],
listIconListItem?: [{	filter?:ValueTypes["IconListItemWhere"],	orderBy?:ValueTypes["IconListItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconListItem"]],
paginateIconListItem?: [{	filter?:ValueTypes["IconListItemWhere"],	orderBy?:ValueTypes["IconListItemOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconListItemConnection"]],
validateCreateIconListItem?: [{	data:ValueTypes["IconListItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconListItem?: [{	by:ValueTypes["IconListItemUniqueWhere"],	data:ValueTypes["IconListItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconList?: [{	by:ValueTypes["IconListUniqueWhere"]},ValueTypes["IconList"]],
listIconList?: [{	filter?:ValueTypes["IconListWhere"],	orderBy?:ValueTypes["IconListOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconList"]],
paginateIconList?: [{	filter?:ValueTypes["IconListWhere"],	orderBy?:ValueTypes["IconListOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconListConnection"]],
validateCreateIconList?: [{	data:ValueTypes["IconListCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconList?: [{	by:ValueTypes["IconListUniqueWhere"],	data:ValueTypes["IconListUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconGroupListItem?: [{	by:ValueTypes["IconGroupListItemUniqueWhere"]},ValueTypes["IconGroupListItem"]],
listIconGroupListItem?: [{	filter?:ValueTypes["IconGroupListItemWhere"],	orderBy?:ValueTypes["IconGroupListItemOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconGroupListItem"]],
paginateIconGroupListItem?: [{	filter?:ValueTypes["IconGroupListItemWhere"],	orderBy?:ValueTypes["IconGroupListItemOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconGroupListItemConnection"]],
validateCreateIconGroupListItem?: [{	data:ValueTypes["IconGroupListItemCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconGroupListItem?: [{	by:ValueTypes["IconGroupListItemUniqueWhere"],	data:ValueTypes["IconGroupListItemUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconGroupList?: [{	by:ValueTypes["IconGroupListUniqueWhere"]},ValueTypes["IconGroupList"]],
listIconGroupList?: [{	filter?:ValueTypes["IconGroupListWhere"],	orderBy?:ValueTypes["IconGroupListOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconGroupList"]],
paginateIconGroupList?: [{	filter?:ValueTypes["IconGroupListWhere"],	orderBy?:ValueTypes["IconGroupListOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconGroupListConnection"]],
validateCreateIconGroupList?: [{	data:ValueTypes["IconGroupListCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconGroupList?: [{	by:ValueTypes["IconGroupListUniqueWhere"],	data:ValueTypes["IconGroupListUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductVariantLocale?: [{	by:ValueTypes["ProductVariantLocaleUniqueWhere"]},ValueTypes["ProductVariantLocale"]],
listProductVariantLocale?: [{	filter?:ValueTypes["ProductVariantLocaleWhere"],	orderBy?:ValueTypes["ProductVariantLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductVariantLocale"]],
paginateProductVariantLocale?: [{	filter?:ValueTypes["ProductVariantLocaleWhere"],	orderBy?:ValueTypes["ProductVariantLocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["ProductVariantLocaleConnection"]],
validateCreateProductVariantLocale?: [{	data:ValueTypes["ProductVariantLocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateProductVariantLocale?: [{	by:ValueTypes["ProductVariantLocaleUniqueWhere"],	data:ValueTypes["ProductVariantLocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getProductVariant?: [{	by:ValueTypes["ProductVariantUniqueWhere"]},ValueTypes["ProductVariant"]],
listProductVariant?: [{	filter?:ValueTypes["ProductVariantWhere"],	orderBy?:ValueTypes["ProductVariantOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductVariant"]],
paginateProductVariant?: [{	filter?:ValueTypes["ProductVariantWhere"],	orderBy?:ValueTypes["ProductVariantOrderBy"][],	skip?:number,	first?:number},ValueTypes["ProductVariantConnection"]],
validateCreateProductVariant?: [{	data:ValueTypes["ProductVariantCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateProductVariant?: [{	by:ValueTypes["ProductVariantUniqueWhere"],	data:ValueTypes["ProductVariantUpdateInput"]},ValueTypes["_ValidationResult"]],
getProduct?: [{	by:ValueTypes["ProductUniqueWhere"]},ValueTypes["Product"]],
listProduct?: [{	filter?:ValueTypes["ProductWhere"],	orderBy?:ValueTypes["ProductOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Product"]],
paginateProduct?: [{	filter?:ValueTypes["ProductWhere"],	orderBy?:ValueTypes["ProductOrderBy"][],	skip?:number,	first?:number},ValueTypes["ProductConnection"]],
validateCreateProduct?: [{	data:ValueTypes["ProductCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateProduct?: [{	by:ValueTypes["ProductUniqueWhere"],	data:ValueTypes["ProductUpdateInput"]},ValueTypes["_ValidationResult"]],
getImageLocale?: [{	by:ValueTypes["ImageLocaleUniqueWhere"]},ValueTypes["ImageLocale"]],
listImageLocale?: [{	filter?:ValueTypes["ImageLocaleWhere"],	orderBy?:ValueTypes["ImageLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ImageLocale"]],
paginateImageLocale?: [{	filter?:ValueTypes["ImageLocaleWhere"],	orderBy?:ValueTypes["ImageLocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["ImageLocaleConnection"]],
validateCreateImageLocale?: [{	data:ValueTypes["ImageLocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateImageLocale?: [{	by:ValueTypes["ImageLocaleUniqueWhere"],	data:ValueTypes["ImageLocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getStore?: [{	by:ValueTypes["StoreUniqueWhere"]},ValueTypes["Store"]],
listStore?: [{	filter?:ValueTypes["StoreWhere"],	orderBy?:ValueTypes["StoreOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Store"]],
paginateStore?: [{	filter?:ValueTypes["StoreWhere"],	orderBy?:ValueTypes["StoreOrderBy"][],	skip?:number,	first?:number},ValueTypes["StoreConnection"]],
validateCreateStore?: [{	data:ValueTypes["StoreCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateStore?: [{	by:ValueTypes["StoreUniqueWhere"],	data:ValueTypes["StoreUpdateInput"]},ValueTypes["_ValidationResult"]],
getAdminNote?: [{	by:ValueTypes["AdminNoteUniqueWhere"]},ValueTypes["AdminNote"]],
listAdminNote?: [{	filter?:ValueTypes["AdminNoteWhere"],	orderBy?:ValueTypes["AdminNoteOrderBy"][],	offset?:number,	limit?:number},ValueTypes["AdminNote"]],
paginateAdminNote?: [{	filter?:ValueTypes["AdminNoteWhere"],	orderBy?:ValueTypes["AdminNoteOrderBy"][],	skip?:number,	first?:number},ValueTypes["AdminNoteConnection"]],
validateCreateAdminNote?: [{	data:ValueTypes["AdminNoteCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateAdminNote?: [{	by:ValueTypes["AdminNoteUniqueWhere"],	data:ValueTypes["AdminNoteUpdateInput"]},ValueTypes["_ValidationResult"]],
getPageLocale?: [{	by:ValueTypes["PageLocaleUniqueWhere"]},ValueTypes["PageLocale"]],
listPageLocale?: [{	filter?:ValueTypes["PageLocaleWhere"],	orderBy?:ValueTypes["PageLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["PageLocale"]],
paginatePageLocale?: [{	filter?:ValueTypes["PageLocaleWhere"],	orderBy?:ValueTypes["PageLocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["PageLocaleConnection"]],
validateCreatePageLocale?: [{	data:ValueTypes["PageLocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdatePageLocale?: [{	by:ValueTypes["PageLocaleUniqueWhere"],	data:ValueTypes["PageLocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getPage?: [{	by:ValueTypes["PageUniqueWhere"]},ValueTypes["Page"]],
listPage?: [{	filter?:ValueTypes["PageWhere"],	orderBy?:ValueTypes["PageOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Page"]],
paginatePage?: [{	filter?:ValueTypes["PageWhere"],	orderBy?:ValueTypes["PageOrderBy"][],	skip?:number,	first?:number},ValueTypes["PageConnection"]],
validateCreatePage?: [{	data:ValueTypes["PageCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdatePage?: [{	by:ValueTypes["PageUniqueWhere"],	data:ValueTypes["PageUpdateInput"]},ValueTypes["_ValidationResult"]],
getImageXImageList?: [{	by:ValueTypes["ImageXImageListUniqueWhere"]},ValueTypes["ImageXImageList"]],
listImageXImageList?: [{	filter?:ValueTypes["ImageXImageListWhere"],	orderBy?:ValueTypes["ImageXImageListOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ImageXImageList"]],
paginateImageXImageList?: [{	filter?:ValueTypes["ImageXImageListWhere"],	orderBy?:ValueTypes["ImageXImageListOrderBy"][],	skip?:number,	first?:number},ValueTypes["ImageXImageListConnection"]],
validateCreateImageXImageList?: [{	data:ValueTypes["ImageXImageListCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateImageXImageList?: [{	by:ValueTypes["ImageXImageListUniqueWhere"],	data:ValueTypes["ImageXImageListUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconGroupLocale?: [{	by:ValueTypes["IconGroupLocaleUniqueWhere"]},ValueTypes["IconGroupLocale"]],
listIconGroupLocale?: [{	filter?:ValueTypes["IconGroupLocaleWhere"],	orderBy?:ValueTypes["IconGroupLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconGroupLocale"]],
paginateIconGroupLocale?: [{	filter?:ValueTypes["IconGroupLocaleWhere"],	orderBy?:ValueTypes["IconGroupLocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconGroupLocaleConnection"]],
validateCreateIconGroupLocale?: [{	data:ValueTypes["IconGroupLocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconGroupLocale?: [{	by:ValueTypes["IconGroupLocaleUniqueWhere"],	data:ValueTypes["IconGroupLocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconLocale?: [{	by:ValueTypes["IconLocaleUniqueWhere"]},ValueTypes["IconLocale"]],
listIconLocale?: [{	filter?:ValueTypes["IconLocaleWhere"],	orderBy?:ValueTypes["IconLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconLocale"]],
paginateIconLocale?: [{	filter?:ValueTypes["IconLocaleWhere"],	orderBy?:ValueTypes["IconLocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconLocaleConnection"]],
validateCreateIconLocale?: [{	data:ValueTypes["IconLocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconLocale?: [{	by:ValueTypes["IconLocaleUniqueWhere"],	data:ValueTypes["IconLocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getIconGroup?: [{	by:ValueTypes["IconGroupUniqueWhere"]},ValueTypes["IconGroup"]],
listIconGroup?: [{	filter?:ValueTypes["IconGroupWhere"],	orderBy?:ValueTypes["IconGroupOrderBy"][],	offset?:number,	limit?:number},ValueTypes["IconGroup"]],
paginateIconGroup?: [{	filter?:ValueTypes["IconGroupWhere"],	orderBy?:ValueTypes["IconGroupOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconGroupConnection"]],
validateCreateIconGroup?: [{	data:ValueTypes["IconGroupCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIconGroup?: [{	by:ValueTypes["IconGroupUniqueWhere"],	data:ValueTypes["IconGroupUpdateInput"]},ValueTypes["_ValidationResult"]],
getIcon?: [{	by:ValueTypes["IconUniqueWhere"]},ValueTypes["Icon"]],
listIcon?: [{	filter?:ValueTypes["IconWhere"],	orderBy?:ValueTypes["IconOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Icon"]],
paginateIcon?: [{	filter?:ValueTypes["IconWhere"],	orderBy?:ValueTypes["IconOrderBy"][],	skip?:number,	first?:number},ValueTypes["IconConnection"]],
validateCreateIcon?: [{	data:ValueTypes["IconCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateIcon?: [{	by:ValueTypes["IconUniqueWhere"],	data:ValueTypes["IconUpdateInput"]},ValueTypes["_ValidationResult"]],
getLocale?: [{	by:ValueTypes["LocaleUniqueWhere"]},ValueTypes["Locale"]],
listLocale?: [{	filter?:ValueTypes["LocaleWhere"],	orderBy?:ValueTypes["LocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Locale"]],
paginateLocale?: [{	filter?:ValueTypes["LocaleWhere"],	orderBy?:ValueTypes["LocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["LocaleConnection"]],
validateCreateLocale?: [{	data:ValueTypes["LocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateLocale?: [{	by:ValueTypes["LocaleUniqueWhere"],	data:ValueTypes["LocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getSeoLocale?: [{	by:ValueTypes["SeoLocaleUniqueWhere"]},ValueTypes["SeoLocale"]],
listSeoLocale?: [{	filter?:ValueTypes["SeoLocaleWhere"],	orderBy?:ValueTypes["SeoLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["SeoLocale"]],
paginateSeoLocale?: [{	filter?:ValueTypes["SeoLocaleWhere"],	orderBy?:ValueTypes["SeoLocaleOrderBy"][],	skip?:number,	first?:number},ValueTypes["SeoLocaleConnection"]],
validateCreateSeoLocale?: [{	data:ValueTypes["SeoLocaleCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateSeoLocale?: [{	by:ValueTypes["SeoLocaleUniqueWhere"],	data:ValueTypes["SeoLocaleUpdateInput"]},ValueTypes["_ValidationResult"]],
getSeo?: [{	by:ValueTypes["SeoUniqueWhere"]},ValueTypes["Seo"]],
listSeo?: [{	filter?:ValueTypes["SeoWhere"],	orderBy?:ValueTypes["SeoOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Seo"]],
paginateSeo?: [{	filter?:ValueTypes["SeoWhere"],	orderBy?:ValueTypes["SeoOrderBy"][],	skip?:number,	first?:number},ValueTypes["SeoConnection"]],
validateCreateSeo?: [{	data:ValueTypes["SeoCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateSeo?: [{	by:ValueTypes["SeoUniqueWhere"],	data:ValueTypes["SeoUpdateInput"]},ValueTypes["_ValidationResult"]],
getLinkable?: [{	by:ValueTypes["LinkableUniqueWhere"]},ValueTypes["Linkable"]],
listLinkable?: [{	filter?:ValueTypes["LinkableWhere"],	orderBy?:ValueTypes["LinkableOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Linkable"]],
paginateLinkable?: [{	filter?:ValueTypes["LinkableWhere"],	orderBy?:ValueTypes["LinkableOrderBy"][],	skip?:number,	first?:number},ValueTypes["LinkableConnection"]],
validateCreateLinkable?: [{	data:ValueTypes["LinkableCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateLinkable?: [{	by:ValueTypes["LinkableUniqueWhere"],	data:ValueTypes["LinkableUpdateInput"]},ValueTypes["_ValidationResult"]],
getImageList?: [{	by:ValueTypes["ImageListUniqueWhere"]},ValueTypes["ImageList"]],
listImageList?: [{	filter?:ValueTypes["ImageListWhere"],	orderBy?:ValueTypes["ImageListOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ImageList"]],
paginateImageList?: [{	filter?:ValueTypes["ImageListWhere"],	orderBy?:ValueTypes["ImageListOrderBy"][],	skip?:number,	first?:number},ValueTypes["ImageListConnection"]],
validateCreateImageList?: [{	data:ValueTypes["ImageListCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateImageList?: [{	by:ValueTypes["ImageListUniqueWhere"],	data:ValueTypes["ImageListUpdateInput"]},ValueTypes["_ValidationResult"]],
getImage?: [{	by:ValueTypes["ImageUniqueWhere"]},ValueTypes["Image"]],
listImage?: [{	filter?:ValueTypes["ImageWhere"],	orderBy?:ValueTypes["ImageOrderBy"][],	offset?:number,	limit?:number},ValueTypes["Image"]],
paginateImage?: [{	filter?:ValueTypes["ImageWhere"],	orderBy?:ValueTypes["ImageOrderBy"][],	skip?:number,	first?:number},ValueTypes["ImageConnection"]],
validateCreateImage?: [{	data:ValueTypes["ImageCreateInput"]},ValueTypes["_ValidationResult"]],
validateUpdateImage?: [{	by:ValueTypes["ImageUniqueWhere"],	data:ValueTypes["ImageUpdateInput"]},ValueTypes["_ValidationResult"]],
		__typename?: true
}>;
	["Review"]: AliasType<{
	_meta?:ValueTypes["ReviewMeta"],
	id?:true,
	username?:true,
	createdAt?:true,
locales?: [{	filter?:ValueTypes["ReviewLocaleWhere"],	orderBy?:ValueTypes["ReviewLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ReviewLocale"]],
localesByLocale?: [{	by:ValueTypes["ReviewLocalesByLocaleUniqueWhere"],	filter?:ValueTypes["ReviewLocaleWhere"]},ValueTypes["ReviewLocale"]],
		__typename?: true
}>;
	["ReviewConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ReviewEdge"],
		__typename?: true
}>;
	["ReviewCreateInput"]: {
	username?:string,
	createdAt?:ValueTypes["DateTime"],
	locales?:ValueTypes["ReviewCreateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["ReviewCreateLocalesEntityRelationInput"]: {
	connect?:ValueTypes["ReviewLocaleUniqueWhere"],
	create?:ValueTypes["ReviewLocaleWithoutReviewCreateInput"],
	alias?:string
};
	["ReviewCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Review"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ReviewDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Review"],
		__typename?: true
}>;
	["ReviewEdge"]: AliasType<{
	node?:ValueTypes["Review"],
		__typename?: true
}>;
	["ReviewLocale"]: AliasType<{
	_meta?:ValueTypes["ReviewLocaleMeta"],
	id?:true,
	content?:true,
image?: [{	filter?:ValueTypes["ImageWhere"]},ValueTypes["Image"]],
locale?: [{	filter?:ValueTypes["LocaleWhere"]},ValueTypes["Locale"]],
review?: [{	filter?:ValueTypes["ReviewWhere"]},ValueTypes["Review"]],
		__typename?: true
}>;
	["ReviewLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ReviewLocaleEdge"],
		__typename?: true
}>;
	["ReviewLocaleCreateImageEntityRelationInput"]: {
	connect?:ValueTypes["ImageUniqueWhere"],
	create?:ValueTypes["ImageCreateInput"]
};
	["ReviewLocaleCreateInput"]: {
	content?:string,
	image?:ValueTypes["ReviewLocaleCreateImageEntityRelationInput"],
	locale?:ValueTypes["ReviewLocaleCreateLocaleEntityRelationInput"],
	review?:ValueTypes["ReviewLocaleCreateReviewEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ReviewLocaleCreateLocaleEntityRelationInput"]: {
	connect?:ValueTypes["LocaleUniqueWhere"],
	create?:ValueTypes["LocaleCreateInput"]
};
	["ReviewLocaleCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ReviewLocale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ReviewLocaleCreateReviewEntityRelationInput"]: {
	connect?:ValueTypes["ReviewUniqueWhere"],
	create?:ValueTypes["ReviewWithoutLocalesCreateInput"]
};
	["ReviewLocaleDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ReviewLocale"],
		__typename?: true
}>;
	["ReviewLocaleEdge"]: AliasType<{
	node?:ValueTypes["ReviewLocale"],
		__typename?: true
}>;
	["ReviewLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	content?:ValueTypes["FieldMeta"],
	image?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
	review?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ReviewLocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	content?:ValueTypes["OrderDirection"],
	image?:ValueTypes["ImageOrderBy"],
	locale?:ValueTypes["LocaleOrderBy"],
	review?:ValueTypes["ReviewOrderBy"]
};
	["ReviewLocalesByLocaleUniqueWhere"]: {
	locale?:ValueTypes["LocaleUniqueWhere"]
};
	["ReviewLocaleUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	review?:ValueTypes["ReviewUniqueWhere"],
	locale?:ValueTypes["LocaleUniqueWhere"]
};
	["ReviewLocaleUpdateImageEntityRelationInput"]: {
	create?:ValueTypes["ImageCreateInput"],
	update?:ValueTypes["ImageUpdateInput"],
	upsert?:ValueTypes["ReviewLocaleUpsertImageRelationInput"],
	connect?:ValueTypes["ImageUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["ReviewLocaleUpdateInput"]: {
	content?:string,
	image?:ValueTypes["ReviewLocaleUpdateImageEntityRelationInput"],
	locale?:ValueTypes["ReviewLocaleUpdateLocaleEntityRelationInput"],
	review?:ValueTypes["ReviewLocaleUpdateReviewEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ReviewLocaleUpdateLocaleEntityRelationInput"]: {
	create?:ValueTypes["LocaleCreateInput"],
	update?:ValueTypes["LocaleUpdateInput"],
	upsert?:ValueTypes["ReviewLocaleUpsertLocaleRelationInput"],
	connect?:ValueTypes["LocaleUniqueWhere"]
};
	["ReviewLocaleUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ReviewLocale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ReviewLocaleUpdateReviewEntityRelationInput"]: {
	create?:ValueTypes["ReviewWithoutLocalesCreateInput"],
	update?:ValueTypes["ReviewWithoutLocalesUpdateInput"],
	upsert?:ValueTypes["ReviewLocaleUpsertReviewRelationInput"],
	connect?:ValueTypes["ReviewUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["ReviewLocaleUpsertImageRelationInput"]: {
	update?:ValueTypes["ImageUpdateInput"],
	create?:ValueTypes["ImageCreateInput"]
};
	["ReviewLocaleUpsertLocaleRelationInput"]: {
	update?:ValueTypes["LocaleUpdateInput"],
	create?:ValueTypes["LocaleCreateInput"]
};
	["ReviewLocaleUpsertReviewRelationInput"]: {
	update?:ValueTypes["ReviewWithoutLocalesUpdateInput"],
	create?:ValueTypes["ReviewWithoutLocalesCreateInput"]
};
	["ReviewLocaleWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	content?:ValueTypes["StringCondition"],
	image?:ValueTypes["ImageWhere"],
	locale?:ValueTypes["LocaleWhere"],
	review?:ValueTypes["ReviewWhere"],
	and?:ValueTypes["ReviewLocaleWhere"][],
	or?:ValueTypes["ReviewLocaleWhere"][],
	not?:ValueTypes["ReviewLocaleWhere"]
};
	["ReviewLocaleWithoutReviewCreateInput"]: {
	content?:string,
	image?:ValueTypes["ReviewLocaleCreateImageEntityRelationInput"],
	locale?:ValueTypes["ReviewLocaleCreateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ReviewLocaleWithoutReviewUpdateInput"]: {
	content?:string,
	image?:ValueTypes["ReviewLocaleUpdateImageEntityRelationInput"],
	locale?:ValueTypes["ReviewLocaleUpdateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
};
	["ReviewMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	username?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ReviewOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	username?:ValueTypes["OrderDirection"],
	createdAt?:ValueTypes["OrderDirection"]
};
	["ReviewUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	locales?:ValueTypes["ReviewLocaleUniqueWhere"]
};
	["ReviewUpdateInput"]: {
	username?:string,
	createdAt?:ValueTypes["DateTime"],
	locales?:ValueTypes["ReviewUpdateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["ReviewUpdateLocalesEntityRelationInput"]: {
	create?:ValueTypes["ReviewLocaleWithoutReviewCreateInput"],
	update?:ValueTypes["ReviewUpdateLocalesRelationInput"],
	upsert?:ValueTypes["ReviewUpsertLocalesRelationInput"],
	connect?:ValueTypes["ReviewLocaleUniqueWhere"],
	disconnect?:ValueTypes["ReviewLocaleUniqueWhere"],
	delete?:ValueTypes["ReviewLocaleUniqueWhere"],
	alias?:string
};
	["ReviewUpdateLocalesRelationInput"]: {
	by?:ValueTypes["ReviewLocaleUniqueWhere"],
	data?:ValueTypes["ReviewLocaleWithoutReviewUpdateInput"]
};
	["ReviewUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Review"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ReviewUpsertLocalesRelationInput"]: {
	by?:ValueTypes["ReviewLocaleUniqueWhere"],
	update?:ValueTypes["ReviewLocaleWithoutReviewUpdateInput"],
	create?:ValueTypes["ReviewLocaleWithoutReviewCreateInput"]
};
	["ReviewView"]:ReviewView;
	["ReviewViewEnumCondition"]: {
	and?:ValueTypes["ReviewViewEnumCondition"][],
	or?:ValueTypes["ReviewViewEnumCondition"][],
	not?:ValueTypes["ReviewViewEnumCondition"],
	eq?:ValueTypes["ReviewView"],
	null?:boolean,
	isNull?:boolean,
	notEq?:ValueTypes["ReviewView"],
	in?:ValueTypes["ReviewView"][],
	notIn?:ValueTypes["ReviewView"][],
	lt?:ValueTypes["ReviewView"],
	lte?:ValueTypes["ReviewView"],
	gt?:ValueTypes["ReviewView"],
	gte?:ValueTypes["ReviewView"]
};
	["ReviewWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	username?:ValueTypes["StringCondition"],
	createdAt?:ValueTypes["DateTimeCondition"],
	locales?:ValueTypes["ReviewLocaleWhere"],
	and?:ValueTypes["ReviewWhere"][],
	or?:ValueTypes["ReviewWhere"][],
	not?:ValueTypes["ReviewWhere"]
};
	["ReviewWithoutLocalesCreateInput"]: {
	username?:string,
	createdAt?:ValueTypes["DateTime"],
	_dummy_field_?:boolean
};
	["ReviewWithoutLocalesUpdateInput"]: {
	username?:string,
	createdAt?:ValueTypes["DateTime"],
	_dummy_field_?:boolean
};
	["S3Header"]: AliasType<{
	key?:true,
	value?:true,
		__typename?: true
}>;
	["S3SignedRead"]: AliasType<{
	url?:true,
	headers?:ValueTypes["S3Header"],
	method?:true,
	/** Allowed patterns:
** */
	objectKey?:true,
	bucket?:true,
		__typename?: true
}>;
	["S3SignedUpload"]: AliasType<{
	url?:true,
	headers?:ValueTypes["S3Header"],
	method?:true,
	/** Allowed patterns:
** */
	objectKey?:true,
	bucket?:true,
	publicUrl?:true,
		__typename?: true
}>;
	["Seo"]: AliasType<{
	_meta?:ValueTypes["SeoMeta"],
	id?:true,
ogImage?: [{	filter?:ValueTypes["ImageWhere"]},ValueTypes["Image"]],
locales?: [{	filter?:ValueTypes["SeoLocaleWhere"],	orderBy?:ValueTypes["SeoLocaleOrderBy"][],	offset?:number,	limit?:number},ValueTypes["SeoLocale"]],
	noindex?:true,
localesByLocale?: [{	by:ValueTypes["SeoLocalesByLocaleUniqueWhere"],	filter?:ValueTypes["SeoLocaleWhere"]},ValueTypes["SeoLocale"]],
		__typename?: true
}>;
	["SeoConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SeoEdge"],
		__typename?: true
}>;
	["SeoCreateInput"]: {
	ogImage?:ValueTypes["SeoCreateOgImageEntityRelationInput"],
	locales?:ValueTypes["SeoCreateLocalesEntityRelationInput"][],
	noindex?:boolean,
	_dummy_field_?:boolean
};
	["SeoCreateLocalesEntityRelationInput"]: {
	connect?:ValueTypes["SeoLocaleUniqueWhere"],
	create?:ValueTypes["SeoLocaleWithoutRootCreateInput"],
	alias?:string
};
	["SeoCreateOgImageEntityRelationInput"]: {
	connect?:ValueTypes["ImageUniqueWhere"],
	create?:ValueTypes["ImageCreateInput"]
};
	["SeoCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Seo"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["SeoDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Seo"],
		__typename?: true
}>;
	["SeoEdge"]: AliasType<{
	node?:ValueTypes["Seo"],
		__typename?: true
}>;
	["SeoLocale"]: AliasType<{
	_meta?:ValueTypes["SeoLocaleMeta"],
	id?:true,
	title?:true,
	description?:true,
	ogTitle?:true,
	ogDescription?:true,
root?: [{	filter?:ValueTypes["SeoWhere"]},ValueTypes["Seo"]],
locale?: [{	filter?:ValueTypes["LocaleWhere"]},ValueTypes["Locale"]],
		__typename?: true
}>;
	["SeoLocaleConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["SeoLocaleEdge"],
		__typename?: true
}>;
	["SeoLocaleCreateInput"]: {
	title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	root?:ValueTypes["SeoLocaleCreateRootEntityRelationInput"],
	locale?:ValueTypes["SeoLocaleCreateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
};
	["SeoLocaleCreateLocaleEntityRelationInput"]: {
	connect?:ValueTypes["LocaleUniqueWhere"],
	create?:ValueTypes["LocaleWithoutSeosCreateInput"]
};
	["SeoLocaleCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["SeoLocale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["SeoLocaleCreateRootEntityRelationInput"]: {
	connect?:ValueTypes["SeoUniqueWhere"],
	create?:ValueTypes["SeoWithoutLocalesCreateInput"]
};
	["SeoLocaleDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["SeoLocale"],
		__typename?: true
}>;
	["SeoLocaleEdge"]: AliasType<{
	node?:ValueTypes["SeoLocale"],
		__typename?: true
}>;
	["SeoLocaleMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	title?:ValueTypes["FieldMeta"],
	description?:ValueTypes["FieldMeta"],
	ogTitle?:ValueTypes["FieldMeta"],
	ogDescription?:ValueTypes["FieldMeta"],
	root?:ValueTypes["FieldMeta"],
	locale?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["SeoLocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	title?:ValueTypes["OrderDirection"],
	description?:ValueTypes["OrderDirection"],
	ogTitle?:ValueTypes["OrderDirection"],
	ogDescription?:ValueTypes["OrderDirection"],
	root?:ValueTypes["SeoOrderBy"],
	locale?:ValueTypes["LocaleOrderBy"]
};
	["SeoLocalesByLocaleUniqueWhere"]: {
	locale?:ValueTypes["LocaleUniqueWhere"]
};
	["SeoLocaleUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	root?:ValueTypes["SeoUniqueWhere"],
	locale?:ValueTypes["LocaleUniqueWhere"]
};
	["SeoLocaleUpdateInput"]: {
	title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	root?:ValueTypes["SeoLocaleUpdateRootEntityRelationInput"],
	locale?:ValueTypes["SeoLocaleUpdateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
};
	["SeoLocaleUpdateLocaleEntityRelationInput"]: {
	create?:ValueTypes["LocaleWithoutSeosCreateInput"],
	update?:ValueTypes["LocaleWithoutSeosUpdateInput"],
	upsert?:ValueTypes["SeoLocaleUpsertLocaleRelationInput"],
	connect?:ValueTypes["LocaleUniqueWhere"]
};
	["SeoLocaleUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["SeoLocale"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["SeoLocaleUpdateRootEntityRelationInput"]: {
	create?:ValueTypes["SeoWithoutLocalesCreateInput"],
	update?:ValueTypes["SeoWithoutLocalesUpdateInput"],
	upsert?:ValueTypes["SeoLocaleUpsertRootRelationInput"],
	connect?:ValueTypes["SeoUniqueWhere"]
};
	["SeoLocaleUpsertLocaleRelationInput"]: {
	update?:ValueTypes["LocaleWithoutSeosUpdateInput"],
	create?:ValueTypes["LocaleWithoutSeosCreateInput"]
};
	["SeoLocaleUpsertRootRelationInput"]: {
	update?:ValueTypes["SeoWithoutLocalesUpdateInput"],
	create?:ValueTypes["SeoWithoutLocalesCreateInput"]
};
	["SeoLocaleWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	title?:ValueTypes["StringCondition"],
	description?:ValueTypes["StringCondition"],
	ogTitle?:ValueTypes["StringCondition"],
	ogDescription?:ValueTypes["StringCondition"],
	root?:ValueTypes["SeoWhere"],
	locale?:ValueTypes["LocaleWhere"],
	and?:ValueTypes["SeoLocaleWhere"][],
	or?:ValueTypes["SeoLocaleWhere"][],
	not?:ValueTypes["SeoLocaleWhere"]
};
	["SeoLocaleWithoutLocaleCreateInput"]: {
	title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	root?:ValueTypes["SeoLocaleCreateRootEntityRelationInput"],
	_dummy_field_?:boolean
};
	["SeoLocaleWithoutLocaleUpdateInput"]: {
	title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	root?:ValueTypes["SeoLocaleUpdateRootEntityRelationInput"],
	_dummy_field_?:boolean
};
	["SeoLocaleWithoutRootCreateInput"]: {
	title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	locale?:ValueTypes["SeoLocaleCreateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
};
	["SeoLocaleWithoutRootUpdateInput"]: {
	title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	locale?:ValueTypes["SeoLocaleUpdateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
};
	["SeoMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	ogImage?:ValueTypes["FieldMeta"],
	locales?:ValueTypes["FieldMeta"],
	noindex?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["SeoOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	ogImage?:ValueTypes["ImageOrderBy"],
	noindex?:ValueTypes["OrderDirection"]
};
	["SeoUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	ogImage?:ValueTypes["ImageUniqueWhere"],
	locales?:ValueTypes["SeoLocaleUniqueWhere"]
};
	["SeoUpdateInput"]: {
	ogImage?:ValueTypes["SeoUpdateOgImageEntityRelationInput"],
	locales?:ValueTypes["SeoUpdateLocalesEntityRelationInput"][],
	noindex?:boolean,
	_dummy_field_?:boolean
};
	["SeoUpdateLocalesEntityRelationInput"]: {
	create?:ValueTypes["SeoLocaleWithoutRootCreateInput"],
	update?:ValueTypes["SeoUpdateLocalesRelationInput"],
	upsert?:ValueTypes["SeoUpsertLocalesRelationInput"],
	connect?:ValueTypes["SeoLocaleUniqueWhere"],
	disconnect?:ValueTypes["SeoLocaleUniqueWhere"],
	delete?:ValueTypes["SeoLocaleUniqueWhere"],
	alias?:string
};
	["SeoUpdateLocalesRelationInput"]: {
	by?:ValueTypes["SeoLocaleUniqueWhere"],
	data?:ValueTypes["SeoLocaleWithoutRootUpdateInput"]
};
	["SeoUpdateOgImageEntityRelationInput"]: {
	create?:ValueTypes["ImageCreateInput"],
	update?:ValueTypes["ImageUpdateInput"],
	upsert?:ValueTypes["SeoUpsertOgImageRelationInput"],
	connect?:ValueTypes["ImageUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["SeoUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Seo"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["SeoUpsertLocalesRelationInput"]: {
	by?:ValueTypes["SeoLocaleUniqueWhere"],
	update?:ValueTypes["SeoLocaleWithoutRootUpdateInput"],
	create?:ValueTypes["SeoLocaleWithoutRootCreateInput"]
};
	["SeoUpsertOgImageRelationInput"]: {
	update?:ValueTypes["ImageUpdateInput"],
	create?:ValueTypes["ImageCreateInput"]
};
	["SeoWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	ogImage?:ValueTypes["ImageWhere"],
	locales?:ValueTypes["SeoLocaleWhere"],
	noindex?:ValueTypes["BooleanCondition"],
	and?:ValueTypes["SeoWhere"][],
	or?:ValueTypes["SeoWhere"][],
	not?:ValueTypes["SeoWhere"]
};
	["SeoWithoutLocalesCreateInput"]: {
	ogImage?:ValueTypes["SeoCreateOgImageEntityRelationInput"],
	noindex?:boolean,
	_dummy_field_?:boolean
};
	["SeoWithoutLocalesUpdateInput"]: {
	ogImage?:ValueTypes["SeoUpdateOgImageEntityRelationInput"],
	noindex?:boolean,
	_dummy_field_?:boolean
};
	["ShopifyLineItem"]: AliasType<{
	_meta?:ValueTypes["ShopifyLineItemMeta"],
	id?:true,
	store?:true,
	shopifyOrderId?:true,
	shopifyLineItemId?:true,
	customAttributes?:true,
		__typename?: true
}>;
	["ShopifyLineItemConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ShopifyLineItemEdge"],
		__typename?: true
}>;
	["ShopifyLineItemCreateInput"]: {
	store?:string,
	shopifyOrderId?:string,
	shopifyLineItemId?:string,
	customAttributes?:string,
	_dummy_field_?:boolean
};
	["ShopifyLineItemCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ShopifyLineItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ShopifyLineItemDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ShopifyLineItem"],
		__typename?: true
}>;
	["ShopifyLineItemEdge"]: AliasType<{
	node?:ValueTypes["ShopifyLineItem"],
		__typename?: true
}>;
	["ShopifyLineItemMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	store?:ValueTypes["FieldMeta"],
	shopifyOrderId?:ValueTypes["FieldMeta"],
	shopifyLineItemId?:ValueTypes["FieldMeta"],
	customAttributes?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ShopifyLineItemOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	store?:ValueTypes["OrderDirection"],
	shopifyOrderId?:ValueTypes["OrderDirection"],
	shopifyLineItemId?:ValueTypes["OrderDirection"],
	customAttributes?:ValueTypes["OrderDirection"]
};
	["ShopifyLineItemUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	store?:string,
	shopifyOrderId?:string,
	shopifyLineItemId?:string
};
	["ShopifyLineItemUpdateInput"]: {
	store?:string,
	shopifyOrderId?:string,
	shopifyLineItemId?:string,
	customAttributes?:string,
	_dummy_field_?:boolean
};
	["ShopifyLineItemUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ShopifyLineItem"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ShopifyLineItemWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	store?:ValueTypes["StringCondition"],
	shopifyOrderId?:ValueTypes["StringCondition"],
	shopifyLineItemId?:ValueTypes["StringCondition"],
	customAttributes?:ValueTypes["StringCondition"],
	and?:ValueTypes["ShopifyLineItemWhere"][],
	or?:ValueTypes["ShopifyLineItemWhere"][],
	not?:ValueTypes["ShopifyLineItemWhere"]
};
	["ShopifyOrder"]: AliasType<{
	_meta?:ValueTypes["ShopifyOrderMeta"],
	id?:true,
	createdAt?:true,
	fulfilledAt?:true,
	store?:true,
	shopifyId?:true,
	shippingType?:true,
	trackingId?:true,
	rawData?:true,
	trackingUrl?:true,
	shippingMeta?:true,
	enableResendAt?:true,
		__typename?: true
}>;
	["ShopifyOrderConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["ShopifyOrderEdge"],
		__typename?: true
}>;
	["ShopifyOrderCreateInput"]: {
	createdAt?:ValueTypes["DateTime"],
	fulfilledAt?:ValueTypes["DateTime"],
	store?:string,
	shopifyId?:string,
	shippingType?:string,
	trackingId?:string,
	rawData?:string,
	trackingUrl?:string,
	shippingMeta?:string,
	enableResendAt?:ValueTypes["DateTime"],
	_dummy_field_?:boolean
};
	["ShopifyOrderCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ShopifyOrder"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ShopifyOrderDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ShopifyOrder"],
		__typename?: true
}>;
	["ShopifyOrderEdge"]: AliasType<{
	node?:ValueTypes["ShopifyOrder"],
		__typename?: true
}>;
	["ShopifyOrderMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	createdAt?:ValueTypes["FieldMeta"],
	fulfilledAt?:ValueTypes["FieldMeta"],
	store?:ValueTypes["FieldMeta"],
	shopifyId?:ValueTypes["FieldMeta"],
	shippingType?:ValueTypes["FieldMeta"],
	trackingId?:ValueTypes["FieldMeta"],
	rawData?:ValueTypes["FieldMeta"],
	trackingUrl?:ValueTypes["FieldMeta"],
	shippingMeta?:ValueTypes["FieldMeta"],
	enableResendAt?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["ShopifyOrderOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	createdAt?:ValueTypes["OrderDirection"],
	fulfilledAt?:ValueTypes["OrderDirection"],
	store?:ValueTypes["OrderDirection"],
	shopifyId?:ValueTypes["OrderDirection"],
	shippingType?:ValueTypes["OrderDirection"],
	trackingId?:ValueTypes["OrderDirection"],
	rawData?:ValueTypes["OrderDirection"],
	trackingUrl?:ValueTypes["OrderDirection"],
	shippingMeta?:ValueTypes["OrderDirection"],
	enableResendAt?:ValueTypes["OrderDirection"]
};
	["ShopifyOrderUniqueWhere"]: {
	id?:ValueTypes["UUID"]
};
	["ShopifyOrderUpdateInput"]: {
	createdAt?:ValueTypes["DateTime"],
	fulfilledAt?:ValueTypes["DateTime"],
	store?:string,
	shopifyId?:string,
	shippingType?:string,
	trackingId?:string,
	rawData?:string,
	trackingUrl?:string,
	shippingMeta?:string,
	enableResendAt?:ValueTypes["DateTime"],
	_dummy_field_?:boolean
};
	["ShopifyOrderUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["ShopifyOrder"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["ShopifyOrderWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	createdAt?:ValueTypes["DateTimeCondition"],
	fulfilledAt?:ValueTypes["DateTimeCondition"],
	store?:ValueTypes["StringCondition"],
	shopifyId?:ValueTypes["StringCondition"],
	shippingType?:ValueTypes["StringCondition"],
	trackingId?:ValueTypes["StringCondition"],
	rawData?:ValueTypes["StringCondition"],
	trackingUrl?:ValueTypes["StringCondition"],
	shippingMeta?:ValueTypes["StringCondition"],
	enableResendAt?:ValueTypes["DateTimeCondition"],
	and?:ValueTypes["ShopifyOrderWhere"][],
	or?:ValueTypes["ShopifyOrderWhere"][],
	not?:ValueTypes["ShopifyOrderWhere"]
};
	["SideBySideView"]:SideBySideView;
	["SideBySideViewEnumCondition"]: {
	and?:ValueTypes["SideBySideViewEnumCondition"][],
	or?:ValueTypes["SideBySideViewEnumCondition"][],
	not?:ValueTypes["SideBySideViewEnumCondition"],
	eq?:ValueTypes["SideBySideView"],
	null?:boolean,
	isNull?:boolean,
	notEq?:ValueTypes["SideBySideView"],
	in?:ValueTypes["SideBySideView"][],
	notIn?:ValueTypes["SideBySideView"][],
	lt?:ValueTypes["SideBySideView"],
	lte?:ValueTypes["SideBySideView"],
	gt?:ValueTypes["SideBySideView"],
	gte?:ValueTypes["SideBySideView"]
};
	["Store"]: AliasType<{
	_meta?:ValueTypes["StoreMeta"],
	id?:true,
	code?:true,
	shopifyDomain?:true,
products?: [{	filter?:ValueTypes["ProductStoreWhere"],	orderBy?:ValueTypes["ProductStoreOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductStore"]],
productVariants?: [{	filter?:ValueTypes["ProductVariantStoreWhere"],	orderBy?:ValueTypes["ProductVariantStoreOrderBy"][],	offset?:number,	limit?:number},ValueTypes["ProductVariantStore"]],
productsByRoot?: [{	by:ValueTypes["StoreProductsByRootUniqueWhere"],	filter?:ValueTypes["ProductStoreWhere"]},ValueTypes["ProductStore"]],
productsByShopifyId?: [{	by:ValueTypes["StoreProductsByShopifyIdUniqueWhere"],	filter?:ValueTypes["ProductStoreWhere"]},ValueTypes["ProductStore"]],
productVariantsByRoot?: [{	by:ValueTypes["StoreProductVariantsByRootUniqueWhere"],	filter?:ValueTypes["ProductVariantStoreWhere"]},ValueTypes["ProductVariantStore"]],
productVariantsByShopifyId?: [{	by:ValueTypes["StoreProductVariantsByShopifyIdUniqueWhere"],	filter?:ValueTypes["ProductVariantStoreWhere"]},ValueTypes["ProductVariantStore"]],
productVariantsByStorefrontId?: [{	by:ValueTypes["StoreProductVariantsByStorefrontIdUniqueWhere"],	filter?:ValueTypes["ProductVariantStoreWhere"]},ValueTypes["ProductVariantStore"]],
		__typename?: true
}>;
	["StoreConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["StoreEdge"],
		__typename?: true
}>;
	["StoreCreateInput"]: {
	code?:string,
	shopifyDomain?:string,
	products?:ValueTypes["StoreCreateProductsEntityRelationInput"][],
	productVariants?:ValueTypes["StoreCreateProductVariantsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["StoreCreateProductsEntityRelationInput"]: {
	connect?:ValueTypes["ProductStoreUniqueWhere"],
	create?:ValueTypes["ProductStoreWithoutStoreCreateInput"],
	alias?:string
};
	["StoreCreateProductVariantsEntityRelationInput"]: {
	connect?:ValueTypes["ProductVariantStoreUniqueWhere"],
	create?:ValueTypes["ProductVariantStoreWithoutStoreCreateInput"],
	alias?:string
};
	["StoreCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Store"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["StoreDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Store"],
		__typename?: true
}>;
	["StoreEdge"]: AliasType<{
	node?:ValueTypes["Store"],
		__typename?: true
}>;
	["StoreMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	code?:ValueTypes["FieldMeta"],
	shopifyDomain?:ValueTypes["FieldMeta"],
	products?:ValueTypes["FieldMeta"],
	productVariants?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["StoreOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	code?:ValueTypes["OrderDirection"],
	shopifyDomain?:ValueTypes["OrderDirection"]
};
	["StoreProductsByRootUniqueWhere"]: {
	root?:ValueTypes["ProductUniqueWhere"]
};
	["StoreProductsByShopifyIdUniqueWhere"]: {
	shopifyId?:string
};
	["StoreProductVariantsByRootUniqueWhere"]: {
	root?:ValueTypes["ProductVariantUniqueWhere"]
};
	["StoreProductVariantsByShopifyIdUniqueWhere"]: {
	shopifyId?:string
};
	["StoreProductVariantsByStorefrontIdUniqueWhere"]: {
	storefrontId?:string
};
	["StoreUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	code?:string,
	products?:ValueTypes["ProductStoreUniqueWhere"],
	productVariants?:ValueTypes["ProductVariantStoreUniqueWhere"]
};
	["StoreUpdateInput"]: {
	code?:string,
	shopifyDomain?:string,
	products?:ValueTypes["StoreUpdateProductsEntityRelationInput"][],
	productVariants?:ValueTypes["StoreUpdateProductVariantsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["StoreUpdateProductsEntityRelationInput"]: {
	create?:ValueTypes["ProductStoreWithoutStoreCreateInput"],
	update?:ValueTypes["StoreUpdateProductsRelationInput"],
	upsert?:ValueTypes["StoreUpsertProductsRelationInput"],
	connect?:ValueTypes["ProductStoreUniqueWhere"],
	disconnect?:ValueTypes["ProductStoreUniqueWhere"],
	delete?:ValueTypes["ProductStoreUniqueWhere"],
	alias?:string
};
	["StoreUpdateProductsRelationInput"]: {
	by?:ValueTypes["ProductStoreUniqueWhere"],
	data?:ValueTypes["ProductStoreWithoutStoreUpdateInput"]
};
	["StoreUpdateProductVariantsEntityRelationInput"]: {
	create?:ValueTypes["ProductVariantStoreWithoutStoreCreateInput"],
	update?:ValueTypes["StoreUpdateProductVariantsRelationInput"],
	upsert?:ValueTypes["StoreUpsertProductVariantsRelationInput"],
	connect?:ValueTypes["ProductVariantStoreUniqueWhere"],
	disconnect?:ValueTypes["ProductVariantStoreUniqueWhere"],
	delete?:ValueTypes["ProductVariantStoreUniqueWhere"],
	alias?:string
};
	["StoreUpdateProductVariantsRelationInput"]: {
	by?:ValueTypes["ProductVariantStoreUniqueWhere"],
	data?:ValueTypes["ProductVariantStoreWithoutStoreUpdateInput"]
};
	["StoreUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["Store"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["StoreUpsertProductsRelationInput"]: {
	by?:ValueTypes["ProductStoreUniqueWhere"],
	update?:ValueTypes["ProductStoreWithoutStoreUpdateInput"],
	create?:ValueTypes["ProductStoreWithoutStoreCreateInput"]
};
	["StoreUpsertProductVariantsRelationInput"]: {
	by?:ValueTypes["ProductVariantStoreUniqueWhere"],
	update?:ValueTypes["ProductVariantStoreWithoutStoreUpdateInput"],
	create?:ValueTypes["ProductVariantStoreWithoutStoreCreateInput"]
};
	["StoreWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	code?:ValueTypes["StringCondition"],
	shopifyDomain?:ValueTypes["StringCondition"],
	products?:ValueTypes["ProductStoreWhere"],
	productVariants?:ValueTypes["ProductVariantStoreWhere"],
	and?:ValueTypes["StoreWhere"][],
	or?:ValueTypes["StoreWhere"][],
	not?:ValueTypes["StoreWhere"]
};
	["StoreWithoutProductsCreateInput"]: {
	code?:string,
	shopifyDomain?:string,
	productVariants?:ValueTypes["StoreCreateProductVariantsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["StoreWithoutProductsUpdateInput"]: {
	code?:string,
	shopifyDomain?:string,
	productVariants?:ValueTypes["StoreUpdateProductVariantsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["StoreWithoutProductVariantsCreateInput"]: {
	code?:string,
	shopifyDomain?:string,
	products?:ValueTypes["StoreCreateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["StoreWithoutProductVariantsUpdateInput"]: {
	code?:string,
	shopifyDomain?:string,
	products?:ValueTypes["StoreUpdateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["StringCondition"]: {
	and?:ValueTypes["StringCondition"][],
	or?:ValueTypes["StringCondition"][],
	not?:ValueTypes["StringCondition"],
	eq?:string,
	null?:boolean,
	isNull?:boolean,
	notEq?:string,
	in?:string[],
	notIn?:string[],
	lt?:string,
	lte?:string,
	gt?:string,
	gte?:string,
	contains?:string,
	startsWith?:string,
	endsWith?:string,
	containsCI?:string,
	startsWithCI?:string,
	endsWithCI?:string
};
	["TranslationCatalogue"]: AliasType<{
	_meta?:ValueTypes["TranslationCatalogueMeta"],
	id?:true,
	identifier?:true,
	name?:true,
domain?: [{	filter?:ValueTypes["TranslationDomainWhere"]},ValueTypes["TranslationDomain"]],
fallback?: [{	filter?:ValueTypes["TranslationCatalogueWhere"]},ValueTypes["TranslationCatalogue"]],
values?: [{	filter?:ValueTypes["TranslationValueWhere"],	orderBy?:ValueTypes["TranslationValueOrderBy"][],	offset?:number,	limit?:number},ValueTypes["TranslationValue"]],
valuesByKey?: [{	by:ValueTypes["TranslationCatalogueValuesByKeyUniqueWhere"],	filter?:ValueTypes["TranslationValueWhere"]},ValueTypes["TranslationValue"]],
		__typename?: true
}>;
	["TranslationCatalogueConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationCatalogueEdge"],
		__typename?: true
}>;
	["TranslationCatalogueCreateDomainEntityRelationInput"]: {
	connect?:ValueTypes["TranslationDomainUniqueWhere"],
	create?:ValueTypes["TranslationDomainWithoutCataloguesCreateInput"]
};
	["TranslationCatalogueCreateFallbackEntityRelationInput"]: {
	connect?:ValueTypes["TranslationCatalogueUniqueWhere"],
	create?:ValueTypes["TranslationCatalogueCreateInput"]
};
	["TranslationCatalogueCreateInput"]: {
	identifier?:string,
	name?:string,
	domain?:ValueTypes["TranslationCatalogueCreateDomainEntityRelationInput"],
	fallback?:ValueTypes["TranslationCatalogueCreateFallbackEntityRelationInput"],
	values?:ValueTypes["TranslationCatalogueCreateValuesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["TranslationCatalogueCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["TranslationCatalogue"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["TranslationCatalogueCreateValuesEntityRelationInput"]: {
	connect?:ValueTypes["TranslationValueUniqueWhere"],
	create?:ValueTypes["TranslationValueWithoutCatalogueCreateInput"],
	alias?:string
};
	["TranslationCatalogueDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["TranslationCatalogue"],
		__typename?: true
}>;
	["TranslationCatalogueEdge"]: AliasType<{
	node?:ValueTypes["TranslationCatalogue"],
		__typename?: true
}>;
	["TranslationCatalogueMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	domain?:ValueTypes["FieldMeta"],
	fallback?:ValueTypes["FieldMeta"],
	values?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["TranslationCatalogueOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	identifier?:ValueTypes["OrderDirection"],
	name?:ValueTypes["OrderDirection"],
	domain?:ValueTypes["TranslationDomainOrderBy"],
	fallback?:ValueTypes["TranslationCatalogueOrderBy"]
};
	["TranslationCatalogueUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	domain?:ValueTypes["TranslationDomainUniqueWhere"],
	identifier?:string,
	values?:ValueTypes["TranslationValueUniqueWhere"]
};
	["TranslationCatalogueUpdateDomainEntityRelationInput"]: {
	create?:ValueTypes["TranslationDomainWithoutCataloguesCreateInput"],
	update?:ValueTypes["TranslationDomainWithoutCataloguesUpdateInput"],
	upsert?:ValueTypes["TranslationCatalogueUpsertDomainRelationInput"],
	connect?:ValueTypes["TranslationDomainUniqueWhere"]
};
	["TranslationCatalogueUpdateFallbackEntityRelationInput"]: {
	create?:ValueTypes["TranslationCatalogueCreateInput"],
	update?:ValueTypes["TranslationCatalogueUpdateInput"],
	upsert?:ValueTypes["TranslationCatalogueUpsertFallbackRelationInput"],
	connect?:ValueTypes["TranslationCatalogueUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
};
	["TranslationCatalogueUpdateInput"]: {
	identifier?:string,
	name?:string,
	domain?:ValueTypes["TranslationCatalogueUpdateDomainEntityRelationInput"],
	fallback?:ValueTypes["TranslationCatalogueUpdateFallbackEntityRelationInput"],
	values?:ValueTypes["TranslationCatalogueUpdateValuesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["TranslationCatalogueUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["TranslationCatalogue"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["TranslationCatalogueUpdateValuesEntityRelationInput"]: {
	create?:ValueTypes["TranslationValueWithoutCatalogueCreateInput"],
	update?:ValueTypes["TranslationCatalogueUpdateValuesRelationInput"],
	upsert?:ValueTypes["TranslationCatalogueUpsertValuesRelationInput"],
	connect?:ValueTypes["TranslationValueUniqueWhere"],
	disconnect?:ValueTypes["TranslationValueUniqueWhere"],
	delete?:ValueTypes["TranslationValueUniqueWhere"],
	alias?:string
};
	["TranslationCatalogueUpdateValuesRelationInput"]: {
	by?:ValueTypes["TranslationValueUniqueWhere"],
	data?:ValueTypes["TranslationValueWithoutCatalogueUpdateInput"]
};
	["TranslationCatalogueUpsertDomainRelationInput"]: {
	update?:ValueTypes["TranslationDomainWithoutCataloguesUpdateInput"],
	create?:ValueTypes["TranslationDomainWithoutCataloguesCreateInput"]
};
	["TranslationCatalogueUpsertFallbackRelationInput"]: {
	update?:ValueTypes["TranslationCatalogueUpdateInput"],
	create?:ValueTypes["TranslationCatalogueCreateInput"]
};
	["TranslationCatalogueUpsertValuesRelationInput"]: {
	by?:ValueTypes["TranslationValueUniqueWhere"],
	update?:ValueTypes["TranslationValueWithoutCatalogueUpdateInput"],
	create?:ValueTypes["TranslationValueWithoutCatalogueCreateInput"]
};
	["TranslationCatalogueValuesByKeyUniqueWhere"]: {
	key?:ValueTypes["TranslationKeyUniqueWhere"]
};
	["TranslationCatalogueWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	identifier?:ValueTypes["StringCondition"],
	name?:ValueTypes["StringCondition"],
	domain?:ValueTypes["TranslationDomainWhere"],
	fallback?:ValueTypes["TranslationCatalogueWhere"],
	values?:ValueTypes["TranslationValueWhere"],
	and?:ValueTypes["TranslationCatalogueWhere"][],
	or?:ValueTypes["TranslationCatalogueWhere"][],
	not?:ValueTypes["TranslationCatalogueWhere"]
};
	["TranslationCatalogueWithoutDomainCreateInput"]: {
	identifier?:string,
	name?:string,
	fallback?:ValueTypes["TranslationCatalogueCreateFallbackEntityRelationInput"],
	values?:ValueTypes["TranslationCatalogueCreateValuesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["TranslationCatalogueWithoutDomainUpdateInput"]: {
	identifier?:string,
	name?:string,
	fallback?:ValueTypes["TranslationCatalogueUpdateFallbackEntityRelationInput"],
	values?:ValueTypes["TranslationCatalogueUpdateValuesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["TranslationCatalogueWithoutValuesCreateInput"]: {
	identifier?:string,
	name?:string,
	domain?:ValueTypes["TranslationCatalogueCreateDomainEntityRelationInput"],
	fallback?:ValueTypes["TranslationCatalogueCreateFallbackEntityRelationInput"],
	_dummy_field_?:boolean
};
	["TranslationCatalogueWithoutValuesUpdateInput"]: {
	identifier?:string,
	name?:string,
	domain?:ValueTypes["TranslationCatalogueUpdateDomainEntityRelationInput"],
	fallback?:ValueTypes["TranslationCatalogueUpdateFallbackEntityRelationInput"],
	_dummy_field_?:boolean
};
	["TranslationContentType"]:TranslationContentType;
	["TranslationContentTypeEnumCondition"]: {
	and?:ValueTypes["TranslationContentTypeEnumCondition"][],
	or?:ValueTypes["TranslationContentTypeEnumCondition"][],
	not?:ValueTypes["TranslationContentTypeEnumCondition"],
	eq?:ValueTypes["TranslationContentType"],
	null?:boolean,
	isNull?:boolean,
	notEq?:ValueTypes["TranslationContentType"],
	in?:ValueTypes["TranslationContentType"][],
	notIn?:ValueTypes["TranslationContentType"][],
	lt?:ValueTypes["TranslationContentType"],
	lte?:ValueTypes["TranslationContentType"],
	gt?:ValueTypes["TranslationContentType"],
	gte?:ValueTypes["TranslationContentType"]
};
	["TranslationDomain"]: AliasType<{
	_meta?:ValueTypes["TranslationDomainMeta"],
	id?:true,
	identifier?:true,
	name?:true,
catalogues?: [{	filter?:ValueTypes["TranslationCatalogueWhere"],	orderBy?:ValueTypes["TranslationCatalogueOrderBy"][],	offset?:number,	limit?:number},ValueTypes["TranslationCatalogue"]],
keys?: [{	filter?:ValueTypes["TranslationKeyWhere"],	orderBy?:ValueTypes["TranslationKeyOrderBy"][],	offset?:number,	limit?:number},ValueTypes["TranslationKey"]],
cataloguesByIdentifier?: [{	by:ValueTypes["TranslationDomainCataloguesByIdentifierUniqueWhere"],	filter?:ValueTypes["TranslationCatalogueWhere"]},ValueTypes["TranslationCatalogue"]],
cataloguesByValues?: [{	by:ValueTypes["TranslationDomainCataloguesByValuesUniqueWhere"],	filter?:ValueTypes["TranslationCatalogueWhere"]},ValueTypes["TranslationCatalogue"]],
keysByIdentifier?: [{	by:ValueTypes["TranslationDomainKeysByIdentifierUniqueWhere"],	filter?:ValueTypes["TranslationKeyWhere"]},ValueTypes["TranslationKey"]],
keysByValues?: [{	by:ValueTypes["TranslationDomainKeysByValuesUniqueWhere"],	filter?:ValueTypes["TranslationKeyWhere"]},ValueTypes["TranslationKey"]],
		__typename?: true
}>;
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
	identifier?:string
};
	["TranslationDomainCataloguesByValuesUniqueWhere"]: {
	values?:ValueTypes["TranslationValueUniqueWhere"]
};
	["TranslationDomainConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationDomainEdge"],
		__typename?: true
}>;
	["TranslationDomainCreateCataloguesEntityRelationInput"]: {
	connect?:ValueTypes["TranslationCatalogueUniqueWhere"],
	create?:ValueTypes["TranslationCatalogueWithoutDomainCreateInput"],
	alias?:string
};
	["TranslationDomainCreateInput"]: {
	identifier?:string,
	name?:string,
	catalogues?:ValueTypes["TranslationDomainCreateCataloguesEntityRelationInput"][],
	keys?:ValueTypes["TranslationDomainCreateKeysEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["TranslationDomainCreateKeysEntityRelationInput"]: {
	connect?:ValueTypes["TranslationKeyUniqueWhere"],
	create?:ValueTypes["TranslationKeyWithoutDomainCreateInput"],
	alias?:string
};
	["TranslationDomainCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["TranslationDomain"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["TranslationDomainDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["TranslationDomain"],
		__typename?: true
}>;
	["TranslationDomainEdge"]: AliasType<{
	node?:ValueTypes["TranslationDomain"],
		__typename?: true
}>;
	["TranslationDomainKeysByIdentifierUniqueWhere"]: {
	identifier?:string
};
	["TranslationDomainKeysByValuesUniqueWhere"]: {
	values?:ValueTypes["TranslationValueUniqueWhere"]
};
	["TranslationDomainMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	name?:ValueTypes["FieldMeta"],
	catalogues?:ValueTypes["FieldMeta"],
	keys?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["TranslationDomainOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	identifier?:ValueTypes["OrderDirection"],
	name?:ValueTypes["OrderDirection"]
};
	["TranslationDomainUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	identifier?:string,
	catalogues?:ValueTypes["TranslationCatalogueUniqueWhere"],
	keys?:ValueTypes["TranslationKeyUniqueWhere"]
};
	["TranslationDomainUpdateCataloguesEntityRelationInput"]: {
	create?:ValueTypes["TranslationCatalogueWithoutDomainCreateInput"],
	update?:ValueTypes["TranslationDomainUpdateCataloguesRelationInput"],
	upsert?:ValueTypes["TranslationDomainUpsertCataloguesRelationInput"],
	connect?:ValueTypes["TranslationCatalogueUniqueWhere"],
	disconnect?:ValueTypes["TranslationCatalogueUniqueWhere"],
	delete?:ValueTypes["TranslationCatalogueUniqueWhere"],
	alias?:string
};
	["TranslationDomainUpdateCataloguesRelationInput"]: {
	by?:ValueTypes["TranslationCatalogueUniqueWhere"],
	data?:ValueTypes["TranslationCatalogueWithoutDomainUpdateInput"]
};
	["TranslationDomainUpdateInput"]: {
	identifier?:string,
	name?:string,
	catalogues?:ValueTypes["TranslationDomainUpdateCataloguesEntityRelationInput"][],
	keys?:ValueTypes["TranslationDomainUpdateKeysEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["TranslationDomainUpdateKeysEntityRelationInput"]: {
	create?:ValueTypes["TranslationKeyWithoutDomainCreateInput"],
	update?:ValueTypes["TranslationDomainUpdateKeysRelationInput"],
	upsert?:ValueTypes["TranslationDomainUpsertKeysRelationInput"],
	connect?:ValueTypes["TranslationKeyUniqueWhere"],
	disconnect?:ValueTypes["TranslationKeyUniqueWhere"],
	delete?:ValueTypes["TranslationKeyUniqueWhere"],
	alias?:string
};
	["TranslationDomainUpdateKeysRelationInput"]: {
	by?:ValueTypes["TranslationKeyUniqueWhere"],
	data?:ValueTypes["TranslationKeyWithoutDomainUpdateInput"]
};
	["TranslationDomainUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["TranslationDomain"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["TranslationDomainUpsertCataloguesRelationInput"]: {
	by?:ValueTypes["TranslationCatalogueUniqueWhere"],
	update?:ValueTypes["TranslationCatalogueWithoutDomainUpdateInput"],
	create?:ValueTypes["TranslationCatalogueWithoutDomainCreateInput"]
};
	["TranslationDomainUpsertKeysRelationInput"]: {
	by?:ValueTypes["TranslationKeyUniqueWhere"],
	update?:ValueTypes["TranslationKeyWithoutDomainUpdateInput"],
	create?:ValueTypes["TranslationKeyWithoutDomainCreateInput"]
};
	["TranslationDomainWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	identifier?:ValueTypes["StringCondition"],
	name?:ValueTypes["StringCondition"],
	catalogues?:ValueTypes["TranslationCatalogueWhere"],
	keys?:ValueTypes["TranslationKeyWhere"],
	and?:ValueTypes["TranslationDomainWhere"][],
	or?:ValueTypes["TranslationDomainWhere"][],
	not?:ValueTypes["TranslationDomainWhere"]
};
	["TranslationDomainWithoutCataloguesCreateInput"]: {
	identifier?:string,
	name?:string,
	keys?:ValueTypes["TranslationDomainCreateKeysEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["TranslationDomainWithoutCataloguesUpdateInput"]: {
	identifier?:string,
	name?:string,
	keys?:ValueTypes["TranslationDomainUpdateKeysEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["TranslationDomainWithoutKeysCreateInput"]: {
	identifier?:string,
	name?:string,
	catalogues?:ValueTypes["TranslationDomainCreateCataloguesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["TranslationDomainWithoutKeysUpdateInput"]: {
	identifier?:string,
	name?:string,
	catalogues?:ValueTypes["TranslationDomainUpdateCataloguesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["TranslationKey"]: AliasType<{
	_meta?:ValueTypes["TranslationKeyMeta"],
	id?:true,
	identifier?:true,
	contentType?:true,
	note?:true,
domain?: [{	filter?:ValueTypes["TranslationDomainWhere"]},ValueTypes["TranslationDomain"]],
values?: [{	filter?:ValueTypes["TranslationValueWhere"],	orderBy?:ValueTypes["TranslationValueOrderBy"][],	offset?:number,	limit?:number},ValueTypes["TranslationValue"]],
valuesByCatalogue?: [{	by:ValueTypes["TranslationKeyValuesByCatalogueUniqueWhere"],	filter?:ValueTypes["TranslationValueWhere"]},ValueTypes["TranslationValue"]],
		__typename?: true
}>;
	["TranslationKeyConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationKeyEdge"],
		__typename?: true
}>;
	["TranslationKeyCreateDomainEntityRelationInput"]: {
	connect?:ValueTypes["TranslationDomainUniqueWhere"],
	create?:ValueTypes["TranslationDomainWithoutKeysCreateInput"]
};
	["TranslationKeyCreateInput"]: {
	identifier?:string,
	contentType?:ValueTypes["TranslationContentType"],
	note?:string,
	domain?:ValueTypes["TranslationKeyCreateDomainEntityRelationInput"],
	values?:ValueTypes["TranslationKeyCreateValuesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["TranslationKeyCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["TranslationKey"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["TranslationKeyCreateValuesEntityRelationInput"]: {
	connect?:ValueTypes["TranslationValueUniqueWhere"],
	create?:ValueTypes["TranslationValueWithoutKeyCreateInput"],
	alias?:string
};
	["TranslationKeyDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["TranslationKey"],
		__typename?: true
}>;
	["TranslationKeyEdge"]: AliasType<{
	node?:ValueTypes["TranslationKey"],
		__typename?: true
}>;
	["TranslationKeyMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	identifier?:ValueTypes["FieldMeta"],
	contentType?:ValueTypes["FieldMeta"],
	note?:ValueTypes["FieldMeta"],
	domain?:ValueTypes["FieldMeta"],
	values?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["TranslationKeyOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	identifier?:ValueTypes["OrderDirection"],
	contentType?:ValueTypes["OrderDirection"],
	note?:ValueTypes["OrderDirection"],
	domain?:ValueTypes["TranslationDomainOrderBy"]
};
	["TranslationKeyUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	domain?:ValueTypes["TranslationDomainUniqueWhere"],
	identifier?:string,
	values?:ValueTypes["TranslationValueUniqueWhere"]
};
	["TranslationKeyUpdateDomainEntityRelationInput"]: {
	create?:ValueTypes["TranslationDomainWithoutKeysCreateInput"],
	update?:ValueTypes["TranslationDomainWithoutKeysUpdateInput"],
	upsert?:ValueTypes["TranslationKeyUpsertDomainRelationInput"],
	connect?:ValueTypes["TranslationDomainUniqueWhere"]
};
	["TranslationKeyUpdateInput"]: {
	identifier?:string,
	contentType?:ValueTypes["TranslationContentType"],
	note?:string,
	domain?:ValueTypes["TranslationKeyUpdateDomainEntityRelationInput"],
	values?:ValueTypes["TranslationKeyUpdateValuesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["TranslationKeyUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["TranslationKey"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["TranslationKeyUpdateValuesEntityRelationInput"]: {
	create?:ValueTypes["TranslationValueWithoutKeyCreateInput"],
	update?:ValueTypes["TranslationKeyUpdateValuesRelationInput"],
	upsert?:ValueTypes["TranslationKeyUpsertValuesRelationInput"],
	connect?:ValueTypes["TranslationValueUniqueWhere"],
	disconnect?:ValueTypes["TranslationValueUniqueWhere"],
	delete?:ValueTypes["TranslationValueUniqueWhere"],
	alias?:string
};
	["TranslationKeyUpdateValuesRelationInput"]: {
	by?:ValueTypes["TranslationValueUniqueWhere"],
	data?:ValueTypes["TranslationValueWithoutKeyUpdateInput"]
};
	["TranslationKeyUpsertDomainRelationInput"]: {
	update?:ValueTypes["TranslationDomainWithoutKeysUpdateInput"],
	create?:ValueTypes["TranslationDomainWithoutKeysCreateInput"]
};
	["TranslationKeyUpsertValuesRelationInput"]: {
	by?:ValueTypes["TranslationValueUniqueWhere"],
	update?:ValueTypes["TranslationValueWithoutKeyUpdateInput"],
	create?:ValueTypes["TranslationValueWithoutKeyCreateInput"]
};
	["TranslationKeyValuesByCatalogueUniqueWhere"]: {
	catalogue?:ValueTypes["TranslationCatalogueUniqueWhere"]
};
	["TranslationKeyWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	identifier?:ValueTypes["StringCondition"],
	contentType?:ValueTypes["TranslationContentTypeEnumCondition"],
	note?:ValueTypes["StringCondition"],
	domain?:ValueTypes["TranslationDomainWhere"],
	values?:ValueTypes["TranslationValueWhere"],
	and?:ValueTypes["TranslationKeyWhere"][],
	or?:ValueTypes["TranslationKeyWhere"][],
	not?:ValueTypes["TranslationKeyWhere"]
};
	["TranslationKeyWithoutDomainCreateInput"]: {
	identifier?:string,
	contentType?:ValueTypes["TranslationContentType"],
	note?:string,
	values?:ValueTypes["TranslationKeyCreateValuesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["TranslationKeyWithoutDomainUpdateInput"]: {
	identifier?:string,
	contentType?:ValueTypes["TranslationContentType"],
	note?:string,
	values?:ValueTypes["TranslationKeyUpdateValuesEntityRelationInput"][],
	_dummy_field_?:boolean
};
	["TranslationKeyWithoutValuesCreateInput"]: {
	identifier?:string,
	contentType?:ValueTypes["TranslationContentType"],
	note?:string,
	domain?:ValueTypes["TranslationKeyCreateDomainEntityRelationInput"],
	_dummy_field_?:boolean
};
	["TranslationKeyWithoutValuesUpdateInput"]: {
	identifier?:string,
	contentType?:ValueTypes["TranslationContentType"],
	note?:string,
	domain?:ValueTypes["TranslationKeyUpdateDomainEntityRelationInput"],
	_dummy_field_?:boolean
};
	["TranslationValue"]: AliasType<{
	_meta?:ValueTypes["TranslationValueMeta"],
	id?:true,
	value?:true,
catalogue?: [{	filter?:ValueTypes["TranslationCatalogueWhere"]},ValueTypes["TranslationCatalogue"]],
key?: [{	filter?:ValueTypes["TranslationKeyWhere"]},ValueTypes["TranslationKey"]],
		__typename?: true
}>;
	["TranslationValueConnection"]: AliasType<{
	pageInfo?:ValueTypes["PageInfo"],
	edges?:ValueTypes["TranslationValueEdge"],
		__typename?: true
}>;
	["TranslationValueCreateCatalogueEntityRelationInput"]: {
	connect?:ValueTypes["TranslationCatalogueUniqueWhere"],
	create?:ValueTypes["TranslationCatalogueWithoutValuesCreateInput"]
};
	["TranslationValueCreateInput"]: {
	value?:string,
	catalogue?:ValueTypes["TranslationValueCreateCatalogueEntityRelationInput"],
	key?:ValueTypes["TranslationValueCreateKeyEntityRelationInput"],
	_dummy_field_?:boolean
};
	["TranslationValueCreateKeyEntityRelationInput"]: {
	connect?:ValueTypes["TranslationKeyUniqueWhere"],
	create?:ValueTypes["TranslationKeyWithoutValuesCreateInput"]
};
	["TranslationValueCreateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["TranslationValue"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["TranslationValueDeleteResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["TranslationValue"],
		__typename?: true
}>;
	["TranslationValueEdge"]: AliasType<{
	node?:ValueTypes["TranslationValue"],
		__typename?: true
}>;
	["TranslationValueMeta"]: AliasType<{
	id?:ValueTypes["FieldMeta"],
	value?:ValueTypes["FieldMeta"],
	catalogue?:ValueTypes["FieldMeta"],
	key?:ValueTypes["FieldMeta"],
		__typename?: true
}>;
	["TranslationValueOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:ValueTypes["OrderDirection"],
	value?:ValueTypes["OrderDirection"],
	catalogue?:ValueTypes["TranslationCatalogueOrderBy"],
	key?:ValueTypes["TranslationKeyOrderBy"]
};
	["TranslationValueUniqueWhere"]: {
	id?:ValueTypes["UUID"],
	catalogue?:ValueTypes["TranslationCatalogueUniqueWhere"],
	key?:ValueTypes["TranslationKeyUniqueWhere"]
};
	["TranslationValueUpdateCatalogueEntityRelationInput"]: {
	create?:ValueTypes["TranslationCatalogueWithoutValuesCreateInput"],
	update?:ValueTypes["TranslationCatalogueWithoutValuesUpdateInput"],
	upsert?:ValueTypes["TranslationValueUpsertCatalogueRelationInput"],
	connect?:ValueTypes["TranslationCatalogueUniqueWhere"]
};
	["TranslationValueUpdateInput"]: {
	value?:string,
	catalogue?:ValueTypes["TranslationValueUpdateCatalogueEntityRelationInput"],
	key?:ValueTypes["TranslationValueUpdateKeyEntityRelationInput"],
	_dummy_field_?:boolean
};
	["TranslationValueUpdateKeyEntityRelationInput"]: {
	create?:ValueTypes["TranslationKeyWithoutValuesCreateInput"],
	update?:ValueTypes["TranslationKeyWithoutValuesUpdateInput"],
	upsert?:ValueTypes["TranslationValueUpsertKeyRelationInput"],
	connect?:ValueTypes["TranslationKeyUniqueWhere"]
};
	["TranslationValueUpdateResult"]: AliasType<{
	ok?:true,
	errorMessage?:true,
	errors?:ValueTypes["_MutationError"],
	node?:ValueTypes["TranslationValue"],
	validation?:ValueTypes["_ValidationResult"],
		__typename?: true
}>;
	["TranslationValueUpsertCatalogueRelationInput"]: {
	update?:ValueTypes["TranslationCatalogueWithoutValuesUpdateInput"],
	create?:ValueTypes["TranslationCatalogueWithoutValuesCreateInput"]
};
	["TranslationValueUpsertKeyRelationInput"]: {
	update?:ValueTypes["TranslationKeyWithoutValuesUpdateInput"],
	create?:ValueTypes["TranslationKeyWithoutValuesCreateInput"]
};
	["TranslationValueWhere"]: {
	id?:ValueTypes["UUIDCondition"],
	value?:ValueTypes["StringCondition"],
	catalogue?:ValueTypes["TranslationCatalogueWhere"],
	key?:ValueTypes["TranslationKeyWhere"],
	and?:ValueTypes["TranslationValueWhere"][],
	or?:ValueTypes["TranslationValueWhere"][],
	not?:ValueTypes["TranslationValueWhere"]
};
	["TranslationValueWithoutCatalogueCreateInput"]: {
	value?:string,
	key?:ValueTypes["TranslationValueCreateKeyEntityRelationInput"],
	_dummy_field_?:boolean
};
	["TranslationValueWithoutCatalogueUpdateInput"]: {
	value?:string,
	key?:ValueTypes["TranslationValueUpdateKeyEntityRelationInput"],
	_dummy_field_?:boolean
};
	["TranslationValueWithoutKeyCreateInput"]: {
	value?:string,
	catalogue?:ValueTypes["TranslationValueCreateCatalogueEntityRelationInput"],
	_dummy_field_?:boolean
};
	["TranslationValueWithoutKeyUpdateInput"]: {
	value?:string,
	catalogue?:ValueTypes["TranslationValueUpdateCatalogueEntityRelationInput"],
	_dummy_field_?:boolean
};
	["UUID"]:unknown;
	["UUIDCondition"]: {
	and?:ValueTypes["UUIDCondition"][],
	or?:ValueTypes["UUIDCondition"][],
	not?:ValueTypes["UUIDCondition"],
	eq?:ValueTypes["UUID"],
	null?:boolean,
	isNull?:boolean,
	notEq?:ValueTypes["UUID"],
	in?:ValueTypes["UUID"][],
	notIn?:ValueTypes["UUID"][],
	lt?:ValueTypes["UUID"],
	lte?:ValueTypes["UUID"],
	gt?:ValueTypes["UUID"],
	gte?:ValueTypes["UUID"]
}
  }

export type PartialObjects = {
    ["_AnyValue"]: PartialObjects["_IntValue"] | PartialObjects["_StringValue"] | PartialObjects["_BooleanValue"] | PartialObjects["_FloatValue"] | PartialObjects["_UndefinedValue"],
	["_Argument"]: PartialObjects["_ValidatorArgument"] | PartialObjects["_PathArgument"] | PartialObjects["_LiteralArgument"],
	["_BooleanValue"]: {
		__typename?: "_BooleanValue";
			booleanValue?:boolean
	},
	["_Entity"]: {
		__typename?: "_Entity";
			name?:string,
			fields?:PartialObjects["_Field"][]
	},
	["_Enum"]: {
		__typename?: "_Enum";
			name?:string,
			values?:string[]
	},
	["_Field"]: {
		__typename?: "_Field";
			name?:string,
			type?:string,
			rules?:PartialObjects["_Rule"][],
			validators?:PartialObjects["_Validator"][]
	},
	["_FieldPathFragment"]: {
		__typename?: "_FieldPathFragment";
			field?:string
	},
	["_FloatValue"]: {
		__typename?: "_FloatValue";
			floatValue?:number
	},
	["_IndexPathFragment"]: {
		__typename?: "_IndexPathFragment";
			index?:number,
			alias?:string
	},
	["_IntValue"]: {
		__typename?: "_IntValue";
			intValue?:number
	},
	["_LiteralArgument"]: {
		__typename?: "_LiteralArgument";
			value?:PartialObjects["_AnyValue"]
	},
	["_MutationError"]: {
		__typename?: "_MutationError";
			path?:PartialObjects["_PathFragment"][],
			type?:PartialObjects["_MutationErrorType"],
			message?:string
	},
	["_MutationErrorType"]:_MutationErrorType,
	["_PathArgument"]: {
		__typename?: "_PathArgument";
			path?:string[]
	},
	["_PathFragment"]: PartialObjects["_FieldPathFragment"] | PartialObjects["_IndexPathFragment"],
	["_Rule"]: {
		__typename?: "_Rule";
			message?:PartialObjects["_RuleMessage"],
			validator?:number
	},
	["_RuleMessage"]: {
		__typename?: "_RuleMessage";
			text?:string
	},
	["_Schema"]: {
		__typename?: "_Schema";
			enums?:PartialObjects["_Enum"][],
			entities?:PartialObjects["_Entity"][]
	},
	["_StringValue"]: {
		__typename?: "_StringValue";
			stringValue?:string
	},
	["_UndefinedValue"]: {
		__typename?: "_UndefinedValue";
			undefinedValue?:boolean
	},
	["_ValidationError"]: {
		__typename?: "_ValidationError";
			path?:PartialObjects["_PathFragment"][],
			message?:PartialObjects["_ValidationMessage"]
	},
	["_ValidationMessage"]: {
		__typename?: "_ValidationMessage";
			text?:string
	},
	["_ValidationResult"]: {
		__typename?: "_ValidationResult";
			valid?:boolean,
			errors?:PartialObjects["_ValidationError"][]
	},
	["_Validator"]: {
		__typename?: "_Validator";
			operation?:string,
			arguments?:PartialObjects["_Argument"][]
	},
	["_ValidatorArgument"]: {
		__typename?: "_ValidatorArgument";
			validator?:number
	},
	["AbandonedCheckoutNotification"]: {
		__typename?: "AbandonedCheckoutNotification";
			_meta?:PartialObjects["AbandonedCheckoutNotificationMeta"],
			id?:PartialObjects["UUID"],
			createdAt?:PartialObjects["DateTime"],
			sentAt?:PartialObjects["DateTime"],
			tryCount?:number,
			store?:string,
			shopifyId?:string,
			orderJson?:string,
			metadata?:string,
			status?:string
	},
	["AbandonedCheckoutNotificationConnection"]: {
		__typename?: "AbandonedCheckoutNotificationConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["AbandonedCheckoutNotificationEdge"][]
	},
	["AbandonedCheckoutNotificationCreateInput"]: {
	createdAt?:PartialObjects["DateTime"],
	sentAt?:PartialObjects["DateTime"],
	tryCount?:number,
	store?:string,
	shopifyId?:string,
	orderJson?:string,
	metadata?:string,
	status?:string,
	_dummy_field_?:boolean
},
	["AbandonedCheckoutNotificationCreateResult"]: {
		__typename?: "AbandonedCheckoutNotificationCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["AbandonedCheckoutNotification"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["AbandonedCheckoutNotificationDeleteResult"]: {
		__typename?: "AbandonedCheckoutNotificationDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["AbandonedCheckoutNotification"]
	},
	["AbandonedCheckoutNotificationEdge"]: {
		__typename?: "AbandonedCheckoutNotificationEdge";
			node?:PartialObjects["AbandonedCheckoutNotification"]
	},
	["AbandonedCheckoutNotificationMeta"]: {
		__typename?: "AbandonedCheckoutNotificationMeta";
			id?:PartialObjects["FieldMeta"],
			createdAt?:PartialObjects["FieldMeta"],
			sentAt?:PartialObjects["FieldMeta"],
			tryCount?:PartialObjects["FieldMeta"],
			store?:PartialObjects["FieldMeta"],
			shopifyId?:PartialObjects["FieldMeta"],
			orderJson?:PartialObjects["FieldMeta"],
			metadata?:PartialObjects["FieldMeta"],
			status?:PartialObjects["FieldMeta"]
	},
	["AbandonedCheckoutNotificationOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	createdAt?:PartialObjects["OrderDirection"],
	sentAt?:PartialObjects["OrderDirection"],
	tryCount?:PartialObjects["OrderDirection"],
	store?:PartialObjects["OrderDirection"],
	shopifyId?:PartialObjects["OrderDirection"],
	orderJson?:PartialObjects["OrderDirection"],
	metadata?:PartialObjects["OrderDirection"],
	status?:PartialObjects["OrderDirection"]
},
	["AbandonedCheckoutNotificationUniqueWhere"]: {
	id?:PartialObjects["UUID"]
},
	["AbandonedCheckoutNotificationUpdateInput"]: {
	createdAt?:PartialObjects["DateTime"],
	sentAt?:PartialObjects["DateTime"],
	tryCount?:number,
	store?:string,
	shopifyId?:string,
	orderJson?:string,
	metadata?:string,
	status?:string,
	_dummy_field_?:boolean
},
	["AbandonedCheckoutNotificationUpdateResult"]: {
		__typename?: "AbandonedCheckoutNotificationUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["AbandonedCheckoutNotification"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["AbandonedCheckoutNotificationWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	createdAt?:PartialObjects["DateTimeCondition"],
	sentAt?:PartialObjects["DateTimeCondition"],
	tryCount?:PartialObjects["IntCondition"],
	store?:PartialObjects["StringCondition"],
	shopifyId?:PartialObjects["StringCondition"],
	orderJson?:PartialObjects["StringCondition"],
	metadata?:PartialObjects["StringCondition"],
	status?:PartialObjects["StringCondition"],
	and?:PartialObjects["AbandonedCheckoutNotificationWhere"][],
	or?:PartialObjects["AbandonedCheckoutNotificationWhere"][],
	not?:PartialObjects["AbandonedCheckoutNotificationWhere"]
},
	["AbandonedCheckoutUnsubscription"]: {
		__typename?: "AbandonedCheckoutUnsubscription";
			_meta?:PartialObjects["AbandonedCheckoutUnsubscriptionMeta"],
			id?:PartialObjects["UUID"],
			createdAt?:PartialObjects["DateTime"],
			canceledAt?:PartialObjects["DateTime"],
			email?:string
	},
	["AbandonedCheckoutUnsubscriptionConnection"]: {
		__typename?: "AbandonedCheckoutUnsubscriptionConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["AbandonedCheckoutUnsubscriptionEdge"][]
	},
	["AbandonedCheckoutUnsubscriptionCreateInput"]: {
	createdAt?:PartialObjects["DateTime"],
	canceledAt?:PartialObjects["DateTime"],
	email?:string,
	_dummy_field_?:boolean
},
	["AbandonedCheckoutUnsubscriptionCreateResult"]: {
		__typename?: "AbandonedCheckoutUnsubscriptionCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["AbandonedCheckoutUnsubscription"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["AbandonedCheckoutUnsubscriptionDeleteResult"]: {
		__typename?: "AbandonedCheckoutUnsubscriptionDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["AbandonedCheckoutUnsubscription"]
	},
	["AbandonedCheckoutUnsubscriptionEdge"]: {
		__typename?: "AbandonedCheckoutUnsubscriptionEdge";
			node?:PartialObjects["AbandonedCheckoutUnsubscription"]
	},
	["AbandonedCheckoutUnsubscriptionMeta"]: {
		__typename?: "AbandonedCheckoutUnsubscriptionMeta";
			id?:PartialObjects["FieldMeta"],
			createdAt?:PartialObjects["FieldMeta"],
			canceledAt?:PartialObjects["FieldMeta"],
			email?:PartialObjects["FieldMeta"]
	},
	["AbandonedCheckoutUnsubscriptionOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	createdAt?:PartialObjects["OrderDirection"],
	canceledAt?:PartialObjects["OrderDirection"],
	email?:PartialObjects["OrderDirection"]
},
	["AbandonedCheckoutUnsubscriptionUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	email?:string
},
	["AbandonedCheckoutUnsubscriptionUpdateInput"]: {
	createdAt?:PartialObjects["DateTime"],
	canceledAt?:PartialObjects["DateTime"],
	email?:string,
	_dummy_field_?:boolean
},
	["AbandonedCheckoutUnsubscriptionUpdateResult"]: {
		__typename?: "AbandonedCheckoutUnsubscriptionUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["AbandonedCheckoutUnsubscription"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["AbandonedCheckoutUnsubscriptionWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	createdAt?:PartialObjects["DateTimeCondition"],
	canceledAt?:PartialObjects["DateTimeCondition"],
	email?:PartialObjects["StringCondition"],
	and?:PartialObjects["AbandonedCheckoutUnsubscriptionWhere"][],
	or?:PartialObjects["AbandonedCheckoutUnsubscriptionWhere"][],
	not?:PartialObjects["AbandonedCheckoutUnsubscriptionWhere"]
},
	["AdminNote"]: {
		__typename?: "AdminNote";
			_meta?:PartialObjects["AdminNoteMeta"],
			id?:PartialObjects["UUID"],
			createdAt?:PartialObjects["DateTime"],
			name?:string,
			data?:string
	},
	["AdminNoteConnection"]: {
		__typename?: "AdminNoteConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["AdminNoteEdge"][]
	},
	["AdminNoteCreateInput"]: {
	createdAt?:PartialObjects["DateTime"],
	name?:string,
	data?:string,
	_dummy_field_?:boolean
},
	["AdminNoteCreateResult"]: {
		__typename?: "AdminNoteCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["AdminNote"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["AdminNoteDeleteResult"]: {
		__typename?: "AdminNoteDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["AdminNote"]
	},
	["AdminNoteEdge"]: {
		__typename?: "AdminNoteEdge";
			node?:PartialObjects["AdminNote"]
	},
	["AdminNoteMeta"]: {
		__typename?: "AdminNoteMeta";
			id?:PartialObjects["FieldMeta"],
			createdAt?:PartialObjects["FieldMeta"],
			name?:PartialObjects["FieldMeta"],
			data?:PartialObjects["FieldMeta"]
	},
	["AdminNoteOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	createdAt?:PartialObjects["OrderDirection"],
	name?:PartialObjects["OrderDirection"],
	data?:PartialObjects["OrderDirection"]
},
	["AdminNoteUniqueWhere"]: {
	id?:PartialObjects["UUID"]
},
	["AdminNoteUpdateInput"]: {
	createdAt?:PartialObjects["DateTime"],
	name?:string,
	data?:string,
	_dummy_field_?:boolean
},
	["AdminNoteUpdateResult"]: {
		__typename?: "AdminNoteUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["AdminNote"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["AdminNoteWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	createdAt?:PartialObjects["DateTimeCondition"],
	name?:PartialObjects["StringCondition"],
	data?:PartialObjects["StringCondition"],
	and?:PartialObjects["AdminNoteWhere"][],
	or?:PartialObjects["AdminNoteWhere"][],
	not?:PartialObjects["AdminNoteWhere"]
},
	["BooleanCondition"]: {
	and?:PartialObjects["BooleanCondition"][],
	or?:PartialObjects["BooleanCondition"][],
	not?:PartialObjects["BooleanCondition"],
	eq?:boolean,
	null?:boolean,
	isNull?:boolean,
	notEq?:boolean,
	in?:boolean[],
	notIn?:boolean[],
	lt?:boolean,
	lte?:boolean,
	gt?:boolean,
	gte?:boolean
},
	["Content"]: {
		__typename?: "Content";
			_meta?:PartialObjects["ContentMeta"],
			id?:PartialObjects["UUID"],
			blocks?:PartialObjects["ContentBlock"][],
			page?:PartialObjects["PageLocale"]
	},
	["ContentBlock"]: {
		__typename?: "ContentBlock";
			_meta?:PartialObjects["ContentBlockMeta"],
			id?:PartialObjects["UUID"],
			order?:number,
			type?:PartialObjects["ContentBlockType"],
			primaryText?:string,
			secondaryText?:string,
			jsonContent?:string,
			headerView?:PartialObjects["HeaderView"],
			reviewView?:PartialObjects["ReviewView"],
			content?:PartialObjects["Content"],
			link?:PartialObjects["Link"],
			image?:PartialObjects["Image"],
			media?:PartialObjects["Media"],
			product?:PartialObjects["Product"],
			featureList?:PartialObjects["FeatureList"],
			iconList?:PartialObjects["IconListBlock"],
			reviewList?:PartialObjects["Review"][],
			faq?:PartialObjects["Faq"],
			productList?:PartialObjects["Product"][],
			tertiaryText?:string,
			sideBySideView?:PartialObjects["SideBySideView"],
			mobileImage?:PartialObjects["Image"],
			htmlId?:string
	},
	["ContentBlockConnection"]: {
		__typename?: "ContentBlockConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["ContentBlockEdge"][]
	},
	["ContentBlockCreateContentEntityRelationInput"]: {
	connect?:PartialObjects["ContentUniqueWhere"],
	create?:PartialObjects["ContentWithoutBlocksCreateInput"]
},
	["ContentBlockCreateFaqEntityRelationInput"]: {
	connect?:PartialObjects["FaqUniqueWhere"],
	create?:PartialObjects["FaqCreateInput"]
},
	["ContentBlockCreateFeatureListEntityRelationInput"]: {
	connect?:PartialObjects["FeatureListUniqueWhere"],
	create?:PartialObjects["FeatureListCreateInput"]
},
	["ContentBlockCreateIconListEntityRelationInput"]: {
	connect?:PartialObjects["IconListBlockUniqueWhere"],
	create?:PartialObjects["IconListBlockCreateInput"]
},
	["ContentBlockCreateImageEntityRelationInput"]: {
	connect?:PartialObjects["ImageUniqueWhere"],
	create?:PartialObjects["ImageCreateInput"]
},
	["ContentBlockCreateInput"]: {
	order?:number,
	type?:PartialObjects["ContentBlockType"],
	primaryText?:string,
	secondaryText?:string,
	jsonContent?:string,
	headerView?:PartialObjects["HeaderView"],
	reviewView?:PartialObjects["ReviewView"],
	content?:PartialObjects["ContentBlockCreateContentEntityRelationInput"],
	link?:PartialObjects["ContentBlockCreateLinkEntityRelationInput"],
	image?:PartialObjects["ContentBlockCreateImageEntityRelationInput"],
	media?:PartialObjects["ContentBlockCreateMediaEntityRelationInput"],
	product?:PartialObjects["ContentBlockCreateProductEntityRelationInput"],
	featureList?:PartialObjects["ContentBlockCreateFeatureListEntityRelationInput"],
	iconList?:PartialObjects["ContentBlockCreateIconListEntityRelationInput"],
	reviewList?:PartialObjects["ContentBlockCreateReviewListEntityRelationInput"][],
	faq?:PartialObjects["ContentBlockCreateFaqEntityRelationInput"],
	productList?:PartialObjects["ContentBlockCreateProductListEntityRelationInput"][],
	tertiaryText?:string,
	sideBySideView?:PartialObjects["SideBySideView"],
	mobileImage?:PartialObjects["ContentBlockCreateMobileImageEntityRelationInput"],
	htmlId?:string,
	_dummy_field_?:boolean
},
	["ContentBlockCreateLinkEntityRelationInput"]: {
	connect?:PartialObjects["LinkUniqueWhere"],
	create?:PartialObjects["LinkCreateInput"]
},
	["ContentBlockCreateMediaEntityRelationInput"]: {
	connect?:PartialObjects["MediaUniqueWhere"],
	create?:PartialObjects["MediaCreateInput"]
},
	["ContentBlockCreateMobileImageEntityRelationInput"]: {
	connect?:PartialObjects["ImageUniqueWhere"],
	create?:PartialObjects["ImageCreateInput"]
},
	["ContentBlockCreateProductEntityRelationInput"]: {
	connect?:PartialObjects["ProductUniqueWhere"],
	create?:PartialObjects["ProductCreateInput"]
},
	["ContentBlockCreateProductListEntityRelationInput"]: {
	connect?:PartialObjects["ProductUniqueWhere"],
	create?:PartialObjects["ProductCreateInput"],
	alias?:string
},
	["ContentBlockCreateResult"]: {
		__typename?: "ContentBlockCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ContentBlock"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ContentBlockCreateReviewListEntityRelationInput"]: {
	connect?:PartialObjects["ReviewUniqueWhere"],
	create?:PartialObjects["ReviewCreateInput"],
	alias?:string
},
	["ContentBlockDeleteResult"]: {
		__typename?: "ContentBlockDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ContentBlock"]
	},
	["ContentBlockEdge"]: {
		__typename?: "ContentBlockEdge";
			node?:PartialObjects["ContentBlock"]
	},
	["ContentBlockMeta"]: {
		__typename?: "ContentBlockMeta";
			id?:PartialObjects["FieldMeta"],
			order?:PartialObjects["FieldMeta"],
			type?:PartialObjects["FieldMeta"],
			primaryText?:PartialObjects["FieldMeta"],
			secondaryText?:PartialObjects["FieldMeta"],
			jsonContent?:PartialObjects["FieldMeta"],
			headerView?:PartialObjects["FieldMeta"],
			reviewView?:PartialObjects["FieldMeta"],
			content?:PartialObjects["FieldMeta"],
			link?:PartialObjects["FieldMeta"],
			image?:PartialObjects["FieldMeta"],
			media?:PartialObjects["FieldMeta"],
			product?:PartialObjects["FieldMeta"],
			featureList?:PartialObjects["FieldMeta"],
			iconList?:PartialObjects["FieldMeta"],
			reviewList?:PartialObjects["FieldMeta"],
			faq?:PartialObjects["FieldMeta"],
			productList?:PartialObjects["FieldMeta"],
			tertiaryText?:PartialObjects["FieldMeta"],
			sideBySideView?:PartialObjects["FieldMeta"],
			mobileImage?:PartialObjects["FieldMeta"],
			htmlId?:PartialObjects["FieldMeta"]
	},
	["ContentBlockOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	order?:PartialObjects["OrderDirection"],
	type?:PartialObjects["OrderDirection"],
	primaryText?:PartialObjects["OrderDirection"],
	secondaryText?:PartialObjects["OrderDirection"],
	jsonContent?:PartialObjects["OrderDirection"],
	headerView?:PartialObjects["OrderDirection"],
	reviewView?:PartialObjects["OrderDirection"],
	content?:PartialObjects["ContentOrderBy"],
	link?:PartialObjects["LinkOrderBy"],
	image?:PartialObjects["ImageOrderBy"],
	media?:PartialObjects["MediaOrderBy"],
	product?:PartialObjects["ProductOrderBy"],
	featureList?:PartialObjects["FeatureListOrderBy"],
	iconList?:PartialObjects["IconListBlockOrderBy"],
	faq?:PartialObjects["FaqOrderBy"],
	tertiaryText?:PartialObjects["OrderDirection"],
	sideBySideView?:PartialObjects["OrderDirection"],
	mobileImage?:PartialObjects["ImageOrderBy"],
	htmlId?:PartialObjects["OrderDirection"]
},
	["ContentBlockType"]:ContentBlockType,
	["ContentBlockTypeEnumCondition"]: {
	and?:PartialObjects["ContentBlockTypeEnumCondition"][],
	or?:PartialObjects["ContentBlockTypeEnumCondition"][],
	not?:PartialObjects["ContentBlockTypeEnumCondition"],
	eq?:PartialObjects["ContentBlockType"],
	null?:boolean,
	isNull?:boolean,
	notEq?:PartialObjects["ContentBlockType"],
	in?:PartialObjects["ContentBlockType"][],
	notIn?:PartialObjects["ContentBlockType"][],
	lt?:PartialObjects["ContentBlockType"],
	lte?:PartialObjects["ContentBlockType"],
	gt?:PartialObjects["ContentBlockType"],
	gte?:PartialObjects["ContentBlockType"]
},
	["ContentBlockUniqueWhere"]: {
	id?:PartialObjects["UUID"]
},
	["ContentBlockUpdateContentEntityRelationInput"]: {
	create?:PartialObjects["ContentWithoutBlocksCreateInput"],
	update?:PartialObjects["ContentWithoutBlocksUpdateInput"],
	upsert?:PartialObjects["ContentBlockUpsertContentRelationInput"],
	connect?:PartialObjects["ContentUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["ContentBlockUpdateFaqEntityRelationInput"]: {
	create?:PartialObjects["FaqCreateInput"],
	update?:PartialObjects["FaqUpdateInput"],
	upsert?:PartialObjects["ContentBlockUpsertFaqRelationInput"],
	connect?:PartialObjects["FaqUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["ContentBlockUpdateFeatureListEntityRelationInput"]: {
	create?:PartialObjects["FeatureListCreateInput"],
	update?:PartialObjects["FeatureListUpdateInput"],
	upsert?:PartialObjects["ContentBlockUpsertFeatureListRelationInput"],
	connect?:PartialObjects["FeatureListUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["ContentBlockUpdateIconListEntityRelationInput"]: {
	create?:PartialObjects["IconListBlockCreateInput"],
	update?:PartialObjects["IconListBlockUpdateInput"],
	upsert?:PartialObjects["ContentBlockUpsertIconListRelationInput"],
	connect?:PartialObjects["IconListBlockUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["ContentBlockUpdateImageEntityRelationInput"]: {
	create?:PartialObjects["ImageCreateInput"],
	update?:PartialObjects["ImageUpdateInput"],
	upsert?:PartialObjects["ContentBlockUpsertImageRelationInput"],
	connect?:PartialObjects["ImageUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["ContentBlockUpdateInput"]: {
	order?:number,
	type?:PartialObjects["ContentBlockType"],
	primaryText?:string,
	secondaryText?:string,
	jsonContent?:string,
	headerView?:PartialObjects["HeaderView"],
	reviewView?:PartialObjects["ReviewView"],
	content?:PartialObjects["ContentBlockUpdateContentEntityRelationInput"],
	link?:PartialObjects["ContentBlockUpdateLinkEntityRelationInput"],
	image?:PartialObjects["ContentBlockUpdateImageEntityRelationInput"],
	media?:PartialObjects["ContentBlockUpdateMediaEntityRelationInput"],
	product?:PartialObjects["ContentBlockUpdateProductEntityRelationInput"],
	featureList?:PartialObjects["ContentBlockUpdateFeatureListEntityRelationInput"],
	iconList?:PartialObjects["ContentBlockUpdateIconListEntityRelationInput"],
	reviewList?:PartialObjects["ContentBlockUpdateReviewListEntityRelationInput"][],
	faq?:PartialObjects["ContentBlockUpdateFaqEntityRelationInput"],
	productList?:PartialObjects["ContentBlockUpdateProductListEntityRelationInput"][],
	tertiaryText?:string,
	sideBySideView?:PartialObjects["SideBySideView"],
	mobileImage?:PartialObjects["ContentBlockUpdateMobileImageEntityRelationInput"],
	htmlId?:string,
	_dummy_field_?:boolean
},
	["ContentBlockUpdateLinkEntityRelationInput"]: {
	create?:PartialObjects["LinkCreateInput"],
	update?:PartialObjects["LinkUpdateInput"],
	upsert?:PartialObjects["ContentBlockUpsertLinkRelationInput"],
	connect?:PartialObjects["LinkUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["ContentBlockUpdateMediaEntityRelationInput"]: {
	create?:PartialObjects["MediaCreateInput"],
	update?:PartialObjects["MediaUpdateInput"],
	upsert?:PartialObjects["ContentBlockUpsertMediaRelationInput"],
	connect?:PartialObjects["MediaUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["ContentBlockUpdateMobileImageEntityRelationInput"]: {
	create?:PartialObjects["ImageCreateInput"],
	update?:PartialObjects["ImageUpdateInput"],
	upsert?:PartialObjects["ContentBlockUpsertMobileImageRelationInput"],
	connect?:PartialObjects["ImageUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["ContentBlockUpdateProductEntityRelationInput"]: {
	create?:PartialObjects["ProductCreateInput"],
	update?:PartialObjects["ProductUpdateInput"],
	upsert?:PartialObjects["ContentBlockUpsertProductRelationInput"],
	connect?:PartialObjects["ProductUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["ContentBlockUpdateProductListEntityRelationInput"]: {
	create?:PartialObjects["ProductCreateInput"],
	update?:PartialObjects["ContentBlockUpdateProductListRelationInput"],
	upsert?:PartialObjects["ContentBlockUpsertProductListRelationInput"],
	connect?:PartialObjects["ProductUniqueWhere"],
	disconnect?:PartialObjects["ProductUniqueWhere"],
	delete?:PartialObjects["ProductUniqueWhere"],
	alias?:string
},
	["ContentBlockUpdateProductListRelationInput"]: {
	by?:PartialObjects["ProductUniqueWhere"],
	data?:PartialObjects["ProductUpdateInput"]
},
	["ContentBlockUpdateResult"]: {
		__typename?: "ContentBlockUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ContentBlock"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ContentBlockUpdateReviewListEntityRelationInput"]: {
	create?:PartialObjects["ReviewCreateInput"],
	update?:PartialObjects["ContentBlockUpdateReviewListRelationInput"],
	upsert?:PartialObjects["ContentBlockUpsertReviewListRelationInput"],
	connect?:PartialObjects["ReviewUniqueWhere"],
	disconnect?:PartialObjects["ReviewUniqueWhere"],
	delete?:PartialObjects["ReviewUniqueWhere"],
	alias?:string
},
	["ContentBlockUpdateReviewListRelationInput"]: {
	by?:PartialObjects["ReviewUniqueWhere"],
	data?:PartialObjects["ReviewUpdateInput"]
},
	["ContentBlockUpsertContentRelationInput"]: {
	update?:PartialObjects["ContentWithoutBlocksUpdateInput"],
	create?:PartialObjects["ContentWithoutBlocksCreateInput"]
},
	["ContentBlockUpsertFaqRelationInput"]: {
	update?:PartialObjects["FaqUpdateInput"],
	create?:PartialObjects["FaqCreateInput"]
},
	["ContentBlockUpsertFeatureListRelationInput"]: {
	update?:PartialObjects["FeatureListUpdateInput"],
	create?:PartialObjects["FeatureListCreateInput"]
},
	["ContentBlockUpsertIconListRelationInput"]: {
	update?:PartialObjects["IconListBlockUpdateInput"],
	create?:PartialObjects["IconListBlockCreateInput"]
},
	["ContentBlockUpsertImageRelationInput"]: {
	update?:PartialObjects["ImageUpdateInput"],
	create?:PartialObjects["ImageCreateInput"]
},
	["ContentBlockUpsertLinkRelationInput"]: {
	update?:PartialObjects["LinkUpdateInput"],
	create?:PartialObjects["LinkCreateInput"]
},
	["ContentBlockUpsertMediaRelationInput"]: {
	update?:PartialObjects["MediaUpdateInput"],
	create?:PartialObjects["MediaCreateInput"]
},
	["ContentBlockUpsertMobileImageRelationInput"]: {
	update?:PartialObjects["ImageUpdateInput"],
	create?:PartialObjects["ImageCreateInput"]
},
	["ContentBlockUpsertProductListRelationInput"]: {
	by?:PartialObjects["ProductUniqueWhere"],
	update?:PartialObjects["ProductUpdateInput"],
	create?:PartialObjects["ProductCreateInput"]
},
	["ContentBlockUpsertProductRelationInput"]: {
	update?:PartialObjects["ProductUpdateInput"],
	create?:PartialObjects["ProductCreateInput"]
},
	["ContentBlockUpsertReviewListRelationInput"]: {
	by?:PartialObjects["ReviewUniqueWhere"],
	update?:PartialObjects["ReviewUpdateInput"],
	create?:PartialObjects["ReviewCreateInput"]
},
	["ContentBlockWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	order?:PartialObjects["IntCondition"],
	type?:PartialObjects["ContentBlockTypeEnumCondition"],
	primaryText?:PartialObjects["StringCondition"],
	secondaryText?:PartialObjects["StringCondition"],
	jsonContent?:PartialObjects["StringCondition"],
	headerView?:PartialObjects["HeaderViewEnumCondition"],
	reviewView?:PartialObjects["ReviewViewEnumCondition"],
	content?:PartialObjects["ContentWhere"],
	link?:PartialObjects["LinkWhere"],
	image?:PartialObjects["ImageWhere"],
	media?:PartialObjects["MediaWhere"],
	product?:PartialObjects["ProductWhere"],
	featureList?:PartialObjects["FeatureListWhere"],
	iconList?:PartialObjects["IconListBlockWhere"],
	reviewList?:PartialObjects["ReviewWhere"],
	faq?:PartialObjects["FaqWhere"],
	productList?:PartialObjects["ProductWhere"],
	tertiaryText?:PartialObjects["StringCondition"],
	sideBySideView?:PartialObjects["SideBySideViewEnumCondition"],
	mobileImage?:PartialObjects["ImageWhere"],
	htmlId?:PartialObjects["StringCondition"],
	and?:PartialObjects["ContentBlockWhere"][],
	or?:PartialObjects["ContentBlockWhere"][],
	not?:PartialObjects["ContentBlockWhere"]
},
	["ContentBlockWithoutContentCreateInput"]: {
	order?:number,
	type?:PartialObjects["ContentBlockType"],
	primaryText?:string,
	secondaryText?:string,
	jsonContent?:string,
	headerView?:PartialObjects["HeaderView"],
	reviewView?:PartialObjects["ReviewView"],
	link?:PartialObjects["ContentBlockCreateLinkEntityRelationInput"],
	image?:PartialObjects["ContentBlockCreateImageEntityRelationInput"],
	media?:PartialObjects["ContentBlockCreateMediaEntityRelationInput"],
	product?:PartialObjects["ContentBlockCreateProductEntityRelationInput"],
	featureList?:PartialObjects["ContentBlockCreateFeatureListEntityRelationInput"],
	iconList?:PartialObjects["ContentBlockCreateIconListEntityRelationInput"],
	reviewList?:PartialObjects["ContentBlockCreateReviewListEntityRelationInput"][],
	faq?:PartialObjects["ContentBlockCreateFaqEntityRelationInput"],
	productList?:PartialObjects["ContentBlockCreateProductListEntityRelationInput"][],
	tertiaryText?:string,
	sideBySideView?:PartialObjects["SideBySideView"],
	mobileImage?:PartialObjects["ContentBlockCreateMobileImageEntityRelationInput"],
	htmlId?:string,
	_dummy_field_?:boolean
},
	["ContentBlockWithoutContentUpdateInput"]: {
	order?:number,
	type?:PartialObjects["ContentBlockType"],
	primaryText?:string,
	secondaryText?:string,
	jsonContent?:string,
	headerView?:PartialObjects["HeaderView"],
	reviewView?:PartialObjects["ReviewView"],
	link?:PartialObjects["ContentBlockUpdateLinkEntityRelationInput"],
	image?:PartialObjects["ContentBlockUpdateImageEntityRelationInput"],
	media?:PartialObjects["ContentBlockUpdateMediaEntityRelationInput"],
	product?:PartialObjects["ContentBlockUpdateProductEntityRelationInput"],
	featureList?:PartialObjects["ContentBlockUpdateFeatureListEntityRelationInput"],
	iconList?:PartialObjects["ContentBlockUpdateIconListEntityRelationInput"],
	reviewList?:PartialObjects["ContentBlockUpdateReviewListEntityRelationInput"][],
	faq?:PartialObjects["ContentBlockUpdateFaqEntityRelationInput"],
	productList?:PartialObjects["ContentBlockUpdateProductListEntityRelationInput"][],
	tertiaryText?:string,
	sideBySideView?:PartialObjects["SideBySideView"],
	mobileImage?:PartialObjects["ContentBlockUpdateMobileImageEntityRelationInput"],
	htmlId?:string,
	_dummy_field_?:boolean
},
	["ContentConnection"]: {
		__typename?: "ContentConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["ContentEdge"][]
	},
	["ContentCreateBlocksEntityRelationInput"]: {
	connect?:PartialObjects["ContentBlockUniqueWhere"],
	create?:PartialObjects["ContentBlockWithoutContentCreateInput"],
	alias?:string
},
	["ContentCreateInput"]: {
	blocks?:PartialObjects["ContentCreateBlocksEntityRelationInput"][],
	page?:PartialObjects["ContentCreatePageEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ContentCreatePageEntityRelationInput"]: {
	connect?:PartialObjects["PageLocaleUniqueWhere"],
	create?:PartialObjects["PageLocaleWithoutContentCreateInput"]
},
	["ContentCreateResult"]: {
		__typename?: "ContentCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Content"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ContentDeleteResult"]: {
		__typename?: "ContentDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Content"]
	},
	["ContentEdge"]: {
		__typename?: "ContentEdge";
			node?:PartialObjects["Content"]
	},
	["ContentMeta"]: {
		__typename?: "ContentMeta";
			id?:PartialObjects["FieldMeta"],
			blocks?:PartialObjects["FieldMeta"],
			page?:PartialObjects["FieldMeta"]
	},
	["ContentOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	page?:PartialObjects["PageLocaleOrderBy"]
},
	["ContentUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	blocks?:PartialObjects["ContentBlockUniqueWhere"],
	page?:PartialObjects["PageLocaleUniqueWhere"]
},
	["ContentUpdateBlocksEntityRelationInput"]: {
	create?:PartialObjects["ContentBlockWithoutContentCreateInput"],
	update?:PartialObjects["ContentUpdateBlocksRelationInput"],
	upsert?:PartialObjects["ContentUpsertBlocksRelationInput"],
	connect?:PartialObjects["ContentBlockUniqueWhere"],
	disconnect?:PartialObjects["ContentBlockUniqueWhere"],
	delete?:PartialObjects["ContentBlockUniqueWhere"],
	alias?:string
},
	["ContentUpdateBlocksRelationInput"]: {
	by?:PartialObjects["ContentBlockUniqueWhere"],
	data?:PartialObjects["ContentBlockWithoutContentUpdateInput"]
},
	["ContentUpdateInput"]: {
	blocks?:PartialObjects["ContentUpdateBlocksEntityRelationInput"][],
	page?:PartialObjects["ContentUpdatePageEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ContentUpdatePageEntityRelationInput"]: {
	create?:PartialObjects["PageLocaleWithoutContentCreateInput"],
	update?:PartialObjects["PageLocaleWithoutContentUpdateInput"],
	upsert?:PartialObjects["ContentUpsertPageRelationInput"],
	connect?:PartialObjects["PageLocaleUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["ContentUpdateResult"]: {
		__typename?: "ContentUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Content"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ContentUpsertBlocksRelationInput"]: {
	by?:PartialObjects["ContentBlockUniqueWhere"],
	update?:PartialObjects["ContentBlockWithoutContentUpdateInput"],
	create?:PartialObjects["ContentBlockWithoutContentCreateInput"]
},
	["ContentUpsertPageRelationInput"]: {
	update?:PartialObjects["PageLocaleWithoutContentUpdateInput"],
	create?:PartialObjects["PageLocaleWithoutContentCreateInput"]
},
	["ContentWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	blocks?:PartialObjects["ContentBlockWhere"],
	page?:PartialObjects["PageLocaleWhere"],
	and?:PartialObjects["ContentWhere"][],
	or?:PartialObjects["ContentWhere"][],
	not?:PartialObjects["ContentWhere"]
},
	["ContentWithoutBlocksCreateInput"]: {
	page?:PartialObjects["ContentCreatePageEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ContentWithoutBlocksUpdateInput"]: {
	page?:PartialObjects["ContentUpdatePageEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ContentWithoutPageCreateInput"]: {
	blocks?:PartialObjects["ContentCreateBlocksEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["ContentWithoutPageUpdateInput"]: {
	blocks?:PartialObjects["ContentUpdateBlocksEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["DateTime"]:any,
	["DateTimeCondition"]: {
	and?:PartialObjects["DateTimeCondition"][],
	or?:PartialObjects["DateTimeCondition"][],
	not?:PartialObjects["DateTimeCondition"],
	eq?:PartialObjects["DateTime"],
	null?:boolean,
	isNull?:boolean,
	notEq?:PartialObjects["DateTime"],
	in?:PartialObjects["DateTime"][],
	notIn?:PartialObjects["DateTime"][],
	lt?:PartialObjects["DateTime"],
	lte?:PartialObjects["DateTime"],
	gt?:PartialObjects["DateTime"],
	gte?:PartialObjects["DateTime"]
},
	["Faq"]: {
		__typename?: "Faq";
			_meta?:PartialObjects["FaqMeta"],
			id?:PartialObjects["UUID"],
			items?:PartialObjects["FaqItem"][]
	},
	["FaqConnection"]: {
		__typename?: "FaqConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["FaqEdge"][]
	},
	["FaqCreateInput"]: {
	items?:PartialObjects["FaqCreateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["FaqCreateItemsEntityRelationInput"]: {
	connect?:PartialObjects["FaqItemUniqueWhere"],
	create?:PartialObjects["FaqItemWithoutListCreateInput"],
	alias?:string
},
	["FaqCreateResult"]: {
		__typename?: "FaqCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Faq"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["FaqDeleteResult"]: {
		__typename?: "FaqDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Faq"]
	},
	["FaqEdge"]: {
		__typename?: "FaqEdge";
			node?:PartialObjects["Faq"]
	},
	["FaqItem"]: {
		__typename?: "FaqItem";
			_meta?:PartialObjects["FaqItemMeta"],
			id?:PartialObjects["UUID"],
			order?:number,
			question?:string,
			answer?:string,
			list?:PartialObjects["Faq"]
	},
	["FaqItemConnection"]: {
		__typename?: "FaqItemConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["FaqItemEdge"][]
	},
	["FaqItemCreateInput"]: {
	order?:number,
	question?:string,
	answer?:string,
	list?:PartialObjects["FaqItemCreateListEntityRelationInput"],
	_dummy_field_?:boolean
},
	["FaqItemCreateListEntityRelationInput"]: {
	connect?:PartialObjects["FaqUniqueWhere"],
	create?:PartialObjects["FaqWithoutItemsCreateInput"]
},
	["FaqItemCreateResult"]: {
		__typename?: "FaqItemCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["FaqItem"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["FaqItemDeleteResult"]: {
		__typename?: "FaqItemDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["FaqItem"]
	},
	["FaqItemEdge"]: {
		__typename?: "FaqItemEdge";
			node?:PartialObjects["FaqItem"]
	},
	["FaqItemMeta"]: {
		__typename?: "FaqItemMeta";
			id?:PartialObjects["FieldMeta"],
			order?:PartialObjects["FieldMeta"],
			question?:PartialObjects["FieldMeta"],
			answer?:PartialObjects["FieldMeta"],
			list?:PartialObjects["FieldMeta"]
	},
	["FaqItemOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	order?:PartialObjects["OrderDirection"],
	question?:PartialObjects["OrderDirection"],
	answer?:PartialObjects["OrderDirection"],
	list?:PartialObjects["FaqOrderBy"]
},
	["FaqItemUniqueWhere"]: {
	id?:PartialObjects["UUID"]
},
	["FaqItemUpdateInput"]: {
	order?:number,
	question?:string,
	answer?:string,
	list?:PartialObjects["FaqItemUpdateListEntityRelationInput"],
	_dummy_field_?:boolean
},
	["FaqItemUpdateListEntityRelationInput"]: {
	create?:PartialObjects["FaqWithoutItemsCreateInput"],
	update?:PartialObjects["FaqWithoutItemsUpdateInput"],
	upsert?:PartialObjects["FaqItemUpsertListRelationInput"],
	connect?:PartialObjects["FaqUniqueWhere"]
},
	["FaqItemUpdateResult"]: {
		__typename?: "FaqItemUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["FaqItem"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["FaqItemUpsertListRelationInput"]: {
	update?:PartialObjects["FaqWithoutItemsUpdateInput"],
	create?:PartialObjects["FaqWithoutItemsCreateInput"]
},
	["FaqItemWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	order?:PartialObjects["IntCondition"],
	question?:PartialObjects["StringCondition"],
	answer?:PartialObjects["StringCondition"],
	list?:PartialObjects["FaqWhere"],
	and?:PartialObjects["FaqItemWhere"][],
	or?:PartialObjects["FaqItemWhere"][],
	not?:PartialObjects["FaqItemWhere"]
},
	["FaqItemWithoutListCreateInput"]: {
	order?:number,
	question?:string,
	answer?:string,
	_dummy_field_?:boolean
},
	["FaqItemWithoutListUpdateInput"]: {
	order?:number,
	question?:string,
	answer?:string,
	_dummy_field_?:boolean
},
	["FaqMeta"]: {
		__typename?: "FaqMeta";
			id?:PartialObjects["FieldMeta"],
			items?:PartialObjects["FieldMeta"]
	},
	["FaqOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"]
},
	["FaqUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	items?:PartialObjects["FaqItemUniqueWhere"]
},
	["FaqUpdateInput"]: {
	items?:PartialObjects["FaqUpdateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["FaqUpdateItemsEntityRelationInput"]: {
	create?:PartialObjects["FaqItemWithoutListCreateInput"],
	update?:PartialObjects["FaqUpdateItemsRelationInput"],
	upsert?:PartialObjects["FaqUpsertItemsRelationInput"],
	connect?:PartialObjects["FaqItemUniqueWhere"],
	disconnect?:PartialObjects["FaqItemUniqueWhere"],
	delete?:PartialObjects["FaqItemUniqueWhere"],
	alias?:string
},
	["FaqUpdateItemsRelationInput"]: {
	by?:PartialObjects["FaqItemUniqueWhere"],
	data?:PartialObjects["FaqItemWithoutListUpdateInput"]
},
	["FaqUpdateResult"]: {
		__typename?: "FaqUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Faq"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["FaqUpsertItemsRelationInput"]: {
	by?:PartialObjects["FaqItemUniqueWhere"],
	update?:PartialObjects["FaqItemWithoutListUpdateInput"],
	create?:PartialObjects["FaqItemWithoutListCreateInput"]
},
	["FaqWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	items?:PartialObjects["FaqItemWhere"],
	and?:PartialObjects["FaqWhere"][],
	or?:PartialObjects["FaqWhere"][],
	not?:PartialObjects["FaqWhere"]
},
	["FaqWithoutItemsCreateInput"]: {
	_dummy_field_?:boolean
},
	["FaqWithoutItemsUpdateInput"]: {
	_dummy_field_?:boolean
},
	["FeatureList"]: {
		__typename?: "FeatureList";
			_meta?:PartialObjects["FeatureListMeta"],
			id?:PartialObjects["UUID"],
			items?:PartialObjects["FeatureListItem"][]
	},
	["FeatureListConnection"]: {
		__typename?: "FeatureListConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["FeatureListEdge"][]
	},
	["FeatureListCreateInput"]: {
	items?:PartialObjects["FeatureListCreateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["FeatureListCreateItemsEntityRelationInput"]: {
	connect?:PartialObjects["FeatureListItemUniqueWhere"],
	create?:PartialObjects["FeatureListItemWithoutListCreateInput"],
	alias?:string
},
	["FeatureListCreateResult"]: {
		__typename?: "FeatureListCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["FeatureList"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["FeatureListDeleteResult"]: {
		__typename?: "FeatureListDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["FeatureList"]
	},
	["FeatureListEdge"]: {
		__typename?: "FeatureListEdge";
			node?:PartialObjects["FeatureList"]
	},
	["FeatureListItem"]: {
		__typename?: "FeatureListItem";
			_meta?:PartialObjects["FeatureListItemMeta"],
			id?:PartialObjects["UUID"],
			order?:number,
			headline?:string,
			text?:string,
			list?:PartialObjects["FeatureList"]
	},
	["FeatureListItemConnection"]: {
		__typename?: "FeatureListItemConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["FeatureListItemEdge"][]
	},
	["FeatureListItemCreateInput"]: {
	order?:number,
	headline?:string,
	text?:string,
	list?:PartialObjects["FeatureListItemCreateListEntityRelationInput"],
	_dummy_field_?:boolean
},
	["FeatureListItemCreateListEntityRelationInput"]: {
	connect?:PartialObjects["FeatureListUniqueWhere"],
	create?:PartialObjects["FeatureListWithoutItemsCreateInput"]
},
	["FeatureListItemCreateResult"]: {
		__typename?: "FeatureListItemCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["FeatureListItem"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["FeatureListItemDeleteResult"]: {
		__typename?: "FeatureListItemDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["FeatureListItem"]
	},
	["FeatureListItemEdge"]: {
		__typename?: "FeatureListItemEdge";
			node?:PartialObjects["FeatureListItem"]
	},
	["FeatureListItemMeta"]: {
		__typename?: "FeatureListItemMeta";
			id?:PartialObjects["FieldMeta"],
			order?:PartialObjects["FieldMeta"],
			headline?:PartialObjects["FieldMeta"],
			text?:PartialObjects["FieldMeta"],
			list?:PartialObjects["FieldMeta"]
	},
	["FeatureListItemOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	order?:PartialObjects["OrderDirection"],
	headline?:PartialObjects["OrderDirection"],
	text?:PartialObjects["OrderDirection"],
	list?:PartialObjects["FeatureListOrderBy"]
},
	["FeatureListItemUniqueWhere"]: {
	id?:PartialObjects["UUID"]
},
	["FeatureListItemUpdateInput"]: {
	order?:number,
	headline?:string,
	text?:string,
	list?:PartialObjects["FeatureListItemUpdateListEntityRelationInput"],
	_dummy_field_?:boolean
},
	["FeatureListItemUpdateListEntityRelationInput"]: {
	create?:PartialObjects["FeatureListWithoutItemsCreateInput"],
	update?:PartialObjects["FeatureListWithoutItemsUpdateInput"],
	upsert?:PartialObjects["FeatureListItemUpsertListRelationInput"],
	connect?:PartialObjects["FeatureListUniqueWhere"]
},
	["FeatureListItemUpdateResult"]: {
		__typename?: "FeatureListItemUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["FeatureListItem"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["FeatureListItemUpsertListRelationInput"]: {
	update?:PartialObjects["FeatureListWithoutItemsUpdateInput"],
	create?:PartialObjects["FeatureListWithoutItemsCreateInput"]
},
	["FeatureListItemWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	order?:PartialObjects["IntCondition"],
	headline?:PartialObjects["StringCondition"],
	text?:PartialObjects["StringCondition"],
	list?:PartialObjects["FeatureListWhere"],
	and?:PartialObjects["FeatureListItemWhere"][],
	or?:PartialObjects["FeatureListItemWhere"][],
	not?:PartialObjects["FeatureListItemWhere"]
},
	["FeatureListItemWithoutListCreateInput"]: {
	order?:number,
	headline?:string,
	text?:string,
	_dummy_field_?:boolean
},
	["FeatureListItemWithoutListUpdateInput"]: {
	order?:number,
	headline?:string,
	text?:string,
	_dummy_field_?:boolean
},
	["FeatureListMeta"]: {
		__typename?: "FeatureListMeta";
			id?:PartialObjects["FieldMeta"],
			items?:PartialObjects["FieldMeta"]
	},
	["FeatureListOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"]
},
	["FeatureListUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	items?:PartialObjects["FeatureListItemUniqueWhere"]
},
	["FeatureListUpdateInput"]: {
	items?:PartialObjects["FeatureListUpdateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["FeatureListUpdateItemsEntityRelationInput"]: {
	create?:PartialObjects["FeatureListItemWithoutListCreateInput"],
	update?:PartialObjects["FeatureListUpdateItemsRelationInput"],
	upsert?:PartialObjects["FeatureListUpsertItemsRelationInput"],
	connect?:PartialObjects["FeatureListItemUniqueWhere"],
	disconnect?:PartialObjects["FeatureListItemUniqueWhere"],
	delete?:PartialObjects["FeatureListItemUniqueWhere"],
	alias?:string
},
	["FeatureListUpdateItemsRelationInput"]: {
	by?:PartialObjects["FeatureListItemUniqueWhere"],
	data?:PartialObjects["FeatureListItemWithoutListUpdateInput"]
},
	["FeatureListUpdateResult"]: {
		__typename?: "FeatureListUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["FeatureList"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["FeatureListUpsertItemsRelationInput"]: {
	by?:PartialObjects["FeatureListItemUniqueWhere"],
	update?:PartialObjects["FeatureListItemWithoutListUpdateInput"],
	create?:PartialObjects["FeatureListItemWithoutListCreateInput"]
},
	["FeatureListWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	items?:PartialObjects["FeatureListItemWhere"],
	and?:PartialObjects["FeatureListWhere"][],
	or?:PartialObjects["FeatureListWhere"][],
	not?:PartialObjects["FeatureListWhere"]
},
	["FeatureListWithoutItemsCreateInput"]: {
	_dummy_field_?:boolean
},
	["FeatureListWithoutItemsUpdateInput"]: {
	_dummy_field_?:boolean
},
	["FieldMeta"]: {
		__typename?: "FieldMeta";
			readable?:boolean,
			updatable?:boolean
	},
	["FloatCondition"]: {
	and?:PartialObjects["FloatCondition"][],
	or?:PartialObjects["FloatCondition"][],
	not?:PartialObjects["FloatCondition"],
	eq?:number,
	null?:boolean,
	isNull?:boolean,
	notEq?:number,
	in?:number[],
	notIn?:number[],
	lt?:number,
	lte?:number,
	gt?:number,
	gte?:number
},
	["Gallery"]: {
		__typename?: "Gallery";
			_meta?:PartialObjects["GalleryMeta"],
			id?:PartialObjects["UUID"],
			items?:PartialObjects["GalleryItem"][]
	},
	["GalleryConnection"]: {
		__typename?: "GalleryConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["GalleryEdge"][]
	},
	["GalleryCreateInput"]: {
	items?:PartialObjects["GalleryCreateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["GalleryCreateItemsEntityRelationInput"]: {
	connect?:PartialObjects["GalleryItemUniqueWhere"],
	create?:PartialObjects["GalleryItemWithoutListCreateInput"],
	alias?:string
},
	["GalleryCreateResult"]: {
		__typename?: "GalleryCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Gallery"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["GalleryDeleteResult"]: {
		__typename?: "GalleryDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Gallery"]
	},
	["GalleryEdge"]: {
		__typename?: "GalleryEdge";
			node?:PartialObjects["Gallery"]
	},
	["GalleryItem"]: {
		__typename?: "GalleryItem";
			_meta?:PartialObjects["GalleryItemMeta"],
			id?:PartialObjects["UUID"],
			order?:number,
			caption?:string,
			list?:PartialObjects["Gallery"],
			image?:PartialObjects["Image"]
	},
	["GalleryItemConnection"]: {
		__typename?: "GalleryItemConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["GalleryItemEdge"][]
	},
	["GalleryItemCreateImageEntityRelationInput"]: {
	connect?:PartialObjects["ImageUniqueWhere"],
	create?:PartialObjects["ImageCreateInput"]
},
	["GalleryItemCreateInput"]: {
	order?:number,
	caption?:string,
	list?:PartialObjects["GalleryItemCreateListEntityRelationInput"],
	image?:PartialObjects["GalleryItemCreateImageEntityRelationInput"],
	_dummy_field_?:boolean
},
	["GalleryItemCreateListEntityRelationInput"]: {
	connect?:PartialObjects["GalleryUniqueWhere"],
	create?:PartialObjects["GalleryWithoutItemsCreateInput"]
},
	["GalleryItemCreateResult"]: {
		__typename?: "GalleryItemCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["GalleryItem"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["GalleryItemDeleteResult"]: {
		__typename?: "GalleryItemDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["GalleryItem"]
	},
	["GalleryItemEdge"]: {
		__typename?: "GalleryItemEdge";
			node?:PartialObjects["GalleryItem"]
	},
	["GalleryItemMeta"]: {
		__typename?: "GalleryItemMeta";
			id?:PartialObjects["FieldMeta"],
			order?:PartialObjects["FieldMeta"],
			caption?:PartialObjects["FieldMeta"],
			list?:PartialObjects["FieldMeta"],
			image?:PartialObjects["FieldMeta"]
	},
	["GalleryItemOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	order?:PartialObjects["OrderDirection"],
	caption?:PartialObjects["OrderDirection"],
	list?:PartialObjects["GalleryOrderBy"],
	image?:PartialObjects["ImageOrderBy"]
},
	["GalleryItemUniqueWhere"]: {
	id?:PartialObjects["UUID"]
},
	["GalleryItemUpdateImageEntityRelationInput"]: {
	create?:PartialObjects["ImageCreateInput"],
	update?:PartialObjects["ImageUpdateInput"],
	upsert?:PartialObjects["GalleryItemUpsertImageRelationInput"],
	connect?:PartialObjects["ImageUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["GalleryItemUpdateInput"]: {
	order?:number,
	caption?:string,
	list?:PartialObjects["GalleryItemUpdateListEntityRelationInput"],
	image?:PartialObjects["GalleryItemUpdateImageEntityRelationInput"],
	_dummy_field_?:boolean
},
	["GalleryItemUpdateListEntityRelationInput"]: {
	create?:PartialObjects["GalleryWithoutItemsCreateInput"],
	update?:PartialObjects["GalleryWithoutItemsUpdateInput"],
	upsert?:PartialObjects["GalleryItemUpsertListRelationInput"],
	connect?:PartialObjects["GalleryUniqueWhere"]
},
	["GalleryItemUpdateResult"]: {
		__typename?: "GalleryItemUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["GalleryItem"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["GalleryItemUpsertImageRelationInput"]: {
	update?:PartialObjects["ImageUpdateInput"],
	create?:PartialObjects["ImageCreateInput"]
},
	["GalleryItemUpsertListRelationInput"]: {
	update?:PartialObjects["GalleryWithoutItemsUpdateInput"],
	create?:PartialObjects["GalleryWithoutItemsCreateInput"]
},
	["GalleryItemWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	order?:PartialObjects["IntCondition"],
	caption?:PartialObjects["StringCondition"],
	list?:PartialObjects["GalleryWhere"],
	image?:PartialObjects["ImageWhere"],
	and?:PartialObjects["GalleryItemWhere"][],
	or?:PartialObjects["GalleryItemWhere"][],
	not?:PartialObjects["GalleryItemWhere"]
},
	["GalleryItemWithoutListCreateInput"]: {
	order?:number,
	caption?:string,
	image?:PartialObjects["GalleryItemCreateImageEntityRelationInput"],
	_dummy_field_?:boolean
},
	["GalleryItemWithoutListUpdateInput"]: {
	order?:number,
	caption?:string,
	image?:PartialObjects["GalleryItemUpdateImageEntityRelationInput"],
	_dummy_field_?:boolean
},
	["GalleryMeta"]: {
		__typename?: "GalleryMeta";
			id?:PartialObjects["FieldMeta"],
			items?:PartialObjects["FieldMeta"]
	},
	["GalleryOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"]
},
	["GalleryUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	items?:PartialObjects["GalleryItemUniqueWhere"]
},
	["GalleryUpdateInput"]: {
	items?:PartialObjects["GalleryUpdateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["GalleryUpdateItemsEntityRelationInput"]: {
	create?:PartialObjects["GalleryItemWithoutListCreateInput"],
	update?:PartialObjects["GalleryUpdateItemsRelationInput"],
	upsert?:PartialObjects["GalleryUpsertItemsRelationInput"],
	connect?:PartialObjects["GalleryItemUniqueWhere"],
	disconnect?:PartialObjects["GalleryItemUniqueWhere"],
	delete?:PartialObjects["GalleryItemUniqueWhere"],
	alias?:string
},
	["GalleryUpdateItemsRelationInput"]: {
	by?:PartialObjects["GalleryItemUniqueWhere"],
	data?:PartialObjects["GalleryItemWithoutListUpdateInput"]
},
	["GalleryUpdateResult"]: {
		__typename?: "GalleryUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Gallery"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["GalleryUpsertItemsRelationInput"]: {
	by?:PartialObjects["GalleryItemUniqueWhere"],
	update?:PartialObjects["GalleryItemWithoutListUpdateInput"],
	create?:PartialObjects["GalleryItemWithoutListCreateInput"]
},
	["GalleryWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	items?:PartialObjects["GalleryItemWhere"],
	and?:PartialObjects["GalleryWhere"][],
	or?:PartialObjects["GalleryWhere"][],
	not?:PartialObjects["GalleryWhere"]
},
	["GalleryWithoutItemsCreateInput"]: {
	_dummy_field_?:boolean
},
	["GalleryWithoutItemsUpdateInput"]: {
	_dummy_field_?:boolean
},
	["HeaderView"]:HeaderView,
	["HeaderViewEnumCondition"]: {
	and?:PartialObjects["HeaderViewEnumCondition"][],
	or?:PartialObjects["HeaderViewEnumCondition"][],
	not?:PartialObjects["HeaderViewEnumCondition"],
	eq?:PartialObjects["HeaderView"],
	null?:boolean,
	isNull?:boolean,
	notEq?:PartialObjects["HeaderView"],
	in?:PartialObjects["HeaderView"][],
	notIn?:PartialObjects["HeaderView"][],
	lt?:PartialObjects["HeaderView"],
	lte?:PartialObjects["HeaderView"],
	gt?:PartialObjects["HeaderView"],
	gte?:PartialObjects["HeaderView"]
},
	["Icon"]: {
		__typename?: "Icon";
			_meta?:PartialObjects["IconMeta"],
			id?:PartialObjects["UUID"],
			key?:string,
			image?:PartialObjects["Image"],
			locales?:PartialObjects["IconLocale"][],
			localesByLocale?:PartialObjects["IconLocale"]
	},
	["IconConnection"]: {
		__typename?: "IconConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["IconEdge"][]
	},
	["IconCreateImageEntityRelationInput"]: {
	connect?:PartialObjects["ImageUniqueWhere"],
	create?:PartialObjects["ImageCreateInput"]
},
	["IconCreateInput"]: {
	key?:string,
	image?:PartialObjects["IconCreateImageEntityRelationInput"],
	locales?:PartialObjects["IconCreateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["IconCreateLocalesEntityRelationInput"]: {
	connect?:PartialObjects["IconLocaleUniqueWhere"],
	create?:PartialObjects["IconLocaleWithoutRootCreateInput"],
	alias?:string
},
	["IconCreateResult"]: {
		__typename?: "IconCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Icon"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconDeleteResult"]: {
		__typename?: "IconDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Icon"]
	},
	["IconEdge"]: {
		__typename?: "IconEdge";
			node?:PartialObjects["Icon"]
	},
	["IconGroup"]: {
		__typename?: "IconGroup";
			_meta?:PartialObjects["IconGroupMeta"],
			id?:PartialObjects["UUID"],
			icons?:PartialObjects["IconList"],
			locales?:PartialObjects["IconGroupLocale"][],
			title?:string,
			localesByLocale?:PartialObjects["IconGroupLocale"]
	},
	["IconGroupConnection"]: {
		__typename?: "IconGroupConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["IconGroupEdge"][]
	},
	["IconGroupCreateIconsEntityRelationInput"]: {
	connect?:PartialObjects["IconListUniqueWhere"],
	create?:PartialObjects["IconListCreateInput"]
},
	["IconGroupCreateInput"]: {
	icons?:PartialObjects["IconGroupCreateIconsEntityRelationInput"],
	locales?:PartialObjects["IconGroupCreateLocalesEntityRelationInput"][],
	title?:string,
	_dummy_field_?:boolean
},
	["IconGroupCreateLocalesEntityRelationInput"]: {
	connect?:PartialObjects["IconGroupLocaleUniqueWhere"],
	create?:PartialObjects["IconGroupLocaleWithoutRootCreateInput"],
	alias?:string
},
	["IconGroupCreateResult"]: {
		__typename?: "IconGroupCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconGroup"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconGroupDeleteResult"]: {
		__typename?: "IconGroupDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconGroup"]
	},
	["IconGroupEdge"]: {
		__typename?: "IconGroupEdge";
			node?:PartialObjects["IconGroup"]
	},
	["IconGroupList"]: {
		__typename?: "IconGroupList";
			_meta?:PartialObjects["IconGroupListMeta"],
			id?:PartialObjects["UUID"],
			items?:PartialObjects["IconGroupListItem"][],
			title?:string
	},
	["IconGroupListConnection"]: {
		__typename?: "IconGroupListConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["IconGroupListEdge"][]
	},
	["IconGroupListCreateInput"]: {
	items?:PartialObjects["IconGroupListCreateItemsEntityRelationInput"][],
	title?:string,
	_dummy_field_?:boolean
},
	["IconGroupListCreateItemsEntityRelationInput"]: {
	connect?:PartialObjects["IconGroupListItemUniqueWhere"],
	create?:PartialObjects["IconGroupListItemWithoutListCreateInput"],
	alias?:string
},
	["IconGroupListCreateResult"]: {
		__typename?: "IconGroupListCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconGroupList"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconGroupListDeleteResult"]: {
		__typename?: "IconGroupListDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconGroupList"]
	},
	["IconGroupListEdge"]: {
		__typename?: "IconGroupListEdge";
			node?:PartialObjects["IconGroupList"]
	},
	["IconGroupListItem"]: {
		__typename?: "IconGroupListItem";
			_meta?:PartialObjects["IconGroupListItemMeta"],
			id?:PartialObjects["UUID"],
			order?:number,
			list?:PartialObjects["IconGroupList"],
			group?:PartialObjects["IconGroup"]
	},
	["IconGroupListItemConnection"]: {
		__typename?: "IconGroupListItemConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["IconGroupListItemEdge"][]
	},
	["IconGroupListItemCreateGroupEntityRelationInput"]: {
	connect?:PartialObjects["IconGroupUniqueWhere"],
	create?:PartialObjects["IconGroupCreateInput"]
},
	["IconGroupListItemCreateInput"]: {
	order?:number,
	list?:PartialObjects["IconGroupListItemCreateListEntityRelationInput"],
	group?:PartialObjects["IconGroupListItemCreateGroupEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconGroupListItemCreateListEntityRelationInput"]: {
	connect?:PartialObjects["IconGroupListUniqueWhere"],
	create?:PartialObjects["IconGroupListWithoutItemsCreateInput"]
},
	["IconGroupListItemCreateResult"]: {
		__typename?: "IconGroupListItemCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconGroupListItem"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconGroupListItemDeleteResult"]: {
		__typename?: "IconGroupListItemDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconGroupListItem"]
	},
	["IconGroupListItemEdge"]: {
		__typename?: "IconGroupListItemEdge";
			node?:PartialObjects["IconGroupListItem"]
	},
	["IconGroupListItemMeta"]: {
		__typename?: "IconGroupListItemMeta";
			id?:PartialObjects["FieldMeta"],
			order?:PartialObjects["FieldMeta"],
			list?:PartialObjects["FieldMeta"],
			group?:PartialObjects["FieldMeta"]
	},
	["IconGroupListItemOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	order?:PartialObjects["OrderDirection"],
	list?:PartialObjects["IconGroupListOrderBy"],
	group?:PartialObjects["IconGroupOrderBy"]
},
	["IconGroupListItemUniqueWhere"]: {
	id?:PartialObjects["UUID"]
},
	["IconGroupListItemUpdateGroupEntityRelationInput"]: {
	create?:PartialObjects["IconGroupCreateInput"],
	update?:PartialObjects["IconGroupUpdateInput"],
	upsert?:PartialObjects["IconGroupListItemUpsertGroupRelationInput"],
	connect?:PartialObjects["IconGroupUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["IconGroupListItemUpdateInput"]: {
	order?:number,
	list?:PartialObjects["IconGroupListItemUpdateListEntityRelationInput"],
	group?:PartialObjects["IconGroupListItemUpdateGroupEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconGroupListItemUpdateListEntityRelationInput"]: {
	create?:PartialObjects["IconGroupListWithoutItemsCreateInput"],
	update?:PartialObjects["IconGroupListWithoutItemsUpdateInput"],
	upsert?:PartialObjects["IconGroupListItemUpsertListRelationInput"],
	connect?:PartialObjects["IconGroupListUniqueWhere"]
},
	["IconGroupListItemUpdateResult"]: {
		__typename?: "IconGroupListItemUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconGroupListItem"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconGroupListItemUpsertGroupRelationInput"]: {
	update?:PartialObjects["IconGroupUpdateInput"],
	create?:PartialObjects["IconGroupCreateInput"]
},
	["IconGroupListItemUpsertListRelationInput"]: {
	update?:PartialObjects["IconGroupListWithoutItemsUpdateInput"],
	create?:PartialObjects["IconGroupListWithoutItemsCreateInput"]
},
	["IconGroupListItemWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	order?:PartialObjects["IntCondition"],
	list?:PartialObjects["IconGroupListWhere"],
	group?:PartialObjects["IconGroupWhere"],
	and?:PartialObjects["IconGroupListItemWhere"][],
	or?:PartialObjects["IconGroupListItemWhere"][],
	not?:PartialObjects["IconGroupListItemWhere"]
},
	["IconGroupListItemWithoutListCreateInput"]: {
	order?:number,
	group?:PartialObjects["IconGroupListItemCreateGroupEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconGroupListItemWithoutListUpdateInput"]: {
	order?:number,
	group?:PartialObjects["IconGroupListItemUpdateGroupEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconGroupListMeta"]: {
		__typename?: "IconGroupListMeta";
			id?:PartialObjects["FieldMeta"],
			items?:PartialObjects["FieldMeta"],
			title?:PartialObjects["FieldMeta"]
	},
	["IconGroupListOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	title?:PartialObjects["OrderDirection"]
},
	["IconGroupListUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	items?:PartialObjects["IconGroupListItemUniqueWhere"]
},
	["IconGroupListUpdateInput"]: {
	items?:PartialObjects["IconGroupListUpdateItemsEntityRelationInput"][],
	title?:string,
	_dummy_field_?:boolean
},
	["IconGroupListUpdateItemsEntityRelationInput"]: {
	create?:PartialObjects["IconGroupListItemWithoutListCreateInput"],
	update?:PartialObjects["IconGroupListUpdateItemsRelationInput"],
	upsert?:PartialObjects["IconGroupListUpsertItemsRelationInput"],
	connect?:PartialObjects["IconGroupListItemUniqueWhere"],
	disconnect?:PartialObjects["IconGroupListItemUniqueWhere"],
	delete?:PartialObjects["IconGroupListItemUniqueWhere"],
	alias?:string
},
	["IconGroupListUpdateItemsRelationInput"]: {
	by?:PartialObjects["IconGroupListItemUniqueWhere"],
	data?:PartialObjects["IconGroupListItemWithoutListUpdateInput"]
},
	["IconGroupListUpdateResult"]: {
		__typename?: "IconGroupListUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconGroupList"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconGroupListUpsertItemsRelationInput"]: {
	by?:PartialObjects["IconGroupListItemUniqueWhere"],
	update?:PartialObjects["IconGroupListItemWithoutListUpdateInput"],
	create?:PartialObjects["IconGroupListItemWithoutListCreateInput"]
},
	["IconGroupListWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	items?:PartialObjects["IconGroupListItemWhere"],
	title?:PartialObjects["StringCondition"],
	and?:PartialObjects["IconGroupListWhere"][],
	or?:PartialObjects["IconGroupListWhere"][],
	not?:PartialObjects["IconGroupListWhere"]
},
	["IconGroupListWithoutItemsCreateInput"]: {
	title?:string,
	_dummy_field_?:boolean
},
	["IconGroupListWithoutItemsUpdateInput"]: {
	title?:string,
	_dummy_field_?:boolean
},
	["IconGroupLocale"]: {
		__typename?: "IconGroupLocale";
			_meta?:PartialObjects["IconGroupLocaleMeta"],
			id?:PartialObjects["UUID"],
			title?:string,
			root?:PartialObjects["IconGroup"],
			locale?:PartialObjects["Locale"]
	},
	["IconGroupLocaleConnection"]: {
		__typename?: "IconGroupLocaleConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["IconGroupLocaleEdge"][]
	},
	["IconGroupLocaleCreateInput"]: {
	title?:string,
	root?:PartialObjects["IconGroupLocaleCreateRootEntityRelationInput"],
	locale?:PartialObjects["IconGroupLocaleCreateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconGroupLocaleCreateLocaleEntityRelationInput"]: {
	connect?:PartialObjects["LocaleUniqueWhere"],
	create?:PartialObjects["LocaleWithoutIconGroupsCreateInput"]
},
	["IconGroupLocaleCreateResult"]: {
		__typename?: "IconGroupLocaleCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconGroupLocale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconGroupLocaleCreateRootEntityRelationInput"]: {
	connect?:PartialObjects["IconGroupUniqueWhere"],
	create?:PartialObjects["IconGroupWithoutLocalesCreateInput"]
},
	["IconGroupLocaleDeleteResult"]: {
		__typename?: "IconGroupLocaleDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconGroupLocale"]
	},
	["IconGroupLocaleEdge"]: {
		__typename?: "IconGroupLocaleEdge";
			node?:PartialObjects["IconGroupLocale"]
	},
	["IconGroupLocaleMeta"]: {
		__typename?: "IconGroupLocaleMeta";
			id?:PartialObjects["FieldMeta"],
			title?:PartialObjects["FieldMeta"],
			root?:PartialObjects["FieldMeta"],
			locale?:PartialObjects["FieldMeta"]
	},
	["IconGroupLocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	title?:PartialObjects["OrderDirection"],
	root?:PartialObjects["IconGroupOrderBy"],
	locale?:PartialObjects["LocaleOrderBy"]
},
	["IconGroupLocalesByLocaleUniqueWhere"]: {
	locale?:PartialObjects["LocaleUniqueWhere"]
},
	["IconGroupLocaleUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	root?:PartialObjects["IconGroupUniqueWhere"],
	locale?:PartialObjects["LocaleUniqueWhere"]
},
	["IconGroupLocaleUpdateInput"]: {
	title?:string,
	root?:PartialObjects["IconGroupLocaleUpdateRootEntityRelationInput"],
	locale?:PartialObjects["IconGroupLocaleUpdateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconGroupLocaleUpdateLocaleEntityRelationInput"]: {
	create?:PartialObjects["LocaleWithoutIconGroupsCreateInput"],
	update?:PartialObjects["LocaleWithoutIconGroupsUpdateInput"],
	upsert?:PartialObjects["IconGroupLocaleUpsertLocaleRelationInput"],
	connect?:PartialObjects["LocaleUniqueWhere"]
},
	["IconGroupLocaleUpdateResult"]: {
		__typename?: "IconGroupLocaleUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconGroupLocale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconGroupLocaleUpdateRootEntityRelationInput"]: {
	create?:PartialObjects["IconGroupWithoutLocalesCreateInput"],
	update?:PartialObjects["IconGroupWithoutLocalesUpdateInput"],
	upsert?:PartialObjects["IconGroupLocaleUpsertRootRelationInput"],
	connect?:PartialObjects["IconGroupUniqueWhere"]
},
	["IconGroupLocaleUpsertLocaleRelationInput"]: {
	update?:PartialObjects["LocaleWithoutIconGroupsUpdateInput"],
	create?:PartialObjects["LocaleWithoutIconGroupsCreateInput"]
},
	["IconGroupLocaleUpsertRootRelationInput"]: {
	update?:PartialObjects["IconGroupWithoutLocalesUpdateInput"],
	create?:PartialObjects["IconGroupWithoutLocalesCreateInput"]
},
	["IconGroupLocaleWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	title?:PartialObjects["StringCondition"],
	root?:PartialObjects["IconGroupWhere"],
	locale?:PartialObjects["LocaleWhere"],
	and?:PartialObjects["IconGroupLocaleWhere"][],
	or?:PartialObjects["IconGroupLocaleWhere"][],
	not?:PartialObjects["IconGroupLocaleWhere"]
},
	["IconGroupLocaleWithoutLocaleCreateInput"]: {
	title?:string,
	root?:PartialObjects["IconGroupLocaleCreateRootEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconGroupLocaleWithoutLocaleUpdateInput"]: {
	title?:string,
	root?:PartialObjects["IconGroupLocaleUpdateRootEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconGroupLocaleWithoutRootCreateInput"]: {
	title?:string,
	locale?:PartialObjects["IconGroupLocaleCreateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconGroupLocaleWithoutRootUpdateInput"]: {
	title?:string,
	locale?:PartialObjects["IconGroupLocaleUpdateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconGroupMeta"]: {
		__typename?: "IconGroupMeta";
			id?:PartialObjects["FieldMeta"],
			icons?:PartialObjects["FieldMeta"],
			locales?:PartialObjects["FieldMeta"],
			title?:PartialObjects["FieldMeta"]
	},
	["IconGroupOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	icons?:PartialObjects["IconListOrderBy"],
	title?:PartialObjects["OrderDirection"]
},
	["IconGroupUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	locales?:PartialObjects["IconGroupLocaleUniqueWhere"]
},
	["IconGroupUpdateIconsEntityRelationInput"]: {
	create?:PartialObjects["IconListCreateInput"],
	update?:PartialObjects["IconListUpdateInput"],
	upsert?:PartialObjects["IconGroupUpsertIconsRelationInput"],
	connect?:PartialObjects["IconListUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["IconGroupUpdateInput"]: {
	icons?:PartialObjects["IconGroupUpdateIconsEntityRelationInput"],
	locales?:PartialObjects["IconGroupUpdateLocalesEntityRelationInput"][],
	title?:string,
	_dummy_field_?:boolean
},
	["IconGroupUpdateLocalesEntityRelationInput"]: {
	create?:PartialObjects["IconGroupLocaleWithoutRootCreateInput"],
	update?:PartialObjects["IconGroupUpdateLocalesRelationInput"],
	upsert?:PartialObjects["IconGroupUpsertLocalesRelationInput"],
	connect?:PartialObjects["IconGroupLocaleUniqueWhere"],
	disconnect?:PartialObjects["IconGroupLocaleUniqueWhere"],
	delete?:PartialObjects["IconGroupLocaleUniqueWhere"],
	alias?:string
},
	["IconGroupUpdateLocalesRelationInput"]: {
	by?:PartialObjects["IconGroupLocaleUniqueWhere"],
	data?:PartialObjects["IconGroupLocaleWithoutRootUpdateInput"]
},
	["IconGroupUpdateResult"]: {
		__typename?: "IconGroupUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconGroup"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconGroupUpsertIconsRelationInput"]: {
	update?:PartialObjects["IconListUpdateInput"],
	create?:PartialObjects["IconListCreateInput"]
},
	["IconGroupUpsertLocalesRelationInput"]: {
	by?:PartialObjects["IconGroupLocaleUniqueWhere"],
	update?:PartialObjects["IconGroupLocaleWithoutRootUpdateInput"],
	create?:PartialObjects["IconGroupLocaleWithoutRootCreateInput"]
},
	["IconGroupWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	icons?:PartialObjects["IconListWhere"],
	locales?:PartialObjects["IconGroupLocaleWhere"],
	title?:PartialObjects["StringCondition"],
	and?:PartialObjects["IconGroupWhere"][],
	or?:PartialObjects["IconGroupWhere"][],
	not?:PartialObjects["IconGroupWhere"]
},
	["IconGroupWithoutLocalesCreateInput"]: {
	icons?:PartialObjects["IconGroupCreateIconsEntityRelationInput"],
	title?:string,
	_dummy_field_?:boolean
},
	["IconGroupWithoutLocalesUpdateInput"]: {
	icons?:PartialObjects["IconGroupUpdateIconsEntityRelationInput"],
	title?:string,
	_dummy_field_?:boolean
},
	["IconList"]: {
		__typename?: "IconList";
			_meta?:PartialObjects["IconListMeta"],
			id?:PartialObjects["UUID"],
			items?:PartialObjects["IconListItem"][]
	},
	["IconListBlock"]: {
		__typename?: "IconListBlock";
			_meta?:PartialObjects["IconListBlockMeta"],
			id?:PartialObjects["UUID"],
			items?:PartialObjects["IconListBlockItem"][]
	},
	["IconListBlockConnection"]: {
		__typename?: "IconListBlockConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["IconListBlockEdge"][]
	},
	["IconListBlockCreateInput"]: {
	items?:PartialObjects["IconListBlockCreateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["IconListBlockCreateItemsEntityRelationInput"]: {
	connect?:PartialObjects["IconListBlockItemUniqueWhere"],
	create?:PartialObjects["IconListBlockItemWithoutListCreateInput"],
	alias?:string
},
	["IconListBlockCreateResult"]: {
		__typename?: "IconListBlockCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconListBlock"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconListBlockDeleteResult"]: {
		__typename?: "IconListBlockDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconListBlock"]
	},
	["IconListBlockEdge"]: {
		__typename?: "IconListBlockEdge";
			node?:PartialObjects["IconListBlock"]
	},
	["IconListBlockItem"]: {
		__typename?: "IconListBlockItem";
			_meta?:PartialObjects["IconListBlockItemMeta"],
			id?:PartialObjects["UUID"],
			order?:number,
			text?:string,
			list?:PartialObjects["IconListBlock"],
			icon?:PartialObjects["Image"]
	},
	["IconListBlockItemConnection"]: {
		__typename?: "IconListBlockItemConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["IconListBlockItemEdge"][]
	},
	["IconListBlockItemCreateIconEntityRelationInput"]: {
	connect?:PartialObjects["ImageUniqueWhere"],
	create?:PartialObjects["ImageCreateInput"]
},
	["IconListBlockItemCreateInput"]: {
	order?:number,
	text?:string,
	list?:PartialObjects["IconListBlockItemCreateListEntityRelationInput"],
	icon?:PartialObjects["IconListBlockItemCreateIconEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconListBlockItemCreateListEntityRelationInput"]: {
	connect?:PartialObjects["IconListBlockUniqueWhere"],
	create?:PartialObjects["IconListBlockWithoutItemsCreateInput"]
},
	["IconListBlockItemCreateResult"]: {
		__typename?: "IconListBlockItemCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconListBlockItem"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconListBlockItemDeleteResult"]: {
		__typename?: "IconListBlockItemDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconListBlockItem"]
	},
	["IconListBlockItemEdge"]: {
		__typename?: "IconListBlockItemEdge";
			node?:PartialObjects["IconListBlockItem"]
	},
	["IconListBlockItemMeta"]: {
		__typename?: "IconListBlockItemMeta";
			id?:PartialObjects["FieldMeta"],
			order?:PartialObjects["FieldMeta"],
			text?:PartialObjects["FieldMeta"],
			list?:PartialObjects["FieldMeta"],
			icon?:PartialObjects["FieldMeta"]
	},
	["IconListBlockItemOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	order?:PartialObjects["OrderDirection"],
	text?:PartialObjects["OrderDirection"],
	list?:PartialObjects["IconListBlockOrderBy"],
	icon?:PartialObjects["ImageOrderBy"]
},
	["IconListBlockItemUniqueWhere"]: {
	id?:PartialObjects["UUID"]
},
	["IconListBlockItemUpdateIconEntityRelationInput"]: {
	create?:PartialObjects["ImageCreateInput"],
	update?:PartialObjects["ImageUpdateInput"],
	upsert?:PartialObjects["IconListBlockItemUpsertIconRelationInput"],
	connect?:PartialObjects["ImageUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["IconListBlockItemUpdateInput"]: {
	order?:number,
	text?:string,
	list?:PartialObjects["IconListBlockItemUpdateListEntityRelationInput"],
	icon?:PartialObjects["IconListBlockItemUpdateIconEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconListBlockItemUpdateListEntityRelationInput"]: {
	create?:PartialObjects["IconListBlockWithoutItemsCreateInput"],
	update?:PartialObjects["IconListBlockWithoutItemsUpdateInput"],
	upsert?:PartialObjects["IconListBlockItemUpsertListRelationInput"],
	connect?:PartialObjects["IconListBlockUniqueWhere"]
},
	["IconListBlockItemUpdateResult"]: {
		__typename?: "IconListBlockItemUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconListBlockItem"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconListBlockItemUpsertIconRelationInput"]: {
	update?:PartialObjects["ImageUpdateInput"],
	create?:PartialObjects["ImageCreateInput"]
},
	["IconListBlockItemUpsertListRelationInput"]: {
	update?:PartialObjects["IconListBlockWithoutItemsUpdateInput"],
	create?:PartialObjects["IconListBlockWithoutItemsCreateInput"]
},
	["IconListBlockItemWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	order?:PartialObjects["IntCondition"],
	text?:PartialObjects["StringCondition"],
	list?:PartialObjects["IconListBlockWhere"],
	icon?:PartialObjects["ImageWhere"],
	and?:PartialObjects["IconListBlockItemWhere"][],
	or?:PartialObjects["IconListBlockItemWhere"][],
	not?:PartialObjects["IconListBlockItemWhere"]
},
	["IconListBlockItemWithoutListCreateInput"]: {
	order?:number,
	text?:string,
	icon?:PartialObjects["IconListBlockItemCreateIconEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconListBlockItemWithoutListUpdateInput"]: {
	order?:number,
	text?:string,
	icon?:PartialObjects["IconListBlockItemUpdateIconEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconListBlockMeta"]: {
		__typename?: "IconListBlockMeta";
			id?:PartialObjects["FieldMeta"],
			items?:PartialObjects["FieldMeta"]
	},
	["IconListBlockOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"]
},
	["IconListBlockUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	items?:PartialObjects["IconListBlockItemUniqueWhere"]
},
	["IconListBlockUpdateInput"]: {
	items?:PartialObjects["IconListBlockUpdateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["IconListBlockUpdateItemsEntityRelationInput"]: {
	create?:PartialObjects["IconListBlockItemWithoutListCreateInput"],
	update?:PartialObjects["IconListBlockUpdateItemsRelationInput"],
	upsert?:PartialObjects["IconListBlockUpsertItemsRelationInput"],
	connect?:PartialObjects["IconListBlockItemUniqueWhere"],
	disconnect?:PartialObjects["IconListBlockItemUniqueWhere"],
	delete?:PartialObjects["IconListBlockItemUniqueWhere"],
	alias?:string
},
	["IconListBlockUpdateItemsRelationInput"]: {
	by?:PartialObjects["IconListBlockItemUniqueWhere"],
	data?:PartialObjects["IconListBlockItemWithoutListUpdateInput"]
},
	["IconListBlockUpdateResult"]: {
		__typename?: "IconListBlockUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconListBlock"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconListBlockUpsertItemsRelationInput"]: {
	by?:PartialObjects["IconListBlockItemUniqueWhere"],
	update?:PartialObjects["IconListBlockItemWithoutListUpdateInput"],
	create?:PartialObjects["IconListBlockItemWithoutListCreateInput"]
},
	["IconListBlockWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	items?:PartialObjects["IconListBlockItemWhere"],
	and?:PartialObjects["IconListBlockWhere"][],
	or?:PartialObjects["IconListBlockWhere"][],
	not?:PartialObjects["IconListBlockWhere"]
},
	["IconListBlockWithoutItemsCreateInput"]: {
	_dummy_field_?:boolean
},
	["IconListBlockWithoutItemsUpdateInput"]: {
	_dummy_field_?:boolean
},
	["IconListConnection"]: {
		__typename?: "IconListConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["IconListEdge"][]
	},
	["IconListCreateInput"]: {
	items?:PartialObjects["IconListCreateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["IconListCreateItemsEntityRelationInput"]: {
	connect?:PartialObjects["IconListItemUniqueWhere"],
	create?:PartialObjects["IconListItemWithoutListCreateInput"],
	alias?:string
},
	["IconListCreateResult"]: {
		__typename?: "IconListCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconList"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconListDeleteResult"]: {
		__typename?: "IconListDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconList"]
	},
	["IconListEdge"]: {
		__typename?: "IconListEdge";
			node?:PartialObjects["IconList"]
	},
	["IconListItem"]: {
		__typename?: "IconListItem";
			_meta?:PartialObjects["IconListItemMeta"],
			id?:PartialObjects["UUID"],
			order?:number,
			list?:PartialObjects["IconList"],
			icon?:PartialObjects["Icon"]
	},
	["IconListItemConnection"]: {
		__typename?: "IconListItemConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["IconListItemEdge"][]
	},
	["IconListItemCreateIconEntityRelationInput"]: {
	connect?:PartialObjects["IconUniqueWhere"],
	create?:PartialObjects["IconCreateInput"]
},
	["IconListItemCreateInput"]: {
	order?:number,
	list?:PartialObjects["IconListItemCreateListEntityRelationInput"],
	icon?:PartialObjects["IconListItemCreateIconEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconListItemCreateListEntityRelationInput"]: {
	connect?:PartialObjects["IconListUniqueWhere"],
	create?:PartialObjects["IconListWithoutItemsCreateInput"]
},
	["IconListItemCreateResult"]: {
		__typename?: "IconListItemCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconListItem"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconListItemDeleteResult"]: {
		__typename?: "IconListItemDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconListItem"]
	},
	["IconListItemEdge"]: {
		__typename?: "IconListItemEdge";
			node?:PartialObjects["IconListItem"]
	},
	["IconListItemMeta"]: {
		__typename?: "IconListItemMeta";
			id?:PartialObjects["FieldMeta"],
			order?:PartialObjects["FieldMeta"],
			list?:PartialObjects["FieldMeta"],
			icon?:PartialObjects["FieldMeta"]
	},
	["IconListItemOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	order?:PartialObjects["OrderDirection"],
	list?:PartialObjects["IconListOrderBy"],
	icon?:PartialObjects["IconOrderBy"]
},
	["IconListItemUniqueWhere"]: {
	id?:PartialObjects["UUID"]
},
	["IconListItemUpdateIconEntityRelationInput"]: {
	create?:PartialObjects["IconCreateInput"],
	update?:PartialObjects["IconUpdateInput"],
	upsert?:PartialObjects["IconListItemUpsertIconRelationInput"],
	connect?:PartialObjects["IconUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["IconListItemUpdateInput"]: {
	order?:number,
	list?:PartialObjects["IconListItemUpdateListEntityRelationInput"],
	icon?:PartialObjects["IconListItemUpdateIconEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconListItemUpdateListEntityRelationInput"]: {
	create?:PartialObjects["IconListWithoutItemsCreateInput"],
	update?:PartialObjects["IconListWithoutItemsUpdateInput"],
	upsert?:PartialObjects["IconListItemUpsertListRelationInput"],
	connect?:PartialObjects["IconListUniqueWhere"]
},
	["IconListItemUpdateResult"]: {
		__typename?: "IconListItemUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconListItem"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconListItemUpsertIconRelationInput"]: {
	update?:PartialObjects["IconUpdateInput"],
	create?:PartialObjects["IconCreateInput"]
},
	["IconListItemUpsertListRelationInput"]: {
	update?:PartialObjects["IconListWithoutItemsUpdateInput"],
	create?:PartialObjects["IconListWithoutItemsCreateInput"]
},
	["IconListItemWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	order?:PartialObjects["IntCondition"],
	list?:PartialObjects["IconListWhere"],
	icon?:PartialObjects["IconWhere"],
	and?:PartialObjects["IconListItemWhere"][],
	or?:PartialObjects["IconListItemWhere"][],
	not?:PartialObjects["IconListItemWhere"]
},
	["IconListItemWithoutListCreateInput"]: {
	order?:number,
	icon?:PartialObjects["IconListItemCreateIconEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconListItemWithoutListUpdateInput"]: {
	order?:number,
	icon?:PartialObjects["IconListItemUpdateIconEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconListMeta"]: {
		__typename?: "IconListMeta";
			id?:PartialObjects["FieldMeta"],
			items?:PartialObjects["FieldMeta"]
	},
	["IconListOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"]
},
	["IconListUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	items?:PartialObjects["IconListItemUniqueWhere"]
},
	["IconListUpdateInput"]: {
	items?:PartialObjects["IconListUpdateItemsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["IconListUpdateItemsEntityRelationInput"]: {
	create?:PartialObjects["IconListItemWithoutListCreateInput"],
	update?:PartialObjects["IconListUpdateItemsRelationInput"],
	upsert?:PartialObjects["IconListUpsertItemsRelationInput"],
	connect?:PartialObjects["IconListItemUniqueWhere"],
	disconnect?:PartialObjects["IconListItemUniqueWhere"],
	delete?:PartialObjects["IconListItemUniqueWhere"],
	alias?:string
},
	["IconListUpdateItemsRelationInput"]: {
	by?:PartialObjects["IconListItemUniqueWhere"],
	data?:PartialObjects["IconListItemWithoutListUpdateInput"]
},
	["IconListUpdateResult"]: {
		__typename?: "IconListUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconList"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconListUpsertItemsRelationInput"]: {
	by?:PartialObjects["IconListItemUniqueWhere"],
	update?:PartialObjects["IconListItemWithoutListUpdateInput"],
	create?:PartialObjects["IconListItemWithoutListCreateInput"]
},
	["IconListWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	items?:PartialObjects["IconListItemWhere"],
	and?:PartialObjects["IconListWhere"][],
	or?:PartialObjects["IconListWhere"][],
	not?:PartialObjects["IconListWhere"]
},
	["IconListWithoutItemsCreateInput"]: {
	_dummy_field_?:boolean
},
	["IconListWithoutItemsUpdateInput"]: {
	_dummy_field_?:boolean
},
	["IconLocale"]: {
		__typename?: "IconLocale";
			_meta?:PartialObjects["IconLocaleMeta"],
			id?:PartialObjects["UUID"],
			title?:string,
			alt?:string,
			root?:PartialObjects["Icon"],
			locale?:PartialObjects["Locale"]
	},
	["IconLocaleConnection"]: {
		__typename?: "IconLocaleConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["IconLocaleEdge"][]
	},
	["IconLocaleCreateInput"]: {
	title?:string,
	alt?:string,
	root?:PartialObjects["IconLocaleCreateRootEntityRelationInput"],
	locale?:PartialObjects["IconLocaleCreateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconLocaleCreateLocaleEntityRelationInput"]: {
	connect?:PartialObjects["LocaleUniqueWhere"],
	create?:PartialObjects["LocaleWithoutIconsCreateInput"]
},
	["IconLocaleCreateResult"]: {
		__typename?: "IconLocaleCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconLocale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconLocaleCreateRootEntityRelationInput"]: {
	connect?:PartialObjects["IconUniqueWhere"],
	create?:PartialObjects["IconWithoutLocalesCreateInput"]
},
	["IconLocaleDeleteResult"]: {
		__typename?: "IconLocaleDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconLocale"]
	},
	["IconLocaleEdge"]: {
		__typename?: "IconLocaleEdge";
			node?:PartialObjects["IconLocale"]
	},
	["IconLocaleMeta"]: {
		__typename?: "IconLocaleMeta";
			id?:PartialObjects["FieldMeta"],
			title?:PartialObjects["FieldMeta"],
			alt?:PartialObjects["FieldMeta"],
			root?:PartialObjects["FieldMeta"],
			locale?:PartialObjects["FieldMeta"]
	},
	["IconLocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	title?:PartialObjects["OrderDirection"],
	alt?:PartialObjects["OrderDirection"],
	root?:PartialObjects["IconOrderBy"],
	locale?:PartialObjects["LocaleOrderBy"]
},
	["IconLocalesByLocaleUniqueWhere"]: {
	locale?:PartialObjects["LocaleUniqueWhere"]
},
	["IconLocaleUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	root?:PartialObjects["IconUniqueWhere"],
	locale?:PartialObjects["LocaleUniqueWhere"]
},
	["IconLocaleUpdateInput"]: {
	title?:string,
	alt?:string,
	root?:PartialObjects["IconLocaleUpdateRootEntityRelationInput"],
	locale?:PartialObjects["IconLocaleUpdateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconLocaleUpdateLocaleEntityRelationInput"]: {
	create?:PartialObjects["LocaleWithoutIconsCreateInput"],
	update?:PartialObjects["LocaleWithoutIconsUpdateInput"],
	upsert?:PartialObjects["IconLocaleUpsertLocaleRelationInput"],
	connect?:PartialObjects["LocaleUniqueWhere"]
},
	["IconLocaleUpdateResult"]: {
		__typename?: "IconLocaleUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["IconLocale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconLocaleUpdateRootEntityRelationInput"]: {
	create?:PartialObjects["IconWithoutLocalesCreateInput"],
	update?:PartialObjects["IconWithoutLocalesUpdateInput"],
	upsert?:PartialObjects["IconLocaleUpsertRootRelationInput"],
	connect?:PartialObjects["IconUniqueWhere"]
},
	["IconLocaleUpsertLocaleRelationInput"]: {
	update?:PartialObjects["LocaleWithoutIconsUpdateInput"],
	create?:PartialObjects["LocaleWithoutIconsCreateInput"]
},
	["IconLocaleUpsertRootRelationInput"]: {
	update?:PartialObjects["IconWithoutLocalesUpdateInput"],
	create?:PartialObjects["IconWithoutLocalesCreateInput"]
},
	["IconLocaleWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	title?:PartialObjects["StringCondition"],
	alt?:PartialObjects["StringCondition"],
	root?:PartialObjects["IconWhere"],
	locale?:PartialObjects["LocaleWhere"],
	and?:PartialObjects["IconLocaleWhere"][],
	or?:PartialObjects["IconLocaleWhere"][],
	not?:PartialObjects["IconLocaleWhere"]
},
	["IconLocaleWithoutLocaleCreateInput"]: {
	title?:string,
	alt?:string,
	root?:PartialObjects["IconLocaleCreateRootEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconLocaleWithoutLocaleUpdateInput"]: {
	title?:string,
	alt?:string,
	root?:PartialObjects["IconLocaleUpdateRootEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconLocaleWithoutRootCreateInput"]: {
	title?:string,
	alt?:string,
	locale?:PartialObjects["IconLocaleCreateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconLocaleWithoutRootUpdateInput"]: {
	title?:string,
	alt?:string,
	locale?:PartialObjects["IconLocaleUpdateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconMeta"]: {
		__typename?: "IconMeta";
			id?:PartialObjects["FieldMeta"],
			key?:PartialObjects["FieldMeta"],
			image?:PartialObjects["FieldMeta"],
			locales?:PartialObjects["FieldMeta"]
	},
	["IconOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	key?:PartialObjects["OrderDirection"],
	image?:PartialObjects["ImageOrderBy"]
},
	["IconUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	locales?:PartialObjects["IconLocaleUniqueWhere"]
},
	["IconUpdateImageEntityRelationInput"]: {
	create?:PartialObjects["ImageCreateInput"],
	update?:PartialObjects["ImageUpdateInput"],
	upsert?:PartialObjects["IconUpsertImageRelationInput"],
	connect?:PartialObjects["ImageUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["IconUpdateInput"]: {
	key?:string,
	image?:PartialObjects["IconUpdateImageEntityRelationInput"],
	locales?:PartialObjects["IconUpdateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["IconUpdateLocalesEntityRelationInput"]: {
	create?:PartialObjects["IconLocaleWithoutRootCreateInput"],
	update?:PartialObjects["IconUpdateLocalesRelationInput"],
	upsert?:PartialObjects["IconUpsertLocalesRelationInput"],
	connect?:PartialObjects["IconLocaleUniqueWhere"],
	disconnect?:PartialObjects["IconLocaleUniqueWhere"],
	delete?:PartialObjects["IconLocaleUniqueWhere"],
	alias?:string
},
	["IconUpdateLocalesRelationInput"]: {
	by?:PartialObjects["IconLocaleUniqueWhere"],
	data?:PartialObjects["IconLocaleWithoutRootUpdateInput"]
},
	["IconUpdateResult"]: {
		__typename?: "IconUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Icon"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["IconUpsertImageRelationInput"]: {
	update?:PartialObjects["ImageUpdateInput"],
	create?:PartialObjects["ImageCreateInput"]
},
	["IconUpsertLocalesRelationInput"]: {
	by?:PartialObjects["IconLocaleUniqueWhere"],
	update?:PartialObjects["IconLocaleWithoutRootUpdateInput"],
	create?:PartialObjects["IconLocaleWithoutRootCreateInput"]
},
	["IconWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	key?:PartialObjects["StringCondition"],
	image?:PartialObjects["ImageWhere"],
	locales?:PartialObjects["IconLocaleWhere"],
	and?:PartialObjects["IconWhere"][],
	or?:PartialObjects["IconWhere"][],
	not?:PartialObjects["IconWhere"]
},
	["IconWithoutLocalesCreateInput"]: {
	key?:string,
	image?:PartialObjects["IconCreateImageEntityRelationInput"],
	_dummy_field_?:boolean
},
	["IconWithoutLocalesUpdateInput"]: {
	key?:string,
	image?:PartialObjects["IconUpdateImageEntityRelationInput"],
	_dummy_field_?:boolean
},
	["Image"]: {
		__typename?: "Image";
			_meta?:PartialObjects["ImageMeta"],
			id?:PartialObjects["UUID"],
			url?:string,
			width?:number,
			height?:number,
			size?:number,
			type?:string,
			title?:string,
			alt?:string,
			decorationPreset?:string
	},
	["ImageConnection"]: {
		__typename?: "ImageConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["ImageEdge"][]
	},
	["ImageCreateInput"]: {
	url?:string,
	width?:number,
	height?:number,
	size?:number,
	type?:string,
	title?:string,
	alt?:string,
	decorationPreset?:string,
	_dummy_field_?:boolean
},
	["ImageCreateResult"]: {
		__typename?: "ImageCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Image"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ImageDeleteResult"]: {
		__typename?: "ImageDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Image"]
	},
	["ImageEdge"]: {
		__typename?: "ImageEdge";
			node?:PartialObjects["Image"]
	},
	["ImageList"]: {
		__typename?: "ImageList";
			_meta?:PartialObjects["ImageListMeta"],
			id?:PartialObjects["UUID"],
			images?:PartialObjects["ImageXImageList"][]
	},
	["ImageListConnection"]: {
		__typename?: "ImageListConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["ImageListEdge"][]
	},
	["ImageListCreateImagesEntityRelationInput"]: {
	connect?:PartialObjects["ImageXImageListUniqueWhere"],
	create?:PartialObjects["ImageXImageListWithoutImageListCreateInput"],
	alias?:string
},
	["ImageListCreateInput"]: {
	images?:PartialObjects["ImageListCreateImagesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["ImageListCreateResult"]: {
		__typename?: "ImageListCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ImageList"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ImageListDeleteResult"]: {
		__typename?: "ImageListDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ImageList"]
	},
	["ImageListEdge"]: {
		__typename?: "ImageListEdge";
			node?:PartialObjects["ImageList"]
	},
	["ImageListMeta"]: {
		__typename?: "ImageListMeta";
			id?:PartialObjects["FieldMeta"],
			images?:PartialObjects["FieldMeta"]
	},
	["ImageListOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"]
},
	["ImageListUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	images?:PartialObjects["ImageXImageListUniqueWhere"]
},
	["ImageListUpdateImagesEntityRelationInput"]: {
	create?:PartialObjects["ImageXImageListWithoutImageListCreateInput"],
	update?:PartialObjects["ImageListUpdateImagesRelationInput"],
	upsert?:PartialObjects["ImageListUpsertImagesRelationInput"],
	connect?:PartialObjects["ImageXImageListUniqueWhere"],
	disconnect?:PartialObjects["ImageXImageListUniqueWhere"],
	delete?:PartialObjects["ImageXImageListUniqueWhere"],
	alias?:string
},
	["ImageListUpdateImagesRelationInput"]: {
	by?:PartialObjects["ImageXImageListUniqueWhere"],
	data?:PartialObjects["ImageXImageListWithoutImageListUpdateInput"]
},
	["ImageListUpdateInput"]: {
	images?:PartialObjects["ImageListUpdateImagesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["ImageListUpdateResult"]: {
		__typename?: "ImageListUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ImageList"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ImageListUpsertImagesRelationInput"]: {
	by?:PartialObjects["ImageXImageListUniqueWhere"],
	update?:PartialObjects["ImageXImageListWithoutImageListUpdateInput"],
	create?:PartialObjects["ImageXImageListWithoutImageListCreateInput"]
},
	["ImageListWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	images?:PartialObjects["ImageXImageListWhere"],
	and?:PartialObjects["ImageListWhere"][],
	or?:PartialObjects["ImageListWhere"][],
	not?:PartialObjects["ImageListWhere"]
},
	["ImageListWithoutImagesCreateInput"]: {
	_dummy_field_?:boolean
},
	["ImageListWithoutImagesUpdateInput"]: {
	_dummy_field_?:boolean
},
	["ImageLocale"]: {
		__typename?: "ImageLocale";
			_meta?:PartialObjects["ImageLocaleMeta"],
			id?:PartialObjects["UUID"],
			url?:string,
			width?:number,
			height?:number,
			size?:number,
			type?:string,
			title?:string,
			alt?:string,
			decorationPreset?:string
	},
	["ImageLocaleConnection"]: {
		__typename?: "ImageLocaleConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["ImageLocaleEdge"][]
	},
	["ImageLocaleCreateInput"]: {
	url?:string,
	width?:number,
	height?:number,
	size?:number,
	type?:string,
	title?:string,
	alt?:string,
	decorationPreset?:string,
	_dummy_field_?:boolean
},
	["ImageLocaleCreateResult"]: {
		__typename?: "ImageLocaleCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ImageLocale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ImageLocaleDeleteResult"]: {
		__typename?: "ImageLocaleDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ImageLocale"]
	},
	["ImageLocaleEdge"]: {
		__typename?: "ImageLocaleEdge";
			node?:PartialObjects["ImageLocale"]
	},
	["ImageLocaleMeta"]: {
		__typename?: "ImageLocaleMeta";
			id?:PartialObjects["FieldMeta"],
			url?:PartialObjects["FieldMeta"],
			width?:PartialObjects["FieldMeta"],
			height?:PartialObjects["FieldMeta"],
			size?:PartialObjects["FieldMeta"],
			type?:PartialObjects["FieldMeta"],
			title?:PartialObjects["FieldMeta"],
			alt?:PartialObjects["FieldMeta"],
			decorationPreset?:PartialObjects["FieldMeta"]
	},
	["ImageLocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	url?:PartialObjects["OrderDirection"],
	width?:PartialObjects["OrderDirection"],
	height?:PartialObjects["OrderDirection"],
	size?:PartialObjects["OrderDirection"],
	type?:PartialObjects["OrderDirection"],
	title?:PartialObjects["OrderDirection"],
	alt?:PartialObjects["OrderDirection"],
	decorationPreset?:PartialObjects["OrderDirection"]
},
	["ImageLocaleUniqueWhere"]: {
	id?:PartialObjects["UUID"]
},
	["ImageLocaleUpdateInput"]: {
	url?:string,
	width?:number,
	height?:number,
	size?:number,
	type?:string,
	title?:string,
	alt?:string,
	decorationPreset?:string,
	_dummy_field_?:boolean
},
	["ImageLocaleUpdateResult"]: {
		__typename?: "ImageLocaleUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ImageLocale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ImageLocaleWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	url?:PartialObjects["StringCondition"],
	width?:PartialObjects["IntCondition"],
	height?:PartialObjects["IntCondition"],
	size?:PartialObjects["IntCondition"],
	type?:PartialObjects["StringCondition"],
	title?:PartialObjects["StringCondition"],
	alt?:PartialObjects["StringCondition"],
	decorationPreset?:PartialObjects["StringCondition"],
	and?:PartialObjects["ImageLocaleWhere"][],
	or?:PartialObjects["ImageLocaleWhere"][],
	not?:PartialObjects["ImageLocaleWhere"]
},
	["ImageMeta"]: {
		__typename?: "ImageMeta";
			id?:PartialObjects["FieldMeta"],
			url?:PartialObjects["FieldMeta"],
			width?:PartialObjects["FieldMeta"],
			height?:PartialObjects["FieldMeta"],
			size?:PartialObjects["FieldMeta"],
			type?:PartialObjects["FieldMeta"],
			title?:PartialObjects["FieldMeta"],
			alt?:PartialObjects["FieldMeta"],
			decorationPreset?:PartialObjects["FieldMeta"]
	},
	["ImageOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	url?:PartialObjects["OrderDirection"],
	width?:PartialObjects["OrderDirection"],
	height?:PartialObjects["OrderDirection"],
	size?:PartialObjects["OrderDirection"],
	type?:PartialObjects["OrderDirection"],
	title?:PartialObjects["OrderDirection"],
	alt?:PartialObjects["OrderDirection"],
	decorationPreset?:PartialObjects["OrderDirection"]
},
	["ImageUniqueWhere"]: {
	id?:PartialObjects["UUID"]
},
	["ImageUpdateInput"]: {
	url?:string,
	width?:number,
	height?:number,
	size?:number,
	type?:string,
	title?:string,
	alt?:string,
	decorationPreset?:string,
	_dummy_field_?:boolean
},
	["ImageUpdateResult"]: {
		__typename?: "ImageUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Image"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ImageWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	url?:PartialObjects["StringCondition"],
	width?:PartialObjects["IntCondition"],
	height?:PartialObjects["IntCondition"],
	size?:PartialObjects["IntCondition"],
	type?:PartialObjects["StringCondition"],
	title?:PartialObjects["StringCondition"],
	alt?:PartialObjects["StringCondition"],
	decorationPreset?:PartialObjects["StringCondition"],
	and?:PartialObjects["ImageWhere"][],
	or?:PartialObjects["ImageWhere"][],
	not?:PartialObjects["ImageWhere"]
},
	["ImageXImageList"]: {
		__typename?: "ImageXImageList";
			_meta?:PartialObjects["ImageXImageListMeta"],
			id?:PartialObjects["UUID"],
			imagePosition?:number,
			imageList?:PartialObjects["ImageList"],
			image?:PartialObjects["Image"]
	},
	["ImageXImageListConnection"]: {
		__typename?: "ImageXImageListConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["ImageXImageListEdge"][]
	},
	["ImageXImageListCreateImageEntityRelationInput"]: {
	connect?:PartialObjects["ImageUniqueWhere"],
	create?:PartialObjects["ImageCreateInput"]
},
	["ImageXImageListCreateImageListEntityRelationInput"]: {
	connect?:PartialObjects["ImageListUniqueWhere"],
	create?:PartialObjects["ImageListWithoutImagesCreateInput"]
},
	["ImageXImageListCreateInput"]: {
	imagePosition?:number,
	imageList?:PartialObjects["ImageXImageListCreateImageListEntityRelationInput"],
	image?:PartialObjects["ImageXImageListCreateImageEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ImageXImageListCreateResult"]: {
		__typename?: "ImageXImageListCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ImageXImageList"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ImageXImageListDeleteResult"]: {
		__typename?: "ImageXImageListDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ImageXImageList"]
	},
	["ImageXImageListEdge"]: {
		__typename?: "ImageXImageListEdge";
			node?:PartialObjects["ImageXImageList"]
	},
	["ImageXImageListMeta"]: {
		__typename?: "ImageXImageListMeta";
			id?:PartialObjects["FieldMeta"],
			imagePosition?:PartialObjects["FieldMeta"],
			imageList?:PartialObjects["FieldMeta"],
			image?:PartialObjects["FieldMeta"]
	},
	["ImageXImageListOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	imagePosition?:PartialObjects["OrderDirection"],
	imageList?:PartialObjects["ImageListOrderBy"],
	image?:PartialObjects["ImageOrderBy"]
},
	["ImageXImageListUniqueWhere"]: {
	id?:PartialObjects["UUID"]
},
	["ImageXImageListUpdateImageEntityRelationInput"]: {
	create?:PartialObjects["ImageCreateInput"],
	update?:PartialObjects["ImageUpdateInput"],
	upsert?:PartialObjects["ImageXImageListUpsertImageRelationInput"],
	connect?:PartialObjects["ImageUniqueWhere"]
},
	["ImageXImageListUpdateImageListEntityRelationInput"]: {
	create?:PartialObjects["ImageListWithoutImagesCreateInput"],
	update?:PartialObjects["ImageListWithoutImagesUpdateInput"],
	upsert?:PartialObjects["ImageXImageListUpsertImageListRelationInput"],
	connect?:PartialObjects["ImageListUniqueWhere"]
},
	["ImageXImageListUpdateInput"]: {
	imagePosition?:number,
	imageList?:PartialObjects["ImageXImageListUpdateImageListEntityRelationInput"],
	image?:PartialObjects["ImageXImageListUpdateImageEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ImageXImageListUpdateResult"]: {
		__typename?: "ImageXImageListUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ImageXImageList"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ImageXImageListUpsertImageListRelationInput"]: {
	update?:PartialObjects["ImageListWithoutImagesUpdateInput"],
	create?:PartialObjects["ImageListWithoutImagesCreateInput"]
},
	["ImageXImageListUpsertImageRelationInput"]: {
	update?:PartialObjects["ImageUpdateInput"],
	create?:PartialObjects["ImageCreateInput"]
},
	["ImageXImageListWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	imagePosition?:PartialObjects["IntCondition"],
	imageList?:PartialObjects["ImageListWhere"],
	image?:PartialObjects["ImageWhere"],
	and?:PartialObjects["ImageXImageListWhere"][],
	or?:PartialObjects["ImageXImageListWhere"][],
	not?:PartialObjects["ImageXImageListWhere"]
},
	["ImageXImageListWithoutImageListCreateInput"]: {
	imagePosition?:number,
	image?:PartialObjects["ImageXImageListCreateImageEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ImageXImageListWithoutImageListUpdateInput"]: {
	imagePosition?:number,
	image?:PartialObjects["ImageXImageListUpdateImageEntityRelationInput"],
	_dummy_field_?:boolean
},
	["Info"]: {
		__typename?: "Info";
			description?:string
	},
	["IntCondition"]: {
	and?:PartialObjects["IntCondition"][],
	or?:PartialObjects["IntCondition"][],
	not?:PartialObjects["IntCondition"],
	eq?:number,
	null?:boolean,
	isNull?:boolean,
	notEq?:number,
	in?:number[],
	notIn?:number[],
	lt?:number,
	lte?:number,
	gt?:number,
	gte?:number
},
	["Link"]: {
		__typename?: "Link";
			_meta?:PartialObjects["LinkMeta"],
			id?:PartialObjects["UUID"],
			title?:string,
			externalLink?:string,
			internalLink?:PartialObjects["Linkable"]
	},
	["Linkable"]: {
		__typename?: "Linkable";
			_meta?:PartialObjects["LinkableMeta"],
			id?:PartialObjects["UUID"],
			url?:string,
			page?:PartialObjects["PageLocale"]
	},
	["LinkableConnection"]: {
		__typename?: "LinkableConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["LinkableEdge"][]
	},
	["LinkableCreateInput"]: {
	url?:string,
	page?:PartialObjects["LinkableCreatePageEntityRelationInput"],
	_dummy_field_?:boolean
},
	["LinkableCreatePageEntityRelationInput"]: {
	connect?:PartialObjects["PageLocaleUniqueWhere"],
	create?:PartialObjects["PageLocaleWithoutLinkCreateInput"]
},
	["LinkableCreateResult"]: {
		__typename?: "LinkableCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Linkable"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["LinkableDeleteResult"]: {
		__typename?: "LinkableDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Linkable"]
	},
	["LinkableEdge"]: {
		__typename?: "LinkableEdge";
			node?:PartialObjects["Linkable"]
	},
	["LinkableMeta"]: {
		__typename?: "LinkableMeta";
			id?:PartialObjects["FieldMeta"],
			url?:PartialObjects["FieldMeta"],
			page?:PartialObjects["FieldMeta"]
	},
	["LinkableOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	url?:PartialObjects["OrderDirection"],
	page?:PartialObjects["PageLocaleOrderBy"]
},
	["LinkableUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	url?:string,
	page?:PartialObjects["PageLocaleUniqueWhere"]
},
	["LinkableUpdateInput"]: {
	url?:string,
	page?:PartialObjects["LinkableUpdatePageEntityRelationInput"],
	_dummy_field_?:boolean
},
	["LinkableUpdatePageEntityRelationInput"]: {
	create?:PartialObjects["PageLocaleWithoutLinkCreateInput"],
	update?:PartialObjects["PageLocaleWithoutLinkUpdateInput"],
	upsert?:PartialObjects["LinkableUpsertPageRelationInput"],
	connect?:PartialObjects["PageLocaleUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["LinkableUpdateResult"]: {
		__typename?: "LinkableUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Linkable"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["LinkableUpsertPageRelationInput"]: {
	update?:PartialObjects["PageLocaleWithoutLinkUpdateInput"],
	create?:PartialObjects["PageLocaleWithoutLinkCreateInput"]
},
	["LinkableWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	url?:PartialObjects["StringCondition"],
	page?:PartialObjects["PageLocaleWhere"],
	and?:PartialObjects["LinkableWhere"][],
	or?:PartialObjects["LinkableWhere"][],
	not?:PartialObjects["LinkableWhere"]
},
	["LinkableWithoutPageCreateInput"]: {
	url?:string,
	_dummy_field_?:boolean
},
	["LinkableWithoutPageUpdateInput"]: {
	url?:string,
	_dummy_field_?:boolean
},
	["LinkConnection"]: {
		__typename?: "LinkConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["LinkEdge"][]
	},
	["LinkCreateInput"]: {
	title?:string,
	externalLink?:string,
	internalLink?:PartialObjects["LinkCreateInternalLinkEntityRelationInput"],
	_dummy_field_?:boolean
},
	["LinkCreateInternalLinkEntityRelationInput"]: {
	connect?:PartialObjects["LinkableUniqueWhere"],
	create?:PartialObjects["LinkableCreateInput"]
},
	["LinkCreateResult"]: {
		__typename?: "LinkCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Link"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["LinkDeleteResult"]: {
		__typename?: "LinkDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Link"]
	},
	["LinkEdge"]: {
		__typename?: "LinkEdge";
			node?:PartialObjects["Link"]
	},
	["LinkMeta"]: {
		__typename?: "LinkMeta";
			id?:PartialObjects["FieldMeta"],
			title?:PartialObjects["FieldMeta"],
			externalLink?:PartialObjects["FieldMeta"],
			internalLink?:PartialObjects["FieldMeta"]
	},
	["LinkOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	title?:PartialObjects["OrderDirection"],
	externalLink?:PartialObjects["OrderDirection"],
	internalLink?:PartialObjects["LinkableOrderBy"]
},
	["LinkUniqueWhere"]: {
	id?:PartialObjects["UUID"]
},
	["LinkUpdateInput"]: {
	title?:string,
	externalLink?:string,
	internalLink?:PartialObjects["LinkUpdateInternalLinkEntityRelationInput"],
	_dummy_field_?:boolean
},
	["LinkUpdateInternalLinkEntityRelationInput"]: {
	create?:PartialObjects["LinkableCreateInput"],
	update?:PartialObjects["LinkableUpdateInput"],
	upsert?:PartialObjects["LinkUpsertInternalLinkRelationInput"],
	connect?:PartialObjects["LinkableUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["LinkUpdateResult"]: {
		__typename?: "LinkUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Link"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["LinkUpsertInternalLinkRelationInput"]: {
	update?:PartialObjects["LinkableUpdateInput"],
	create?:PartialObjects["LinkableCreateInput"]
},
	["LinkWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	title?:PartialObjects["StringCondition"],
	externalLink?:PartialObjects["StringCondition"],
	internalLink?:PartialObjects["LinkableWhere"],
	and?:PartialObjects["LinkWhere"][],
	or?:PartialObjects["LinkWhere"][],
	not?:PartialObjects["LinkWhere"]
},
	["Locale"]: {
		__typename?: "Locale";
			_meta?:PartialObjects["LocaleMeta"],
			id?:PartialObjects["UUID"],
			code?:string,
			iconGroups?:PartialObjects["IconGroupLocale"][],
			icons?:PartialObjects["IconLocale"][],
			productVariants?:PartialObjects["ProductVariantLocale"][],
			seos?:PartialObjects["SeoLocale"][],
			pages?:PartialObjects["PageLocale"][],
			products?:PartialObjects["ProductLocale"][],
			iconGroupsByRoot?:PartialObjects["IconGroupLocale"],
			iconsByRoot?:PartialObjects["IconLocale"],
			productVariantsByRoot?:PartialObjects["ProductVariantLocale"],
			seosByRoot?:PartialObjects["SeoLocale"],
			pagesByRoot?:PartialObjects["PageLocale"],
			pagesByLink?:PartialObjects["PageLocale"],
			pagesByContent?:PartialObjects["PageLocale"],
			productsByRoot?:PartialObjects["ProductLocale"]
	},
	["LocaleConnection"]: {
		__typename?: "LocaleConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["LocaleEdge"][]
	},
	["LocaleCreateIconGroupsEntityRelationInput"]: {
	connect?:PartialObjects["IconGroupLocaleUniqueWhere"],
	create?:PartialObjects["IconGroupLocaleWithoutLocaleCreateInput"],
	alias?:string
},
	["LocaleCreateIconsEntityRelationInput"]: {
	connect?:PartialObjects["IconLocaleUniqueWhere"],
	create?:PartialObjects["IconLocaleWithoutLocaleCreateInput"],
	alias?:string
},
	["LocaleCreateInput"]: {
	code?:string,
	iconGroups?:PartialObjects["LocaleCreateIconGroupsEntityRelationInput"][],
	icons?:PartialObjects["LocaleCreateIconsEntityRelationInput"][],
	productVariants?:PartialObjects["LocaleCreateProductVariantsEntityRelationInput"][],
	seos?:PartialObjects["LocaleCreateSeosEntityRelationInput"][],
	pages?:PartialObjects["LocaleCreatePagesEntityRelationInput"][],
	products?:PartialObjects["LocaleCreateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["LocaleCreatePagesEntityRelationInput"]: {
	connect?:PartialObjects["PageLocaleUniqueWhere"],
	create?:PartialObjects["PageLocaleWithoutLocaleCreateInput"],
	alias?:string
},
	["LocaleCreateProductsEntityRelationInput"]: {
	connect?:PartialObjects["ProductLocaleUniqueWhere"],
	create?:PartialObjects["ProductLocaleWithoutLocaleCreateInput"],
	alias?:string
},
	["LocaleCreateProductVariantsEntityRelationInput"]: {
	connect?:PartialObjects["ProductVariantLocaleUniqueWhere"],
	create?:PartialObjects["ProductVariantLocaleWithoutLocaleCreateInput"],
	alias?:string
},
	["LocaleCreateResult"]: {
		__typename?: "LocaleCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Locale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["LocaleCreateSeosEntityRelationInput"]: {
	connect?:PartialObjects["SeoLocaleUniqueWhere"],
	create?:PartialObjects["SeoLocaleWithoutLocaleCreateInput"],
	alias?:string
},
	["LocaleDeleteResult"]: {
		__typename?: "LocaleDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Locale"]
	},
	["LocaleEdge"]: {
		__typename?: "LocaleEdge";
			node?:PartialObjects["Locale"]
	},
	["LocaleIconGroupsByRootUniqueWhere"]: {
	root?:PartialObjects["IconGroupUniqueWhere"]
},
	["LocaleIconsByRootUniqueWhere"]: {
	root?:PartialObjects["IconUniqueWhere"]
},
	["LocaleMeta"]: {
		__typename?: "LocaleMeta";
			id?:PartialObjects["FieldMeta"],
			code?:PartialObjects["FieldMeta"],
			iconGroups?:PartialObjects["FieldMeta"],
			icons?:PartialObjects["FieldMeta"],
			productVariants?:PartialObjects["FieldMeta"],
			seos?:PartialObjects["FieldMeta"],
			pages?:PartialObjects["FieldMeta"],
			products?:PartialObjects["FieldMeta"]
	},
	["LocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	code?:PartialObjects["OrderDirection"]
},
	["LocalePagesByContentUniqueWhere"]: {
	content?:PartialObjects["ContentUniqueWhere"]
},
	["LocalePagesByLinkUniqueWhere"]: {
	link?:PartialObjects["LinkableUniqueWhere"]
},
	["LocalePagesByRootUniqueWhere"]: {
	root?:PartialObjects["PageUniqueWhere"]
},
	["LocaleProductsByRootUniqueWhere"]: {
	root?:PartialObjects["ProductUniqueWhere"]
},
	["LocaleProductVariantsByRootUniqueWhere"]: {
	root?:PartialObjects["ProductVariantUniqueWhere"]
},
	["LocaleSeosByRootUniqueWhere"]: {
	root?:PartialObjects["SeoUniqueWhere"]
},
	["LocaleUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	code?:string,
	iconGroups?:PartialObjects["IconGroupLocaleUniqueWhere"],
	icons?:PartialObjects["IconLocaleUniqueWhere"],
	productVariants?:PartialObjects["ProductVariantLocaleUniqueWhere"],
	seos?:PartialObjects["SeoLocaleUniqueWhere"],
	pages?:PartialObjects["PageLocaleUniqueWhere"],
	products?:PartialObjects["ProductLocaleUniqueWhere"]
},
	["LocaleUpdateIconGroupsEntityRelationInput"]: {
	create?:PartialObjects["IconGroupLocaleWithoutLocaleCreateInput"],
	update?:PartialObjects["LocaleUpdateIconGroupsRelationInput"],
	upsert?:PartialObjects["LocaleUpsertIconGroupsRelationInput"],
	connect?:PartialObjects["IconGroupLocaleUniqueWhere"],
	disconnect?:PartialObjects["IconGroupLocaleUniqueWhere"],
	delete?:PartialObjects["IconGroupLocaleUniqueWhere"],
	alias?:string
},
	["LocaleUpdateIconGroupsRelationInput"]: {
	by?:PartialObjects["IconGroupLocaleUniqueWhere"],
	data?:PartialObjects["IconGroupLocaleWithoutLocaleUpdateInput"]
},
	["LocaleUpdateIconsEntityRelationInput"]: {
	create?:PartialObjects["IconLocaleWithoutLocaleCreateInput"],
	update?:PartialObjects["LocaleUpdateIconsRelationInput"],
	upsert?:PartialObjects["LocaleUpsertIconsRelationInput"],
	connect?:PartialObjects["IconLocaleUniqueWhere"],
	disconnect?:PartialObjects["IconLocaleUniqueWhere"],
	delete?:PartialObjects["IconLocaleUniqueWhere"],
	alias?:string
},
	["LocaleUpdateIconsRelationInput"]: {
	by?:PartialObjects["IconLocaleUniqueWhere"],
	data?:PartialObjects["IconLocaleWithoutLocaleUpdateInput"]
},
	["LocaleUpdateInput"]: {
	code?:string,
	iconGroups?:PartialObjects["LocaleUpdateIconGroupsEntityRelationInput"][],
	icons?:PartialObjects["LocaleUpdateIconsEntityRelationInput"][],
	productVariants?:PartialObjects["LocaleUpdateProductVariantsEntityRelationInput"][],
	seos?:PartialObjects["LocaleUpdateSeosEntityRelationInput"][],
	pages?:PartialObjects["LocaleUpdatePagesEntityRelationInput"][],
	products?:PartialObjects["LocaleUpdateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["LocaleUpdatePagesEntityRelationInput"]: {
	create?:PartialObjects["PageLocaleWithoutLocaleCreateInput"],
	update?:PartialObjects["LocaleUpdatePagesRelationInput"],
	upsert?:PartialObjects["LocaleUpsertPagesRelationInput"],
	connect?:PartialObjects["PageLocaleUniqueWhere"],
	disconnect?:PartialObjects["PageLocaleUniqueWhere"],
	delete?:PartialObjects["PageLocaleUniqueWhere"],
	alias?:string
},
	["LocaleUpdatePagesRelationInput"]: {
	by?:PartialObjects["PageLocaleUniqueWhere"],
	data?:PartialObjects["PageLocaleWithoutLocaleUpdateInput"]
},
	["LocaleUpdateProductsEntityRelationInput"]: {
	create?:PartialObjects["ProductLocaleWithoutLocaleCreateInput"],
	update?:PartialObjects["LocaleUpdateProductsRelationInput"],
	upsert?:PartialObjects["LocaleUpsertProductsRelationInput"],
	connect?:PartialObjects["ProductLocaleUniqueWhere"],
	disconnect?:PartialObjects["ProductLocaleUniqueWhere"],
	delete?:PartialObjects["ProductLocaleUniqueWhere"],
	alias?:string
},
	["LocaleUpdateProductsRelationInput"]: {
	by?:PartialObjects["ProductLocaleUniqueWhere"],
	data?:PartialObjects["ProductLocaleWithoutLocaleUpdateInput"]
},
	["LocaleUpdateProductVariantsEntityRelationInput"]: {
	create?:PartialObjects["ProductVariantLocaleWithoutLocaleCreateInput"],
	update?:PartialObjects["LocaleUpdateProductVariantsRelationInput"],
	upsert?:PartialObjects["LocaleUpsertProductVariantsRelationInput"],
	connect?:PartialObjects["ProductVariantLocaleUniqueWhere"],
	disconnect?:PartialObjects["ProductVariantLocaleUniqueWhere"],
	delete?:PartialObjects["ProductVariantLocaleUniqueWhere"],
	alias?:string
},
	["LocaleUpdateProductVariantsRelationInput"]: {
	by?:PartialObjects["ProductVariantLocaleUniqueWhere"],
	data?:PartialObjects["ProductVariantLocaleWithoutLocaleUpdateInput"]
},
	["LocaleUpdateResult"]: {
		__typename?: "LocaleUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Locale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["LocaleUpdateSeosEntityRelationInput"]: {
	create?:PartialObjects["SeoLocaleWithoutLocaleCreateInput"],
	update?:PartialObjects["LocaleUpdateSeosRelationInput"],
	upsert?:PartialObjects["LocaleUpsertSeosRelationInput"],
	connect?:PartialObjects["SeoLocaleUniqueWhere"],
	disconnect?:PartialObjects["SeoLocaleUniqueWhere"],
	delete?:PartialObjects["SeoLocaleUniqueWhere"],
	alias?:string
},
	["LocaleUpdateSeosRelationInput"]: {
	by?:PartialObjects["SeoLocaleUniqueWhere"],
	data?:PartialObjects["SeoLocaleWithoutLocaleUpdateInput"]
},
	["LocaleUpsertIconGroupsRelationInput"]: {
	by?:PartialObjects["IconGroupLocaleUniqueWhere"],
	update?:PartialObjects["IconGroupLocaleWithoutLocaleUpdateInput"],
	create?:PartialObjects["IconGroupLocaleWithoutLocaleCreateInput"]
},
	["LocaleUpsertIconsRelationInput"]: {
	by?:PartialObjects["IconLocaleUniqueWhere"],
	update?:PartialObjects["IconLocaleWithoutLocaleUpdateInput"],
	create?:PartialObjects["IconLocaleWithoutLocaleCreateInput"]
},
	["LocaleUpsertPagesRelationInput"]: {
	by?:PartialObjects["PageLocaleUniqueWhere"],
	update?:PartialObjects["PageLocaleWithoutLocaleUpdateInput"],
	create?:PartialObjects["PageLocaleWithoutLocaleCreateInput"]
},
	["LocaleUpsertProductsRelationInput"]: {
	by?:PartialObjects["ProductLocaleUniqueWhere"],
	update?:PartialObjects["ProductLocaleWithoutLocaleUpdateInput"],
	create?:PartialObjects["ProductLocaleWithoutLocaleCreateInput"]
},
	["LocaleUpsertProductVariantsRelationInput"]: {
	by?:PartialObjects["ProductVariantLocaleUniqueWhere"],
	update?:PartialObjects["ProductVariantLocaleWithoutLocaleUpdateInput"],
	create?:PartialObjects["ProductVariantLocaleWithoutLocaleCreateInput"]
},
	["LocaleUpsertSeosRelationInput"]: {
	by?:PartialObjects["SeoLocaleUniqueWhere"],
	update?:PartialObjects["SeoLocaleWithoutLocaleUpdateInput"],
	create?:PartialObjects["SeoLocaleWithoutLocaleCreateInput"]
},
	["LocaleWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	code?:PartialObjects["StringCondition"],
	iconGroups?:PartialObjects["IconGroupLocaleWhere"],
	icons?:PartialObjects["IconLocaleWhere"],
	productVariants?:PartialObjects["ProductVariantLocaleWhere"],
	seos?:PartialObjects["SeoLocaleWhere"],
	pages?:PartialObjects["PageLocaleWhere"],
	products?:PartialObjects["ProductLocaleWhere"],
	and?:PartialObjects["LocaleWhere"][],
	or?:PartialObjects["LocaleWhere"][],
	not?:PartialObjects["LocaleWhere"]
},
	["LocaleWithoutIconGroupsCreateInput"]: {
	code?:string,
	icons?:PartialObjects["LocaleCreateIconsEntityRelationInput"][],
	productVariants?:PartialObjects["LocaleCreateProductVariantsEntityRelationInput"][],
	seos?:PartialObjects["LocaleCreateSeosEntityRelationInput"][],
	pages?:PartialObjects["LocaleCreatePagesEntityRelationInput"][],
	products?:PartialObjects["LocaleCreateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["LocaleWithoutIconGroupsUpdateInput"]: {
	code?:string,
	icons?:PartialObjects["LocaleUpdateIconsEntityRelationInput"][],
	productVariants?:PartialObjects["LocaleUpdateProductVariantsEntityRelationInput"][],
	seos?:PartialObjects["LocaleUpdateSeosEntityRelationInput"][],
	pages?:PartialObjects["LocaleUpdatePagesEntityRelationInput"][],
	products?:PartialObjects["LocaleUpdateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["LocaleWithoutIconsCreateInput"]: {
	code?:string,
	iconGroups?:PartialObjects["LocaleCreateIconGroupsEntityRelationInput"][],
	productVariants?:PartialObjects["LocaleCreateProductVariantsEntityRelationInput"][],
	seos?:PartialObjects["LocaleCreateSeosEntityRelationInput"][],
	pages?:PartialObjects["LocaleCreatePagesEntityRelationInput"][],
	products?:PartialObjects["LocaleCreateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["LocaleWithoutIconsUpdateInput"]: {
	code?:string,
	iconGroups?:PartialObjects["LocaleUpdateIconGroupsEntityRelationInput"][],
	productVariants?:PartialObjects["LocaleUpdateProductVariantsEntityRelationInput"][],
	seos?:PartialObjects["LocaleUpdateSeosEntityRelationInput"][],
	pages?:PartialObjects["LocaleUpdatePagesEntityRelationInput"][],
	products?:PartialObjects["LocaleUpdateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["LocaleWithoutPagesCreateInput"]: {
	code?:string,
	iconGroups?:PartialObjects["LocaleCreateIconGroupsEntityRelationInput"][],
	icons?:PartialObjects["LocaleCreateIconsEntityRelationInput"][],
	productVariants?:PartialObjects["LocaleCreateProductVariantsEntityRelationInput"][],
	seos?:PartialObjects["LocaleCreateSeosEntityRelationInput"][],
	products?:PartialObjects["LocaleCreateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["LocaleWithoutPagesUpdateInput"]: {
	code?:string,
	iconGroups?:PartialObjects["LocaleUpdateIconGroupsEntityRelationInput"][],
	icons?:PartialObjects["LocaleUpdateIconsEntityRelationInput"][],
	productVariants?:PartialObjects["LocaleUpdateProductVariantsEntityRelationInput"][],
	seos?:PartialObjects["LocaleUpdateSeosEntityRelationInput"][],
	products?:PartialObjects["LocaleUpdateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["LocaleWithoutProductsCreateInput"]: {
	code?:string,
	iconGroups?:PartialObjects["LocaleCreateIconGroupsEntityRelationInput"][],
	icons?:PartialObjects["LocaleCreateIconsEntityRelationInput"][],
	productVariants?:PartialObjects["LocaleCreateProductVariantsEntityRelationInput"][],
	seos?:PartialObjects["LocaleCreateSeosEntityRelationInput"][],
	pages?:PartialObjects["LocaleCreatePagesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["LocaleWithoutProductsUpdateInput"]: {
	code?:string,
	iconGroups?:PartialObjects["LocaleUpdateIconGroupsEntityRelationInput"][],
	icons?:PartialObjects["LocaleUpdateIconsEntityRelationInput"][],
	productVariants?:PartialObjects["LocaleUpdateProductVariantsEntityRelationInput"][],
	seos?:PartialObjects["LocaleUpdateSeosEntityRelationInput"][],
	pages?:PartialObjects["LocaleUpdatePagesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["LocaleWithoutProductVariantsCreateInput"]: {
	code?:string,
	iconGroups?:PartialObjects["LocaleCreateIconGroupsEntityRelationInput"][],
	icons?:PartialObjects["LocaleCreateIconsEntityRelationInput"][],
	seos?:PartialObjects["LocaleCreateSeosEntityRelationInput"][],
	pages?:PartialObjects["LocaleCreatePagesEntityRelationInput"][],
	products?:PartialObjects["LocaleCreateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["LocaleWithoutProductVariantsUpdateInput"]: {
	code?:string,
	iconGroups?:PartialObjects["LocaleUpdateIconGroupsEntityRelationInput"][],
	icons?:PartialObjects["LocaleUpdateIconsEntityRelationInput"][],
	seos?:PartialObjects["LocaleUpdateSeosEntityRelationInput"][],
	pages?:PartialObjects["LocaleUpdatePagesEntityRelationInput"][],
	products?:PartialObjects["LocaleUpdateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["LocaleWithoutSeosCreateInput"]: {
	code?:string,
	iconGroups?:PartialObjects["LocaleCreateIconGroupsEntityRelationInput"][],
	icons?:PartialObjects["LocaleCreateIconsEntityRelationInput"][],
	productVariants?:PartialObjects["LocaleCreateProductVariantsEntityRelationInput"][],
	pages?:PartialObjects["LocaleCreatePagesEntityRelationInput"][],
	products?:PartialObjects["LocaleCreateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["LocaleWithoutSeosUpdateInput"]: {
	code?:string,
	iconGroups?:PartialObjects["LocaleUpdateIconGroupsEntityRelationInput"][],
	icons?:PartialObjects["LocaleUpdateIconsEntityRelationInput"][],
	productVariants?:PartialObjects["LocaleUpdateProductVariantsEntityRelationInput"][],
	pages?:PartialObjects["LocaleUpdatePagesEntityRelationInput"][],
	products?:PartialObjects["LocaleUpdateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["Media"]: {
		__typename?: "Media";
			_meta?:PartialObjects["MediaMeta"],
			id?:PartialObjects["UUID"],
			url?:string,
			videoUrl?:string,
			width?:number,
			height?:number,
			poster?:string,
			type?:PartialObjects["MediaItemType"],
			caption?:string,
			audio?:boolean
	},
	["MediaConnection"]: {
		__typename?: "MediaConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["MediaEdge"][]
	},
	["MediaCreateInput"]: {
	url?:string,
	videoUrl?:string,
	width?:number,
	height?:number,
	poster?:string,
	type?:PartialObjects["MediaItemType"],
	caption?:string,
	audio?:boolean,
	_dummy_field_?:boolean
},
	["MediaCreateResult"]: {
		__typename?: "MediaCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Media"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["MediaDeleteResult"]: {
		__typename?: "MediaDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Media"]
	},
	["MediaEdge"]: {
		__typename?: "MediaEdge";
			node?:PartialObjects["Media"]
	},
	["MediaItemType"]:MediaItemType,
	["MediaItemTypeEnumCondition"]: {
	and?:PartialObjects["MediaItemTypeEnumCondition"][],
	or?:PartialObjects["MediaItemTypeEnumCondition"][],
	not?:PartialObjects["MediaItemTypeEnumCondition"],
	eq?:PartialObjects["MediaItemType"],
	null?:boolean,
	isNull?:boolean,
	notEq?:PartialObjects["MediaItemType"],
	in?:PartialObjects["MediaItemType"][],
	notIn?:PartialObjects["MediaItemType"][],
	lt?:PartialObjects["MediaItemType"],
	lte?:PartialObjects["MediaItemType"],
	gt?:PartialObjects["MediaItemType"],
	gte?:PartialObjects["MediaItemType"]
},
	["MediaMeta"]: {
		__typename?: "MediaMeta";
			id?:PartialObjects["FieldMeta"],
			url?:PartialObjects["FieldMeta"],
			videoUrl?:PartialObjects["FieldMeta"],
			width?:PartialObjects["FieldMeta"],
			height?:PartialObjects["FieldMeta"],
			poster?:PartialObjects["FieldMeta"],
			type?:PartialObjects["FieldMeta"],
			caption?:PartialObjects["FieldMeta"],
			audio?:PartialObjects["FieldMeta"]
	},
	["MediaOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	url?:PartialObjects["OrderDirection"],
	videoUrl?:PartialObjects["OrderDirection"],
	width?:PartialObjects["OrderDirection"],
	height?:PartialObjects["OrderDirection"],
	poster?:PartialObjects["OrderDirection"],
	type?:PartialObjects["OrderDirection"],
	caption?:PartialObjects["OrderDirection"],
	audio?:PartialObjects["OrderDirection"]
},
	["MediaUniqueWhere"]: {
	id?:PartialObjects["UUID"]
},
	["MediaUpdateInput"]: {
	url?:string,
	videoUrl?:string,
	width?:number,
	height?:number,
	poster?:string,
	type?:PartialObjects["MediaItemType"],
	caption?:string,
	audio?:boolean,
	_dummy_field_?:boolean
},
	["MediaUpdateResult"]: {
		__typename?: "MediaUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Media"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["MediaWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	url?:PartialObjects["StringCondition"],
	videoUrl?:PartialObjects["StringCondition"],
	width?:PartialObjects["IntCondition"],
	height?:PartialObjects["IntCondition"],
	poster?:PartialObjects["StringCondition"],
	type?:PartialObjects["MediaItemTypeEnumCondition"],
	caption?:PartialObjects["StringCondition"],
	audio?:PartialObjects["BooleanCondition"],
	and?:PartialObjects["MediaWhere"][],
	or?:PartialObjects["MediaWhere"][],
	not?:PartialObjects["MediaWhere"]
},
	["Mutation"]: {
		__typename?: "Mutation";
			createAbandonedCheckoutUnsubscription?:PartialObjects["AbandonedCheckoutUnsubscriptionCreateResult"],
			deleteAbandonedCheckoutUnsubscription?:PartialObjects["AbandonedCheckoutUnsubscriptionDeleteResult"],
			updateAbandonedCheckoutUnsubscription?:PartialObjects["AbandonedCheckoutUnsubscriptionUpdateResult"],
			createAbandonedCheckoutNotification?:PartialObjects["AbandonedCheckoutNotificationCreateResult"],
			deleteAbandonedCheckoutNotification?:PartialObjects["AbandonedCheckoutNotificationDeleteResult"],
			updateAbandonedCheckoutNotification?:PartialObjects["AbandonedCheckoutNotificationUpdateResult"],
			createShopifyLineItem?:PartialObjects["ShopifyLineItemCreateResult"],
			deleteShopifyLineItem?:PartialObjects["ShopifyLineItemDeleteResult"],
			updateShopifyLineItem?:PartialObjects["ShopifyLineItemUpdateResult"],
			createShopifyOrder?:PartialObjects["ShopifyOrderCreateResult"],
			deleteShopifyOrder?:PartialObjects["ShopifyOrderDeleteResult"],
			updateShopifyOrder?:PartialObjects["ShopifyOrderUpdateResult"],
			createTranslationValue?:PartialObjects["TranslationValueCreateResult"],
			deleteTranslationValue?:PartialObjects["TranslationValueDeleteResult"],
			updateTranslationValue?:PartialObjects["TranslationValueUpdateResult"],
			createTranslationKey?:PartialObjects["TranslationKeyCreateResult"],
			deleteTranslationKey?:PartialObjects["TranslationKeyDeleteResult"],
			updateTranslationKey?:PartialObjects["TranslationKeyUpdateResult"],
			createTranslationCatalogue?:PartialObjects["TranslationCatalogueCreateResult"],
			deleteTranslationCatalogue?:PartialObjects["TranslationCatalogueDeleteResult"],
			updateTranslationCatalogue?:PartialObjects["TranslationCatalogueUpdateResult"],
			createTranslationDomain?:PartialObjects["TranslationDomainCreateResult"],
			deleteTranslationDomain?:PartialObjects["TranslationDomainDeleteResult"],
			updateTranslationDomain?:PartialObjects["TranslationDomainUpdateResult"],
			createProductLocale?:PartialObjects["ProductLocaleCreateResult"],
			deleteProductLocale?:PartialObjects["ProductLocaleDeleteResult"],
			updateProductLocale?:PartialObjects["ProductLocaleUpdateResult"],
			createMedia?:PartialObjects["MediaCreateResult"],
			deleteMedia?:PartialObjects["MediaDeleteResult"],
			updateMedia?:PartialObjects["MediaUpdateResult"],
			createIconListBlockItem?:PartialObjects["IconListBlockItemCreateResult"],
			deleteIconListBlockItem?:PartialObjects["IconListBlockItemDeleteResult"],
			updateIconListBlockItem?:PartialObjects["IconListBlockItemUpdateResult"],
			createIconListBlock?:PartialObjects["IconListBlockCreateResult"],
			deleteIconListBlock?:PartialObjects["IconListBlockDeleteResult"],
			updateIconListBlock?:PartialObjects["IconListBlockUpdateResult"],
			createFeatureListItem?:PartialObjects["FeatureListItemCreateResult"],
			deleteFeatureListItem?:PartialObjects["FeatureListItemDeleteResult"],
			updateFeatureListItem?:PartialObjects["FeatureListItemUpdateResult"],
			createFeatureList?:PartialObjects["FeatureListCreateResult"],
			deleteFeatureList?:PartialObjects["FeatureListDeleteResult"],
			updateFeatureList?:PartialObjects["FeatureListUpdateResult"],
			createFaqItem?:PartialObjects["FaqItemCreateResult"],
			deleteFaqItem?:PartialObjects["FaqItemDeleteResult"],
			updateFaqItem?:PartialObjects["FaqItemUpdateResult"],
			createFaq?:PartialObjects["FaqCreateResult"],
			deleteFaq?:PartialObjects["FaqDeleteResult"],
			updateFaq?:PartialObjects["FaqUpdateResult"],
			createContentBlock?:PartialObjects["ContentBlockCreateResult"],
			deleteContentBlock?:PartialObjects["ContentBlockDeleteResult"],
			updateContentBlock?:PartialObjects["ContentBlockUpdateResult"],
			createContent?:PartialObjects["ContentCreateResult"],
			deleteContent?:PartialObjects["ContentDeleteResult"],
			updateContent?:PartialObjects["ContentUpdateResult"],
			createLink?:PartialObjects["LinkCreateResult"],
			deleteLink?:PartialObjects["LinkDeleteResult"],
			updateLink?:PartialObjects["LinkUpdateResult"],
			createReviewLocale?:PartialObjects["ReviewLocaleCreateResult"],
			deleteReviewLocale?:PartialObjects["ReviewLocaleDeleteResult"],
			updateReviewLocale?:PartialObjects["ReviewLocaleUpdateResult"],
			createReview?:PartialObjects["ReviewCreateResult"],
			deleteReview?:PartialObjects["ReviewDeleteResult"],
			updateReview?:PartialObjects["ReviewUpdateResult"],
			createGalleryItem?:PartialObjects["GalleryItemCreateResult"],
			deleteGalleryItem?:PartialObjects["GalleryItemDeleteResult"],
			updateGalleryItem?:PartialObjects["GalleryItemUpdateResult"],
			createGallery?:PartialObjects["GalleryCreateResult"],
			deleteGallery?:PartialObjects["GalleryDeleteResult"],
			updateGallery?:PartialObjects["GalleryUpdateResult"],
			createProductVariantStore?:PartialObjects["ProductVariantStoreCreateResult"],
			deleteProductVariantStore?:PartialObjects["ProductVariantStoreDeleteResult"],
			updateProductVariantStore?:PartialObjects["ProductVariantStoreUpdateResult"],
			createProductStore?:PartialObjects["ProductStoreCreateResult"],
			deleteProductStore?:PartialObjects["ProductStoreDeleteResult"],
			updateProductStore?:PartialObjects["ProductStoreUpdateResult"],
			createIconListItem?:PartialObjects["IconListItemCreateResult"],
			deleteIconListItem?:PartialObjects["IconListItemDeleteResult"],
			updateIconListItem?:PartialObjects["IconListItemUpdateResult"],
			createIconList?:PartialObjects["IconListCreateResult"],
			deleteIconList?:PartialObjects["IconListDeleteResult"],
			updateIconList?:PartialObjects["IconListUpdateResult"],
			createIconGroupListItem?:PartialObjects["IconGroupListItemCreateResult"],
			deleteIconGroupListItem?:PartialObjects["IconGroupListItemDeleteResult"],
			updateIconGroupListItem?:PartialObjects["IconGroupListItemUpdateResult"],
			createIconGroupList?:PartialObjects["IconGroupListCreateResult"],
			deleteIconGroupList?:PartialObjects["IconGroupListDeleteResult"],
			updateIconGroupList?:PartialObjects["IconGroupListUpdateResult"],
			createProductVariantLocale?:PartialObjects["ProductVariantLocaleCreateResult"],
			deleteProductVariantLocale?:PartialObjects["ProductVariantLocaleDeleteResult"],
			updateProductVariantLocale?:PartialObjects["ProductVariantLocaleUpdateResult"],
			createProductVariant?:PartialObjects["ProductVariantCreateResult"],
			deleteProductVariant?:PartialObjects["ProductVariantDeleteResult"],
			updateProductVariant?:PartialObjects["ProductVariantUpdateResult"],
			createProduct?:PartialObjects["ProductCreateResult"],
			deleteProduct?:PartialObjects["ProductDeleteResult"],
			updateProduct?:PartialObjects["ProductUpdateResult"],
			createImageLocale?:PartialObjects["ImageLocaleCreateResult"],
			deleteImageLocale?:PartialObjects["ImageLocaleDeleteResult"],
			updateImageLocale?:PartialObjects["ImageLocaleUpdateResult"],
			createStore?:PartialObjects["StoreCreateResult"],
			deleteStore?:PartialObjects["StoreDeleteResult"],
			updateStore?:PartialObjects["StoreUpdateResult"],
			createAdminNote?:PartialObjects["AdminNoteCreateResult"],
			deleteAdminNote?:PartialObjects["AdminNoteDeleteResult"],
			updateAdminNote?:PartialObjects["AdminNoteUpdateResult"],
			createPageLocale?:PartialObjects["PageLocaleCreateResult"],
			deletePageLocale?:PartialObjects["PageLocaleDeleteResult"],
			updatePageLocale?:PartialObjects["PageLocaleUpdateResult"],
			createPage?:PartialObjects["PageCreateResult"],
			deletePage?:PartialObjects["PageDeleteResult"],
			updatePage?:PartialObjects["PageUpdateResult"],
			createImageXImageList?:PartialObjects["ImageXImageListCreateResult"],
			deleteImageXImageList?:PartialObjects["ImageXImageListDeleteResult"],
			updateImageXImageList?:PartialObjects["ImageXImageListUpdateResult"],
			createIconGroupLocale?:PartialObjects["IconGroupLocaleCreateResult"],
			deleteIconGroupLocale?:PartialObjects["IconGroupLocaleDeleteResult"],
			updateIconGroupLocale?:PartialObjects["IconGroupLocaleUpdateResult"],
			createIconLocale?:PartialObjects["IconLocaleCreateResult"],
			deleteIconLocale?:PartialObjects["IconLocaleDeleteResult"],
			updateIconLocale?:PartialObjects["IconLocaleUpdateResult"],
			createIconGroup?:PartialObjects["IconGroupCreateResult"],
			deleteIconGroup?:PartialObjects["IconGroupDeleteResult"],
			updateIconGroup?:PartialObjects["IconGroupUpdateResult"],
			createIcon?:PartialObjects["IconCreateResult"],
			deleteIcon?:PartialObjects["IconDeleteResult"],
			updateIcon?:PartialObjects["IconUpdateResult"],
			createLocale?:PartialObjects["LocaleCreateResult"],
			deleteLocale?:PartialObjects["LocaleDeleteResult"],
			updateLocale?:PartialObjects["LocaleUpdateResult"],
			createSeoLocale?:PartialObjects["SeoLocaleCreateResult"],
			deleteSeoLocale?:PartialObjects["SeoLocaleDeleteResult"],
			updateSeoLocale?:PartialObjects["SeoLocaleUpdateResult"],
			createSeo?:PartialObjects["SeoCreateResult"],
			deleteSeo?:PartialObjects["SeoDeleteResult"],
			updateSeo?:PartialObjects["SeoUpdateResult"],
			createLinkable?:PartialObjects["LinkableCreateResult"],
			deleteLinkable?:PartialObjects["LinkableDeleteResult"],
			updateLinkable?:PartialObjects["LinkableUpdateResult"],
			createImageList?:PartialObjects["ImageListCreateResult"],
			deleteImageList?:PartialObjects["ImageListDeleteResult"],
			updateImageList?:PartialObjects["ImageListUpdateResult"],
			createImage?:PartialObjects["ImageCreateResult"],
			deleteImage?:PartialObjects["ImageDeleteResult"],
			updateImage?:PartialObjects["ImageUpdateResult"],
			transaction?:PartialObjects["MutationTransaction"],
			generateUploadUrl?:PartialObjects["S3SignedUpload"],
			generateReadUrl?:PartialObjects["S3SignedRead"]
	},
	["MutationTransaction"]: {
		__typename?: "MutationTransaction";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			validation?:PartialObjects["_ValidationResult"],
			createAbandonedCheckoutUnsubscription?:PartialObjects["AbandonedCheckoutUnsubscriptionCreateResult"],
			deleteAbandonedCheckoutUnsubscription?:PartialObjects["AbandonedCheckoutUnsubscriptionDeleteResult"],
			updateAbandonedCheckoutUnsubscription?:PartialObjects["AbandonedCheckoutUnsubscriptionUpdateResult"],
			createAbandonedCheckoutNotification?:PartialObjects["AbandonedCheckoutNotificationCreateResult"],
			deleteAbandonedCheckoutNotification?:PartialObjects["AbandonedCheckoutNotificationDeleteResult"],
			updateAbandonedCheckoutNotification?:PartialObjects["AbandonedCheckoutNotificationUpdateResult"],
			createShopifyLineItem?:PartialObjects["ShopifyLineItemCreateResult"],
			deleteShopifyLineItem?:PartialObjects["ShopifyLineItemDeleteResult"],
			updateShopifyLineItem?:PartialObjects["ShopifyLineItemUpdateResult"],
			createShopifyOrder?:PartialObjects["ShopifyOrderCreateResult"],
			deleteShopifyOrder?:PartialObjects["ShopifyOrderDeleteResult"],
			updateShopifyOrder?:PartialObjects["ShopifyOrderUpdateResult"],
			createTranslationValue?:PartialObjects["TranslationValueCreateResult"],
			deleteTranslationValue?:PartialObjects["TranslationValueDeleteResult"],
			updateTranslationValue?:PartialObjects["TranslationValueUpdateResult"],
			createTranslationKey?:PartialObjects["TranslationKeyCreateResult"],
			deleteTranslationKey?:PartialObjects["TranslationKeyDeleteResult"],
			updateTranslationKey?:PartialObjects["TranslationKeyUpdateResult"],
			createTranslationCatalogue?:PartialObjects["TranslationCatalogueCreateResult"],
			deleteTranslationCatalogue?:PartialObjects["TranslationCatalogueDeleteResult"],
			updateTranslationCatalogue?:PartialObjects["TranslationCatalogueUpdateResult"],
			createTranslationDomain?:PartialObjects["TranslationDomainCreateResult"],
			deleteTranslationDomain?:PartialObjects["TranslationDomainDeleteResult"],
			updateTranslationDomain?:PartialObjects["TranslationDomainUpdateResult"],
			createProductLocale?:PartialObjects["ProductLocaleCreateResult"],
			deleteProductLocale?:PartialObjects["ProductLocaleDeleteResult"],
			updateProductLocale?:PartialObjects["ProductLocaleUpdateResult"],
			createMedia?:PartialObjects["MediaCreateResult"],
			deleteMedia?:PartialObjects["MediaDeleteResult"],
			updateMedia?:PartialObjects["MediaUpdateResult"],
			createIconListBlockItem?:PartialObjects["IconListBlockItemCreateResult"],
			deleteIconListBlockItem?:PartialObjects["IconListBlockItemDeleteResult"],
			updateIconListBlockItem?:PartialObjects["IconListBlockItemUpdateResult"],
			createIconListBlock?:PartialObjects["IconListBlockCreateResult"],
			deleteIconListBlock?:PartialObjects["IconListBlockDeleteResult"],
			updateIconListBlock?:PartialObjects["IconListBlockUpdateResult"],
			createFeatureListItem?:PartialObjects["FeatureListItemCreateResult"],
			deleteFeatureListItem?:PartialObjects["FeatureListItemDeleteResult"],
			updateFeatureListItem?:PartialObjects["FeatureListItemUpdateResult"],
			createFeatureList?:PartialObjects["FeatureListCreateResult"],
			deleteFeatureList?:PartialObjects["FeatureListDeleteResult"],
			updateFeatureList?:PartialObjects["FeatureListUpdateResult"],
			createFaqItem?:PartialObjects["FaqItemCreateResult"],
			deleteFaqItem?:PartialObjects["FaqItemDeleteResult"],
			updateFaqItem?:PartialObjects["FaqItemUpdateResult"],
			createFaq?:PartialObjects["FaqCreateResult"],
			deleteFaq?:PartialObjects["FaqDeleteResult"],
			updateFaq?:PartialObjects["FaqUpdateResult"],
			createContentBlock?:PartialObjects["ContentBlockCreateResult"],
			deleteContentBlock?:PartialObjects["ContentBlockDeleteResult"],
			updateContentBlock?:PartialObjects["ContentBlockUpdateResult"],
			createContent?:PartialObjects["ContentCreateResult"],
			deleteContent?:PartialObjects["ContentDeleteResult"],
			updateContent?:PartialObjects["ContentUpdateResult"],
			createLink?:PartialObjects["LinkCreateResult"],
			deleteLink?:PartialObjects["LinkDeleteResult"],
			updateLink?:PartialObjects["LinkUpdateResult"],
			createReviewLocale?:PartialObjects["ReviewLocaleCreateResult"],
			deleteReviewLocale?:PartialObjects["ReviewLocaleDeleteResult"],
			updateReviewLocale?:PartialObjects["ReviewLocaleUpdateResult"],
			createReview?:PartialObjects["ReviewCreateResult"],
			deleteReview?:PartialObjects["ReviewDeleteResult"],
			updateReview?:PartialObjects["ReviewUpdateResult"],
			createGalleryItem?:PartialObjects["GalleryItemCreateResult"],
			deleteGalleryItem?:PartialObjects["GalleryItemDeleteResult"],
			updateGalleryItem?:PartialObjects["GalleryItemUpdateResult"],
			createGallery?:PartialObjects["GalleryCreateResult"],
			deleteGallery?:PartialObjects["GalleryDeleteResult"],
			updateGallery?:PartialObjects["GalleryUpdateResult"],
			createProductVariantStore?:PartialObjects["ProductVariantStoreCreateResult"],
			deleteProductVariantStore?:PartialObjects["ProductVariantStoreDeleteResult"],
			updateProductVariantStore?:PartialObjects["ProductVariantStoreUpdateResult"],
			createProductStore?:PartialObjects["ProductStoreCreateResult"],
			deleteProductStore?:PartialObjects["ProductStoreDeleteResult"],
			updateProductStore?:PartialObjects["ProductStoreUpdateResult"],
			createIconListItem?:PartialObjects["IconListItemCreateResult"],
			deleteIconListItem?:PartialObjects["IconListItemDeleteResult"],
			updateIconListItem?:PartialObjects["IconListItemUpdateResult"],
			createIconList?:PartialObjects["IconListCreateResult"],
			deleteIconList?:PartialObjects["IconListDeleteResult"],
			updateIconList?:PartialObjects["IconListUpdateResult"],
			createIconGroupListItem?:PartialObjects["IconGroupListItemCreateResult"],
			deleteIconGroupListItem?:PartialObjects["IconGroupListItemDeleteResult"],
			updateIconGroupListItem?:PartialObjects["IconGroupListItemUpdateResult"],
			createIconGroupList?:PartialObjects["IconGroupListCreateResult"],
			deleteIconGroupList?:PartialObjects["IconGroupListDeleteResult"],
			updateIconGroupList?:PartialObjects["IconGroupListUpdateResult"],
			createProductVariantLocale?:PartialObjects["ProductVariantLocaleCreateResult"],
			deleteProductVariantLocale?:PartialObjects["ProductVariantLocaleDeleteResult"],
			updateProductVariantLocale?:PartialObjects["ProductVariantLocaleUpdateResult"],
			createProductVariant?:PartialObjects["ProductVariantCreateResult"],
			deleteProductVariant?:PartialObjects["ProductVariantDeleteResult"],
			updateProductVariant?:PartialObjects["ProductVariantUpdateResult"],
			createProduct?:PartialObjects["ProductCreateResult"],
			deleteProduct?:PartialObjects["ProductDeleteResult"],
			updateProduct?:PartialObjects["ProductUpdateResult"],
			createImageLocale?:PartialObjects["ImageLocaleCreateResult"],
			deleteImageLocale?:PartialObjects["ImageLocaleDeleteResult"],
			updateImageLocale?:PartialObjects["ImageLocaleUpdateResult"],
			createStore?:PartialObjects["StoreCreateResult"],
			deleteStore?:PartialObjects["StoreDeleteResult"],
			updateStore?:PartialObjects["StoreUpdateResult"],
			createAdminNote?:PartialObjects["AdminNoteCreateResult"],
			deleteAdminNote?:PartialObjects["AdminNoteDeleteResult"],
			updateAdminNote?:PartialObjects["AdminNoteUpdateResult"],
			createPageLocale?:PartialObjects["PageLocaleCreateResult"],
			deletePageLocale?:PartialObjects["PageLocaleDeleteResult"],
			updatePageLocale?:PartialObjects["PageLocaleUpdateResult"],
			createPage?:PartialObjects["PageCreateResult"],
			deletePage?:PartialObjects["PageDeleteResult"],
			updatePage?:PartialObjects["PageUpdateResult"],
			createImageXImageList?:PartialObjects["ImageXImageListCreateResult"],
			deleteImageXImageList?:PartialObjects["ImageXImageListDeleteResult"],
			updateImageXImageList?:PartialObjects["ImageXImageListUpdateResult"],
			createIconGroupLocale?:PartialObjects["IconGroupLocaleCreateResult"],
			deleteIconGroupLocale?:PartialObjects["IconGroupLocaleDeleteResult"],
			updateIconGroupLocale?:PartialObjects["IconGroupLocaleUpdateResult"],
			createIconLocale?:PartialObjects["IconLocaleCreateResult"],
			deleteIconLocale?:PartialObjects["IconLocaleDeleteResult"],
			updateIconLocale?:PartialObjects["IconLocaleUpdateResult"],
			createIconGroup?:PartialObjects["IconGroupCreateResult"],
			deleteIconGroup?:PartialObjects["IconGroupDeleteResult"],
			updateIconGroup?:PartialObjects["IconGroupUpdateResult"],
			createIcon?:PartialObjects["IconCreateResult"],
			deleteIcon?:PartialObjects["IconDeleteResult"],
			updateIcon?:PartialObjects["IconUpdateResult"],
			createLocale?:PartialObjects["LocaleCreateResult"],
			deleteLocale?:PartialObjects["LocaleDeleteResult"],
			updateLocale?:PartialObjects["LocaleUpdateResult"],
			createSeoLocale?:PartialObjects["SeoLocaleCreateResult"],
			deleteSeoLocale?:PartialObjects["SeoLocaleDeleteResult"],
			updateSeoLocale?:PartialObjects["SeoLocaleUpdateResult"],
			createSeo?:PartialObjects["SeoCreateResult"],
			deleteSeo?:PartialObjects["SeoDeleteResult"],
			updateSeo?:PartialObjects["SeoUpdateResult"],
			createLinkable?:PartialObjects["LinkableCreateResult"],
			deleteLinkable?:PartialObjects["LinkableDeleteResult"],
			updateLinkable?:PartialObjects["LinkableUpdateResult"],
			createImageList?:PartialObjects["ImageListCreateResult"],
			deleteImageList?:PartialObjects["ImageListDeleteResult"],
			updateImageList?:PartialObjects["ImageListUpdateResult"],
			createImage?:PartialObjects["ImageCreateResult"],
			deleteImage?:PartialObjects["ImageDeleteResult"],
			updateImage?:PartialObjects["ImageUpdateResult"]
	},
	["OrderDirection"]:OrderDirection,
	["Page"]: {
		__typename?: "Page";
			_meta?:PartialObjects["PageMeta"],
			id?:PartialObjects["UUID"],
			internalName?:string,
			template?:PartialObjects["PageType"],
			seo?:PartialObjects["Seo"],
			locales?:PartialObjects["PageLocale"][],
			localesByLocale?:PartialObjects["PageLocale"],
			localesByLink?:PartialObjects["PageLocale"],
			localesByContent?:PartialObjects["PageLocale"]
	},
	["PageConnection"]: {
		__typename?: "PageConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["PageEdge"][]
	},
	["PageCreateInput"]: {
	internalName?:string,
	template?:PartialObjects["PageType"],
	seo?:PartialObjects["PageCreateSeoEntityRelationInput"],
	locales?:PartialObjects["PageCreateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["PageCreateLocalesEntityRelationInput"]: {
	connect?:PartialObjects["PageLocaleUniqueWhere"],
	create?:PartialObjects["PageLocaleWithoutRootCreateInput"],
	alias?:string
},
	["PageCreateResult"]: {
		__typename?: "PageCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Page"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["PageCreateSeoEntityRelationInput"]: {
	connect?:PartialObjects["SeoUniqueWhere"],
	create?:PartialObjects["SeoCreateInput"]
},
	["PageDeleteResult"]: {
		__typename?: "PageDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Page"]
	},
	["PageEdge"]: {
		__typename?: "PageEdge";
			node?:PartialObjects["Page"]
	},
	["PageInfo"]: {
		__typename?: "PageInfo";
			totalCount?:number
	},
	["PageLocale"]: {
		__typename?: "PageLocale";
			_meta?:PartialObjects["PageLocaleMeta"],
			id?:PartialObjects["UUID"],
			title?:string,
			locale?:PartialObjects["Locale"],
			link?:PartialObjects["Linkable"],
			root?:PartialObjects["Page"],
			content?:PartialObjects["Content"],
			bannerColor?:string,
			bannerBgColor?:string,
			bannerText?:string
	},
	["PageLocaleConnection"]: {
		__typename?: "PageLocaleConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["PageLocaleEdge"][]
	},
	["PageLocaleCreateContentEntityRelationInput"]: {
	connect?:PartialObjects["ContentUniqueWhere"],
	create?:PartialObjects["ContentWithoutPageCreateInput"]
},
	["PageLocaleCreateInput"]: {
	title?:string,
	locale?:PartialObjects["PageLocaleCreateLocaleEntityRelationInput"],
	link?:PartialObjects["PageLocaleCreateLinkEntityRelationInput"],
	root?:PartialObjects["PageLocaleCreateRootEntityRelationInput"],
	content?:PartialObjects["PageLocaleCreateContentEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
},
	["PageLocaleCreateLinkEntityRelationInput"]: {
	connect?:PartialObjects["LinkableUniqueWhere"],
	create?:PartialObjects["LinkableWithoutPageCreateInput"]
},
	["PageLocaleCreateLocaleEntityRelationInput"]: {
	connect?:PartialObjects["LocaleUniqueWhere"],
	create?:PartialObjects["LocaleWithoutPagesCreateInput"]
},
	["PageLocaleCreateResult"]: {
		__typename?: "PageLocaleCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["PageLocale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["PageLocaleCreateRootEntityRelationInput"]: {
	connect?:PartialObjects["PageUniqueWhere"],
	create?:PartialObjects["PageWithoutLocalesCreateInput"]
},
	["PageLocaleDeleteResult"]: {
		__typename?: "PageLocaleDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["PageLocale"]
	},
	["PageLocaleEdge"]: {
		__typename?: "PageLocaleEdge";
			node?:PartialObjects["PageLocale"]
	},
	["PageLocaleMeta"]: {
		__typename?: "PageLocaleMeta";
			id?:PartialObjects["FieldMeta"],
			title?:PartialObjects["FieldMeta"],
			locale?:PartialObjects["FieldMeta"],
			link?:PartialObjects["FieldMeta"],
			root?:PartialObjects["FieldMeta"],
			content?:PartialObjects["FieldMeta"],
			bannerColor?:PartialObjects["FieldMeta"],
			bannerBgColor?:PartialObjects["FieldMeta"],
			bannerText?:PartialObjects["FieldMeta"]
	},
	["PageLocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	title?:PartialObjects["OrderDirection"],
	locale?:PartialObjects["LocaleOrderBy"],
	link?:PartialObjects["LinkableOrderBy"],
	root?:PartialObjects["PageOrderBy"],
	content?:PartialObjects["ContentOrderBy"],
	bannerColor?:PartialObjects["OrderDirection"],
	bannerBgColor?:PartialObjects["OrderDirection"],
	bannerText?:PartialObjects["OrderDirection"]
},
	["PageLocalesByContentUniqueWhere"]: {
	content?:PartialObjects["ContentUniqueWhere"]
},
	["PageLocalesByLinkUniqueWhere"]: {
	link?:PartialObjects["LinkableUniqueWhere"]
},
	["PageLocalesByLocaleUniqueWhere"]: {
	locale?:PartialObjects["LocaleUniqueWhere"]
},
	["PageLocaleUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	root?:PartialObjects["PageUniqueWhere"],
	locale?:PartialObjects["LocaleUniqueWhere"],
	link?:PartialObjects["LinkableUniqueWhere"],
	content?:PartialObjects["ContentUniqueWhere"]
},
	["PageLocaleUpdateContentEntityRelationInput"]: {
	create?:PartialObjects["ContentWithoutPageCreateInput"],
	update?:PartialObjects["ContentWithoutPageUpdateInput"],
	upsert?:PartialObjects["PageLocaleUpsertContentRelationInput"],
	connect?:PartialObjects["ContentUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["PageLocaleUpdateInput"]: {
	title?:string,
	locale?:PartialObjects["PageLocaleUpdateLocaleEntityRelationInput"],
	link?:PartialObjects["PageLocaleUpdateLinkEntityRelationInput"],
	root?:PartialObjects["PageLocaleUpdateRootEntityRelationInput"],
	content?:PartialObjects["PageLocaleUpdateContentEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
},
	["PageLocaleUpdateLinkEntityRelationInput"]: {
	create?:PartialObjects["LinkableWithoutPageCreateInput"],
	update?:PartialObjects["LinkableWithoutPageUpdateInput"],
	upsert?:PartialObjects["PageLocaleUpsertLinkRelationInput"],
	connect?:PartialObjects["LinkableUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["PageLocaleUpdateLocaleEntityRelationInput"]: {
	create?:PartialObjects["LocaleWithoutPagesCreateInput"],
	update?:PartialObjects["LocaleWithoutPagesUpdateInput"],
	upsert?:PartialObjects["PageLocaleUpsertLocaleRelationInput"],
	connect?:PartialObjects["LocaleUniqueWhere"]
},
	["PageLocaleUpdateResult"]: {
		__typename?: "PageLocaleUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["PageLocale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["PageLocaleUpdateRootEntityRelationInput"]: {
	create?:PartialObjects["PageWithoutLocalesCreateInput"],
	update?:PartialObjects["PageWithoutLocalesUpdateInput"],
	upsert?:PartialObjects["PageLocaleUpsertRootRelationInput"],
	connect?:PartialObjects["PageUniqueWhere"]
},
	["PageLocaleUpsertContentRelationInput"]: {
	update?:PartialObjects["ContentWithoutPageUpdateInput"],
	create?:PartialObjects["ContentWithoutPageCreateInput"]
},
	["PageLocaleUpsertLinkRelationInput"]: {
	update?:PartialObjects["LinkableWithoutPageUpdateInput"],
	create?:PartialObjects["LinkableWithoutPageCreateInput"]
},
	["PageLocaleUpsertLocaleRelationInput"]: {
	update?:PartialObjects["LocaleWithoutPagesUpdateInput"],
	create?:PartialObjects["LocaleWithoutPagesCreateInput"]
},
	["PageLocaleUpsertRootRelationInput"]: {
	update?:PartialObjects["PageWithoutLocalesUpdateInput"],
	create?:PartialObjects["PageWithoutLocalesCreateInput"]
},
	["PageLocaleWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	title?:PartialObjects["StringCondition"],
	locale?:PartialObjects["LocaleWhere"],
	link?:PartialObjects["LinkableWhere"],
	root?:PartialObjects["PageWhere"],
	content?:PartialObjects["ContentWhere"],
	bannerColor?:PartialObjects["StringCondition"],
	bannerBgColor?:PartialObjects["StringCondition"],
	bannerText?:PartialObjects["StringCondition"],
	and?:PartialObjects["PageLocaleWhere"][],
	or?:PartialObjects["PageLocaleWhere"][],
	not?:PartialObjects["PageLocaleWhere"]
},
	["PageLocaleWithoutContentCreateInput"]: {
	title?:string,
	locale?:PartialObjects["PageLocaleCreateLocaleEntityRelationInput"],
	link?:PartialObjects["PageLocaleCreateLinkEntityRelationInput"],
	root?:PartialObjects["PageLocaleCreateRootEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
},
	["PageLocaleWithoutContentUpdateInput"]: {
	title?:string,
	locale?:PartialObjects["PageLocaleUpdateLocaleEntityRelationInput"],
	link?:PartialObjects["PageLocaleUpdateLinkEntityRelationInput"],
	root?:PartialObjects["PageLocaleUpdateRootEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
},
	["PageLocaleWithoutLinkCreateInput"]: {
	title?:string,
	locale?:PartialObjects["PageLocaleCreateLocaleEntityRelationInput"],
	root?:PartialObjects["PageLocaleCreateRootEntityRelationInput"],
	content?:PartialObjects["PageLocaleCreateContentEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
},
	["PageLocaleWithoutLinkUpdateInput"]: {
	title?:string,
	locale?:PartialObjects["PageLocaleUpdateLocaleEntityRelationInput"],
	root?:PartialObjects["PageLocaleUpdateRootEntityRelationInput"],
	content?:PartialObjects["PageLocaleUpdateContentEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
},
	["PageLocaleWithoutLocaleCreateInput"]: {
	title?:string,
	link?:PartialObjects["PageLocaleCreateLinkEntityRelationInput"],
	root?:PartialObjects["PageLocaleCreateRootEntityRelationInput"],
	content?:PartialObjects["PageLocaleCreateContentEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
},
	["PageLocaleWithoutLocaleUpdateInput"]: {
	title?:string,
	link?:PartialObjects["PageLocaleUpdateLinkEntityRelationInput"],
	root?:PartialObjects["PageLocaleUpdateRootEntityRelationInput"],
	content?:PartialObjects["PageLocaleUpdateContentEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
},
	["PageLocaleWithoutRootCreateInput"]: {
	title?:string,
	locale?:PartialObjects["PageLocaleCreateLocaleEntityRelationInput"],
	link?:PartialObjects["PageLocaleCreateLinkEntityRelationInput"],
	content?:PartialObjects["PageLocaleCreateContentEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
},
	["PageLocaleWithoutRootUpdateInput"]: {
	title?:string,
	locale?:PartialObjects["PageLocaleUpdateLocaleEntityRelationInput"],
	link?:PartialObjects["PageLocaleUpdateLinkEntityRelationInput"],
	content?:PartialObjects["PageLocaleUpdateContentEntityRelationInput"],
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
},
	["PageMeta"]: {
		__typename?: "PageMeta";
			id?:PartialObjects["FieldMeta"],
			internalName?:PartialObjects["FieldMeta"],
			template?:PartialObjects["FieldMeta"],
			seo?:PartialObjects["FieldMeta"],
			locales?:PartialObjects["FieldMeta"]
	},
	["PageOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	internalName?:PartialObjects["OrderDirection"],
	template?:PartialObjects["OrderDirection"],
	seo?:PartialObjects["SeoOrderBy"]
},
	["PageType"]:PageType,
	["PageTypeEnumCondition"]: {
	and?:PartialObjects["PageTypeEnumCondition"][],
	or?:PartialObjects["PageTypeEnumCondition"][],
	not?:PartialObjects["PageTypeEnumCondition"],
	eq?:PartialObjects["PageType"],
	null?:boolean,
	isNull?:boolean,
	notEq?:PartialObjects["PageType"],
	in?:PartialObjects["PageType"][],
	notIn?:PartialObjects["PageType"][],
	lt?:PartialObjects["PageType"],
	lte?:PartialObjects["PageType"],
	gt?:PartialObjects["PageType"],
	gte?:PartialObjects["PageType"]
},
	["PageUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	seo?:PartialObjects["SeoUniqueWhere"],
	locales?:PartialObjects["PageLocaleUniqueWhere"]
},
	["PageUpdateInput"]: {
	internalName?:string,
	template?:PartialObjects["PageType"],
	seo?:PartialObjects["PageUpdateSeoEntityRelationInput"],
	locales?:PartialObjects["PageUpdateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["PageUpdateLocalesEntityRelationInput"]: {
	create?:PartialObjects["PageLocaleWithoutRootCreateInput"],
	update?:PartialObjects["PageUpdateLocalesRelationInput"],
	upsert?:PartialObjects["PageUpsertLocalesRelationInput"],
	connect?:PartialObjects["PageLocaleUniqueWhere"],
	disconnect?:PartialObjects["PageLocaleUniqueWhere"],
	delete?:PartialObjects["PageLocaleUniqueWhere"],
	alias?:string
},
	["PageUpdateLocalesRelationInput"]: {
	by?:PartialObjects["PageLocaleUniqueWhere"],
	data?:PartialObjects["PageLocaleWithoutRootUpdateInput"]
},
	["PageUpdateResult"]: {
		__typename?: "PageUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Page"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["PageUpdateSeoEntityRelationInput"]: {
	create?:PartialObjects["SeoCreateInput"],
	update?:PartialObjects["SeoUpdateInput"],
	upsert?:PartialObjects["PageUpsertSeoRelationInput"],
	connect?:PartialObjects["SeoUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["PageUpsertLocalesRelationInput"]: {
	by?:PartialObjects["PageLocaleUniqueWhere"],
	update?:PartialObjects["PageLocaleWithoutRootUpdateInput"],
	create?:PartialObjects["PageLocaleWithoutRootCreateInput"]
},
	["PageUpsertSeoRelationInput"]: {
	update?:PartialObjects["SeoUpdateInput"],
	create?:PartialObjects["SeoCreateInput"]
},
	["PageWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	internalName?:PartialObjects["StringCondition"],
	template?:PartialObjects["PageTypeEnumCondition"],
	seo?:PartialObjects["SeoWhere"],
	locales?:PartialObjects["PageLocaleWhere"],
	and?:PartialObjects["PageWhere"][],
	or?:PartialObjects["PageWhere"][],
	not?:PartialObjects["PageWhere"]
},
	["PageWithoutLocalesCreateInput"]: {
	internalName?:string,
	template?:PartialObjects["PageType"],
	seo?:PartialObjects["PageCreateSeoEntityRelationInput"],
	_dummy_field_?:boolean
},
	["PageWithoutLocalesUpdateInput"]: {
	internalName?:string,
	template?:PartialObjects["PageType"],
	seo?:PartialObjects["PageUpdateSeoEntityRelationInput"],
	_dummy_field_?:boolean
},
	["Product"]: {
		__typename?: "Product";
			_meta?:PartialObjects["ProductMeta"],
			id?:PartialObjects["UUID"],
			title?:string,
			shopifyHandle?:string,
			productType?:string,
			stores?:PartialObjects["ProductStore"][],
			productVariants?:PartialObjects["ProductVariant"][],
			locales?:PartialObjects["ProductLocale"][],
			storesByStore?:PartialObjects["ProductStore"],
			storesByShopifyId?:PartialObjects["ProductStore"],
			productVariantsByShopifySku?:PartialObjects["ProductVariant"],
			productVariantsByLocales?:PartialObjects["ProductVariant"],
			productVariantsByStores?:PartialObjects["ProductVariant"],
			localesByLocale?:PartialObjects["ProductLocale"]
	},
	["ProductConnection"]: {
		__typename?: "ProductConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["ProductEdge"][]
	},
	["ProductCreateInput"]: {
	title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:PartialObjects["ProductCreateStoresEntityRelationInput"][],
	productVariants?:PartialObjects["ProductCreateProductVariantsEntityRelationInput"][],
	locales?:PartialObjects["ProductCreateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["ProductCreateLocalesEntityRelationInput"]: {
	connect?:PartialObjects["ProductLocaleUniqueWhere"],
	create?:PartialObjects["ProductLocaleWithoutRootCreateInput"],
	alias?:string
},
	["ProductCreateProductVariantsEntityRelationInput"]: {
	connect?:PartialObjects["ProductVariantUniqueWhere"],
	create?:PartialObjects["ProductVariantWithoutProductCreateInput"],
	alias?:string
},
	["ProductCreateResult"]: {
		__typename?: "ProductCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Product"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ProductCreateStoresEntityRelationInput"]: {
	connect?:PartialObjects["ProductStoreUniqueWhere"],
	create?:PartialObjects["ProductStoreWithoutRootCreateInput"],
	alias?:string
},
	["ProductDeleteResult"]: {
		__typename?: "ProductDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Product"]
	},
	["ProductEdge"]: {
		__typename?: "ProductEdge";
			node?:PartialObjects["Product"]
	},
	["ProductLocale"]: {
		__typename?: "ProductLocale";
			_meta?:PartialObjects["ProductLocaleMeta"],
			id?:PartialObjects["UUID"],
			title?:string,
			description?:string,
			descriptionSecondary?:string,
			root?:PartialObjects["Product"],
			locale?:PartialObjects["Locale"],
			gallery?:PartialObjects["Gallery"],
			titleTemplate?:string
	},
	["ProductLocaleConnection"]: {
		__typename?: "ProductLocaleConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["ProductLocaleEdge"][]
	},
	["ProductLocaleCreateGalleryEntityRelationInput"]: {
	connect?:PartialObjects["GalleryUniqueWhere"],
	create?:PartialObjects["GalleryCreateInput"]
},
	["ProductLocaleCreateInput"]: {
	title?:string,
	description?:string,
	descriptionSecondary?:string,
	root?:PartialObjects["ProductLocaleCreateRootEntityRelationInput"],
	locale?:PartialObjects["ProductLocaleCreateLocaleEntityRelationInput"],
	gallery?:PartialObjects["ProductLocaleCreateGalleryEntityRelationInput"],
	titleTemplate?:string,
	_dummy_field_?:boolean
},
	["ProductLocaleCreateLocaleEntityRelationInput"]: {
	connect?:PartialObjects["LocaleUniqueWhere"],
	create?:PartialObjects["LocaleWithoutProductsCreateInput"]
},
	["ProductLocaleCreateResult"]: {
		__typename?: "ProductLocaleCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ProductLocale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ProductLocaleCreateRootEntityRelationInput"]: {
	connect?:PartialObjects["ProductUniqueWhere"],
	create?:PartialObjects["ProductWithoutLocalesCreateInput"]
},
	["ProductLocaleDeleteResult"]: {
		__typename?: "ProductLocaleDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ProductLocale"]
	},
	["ProductLocaleEdge"]: {
		__typename?: "ProductLocaleEdge";
			node?:PartialObjects["ProductLocale"]
	},
	["ProductLocaleMeta"]: {
		__typename?: "ProductLocaleMeta";
			id?:PartialObjects["FieldMeta"],
			title?:PartialObjects["FieldMeta"],
			description?:PartialObjects["FieldMeta"],
			descriptionSecondary?:PartialObjects["FieldMeta"],
			root?:PartialObjects["FieldMeta"],
			locale?:PartialObjects["FieldMeta"],
			gallery?:PartialObjects["FieldMeta"],
			titleTemplate?:PartialObjects["FieldMeta"]
	},
	["ProductLocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	title?:PartialObjects["OrderDirection"],
	description?:PartialObjects["OrderDirection"],
	descriptionSecondary?:PartialObjects["OrderDirection"],
	root?:PartialObjects["ProductOrderBy"],
	locale?:PartialObjects["LocaleOrderBy"],
	gallery?:PartialObjects["GalleryOrderBy"],
	titleTemplate?:PartialObjects["OrderDirection"]
},
	["ProductLocalesByLocaleUniqueWhere"]: {
	locale?:PartialObjects["LocaleUniqueWhere"]
},
	["ProductLocaleUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	root?:PartialObjects["ProductUniqueWhere"],
	locale?:PartialObjects["LocaleUniqueWhere"]
},
	["ProductLocaleUpdateGalleryEntityRelationInput"]: {
	create?:PartialObjects["GalleryCreateInput"],
	update?:PartialObjects["GalleryUpdateInput"],
	upsert?:PartialObjects["ProductLocaleUpsertGalleryRelationInput"],
	connect?:PartialObjects["GalleryUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["ProductLocaleUpdateInput"]: {
	title?:string,
	description?:string,
	descriptionSecondary?:string,
	root?:PartialObjects["ProductLocaleUpdateRootEntityRelationInput"],
	locale?:PartialObjects["ProductLocaleUpdateLocaleEntityRelationInput"],
	gallery?:PartialObjects["ProductLocaleUpdateGalleryEntityRelationInput"],
	titleTemplate?:string,
	_dummy_field_?:boolean
},
	["ProductLocaleUpdateLocaleEntityRelationInput"]: {
	create?:PartialObjects["LocaleWithoutProductsCreateInput"],
	update?:PartialObjects["LocaleWithoutProductsUpdateInput"],
	upsert?:PartialObjects["ProductLocaleUpsertLocaleRelationInput"],
	connect?:PartialObjects["LocaleUniqueWhere"]
},
	["ProductLocaleUpdateResult"]: {
		__typename?: "ProductLocaleUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ProductLocale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ProductLocaleUpdateRootEntityRelationInput"]: {
	create?:PartialObjects["ProductWithoutLocalesCreateInput"],
	update?:PartialObjects["ProductWithoutLocalesUpdateInput"],
	upsert?:PartialObjects["ProductLocaleUpsertRootRelationInput"],
	connect?:PartialObjects["ProductUniqueWhere"]
},
	["ProductLocaleUpsertGalleryRelationInput"]: {
	update?:PartialObjects["GalleryUpdateInput"],
	create?:PartialObjects["GalleryCreateInput"]
},
	["ProductLocaleUpsertLocaleRelationInput"]: {
	update?:PartialObjects["LocaleWithoutProductsUpdateInput"],
	create?:PartialObjects["LocaleWithoutProductsCreateInput"]
},
	["ProductLocaleUpsertRootRelationInput"]: {
	update?:PartialObjects["ProductWithoutLocalesUpdateInput"],
	create?:PartialObjects["ProductWithoutLocalesCreateInput"]
},
	["ProductLocaleWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	title?:PartialObjects["StringCondition"],
	description?:PartialObjects["StringCondition"],
	descriptionSecondary?:PartialObjects["StringCondition"],
	root?:PartialObjects["ProductWhere"],
	locale?:PartialObjects["LocaleWhere"],
	gallery?:PartialObjects["GalleryWhere"],
	titleTemplate?:PartialObjects["StringCondition"],
	and?:PartialObjects["ProductLocaleWhere"][],
	or?:PartialObjects["ProductLocaleWhere"][],
	not?:PartialObjects["ProductLocaleWhere"]
},
	["ProductLocaleWithoutLocaleCreateInput"]: {
	title?:string,
	description?:string,
	descriptionSecondary?:string,
	root?:PartialObjects["ProductLocaleCreateRootEntityRelationInput"],
	gallery?:PartialObjects["ProductLocaleCreateGalleryEntityRelationInput"],
	titleTemplate?:string,
	_dummy_field_?:boolean
},
	["ProductLocaleWithoutLocaleUpdateInput"]: {
	title?:string,
	description?:string,
	descriptionSecondary?:string,
	root?:PartialObjects["ProductLocaleUpdateRootEntityRelationInput"],
	gallery?:PartialObjects["ProductLocaleUpdateGalleryEntityRelationInput"],
	titleTemplate?:string,
	_dummy_field_?:boolean
},
	["ProductLocaleWithoutRootCreateInput"]: {
	title?:string,
	description?:string,
	descriptionSecondary?:string,
	locale?:PartialObjects["ProductLocaleCreateLocaleEntityRelationInput"],
	gallery?:PartialObjects["ProductLocaleCreateGalleryEntityRelationInput"],
	titleTemplate?:string,
	_dummy_field_?:boolean
},
	["ProductLocaleWithoutRootUpdateInput"]: {
	title?:string,
	description?:string,
	descriptionSecondary?:string,
	locale?:PartialObjects["ProductLocaleUpdateLocaleEntityRelationInput"],
	gallery?:PartialObjects["ProductLocaleUpdateGalleryEntityRelationInput"],
	titleTemplate?:string,
	_dummy_field_?:boolean
},
	["ProductMeta"]: {
		__typename?: "ProductMeta";
			id?:PartialObjects["FieldMeta"],
			title?:PartialObjects["FieldMeta"],
			shopifyHandle?:PartialObjects["FieldMeta"],
			productType?:PartialObjects["FieldMeta"],
			stores?:PartialObjects["FieldMeta"],
			productVariants?:PartialObjects["FieldMeta"],
			locales?:PartialObjects["FieldMeta"]
	},
	["ProductOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	title?:PartialObjects["OrderDirection"],
	shopifyHandle?:PartialObjects["OrderDirection"],
	productType?:PartialObjects["OrderDirection"]
},
	["ProductProductVariantsByLocalesUniqueWhere"]: {
	locales?:PartialObjects["ProductVariantLocaleUniqueWhere"]
},
	["ProductProductVariantsByShopifySkuUniqueWhere"]: {
	shopifySku?:string
},
	["ProductProductVariantsByStoresUniqueWhere"]: {
	stores?:PartialObjects["ProductVariantStoreUniqueWhere"]
},
	["ProductStore"]: {
		__typename?: "ProductStore";
			_meta?:PartialObjects["ProductStoreMeta"],
			id?:PartialObjects["UUID"],
			shopifyId?:string,
			root?:PartialObjects["Product"],
			store?:PartialObjects["Store"]
	},
	["ProductStoreConnection"]: {
		__typename?: "ProductStoreConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["ProductStoreEdge"][]
	},
	["ProductStoreCreateInput"]: {
	shopifyId?:string,
	root?:PartialObjects["ProductStoreCreateRootEntityRelationInput"],
	store?:PartialObjects["ProductStoreCreateStoreEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ProductStoreCreateResult"]: {
		__typename?: "ProductStoreCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ProductStore"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ProductStoreCreateRootEntityRelationInput"]: {
	connect?:PartialObjects["ProductUniqueWhere"],
	create?:PartialObjects["ProductWithoutStoresCreateInput"]
},
	["ProductStoreCreateStoreEntityRelationInput"]: {
	connect?:PartialObjects["StoreUniqueWhere"],
	create?:PartialObjects["StoreWithoutProductsCreateInput"]
},
	["ProductStoreDeleteResult"]: {
		__typename?: "ProductStoreDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ProductStore"]
	},
	["ProductStoreEdge"]: {
		__typename?: "ProductStoreEdge";
			node?:PartialObjects["ProductStore"]
	},
	["ProductStoreMeta"]: {
		__typename?: "ProductStoreMeta";
			id?:PartialObjects["FieldMeta"],
			shopifyId?:PartialObjects["FieldMeta"],
			root?:PartialObjects["FieldMeta"],
			store?:PartialObjects["FieldMeta"]
	},
	["ProductStoreOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	shopifyId?:PartialObjects["OrderDirection"],
	root?:PartialObjects["ProductOrderBy"],
	store?:PartialObjects["StoreOrderBy"]
},
	["ProductStoresByShopifyIdUniqueWhere"]: {
	shopifyId?:string
},
	["ProductStoresByStoreUniqueWhere"]: {
	store?:PartialObjects["StoreUniqueWhere"]
},
	["ProductStoreUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	root?:PartialObjects["ProductUniqueWhere"],
	store?:PartialObjects["StoreUniqueWhere"],
	shopifyId?:string
},
	["ProductStoreUpdateInput"]: {
	shopifyId?:string,
	root?:PartialObjects["ProductStoreUpdateRootEntityRelationInput"],
	store?:PartialObjects["ProductStoreUpdateStoreEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ProductStoreUpdateResult"]: {
		__typename?: "ProductStoreUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ProductStore"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ProductStoreUpdateRootEntityRelationInput"]: {
	create?:PartialObjects["ProductWithoutStoresCreateInput"],
	update?:PartialObjects["ProductWithoutStoresUpdateInput"],
	upsert?:PartialObjects["ProductStoreUpsertRootRelationInput"],
	connect?:PartialObjects["ProductUniqueWhere"]
},
	["ProductStoreUpdateStoreEntityRelationInput"]: {
	create?:PartialObjects["StoreWithoutProductsCreateInput"],
	update?:PartialObjects["StoreWithoutProductsUpdateInput"],
	upsert?:PartialObjects["ProductStoreUpsertStoreRelationInput"],
	connect?:PartialObjects["StoreUniqueWhere"]
},
	["ProductStoreUpsertRootRelationInput"]: {
	update?:PartialObjects["ProductWithoutStoresUpdateInput"],
	create?:PartialObjects["ProductWithoutStoresCreateInput"]
},
	["ProductStoreUpsertStoreRelationInput"]: {
	update?:PartialObjects["StoreWithoutProductsUpdateInput"],
	create?:PartialObjects["StoreWithoutProductsCreateInput"]
},
	["ProductStoreWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	shopifyId?:PartialObjects["StringCondition"],
	root?:PartialObjects["ProductWhere"],
	store?:PartialObjects["StoreWhere"],
	and?:PartialObjects["ProductStoreWhere"][],
	or?:PartialObjects["ProductStoreWhere"][],
	not?:PartialObjects["ProductStoreWhere"]
},
	["ProductStoreWithoutRootCreateInput"]: {
	shopifyId?:string,
	store?:PartialObjects["ProductStoreCreateStoreEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ProductStoreWithoutRootUpdateInput"]: {
	shopifyId?:string,
	store?:PartialObjects["ProductStoreUpdateStoreEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ProductStoreWithoutStoreCreateInput"]: {
	shopifyId?:string,
	root?:PartialObjects["ProductStoreCreateRootEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ProductStoreWithoutStoreUpdateInput"]: {
	shopifyId?:string,
	root?:PartialObjects["ProductStoreUpdateRootEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ProductUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	shopifyHandle?:string,
	stores?:PartialObjects["ProductStoreUniqueWhere"],
	productVariants?:PartialObjects["ProductVariantUniqueWhere"],
	locales?:PartialObjects["ProductLocaleUniqueWhere"]
},
	["ProductUpdateInput"]: {
	title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:PartialObjects["ProductUpdateStoresEntityRelationInput"][],
	productVariants?:PartialObjects["ProductUpdateProductVariantsEntityRelationInput"][],
	locales?:PartialObjects["ProductUpdateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["ProductUpdateLocalesEntityRelationInput"]: {
	create?:PartialObjects["ProductLocaleWithoutRootCreateInput"],
	update?:PartialObjects["ProductUpdateLocalesRelationInput"],
	upsert?:PartialObjects["ProductUpsertLocalesRelationInput"],
	connect?:PartialObjects["ProductLocaleUniqueWhere"],
	disconnect?:PartialObjects["ProductLocaleUniqueWhere"],
	delete?:PartialObjects["ProductLocaleUniqueWhere"],
	alias?:string
},
	["ProductUpdateLocalesRelationInput"]: {
	by?:PartialObjects["ProductLocaleUniqueWhere"],
	data?:PartialObjects["ProductLocaleWithoutRootUpdateInput"]
},
	["ProductUpdateProductVariantsEntityRelationInput"]: {
	create?:PartialObjects["ProductVariantWithoutProductCreateInput"],
	update?:PartialObjects["ProductUpdateProductVariantsRelationInput"],
	upsert?:PartialObjects["ProductUpsertProductVariantsRelationInput"],
	connect?:PartialObjects["ProductVariantUniqueWhere"],
	disconnect?:PartialObjects["ProductVariantUniqueWhere"],
	delete?:PartialObjects["ProductVariantUniqueWhere"],
	alias?:string
},
	["ProductUpdateProductVariantsRelationInput"]: {
	by?:PartialObjects["ProductVariantUniqueWhere"],
	data?:PartialObjects["ProductVariantWithoutProductUpdateInput"]
},
	["ProductUpdateResult"]: {
		__typename?: "ProductUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Product"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ProductUpdateStoresEntityRelationInput"]: {
	create?:PartialObjects["ProductStoreWithoutRootCreateInput"],
	update?:PartialObjects["ProductUpdateStoresRelationInput"],
	upsert?:PartialObjects["ProductUpsertStoresRelationInput"],
	connect?:PartialObjects["ProductStoreUniqueWhere"],
	disconnect?:PartialObjects["ProductStoreUniqueWhere"],
	delete?:PartialObjects["ProductStoreUniqueWhere"],
	alias?:string
},
	["ProductUpdateStoresRelationInput"]: {
	by?:PartialObjects["ProductStoreUniqueWhere"],
	data?:PartialObjects["ProductStoreWithoutRootUpdateInput"]
},
	["ProductUpsertLocalesRelationInput"]: {
	by?:PartialObjects["ProductLocaleUniqueWhere"],
	update?:PartialObjects["ProductLocaleWithoutRootUpdateInput"],
	create?:PartialObjects["ProductLocaleWithoutRootCreateInput"]
},
	["ProductUpsertProductVariantsRelationInput"]: {
	by?:PartialObjects["ProductVariantUniqueWhere"],
	update?:PartialObjects["ProductVariantWithoutProductUpdateInput"],
	create?:PartialObjects["ProductVariantWithoutProductCreateInput"]
},
	["ProductUpsertStoresRelationInput"]: {
	by?:PartialObjects["ProductStoreUniqueWhere"],
	update?:PartialObjects["ProductStoreWithoutRootUpdateInput"],
	create?:PartialObjects["ProductStoreWithoutRootCreateInput"]
},
	["ProductVariant"]: {
		__typename?: "ProductVariant";
			_meta?:PartialObjects["ProductVariantMeta"],
			id?:PartialObjects["UUID"],
			shopifySku?:string,
			product?:PartialObjects["Product"],
			locales?:PartialObjects["ProductVariantLocale"][],
			stores?:PartialObjects["ProductVariantStore"][],
			title?:string,
			heurekaId?:string,
			localesByLocale?:PartialObjects["ProductVariantLocale"],
			storesByStore?:PartialObjects["ProductVariantStore"],
			storesByShopifyId?:PartialObjects["ProductVariantStore"],
			storesByStorefrontId?:PartialObjects["ProductVariantStore"]
	},
	["ProductVariantConnection"]: {
		__typename?: "ProductVariantConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["ProductVariantEdge"][]
	},
	["ProductVariantCreateInput"]: {
	shopifySku?:string,
	product?:PartialObjects["ProductVariantCreateProductEntityRelationInput"],
	locales?:PartialObjects["ProductVariantCreateLocalesEntityRelationInput"][],
	stores?:PartialObjects["ProductVariantCreateStoresEntityRelationInput"][],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
},
	["ProductVariantCreateLocalesEntityRelationInput"]: {
	connect?:PartialObjects["ProductVariantLocaleUniqueWhere"],
	create?:PartialObjects["ProductVariantLocaleWithoutRootCreateInput"],
	alias?:string
},
	["ProductVariantCreateProductEntityRelationInput"]: {
	connect?:PartialObjects["ProductUniqueWhere"],
	create?:PartialObjects["ProductWithoutProductVariantsCreateInput"]
},
	["ProductVariantCreateResult"]: {
		__typename?: "ProductVariantCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ProductVariant"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ProductVariantCreateStoresEntityRelationInput"]: {
	connect?:PartialObjects["ProductVariantStoreUniqueWhere"],
	create?:PartialObjects["ProductVariantStoreWithoutRootCreateInput"],
	alias?:string
},
	["ProductVariantDeleteResult"]: {
		__typename?: "ProductVariantDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ProductVariant"]
	},
	["ProductVariantEdge"]: {
		__typename?: "ProductVariantEdge";
			node?:PartialObjects["ProductVariant"]
	},
	["ProductVariantLocale"]: {
		__typename?: "ProductVariantLocale";
			_meta?:PartialObjects["ProductVariantLocaleMeta"],
			id?:PartialObjects["UUID"],
			title?:string,
			description?:string,
			root?:PartialObjects["ProductVariant"],
			locale?:PartialObjects["Locale"],
			gallery?:PartialObjects["Gallery"],
			descriptionSecondary?:string,
			titleTemplate?:string
	},
	["ProductVariantLocaleConnection"]: {
		__typename?: "ProductVariantLocaleConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["ProductVariantLocaleEdge"][]
	},
	["ProductVariantLocaleCreateGalleryEntityRelationInput"]: {
	connect?:PartialObjects["GalleryUniqueWhere"],
	create?:PartialObjects["GalleryCreateInput"]
},
	["ProductVariantLocaleCreateInput"]: {
	title?:string,
	description?:string,
	root?:PartialObjects["ProductVariantLocaleCreateRootEntityRelationInput"],
	locale?:PartialObjects["ProductVariantLocaleCreateLocaleEntityRelationInput"],
	gallery?:PartialObjects["ProductVariantLocaleCreateGalleryEntityRelationInput"],
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
},
	["ProductVariantLocaleCreateLocaleEntityRelationInput"]: {
	connect?:PartialObjects["LocaleUniqueWhere"],
	create?:PartialObjects["LocaleWithoutProductVariantsCreateInput"]
},
	["ProductVariantLocaleCreateResult"]: {
		__typename?: "ProductVariantLocaleCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ProductVariantLocale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ProductVariantLocaleCreateRootEntityRelationInput"]: {
	connect?:PartialObjects["ProductVariantUniqueWhere"],
	create?:PartialObjects["ProductVariantWithoutLocalesCreateInput"]
},
	["ProductVariantLocaleDeleteResult"]: {
		__typename?: "ProductVariantLocaleDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ProductVariantLocale"]
	},
	["ProductVariantLocaleEdge"]: {
		__typename?: "ProductVariantLocaleEdge";
			node?:PartialObjects["ProductVariantLocale"]
	},
	["ProductVariantLocaleMeta"]: {
		__typename?: "ProductVariantLocaleMeta";
			id?:PartialObjects["FieldMeta"],
			title?:PartialObjects["FieldMeta"],
			description?:PartialObjects["FieldMeta"],
			root?:PartialObjects["FieldMeta"],
			locale?:PartialObjects["FieldMeta"],
			gallery?:PartialObjects["FieldMeta"],
			descriptionSecondary?:PartialObjects["FieldMeta"],
			titleTemplate?:PartialObjects["FieldMeta"]
	},
	["ProductVariantLocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	title?:PartialObjects["OrderDirection"],
	description?:PartialObjects["OrderDirection"],
	root?:PartialObjects["ProductVariantOrderBy"],
	locale?:PartialObjects["LocaleOrderBy"],
	gallery?:PartialObjects["GalleryOrderBy"],
	descriptionSecondary?:PartialObjects["OrderDirection"],
	titleTemplate?:PartialObjects["OrderDirection"]
},
	["ProductVariantLocalesByLocaleUniqueWhere"]: {
	locale?:PartialObjects["LocaleUniqueWhere"]
},
	["ProductVariantLocaleUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	root?:PartialObjects["ProductVariantUniqueWhere"],
	locale?:PartialObjects["LocaleUniqueWhere"]
},
	["ProductVariantLocaleUpdateGalleryEntityRelationInput"]: {
	create?:PartialObjects["GalleryCreateInput"],
	update?:PartialObjects["GalleryUpdateInput"],
	upsert?:PartialObjects["ProductVariantLocaleUpsertGalleryRelationInput"],
	connect?:PartialObjects["GalleryUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["ProductVariantLocaleUpdateInput"]: {
	title?:string,
	description?:string,
	root?:PartialObjects["ProductVariantLocaleUpdateRootEntityRelationInput"],
	locale?:PartialObjects["ProductVariantLocaleUpdateLocaleEntityRelationInput"],
	gallery?:PartialObjects["ProductVariantLocaleUpdateGalleryEntityRelationInput"],
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
},
	["ProductVariantLocaleUpdateLocaleEntityRelationInput"]: {
	create?:PartialObjects["LocaleWithoutProductVariantsCreateInput"],
	update?:PartialObjects["LocaleWithoutProductVariantsUpdateInput"],
	upsert?:PartialObjects["ProductVariantLocaleUpsertLocaleRelationInput"],
	connect?:PartialObjects["LocaleUniqueWhere"]
},
	["ProductVariantLocaleUpdateResult"]: {
		__typename?: "ProductVariantLocaleUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ProductVariantLocale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ProductVariantLocaleUpdateRootEntityRelationInput"]: {
	create?:PartialObjects["ProductVariantWithoutLocalesCreateInput"],
	update?:PartialObjects["ProductVariantWithoutLocalesUpdateInput"],
	upsert?:PartialObjects["ProductVariantLocaleUpsertRootRelationInput"],
	connect?:PartialObjects["ProductVariantUniqueWhere"]
},
	["ProductVariantLocaleUpsertGalleryRelationInput"]: {
	update?:PartialObjects["GalleryUpdateInput"],
	create?:PartialObjects["GalleryCreateInput"]
},
	["ProductVariantLocaleUpsertLocaleRelationInput"]: {
	update?:PartialObjects["LocaleWithoutProductVariantsUpdateInput"],
	create?:PartialObjects["LocaleWithoutProductVariantsCreateInput"]
},
	["ProductVariantLocaleUpsertRootRelationInput"]: {
	update?:PartialObjects["ProductVariantWithoutLocalesUpdateInput"],
	create?:PartialObjects["ProductVariantWithoutLocalesCreateInput"]
},
	["ProductVariantLocaleWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	title?:PartialObjects["StringCondition"],
	description?:PartialObjects["StringCondition"],
	root?:PartialObjects["ProductVariantWhere"],
	locale?:PartialObjects["LocaleWhere"],
	gallery?:PartialObjects["GalleryWhere"],
	descriptionSecondary?:PartialObjects["StringCondition"],
	titleTemplate?:PartialObjects["StringCondition"],
	and?:PartialObjects["ProductVariantLocaleWhere"][],
	or?:PartialObjects["ProductVariantLocaleWhere"][],
	not?:PartialObjects["ProductVariantLocaleWhere"]
},
	["ProductVariantLocaleWithoutLocaleCreateInput"]: {
	title?:string,
	description?:string,
	root?:PartialObjects["ProductVariantLocaleCreateRootEntityRelationInput"],
	gallery?:PartialObjects["ProductVariantLocaleCreateGalleryEntityRelationInput"],
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
},
	["ProductVariantLocaleWithoutLocaleUpdateInput"]: {
	title?:string,
	description?:string,
	root?:PartialObjects["ProductVariantLocaleUpdateRootEntityRelationInput"],
	gallery?:PartialObjects["ProductVariantLocaleUpdateGalleryEntityRelationInput"],
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
},
	["ProductVariantLocaleWithoutRootCreateInput"]: {
	title?:string,
	description?:string,
	locale?:PartialObjects["ProductVariantLocaleCreateLocaleEntityRelationInput"],
	gallery?:PartialObjects["ProductVariantLocaleCreateGalleryEntityRelationInput"],
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
},
	["ProductVariantLocaleWithoutRootUpdateInput"]: {
	title?:string,
	description?:string,
	locale?:PartialObjects["ProductVariantLocaleUpdateLocaleEntityRelationInput"],
	gallery?:PartialObjects["ProductVariantLocaleUpdateGalleryEntityRelationInput"],
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
},
	["ProductVariantMeta"]: {
		__typename?: "ProductVariantMeta";
			id?:PartialObjects["FieldMeta"],
			shopifySku?:PartialObjects["FieldMeta"],
			product?:PartialObjects["FieldMeta"],
			locales?:PartialObjects["FieldMeta"],
			stores?:PartialObjects["FieldMeta"],
			title?:PartialObjects["FieldMeta"],
			heurekaId?:PartialObjects["FieldMeta"]
	},
	["ProductVariantOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	shopifySku?:PartialObjects["OrderDirection"],
	product?:PartialObjects["ProductOrderBy"],
	title?:PartialObjects["OrderDirection"],
	heurekaId?:PartialObjects["OrderDirection"]
},
	["ProductVariantStore"]: {
		__typename?: "ProductVariantStore";
			_meta?:PartialObjects["ProductVariantStoreMeta"],
			id?:PartialObjects["UUID"],
			price?:number,
			shopifyId?:string,
			root?:PartialObjects["ProductVariant"],
			store?:PartialObjects["Store"],
			compareAtPrice?:number,
			storefrontId?:string
	},
	["ProductVariantStoreConnection"]: {
		__typename?: "ProductVariantStoreConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["ProductVariantStoreEdge"][]
	},
	["ProductVariantStoreCreateInput"]: {
	price?:number,
	shopifyId?:string,
	root?:PartialObjects["ProductVariantStoreCreateRootEntityRelationInput"],
	store?:PartialObjects["ProductVariantStoreCreateStoreEntityRelationInput"],
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
},
	["ProductVariantStoreCreateResult"]: {
		__typename?: "ProductVariantStoreCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ProductVariantStore"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ProductVariantStoreCreateRootEntityRelationInput"]: {
	connect?:PartialObjects["ProductVariantUniqueWhere"],
	create?:PartialObjects["ProductVariantWithoutStoresCreateInput"]
},
	["ProductVariantStoreCreateStoreEntityRelationInput"]: {
	connect?:PartialObjects["StoreUniqueWhere"],
	create?:PartialObjects["StoreWithoutProductVariantsCreateInput"]
},
	["ProductVariantStoreDeleteResult"]: {
		__typename?: "ProductVariantStoreDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ProductVariantStore"]
	},
	["ProductVariantStoreEdge"]: {
		__typename?: "ProductVariantStoreEdge";
			node?:PartialObjects["ProductVariantStore"]
	},
	["ProductVariantStoreMeta"]: {
		__typename?: "ProductVariantStoreMeta";
			id?:PartialObjects["FieldMeta"],
			price?:PartialObjects["FieldMeta"],
			shopifyId?:PartialObjects["FieldMeta"],
			root?:PartialObjects["FieldMeta"],
			store?:PartialObjects["FieldMeta"],
			compareAtPrice?:PartialObjects["FieldMeta"],
			storefrontId?:PartialObjects["FieldMeta"]
	},
	["ProductVariantStoreOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	price?:PartialObjects["OrderDirection"],
	shopifyId?:PartialObjects["OrderDirection"],
	root?:PartialObjects["ProductVariantOrderBy"],
	store?:PartialObjects["StoreOrderBy"],
	compareAtPrice?:PartialObjects["OrderDirection"],
	storefrontId?:PartialObjects["OrderDirection"]
},
	["ProductVariantStoresByShopifyIdUniqueWhere"]: {
	shopifyId?:string
},
	["ProductVariantStoresByStorefrontIdUniqueWhere"]: {
	storefrontId?:string
},
	["ProductVariantStoresByStoreUniqueWhere"]: {
	store?:PartialObjects["StoreUniqueWhere"]
},
	["ProductVariantStoreUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	root?:PartialObjects["ProductVariantUniqueWhere"],
	store?:PartialObjects["StoreUniqueWhere"],
	shopifyId?:string,
	storefrontId?:string
},
	["ProductVariantStoreUpdateInput"]: {
	price?:number,
	shopifyId?:string,
	root?:PartialObjects["ProductVariantStoreUpdateRootEntityRelationInput"],
	store?:PartialObjects["ProductVariantStoreUpdateStoreEntityRelationInput"],
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
},
	["ProductVariantStoreUpdateResult"]: {
		__typename?: "ProductVariantStoreUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ProductVariantStore"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ProductVariantStoreUpdateRootEntityRelationInput"]: {
	create?:PartialObjects["ProductVariantWithoutStoresCreateInput"],
	update?:PartialObjects["ProductVariantWithoutStoresUpdateInput"],
	upsert?:PartialObjects["ProductVariantStoreUpsertRootRelationInput"],
	connect?:PartialObjects["ProductVariantUniqueWhere"]
},
	["ProductVariantStoreUpdateStoreEntityRelationInput"]: {
	create?:PartialObjects["StoreWithoutProductVariantsCreateInput"],
	update?:PartialObjects["StoreWithoutProductVariantsUpdateInput"],
	upsert?:PartialObjects["ProductVariantStoreUpsertStoreRelationInput"],
	connect?:PartialObjects["StoreUniqueWhere"]
},
	["ProductVariantStoreUpsertRootRelationInput"]: {
	update?:PartialObjects["ProductVariantWithoutStoresUpdateInput"],
	create?:PartialObjects["ProductVariantWithoutStoresCreateInput"]
},
	["ProductVariantStoreUpsertStoreRelationInput"]: {
	update?:PartialObjects["StoreWithoutProductVariantsUpdateInput"],
	create?:PartialObjects["StoreWithoutProductVariantsCreateInput"]
},
	["ProductVariantStoreWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	price?:PartialObjects["FloatCondition"],
	shopifyId?:PartialObjects["StringCondition"],
	root?:PartialObjects["ProductVariantWhere"],
	store?:PartialObjects["StoreWhere"],
	compareAtPrice?:PartialObjects["FloatCondition"],
	storefrontId?:PartialObjects["StringCondition"],
	and?:PartialObjects["ProductVariantStoreWhere"][],
	or?:PartialObjects["ProductVariantStoreWhere"][],
	not?:PartialObjects["ProductVariantStoreWhere"]
},
	["ProductVariantStoreWithoutRootCreateInput"]: {
	price?:number,
	shopifyId?:string,
	store?:PartialObjects["ProductVariantStoreCreateStoreEntityRelationInput"],
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
},
	["ProductVariantStoreWithoutRootUpdateInput"]: {
	price?:number,
	shopifyId?:string,
	store?:PartialObjects["ProductVariantStoreUpdateStoreEntityRelationInput"],
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
},
	["ProductVariantStoreWithoutStoreCreateInput"]: {
	price?:number,
	shopifyId?:string,
	root?:PartialObjects["ProductVariantStoreCreateRootEntityRelationInput"],
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
},
	["ProductVariantStoreWithoutStoreUpdateInput"]: {
	price?:number,
	shopifyId?:string,
	root?:PartialObjects["ProductVariantStoreUpdateRootEntityRelationInput"],
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
},
	["ProductVariantUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	shopifySku?:string,
	locales?:PartialObjects["ProductVariantLocaleUniqueWhere"],
	stores?:PartialObjects["ProductVariantStoreUniqueWhere"]
},
	["ProductVariantUpdateInput"]: {
	shopifySku?:string,
	product?:PartialObjects["ProductVariantUpdateProductEntityRelationInput"],
	locales?:PartialObjects["ProductVariantUpdateLocalesEntityRelationInput"][],
	stores?:PartialObjects["ProductVariantUpdateStoresEntityRelationInput"][],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
},
	["ProductVariantUpdateLocalesEntityRelationInput"]: {
	create?:PartialObjects["ProductVariantLocaleWithoutRootCreateInput"],
	update?:PartialObjects["ProductVariantUpdateLocalesRelationInput"],
	upsert?:PartialObjects["ProductVariantUpsertLocalesRelationInput"],
	connect?:PartialObjects["ProductVariantLocaleUniqueWhere"],
	disconnect?:PartialObjects["ProductVariantLocaleUniqueWhere"],
	delete?:PartialObjects["ProductVariantLocaleUniqueWhere"],
	alias?:string
},
	["ProductVariantUpdateLocalesRelationInput"]: {
	by?:PartialObjects["ProductVariantLocaleUniqueWhere"],
	data?:PartialObjects["ProductVariantLocaleWithoutRootUpdateInput"]
},
	["ProductVariantUpdateProductEntityRelationInput"]: {
	create?:PartialObjects["ProductWithoutProductVariantsCreateInput"],
	update?:PartialObjects["ProductWithoutProductVariantsUpdateInput"],
	upsert?:PartialObjects["ProductVariantUpsertProductRelationInput"],
	connect?:PartialObjects["ProductUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["ProductVariantUpdateResult"]: {
		__typename?: "ProductVariantUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ProductVariant"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ProductVariantUpdateStoresEntityRelationInput"]: {
	create?:PartialObjects["ProductVariantStoreWithoutRootCreateInput"],
	update?:PartialObjects["ProductVariantUpdateStoresRelationInput"],
	upsert?:PartialObjects["ProductVariantUpsertStoresRelationInput"],
	connect?:PartialObjects["ProductVariantStoreUniqueWhere"],
	disconnect?:PartialObjects["ProductVariantStoreUniqueWhere"],
	delete?:PartialObjects["ProductVariantStoreUniqueWhere"],
	alias?:string
},
	["ProductVariantUpdateStoresRelationInput"]: {
	by?:PartialObjects["ProductVariantStoreUniqueWhere"],
	data?:PartialObjects["ProductVariantStoreWithoutRootUpdateInput"]
},
	["ProductVariantUpsertLocalesRelationInput"]: {
	by?:PartialObjects["ProductVariantLocaleUniqueWhere"],
	update?:PartialObjects["ProductVariantLocaleWithoutRootUpdateInput"],
	create?:PartialObjects["ProductVariantLocaleWithoutRootCreateInput"]
},
	["ProductVariantUpsertProductRelationInput"]: {
	update?:PartialObjects["ProductWithoutProductVariantsUpdateInput"],
	create?:PartialObjects["ProductWithoutProductVariantsCreateInput"]
},
	["ProductVariantUpsertStoresRelationInput"]: {
	by?:PartialObjects["ProductVariantStoreUniqueWhere"],
	update?:PartialObjects["ProductVariantStoreWithoutRootUpdateInput"],
	create?:PartialObjects["ProductVariantStoreWithoutRootCreateInput"]
},
	["ProductVariantWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	shopifySku?:PartialObjects["StringCondition"],
	product?:PartialObjects["ProductWhere"],
	locales?:PartialObjects["ProductVariantLocaleWhere"],
	stores?:PartialObjects["ProductVariantStoreWhere"],
	title?:PartialObjects["StringCondition"],
	heurekaId?:PartialObjects["StringCondition"],
	and?:PartialObjects["ProductVariantWhere"][],
	or?:PartialObjects["ProductVariantWhere"][],
	not?:PartialObjects["ProductVariantWhere"]
},
	["ProductVariantWithoutLocalesCreateInput"]: {
	shopifySku?:string,
	product?:PartialObjects["ProductVariantCreateProductEntityRelationInput"],
	stores?:PartialObjects["ProductVariantCreateStoresEntityRelationInput"][],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
},
	["ProductVariantWithoutLocalesUpdateInput"]: {
	shopifySku?:string,
	product?:PartialObjects["ProductVariantUpdateProductEntityRelationInput"],
	stores?:PartialObjects["ProductVariantUpdateStoresEntityRelationInput"][],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
},
	["ProductVariantWithoutProductCreateInput"]: {
	shopifySku?:string,
	locales?:PartialObjects["ProductVariantCreateLocalesEntityRelationInput"][],
	stores?:PartialObjects["ProductVariantCreateStoresEntityRelationInput"][],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
},
	["ProductVariantWithoutProductUpdateInput"]: {
	shopifySku?:string,
	locales?:PartialObjects["ProductVariantUpdateLocalesEntityRelationInput"][],
	stores?:PartialObjects["ProductVariantUpdateStoresEntityRelationInput"][],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
},
	["ProductVariantWithoutStoresCreateInput"]: {
	shopifySku?:string,
	product?:PartialObjects["ProductVariantCreateProductEntityRelationInput"],
	locales?:PartialObjects["ProductVariantCreateLocalesEntityRelationInput"][],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
},
	["ProductVariantWithoutStoresUpdateInput"]: {
	shopifySku?:string,
	product?:PartialObjects["ProductVariantUpdateProductEntityRelationInput"],
	locales?:PartialObjects["ProductVariantUpdateLocalesEntityRelationInput"][],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
},
	["ProductWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	title?:PartialObjects["StringCondition"],
	shopifyHandle?:PartialObjects["StringCondition"],
	productType?:PartialObjects["StringCondition"],
	stores?:PartialObjects["ProductStoreWhere"],
	productVariants?:PartialObjects["ProductVariantWhere"],
	locales?:PartialObjects["ProductLocaleWhere"],
	and?:PartialObjects["ProductWhere"][],
	or?:PartialObjects["ProductWhere"][],
	not?:PartialObjects["ProductWhere"]
},
	["ProductWithoutLocalesCreateInput"]: {
	title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:PartialObjects["ProductCreateStoresEntityRelationInput"][],
	productVariants?:PartialObjects["ProductCreateProductVariantsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["ProductWithoutLocalesUpdateInput"]: {
	title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:PartialObjects["ProductUpdateStoresEntityRelationInput"][],
	productVariants?:PartialObjects["ProductUpdateProductVariantsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["ProductWithoutProductVariantsCreateInput"]: {
	title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:PartialObjects["ProductCreateStoresEntityRelationInput"][],
	locales?:PartialObjects["ProductCreateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["ProductWithoutProductVariantsUpdateInput"]: {
	title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:PartialObjects["ProductUpdateStoresEntityRelationInput"][],
	locales?:PartialObjects["ProductUpdateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["ProductWithoutStoresCreateInput"]: {
	title?:string,
	shopifyHandle?:string,
	productType?:string,
	productVariants?:PartialObjects["ProductCreateProductVariantsEntityRelationInput"][],
	locales?:PartialObjects["ProductCreateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["ProductWithoutStoresUpdateInput"]: {
	title?:string,
	shopifyHandle?:string,
	productType?:string,
	productVariants?:PartialObjects["ProductUpdateProductVariantsEntityRelationInput"][],
	locales?:PartialObjects["ProductUpdateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["Query"]: {
		__typename?: "Query";
			getAbandonedCheckoutUnsubscription?:PartialObjects["AbandonedCheckoutUnsubscription"],
			listAbandonedCheckoutUnsubscription?:PartialObjects["AbandonedCheckoutUnsubscription"][],
			paginateAbandonedCheckoutUnsubscription?:PartialObjects["AbandonedCheckoutUnsubscriptionConnection"],
			validateCreateAbandonedCheckoutUnsubscription?:PartialObjects["_ValidationResult"],
			validateUpdateAbandonedCheckoutUnsubscription?:PartialObjects["_ValidationResult"],
			getAbandonedCheckoutNotification?:PartialObjects["AbandonedCheckoutNotification"],
			listAbandonedCheckoutNotification?:PartialObjects["AbandonedCheckoutNotification"][],
			paginateAbandonedCheckoutNotification?:PartialObjects["AbandonedCheckoutNotificationConnection"],
			validateCreateAbandonedCheckoutNotification?:PartialObjects["_ValidationResult"],
			validateUpdateAbandonedCheckoutNotification?:PartialObjects["_ValidationResult"],
			getShopifyLineItem?:PartialObjects["ShopifyLineItem"],
			listShopifyLineItem?:PartialObjects["ShopifyLineItem"][],
			paginateShopifyLineItem?:PartialObjects["ShopifyLineItemConnection"],
			validateCreateShopifyLineItem?:PartialObjects["_ValidationResult"],
			validateUpdateShopifyLineItem?:PartialObjects["_ValidationResult"],
			getShopifyOrder?:PartialObjects["ShopifyOrder"],
			listShopifyOrder?:PartialObjects["ShopifyOrder"][],
			paginateShopifyOrder?:PartialObjects["ShopifyOrderConnection"],
			validateCreateShopifyOrder?:PartialObjects["_ValidationResult"],
			validateUpdateShopifyOrder?:PartialObjects["_ValidationResult"],
			getTranslationValue?:PartialObjects["TranslationValue"],
			listTranslationValue?:PartialObjects["TranslationValue"][],
			paginateTranslationValue?:PartialObjects["TranslationValueConnection"],
			validateCreateTranslationValue?:PartialObjects["_ValidationResult"],
			validateUpdateTranslationValue?:PartialObjects["_ValidationResult"],
			getTranslationKey?:PartialObjects["TranslationKey"],
			listTranslationKey?:PartialObjects["TranslationKey"][],
			paginateTranslationKey?:PartialObjects["TranslationKeyConnection"],
			validateCreateTranslationKey?:PartialObjects["_ValidationResult"],
			validateUpdateTranslationKey?:PartialObjects["_ValidationResult"],
			getTranslationCatalogue?:PartialObjects["TranslationCatalogue"],
			listTranslationCatalogue?:PartialObjects["TranslationCatalogue"][],
			paginateTranslationCatalogue?:PartialObjects["TranslationCatalogueConnection"],
			validateCreateTranslationCatalogue?:PartialObjects["_ValidationResult"],
			validateUpdateTranslationCatalogue?:PartialObjects["_ValidationResult"],
			getTranslationDomain?:PartialObjects["TranslationDomain"],
			listTranslationDomain?:PartialObjects["TranslationDomain"][],
			paginateTranslationDomain?:PartialObjects["TranslationDomainConnection"],
			validateCreateTranslationDomain?:PartialObjects["_ValidationResult"],
			validateUpdateTranslationDomain?:PartialObjects["_ValidationResult"],
			getProductLocale?:PartialObjects["ProductLocale"],
			listProductLocale?:PartialObjects["ProductLocale"][],
			paginateProductLocale?:PartialObjects["ProductLocaleConnection"],
			validateCreateProductLocale?:PartialObjects["_ValidationResult"],
			validateUpdateProductLocale?:PartialObjects["_ValidationResult"],
			getMedia?:PartialObjects["Media"],
			listMedia?:PartialObjects["Media"][],
			paginateMedia?:PartialObjects["MediaConnection"],
			validateCreateMedia?:PartialObjects["_ValidationResult"],
			validateUpdateMedia?:PartialObjects["_ValidationResult"],
			getIconListBlockItem?:PartialObjects["IconListBlockItem"],
			listIconListBlockItem?:PartialObjects["IconListBlockItem"][],
			paginateIconListBlockItem?:PartialObjects["IconListBlockItemConnection"],
			validateCreateIconListBlockItem?:PartialObjects["_ValidationResult"],
			validateUpdateIconListBlockItem?:PartialObjects["_ValidationResult"],
			getIconListBlock?:PartialObjects["IconListBlock"],
			listIconListBlock?:PartialObjects["IconListBlock"][],
			paginateIconListBlock?:PartialObjects["IconListBlockConnection"],
			validateCreateIconListBlock?:PartialObjects["_ValidationResult"],
			validateUpdateIconListBlock?:PartialObjects["_ValidationResult"],
			getFeatureListItem?:PartialObjects["FeatureListItem"],
			listFeatureListItem?:PartialObjects["FeatureListItem"][],
			paginateFeatureListItem?:PartialObjects["FeatureListItemConnection"],
			validateCreateFeatureListItem?:PartialObjects["_ValidationResult"],
			validateUpdateFeatureListItem?:PartialObjects["_ValidationResult"],
			getFeatureList?:PartialObjects["FeatureList"],
			listFeatureList?:PartialObjects["FeatureList"][],
			paginateFeatureList?:PartialObjects["FeatureListConnection"],
			validateCreateFeatureList?:PartialObjects["_ValidationResult"],
			validateUpdateFeatureList?:PartialObjects["_ValidationResult"],
			getFaqItem?:PartialObjects["FaqItem"],
			listFaqItem?:PartialObjects["FaqItem"][],
			paginateFaqItem?:PartialObjects["FaqItemConnection"],
			validateCreateFaqItem?:PartialObjects["_ValidationResult"],
			validateUpdateFaqItem?:PartialObjects["_ValidationResult"],
			getFaq?:PartialObjects["Faq"],
			listFaq?:PartialObjects["Faq"][],
			paginateFaq?:PartialObjects["FaqConnection"],
			validateCreateFaq?:PartialObjects["_ValidationResult"],
			validateUpdateFaq?:PartialObjects["_ValidationResult"],
			getContentBlock?:PartialObjects["ContentBlock"],
			listContentBlock?:PartialObjects["ContentBlock"][],
			paginateContentBlock?:PartialObjects["ContentBlockConnection"],
			validateCreateContentBlock?:PartialObjects["_ValidationResult"],
			validateUpdateContentBlock?:PartialObjects["_ValidationResult"],
			getContent?:PartialObjects["Content"],
			listContent?:PartialObjects["Content"][],
			paginateContent?:PartialObjects["ContentConnection"],
			validateCreateContent?:PartialObjects["_ValidationResult"],
			validateUpdateContent?:PartialObjects["_ValidationResult"],
			getLink?:PartialObjects["Link"],
			listLink?:PartialObjects["Link"][],
			paginateLink?:PartialObjects["LinkConnection"],
			validateCreateLink?:PartialObjects["_ValidationResult"],
			validateUpdateLink?:PartialObjects["_ValidationResult"],
			getReviewLocale?:PartialObjects["ReviewLocale"],
			listReviewLocale?:PartialObjects["ReviewLocale"][],
			paginateReviewLocale?:PartialObjects["ReviewLocaleConnection"],
			validateCreateReviewLocale?:PartialObjects["_ValidationResult"],
			validateUpdateReviewLocale?:PartialObjects["_ValidationResult"],
			getReview?:PartialObjects["Review"],
			listReview?:PartialObjects["Review"][],
			paginateReview?:PartialObjects["ReviewConnection"],
			validateCreateReview?:PartialObjects["_ValidationResult"],
			validateUpdateReview?:PartialObjects["_ValidationResult"],
			getGalleryItem?:PartialObjects["GalleryItem"],
			listGalleryItem?:PartialObjects["GalleryItem"][],
			paginateGalleryItem?:PartialObjects["GalleryItemConnection"],
			validateCreateGalleryItem?:PartialObjects["_ValidationResult"],
			validateUpdateGalleryItem?:PartialObjects["_ValidationResult"],
			getGallery?:PartialObjects["Gallery"],
			listGallery?:PartialObjects["Gallery"][],
			paginateGallery?:PartialObjects["GalleryConnection"],
			validateCreateGallery?:PartialObjects["_ValidationResult"],
			validateUpdateGallery?:PartialObjects["_ValidationResult"],
			getProductVariantStore?:PartialObjects["ProductVariantStore"],
			listProductVariantStore?:PartialObjects["ProductVariantStore"][],
			paginateProductVariantStore?:PartialObjects["ProductVariantStoreConnection"],
			validateCreateProductVariantStore?:PartialObjects["_ValidationResult"],
			validateUpdateProductVariantStore?:PartialObjects["_ValidationResult"],
			getProductStore?:PartialObjects["ProductStore"],
			listProductStore?:PartialObjects["ProductStore"][],
			paginateProductStore?:PartialObjects["ProductStoreConnection"],
			validateCreateProductStore?:PartialObjects["_ValidationResult"],
			validateUpdateProductStore?:PartialObjects["_ValidationResult"],
			getIconListItem?:PartialObjects["IconListItem"],
			listIconListItem?:PartialObjects["IconListItem"][],
			paginateIconListItem?:PartialObjects["IconListItemConnection"],
			validateCreateIconListItem?:PartialObjects["_ValidationResult"],
			validateUpdateIconListItem?:PartialObjects["_ValidationResult"],
			getIconList?:PartialObjects["IconList"],
			listIconList?:PartialObjects["IconList"][],
			paginateIconList?:PartialObjects["IconListConnection"],
			validateCreateIconList?:PartialObjects["_ValidationResult"],
			validateUpdateIconList?:PartialObjects["_ValidationResult"],
			getIconGroupListItem?:PartialObjects["IconGroupListItem"],
			listIconGroupListItem?:PartialObjects["IconGroupListItem"][],
			paginateIconGroupListItem?:PartialObjects["IconGroupListItemConnection"],
			validateCreateIconGroupListItem?:PartialObjects["_ValidationResult"],
			validateUpdateIconGroupListItem?:PartialObjects["_ValidationResult"],
			getIconGroupList?:PartialObjects["IconGroupList"],
			listIconGroupList?:PartialObjects["IconGroupList"][],
			paginateIconGroupList?:PartialObjects["IconGroupListConnection"],
			validateCreateIconGroupList?:PartialObjects["_ValidationResult"],
			validateUpdateIconGroupList?:PartialObjects["_ValidationResult"],
			getProductVariantLocale?:PartialObjects["ProductVariantLocale"],
			listProductVariantLocale?:PartialObjects["ProductVariantLocale"][],
			paginateProductVariantLocale?:PartialObjects["ProductVariantLocaleConnection"],
			validateCreateProductVariantLocale?:PartialObjects["_ValidationResult"],
			validateUpdateProductVariantLocale?:PartialObjects["_ValidationResult"],
			getProductVariant?:PartialObjects["ProductVariant"],
			listProductVariant?:PartialObjects["ProductVariant"][],
			paginateProductVariant?:PartialObjects["ProductVariantConnection"],
			validateCreateProductVariant?:PartialObjects["_ValidationResult"],
			validateUpdateProductVariant?:PartialObjects["_ValidationResult"],
			getProduct?:PartialObjects["Product"],
			listProduct?:PartialObjects["Product"][],
			paginateProduct?:PartialObjects["ProductConnection"],
			validateCreateProduct?:PartialObjects["_ValidationResult"],
			validateUpdateProduct?:PartialObjects["_ValidationResult"],
			getImageLocale?:PartialObjects["ImageLocale"],
			listImageLocale?:PartialObjects["ImageLocale"][],
			paginateImageLocale?:PartialObjects["ImageLocaleConnection"],
			validateCreateImageLocale?:PartialObjects["_ValidationResult"],
			validateUpdateImageLocale?:PartialObjects["_ValidationResult"],
			getStore?:PartialObjects["Store"],
			listStore?:PartialObjects["Store"][],
			paginateStore?:PartialObjects["StoreConnection"],
			validateCreateStore?:PartialObjects["_ValidationResult"],
			validateUpdateStore?:PartialObjects["_ValidationResult"],
			getAdminNote?:PartialObjects["AdminNote"],
			listAdminNote?:PartialObjects["AdminNote"][],
			paginateAdminNote?:PartialObjects["AdminNoteConnection"],
			validateCreateAdminNote?:PartialObjects["_ValidationResult"],
			validateUpdateAdminNote?:PartialObjects["_ValidationResult"],
			getPageLocale?:PartialObjects["PageLocale"],
			listPageLocale?:PartialObjects["PageLocale"][],
			paginatePageLocale?:PartialObjects["PageLocaleConnection"],
			validateCreatePageLocale?:PartialObjects["_ValidationResult"],
			validateUpdatePageLocale?:PartialObjects["_ValidationResult"],
			getPage?:PartialObjects["Page"],
			listPage?:PartialObjects["Page"][],
			paginatePage?:PartialObjects["PageConnection"],
			validateCreatePage?:PartialObjects["_ValidationResult"],
			validateUpdatePage?:PartialObjects["_ValidationResult"],
			getImageXImageList?:PartialObjects["ImageXImageList"],
			listImageXImageList?:PartialObjects["ImageXImageList"][],
			paginateImageXImageList?:PartialObjects["ImageXImageListConnection"],
			validateCreateImageXImageList?:PartialObjects["_ValidationResult"],
			validateUpdateImageXImageList?:PartialObjects["_ValidationResult"],
			getIconGroupLocale?:PartialObjects["IconGroupLocale"],
			listIconGroupLocale?:PartialObjects["IconGroupLocale"][],
			paginateIconGroupLocale?:PartialObjects["IconGroupLocaleConnection"],
			validateCreateIconGroupLocale?:PartialObjects["_ValidationResult"],
			validateUpdateIconGroupLocale?:PartialObjects["_ValidationResult"],
			getIconLocale?:PartialObjects["IconLocale"],
			listIconLocale?:PartialObjects["IconLocale"][],
			paginateIconLocale?:PartialObjects["IconLocaleConnection"],
			validateCreateIconLocale?:PartialObjects["_ValidationResult"],
			validateUpdateIconLocale?:PartialObjects["_ValidationResult"],
			getIconGroup?:PartialObjects["IconGroup"],
			listIconGroup?:PartialObjects["IconGroup"][],
			paginateIconGroup?:PartialObjects["IconGroupConnection"],
			validateCreateIconGroup?:PartialObjects["_ValidationResult"],
			validateUpdateIconGroup?:PartialObjects["_ValidationResult"],
			getIcon?:PartialObjects["Icon"],
			listIcon?:PartialObjects["Icon"][],
			paginateIcon?:PartialObjects["IconConnection"],
			validateCreateIcon?:PartialObjects["_ValidationResult"],
			validateUpdateIcon?:PartialObjects["_ValidationResult"],
			getLocale?:PartialObjects["Locale"],
			listLocale?:PartialObjects["Locale"][],
			paginateLocale?:PartialObjects["LocaleConnection"],
			validateCreateLocale?:PartialObjects["_ValidationResult"],
			validateUpdateLocale?:PartialObjects["_ValidationResult"],
			getSeoLocale?:PartialObjects["SeoLocale"],
			listSeoLocale?:PartialObjects["SeoLocale"][],
			paginateSeoLocale?:PartialObjects["SeoLocaleConnection"],
			validateCreateSeoLocale?:PartialObjects["_ValidationResult"],
			validateUpdateSeoLocale?:PartialObjects["_ValidationResult"],
			getSeo?:PartialObjects["Seo"],
			listSeo?:PartialObjects["Seo"][],
			paginateSeo?:PartialObjects["SeoConnection"],
			validateCreateSeo?:PartialObjects["_ValidationResult"],
			validateUpdateSeo?:PartialObjects["_ValidationResult"],
			getLinkable?:PartialObjects["Linkable"],
			listLinkable?:PartialObjects["Linkable"][],
			paginateLinkable?:PartialObjects["LinkableConnection"],
			validateCreateLinkable?:PartialObjects["_ValidationResult"],
			validateUpdateLinkable?:PartialObjects["_ValidationResult"],
			getImageList?:PartialObjects["ImageList"],
			listImageList?:PartialObjects["ImageList"][],
			paginateImageList?:PartialObjects["ImageListConnection"],
			validateCreateImageList?:PartialObjects["_ValidationResult"],
			validateUpdateImageList?:PartialObjects["_ValidationResult"],
			getImage?:PartialObjects["Image"],
			listImage?:PartialObjects["Image"][],
			paginateImage?:PartialObjects["ImageConnection"],
			validateCreateImage?:PartialObjects["_ValidationResult"],
			validateUpdateImage?:PartialObjects["_ValidationResult"],
			transaction?:PartialObjects["QueryTransaction"],
			_info?:PartialObjects["Info"],
			schema?:PartialObjects["_Schema"],
			s3DummyQuery?:string
	},
	["QueryTransaction"]: {
		__typename?: "QueryTransaction";
			getAbandonedCheckoutUnsubscription?:PartialObjects["AbandonedCheckoutUnsubscription"],
			listAbandonedCheckoutUnsubscription?:PartialObjects["AbandonedCheckoutUnsubscription"][],
			paginateAbandonedCheckoutUnsubscription?:PartialObjects["AbandonedCheckoutUnsubscriptionConnection"],
			validateCreateAbandonedCheckoutUnsubscription?:PartialObjects["_ValidationResult"],
			validateUpdateAbandonedCheckoutUnsubscription?:PartialObjects["_ValidationResult"],
			getAbandonedCheckoutNotification?:PartialObjects["AbandonedCheckoutNotification"],
			listAbandonedCheckoutNotification?:PartialObjects["AbandonedCheckoutNotification"][],
			paginateAbandonedCheckoutNotification?:PartialObjects["AbandonedCheckoutNotificationConnection"],
			validateCreateAbandonedCheckoutNotification?:PartialObjects["_ValidationResult"],
			validateUpdateAbandonedCheckoutNotification?:PartialObjects["_ValidationResult"],
			getShopifyLineItem?:PartialObjects["ShopifyLineItem"],
			listShopifyLineItem?:PartialObjects["ShopifyLineItem"][],
			paginateShopifyLineItem?:PartialObjects["ShopifyLineItemConnection"],
			validateCreateShopifyLineItem?:PartialObjects["_ValidationResult"],
			validateUpdateShopifyLineItem?:PartialObjects["_ValidationResult"],
			getShopifyOrder?:PartialObjects["ShopifyOrder"],
			listShopifyOrder?:PartialObjects["ShopifyOrder"][],
			paginateShopifyOrder?:PartialObjects["ShopifyOrderConnection"],
			validateCreateShopifyOrder?:PartialObjects["_ValidationResult"],
			validateUpdateShopifyOrder?:PartialObjects["_ValidationResult"],
			getTranslationValue?:PartialObjects["TranslationValue"],
			listTranslationValue?:PartialObjects["TranslationValue"][],
			paginateTranslationValue?:PartialObjects["TranslationValueConnection"],
			validateCreateTranslationValue?:PartialObjects["_ValidationResult"],
			validateUpdateTranslationValue?:PartialObjects["_ValidationResult"],
			getTranslationKey?:PartialObjects["TranslationKey"],
			listTranslationKey?:PartialObjects["TranslationKey"][],
			paginateTranslationKey?:PartialObjects["TranslationKeyConnection"],
			validateCreateTranslationKey?:PartialObjects["_ValidationResult"],
			validateUpdateTranslationKey?:PartialObjects["_ValidationResult"],
			getTranslationCatalogue?:PartialObjects["TranslationCatalogue"],
			listTranslationCatalogue?:PartialObjects["TranslationCatalogue"][],
			paginateTranslationCatalogue?:PartialObjects["TranslationCatalogueConnection"],
			validateCreateTranslationCatalogue?:PartialObjects["_ValidationResult"],
			validateUpdateTranslationCatalogue?:PartialObjects["_ValidationResult"],
			getTranslationDomain?:PartialObjects["TranslationDomain"],
			listTranslationDomain?:PartialObjects["TranslationDomain"][],
			paginateTranslationDomain?:PartialObjects["TranslationDomainConnection"],
			validateCreateTranslationDomain?:PartialObjects["_ValidationResult"],
			validateUpdateTranslationDomain?:PartialObjects["_ValidationResult"],
			getProductLocale?:PartialObjects["ProductLocale"],
			listProductLocale?:PartialObjects["ProductLocale"][],
			paginateProductLocale?:PartialObjects["ProductLocaleConnection"],
			validateCreateProductLocale?:PartialObjects["_ValidationResult"],
			validateUpdateProductLocale?:PartialObjects["_ValidationResult"],
			getMedia?:PartialObjects["Media"],
			listMedia?:PartialObjects["Media"][],
			paginateMedia?:PartialObjects["MediaConnection"],
			validateCreateMedia?:PartialObjects["_ValidationResult"],
			validateUpdateMedia?:PartialObjects["_ValidationResult"],
			getIconListBlockItem?:PartialObjects["IconListBlockItem"],
			listIconListBlockItem?:PartialObjects["IconListBlockItem"][],
			paginateIconListBlockItem?:PartialObjects["IconListBlockItemConnection"],
			validateCreateIconListBlockItem?:PartialObjects["_ValidationResult"],
			validateUpdateIconListBlockItem?:PartialObjects["_ValidationResult"],
			getIconListBlock?:PartialObjects["IconListBlock"],
			listIconListBlock?:PartialObjects["IconListBlock"][],
			paginateIconListBlock?:PartialObjects["IconListBlockConnection"],
			validateCreateIconListBlock?:PartialObjects["_ValidationResult"],
			validateUpdateIconListBlock?:PartialObjects["_ValidationResult"],
			getFeatureListItem?:PartialObjects["FeatureListItem"],
			listFeatureListItem?:PartialObjects["FeatureListItem"][],
			paginateFeatureListItem?:PartialObjects["FeatureListItemConnection"],
			validateCreateFeatureListItem?:PartialObjects["_ValidationResult"],
			validateUpdateFeatureListItem?:PartialObjects["_ValidationResult"],
			getFeatureList?:PartialObjects["FeatureList"],
			listFeatureList?:PartialObjects["FeatureList"][],
			paginateFeatureList?:PartialObjects["FeatureListConnection"],
			validateCreateFeatureList?:PartialObjects["_ValidationResult"],
			validateUpdateFeatureList?:PartialObjects["_ValidationResult"],
			getFaqItem?:PartialObjects["FaqItem"],
			listFaqItem?:PartialObjects["FaqItem"][],
			paginateFaqItem?:PartialObjects["FaqItemConnection"],
			validateCreateFaqItem?:PartialObjects["_ValidationResult"],
			validateUpdateFaqItem?:PartialObjects["_ValidationResult"],
			getFaq?:PartialObjects["Faq"],
			listFaq?:PartialObjects["Faq"][],
			paginateFaq?:PartialObjects["FaqConnection"],
			validateCreateFaq?:PartialObjects["_ValidationResult"],
			validateUpdateFaq?:PartialObjects["_ValidationResult"],
			getContentBlock?:PartialObjects["ContentBlock"],
			listContentBlock?:PartialObjects["ContentBlock"][],
			paginateContentBlock?:PartialObjects["ContentBlockConnection"],
			validateCreateContentBlock?:PartialObjects["_ValidationResult"],
			validateUpdateContentBlock?:PartialObjects["_ValidationResult"],
			getContent?:PartialObjects["Content"],
			listContent?:PartialObjects["Content"][],
			paginateContent?:PartialObjects["ContentConnection"],
			validateCreateContent?:PartialObjects["_ValidationResult"],
			validateUpdateContent?:PartialObjects["_ValidationResult"],
			getLink?:PartialObjects["Link"],
			listLink?:PartialObjects["Link"][],
			paginateLink?:PartialObjects["LinkConnection"],
			validateCreateLink?:PartialObjects["_ValidationResult"],
			validateUpdateLink?:PartialObjects["_ValidationResult"],
			getReviewLocale?:PartialObjects["ReviewLocale"],
			listReviewLocale?:PartialObjects["ReviewLocale"][],
			paginateReviewLocale?:PartialObjects["ReviewLocaleConnection"],
			validateCreateReviewLocale?:PartialObjects["_ValidationResult"],
			validateUpdateReviewLocale?:PartialObjects["_ValidationResult"],
			getReview?:PartialObjects["Review"],
			listReview?:PartialObjects["Review"][],
			paginateReview?:PartialObjects["ReviewConnection"],
			validateCreateReview?:PartialObjects["_ValidationResult"],
			validateUpdateReview?:PartialObjects["_ValidationResult"],
			getGalleryItem?:PartialObjects["GalleryItem"],
			listGalleryItem?:PartialObjects["GalleryItem"][],
			paginateGalleryItem?:PartialObjects["GalleryItemConnection"],
			validateCreateGalleryItem?:PartialObjects["_ValidationResult"],
			validateUpdateGalleryItem?:PartialObjects["_ValidationResult"],
			getGallery?:PartialObjects["Gallery"],
			listGallery?:PartialObjects["Gallery"][],
			paginateGallery?:PartialObjects["GalleryConnection"],
			validateCreateGallery?:PartialObjects["_ValidationResult"],
			validateUpdateGallery?:PartialObjects["_ValidationResult"],
			getProductVariantStore?:PartialObjects["ProductVariantStore"],
			listProductVariantStore?:PartialObjects["ProductVariantStore"][],
			paginateProductVariantStore?:PartialObjects["ProductVariantStoreConnection"],
			validateCreateProductVariantStore?:PartialObjects["_ValidationResult"],
			validateUpdateProductVariantStore?:PartialObjects["_ValidationResult"],
			getProductStore?:PartialObjects["ProductStore"],
			listProductStore?:PartialObjects["ProductStore"][],
			paginateProductStore?:PartialObjects["ProductStoreConnection"],
			validateCreateProductStore?:PartialObjects["_ValidationResult"],
			validateUpdateProductStore?:PartialObjects["_ValidationResult"],
			getIconListItem?:PartialObjects["IconListItem"],
			listIconListItem?:PartialObjects["IconListItem"][],
			paginateIconListItem?:PartialObjects["IconListItemConnection"],
			validateCreateIconListItem?:PartialObjects["_ValidationResult"],
			validateUpdateIconListItem?:PartialObjects["_ValidationResult"],
			getIconList?:PartialObjects["IconList"],
			listIconList?:PartialObjects["IconList"][],
			paginateIconList?:PartialObjects["IconListConnection"],
			validateCreateIconList?:PartialObjects["_ValidationResult"],
			validateUpdateIconList?:PartialObjects["_ValidationResult"],
			getIconGroupListItem?:PartialObjects["IconGroupListItem"],
			listIconGroupListItem?:PartialObjects["IconGroupListItem"][],
			paginateIconGroupListItem?:PartialObjects["IconGroupListItemConnection"],
			validateCreateIconGroupListItem?:PartialObjects["_ValidationResult"],
			validateUpdateIconGroupListItem?:PartialObjects["_ValidationResult"],
			getIconGroupList?:PartialObjects["IconGroupList"],
			listIconGroupList?:PartialObjects["IconGroupList"][],
			paginateIconGroupList?:PartialObjects["IconGroupListConnection"],
			validateCreateIconGroupList?:PartialObjects["_ValidationResult"],
			validateUpdateIconGroupList?:PartialObjects["_ValidationResult"],
			getProductVariantLocale?:PartialObjects["ProductVariantLocale"],
			listProductVariantLocale?:PartialObjects["ProductVariantLocale"][],
			paginateProductVariantLocale?:PartialObjects["ProductVariantLocaleConnection"],
			validateCreateProductVariantLocale?:PartialObjects["_ValidationResult"],
			validateUpdateProductVariantLocale?:PartialObjects["_ValidationResult"],
			getProductVariant?:PartialObjects["ProductVariant"],
			listProductVariant?:PartialObjects["ProductVariant"][],
			paginateProductVariant?:PartialObjects["ProductVariantConnection"],
			validateCreateProductVariant?:PartialObjects["_ValidationResult"],
			validateUpdateProductVariant?:PartialObjects["_ValidationResult"],
			getProduct?:PartialObjects["Product"],
			listProduct?:PartialObjects["Product"][],
			paginateProduct?:PartialObjects["ProductConnection"],
			validateCreateProduct?:PartialObjects["_ValidationResult"],
			validateUpdateProduct?:PartialObjects["_ValidationResult"],
			getImageLocale?:PartialObjects["ImageLocale"],
			listImageLocale?:PartialObjects["ImageLocale"][],
			paginateImageLocale?:PartialObjects["ImageLocaleConnection"],
			validateCreateImageLocale?:PartialObjects["_ValidationResult"],
			validateUpdateImageLocale?:PartialObjects["_ValidationResult"],
			getStore?:PartialObjects["Store"],
			listStore?:PartialObjects["Store"][],
			paginateStore?:PartialObjects["StoreConnection"],
			validateCreateStore?:PartialObjects["_ValidationResult"],
			validateUpdateStore?:PartialObjects["_ValidationResult"],
			getAdminNote?:PartialObjects["AdminNote"],
			listAdminNote?:PartialObjects["AdminNote"][],
			paginateAdminNote?:PartialObjects["AdminNoteConnection"],
			validateCreateAdminNote?:PartialObjects["_ValidationResult"],
			validateUpdateAdminNote?:PartialObjects["_ValidationResult"],
			getPageLocale?:PartialObjects["PageLocale"],
			listPageLocale?:PartialObjects["PageLocale"][],
			paginatePageLocale?:PartialObjects["PageLocaleConnection"],
			validateCreatePageLocale?:PartialObjects["_ValidationResult"],
			validateUpdatePageLocale?:PartialObjects["_ValidationResult"],
			getPage?:PartialObjects["Page"],
			listPage?:PartialObjects["Page"][],
			paginatePage?:PartialObjects["PageConnection"],
			validateCreatePage?:PartialObjects["_ValidationResult"],
			validateUpdatePage?:PartialObjects["_ValidationResult"],
			getImageXImageList?:PartialObjects["ImageXImageList"],
			listImageXImageList?:PartialObjects["ImageXImageList"][],
			paginateImageXImageList?:PartialObjects["ImageXImageListConnection"],
			validateCreateImageXImageList?:PartialObjects["_ValidationResult"],
			validateUpdateImageXImageList?:PartialObjects["_ValidationResult"],
			getIconGroupLocale?:PartialObjects["IconGroupLocale"],
			listIconGroupLocale?:PartialObjects["IconGroupLocale"][],
			paginateIconGroupLocale?:PartialObjects["IconGroupLocaleConnection"],
			validateCreateIconGroupLocale?:PartialObjects["_ValidationResult"],
			validateUpdateIconGroupLocale?:PartialObjects["_ValidationResult"],
			getIconLocale?:PartialObjects["IconLocale"],
			listIconLocale?:PartialObjects["IconLocale"][],
			paginateIconLocale?:PartialObjects["IconLocaleConnection"],
			validateCreateIconLocale?:PartialObjects["_ValidationResult"],
			validateUpdateIconLocale?:PartialObjects["_ValidationResult"],
			getIconGroup?:PartialObjects["IconGroup"],
			listIconGroup?:PartialObjects["IconGroup"][],
			paginateIconGroup?:PartialObjects["IconGroupConnection"],
			validateCreateIconGroup?:PartialObjects["_ValidationResult"],
			validateUpdateIconGroup?:PartialObjects["_ValidationResult"],
			getIcon?:PartialObjects["Icon"],
			listIcon?:PartialObjects["Icon"][],
			paginateIcon?:PartialObjects["IconConnection"],
			validateCreateIcon?:PartialObjects["_ValidationResult"],
			validateUpdateIcon?:PartialObjects["_ValidationResult"],
			getLocale?:PartialObjects["Locale"],
			listLocale?:PartialObjects["Locale"][],
			paginateLocale?:PartialObjects["LocaleConnection"],
			validateCreateLocale?:PartialObjects["_ValidationResult"],
			validateUpdateLocale?:PartialObjects["_ValidationResult"],
			getSeoLocale?:PartialObjects["SeoLocale"],
			listSeoLocale?:PartialObjects["SeoLocale"][],
			paginateSeoLocale?:PartialObjects["SeoLocaleConnection"],
			validateCreateSeoLocale?:PartialObjects["_ValidationResult"],
			validateUpdateSeoLocale?:PartialObjects["_ValidationResult"],
			getSeo?:PartialObjects["Seo"],
			listSeo?:PartialObjects["Seo"][],
			paginateSeo?:PartialObjects["SeoConnection"],
			validateCreateSeo?:PartialObjects["_ValidationResult"],
			validateUpdateSeo?:PartialObjects["_ValidationResult"],
			getLinkable?:PartialObjects["Linkable"],
			listLinkable?:PartialObjects["Linkable"][],
			paginateLinkable?:PartialObjects["LinkableConnection"],
			validateCreateLinkable?:PartialObjects["_ValidationResult"],
			validateUpdateLinkable?:PartialObjects["_ValidationResult"],
			getImageList?:PartialObjects["ImageList"],
			listImageList?:PartialObjects["ImageList"][],
			paginateImageList?:PartialObjects["ImageListConnection"],
			validateCreateImageList?:PartialObjects["_ValidationResult"],
			validateUpdateImageList?:PartialObjects["_ValidationResult"],
			getImage?:PartialObjects["Image"],
			listImage?:PartialObjects["Image"][],
			paginateImage?:PartialObjects["ImageConnection"],
			validateCreateImage?:PartialObjects["_ValidationResult"],
			validateUpdateImage?:PartialObjects["_ValidationResult"]
	},
	["Review"]: {
		__typename?: "Review";
			_meta?:PartialObjects["ReviewMeta"],
			id?:PartialObjects["UUID"],
			username?:string,
			createdAt?:PartialObjects["DateTime"],
			locales?:PartialObjects["ReviewLocale"][],
			localesByLocale?:PartialObjects["ReviewLocale"]
	},
	["ReviewConnection"]: {
		__typename?: "ReviewConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["ReviewEdge"][]
	},
	["ReviewCreateInput"]: {
	username?:string,
	createdAt?:PartialObjects["DateTime"],
	locales?:PartialObjects["ReviewCreateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["ReviewCreateLocalesEntityRelationInput"]: {
	connect?:PartialObjects["ReviewLocaleUniqueWhere"],
	create?:PartialObjects["ReviewLocaleWithoutReviewCreateInput"],
	alias?:string
},
	["ReviewCreateResult"]: {
		__typename?: "ReviewCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Review"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ReviewDeleteResult"]: {
		__typename?: "ReviewDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Review"]
	},
	["ReviewEdge"]: {
		__typename?: "ReviewEdge";
			node?:PartialObjects["Review"]
	},
	["ReviewLocale"]: {
		__typename?: "ReviewLocale";
			_meta?:PartialObjects["ReviewLocaleMeta"],
			id?:PartialObjects["UUID"],
			content?:string,
			image?:PartialObjects["Image"],
			locale?:PartialObjects["Locale"],
			review?:PartialObjects["Review"]
	},
	["ReviewLocaleConnection"]: {
		__typename?: "ReviewLocaleConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["ReviewLocaleEdge"][]
	},
	["ReviewLocaleCreateImageEntityRelationInput"]: {
	connect?:PartialObjects["ImageUniqueWhere"],
	create?:PartialObjects["ImageCreateInput"]
},
	["ReviewLocaleCreateInput"]: {
	content?:string,
	image?:PartialObjects["ReviewLocaleCreateImageEntityRelationInput"],
	locale?:PartialObjects["ReviewLocaleCreateLocaleEntityRelationInput"],
	review?:PartialObjects["ReviewLocaleCreateReviewEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ReviewLocaleCreateLocaleEntityRelationInput"]: {
	connect?:PartialObjects["LocaleUniqueWhere"],
	create?:PartialObjects["LocaleCreateInput"]
},
	["ReviewLocaleCreateResult"]: {
		__typename?: "ReviewLocaleCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ReviewLocale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ReviewLocaleCreateReviewEntityRelationInput"]: {
	connect?:PartialObjects["ReviewUniqueWhere"],
	create?:PartialObjects["ReviewWithoutLocalesCreateInput"]
},
	["ReviewLocaleDeleteResult"]: {
		__typename?: "ReviewLocaleDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ReviewLocale"]
	},
	["ReviewLocaleEdge"]: {
		__typename?: "ReviewLocaleEdge";
			node?:PartialObjects["ReviewLocale"]
	},
	["ReviewLocaleMeta"]: {
		__typename?: "ReviewLocaleMeta";
			id?:PartialObjects["FieldMeta"],
			content?:PartialObjects["FieldMeta"],
			image?:PartialObjects["FieldMeta"],
			locale?:PartialObjects["FieldMeta"],
			review?:PartialObjects["FieldMeta"]
	},
	["ReviewLocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	content?:PartialObjects["OrderDirection"],
	image?:PartialObjects["ImageOrderBy"],
	locale?:PartialObjects["LocaleOrderBy"],
	review?:PartialObjects["ReviewOrderBy"]
},
	["ReviewLocalesByLocaleUniqueWhere"]: {
	locale?:PartialObjects["LocaleUniqueWhere"]
},
	["ReviewLocaleUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	review?:PartialObjects["ReviewUniqueWhere"],
	locale?:PartialObjects["LocaleUniqueWhere"]
},
	["ReviewLocaleUpdateImageEntityRelationInput"]: {
	create?:PartialObjects["ImageCreateInput"],
	update?:PartialObjects["ImageUpdateInput"],
	upsert?:PartialObjects["ReviewLocaleUpsertImageRelationInput"],
	connect?:PartialObjects["ImageUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["ReviewLocaleUpdateInput"]: {
	content?:string,
	image?:PartialObjects["ReviewLocaleUpdateImageEntityRelationInput"],
	locale?:PartialObjects["ReviewLocaleUpdateLocaleEntityRelationInput"],
	review?:PartialObjects["ReviewLocaleUpdateReviewEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ReviewLocaleUpdateLocaleEntityRelationInput"]: {
	create?:PartialObjects["LocaleCreateInput"],
	update?:PartialObjects["LocaleUpdateInput"],
	upsert?:PartialObjects["ReviewLocaleUpsertLocaleRelationInput"],
	connect?:PartialObjects["LocaleUniqueWhere"]
},
	["ReviewLocaleUpdateResult"]: {
		__typename?: "ReviewLocaleUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ReviewLocale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ReviewLocaleUpdateReviewEntityRelationInput"]: {
	create?:PartialObjects["ReviewWithoutLocalesCreateInput"],
	update?:PartialObjects["ReviewWithoutLocalesUpdateInput"],
	upsert?:PartialObjects["ReviewLocaleUpsertReviewRelationInput"],
	connect?:PartialObjects["ReviewUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["ReviewLocaleUpsertImageRelationInput"]: {
	update?:PartialObjects["ImageUpdateInput"],
	create?:PartialObjects["ImageCreateInput"]
},
	["ReviewLocaleUpsertLocaleRelationInput"]: {
	update?:PartialObjects["LocaleUpdateInput"],
	create?:PartialObjects["LocaleCreateInput"]
},
	["ReviewLocaleUpsertReviewRelationInput"]: {
	update?:PartialObjects["ReviewWithoutLocalesUpdateInput"],
	create?:PartialObjects["ReviewWithoutLocalesCreateInput"]
},
	["ReviewLocaleWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	content?:PartialObjects["StringCondition"],
	image?:PartialObjects["ImageWhere"],
	locale?:PartialObjects["LocaleWhere"],
	review?:PartialObjects["ReviewWhere"],
	and?:PartialObjects["ReviewLocaleWhere"][],
	or?:PartialObjects["ReviewLocaleWhere"][],
	not?:PartialObjects["ReviewLocaleWhere"]
},
	["ReviewLocaleWithoutReviewCreateInput"]: {
	content?:string,
	image?:PartialObjects["ReviewLocaleCreateImageEntityRelationInput"],
	locale?:PartialObjects["ReviewLocaleCreateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ReviewLocaleWithoutReviewUpdateInput"]: {
	content?:string,
	image?:PartialObjects["ReviewLocaleUpdateImageEntityRelationInput"],
	locale?:PartialObjects["ReviewLocaleUpdateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
},
	["ReviewMeta"]: {
		__typename?: "ReviewMeta";
			id?:PartialObjects["FieldMeta"],
			username?:PartialObjects["FieldMeta"],
			createdAt?:PartialObjects["FieldMeta"],
			locales?:PartialObjects["FieldMeta"]
	},
	["ReviewOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	username?:PartialObjects["OrderDirection"],
	createdAt?:PartialObjects["OrderDirection"]
},
	["ReviewUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	locales?:PartialObjects["ReviewLocaleUniqueWhere"]
},
	["ReviewUpdateInput"]: {
	username?:string,
	createdAt?:PartialObjects["DateTime"],
	locales?:PartialObjects["ReviewUpdateLocalesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["ReviewUpdateLocalesEntityRelationInput"]: {
	create?:PartialObjects["ReviewLocaleWithoutReviewCreateInput"],
	update?:PartialObjects["ReviewUpdateLocalesRelationInput"],
	upsert?:PartialObjects["ReviewUpsertLocalesRelationInput"],
	connect?:PartialObjects["ReviewLocaleUniqueWhere"],
	disconnect?:PartialObjects["ReviewLocaleUniqueWhere"],
	delete?:PartialObjects["ReviewLocaleUniqueWhere"],
	alias?:string
},
	["ReviewUpdateLocalesRelationInput"]: {
	by?:PartialObjects["ReviewLocaleUniqueWhere"],
	data?:PartialObjects["ReviewLocaleWithoutReviewUpdateInput"]
},
	["ReviewUpdateResult"]: {
		__typename?: "ReviewUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Review"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ReviewUpsertLocalesRelationInput"]: {
	by?:PartialObjects["ReviewLocaleUniqueWhere"],
	update?:PartialObjects["ReviewLocaleWithoutReviewUpdateInput"],
	create?:PartialObjects["ReviewLocaleWithoutReviewCreateInput"]
},
	["ReviewView"]:ReviewView,
	["ReviewViewEnumCondition"]: {
	and?:PartialObjects["ReviewViewEnumCondition"][],
	or?:PartialObjects["ReviewViewEnumCondition"][],
	not?:PartialObjects["ReviewViewEnumCondition"],
	eq?:PartialObjects["ReviewView"],
	null?:boolean,
	isNull?:boolean,
	notEq?:PartialObjects["ReviewView"],
	in?:PartialObjects["ReviewView"][],
	notIn?:PartialObjects["ReviewView"][],
	lt?:PartialObjects["ReviewView"],
	lte?:PartialObjects["ReviewView"],
	gt?:PartialObjects["ReviewView"],
	gte?:PartialObjects["ReviewView"]
},
	["ReviewWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	username?:PartialObjects["StringCondition"],
	createdAt?:PartialObjects["DateTimeCondition"],
	locales?:PartialObjects["ReviewLocaleWhere"],
	and?:PartialObjects["ReviewWhere"][],
	or?:PartialObjects["ReviewWhere"][],
	not?:PartialObjects["ReviewWhere"]
},
	["ReviewWithoutLocalesCreateInput"]: {
	username?:string,
	createdAt?:PartialObjects["DateTime"],
	_dummy_field_?:boolean
},
	["ReviewWithoutLocalesUpdateInput"]: {
	username?:string,
	createdAt?:PartialObjects["DateTime"],
	_dummy_field_?:boolean
},
	["S3Header"]: {
		__typename?: "S3Header";
			key?:string,
			value?:string
	},
	["S3SignedRead"]: {
		__typename?: "S3SignedRead";
			url?:string,
			headers?:PartialObjects["S3Header"][],
			method?:string,
			/** Allowed patterns:
** */
	objectKey?:string,
			bucket?:string
	},
	["S3SignedUpload"]: {
		__typename?: "S3SignedUpload";
			url?:string,
			headers?:PartialObjects["S3Header"][],
			method?:string,
			/** Allowed patterns:
** */
	objectKey?:string,
			bucket?:string,
			publicUrl?:string
	},
	["Seo"]: {
		__typename?: "Seo";
			_meta?:PartialObjects["SeoMeta"],
			id?:PartialObjects["UUID"],
			ogImage?:PartialObjects["Image"],
			locales?:PartialObjects["SeoLocale"][],
			noindex?:boolean,
			localesByLocale?:PartialObjects["SeoLocale"]
	},
	["SeoConnection"]: {
		__typename?: "SeoConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["SeoEdge"][]
	},
	["SeoCreateInput"]: {
	ogImage?:PartialObjects["SeoCreateOgImageEntityRelationInput"],
	locales?:PartialObjects["SeoCreateLocalesEntityRelationInput"][],
	noindex?:boolean,
	_dummy_field_?:boolean
},
	["SeoCreateLocalesEntityRelationInput"]: {
	connect?:PartialObjects["SeoLocaleUniqueWhere"],
	create?:PartialObjects["SeoLocaleWithoutRootCreateInput"],
	alias?:string
},
	["SeoCreateOgImageEntityRelationInput"]: {
	connect?:PartialObjects["ImageUniqueWhere"],
	create?:PartialObjects["ImageCreateInput"]
},
	["SeoCreateResult"]: {
		__typename?: "SeoCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Seo"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["SeoDeleteResult"]: {
		__typename?: "SeoDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Seo"]
	},
	["SeoEdge"]: {
		__typename?: "SeoEdge";
			node?:PartialObjects["Seo"]
	},
	["SeoLocale"]: {
		__typename?: "SeoLocale";
			_meta?:PartialObjects["SeoLocaleMeta"],
			id?:PartialObjects["UUID"],
			title?:string,
			description?:string,
			ogTitle?:string,
			ogDescription?:string,
			root?:PartialObjects["Seo"],
			locale?:PartialObjects["Locale"]
	},
	["SeoLocaleConnection"]: {
		__typename?: "SeoLocaleConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["SeoLocaleEdge"][]
	},
	["SeoLocaleCreateInput"]: {
	title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	root?:PartialObjects["SeoLocaleCreateRootEntityRelationInput"],
	locale?:PartialObjects["SeoLocaleCreateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
},
	["SeoLocaleCreateLocaleEntityRelationInput"]: {
	connect?:PartialObjects["LocaleUniqueWhere"],
	create?:PartialObjects["LocaleWithoutSeosCreateInput"]
},
	["SeoLocaleCreateResult"]: {
		__typename?: "SeoLocaleCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["SeoLocale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["SeoLocaleCreateRootEntityRelationInput"]: {
	connect?:PartialObjects["SeoUniqueWhere"],
	create?:PartialObjects["SeoWithoutLocalesCreateInput"]
},
	["SeoLocaleDeleteResult"]: {
		__typename?: "SeoLocaleDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["SeoLocale"]
	},
	["SeoLocaleEdge"]: {
		__typename?: "SeoLocaleEdge";
			node?:PartialObjects["SeoLocale"]
	},
	["SeoLocaleMeta"]: {
		__typename?: "SeoLocaleMeta";
			id?:PartialObjects["FieldMeta"],
			title?:PartialObjects["FieldMeta"],
			description?:PartialObjects["FieldMeta"],
			ogTitle?:PartialObjects["FieldMeta"],
			ogDescription?:PartialObjects["FieldMeta"],
			root?:PartialObjects["FieldMeta"],
			locale?:PartialObjects["FieldMeta"]
	},
	["SeoLocaleOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	title?:PartialObjects["OrderDirection"],
	description?:PartialObjects["OrderDirection"],
	ogTitle?:PartialObjects["OrderDirection"],
	ogDescription?:PartialObjects["OrderDirection"],
	root?:PartialObjects["SeoOrderBy"],
	locale?:PartialObjects["LocaleOrderBy"]
},
	["SeoLocalesByLocaleUniqueWhere"]: {
	locale?:PartialObjects["LocaleUniqueWhere"]
},
	["SeoLocaleUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	root?:PartialObjects["SeoUniqueWhere"],
	locale?:PartialObjects["LocaleUniqueWhere"]
},
	["SeoLocaleUpdateInput"]: {
	title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	root?:PartialObjects["SeoLocaleUpdateRootEntityRelationInput"],
	locale?:PartialObjects["SeoLocaleUpdateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
},
	["SeoLocaleUpdateLocaleEntityRelationInput"]: {
	create?:PartialObjects["LocaleWithoutSeosCreateInput"],
	update?:PartialObjects["LocaleWithoutSeosUpdateInput"],
	upsert?:PartialObjects["SeoLocaleUpsertLocaleRelationInput"],
	connect?:PartialObjects["LocaleUniqueWhere"]
},
	["SeoLocaleUpdateResult"]: {
		__typename?: "SeoLocaleUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["SeoLocale"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["SeoLocaleUpdateRootEntityRelationInput"]: {
	create?:PartialObjects["SeoWithoutLocalesCreateInput"],
	update?:PartialObjects["SeoWithoutLocalesUpdateInput"],
	upsert?:PartialObjects["SeoLocaleUpsertRootRelationInput"],
	connect?:PartialObjects["SeoUniqueWhere"]
},
	["SeoLocaleUpsertLocaleRelationInput"]: {
	update?:PartialObjects["LocaleWithoutSeosUpdateInput"],
	create?:PartialObjects["LocaleWithoutSeosCreateInput"]
},
	["SeoLocaleUpsertRootRelationInput"]: {
	update?:PartialObjects["SeoWithoutLocalesUpdateInput"],
	create?:PartialObjects["SeoWithoutLocalesCreateInput"]
},
	["SeoLocaleWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	title?:PartialObjects["StringCondition"],
	description?:PartialObjects["StringCondition"],
	ogTitle?:PartialObjects["StringCondition"],
	ogDescription?:PartialObjects["StringCondition"],
	root?:PartialObjects["SeoWhere"],
	locale?:PartialObjects["LocaleWhere"],
	and?:PartialObjects["SeoLocaleWhere"][],
	or?:PartialObjects["SeoLocaleWhere"][],
	not?:PartialObjects["SeoLocaleWhere"]
},
	["SeoLocaleWithoutLocaleCreateInput"]: {
	title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	root?:PartialObjects["SeoLocaleCreateRootEntityRelationInput"],
	_dummy_field_?:boolean
},
	["SeoLocaleWithoutLocaleUpdateInput"]: {
	title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	root?:PartialObjects["SeoLocaleUpdateRootEntityRelationInput"],
	_dummy_field_?:boolean
},
	["SeoLocaleWithoutRootCreateInput"]: {
	title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	locale?:PartialObjects["SeoLocaleCreateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
},
	["SeoLocaleWithoutRootUpdateInput"]: {
	title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	locale?:PartialObjects["SeoLocaleUpdateLocaleEntityRelationInput"],
	_dummy_field_?:boolean
},
	["SeoMeta"]: {
		__typename?: "SeoMeta";
			id?:PartialObjects["FieldMeta"],
			ogImage?:PartialObjects["FieldMeta"],
			locales?:PartialObjects["FieldMeta"],
			noindex?:PartialObjects["FieldMeta"]
	},
	["SeoOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	ogImage?:PartialObjects["ImageOrderBy"],
	noindex?:PartialObjects["OrderDirection"]
},
	["SeoUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	ogImage?:PartialObjects["ImageUniqueWhere"],
	locales?:PartialObjects["SeoLocaleUniqueWhere"]
},
	["SeoUpdateInput"]: {
	ogImage?:PartialObjects["SeoUpdateOgImageEntityRelationInput"],
	locales?:PartialObjects["SeoUpdateLocalesEntityRelationInput"][],
	noindex?:boolean,
	_dummy_field_?:boolean
},
	["SeoUpdateLocalesEntityRelationInput"]: {
	create?:PartialObjects["SeoLocaleWithoutRootCreateInput"],
	update?:PartialObjects["SeoUpdateLocalesRelationInput"],
	upsert?:PartialObjects["SeoUpsertLocalesRelationInput"],
	connect?:PartialObjects["SeoLocaleUniqueWhere"],
	disconnect?:PartialObjects["SeoLocaleUniqueWhere"],
	delete?:PartialObjects["SeoLocaleUniqueWhere"],
	alias?:string
},
	["SeoUpdateLocalesRelationInput"]: {
	by?:PartialObjects["SeoLocaleUniqueWhere"],
	data?:PartialObjects["SeoLocaleWithoutRootUpdateInput"]
},
	["SeoUpdateOgImageEntityRelationInput"]: {
	create?:PartialObjects["ImageCreateInput"],
	update?:PartialObjects["ImageUpdateInput"],
	upsert?:PartialObjects["SeoUpsertOgImageRelationInput"],
	connect?:PartialObjects["ImageUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["SeoUpdateResult"]: {
		__typename?: "SeoUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Seo"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["SeoUpsertLocalesRelationInput"]: {
	by?:PartialObjects["SeoLocaleUniqueWhere"],
	update?:PartialObjects["SeoLocaleWithoutRootUpdateInput"],
	create?:PartialObjects["SeoLocaleWithoutRootCreateInput"]
},
	["SeoUpsertOgImageRelationInput"]: {
	update?:PartialObjects["ImageUpdateInput"],
	create?:PartialObjects["ImageCreateInput"]
},
	["SeoWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	ogImage?:PartialObjects["ImageWhere"],
	locales?:PartialObjects["SeoLocaleWhere"],
	noindex?:PartialObjects["BooleanCondition"],
	and?:PartialObjects["SeoWhere"][],
	or?:PartialObjects["SeoWhere"][],
	not?:PartialObjects["SeoWhere"]
},
	["SeoWithoutLocalesCreateInput"]: {
	ogImage?:PartialObjects["SeoCreateOgImageEntityRelationInput"],
	noindex?:boolean,
	_dummy_field_?:boolean
},
	["SeoWithoutLocalesUpdateInput"]: {
	ogImage?:PartialObjects["SeoUpdateOgImageEntityRelationInput"],
	noindex?:boolean,
	_dummy_field_?:boolean
},
	["ShopifyLineItem"]: {
		__typename?: "ShopifyLineItem";
			_meta?:PartialObjects["ShopifyLineItemMeta"],
			id?:PartialObjects["UUID"],
			store?:string,
			shopifyOrderId?:string,
			shopifyLineItemId?:string,
			customAttributes?:string
	},
	["ShopifyLineItemConnection"]: {
		__typename?: "ShopifyLineItemConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["ShopifyLineItemEdge"][]
	},
	["ShopifyLineItemCreateInput"]: {
	store?:string,
	shopifyOrderId?:string,
	shopifyLineItemId?:string,
	customAttributes?:string,
	_dummy_field_?:boolean
},
	["ShopifyLineItemCreateResult"]: {
		__typename?: "ShopifyLineItemCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ShopifyLineItem"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ShopifyLineItemDeleteResult"]: {
		__typename?: "ShopifyLineItemDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ShopifyLineItem"]
	},
	["ShopifyLineItemEdge"]: {
		__typename?: "ShopifyLineItemEdge";
			node?:PartialObjects["ShopifyLineItem"]
	},
	["ShopifyLineItemMeta"]: {
		__typename?: "ShopifyLineItemMeta";
			id?:PartialObjects["FieldMeta"],
			store?:PartialObjects["FieldMeta"],
			shopifyOrderId?:PartialObjects["FieldMeta"],
			shopifyLineItemId?:PartialObjects["FieldMeta"],
			customAttributes?:PartialObjects["FieldMeta"]
	},
	["ShopifyLineItemOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	store?:PartialObjects["OrderDirection"],
	shopifyOrderId?:PartialObjects["OrderDirection"],
	shopifyLineItemId?:PartialObjects["OrderDirection"],
	customAttributes?:PartialObjects["OrderDirection"]
},
	["ShopifyLineItemUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	store?:string,
	shopifyOrderId?:string,
	shopifyLineItemId?:string
},
	["ShopifyLineItemUpdateInput"]: {
	store?:string,
	shopifyOrderId?:string,
	shopifyLineItemId?:string,
	customAttributes?:string,
	_dummy_field_?:boolean
},
	["ShopifyLineItemUpdateResult"]: {
		__typename?: "ShopifyLineItemUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ShopifyLineItem"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ShopifyLineItemWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	store?:PartialObjects["StringCondition"],
	shopifyOrderId?:PartialObjects["StringCondition"],
	shopifyLineItemId?:PartialObjects["StringCondition"],
	customAttributes?:PartialObjects["StringCondition"],
	and?:PartialObjects["ShopifyLineItemWhere"][],
	or?:PartialObjects["ShopifyLineItemWhere"][],
	not?:PartialObjects["ShopifyLineItemWhere"]
},
	["ShopifyOrder"]: {
		__typename?: "ShopifyOrder";
			_meta?:PartialObjects["ShopifyOrderMeta"],
			id?:PartialObjects["UUID"],
			createdAt?:PartialObjects["DateTime"],
			fulfilledAt?:PartialObjects["DateTime"],
			store?:string,
			shopifyId?:string,
			shippingType?:string,
			trackingId?:string,
			rawData?:string,
			trackingUrl?:string,
			shippingMeta?:string,
			enableResendAt?:PartialObjects["DateTime"]
	},
	["ShopifyOrderConnection"]: {
		__typename?: "ShopifyOrderConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["ShopifyOrderEdge"][]
	},
	["ShopifyOrderCreateInput"]: {
	createdAt?:PartialObjects["DateTime"],
	fulfilledAt?:PartialObjects["DateTime"],
	store?:string,
	shopifyId?:string,
	shippingType?:string,
	trackingId?:string,
	rawData?:string,
	trackingUrl?:string,
	shippingMeta?:string,
	enableResendAt?:PartialObjects["DateTime"],
	_dummy_field_?:boolean
},
	["ShopifyOrderCreateResult"]: {
		__typename?: "ShopifyOrderCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ShopifyOrder"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ShopifyOrderDeleteResult"]: {
		__typename?: "ShopifyOrderDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ShopifyOrder"]
	},
	["ShopifyOrderEdge"]: {
		__typename?: "ShopifyOrderEdge";
			node?:PartialObjects["ShopifyOrder"]
	},
	["ShopifyOrderMeta"]: {
		__typename?: "ShopifyOrderMeta";
			id?:PartialObjects["FieldMeta"],
			createdAt?:PartialObjects["FieldMeta"],
			fulfilledAt?:PartialObjects["FieldMeta"],
			store?:PartialObjects["FieldMeta"],
			shopifyId?:PartialObjects["FieldMeta"],
			shippingType?:PartialObjects["FieldMeta"],
			trackingId?:PartialObjects["FieldMeta"],
			rawData?:PartialObjects["FieldMeta"],
			trackingUrl?:PartialObjects["FieldMeta"],
			shippingMeta?:PartialObjects["FieldMeta"],
			enableResendAt?:PartialObjects["FieldMeta"]
	},
	["ShopifyOrderOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	createdAt?:PartialObjects["OrderDirection"],
	fulfilledAt?:PartialObjects["OrderDirection"],
	store?:PartialObjects["OrderDirection"],
	shopifyId?:PartialObjects["OrderDirection"],
	shippingType?:PartialObjects["OrderDirection"],
	trackingId?:PartialObjects["OrderDirection"],
	rawData?:PartialObjects["OrderDirection"],
	trackingUrl?:PartialObjects["OrderDirection"],
	shippingMeta?:PartialObjects["OrderDirection"],
	enableResendAt?:PartialObjects["OrderDirection"]
},
	["ShopifyOrderUniqueWhere"]: {
	id?:PartialObjects["UUID"]
},
	["ShopifyOrderUpdateInput"]: {
	createdAt?:PartialObjects["DateTime"],
	fulfilledAt?:PartialObjects["DateTime"],
	store?:string,
	shopifyId?:string,
	shippingType?:string,
	trackingId?:string,
	rawData?:string,
	trackingUrl?:string,
	shippingMeta?:string,
	enableResendAt?:PartialObjects["DateTime"],
	_dummy_field_?:boolean
},
	["ShopifyOrderUpdateResult"]: {
		__typename?: "ShopifyOrderUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["ShopifyOrder"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["ShopifyOrderWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	createdAt?:PartialObjects["DateTimeCondition"],
	fulfilledAt?:PartialObjects["DateTimeCondition"],
	store?:PartialObjects["StringCondition"],
	shopifyId?:PartialObjects["StringCondition"],
	shippingType?:PartialObjects["StringCondition"],
	trackingId?:PartialObjects["StringCondition"],
	rawData?:PartialObjects["StringCondition"],
	trackingUrl?:PartialObjects["StringCondition"],
	shippingMeta?:PartialObjects["StringCondition"],
	enableResendAt?:PartialObjects["DateTimeCondition"],
	and?:PartialObjects["ShopifyOrderWhere"][],
	or?:PartialObjects["ShopifyOrderWhere"][],
	not?:PartialObjects["ShopifyOrderWhere"]
},
	["SideBySideView"]:SideBySideView,
	["SideBySideViewEnumCondition"]: {
	and?:PartialObjects["SideBySideViewEnumCondition"][],
	or?:PartialObjects["SideBySideViewEnumCondition"][],
	not?:PartialObjects["SideBySideViewEnumCondition"],
	eq?:PartialObjects["SideBySideView"],
	null?:boolean,
	isNull?:boolean,
	notEq?:PartialObjects["SideBySideView"],
	in?:PartialObjects["SideBySideView"][],
	notIn?:PartialObjects["SideBySideView"][],
	lt?:PartialObjects["SideBySideView"],
	lte?:PartialObjects["SideBySideView"],
	gt?:PartialObjects["SideBySideView"],
	gte?:PartialObjects["SideBySideView"]
},
	["Store"]: {
		__typename?: "Store";
			_meta?:PartialObjects["StoreMeta"],
			id?:PartialObjects["UUID"],
			code?:string,
			shopifyDomain?:string,
			products?:PartialObjects["ProductStore"][],
			productVariants?:PartialObjects["ProductVariantStore"][],
			productsByRoot?:PartialObjects["ProductStore"],
			productsByShopifyId?:PartialObjects["ProductStore"],
			productVariantsByRoot?:PartialObjects["ProductVariantStore"],
			productVariantsByShopifyId?:PartialObjects["ProductVariantStore"],
			productVariantsByStorefrontId?:PartialObjects["ProductVariantStore"]
	},
	["StoreConnection"]: {
		__typename?: "StoreConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["StoreEdge"][]
	},
	["StoreCreateInput"]: {
	code?:string,
	shopifyDomain?:string,
	products?:PartialObjects["StoreCreateProductsEntityRelationInput"][],
	productVariants?:PartialObjects["StoreCreateProductVariantsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["StoreCreateProductsEntityRelationInput"]: {
	connect?:PartialObjects["ProductStoreUniqueWhere"],
	create?:PartialObjects["ProductStoreWithoutStoreCreateInput"],
	alias?:string
},
	["StoreCreateProductVariantsEntityRelationInput"]: {
	connect?:PartialObjects["ProductVariantStoreUniqueWhere"],
	create?:PartialObjects["ProductVariantStoreWithoutStoreCreateInput"],
	alias?:string
},
	["StoreCreateResult"]: {
		__typename?: "StoreCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Store"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["StoreDeleteResult"]: {
		__typename?: "StoreDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Store"]
	},
	["StoreEdge"]: {
		__typename?: "StoreEdge";
			node?:PartialObjects["Store"]
	},
	["StoreMeta"]: {
		__typename?: "StoreMeta";
			id?:PartialObjects["FieldMeta"],
			code?:PartialObjects["FieldMeta"],
			shopifyDomain?:PartialObjects["FieldMeta"],
			products?:PartialObjects["FieldMeta"],
			productVariants?:PartialObjects["FieldMeta"]
	},
	["StoreOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	code?:PartialObjects["OrderDirection"],
	shopifyDomain?:PartialObjects["OrderDirection"]
},
	["StoreProductsByRootUniqueWhere"]: {
	root?:PartialObjects["ProductUniqueWhere"]
},
	["StoreProductsByShopifyIdUniqueWhere"]: {
	shopifyId?:string
},
	["StoreProductVariantsByRootUniqueWhere"]: {
	root?:PartialObjects["ProductVariantUniqueWhere"]
},
	["StoreProductVariantsByShopifyIdUniqueWhere"]: {
	shopifyId?:string
},
	["StoreProductVariantsByStorefrontIdUniqueWhere"]: {
	storefrontId?:string
},
	["StoreUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	code?:string,
	products?:PartialObjects["ProductStoreUniqueWhere"],
	productVariants?:PartialObjects["ProductVariantStoreUniqueWhere"]
},
	["StoreUpdateInput"]: {
	code?:string,
	shopifyDomain?:string,
	products?:PartialObjects["StoreUpdateProductsEntityRelationInput"][],
	productVariants?:PartialObjects["StoreUpdateProductVariantsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["StoreUpdateProductsEntityRelationInput"]: {
	create?:PartialObjects["ProductStoreWithoutStoreCreateInput"],
	update?:PartialObjects["StoreUpdateProductsRelationInput"],
	upsert?:PartialObjects["StoreUpsertProductsRelationInput"],
	connect?:PartialObjects["ProductStoreUniqueWhere"],
	disconnect?:PartialObjects["ProductStoreUniqueWhere"],
	delete?:PartialObjects["ProductStoreUniqueWhere"],
	alias?:string
},
	["StoreUpdateProductsRelationInput"]: {
	by?:PartialObjects["ProductStoreUniqueWhere"],
	data?:PartialObjects["ProductStoreWithoutStoreUpdateInput"]
},
	["StoreUpdateProductVariantsEntityRelationInput"]: {
	create?:PartialObjects["ProductVariantStoreWithoutStoreCreateInput"],
	update?:PartialObjects["StoreUpdateProductVariantsRelationInput"],
	upsert?:PartialObjects["StoreUpsertProductVariantsRelationInput"],
	connect?:PartialObjects["ProductVariantStoreUniqueWhere"],
	disconnect?:PartialObjects["ProductVariantStoreUniqueWhere"],
	delete?:PartialObjects["ProductVariantStoreUniqueWhere"],
	alias?:string
},
	["StoreUpdateProductVariantsRelationInput"]: {
	by?:PartialObjects["ProductVariantStoreUniqueWhere"],
	data?:PartialObjects["ProductVariantStoreWithoutStoreUpdateInput"]
},
	["StoreUpdateResult"]: {
		__typename?: "StoreUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["Store"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["StoreUpsertProductsRelationInput"]: {
	by?:PartialObjects["ProductStoreUniqueWhere"],
	update?:PartialObjects["ProductStoreWithoutStoreUpdateInput"],
	create?:PartialObjects["ProductStoreWithoutStoreCreateInput"]
},
	["StoreUpsertProductVariantsRelationInput"]: {
	by?:PartialObjects["ProductVariantStoreUniqueWhere"],
	update?:PartialObjects["ProductVariantStoreWithoutStoreUpdateInput"],
	create?:PartialObjects["ProductVariantStoreWithoutStoreCreateInput"]
},
	["StoreWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	code?:PartialObjects["StringCondition"],
	shopifyDomain?:PartialObjects["StringCondition"],
	products?:PartialObjects["ProductStoreWhere"],
	productVariants?:PartialObjects["ProductVariantStoreWhere"],
	and?:PartialObjects["StoreWhere"][],
	or?:PartialObjects["StoreWhere"][],
	not?:PartialObjects["StoreWhere"]
},
	["StoreWithoutProductsCreateInput"]: {
	code?:string,
	shopifyDomain?:string,
	productVariants?:PartialObjects["StoreCreateProductVariantsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["StoreWithoutProductsUpdateInput"]: {
	code?:string,
	shopifyDomain?:string,
	productVariants?:PartialObjects["StoreUpdateProductVariantsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["StoreWithoutProductVariantsCreateInput"]: {
	code?:string,
	shopifyDomain?:string,
	products?:PartialObjects["StoreCreateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["StoreWithoutProductVariantsUpdateInput"]: {
	code?:string,
	shopifyDomain?:string,
	products?:PartialObjects["StoreUpdateProductsEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["StringCondition"]: {
	and?:PartialObjects["StringCondition"][],
	or?:PartialObjects["StringCondition"][],
	not?:PartialObjects["StringCondition"],
	eq?:string,
	null?:boolean,
	isNull?:boolean,
	notEq?:string,
	in?:string[],
	notIn?:string[],
	lt?:string,
	lte?:string,
	gt?:string,
	gte?:string,
	contains?:string,
	startsWith?:string,
	endsWith?:string,
	containsCI?:string,
	startsWithCI?:string,
	endsWithCI?:string
},
	["TranslationCatalogue"]: {
		__typename?: "TranslationCatalogue";
			_meta?:PartialObjects["TranslationCatalogueMeta"],
			id?:PartialObjects["UUID"],
			identifier?:string,
			name?:string,
			domain?:PartialObjects["TranslationDomain"],
			fallback?:PartialObjects["TranslationCatalogue"],
			values?:PartialObjects["TranslationValue"][],
			valuesByKey?:PartialObjects["TranslationValue"]
	},
	["TranslationCatalogueConnection"]: {
		__typename?: "TranslationCatalogueConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["TranslationCatalogueEdge"][]
	},
	["TranslationCatalogueCreateDomainEntityRelationInput"]: {
	connect?:PartialObjects["TranslationDomainUniqueWhere"],
	create?:PartialObjects["TranslationDomainWithoutCataloguesCreateInput"]
},
	["TranslationCatalogueCreateFallbackEntityRelationInput"]: {
	connect?:PartialObjects["TranslationCatalogueUniqueWhere"],
	create?:PartialObjects["TranslationCatalogueCreateInput"]
},
	["TranslationCatalogueCreateInput"]: {
	identifier?:string,
	name?:string,
	domain?:PartialObjects["TranslationCatalogueCreateDomainEntityRelationInput"],
	fallback?:PartialObjects["TranslationCatalogueCreateFallbackEntityRelationInput"],
	values?:PartialObjects["TranslationCatalogueCreateValuesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["TranslationCatalogueCreateResult"]: {
		__typename?: "TranslationCatalogueCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["TranslationCatalogue"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["TranslationCatalogueCreateValuesEntityRelationInput"]: {
	connect?:PartialObjects["TranslationValueUniqueWhere"],
	create?:PartialObjects["TranslationValueWithoutCatalogueCreateInput"],
	alias?:string
},
	["TranslationCatalogueDeleteResult"]: {
		__typename?: "TranslationCatalogueDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["TranslationCatalogue"]
	},
	["TranslationCatalogueEdge"]: {
		__typename?: "TranslationCatalogueEdge";
			node?:PartialObjects["TranslationCatalogue"]
	},
	["TranslationCatalogueMeta"]: {
		__typename?: "TranslationCatalogueMeta";
			id?:PartialObjects["FieldMeta"],
			identifier?:PartialObjects["FieldMeta"],
			name?:PartialObjects["FieldMeta"],
			domain?:PartialObjects["FieldMeta"],
			fallback?:PartialObjects["FieldMeta"],
			values?:PartialObjects["FieldMeta"]
	},
	["TranslationCatalogueOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	identifier?:PartialObjects["OrderDirection"],
	name?:PartialObjects["OrderDirection"],
	domain?:PartialObjects["TranslationDomainOrderBy"],
	fallback?:PartialObjects["TranslationCatalogueOrderBy"]
},
	["TranslationCatalogueUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	domain?:PartialObjects["TranslationDomainUniqueWhere"],
	identifier?:string,
	values?:PartialObjects["TranslationValueUniqueWhere"]
},
	["TranslationCatalogueUpdateDomainEntityRelationInput"]: {
	create?:PartialObjects["TranslationDomainWithoutCataloguesCreateInput"],
	update?:PartialObjects["TranslationDomainWithoutCataloguesUpdateInput"],
	upsert?:PartialObjects["TranslationCatalogueUpsertDomainRelationInput"],
	connect?:PartialObjects["TranslationDomainUniqueWhere"]
},
	["TranslationCatalogueUpdateFallbackEntityRelationInput"]: {
	create?:PartialObjects["TranslationCatalogueCreateInput"],
	update?:PartialObjects["TranslationCatalogueUpdateInput"],
	upsert?:PartialObjects["TranslationCatalogueUpsertFallbackRelationInput"],
	connect?:PartialObjects["TranslationCatalogueUniqueWhere"],
	disconnect?:boolean,
	delete?:boolean
},
	["TranslationCatalogueUpdateInput"]: {
	identifier?:string,
	name?:string,
	domain?:PartialObjects["TranslationCatalogueUpdateDomainEntityRelationInput"],
	fallback?:PartialObjects["TranslationCatalogueUpdateFallbackEntityRelationInput"],
	values?:PartialObjects["TranslationCatalogueUpdateValuesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["TranslationCatalogueUpdateResult"]: {
		__typename?: "TranslationCatalogueUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["TranslationCatalogue"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["TranslationCatalogueUpdateValuesEntityRelationInput"]: {
	create?:PartialObjects["TranslationValueWithoutCatalogueCreateInput"],
	update?:PartialObjects["TranslationCatalogueUpdateValuesRelationInput"],
	upsert?:PartialObjects["TranslationCatalogueUpsertValuesRelationInput"],
	connect?:PartialObjects["TranslationValueUniqueWhere"],
	disconnect?:PartialObjects["TranslationValueUniqueWhere"],
	delete?:PartialObjects["TranslationValueUniqueWhere"],
	alias?:string
},
	["TranslationCatalogueUpdateValuesRelationInput"]: {
	by?:PartialObjects["TranslationValueUniqueWhere"],
	data?:PartialObjects["TranslationValueWithoutCatalogueUpdateInput"]
},
	["TranslationCatalogueUpsertDomainRelationInput"]: {
	update?:PartialObjects["TranslationDomainWithoutCataloguesUpdateInput"],
	create?:PartialObjects["TranslationDomainWithoutCataloguesCreateInput"]
},
	["TranslationCatalogueUpsertFallbackRelationInput"]: {
	update?:PartialObjects["TranslationCatalogueUpdateInput"],
	create?:PartialObjects["TranslationCatalogueCreateInput"]
},
	["TranslationCatalogueUpsertValuesRelationInput"]: {
	by?:PartialObjects["TranslationValueUniqueWhere"],
	update?:PartialObjects["TranslationValueWithoutCatalogueUpdateInput"],
	create?:PartialObjects["TranslationValueWithoutCatalogueCreateInput"]
},
	["TranslationCatalogueValuesByKeyUniqueWhere"]: {
	key?:PartialObjects["TranslationKeyUniqueWhere"]
},
	["TranslationCatalogueWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	identifier?:PartialObjects["StringCondition"],
	name?:PartialObjects["StringCondition"],
	domain?:PartialObjects["TranslationDomainWhere"],
	fallback?:PartialObjects["TranslationCatalogueWhere"],
	values?:PartialObjects["TranslationValueWhere"],
	and?:PartialObjects["TranslationCatalogueWhere"][],
	or?:PartialObjects["TranslationCatalogueWhere"][],
	not?:PartialObjects["TranslationCatalogueWhere"]
},
	["TranslationCatalogueWithoutDomainCreateInput"]: {
	identifier?:string,
	name?:string,
	fallback?:PartialObjects["TranslationCatalogueCreateFallbackEntityRelationInput"],
	values?:PartialObjects["TranslationCatalogueCreateValuesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["TranslationCatalogueWithoutDomainUpdateInput"]: {
	identifier?:string,
	name?:string,
	fallback?:PartialObjects["TranslationCatalogueUpdateFallbackEntityRelationInput"],
	values?:PartialObjects["TranslationCatalogueUpdateValuesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["TranslationCatalogueWithoutValuesCreateInput"]: {
	identifier?:string,
	name?:string,
	domain?:PartialObjects["TranslationCatalogueCreateDomainEntityRelationInput"],
	fallback?:PartialObjects["TranslationCatalogueCreateFallbackEntityRelationInput"],
	_dummy_field_?:boolean
},
	["TranslationCatalogueWithoutValuesUpdateInput"]: {
	identifier?:string,
	name?:string,
	domain?:PartialObjects["TranslationCatalogueUpdateDomainEntityRelationInput"],
	fallback?:PartialObjects["TranslationCatalogueUpdateFallbackEntityRelationInput"],
	_dummy_field_?:boolean
},
	["TranslationContentType"]:TranslationContentType,
	["TranslationContentTypeEnumCondition"]: {
	and?:PartialObjects["TranslationContentTypeEnumCondition"][],
	or?:PartialObjects["TranslationContentTypeEnumCondition"][],
	not?:PartialObjects["TranslationContentTypeEnumCondition"],
	eq?:PartialObjects["TranslationContentType"],
	null?:boolean,
	isNull?:boolean,
	notEq?:PartialObjects["TranslationContentType"],
	in?:PartialObjects["TranslationContentType"][],
	notIn?:PartialObjects["TranslationContentType"][],
	lt?:PartialObjects["TranslationContentType"],
	lte?:PartialObjects["TranslationContentType"],
	gt?:PartialObjects["TranslationContentType"],
	gte?:PartialObjects["TranslationContentType"]
},
	["TranslationDomain"]: {
		__typename?: "TranslationDomain";
			_meta?:PartialObjects["TranslationDomainMeta"],
			id?:PartialObjects["UUID"],
			identifier?:string,
			name?:string,
			catalogues?:PartialObjects["TranslationCatalogue"][],
			keys?:PartialObjects["TranslationKey"][],
			cataloguesByIdentifier?:PartialObjects["TranslationCatalogue"],
			cataloguesByValues?:PartialObjects["TranslationCatalogue"],
			keysByIdentifier?:PartialObjects["TranslationKey"],
			keysByValues?:PartialObjects["TranslationKey"]
	},
	["TranslationDomainCataloguesByIdentifierUniqueWhere"]: {
	identifier?:string
},
	["TranslationDomainCataloguesByValuesUniqueWhere"]: {
	values?:PartialObjects["TranslationValueUniqueWhere"]
},
	["TranslationDomainConnection"]: {
		__typename?: "TranslationDomainConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["TranslationDomainEdge"][]
	},
	["TranslationDomainCreateCataloguesEntityRelationInput"]: {
	connect?:PartialObjects["TranslationCatalogueUniqueWhere"],
	create?:PartialObjects["TranslationCatalogueWithoutDomainCreateInput"],
	alias?:string
},
	["TranslationDomainCreateInput"]: {
	identifier?:string,
	name?:string,
	catalogues?:PartialObjects["TranslationDomainCreateCataloguesEntityRelationInput"][],
	keys?:PartialObjects["TranslationDomainCreateKeysEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["TranslationDomainCreateKeysEntityRelationInput"]: {
	connect?:PartialObjects["TranslationKeyUniqueWhere"],
	create?:PartialObjects["TranslationKeyWithoutDomainCreateInput"],
	alias?:string
},
	["TranslationDomainCreateResult"]: {
		__typename?: "TranslationDomainCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["TranslationDomain"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["TranslationDomainDeleteResult"]: {
		__typename?: "TranslationDomainDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["TranslationDomain"]
	},
	["TranslationDomainEdge"]: {
		__typename?: "TranslationDomainEdge";
			node?:PartialObjects["TranslationDomain"]
	},
	["TranslationDomainKeysByIdentifierUniqueWhere"]: {
	identifier?:string
},
	["TranslationDomainKeysByValuesUniqueWhere"]: {
	values?:PartialObjects["TranslationValueUniqueWhere"]
},
	["TranslationDomainMeta"]: {
		__typename?: "TranslationDomainMeta";
			id?:PartialObjects["FieldMeta"],
			identifier?:PartialObjects["FieldMeta"],
			name?:PartialObjects["FieldMeta"],
			catalogues?:PartialObjects["FieldMeta"],
			keys?:PartialObjects["FieldMeta"]
	},
	["TranslationDomainOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	identifier?:PartialObjects["OrderDirection"],
	name?:PartialObjects["OrderDirection"]
},
	["TranslationDomainUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	identifier?:string,
	catalogues?:PartialObjects["TranslationCatalogueUniqueWhere"],
	keys?:PartialObjects["TranslationKeyUniqueWhere"]
},
	["TranslationDomainUpdateCataloguesEntityRelationInput"]: {
	create?:PartialObjects["TranslationCatalogueWithoutDomainCreateInput"],
	update?:PartialObjects["TranslationDomainUpdateCataloguesRelationInput"],
	upsert?:PartialObjects["TranslationDomainUpsertCataloguesRelationInput"],
	connect?:PartialObjects["TranslationCatalogueUniqueWhere"],
	disconnect?:PartialObjects["TranslationCatalogueUniqueWhere"],
	delete?:PartialObjects["TranslationCatalogueUniqueWhere"],
	alias?:string
},
	["TranslationDomainUpdateCataloguesRelationInput"]: {
	by?:PartialObjects["TranslationCatalogueUniqueWhere"],
	data?:PartialObjects["TranslationCatalogueWithoutDomainUpdateInput"]
},
	["TranslationDomainUpdateInput"]: {
	identifier?:string,
	name?:string,
	catalogues?:PartialObjects["TranslationDomainUpdateCataloguesEntityRelationInput"][],
	keys?:PartialObjects["TranslationDomainUpdateKeysEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["TranslationDomainUpdateKeysEntityRelationInput"]: {
	create?:PartialObjects["TranslationKeyWithoutDomainCreateInput"],
	update?:PartialObjects["TranslationDomainUpdateKeysRelationInput"],
	upsert?:PartialObjects["TranslationDomainUpsertKeysRelationInput"],
	connect?:PartialObjects["TranslationKeyUniqueWhere"],
	disconnect?:PartialObjects["TranslationKeyUniqueWhere"],
	delete?:PartialObjects["TranslationKeyUniqueWhere"],
	alias?:string
},
	["TranslationDomainUpdateKeysRelationInput"]: {
	by?:PartialObjects["TranslationKeyUniqueWhere"],
	data?:PartialObjects["TranslationKeyWithoutDomainUpdateInput"]
},
	["TranslationDomainUpdateResult"]: {
		__typename?: "TranslationDomainUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["TranslationDomain"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["TranslationDomainUpsertCataloguesRelationInput"]: {
	by?:PartialObjects["TranslationCatalogueUniqueWhere"],
	update?:PartialObjects["TranslationCatalogueWithoutDomainUpdateInput"],
	create?:PartialObjects["TranslationCatalogueWithoutDomainCreateInput"]
},
	["TranslationDomainUpsertKeysRelationInput"]: {
	by?:PartialObjects["TranslationKeyUniqueWhere"],
	update?:PartialObjects["TranslationKeyWithoutDomainUpdateInput"],
	create?:PartialObjects["TranslationKeyWithoutDomainCreateInput"]
},
	["TranslationDomainWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	identifier?:PartialObjects["StringCondition"],
	name?:PartialObjects["StringCondition"],
	catalogues?:PartialObjects["TranslationCatalogueWhere"],
	keys?:PartialObjects["TranslationKeyWhere"],
	and?:PartialObjects["TranslationDomainWhere"][],
	or?:PartialObjects["TranslationDomainWhere"][],
	not?:PartialObjects["TranslationDomainWhere"]
},
	["TranslationDomainWithoutCataloguesCreateInput"]: {
	identifier?:string,
	name?:string,
	keys?:PartialObjects["TranslationDomainCreateKeysEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["TranslationDomainWithoutCataloguesUpdateInput"]: {
	identifier?:string,
	name?:string,
	keys?:PartialObjects["TranslationDomainUpdateKeysEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["TranslationDomainWithoutKeysCreateInput"]: {
	identifier?:string,
	name?:string,
	catalogues?:PartialObjects["TranslationDomainCreateCataloguesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["TranslationDomainWithoutKeysUpdateInput"]: {
	identifier?:string,
	name?:string,
	catalogues?:PartialObjects["TranslationDomainUpdateCataloguesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["TranslationKey"]: {
		__typename?: "TranslationKey";
			_meta?:PartialObjects["TranslationKeyMeta"],
			id?:PartialObjects["UUID"],
			identifier?:string,
			contentType?:PartialObjects["TranslationContentType"],
			note?:string,
			domain?:PartialObjects["TranslationDomain"],
			values?:PartialObjects["TranslationValue"][],
			valuesByCatalogue?:PartialObjects["TranslationValue"]
	},
	["TranslationKeyConnection"]: {
		__typename?: "TranslationKeyConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["TranslationKeyEdge"][]
	},
	["TranslationKeyCreateDomainEntityRelationInput"]: {
	connect?:PartialObjects["TranslationDomainUniqueWhere"],
	create?:PartialObjects["TranslationDomainWithoutKeysCreateInput"]
},
	["TranslationKeyCreateInput"]: {
	identifier?:string,
	contentType?:PartialObjects["TranslationContentType"],
	note?:string,
	domain?:PartialObjects["TranslationKeyCreateDomainEntityRelationInput"],
	values?:PartialObjects["TranslationKeyCreateValuesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["TranslationKeyCreateResult"]: {
		__typename?: "TranslationKeyCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["TranslationKey"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["TranslationKeyCreateValuesEntityRelationInput"]: {
	connect?:PartialObjects["TranslationValueUniqueWhere"],
	create?:PartialObjects["TranslationValueWithoutKeyCreateInput"],
	alias?:string
},
	["TranslationKeyDeleteResult"]: {
		__typename?: "TranslationKeyDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["TranslationKey"]
	},
	["TranslationKeyEdge"]: {
		__typename?: "TranslationKeyEdge";
			node?:PartialObjects["TranslationKey"]
	},
	["TranslationKeyMeta"]: {
		__typename?: "TranslationKeyMeta";
			id?:PartialObjects["FieldMeta"],
			identifier?:PartialObjects["FieldMeta"],
			contentType?:PartialObjects["FieldMeta"],
			note?:PartialObjects["FieldMeta"],
			domain?:PartialObjects["FieldMeta"],
			values?:PartialObjects["FieldMeta"]
	},
	["TranslationKeyOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	identifier?:PartialObjects["OrderDirection"],
	contentType?:PartialObjects["OrderDirection"],
	note?:PartialObjects["OrderDirection"],
	domain?:PartialObjects["TranslationDomainOrderBy"]
},
	["TranslationKeyUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	domain?:PartialObjects["TranslationDomainUniqueWhere"],
	identifier?:string,
	values?:PartialObjects["TranslationValueUniqueWhere"]
},
	["TranslationKeyUpdateDomainEntityRelationInput"]: {
	create?:PartialObjects["TranslationDomainWithoutKeysCreateInput"],
	update?:PartialObjects["TranslationDomainWithoutKeysUpdateInput"],
	upsert?:PartialObjects["TranslationKeyUpsertDomainRelationInput"],
	connect?:PartialObjects["TranslationDomainUniqueWhere"]
},
	["TranslationKeyUpdateInput"]: {
	identifier?:string,
	contentType?:PartialObjects["TranslationContentType"],
	note?:string,
	domain?:PartialObjects["TranslationKeyUpdateDomainEntityRelationInput"],
	values?:PartialObjects["TranslationKeyUpdateValuesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["TranslationKeyUpdateResult"]: {
		__typename?: "TranslationKeyUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["TranslationKey"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["TranslationKeyUpdateValuesEntityRelationInput"]: {
	create?:PartialObjects["TranslationValueWithoutKeyCreateInput"],
	update?:PartialObjects["TranslationKeyUpdateValuesRelationInput"],
	upsert?:PartialObjects["TranslationKeyUpsertValuesRelationInput"],
	connect?:PartialObjects["TranslationValueUniqueWhere"],
	disconnect?:PartialObjects["TranslationValueUniqueWhere"],
	delete?:PartialObjects["TranslationValueUniqueWhere"],
	alias?:string
},
	["TranslationKeyUpdateValuesRelationInput"]: {
	by?:PartialObjects["TranslationValueUniqueWhere"],
	data?:PartialObjects["TranslationValueWithoutKeyUpdateInput"]
},
	["TranslationKeyUpsertDomainRelationInput"]: {
	update?:PartialObjects["TranslationDomainWithoutKeysUpdateInput"],
	create?:PartialObjects["TranslationDomainWithoutKeysCreateInput"]
},
	["TranslationKeyUpsertValuesRelationInput"]: {
	by?:PartialObjects["TranslationValueUniqueWhere"],
	update?:PartialObjects["TranslationValueWithoutKeyUpdateInput"],
	create?:PartialObjects["TranslationValueWithoutKeyCreateInput"]
},
	["TranslationKeyValuesByCatalogueUniqueWhere"]: {
	catalogue?:PartialObjects["TranslationCatalogueUniqueWhere"]
},
	["TranslationKeyWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	identifier?:PartialObjects["StringCondition"],
	contentType?:PartialObjects["TranslationContentTypeEnumCondition"],
	note?:PartialObjects["StringCondition"],
	domain?:PartialObjects["TranslationDomainWhere"],
	values?:PartialObjects["TranslationValueWhere"],
	and?:PartialObjects["TranslationKeyWhere"][],
	or?:PartialObjects["TranslationKeyWhere"][],
	not?:PartialObjects["TranslationKeyWhere"]
},
	["TranslationKeyWithoutDomainCreateInput"]: {
	identifier?:string,
	contentType?:PartialObjects["TranslationContentType"],
	note?:string,
	values?:PartialObjects["TranslationKeyCreateValuesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["TranslationKeyWithoutDomainUpdateInput"]: {
	identifier?:string,
	contentType?:PartialObjects["TranslationContentType"],
	note?:string,
	values?:PartialObjects["TranslationKeyUpdateValuesEntityRelationInput"][],
	_dummy_field_?:boolean
},
	["TranslationKeyWithoutValuesCreateInput"]: {
	identifier?:string,
	contentType?:PartialObjects["TranslationContentType"],
	note?:string,
	domain?:PartialObjects["TranslationKeyCreateDomainEntityRelationInput"],
	_dummy_field_?:boolean
},
	["TranslationKeyWithoutValuesUpdateInput"]: {
	identifier?:string,
	contentType?:PartialObjects["TranslationContentType"],
	note?:string,
	domain?:PartialObjects["TranslationKeyUpdateDomainEntityRelationInput"],
	_dummy_field_?:boolean
},
	["TranslationValue"]: {
		__typename?: "TranslationValue";
			_meta?:PartialObjects["TranslationValueMeta"],
			id?:PartialObjects["UUID"],
			value?:string,
			catalogue?:PartialObjects["TranslationCatalogue"],
			key?:PartialObjects["TranslationKey"]
	},
	["TranslationValueConnection"]: {
		__typename?: "TranslationValueConnection";
			pageInfo?:PartialObjects["PageInfo"],
			edges?:PartialObjects["TranslationValueEdge"][]
	},
	["TranslationValueCreateCatalogueEntityRelationInput"]: {
	connect?:PartialObjects["TranslationCatalogueUniqueWhere"],
	create?:PartialObjects["TranslationCatalogueWithoutValuesCreateInput"]
},
	["TranslationValueCreateInput"]: {
	value?:string,
	catalogue?:PartialObjects["TranslationValueCreateCatalogueEntityRelationInput"],
	key?:PartialObjects["TranslationValueCreateKeyEntityRelationInput"],
	_dummy_field_?:boolean
},
	["TranslationValueCreateKeyEntityRelationInput"]: {
	connect?:PartialObjects["TranslationKeyUniqueWhere"],
	create?:PartialObjects["TranslationKeyWithoutValuesCreateInput"]
},
	["TranslationValueCreateResult"]: {
		__typename?: "TranslationValueCreateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["TranslationValue"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["TranslationValueDeleteResult"]: {
		__typename?: "TranslationValueDeleteResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["TranslationValue"]
	},
	["TranslationValueEdge"]: {
		__typename?: "TranslationValueEdge";
			node?:PartialObjects["TranslationValue"]
	},
	["TranslationValueMeta"]: {
		__typename?: "TranslationValueMeta";
			id?:PartialObjects["FieldMeta"],
			value?:PartialObjects["FieldMeta"],
			catalogue?:PartialObjects["FieldMeta"],
			key?:PartialObjects["FieldMeta"]
	},
	["TranslationValueOrderBy"]: {
	_random?:boolean,
	_randomSeeded?:number,
	id?:PartialObjects["OrderDirection"],
	value?:PartialObjects["OrderDirection"],
	catalogue?:PartialObjects["TranslationCatalogueOrderBy"],
	key?:PartialObjects["TranslationKeyOrderBy"]
},
	["TranslationValueUniqueWhere"]: {
	id?:PartialObjects["UUID"],
	catalogue?:PartialObjects["TranslationCatalogueUniqueWhere"],
	key?:PartialObjects["TranslationKeyUniqueWhere"]
},
	["TranslationValueUpdateCatalogueEntityRelationInput"]: {
	create?:PartialObjects["TranslationCatalogueWithoutValuesCreateInput"],
	update?:PartialObjects["TranslationCatalogueWithoutValuesUpdateInput"],
	upsert?:PartialObjects["TranslationValueUpsertCatalogueRelationInput"],
	connect?:PartialObjects["TranslationCatalogueUniqueWhere"]
},
	["TranslationValueUpdateInput"]: {
	value?:string,
	catalogue?:PartialObjects["TranslationValueUpdateCatalogueEntityRelationInput"],
	key?:PartialObjects["TranslationValueUpdateKeyEntityRelationInput"],
	_dummy_field_?:boolean
},
	["TranslationValueUpdateKeyEntityRelationInput"]: {
	create?:PartialObjects["TranslationKeyWithoutValuesCreateInput"],
	update?:PartialObjects["TranslationKeyWithoutValuesUpdateInput"],
	upsert?:PartialObjects["TranslationValueUpsertKeyRelationInput"],
	connect?:PartialObjects["TranslationKeyUniqueWhere"]
},
	["TranslationValueUpdateResult"]: {
		__typename?: "TranslationValueUpdateResult";
			ok?:boolean,
			errorMessage?:string,
			errors?:PartialObjects["_MutationError"][],
			node?:PartialObjects["TranslationValue"],
			validation?:PartialObjects["_ValidationResult"]
	},
	["TranslationValueUpsertCatalogueRelationInput"]: {
	update?:PartialObjects["TranslationCatalogueWithoutValuesUpdateInput"],
	create?:PartialObjects["TranslationCatalogueWithoutValuesCreateInput"]
},
	["TranslationValueUpsertKeyRelationInput"]: {
	update?:PartialObjects["TranslationKeyWithoutValuesUpdateInput"],
	create?:PartialObjects["TranslationKeyWithoutValuesCreateInput"]
},
	["TranslationValueWhere"]: {
	id?:PartialObjects["UUIDCondition"],
	value?:PartialObjects["StringCondition"],
	catalogue?:PartialObjects["TranslationCatalogueWhere"],
	key?:PartialObjects["TranslationKeyWhere"],
	and?:PartialObjects["TranslationValueWhere"][],
	or?:PartialObjects["TranslationValueWhere"][],
	not?:PartialObjects["TranslationValueWhere"]
},
	["TranslationValueWithoutCatalogueCreateInput"]: {
	value?:string,
	key?:PartialObjects["TranslationValueCreateKeyEntityRelationInput"],
	_dummy_field_?:boolean
},
	["TranslationValueWithoutCatalogueUpdateInput"]: {
	value?:string,
	key?:PartialObjects["TranslationValueUpdateKeyEntityRelationInput"],
	_dummy_field_?:boolean
},
	["TranslationValueWithoutKeyCreateInput"]: {
	value?:string,
	catalogue?:PartialObjects["TranslationValueCreateCatalogueEntityRelationInput"],
	_dummy_field_?:boolean
},
	["TranslationValueWithoutKeyUpdateInput"]: {
	value?:string,
	catalogue?:PartialObjects["TranslationValueUpdateCatalogueEntityRelationInput"],
	_dummy_field_?:boolean
},
	["UUID"]:any,
	["UUIDCondition"]: {
	and?:PartialObjects["UUIDCondition"][],
	or?:PartialObjects["UUIDCondition"][],
	not?:PartialObjects["UUIDCondition"],
	eq?:PartialObjects["UUID"],
	null?:boolean,
	isNull?:boolean,
	notEq?:PartialObjects["UUID"],
	in?:PartialObjects["UUID"][],
	notIn?:PartialObjects["UUID"][],
	lt?:PartialObjects["UUID"],
	lte?:PartialObjects["UUID"],
	gt?:PartialObjects["UUID"],
	gte?:PartialObjects["UUID"]
}
  }

export type _AnyValue = {
	__union:_IntValue | _StringValue | _BooleanValue | _FloatValue | _UndefinedValue;
	__resolve:{
		['...on _IntValue']: _IntValue;
		['...on _StringValue']: _StringValue;
		['...on _BooleanValue']: _BooleanValue;
		['...on _FloatValue']: _FloatValue;
		['...on _UndefinedValue']: _UndefinedValue;
	}
}

export type _Argument = {
	__union:_ValidatorArgument | _PathArgument | _LiteralArgument;
	__resolve:{
		['...on _ValidatorArgument']: _ValidatorArgument;
		['...on _PathArgument']: _PathArgument;
		['...on _LiteralArgument']: _LiteralArgument;
	}
}

export type _BooleanValue = {
	__typename?: "_BooleanValue",
	booleanValue:boolean
}

export type _Entity = {
	__typename?: "_Entity",
	name:string,
	fields:_Field[]
}

export type _Enum = {
	__typename?: "_Enum",
	name:string,
	values:string[]
}

export type _Field = {
	__typename?: "_Field",
	name:string,
	type:string,
	rules:_Rule[],
	validators:_Validator[]
}

export type _FieldPathFragment = {
	__typename?: "_FieldPathFragment",
	field:string
}

export type _FloatValue = {
	__typename?: "_FloatValue",
	floatValue:number
}

export type _IndexPathFragment = {
	__typename?: "_IndexPathFragment",
	index:number,
	alias?:string
}

export type _IntValue = {
	__typename?: "_IntValue",
	intValue:number
}

export type _LiteralArgument = {
	__typename?: "_LiteralArgument",
	value?:_AnyValue
}

export type _MutationError = {
	__typename?: "_MutationError",
	path:_PathFragment[],
	type:_MutationErrorType,
	message?:string
}

export enum _MutationErrorType {
	NotNullConstraintViolation = "NotNullConstraintViolation",
	UniqueConstraintViolation = "UniqueConstraintViolation",
	ForeignKeyConstraintViolation = "ForeignKeyConstraintViolation",
	NotFoundOrDenied = "NotFoundOrDenied",
	NonUniqueWhereInput = "NonUniqueWhereInput",
	InvalidDataInput = "InvalidDataInput",
	SqlError = "SqlError"
}

export type _PathArgument = {
	__typename?: "_PathArgument",
	path:string[]
}

export type _PathFragment = {
	__union:_FieldPathFragment | _IndexPathFragment;
	__resolve:{
		['...on _FieldPathFragment']: _FieldPathFragment;
		['...on _IndexPathFragment']: _IndexPathFragment;
	}
}

export type _Rule = {
	__typename?: "_Rule",
	message?:_RuleMessage,
	validator:number
}

export type _RuleMessage = {
	__typename?: "_RuleMessage",
	text?:string
}

export type _Schema = {
	__typename?: "_Schema",
	enums:_Enum[],
	entities:_Entity[]
}

export type _StringValue = {
	__typename?: "_StringValue",
	stringValue:string
}

export type _UndefinedValue = {
	__typename?: "_UndefinedValue",
	undefinedValue:boolean
}

export type _ValidationError = {
	__typename?: "_ValidationError",
	path:_PathFragment[],
	message:_ValidationMessage
}

export type _ValidationMessage = {
	__typename?: "_ValidationMessage",
	text:string
}

export type _ValidationResult = {
	__typename?: "_ValidationResult",
	valid:boolean,
	errors:_ValidationError[]
}

export type _Validator = {
	__typename?: "_Validator",
	operation:string,
	arguments:_Argument[]
}

export type _ValidatorArgument = {
	__typename?: "_ValidatorArgument",
	validator:number
}

export type AbandonedCheckoutNotification = {
	__typename?: "AbandonedCheckoutNotification",
	_meta?:AbandonedCheckoutNotificationMeta,
	id:UUID,
	createdAt:DateTime,
	sentAt?:DateTime,
	tryCount?:number,
	store?:string,
	shopifyId?:string,
	orderJson?:string,
	metadata?:string,
	status?:string
}

export type AbandonedCheckoutNotificationConnection = {
	__typename?: "AbandonedCheckoutNotificationConnection",
	pageInfo:PageInfo,
	edges:AbandonedCheckoutNotificationEdge[]
}

export type AbandonedCheckoutNotificationCreateInput = {
		createdAt?:DateTime,
	sentAt?:DateTime,
	tryCount?:number,
	store?:string,
	shopifyId?:string,
	orderJson?:string,
	metadata?:string,
	status?:string,
	_dummy_field_?:boolean
}

export type AbandonedCheckoutNotificationCreateResult = {
	__typename?: "AbandonedCheckoutNotificationCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:AbandonedCheckoutNotification,
	validation:_ValidationResult
}

export type AbandonedCheckoutNotificationDeleteResult = {
	__typename?: "AbandonedCheckoutNotificationDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:AbandonedCheckoutNotification
}

export type AbandonedCheckoutNotificationEdge = {
	__typename?: "AbandonedCheckoutNotificationEdge",
	node:AbandonedCheckoutNotification
}

export type AbandonedCheckoutNotificationMeta = {
	__typename?: "AbandonedCheckoutNotificationMeta",
	id?:FieldMeta,
	createdAt?:FieldMeta,
	sentAt?:FieldMeta,
	tryCount?:FieldMeta,
	store?:FieldMeta,
	shopifyId?:FieldMeta,
	orderJson?:FieldMeta,
	metadata?:FieldMeta,
	status?:FieldMeta
}

export type AbandonedCheckoutNotificationOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	createdAt?:OrderDirection,
	sentAt?:OrderDirection,
	tryCount?:OrderDirection,
	store?:OrderDirection,
	shopifyId?:OrderDirection,
	orderJson?:OrderDirection,
	metadata?:OrderDirection,
	status?:OrderDirection
}

export type AbandonedCheckoutNotificationUniqueWhere = {
		id?:UUID
}

export type AbandonedCheckoutNotificationUpdateInput = {
		createdAt?:DateTime,
	sentAt?:DateTime,
	tryCount?:number,
	store?:string,
	shopifyId?:string,
	orderJson?:string,
	metadata?:string,
	status?:string,
	_dummy_field_?:boolean
}

export type AbandonedCheckoutNotificationUpdateResult = {
	__typename?: "AbandonedCheckoutNotificationUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:AbandonedCheckoutNotification,
	validation:_ValidationResult
}

export type AbandonedCheckoutNotificationWhere = {
		id?:UUIDCondition,
	createdAt?:DateTimeCondition,
	sentAt?:DateTimeCondition,
	tryCount?:IntCondition,
	store?:StringCondition,
	shopifyId?:StringCondition,
	orderJson?:StringCondition,
	metadata?:StringCondition,
	status?:StringCondition,
	and?:AbandonedCheckoutNotificationWhere[],
	or?:AbandonedCheckoutNotificationWhere[],
	not?:AbandonedCheckoutNotificationWhere
}

export type AbandonedCheckoutUnsubscription = {
	__typename?: "AbandonedCheckoutUnsubscription",
	_meta?:AbandonedCheckoutUnsubscriptionMeta,
	id:UUID,
	createdAt:DateTime,
	canceledAt?:DateTime,
	email:string
}

export type AbandonedCheckoutUnsubscriptionConnection = {
	__typename?: "AbandonedCheckoutUnsubscriptionConnection",
	pageInfo:PageInfo,
	edges:AbandonedCheckoutUnsubscriptionEdge[]
}

export type AbandonedCheckoutUnsubscriptionCreateInput = {
		createdAt?:DateTime,
	canceledAt?:DateTime,
	email?:string,
	_dummy_field_?:boolean
}

export type AbandonedCheckoutUnsubscriptionCreateResult = {
	__typename?: "AbandonedCheckoutUnsubscriptionCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:AbandonedCheckoutUnsubscription,
	validation:_ValidationResult
}

export type AbandonedCheckoutUnsubscriptionDeleteResult = {
	__typename?: "AbandonedCheckoutUnsubscriptionDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:AbandonedCheckoutUnsubscription
}

export type AbandonedCheckoutUnsubscriptionEdge = {
	__typename?: "AbandonedCheckoutUnsubscriptionEdge",
	node:AbandonedCheckoutUnsubscription
}

export type AbandonedCheckoutUnsubscriptionMeta = {
	__typename?: "AbandonedCheckoutUnsubscriptionMeta",
	id?:FieldMeta,
	createdAt?:FieldMeta,
	canceledAt?:FieldMeta,
	email?:FieldMeta
}

export type AbandonedCheckoutUnsubscriptionOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	createdAt?:OrderDirection,
	canceledAt?:OrderDirection,
	email?:OrderDirection
}

export type AbandonedCheckoutUnsubscriptionUniqueWhere = {
		id?:UUID,
	email?:string
}

export type AbandonedCheckoutUnsubscriptionUpdateInput = {
		createdAt?:DateTime,
	canceledAt?:DateTime,
	email?:string,
	_dummy_field_?:boolean
}

export type AbandonedCheckoutUnsubscriptionUpdateResult = {
	__typename?: "AbandonedCheckoutUnsubscriptionUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:AbandonedCheckoutUnsubscription,
	validation:_ValidationResult
}

export type AbandonedCheckoutUnsubscriptionWhere = {
		id?:UUIDCondition,
	createdAt?:DateTimeCondition,
	canceledAt?:DateTimeCondition,
	email?:StringCondition,
	and?:AbandonedCheckoutUnsubscriptionWhere[],
	or?:AbandonedCheckoutUnsubscriptionWhere[],
	not?:AbandonedCheckoutUnsubscriptionWhere
}

export type AdminNote = {
	__typename?: "AdminNote",
	_meta?:AdminNoteMeta,
	id:UUID,
	createdAt:DateTime,
	name?:string,
	data?:string
}

export type AdminNoteConnection = {
	__typename?: "AdminNoteConnection",
	pageInfo:PageInfo,
	edges:AdminNoteEdge[]
}

export type AdminNoteCreateInput = {
		createdAt?:DateTime,
	name?:string,
	data?:string,
	_dummy_field_?:boolean
}

export type AdminNoteCreateResult = {
	__typename?: "AdminNoteCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:AdminNote,
	validation:_ValidationResult
}

export type AdminNoteDeleteResult = {
	__typename?: "AdminNoteDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:AdminNote
}

export type AdminNoteEdge = {
	__typename?: "AdminNoteEdge",
	node:AdminNote
}

export type AdminNoteMeta = {
	__typename?: "AdminNoteMeta",
	id?:FieldMeta,
	createdAt?:FieldMeta,
	name?:FieldMeta,
	data?:FieldMeta
}

export type AdminNoteOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	createdAt?:OrderDirection,
	name?:OrderDirection,
	data?:OrderDirection
}

export type AdminNoteUniqueWhere = {
		id?:UUID
}

export type AdminNoteUpdateInput = {
		createdAt?:DateTime,
	name?:string,
	data?:string,
	_dummy_field_?:boolean
}

export type AdminNoteUpdateResult = {
	__typename?: "AdminNoteUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:AdminNote,
	validation:_ValidationResult
}

export type AdminNoteWhere = {
		id?:UUIDCondition,
	createdAt?:DateTimeCondition,
	name?:StringCondition,
	data?:StringCondition,
	and?:AdminNoteWhere[],
	or?:AdminNoteWhere[],
	not?:AdminNoteWhere
}

export type BooleanCondition = {
		and?:BooleanCondition[],
	or?:BooleanCondition[],
	not?:BooleanCondition,
	eq?:boolean,
	null?:boolean,
	isNull?:boolean,
	notEq?:boolean,
	in?:boolean[],
	notIn?:boolean[],
	lt?:boolean,
	lte?:boolean,
	gt?:boolean,
	gte?:boolean
}

export type Content = {
	__typename?: "Content",
	_meta?:ContentMeta,
	id:UUID,
	blocks:ContentBlock[],
	page?:PageLocale
}

export type ContentBlock = {
	__typename?: "ContentBlock",
	_meta?:ContentBlockMeta,
	id:UUID,
	order:number,
	type:ContentBlockType,
	primaryText?:string,
	secondaryText?:string,
	jsonContent?:string,
	headerView?:HeaderView,
	reviewView?:ReviewView,
	content?:Content,
	link?:Link,
	image?:Image,
	media?:Media,
	product?:Product,
	featureList?:FeatureList,
	iconList?:IconListBlock,
	reviewList:Review[],
	faq?:Faq,
	productList:Product[],
	tertiaryText?:string,
	sideBySideView?:SideBySideView,
	mobileImage?:Image,
	htmlId?:string
}

export type ContentBlockConnection = {
	__typename?: "ContentBlockConnection",
	pageInfo:PageInfo,
	edges:ContentBlockEdge[]
}

export type ContentBlockCreateContentEntityRelationInput = {
		connect?:ContentUniqueWhere,
	create?:ContentWithoutBlocksCreateInput
}

export type ContentBlockCreateFaqEntityRelationInput = {
		connect?:FaqUniqueWhere,
	create?:FaqCreateInput
}

export type ContentBlockCreateFeatureListEntityRelationInput = {
		connect?:FeatureListUniqueWhere,
	create?:FeatureListCreateInput
}

export type ContentBlockCreateIconListEntityRelationInput = {
		connect?:IconListBlockUniqueWhere,
	create?:IconListBlockCreateInput
}

export type ContentBlockCreateImageEntityRelationInput = {
		connect?:ImageUniqueWhere,
	create?:ImageCreateInput
}

export type ContentBlockCreateInput = {
		order?:number,
	type?:ContentBlockType,
	primaryText?:string,
	secondaryText?:string,
	jsonContent?:string,
	headerView?:HeaderView,
	reviewView?:ReviewView,
	content?:ContentBlockCreateContentEntityRelationInput,
	link?:ContentBlockCreateLinkEntityRelationInput,
	image?:ContentBlockCreateImageEntityRelationInput,
	media?:ContentBlockCreateMediaEntityRelationInput,
	product?:ContentBlockCreateProductEntityRelationInput,
	featureList?:ContentBlockCreateFeatureListEntityRelationInput,
	iconList?:ContentBlockCreateIconListEntityRelationInput,
	reviewList?:ContentBlockCreateReviewListEntityRelationInput[],
	faq?:ContentBlockCreateFaqEntityRelationInput,
	productList?:ContentBlockCreateProductListEntityRelationInput[],
	tertiaryText?:string,
	sideBySideView?:SideBySideView,
	mobileImage?:ContentBlockCreateMobileImageEntityRelationInput,
	htmlId?:string,
	_dummy_field_?:boolean
}

export type ContentBlockCreateLinkEntityRelationInput = {
		connect?:LinkUniqueWhere,
	create?:LinkCreateInput
}

export type ContentBlockCreateMediaEntityRelationInput = {
		connect?:MediaUniqueWhere,
	create?:MediaCreateInput
}

export type ContentBlockCreateMobileImageEntityRelationInput = {
		connect?:ImageUniqueWhere,
	create?:ImageCreateInput
}

export type ContentBlockCreateProductEntityRelationInput = {
		connect?:ProductUniqueWhere,
	create?:ProductCreateInput
}

export type ContentBlockCreateProductListEntityRelationInput = {
		connect?:ProductUniqueWhere,
	create?:ProductCreateInput,
	alias?:string
}

export type ContentBlockCreateResult = {
	__typename?: "ContentBlockCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ContentBlock,
	validation:_ValidationResult
}

export type ContentBlockCreateReviewListEntityRelationInput = {
		connect?:ReviewUniqueWhere,
	create?:ReviewCreateInput,
	alias?:string
}

export type ContentBlockDeleteResult = {
	__typename?: "ContentBlockDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ContentBlock
}

export type ContentBlockEdge = {
	__typename?: "ContentBlockEdge",
	node:ContentBlock
}

export type ContentBlockMeta = {
	__typename?: "ContentBlockMeta",
	id?:FieldMeta,
	order?:FieldMeta,
	type?:FieldMeta,
	primaryText?:FieldMeta,
	secondaryText?:FieldMeta,
	jsonContent?:FieldMeta,
	headerView?:FieldMeta,
	reviewView?:FieldMeta,
	content?:FieldMeta,
	link?:FieldMeta,
	image?:FieldMeta,
	media?:FieldMeta,
	product?:FieldMeta,
	featureList?:FieldMeta,
	iconList?:FieldMeta,
	reviewList?:FieldMeta,
	faq?:FieldMeta,
	productList?:FieldMeta,
	tertiaryText?:FieldMeta,
	sideBySideView?:FieldMeta,
	mobileImage?:FieldMeta,
	htmlId?:FieldMeta
}

export type ContentBlockOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	order?:OrderDirection,
	type?:OrderDirection,
	primaryText?:OrderDirection,
	secondaryText?:OrderDirection,
	jsonContent?:OrderDirection,
	headerView?:OrderDirection,
	reviewView?:OrderDirection,
	content?:ContentOrderBy,
	link?:LinkOrderBy,
	image?:ImageOrderBy,
	media?:MediaOrderBy,
	product?:ProductOrderBy,
	featureList?:FeatureListOrderBy,
	iconList?:IconListBlockOrderBy,
	faq?:FaqOrderBy,
	tertiaryText?:OrderDirection,
	sideBySideView?:OrderDirection,
	mobileImage?:ImageOrderBy,
	htmlId?:OrderDirection
}

export enum ContentBlockType {
	header = "header",
	product = "product",
	features = "features",
	icons = "icons",
	sideBySide = "sideBySide",
	reviews = "reviews",
	cta = "cta",
	faq = "faq",
	products = "products"
}

export type ContentBlockTypeEnumCondition = {
		and?:ContentBlockTypeEnumCondition[],
	or?:ContentBlockTypeEnumCondition[],
	not?:ContentBlockTypeEnumCondition,
	eq?:ContentBlockType,
	null?:boolean,
	isNull?:boolean,
	notEq?:ContentBlockType,
	in?:ContentBlockType[],
	notIn?:ContentBlockType[],
	lt?:ContentBlockType,
	lte?:ContentBlockType,
	gt?:ContentBlockType,
	gte?:ContentBlockType
}

export type ContentBlockUniqueWhere = {
		id?:UUID
}

export type ContentBlockUpdateContentEntityRelationInput = {
		create?:ContentWithoutBlocksCreateInput,
	update?:ContentWithoutBlocksUpdateInput,
	upsert?:ContentBlockUpsertContentRelationInput,
	connect?:ContentUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type ContentBlockUpdateFaqEntityRelationInput = {
		create?:FaqCreateInput,
	update?:FaqUpdateInput,
	upsert?:ContentBlockUpsertFaqRelationInput,
	connect?:FaqUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type ContentBlockUpdateFeatureListEntityRelationInput = {
		create?:FeatureListCreateInput,
	update?:FeatureListUpdateInput,
	upsert?:ContentBlockUpsertFeatureListRelationInput,
	connect?:FeatureListUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type ContentBlockUpdateIconListEntityRelationInput = {
		create?:IconListBlockCreateInput,
	update?:IconListBlockUpdateInput,
	upsert?:ContentBlockUpsertIconListRelationInput,
	connect?:IconListBlockUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type ContentBlockUpdateImageEntityRelationInput = {
		create?:ImageCreateInput,
	update?:ImageUpdateInput,
	upsert?:ContentBlockUpsertImageRelationInput,
	connect?:ImageUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type ContentBlockUpdateInput = {
		order?:number,
	type?:ContentBlockType,
	primaryText?:string,
	secondaryText?:string,
	jsonContent?:string,
	headerView?:HeaderView,
	reviewView?:ReviewView,
	content?:ContentBlockUpdateContentEntityRelationInput,
	link?:ContentBlockUpdateLinkEntityRelationInput,
	image?:ContentBlockUpdateImageEntityRelationInput,
	media?:ContentBlockUpdateMediaEntityRelationInput,
	product?:ContentBlockUpdateProductEntityRelationInput,
	featureList?:ContentBlockUpdateFeatureListEntityRelationInput,
	iconList?:ContentBlockUpdateIconListEntityRelationInput,
	reviewList?:ContentBlockUpdateReviewListEntityRelationInput[],
	faq?:ContentBlockUpdateFaqEntityRelationInput,
	productList?:ContentBlockUpdateProductListEntityRelationInput[],
	tertiaryText?:string,
	sideBySideView?:SideBySideView,
	mobileImage?:ContentBlockUpdateMobileImageEntityRelationInput,
	htmlId?:string,
	_dummy_field_?:boolean
}

export type ContentBlockUpdateLinkEntityRelationInput = {
		create?:LinkCreateInput,
	update?:LinkUpdateInput,
	upsert?:ContentBlockUpsertLinkRelationInput,
	connect?:LinkUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type ContentBlockUpdateMediaEntityRelationInput = {
		create?:MediaCreateInput,
	update?:MediaUpdateInput,
	upsert?:ContentBlockUpsertMediaRelationInput,
	connect?:MediaUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type ContentBlockUpdateMobileImageEntityRelationInput = {
		create?:ImageCreateInput,
	update?:ImageUpdateInput,
	upsert?:ContentBlockUpsertMobileImageRelationInput,
	connect?:ImageUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type ContentBlockUpdateProductEntityRelationInput = {
		create?:ProductCreateInput,
	update?:ProductUpdateInput,
	upsert?:ContentBlockUpsertProductRelationInput,
	connect?:ProductUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type ContentBlockUpdateProductListEntityRelationInput = {
		create?:ProductCreateInput,
	update?:ContentBlockUpdateProductListRelationInput,
	upsert?:ContentBlockUpsertProductListRelationInput,
	connect?:ProductUniqueWhere,
	disconnect?:ProductUniqueWhere,
	delete?:ProductUniqueWhere,
	alias?:string
}

export type ContentBlockUpdateProductListRelationInput = {
		by?:ProductUniqueWhere,
	data?:ProductUpdateInput
}

export type ContentBlockUpdateResult = {
	__typename?: "ContentBlockUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ContentBlock,
	validation:_ValidationResult
}

export type ContentBlockUpdateReviewListEntityRelationInput = {
		create?:ReviewCreateInput,
	update?:ContentBlockUpdateReviewListRelationInput,
	upsert?:ContentBlockUpsertReviewListRelationInput,
	connect?:ReviewUniqueWhere,
	disconnect?:ReviewUniqueWhere,
	delete?:ReviewUniqueWhere,
	alias?:string
}

export type ContentBlockUpdateReviewListRelationInput = {
		by?:ReviewUniqueWhere,
	data?:ReviewUpdateInput
}

export type ContentBlockUpsertContentRelationInput = {
		update?:ContentWithoutBlocksUpdateInput,
	create?:ContentWithoutBlocksCreateInput
}

export type ContentBlockUpsertFaqRelationInput = {
		update?:FaqUpdateInput,
	create?:FaqCreateInput
}

export type ContentBlockUpsertFeatureListRelationInput = {
		update?:FeatureListUpdateInput,
	create?:FeatureListCreateInput
}

export type ContentBlockUpsertIconListRelationInput = {
		update?:IconListBlockUpdateInput,
	create?:IconListBlockCreateInput
}

export type ContentBlockUpsertImageRelationInput = {
		update?:ImageUpdateInput,
	create?:ImageCreateInput
}

export type ContentBlockUpsertLinkRelationInput = {
		update?:LinkUpdateInput,
	create?:LinkCreateInput
}

export type ContentBlockUpsertMediaRelationInput = {
		update?:MediaUpdateInput,
	create?:MediaCreateInput
}

export type ContentBlockUpsertMobileImageRelationInput = {
		update?:ImageUpdateInput,
	create?:ImageCreateInput
}

export type ContentBlockUpsertProductListRelationInput = {
		by?:ProductUniqueWhere,
	update?:ProductUpdateInput,
	create?:ProductCreateInput
}

export type ContentBlockUpsertProductRelationInput = {
		update?:ProductUpdateInput,
	create?:ProductCreateInput
}

export type ContentBlockUpsertReviewListRelationInput = {
		by?:ReviewUniqueWhere,
	update?:ReviewUpdateInput,
	create?:ReviewCreateInput
}

export type ContentBlockWhere = {
		id?:UUIDCondition,
	order?:IntCondition,
	type?:ContentBlockTypeEnumCondition,
	primaryText?:StringCondition,
	secondaryText?:StringCondition,
	jsonContent?:StringCondition,
	headerView?:HeaderViewEnumCondition,
	reviewView?:ReviewViewEnumCondition,
	content?:ContentWhere,
	link?:LinkWhere,
	image?:ImageWhere,
	media?:MediaWhere,
	product?:ProductWhere,
	featureList?:FeatureListWhere,
	iconList?:IconListBlockWhere,
	reviewList?:ReviewWhere,
	faq?:FaqWhere,
	productList?:ProductWhere,
	tertiaryText?:StringCondition,
	sideBySideView?:SideBySideViewEnumCondition,
	mobileImage?:ImageWhere,
	htmlId?:StringCondition,
	and?:ContentBlockWhere[],
	or?:ContentBlockWhere[],
	not?:ContentBlockWhere
}

export type ContentBlockWithoutContentCreateInput = {
		order?:number,
	type?:ContentBlockType,
	primaryText?:string,
	secondaryText?:string,
	jsonContent?:string,
	headerView?:HeaderView,
	reviewView?:ReviewView,
	link?:ContentBlockCreateLinkEntityRelationInput,
	image?:ContentBlockCreateImageEntityRelationInput,
	media?:ContentBlockCreateMediaEntityRelationInput,
	product?:ContentBlockCreateProductEntityRelationInput,
	featureList?:ContentBlockCreateFeatureListEntityRelationInput,
	iconList?:ContentBlockCreateIconListEntityRelationInput,
	reviewList?:ContentBlockCreateReviewListEntityRelationInput[],
	faq?:ContentBlockCreateFaqEntityRelationInput,
	productList?:ContentBlockCreateProductListEntityRelationInput[],
	tertiaryText?:string,
	sideBySideView?:SideBySideView,
	mobileImage?:ContentBlockCreateMobileImageEntityRelationInput,
	htmlId?:string,
	_dummy_field_?:boolean
}

export type ContentBlockWithoutContentUpdateInput = {
		order?:number,
	type?:ContentBlockType,
	primaryText?:string,
	secondaryText?:string,
	jsonContent?:string,
	headerView?:HeaderView,
	reviewView?:ReviewView,
	link?:ContentBlockUpdateLinkEntityRelationInput,
	image?:ContentBlockUpdateImageEntityRelationInput,
	media?:ContentBlockUpdateMediaEntityRelationInput,
	product?:ContentBlockUpdateProductEntityRelationInput,
	featureList?:ContentBlockUpdateFeatureListEntityRelationInput,
	iconList?:ContentBlockUpdateIconListEntityRelationInput,
	reviewList?:ContentBlockUpdateReviewListEntityRelationInput[],
	faq?:ContentBlockUpdateFaqEntityRelationInput,
	productList?:ContentBlockUpdateProductListEntityRelationInput[],
	tertiaryText?:string,
	sideBySideView?:SideBySideView,
	mobileImage?:ContentBlockUpdateMobileImageEntityRelationInput,
	htmlId?:string,
	_dummy_field_?:boolean
}

export type ContentConnection = {
	__typename?: "ContentConnection",
	pageInfo:PageInfo,
	edges:ContentEdge[]
}

export type ContentCreateBlocksEntityRelationInput = {
		connect?:ContentBlockUniqueWhere,
	create?:ContentBlockWithoutContentCreateInput,
	alias?:string
}

export type ContentCreateInput = {
		blocks?:ContentCreateBlocksEntityRelationInput[],
	page?:ContentCreatePageEntityRelationInput,
	_dummy_field_?:boolean
}

export type ContentCreatePageEntityRelationInput = {
		connect?:PageLocaleUniqueWhere,
	create?:PageLocaleWithoutContentCreateInput
}

export type ContentCreateResult = {
	__typename?: "ContentCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Content,
	validation:_ValidationResult
}

export type ContentDeleteResult = {
	__typename?: "ContentDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Content
}

export type ContentEdge = {
	__typename?: "ContentEdge",
	node:Content
}

export type ContentMeta = {
	__typename?: "ContentMeta",
	id?:FieldMeta,
	blocks?:FieldMeta,
	page?:FieldMeta
}

export type ContentOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	page?:PageLocaleOrderBy
}

export type ContentUniqueWhere = {
		id?:UUID,
	blocks?:ContentBlockUniqueWhere,
	page?:PageLocaleUniqueWhere
}

export type ContentUpdateBlocksEntityRelationInput = {
		create?:ContentBlockWithoutContentCreateInput,
	update?:ContentUpdateBlocksRelationInput,
	upsert?:ContentUpsertBlocksRelationInput,
	connect?:ContentBlockUniqueWhere,
	disconnect?:ContentBlockUniqueWhere,
	delete?:ContentBlockUniqueWhere,
	alias?:string
}

export type ContentUpdateBlocksRelationInput = {
		by?:ContentBlockUniqueWhere,
	data?:ContentBlockWithoutContentUpdateInput
}

export type ContentUpdateInput = {
		blocks?:ContentUpdateBlocksEntityRelationInput[],
	page?:ContentUpdatePageEntityRelationInput,
	_dummy_field_?:boolean
}

export type ContentUpdatePageEntityRelationInput = {
		create?:PageLocaleWithoutContentCreateInput,
	update?:PageLocaleWithoutContentUpdateInput,
	upsert?:ContentUpsertPageRelationInput,
	connect?:PageLocaleUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type ContentUpdateResult = {
	__typename?: "ContentUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Content,
	validation:_ValidationResult
}

export type ContentUpsertBlocksRelationInput = {
		by?:ContentBlockUniqueWhere,
	update?:ContentBlockWithoutContentUpdateInput,
	create?:ContentBlockWithoutContentCreateInput
}

export type ContentUpsertPageRelationInput = {
		update?:PageLocaleWithoutContentUpdateInput,
	create?:PageLocaleWithoutContentCreateInput
}

export type ContentWhere = {
		id?:UUIDCondition,
	blocks?:ContentBlockWhere,
	page?:PageLocaleWhere,
	and?:ContentWhere[],
	or?:ContentWhere[],
	not?:ContentWhere
}

export type ContentWithoutBlocksCreateInput = {
		page?:ContentCreatePageEntityRelationInput,
	_dummy_field_?:boolean
}

export type ContentWithoutBlocksUpdateInput = {
		page?:ContentUpdatePageEntityRelationInput,
	_dummy_field_?:boolean
}

export type ContentWithoutPageCreateInput = {
		blocks?:ContentCreateBlocksEntityRelationInput[],
	_dummy_field_?:boolean
}

export type ContentWithoutPageUpdateInput = {
		blocks?:ContentUpdateBlocksEntityRelationInput[],
	_dummy_field_?:boolean
}

export type DateTime = any

export type DateTimeCondition = {
		and?:DateTimeCondition[],
	or?:DateTimeCondition[],
	not?:DateTimeCondition,
	eq?:DateTime,
	null?:boolean,
	isNull?:boolean,
	notEq?:DateTime,
	in?:DateTime[],
	notIn?:DateTime[],
	lt?:DateTime,
	lte?:DateTime,
	gt?:DateTime,
	gte?:DateTime
}

export type Faq = {
	__typename?: "Faq",
	_meta?:FaqMeta,
	id:UUID,
	items:FaqItem[]
}

export type FaqConnection = {
	__typename?: "FaqConnection",
	pageInfo:PageInfo,
	edges:FaqEdge[]
}

export type FaqCreateInput = {
		items?:FaqCreateItemsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type FaqCreateItemsEntityRelationInput = {
		connect?:FaqItemUniqueWhere,
	create?:FaqItemWithoutListCreateInput,
	alias?:string
}

export type FaqCreateResult = {
	__typename?: "FaqCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Faq,
	validation:_ValidationResult
}

export type FaqDeleteResult = {
	__typename?: "FaqDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Faq
}

export type FaqEdge = {
	__typename?: "FaqEdge",
	node:Faq
}

export type FaqItem = {
	__typename?: "FaqItem",
	_meta?:FaqItemMeta,
	id:UUID,
	order:number,
	question?:string,
	answer?:string,
	list?:Faq
}

export type FaqItemConnection = {
	__typename?: "FaqItemConnection",
	pageInfo:PageInfo,
	edges:FaqItemEdge[]
}

export type FaqItemCreateInput = {
		order?:number,
	question?:string,
	answer?:string,
	list?:FaqItemCreateListEntityRelationInput,
	_dummy_field_?:boolean
}

export type FaqItemCreateListEntityRelationInput = {
		connect?:FaqUniqueWhere,
	create?:FaqWithoutItemsCreateInput
}

export type FaqItemCreateResult = {
	__typename?: "FaqItemCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:FaqItem,
	validation:_ValidationResult
}

export type FaqItemDeleteResult = {
	__typename?: "FaqItemDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:FaqItem
}

export type FaqItemEdge = {
	__typename?: "FaqItemEdge",
	node:FaqItem
}

export type FaqItemMeta = {
	__typename?: "FaqItemMeta",
	id?:FieldMeta,
	order?:FieldMeta,
	question?:FieldMeta,
	answer?:FieldMeta,
	list?:FieldMeta
}

export type FaqItemOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	order?:OrderDirection,
	question?:OrderDirection,
	answer?:OrderDirection,
	list?:FaqOrderBy
}

export type FaqItemUniqueWhere = {
		id?:UUID
}

export type FaqItemUpdateInput = {
		order?:number,
	question?:string,
	answer?:string,
	list?:FaqItemUpdateListEntityRelationInput,
	_dummy_field_?:boolean
}

export type FaqItemUpdateListEntityRelationInput = {
		create?:FaqWithoutItemsCreateInput,
	update?:FaqWithoutItemsUpdateInput,
	upsert?:FaqItemUpsertListRelationInput,
	connect?:FaqUniqueWhere
}

export type FaqItemUpdateResult = {
	__typename?: "FaqItemUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:FaqItem,
	validation:_ValidationResult
}

export type FaqItemUpsertListRelationInput = {
		update?:FaqWithoutItemsUpdateInput,
	create?:FaqWithoutItemsCreateInput
}

export type FaqItemWhere = {
		id?:UUIDCondition,
	order?:IntCondition,
	question?:StringCondition,
	answer?:StringCondition,
	list?:FaqWhere,
	and?:FaqItemWhere[],
	or?:FaqItemWhere[],
	not?:FaqItemWhere
}

export type FaqItemWithoutListCreateInput = {
		order?:number,
	question?:string,
	answer?:string,
	_dummy_field_?:boolean
}

export type FaqItemWithoutListUpdateInput = {
		order?:number,
	question?:string,
	answer?:string,
	_dummy_field_?:boolean
}

export type FaqMeta = {
	__typename?: "FaqMeta",
	id?:FieldMeta,
	items?:FieldMeta
}

export type FaqOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection
}

export type FaqUniqueWhere = {
		id?:UUID,
	items?:FaqItemUniqueWhere
}

export type FaqUpdateInput = {
		items?:FaqUpdateItemsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type FaqUpdateItemsEntityRelationInput = {
		create?:FaqItemWithoutListCreateInput,
	update?:FaqUpdateItemsRelationInput,
	upsert?:FaqUpsertItemsRelationInput,
	connect?:FaqItemUniqueWhere,
	disconnect?:FaqItemUniqueWhere,
	delete?:FaqItemUniqueWhere,
	alias?:string
}

export type FaqUpdateItemsRelationInput = {
		by?:FaqItemUniqueWhere,
	data?:FaqItemWithoutListUpdateInput
}

export type FaqUpdateResult = {
	__typename?: "FaqUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Faq,
	validation:_ValidationResult
}

export type FaqUpsertItemsRelationInput = {
		by?:FaqItemUniqueWhere,
	update?:FaqItemWithoutListUpdateInput,
	create?:FaqItemWithoutListCreateInput
}

export type FaqWhere = {
		id?:UUIDCondition,
	items?:FaqItemWhere,
	and?:FaqWhere[],
	or?:FaqWhere[],
	not?:FaqWhere
}

export type FaqWithoutItemsCreateInput = {
		_dummy_field_?:boolean
}

export type FaqWithoutItemsUpdateInput = {
		_dummy_field_?:boolean
}

export type FeatureList = {
	__typename?: "FeatureList",
	_meta?:FeatureListMeta,
	id:UUID,
	items:FeatureListItem[]
}

export type FeatureListConnection = {
	__typename?: "FeatureListConnection",
	pageInfo:PageInfo,
	edges:FeatureListEdge[]
}

export type FeatureListCreateInput = {
		items?:FeatureListCreateItemsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type FeatureListCreateItemsEntityRelationInput = {
		connect?:FeatureListItemUniqueWhere,
	create?:FeatureListItemWithoutListCreateInput,
	alias?:string
}

export type FeatureListCreateResult = {
	__typename?: "FeatureListCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:FeatureList,
	validation:_ValidationResult
}

export type FeatureListDeleteResult = {
	__typename?: "FeatureListDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:FeatureList
}

export type FeatureListEdge = {
	__typename?: "FeatureListEdge",
	node:FeatureList
}

export type FeatureListItem = {
	__typename?: "FeatureListItem",
	_meta?:FeatureListItemMeta,
	id:UUID,
	order:number,
	headline?:string,
	text?:string,
	list?:FeatureList
}

export type FeatureListItemConnection = {
	__typename?: "FeatureListItemConnection",
	pageInfo:PageInfo,
	edges:FeatureListItemEdge[]
}

export type FeatureListItemCreateInput = {
		order?:number,
	headline?:string,
	text?:string,
	list?:FeatureListItemCreateListEntityRelationInput,
	_dummy_field_?:boolean
}

export type FeatureListItemCreateListEntityRelationInput = {
		connect?:FeatureListUniqueWhere,
	create?:FeatureListWithoutItemsCreateInput
}

export type FeatureListItemCreateResult = {
	__typename?: "FeatureListItemCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:FeatureListItem,
	validation:_ValidationResult
}

export type FeatureListItemDeleteResult = {
	__typename?: "FeatureListItemDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:FeatureListItem
}

export type FeatureListItemEdge = {
	__typename?: "FeatureListItemEdge",
	node:FeatureListItem
}

export type FeatureListItemMeta = {
	__typename?: "FeatureListItemMeta",
	id?:FieldMeta,
	order?:FieldMeta,
	headline?:FieldMeta,
	text?:FieldMeta,
	list?:FieldMeta
}

export type FeatureListItemOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	order?:OrderDirection,
	headline?:OrderDirection,
	text?:OrderDirection,
	list?:FeatureListOrderBy
}

export type FeatureListItemUniqueWhere = {
		id?:UUID
}

export type FeatureListItemUpdateInput = {
		order?:number,
	headline?:string,
	text?:string,
	list?:FeatureListItemUpdateListEntityRelationInput,
	_dummy_field_?:boolean
}

export type FeatureListItemUpdateListEntityRelationInput = {
		create?:FeatureListWithoutItemsCreateInput,
	update?:FeatureListWithoutItemsUpdateInput,
	upsert?:FeatureListItemUpsertListRelationInput,
	connect?:FeatureListUniqueWhere
}

export type FeatureListItemUpdateResult = {
	__typename?: "FeatureListItemUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:FeatureListItem,
	validation:_ValidationResult
}

export type FeatureListItemUpsertListRelationInput = {
		update?:FeatureListWithoutItemsUpdateInput,
	create?:FeatureListWithoutItemsCreateInput
}

export type FeatureListItemWhere = {
		id?:UUIDCondition,
	order?:IntCondition,
	headline?:StringCondition,
	text?:StringCondition,
	list?:FeatureListWhere,
	and?:FeatureListItemWhere[],
	or?:FeatureListItemWhere[],
	not?:FeatureListItemWhere
}

export type FeatureListItemWithoutListCreateInput = {
		order?:number,
	headline?:string,
	text?:string,
	_dummy_field_?:boolean
}

export type FeatureListItemWithoutListUpdateInput = {
		order?:number,
	headline?:string,
	text?:string,
	_dummy_field_?:boolean
}

export type FeatureListMeta = {
	__typename?: "FeatureListMeta",
	id?:FieldMeta,
	items?:FieldMeta
}

export type FeatureListOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection
}

export type FeatureListUniqueWhere = {
		id?:UUID,
	items?:FeatureListItemUniqueWhere
}

export type FeatureListUpdateInput = {
		items?:FeatureListUpdateItemsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type FeatureListUpdateItemsEntityRelationInput = {
		create?:FeatureListItemWithoutListCreateInput,
	update?:FeatureListUpdateItemsRelationInput,
	upsert?:FeatureListUpsertItemsRelationInput,
	connect?:FeatureListItemUniqueWhere,
	disconnect?:FeatureListItemUniqueWhere,
	delete?:FeatureListItemUniqueWhere,
	alias?:string
}

export type FeatureListUpdateItemsRelationInput = {
		by?:FeatureListItemUniqueWhere,
	data?:FeatureListItemWithoutListUpdateInput
}

export type FeatureListUpdateResult = {
	__typename?: "FeatureListUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:FeatureList,
	validation:_ValidationResult
}

export type FeatureListUpsertItemsRelationInput = {
		by?:FeatureListItemUniqueWhere,
	update?:FeatureListItemWithoutListUpdateInput,
	create?:FeatureListItemWithoutListCreateInput
}

export type FeatureListWhere = {
		id?:UUIDCondition,
	items?:FeatureListItemWhere,
	and?:FeatureListWhere[],
	or?:FeatureListWhere[],
	not?:FeatureListWhere
}

export type FeatureListWithoutItemsCreateInput = {
		_dummy_field_?:boolean
}

export type FeatureListWithoutItemsUpdateInput = {
		_dummy_field_?:boolean
}

export type FieldMeta = {
	__typename?: "FieldMeta",
	readable?:boolean,
	updatable?:boolean
}

export type FloatCondition = {
		and?:FloatCondition[],
	or?:FloatCondition[],
	not?:FloatCondition,
	eq?:number,
	null?:boolean,
	isNull?:boolean,
	notEq?:number,
	in?:number[],
	notIn?:number[],
	lt?:number,
	lte?:number,
	gt?:number,
	gte?:number
}

export type Gallery = {
	__typename?: "Gallery",
	_meta?:GalleryMeta,
	id:UUID,
	items:GalleryItem[]
}

export type GalleryConnection = {
	__typename?: "GalleryConnection",
	pageInfo:PageInfo,
	edges:GalleryEdge[]
}

export type GalleryCreateInput = {
		items?:GalleryCreateItemsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type GalleryCreateItemsEntityRelationInput = {
		connect?:GalleryItemUniqueWhere,
	create?:GalleryItemWithoutListCreateInput,
	alias?:string
}

export type GalleryCreateResult = {
	__typename?: "GalleryCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Gallery,
	validation:_ValidationResult
}

export type GalleryDeleteResult = {
	__typename?: "GalleryDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Gallery
}

export type GalleryEdge = {
	__typename?: "GalleryEdge",
	node:Gallery
}

export type GalleryItem = {
	__typename?: "GalleryItem",
	_meta?:GalleryItemMeta,
	id:UUID,
	order:number,
	caption?:string,
	list?:Gallery,
	image?:Image
}

export type GalleryItemConnection = {
	__typename?: "GalleryItemConnection",
	pageInfo:PageInfo,
	edges:GalleryItemEdge[]
}

export type GalleryItemCreateImageEntityRelationInput = {
		connect?:ImageUniqueWhere,
	create?:ImageCreateInput
}

export type GalleryItemCreateInput = {
		order?:number,
	caption?:string,
	list?:GalleryItemCreateListEntityRelationInput,
	image?:GalleryItemCreateImageEntityRelationInput,
	_dummy_field_?:boolean
}

export type GalleryItemCreateListEntityRelationInput = {
		connect?:GalleryUniqueWhere,
	create?:GalleryWithoutItemsCreateInput
}

export type GalleryItemCreateResult = {
	__typename?: "GalleryItemCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:GalleryItem,
	validation:_ValidationResult
}

export type GalleryItemDeleteResult = {
	__typename?: "GalleryItemDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:GalleryItem
}

export type GalleryItemEdge = {
	__typename?: "GalleryItemEdge",
	node:GalleryItem
}

export type GalleryItemMeta = {
	__typename?: "GalleryItemMeta",
	id?:FieldMeta,
	order?:FieldMeta,
	caption?:FieldMeta,
	list?:FieldMeta,
	image?:FieldMeta
}

export type GalleryItemOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	order?:OrderDirection,
	caption?:OrderDirection,
	list?:GalleryOrderBy,
	image?:ImageOrderBy
}

export type GalleryItemUniqueWhere = {
		id?:UUID
}

export type GalleryItemUpdateImageEntityRelationInput = {
		create?:ImageCreateInput,
	update?:ImageUpdateInput,
	upsert?:GalleryItemUpsertImageRelationInput,
	connect?:ImageUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type GalleryItemUpdateInput = {
		order?:number,
	caption?:string,
	list?:GalleryItemUpdateListEntityRelationInput,
	image?:GalleryItemUpdateImageEntityRelationInput,
	_dummy_field_?:boolean
}

export type GalleryItemUpdateListEntityRelationInput = {
		create?:GalleryWithoutItemsCreateInput,
	update?:GalleryWithoutItemsUpdateInput,
	upsert?:GalleryItemUpsertListRelationInput,
	connect?:GalleryUniqueWhere
}

export type GalleryItemUpdateResult = {
	__typename?: "GalleryItemUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:GalleryItem,
	validation:_ValidationResult
}

export type GalleryItemUpsertImageRelationInput = {
		update?:ImageUpdateInput,
	create?:ImageCreateInput
}

export type GalleryItemUpsertListRelationInput = {
		update?:GalleryWithoutItemsUpdateInput,
	create?:GalleryWithoutItemsCreateInput
}

export type GalleryItemWhere = {
		id?:UUIDCondition,
	order?:IntCondition,
	caption?:StringCondition,
	list?:GalleryWhere,
	image?:ImageWhere,
	and?:GalleryItemWhere[],
	or?:GalleryItemWhere[],
	not?:GalleryItemWhere
}

export type GalleryItemWithoutListCreateInput = {
		order?:number,
	caption?:string,
	image?:GalleryItemCreateImageEntityRelationInput,
	_dummy_field_?:boolean
}

export type GalleryItemWithoutListUpdateInput = {
		order?:number,
	caption?:string,
	image?:GalleryItemUpdateImageEntityRelationInput,
	_dummy_field_?:boolean
}

export type GalleryMeta = {
	__typename?: "GalleryMeta",
	id?:FieldMeta,
	items?:FieldMeta
}

export type GalleryOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection
}

export type GalleryUniqueWhere = {
		id?:UUID,
	items?:GalleryItemUniqueWhere
}

export type GalleryUpdateInput = {
		items?:GalleryUpdateItemsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type GalleryUpdateItemsEntityRelationInput = {
		create?:GalleryItemWithoutListCreateInput,
	update?:GalleryUpdateItemsRelationInput,
	upsert?:GalleryUpsertItemsRelationInput,
	connect?:GalleryItemUniqueWhere,
	disconnect?:GalleryItemUniqueWhere,
	delete?:GalleryItemUniqueWhere,
	alias?:string
}

export type GalleryUpdateItemsRelationInput = {
		by?:GalleryItemUniqueWhere,
	data?:GalleryItemWithoutListUpdateInput
}

export type GalleryUpdateResult = {
	__typename?: "GalleryUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Gallery,
	validation:_ValidationResult
}

export type GalleryUpsertItemsRelationInput = {
		by?:GalleryItemUniqueWhere,
	update?:GalleryItemWithoutListUpdateInput,
	create?:GalleryItemWithoutListCreateInput
}

export type GalleryWhere = {
		id?:UUIDCondition,
	items?:GalleryItemWhere,
	and?:GalleryWhere[],
	or?:GalleryWhere[],
	not?:GalleryWhere
}

export type GalleryWithoutItemsCreateInput = {
		_dummy_field_?:boolean
}

export type GalleryWithoutItemsUpdateInput = {
		_dummy_field_?:boolean
}

export enum HeaderView {
	image = "image",
	noimage = "noimage"
}

export type HeaderViewEnumCondition = {
		and?:HeaderViewEnumCondition[],
	or?:HeaderViewEnumCondition[],
	not?:HeaderViewEnumCondition,
	eq?:HeaderView,
	null?:boolean,
	isNull?:boolean,
	notEq?:HeaderView,
	in?:HeaderView[],
	notIn?:HeaderView[],
	lt?:HeaderView,
	lte?:HeaderView,
	gt?:HeaderView,
	gte?:HeaderView
}

export type Icon = {
	__typename?: "Icon",
	_meta?:IconMeta,
	id:UUID,
	key?:string,
	image?:Image,
	locales:IconLocale[],
	localesByLocale?:IconLocale
}

export type IconConnection = {
	__typename?: "IconConnection",
	pageInfo:PageInfo,
	edges:IconEdge[]
}

export type IconCreateImageEntityRelationInput = {
		connect?:ImageUniqueWhere,
	create?:ImageCreateInput
}

export type IconCreateInput = {
		key?:string,
	image?:IconCreateImageEntityRelationInput,
	locales?:IconCreateLocalesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type IconCreateLocalesEntityRelationInput = {
		connect?:IconLocaleUniqueWhere,
	create?:IconLocaleWithoutRootCreateInput,
	alias?:string
}

export type IconCreateResult = {
	__typename?: "IconCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Icon,
	validation:_ValidationResult
}

export type IconDeleteResult = {
	__typename?: "IconDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Icon
}

export type IconEdge = {
	__typename?: "IconEdge",
	node:Icon
}

export type IconGroup = {
	__typename?: "IconGroup",
	_meta?:IconGroupMeta,
	id:UUID,
	icons?:IconList,
	locales:IconGroupLocale[],
	title?:string,
	localesByLocale?:IconGroupLocale
}

export type IconGroupConnection = {
	__typename?: "IconGroupConnection",
	pageInfo:PageInfo,
	edges:IconGroupEdge[]
}

export type IconGroupCreateIconsEntityRelationInput = {
		connect?:IconListUniqueWhere,
	create?:IconListCreateInput
}

export type IconGroupCreateInput = {
		icons?:IconGroupCreateIconsEntityRelationInput,
	locales?:IconGroupCreateLocalesEntityRelationInput[],
	title?:string,
	_dummy_field_?:boolean
}

export type IconGroupCreateLocalesEntityRelationInput = {
		connect?:IconGroupLocaleUniqueWhere,
	create?:IconGroupLocaleWithoutRootCreateInput,
	alias?:string
}

export type IconGroupCreateResult = {
	__typename?: "IconGroupCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconGroup,
	validation:_ValidationResult
}

export type IconGroupDeleteResult = {
	__typename?: "IconGroupDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconGroup
}

export type IconGroupEdge = {
	__typename?: "IconGroupEdge",
	node:IconGroup
}

export type IconGroupList = {
	__typename?: "IconGroupList",
	_meta?:IconGroupListMeta,
	id:UUID,
	items:IconGroupListItem[],
	title?:string
}

export type IconGroupListConnection = {
	__typename?: "IconGroupListConnection",
	pageInfo:PageInfo,
	edges:IconGroupListEdge[]
}

export type IconGroupListCreateInput = {
		items?:IconGroupListCreateItemsEntityRelationInput[],
	title?:string,
	_dummy_field_?:boolean
}

export type IconGroupListCreateItemsEntityRelationInput = {
		connect?:IconGroupListItemUniqueWhere,
	create?:IconGroupListItemWithoutListCreateInput,
	alias?:string
}

export type IconGroupListCreateResult = {
	__typename?: "IconGroupListCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconGroupList,
	validation:_ValidationResult
}

export type IconGroupListDeleteResult = {
	__typename?: "IconGroupListDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconGroupList
}

export type IconGroupListEdge = {
	__typename?: "IconGroupListEdge",
	node:IconGroupList
}

export type IconGroupListItem = {
	__typename?: "IconGroupListItem",
	_meta?:IconGroupListItemMeta,
	id:UUID,
	order:number,
	list?:IconGroupList,
	group?:IconGroup
}

export type IconGroupListItemConnection = {
	__typename?: "IconGroupListItemConnection",
	pageInfo:PageInfo,
	edges:IconGroupListItemEdge[]
}

export type IconGroupListItemCreateGroupEntityRelationInput = {
		connect?:IconGroupUniqueWhere,
	create?:IconGroupCreateInput
}

export type IconGroupListItemCreateInput = {
		order?:number,
	list?:IconGroupListItemCreateListEntityRelationInput,
	group?:IconGroupListItemCreateGroupEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconGroupListItemCreateListEntityRelationInput = {
		connect?:IconGroupListUniqueWhere,
	create?:IconGroupListWithoutItemsCreateInput
}

export type IconGroupListItemCreateResult = {
	__typename?: "IconGroupListItemCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconGroupListItem,
	validation:_ValidationResult
}

export type IconGroupListItemDeleteResult = {
	__typename?: "IconGroupListItemDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconGroupListItem
}

export type IconGroupListItemEdge = {
	__typename?: "IconGroupListItemEdge",
	node:IconGroupListItem
}

export type IconGroupListItemMeta = {
	__typename?: "IconGroupListItemMeta",
	id?:FieldMeta,
	order?:FieldMeta,
	list?:FieldMeta,
	group?:FieldMeta
}

export type IconGroupListItemOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	order?:OrderDirection,
	list?:IconGroupListOrderBy,
	group?:IconGroupOrderBy
}

export type IconGroupListItemUniqueWhere = {
		id?:UUID
}

export type IconGroupListItemUpdateGroupEntityRelationInput = {
		create?:IconGroupCreateInput,
	update?:IconGroupUpdateInput,
	upsert?:IconGroupListItemUpsertGroupRelationInput,
	connect?:IconGroupUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type IconGroupListItemUpdateInput = {
		order?:number,
	list?:IconGroupListItemUpdateListEntityRelationInput,
	group?:IconGroupListItemUpdateGroupEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconGroupListItemUpdateListEntityRelationInput = {
		create?:IconGroupListWithoutItemsCreateInput,
	update?:IconGroupListWithoutItemsUpdateInput,
	upsert?:IconGroupListItemUpsertListRelationInput,
	connect?:IconGroupListUniqueWhere
}

export type IconGroupListItemUpdateResult = {
	__typename?: "IconGroupListItemUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconGroupListItem,
	validation:_ValidationResult
}

export type IconGroupListItemUpsertGroupRelationInput = {
		update?:IconGroupUpdateInput,
	create?:IconGroupCreateInput
}

export type IconGroupListItemUpsertListRelationInput = {
		update?:IconGroupListWithoutItemsUpdateInput,
	create?:IconGroupListWithoutItemsCreateInput
}

export type IconGroupListItemWhere = {
		id?:UUIDCondition,
	order?:IntCondition,
	list?:IconGroupListWhere,
	group?:IconGroupWhere,
	and?:IconGroupListItemWhere[],
	or?:IconGroupListItemWhere[],
	not?:IconGroupListItemWhere
}

export type IconGroupListItemWithoutListCreateInput = {
		order?:number,
	group?:IconGroupListItemCreateGroupEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconGroupListItemWithoutListUpdateInput = {
		order?:number,
	group?:IconGroupListItemUpdateGroupEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconGroupListMeta = {
	__typename?: "IconGroupListMeta",
	id?:FieldMeta,
	items?:FieldMeta,
	title?:FieldMeta
}

export type IconGroupListOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	title?:OrderDirection
}

export type IconGroupListUniqueWhere = {
		id?:UUID,
	items?:IconGroupListItemUniqueWhere
}

export type IconGroupListUpdateInput = {
		items?:IconGroupListUpdateItemsEntityRelationInput[],
	title?:string,
	_dummy_field_?:boolean
}

export type IconGroupListUpdateItemsEntityRelationInput = {
		create?:IconGroupListItemWithoutListCreateInput,
	update?:IconGroupListUpdateItemsRelationInput,
	upsert?:IconGroupListUpsertItemsRelationInput,
	connect?:IconGroupListItemUniqueWhere,
	disconnect?:IconGroupListItemUniqueWhere,
	delete?:IconGroupListItemUniqueWhere,
	alias?:string
}

export type IconGroupListUpdateItemsRelationInput = {
		by?:IconGroupListItemUniqueWhere,
	data?:IconGroupListItemWithoutListUpdateInput
}

export type IconGroupListUpdateResult = {
	__typename?: "IconGroupListUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconGroupList,
	validation:_ValidationResult
}

export type IconGroupListUpsertItemsRelationInput = {
		by?:IconGroupListItemUniqueWhere,
	update?:IconGroupListItemWithoutListUpdateInput,
	create?:IconGroupListItemWithoutListCreateInput
}

export type IconGroupListWhere = {
		id?:UUIDCondition,
	items?:IconGroupListItemWhere,
	title?:StringCondition,
	and?:IconGroupListWhere[],
	or?:IconGroupListWhere[],
	not?:IconGroupListWhere
}

export type IconGroupListWithoutItemsCreateInput = {
		title?:string,
	_dummy_field_?:boolean
}

export type IconGroupListWithoutItemsUpdateInput = {
		title?:string,
	_dummy_field_?:boolean
}

export type IconGroupLocale = {
	__typename?: "IconGroupLocale",
	_meta?:IconGroupLocaleMeta,
	id:UUID,
	title?:string,
	root?:IconGroup,
	locale?:Locale
}

export type IconGroupLocaleConnection = {
	__typename?: "IconGroupLocaleConnection",
	pageInfo:PageInfo,
	edges:IconGroupLocaleEdge[]
}

export type IconGroupLocaleCreateInput = {
		title?:string,
	root?:IconGroupLocaleCreateRootEntityRelationInput,
	locale?:IconGroupLocaleCreateLocaleEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconGroupLocaleCreateLocaleEntityRelationInput = {
		connect?:LocaleUniqueWhere,
	create?:LocaleWithoutIconGroupsCreateInput
}

export type IconGroupLocaleCreateResult = {
	__typename?: "IconGroupLocaleCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconGroupLocale,
	validation:_ValidationResult
}

export type IconGroupLocaleCreateRootEntityRelationInput = {
		connect?:IconGroupUniqueWhere,
	create?:IconGroupWithoutLocalesCreateInput
}

export type IconGroupLocaleDeleteResult = {
	__typename?: "IconGroupLocaleDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconGroupLocale
}

export type IconGroupLocaleEdge = {
	__typename?: "IconGroupLocaleEdge",
	node:IconGroupLocale
}

export type IconGroupLocaleMeta = {
	__typename?: "IconGroupLocaleMeta",
	id?:FieldMeta,
	title?:FieldMeta,
	root?:FieldMeta,
	locale?:FieldMeta
}

export type IconGroupLocaleOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	title?:OrderDirection,
	root?:IconGroupOrderBy,
	locale?:LocaleOrderBy
}

export type IconGroupLocalesByLocaleUniqueWhere = {
		locale?:LocaleUniqueWhere
}

export type IconGroupLocaleUniqueWhere = {
		id?:UUID,
	root?:IconGroupUniqueWhere,
	locale?:LocaleUniqueWhere
}

export type IconGroupLocaleUpdateInput = {
		title?:string,
	root?:IconGroupLocaleUpdateRootEntityRelationInput,
	locale?:IconGroupLocaleUpdateLocaleEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconGroupLocaleUpdateLocaleEntityRelationInput = {
		create?:LocaleWithoutIconGroupsCreateInput,
	update?:LocaleWithoutIconGroupsUpdateInput,
	upsert?:IconGroupLocaleUpsertLocaleRelationInput,
	connect?:LocaleUniqueWhere
}

export type IconGroupLocaleUpdateResult = {
	__typename?: "IconGroupLocaleUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconGroupLocale,
	validation:_ValidationResult
}

export type IconGroupLocaleUpdateRootEntityRelationInput = {
		create?:IconGroupWithoutLocalesCreateInput,
	update?:IconGroupWithoutLocalesUpdateInput,
	upsert?:IconGroupLocaleUpsertRootRelationInput,
	connect?:IconGroupUniqueWhere
}

export type IconGroupLocaleUpsertLocaleRelationInput = {
		update?:LocaleWithoutIconGroupsUpdateInput,
	create?:LocaleWithoutIconGroupsCreateInput
}

export type IconGroupLocaleUpsertRootRelationInput = {
		update?:IconGroupWithoutLocalesUpdateInput,
	create?:IconGroupWithoutLocalesCreateInput
}

export type IconGroupLocaleWhere = {
		id?:UUIDCondition,
	title?:StringCondition,
	root?:IconGroupWhere,
	locale?:LocaleWhere,
	and?:IconGroupLocaleWhere[],
	or?:IconGroupLocaleWhere[],
	not?:IconGroupLocaleWhere
}

export type IconGroupLocaleWithoutLocaleCreateInput = {
		title?:string,
	root?:IconGroupLocaleCreateRootEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconGroupLocaleWithoutLocaleUpdateInput = {
		title?:string,
	root?:IconGroupLocaleUpdateRootEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconGroupLocaleWithoutRootCreateInput = {
		title?:string,
	locale?:IconGroupLocaleCreateLocaleEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconGroupLocaleWithoutRootUpdateInput = {
		title?:string,
	locale?:IconGroupLocaleUpdateLocaleEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconGroupMeta = {
	__typename?: "IconGroupMeta",
	id?:FieldMeta,
	icons?:FieldMeta,
	locales?:FieldMeta,
	title?:FieldMeta
}

export type IconGroupOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	icons?:IconListOrderBy,
	title?:OrderDirection
}

export type IconGroupUniqueWhere = {
		id?:UUID,
	locales?:IconGroupLocaleUniqueWhere
}

export type IconGroupUpdateIconsEntityRelationInput = {
		create?:IconListCreateInput,
	update?:IconListUpdateInput,
	upsert?:IconGroupUpsertIconsRelationInput,
	connect?:IconListUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type IconGroupUpdateInput = {
		icons?:IconGroupUpdateIconsEntityRelationInput,
	locales?:IconGroupUpdateLocalesEntityRelationInput[],
	title?:string,
	_dummy_field_?:boolean
}

export type IconGroupUpdateLocalesEntityRelationInput = {
		create?:IconGroupLocaleWithoutRootCreateInput,
	update?:IconGroupUpdateLocalesRelationInput,
	upsert?:IconGroupUpsertLocalesRelationInput,
	connect?:IconGroupLocaleUniqueWhere,
	disconnect?:IconGroupLocaleUniqueWhere,
	delete?:IconGroupLocaleUniqueWhere,
	alias?:string
}

export type IconGroupUpdateLocalesRelationInput = {
		by?:IconGroupLocaleUniqueWhere,
	data?:IconGroupLocaleWithoutRootUpdateInput
}

export type IconGroupUpdateResult = {
	__typename?: "IconGroupUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconGroup,
	validation:_ValidationResult
}

export type IconGroupUpsertIconsRelationInput = {
		update?:IconListUpdateInput,
	create?:IconListCreateInput
}

export type IconGroupUpsertLocalesRelationInput = {
		by?:IconGroupLocaleUniqueWhere,
	update?:IconGroupLocaleWithoutRootUpdateInput,
	create?:IconGroupLocaleWithoutRootCreateInput
}

export type IconGroupWhere = {
		id?:UUIDCondition,
	icons?:IconListWhere,
	locales?:IconGroupLocaleWhere,
	title?:StringCondition,
	and?:IconGroupWhere[],
	or?:IconGroupWhere[],
	not?:IconGroupWhere
}

export type IconGroupWithoutLocalesCreateInput = {
		icons?:IconGroupCreateIconsEntityRelationInput,
	title?:string,
	_dummy_field_?:boolean
}

export type IconGroupWithoutLocalesUpdateInput = {
		icons?:IconGroupUpdateIconsEntityRelationInput,
	title?:string,
	_dummy_field_?:boolean
}

export type IconList = {
	__typename?: "IconList",
	_meta?:IconListMeta,
	id:UUID,
	items:IconListItem[]
}

export type IconListBlock = {
	__typename?: "IconListBlock",
	_meta?:IconListBlockMeta,
	id:UUID,
	items:IconListBlockItem[]
}

export type IconListBlockConnection = {
	__typename?: "IconListBlockConnection",
	pageInfo:PageInfo,
	edges:IconListBlockEdge[]
}

export type IconListBlockCreateInput = {
		items?:IconListBlockCreateItemsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type IconListBlockCreateItemsEntityRelationInput = {
		connect?:IconListBlockItemUniqueWhere,
	create?:IconListBlockItemWithoutListCreateInput,
	alias?:string
}

export type IconListBlockCreateResult = {
	__typename?: "IconListBlockCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconListBlock,
	validation:_ValidationResult
}

export type IconListBlockDeleteResult = {
	__typename?: "IconListBlockDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconListBlock
}

export type IconListBlockEdge = {
	__typename?: "IconListBlockEdge",
	node:IconListBlock
}

export type IconListBlockItem = {
	__typename?: "IconListBlockItem",
	_meta?:IconListBlockItemMeta,
	id:UUID,
	order:number,
	text?:string,
	list?:IconListBlock,
	icon?:Image
}

export type IconListBlockItemConnection = {
	__typename?: "IconListBlockItemConnection",
	pageInfo:PageInfo,
	edges:IconListBlockItemEdge[]
}

export type IconListBlockItemCreateIconEntityRelationInput = {
		connect?:ImageUniqueWhere,
	create?:ImageCreateInput
}

export type IconListBlockItemCreateInput = {
		order?:number,
	text?:string,
	list?:IconListBlockItemCreateListEntityRelationInput,
	icon?:IconListBlockItemCreateIconEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconListBlockItemCreateListEntityRelationInput = {
		connect?:IconListBlockUniqueWhere,
	create?:IconListBlockWithoutItemsCreateInput
}

export type IconListBlockItemCreateResult = {
	__typename?: "IconListBlockItemCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconListBlockItem,
	validation:_ValidationResult
}

export type IconListBlockItemDeleteResult = {
	__typename?: "IconListBlockItemDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconListBlockItem
}

export type IconListBlockItemEdge = {
	__typename?: "IconListBlockItemEdge",
	node:IconListBlockItem
}

export type IconListBlockItemMeta = {
	__typename?: "IconListBlockItemMeta",
	id?:FieldMeta,
	order?:FieldMeta,
	text?:FieldMeta,
	list?:FieldMeta,
	icon?:FieldMeta
}

export type IconListBlockItemOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	order?:OrderDirection,
	text?:OrderDirection,
	list?:IconListBlockOrderBy,
	icon?:ImageOrderBy
}

export type IconListBlockItemUniqueWhere = {
		id?:UUID
}

export type IconListBlockItemUpdateIconEntityRelationInput = {
		create?:ImageCreateInput,
	update?:ImageUpdateInput,
	upsert?:IconListBlockItemUpsertIconRelationInput,
	connect?:ImageUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type IconListBlockItemUpdateInput = {
		order?:number,
	text?:string,
	list?:IconListBlockItemUpdateListEntityRelationInput,
	icon?:IconListBlockItemUpdateIconEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconListBlockItemUpdateListEntityRelationInput = {
		create?:IconListBlockWithoutItemsCreateInput,
	update?:IconListBlockWithoutItemsUpdateInput,
	upsert?:IconListBlockItemUpsertListRelationInput,
	connect?:IconListBlockUniqueWhere
}

export type IconListBlockItemUpdateResult = {
	__typename?: "IconListBlockItemUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconListBlockItem,
	validation:_ValidationResult
}

export type IconListBlockItemUpsertIconRelationInput = {
		update?:ImageUpdateInput,
	create?:ImageCreateInput
}

export type IconListBlockItemUpsertListRelationInput = {
		update?:IconListBlockWithoutItemsUpdateInput,
	create?:IconListBlockWithoutItemsCreateInput
}

export type IconListBlockItemWhere = {
		id?:UUIDCondition,
	order?:IntCondition,
	text?:StringCondition,
	list?:IconListBlockWhere,
	icon?:ImageWhere,
	and?:IconListBlockItemWhere[],
	or?:IconListBlockItemWhere[],
	not?:IconListBlockItemWhere
}

export type IconListBlockItemWithoutListCreateInput = {
		order?:number,
	text?:string,
	icon?:IconListBlockItemCreateIconEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconListBlockItemWithoutListUpdateInput = {
		order?:number,
	text?:string,
	icon?:IconListBlockItemUpdateIconEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconListBlockMeta = {
	__typename?: "IconListBlockMeta",
	id?:FieldMeta,
	items?:FieldMeta
}

export type IconListBlockOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection
}

export type IconListBlockUniqueWhere = {
		id?:UUID,
	items?:IconListBlockItemUniqueWhere
}

export type IconListBlockUpdateInput = {
		items?:IconListBlockUpdateItemsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type IconListBlockUpdateItemsEntityRelationInput = {
		create?:IconListBlockItemWithoutListCreateInput,
	update?:IconListBlockUpdateItemsRelationInput,
	upsert?:IconListBlockUpsertItemsRelationInput,
	connect?:IconListBlockItemUniqueWhere,
	disconnect?:IconListBlockItemUniqueWhere,
	delete?:IconListBlockItemUniqueWhere,
	alias?:string
}

export type IconListBlockUpdateItemsRelationInput = {
		by?:IconListBlockItemUniqueWhere,
	data?:IconListBlockItemWithoutListUpdateInput
}

export type IconListBlockUpdateResult = {
	__typename?: "IconListBlockUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconListBlock,
	validation:_ValidationResult
}

export type IconListBlockUpsertItemsRelationInput = {
		by?:IconListBlockItemUniqueWhere,
	update?:IconListBlockItemWithoutListUpdateInput,
	create?:IconListBlockItemWithoutListCreateInput
}

export type IconListBlockWhere = {
		id?:UUIDCondition,
	items?:IconListBlockItemWhere,
	and?:IconListBlockWhere[],
	or?:IconListBlockWhere[],
	not?:IconListBlockWhere
}

export type IconListBlockWithoutItemsCreateInput = {
		_dummy_field_?:boolean
}

export type IconListBlockWithoutItemsUpdateInput = {
		_dummy_field_?:boolean
}

export type IconListConnection = {
	__typename?: "IconListConnection",
	pageInfo:PageInfo,
	edges:IconListEdge[]
}

export type IconListCreateInput = {
		items?:IconListCreateItemsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type IconListCreateItemsEntityRelationInput = {
		connect?:IconListItemUniqueWhere,
	create?:IconListItemWithoutListCreateInput,
	alias?:string
}

export type IconListCreateResult = {
	__typename?: "IconListCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconList,
	validation:_ValidationResult
}

export type IconListDeleteResult = {
	__typename?: "IconListDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconList
}

export type IconListEdge = {
	__typename?: "IconListEdge",
	node:IconList
}

export type IconListItem = {
	__typename?: "IconListItem",
	_meta?:IconListItemMeta,
	id:UUID,
	order:number,
	list?:IconList,
	icon?:Icon
}

export type IconListItemConnection = {
	__typename?: "IconListItemConnection",
	pageInfo:PageInfo,
	edges:IconListItemEdge[]
}

export type IconListItemCreateIconEntityRelationInput = {
		connect?:IconUniqueWhere,
	create?:IconCreateInput
}

export type IconListItemCreateInput = {
		order?:number,
	list?:IconListItemCreateListEntityRelationInput,
	icon?:IconListItemCreateIconEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconListItemCreateListEntityRelationInput = {
		connect?:IconListUniqueWhere,
	create?:IconListWithoutItemsCreateInput
}

export type IconListItemCreateResult = {
	__typename?: "IconListItemCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconListItem,
	validation:_ValidationResult
}

export type IconListItemDeleteResult = {
	__typename?: "IconListItemDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconListItem
}

export type IconListItemEdge = {
	__typename?: "IconListItemEdge",
	node:IconListItem
}

export type IconListItemMeta = {
	__typename?: "IconListItemMeta",
	id?:FieldMeta,
	order?:FieldMeta,
	list?:FieldMeta,
	icon?:FieldMeta
}

export type IconListItemOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	order?:OrderDirection,
	list?:IconListOrderBy,
	icon?:IconOrderBy
}

export type IconListItemUniqueWhere = {
		id?:UUID
}

export type IconListItemUpdateIconEntityRelationInput = {
		create?:IconCreateInput,
	update?:IconUpdateInput,
	upsert?:IconListItemUpsertIconRelationInput,
	connect?:IconUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type IconListItemUpdateInput = {
		order?:number,
	list?:IconListItemUpdateListEntityRelationInput,
	icon?:IconListItemUpdateIconEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconListItemUpdateListEntityRelationInput = {
		create?:IconListWithoutItemsCreateInput,
	update?:IconListWithoutItemsUpdateInput,
	upsert?:IconListItemUpsertListRelationInput,
	connect?:IconListUniqueWhere
}

export type IconListItemUpdateResult = {
	__typename?: "IconListItemUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconListItem,
	validation:_ValidationResult
}

export type IconListItemUpsertIconRelationInput = {
		update?:IconUpdateInput,
	create?:IconCreateInput
}

export type IconListItemUpsertListRelationInput = {
		update?:IconListWithoutItemsUpdateInput,
	create?:IconListWithoutItemsCreateInput
}

export type IconListItemWhere = {
		id?:UUIDCondition,
	order?:IntCondition,
	list?:IconListWhere,
	icon?:IconWhere,
	and?:IconListItemWhere[],
	or?:IconListItemWhere[],
	not?:IconListItemWhere
}

export type IconListItemWithoutListCreateInput = {
		order?:number,
	icon?:IconListItemCreateIconEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconListItemWithoutListUpdateInput = {
		order?:number,
	icon?:IconListItemUpdateIconEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconListMeta = {
	__typename?: "IconListMeta",
	id?:FieldMeta,
	items?:FieldMeta
}

export type IconListOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection
}

export type IconListUniqueWhere = {
		id?:UUID,
	items?:IconListItemUniqueWhere
}

export type IconListUpdateInput = {
		items?:IconListUpdateItemsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type IconListUpdateItemsEntityRelationInput = {
		create?:IconListItemWithoutListCreateInput,
	update?:IconListUpdateItemsRelationInput,
	upsert?:IconListUpsertItemsRelationInput,
	connect?:IconListItemUniqueWhere,
	disconnect?:IconListItemUniqueWhere,
	delete?:IconListItemUniqueWhere,
	alias?:string
}

export type IconListUpdateItemsRelationInput = {
		by?:IconListItemUniqueWhere,
	data?:IconListItemWithoutListUpdateInput
}

export type IconListUpdateResult = {
	__typename?: "IconListUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconList,
	validation:_ValidationResult
}

export type IconListUpsertItemsRelationInput = {
		by?:IconListItemUniqueWhere,
	update?:IconListItemWithoutListUpdateInput,
	create?:IconListItemWithoutListCreateInput
}

export type IconListWhere = {
		id?:UUIDCondition,
	items?:IconListItemWhere,
	and?:IconListWhere[],
	or?:IconListWhere[],
	not?:IconListWhere
}

export type IconListWithoutItemsCreateInput = {
		_dummy_field_?:boolean
}

export type IconListWithoutItemsUpdateInput = {
		_dummy_field_?:boolean
}

export type IconLocale = {
	__typename?: "IconLocale",
	_meta?:IconLocaleMeta,
	id:UUID,
	title?:string,
	alt?:string,
	root?:Icon,
	locale?:Locale
}

export type IconLocaleConnection = {
	__typename?: "IconLocaleConnection",
	pageInfo:PageInfo,
	edges:IconLocaleEdge[]
}

export type IconLocaleCreateInput = {
		title?:string,
	alt?:string,
	root?:IconLocaleCreateRootEntityRelationInput,
	locale?:IconLocaleCreateLocaleEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconLocaleCreateLocaleEntityRelationInput = {
		connect?:LocaleUniqueWhere,
	create?:LocaleWithoutIconsCreateInput
}

export type IconLocaleCreateResult = {
	__typename?: "IconLocaleCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconLocale,
	validation:_ValidationResult
}

export type IconLocaleCreateRootEntityRelationInput = {
		connect?:IconUniqueWhere,
	create?:IconWithoutLocalesCreateInput
}

export type IconLocaleDeleteResult = {
	__typename?: "IconLocaleDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconLocale
}

export type IconLocaleEdge = {
	__typename?: "IconLocaleEdge",
	node:IconLocale
}

export type IconLocaleMeta = {
	__typename?: "IconLocaleMeta",
	id?:FieldMeta,
	title?:FieldMeta,
	alt?:FieldMeta,
	root?:FieldMeta,
	locale?:FieldMeta
}

export type IconLocaleOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	title?:OrderDirection,
	alt?:OrderDirection,
	root?:IconOrderBy,
	locale?:LocaleOrderBy
}

export type IconLocalesByLocaleUniqueWhere = {
		locale?:LocaleUniqueWhere
}

export type IconLocaleUniqueWhere = {
		id?:UUID,
	root?:IconUniqueWhere,
	locale?:LocaleUniqueWhere
}

export type IconLocaleUpdateInput = {
		title?:string,
	alt?:string,
	root?:IconLocaleUpdateRootEntityRelationInput,
	locale?:IconLocaleUpdateLocaleEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconLocaleUpdateLocaleEntityRelationInput = {
		create?:LocaleWithoutIconsCreateInput,
	update?:LocaleWithoutIconsUpdateInput,
	upsert?:IconLocaleUpsertLocaleRelationInput,
	connect?:LocaleUniqueWhere
}

export type IconLocaleUpdateResult = {
	__typename?: "IconLocaleUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:IconLocale,
	validation:_ValidationResult
}

export type IconLocaleUpdateRootEntityRelationInput = {
		create?:IconWithoutLocalesCreateInput,
	update?:IconWithoutLocalesUpdateInput,
	upsert?:IconLocaleUpsertRootRelationInput,
	connect?:IconUniqueWhere
}

export type IconLocaleUpsertLocaleRelationInput = {
		update?:LocaleWithoutIconsUpdateInput,
	create?:LocaleWithoutIconsCreateInput
}

export type IconLocaleUpsertRootRelationInput = {
		update?:IconWithoutLocalesUpdateInput,
	create?:IconWithoutLocalesCreateInput
}

export type IconLocaleWhere = {
		id?:UUIDCondition,
	title?:StringCondition,
	alt?:StringCondition,
	root?:IconWhere,
	locale?:LocaleWhere,
	and?:IconLocaleWhere[],
	or?:IconLocaleWhere[],
	not?:IconLocaleWhere
}

export type IconLocaleWithoutLocaleCreateInput = {
		title?:string,
	alt?:string,
	root?:IconLocaleCreateRootEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconLocaleWithoutLocaleUpdateInput = {
		title?:string,
	alt?:string,
	root?:IconLocaleUpdateRootEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconLocaleWithoutRootCreateInput = {
		title?:string,
	alt?:string,
	locale?:IconLocaleCreateLocaleEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconLocaleWithoutRootUpdateInput = {
		title?:string,
	alt?:string,
	locale?:IconLocaleUpdateLocaleEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconMeta = {
	__typename?: "IconMeta",
	id?:FieldMeta,
	key?:FieldMeta,
	image?:FieldMeta,
	locales?:FieldMeta
}

export type IconOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	key?:OrderDirection,
	image?:ImageOrderBy
}

export type IconUniqueWhere = {
		id?:UUID,
	locales?:IconLocaleUniqueWhere
}

export type IconUpdateImageEntityRelationInput = {
		create?:ImageCreateInput,
	update?:ImageUpdateInput,
	upsert?:IconUpsertImageRelationInput,
	connect?:ImageUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type IconUpdateInput = {
		key?:string,
	image?:IconUpdateImageEntityRelationInput,
	locales?:IconUpdateLocalesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type IconUpdateLocalesEntityRelationInput = {
		create?:IconLocaleWithoutRootCreateInput,
	update?:IconUpdateLocalesRelationInput,
	upsert?:IconUpsertLocalesRelationInput,
	connect?:IconLocaleUniqueWhere,
	disconnect?:IconLocaleUniqueWhere,
	delete?:IconLocaleUniqueWhere,
	alias?:string
}

export type IconUpdateLocalesRelationInput = {
		by?:IconLocaleUniqueWhere,
	data?:IconLocaleWithoutRootUpdateInput
}

export type IconUpdateResult = {
	__typename?: "IconUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Icon,
	validation:_ValidationResult
}

export type IconUpsertImageRelationInput = {
		update?:ImageUpdateInput,
	create?:ImageCreateInput
}

export type IconUpsertLocalesRelationInput = {
		by?:IconLocaleUniqueWhere,
	update?:IconLocaleWithoutRootUpdateInput,
	create?:IconLocaleWithoutRootCreateInput
}

export type IconWhere = {
		id?:UUIDCondition,
	key?:StringCondition,
	image?:ImageWhere,
	locales?:IconLocaleWhere,
	and?:IconWhere[],
	or?:IconWhere[],
	not?:IconWhere
}

export type IconWithoutLocalesCreateInput = {
		key?:string,
	image?:IconCreateImageEntityRelationInput,
	_dummy_field_?:boolean
}

export type IconWithoutLocalesUpdateInput = {
		key?:string,
	image?:IconUpdateImageEntityRelationInput,
	_dummy_field_?:boolean
}

export type Image = {
	__typename?: "Image",
	_meta?:ImageMeta,
	id:UUID,
	url:string,
	width?:number,
	height?:number,
	size?:number,
	type?:string,
	title?:string,
	alt?:string,
	decorationPreset?:string
}

export type ImageConnection = {
	__typename?: "ImageConnection",
	pageInfo:PageInfo,
	edges:ImageEdge[]
}

export type ImageCreateInput = {
		url?:string,
	width?:number,
	height?:number,
	size?:number,
	type?:string,
	title?:string,
	alt?:string,
	decorationPreset?:string,
	_dummy_field_?:boolean
}

export type ImageCreateResult = {
	__typename?: "ImageCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Image,
	validation:_ValidationResult
}

export type ImageDeleteResult = {
	__typename?: "ImageDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Image
}

export type ImageEdge = {
	__typename?: "ImageEdge",
	node:Image
}

export type ImageList = {
	__typename?: "ImageList",
	_meta?:ImageListMeta,
	id:UUID,
	images:ImageXImageList[]
}

export type ImageListConnection = {
	__typename?: "ImageListConnection",
	pageInfo:PageInfo,
	edges:ImageListEdge[]
}

export type ImageListCreateImagesEntityRelationInput = {
		connect?:ImageXImageListUniqueWhere,
	create?:ImageXImageListWithoutImageListCreateInput,
	alias?:string
}

export type ImageListCreateInput = {
		images?:ImageListCreateImagesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type ImageListCreateResult = {
	__typename?: "ImageListCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ImageList,
	validation:_ValidationResult
}

export type ImageListDeleteResult = {
	__typename?: "ImageListDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ImageList
}

export type ImageListEdge = {
	__typename?: "ImageListEdge",
	node:ImageList
}

export type ImageListMeta = {
	__typename?: "ImageListMeta",
	id?:FieldMeta,
	images?:FieldMeta
}

export type ImageListOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection
}

export type ImageListUniqueWhere = {
		id?:UUID,
	images?:ImageXImageListUniqueWhere
}

export type ImageListUpdateImagesEntityRelationInput = {
		create?:ImageXImageListWithoutImageListCreateInput,
	update?:ImageListUpdateImagesRelationInput,
	upsert?:ImageListUpsertImagesRelationInput,
	connect?:ImageXImageListUniqueWhere,
	disconnect?:ImageXImageListUniqueWhere,
	delete?:ImageXImageListUniqueWhere,
	alias?:string
}

export type ImageListUpdateImagesRelationInput = {
		by?:ImageXImageListUniqueWhere,
	data?:ImageXImageListWithoutImageListUpdateInput
}

export type ImageListUpdateInput = {
		images?:ImageListUpdateImagesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type ImageListUpdateResult = {
	__typename?: "ImageListUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ImageList,
	validation:_ValidationResult
}

export type ImageListUpsertImagesRelationInput = {
		by?:ImageXImageListUniqueWhere,
	update?:ImageXImageListWithoutImageListUpdateInput,
	create?:ImageXImageListWithoutImageListCreateInput
}

export type ImageListWhere = {
		id?:UUIDCondition,
	images?:ImageXImageListWhere,
	and?:ImageListWhere[],
	or?:ImageListWhere[],
	not?:ImageListWhere
}

export type ImageListWithoutImagesCreateInput = {
		_dummy_field_?:boolean
}

export type ImageListWithoutImagesUpdateInput = {
		_dummy_field_?:boolean
}

export type ImageLocale = {
	__typename?: "ImageLocale",
	_meta?:ImageLocaleMeta,
	id:UUID,
	url:string,
	width?:number,
	height?:number,
	size?:number,
	type?:string,
	title?:string,
	alt?:string,
	decorationPreset?:string
}

export type ImageLocaleConnection = {
	__typename?: "ImageLocaleConnection",
	pageInfo:PageInfo,
	edges:ImageLocaleEdge[]
}

export type ImageLocaleCreateInput = {
		url?:string,
	width?:number,
	height?:number,
	size?:number,
	type?:string,
	title?:string,
	alt?:string,
	decorationPreset?:string,
	_dummy_field_?:boolean
}

export type ImageLocaleCreateResult = {
	__typename?: "ImageLocaleCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ImageLocale,
	validation:_ValidationResult
}

export type ImageLocaleDeleteResult = {
	__typename?: "ImageLocaleDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ImageLocale
}

export type ImageLocaleEdge = {
	__typename?: "ImageLocaleEdge",
	node:ImageLocale
}

export type ImageLocaleMeta = {
	__typename?: "ImageLocaleMeta",
	id?:FieldMeta,
	url?:FieldMeta,
	width?:FieldMeta,
	height?:FieldMeta,
	size?:FieldMeta,
	type?:FieldMeta,
	title?:FieldMeta,
	alt?:FieldMeta,
	decorationPreset?:FieldMeta
}

export type ImageLocaleOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	url?:OrderDirection,
	width?:OrderDirection,
	height?:OrderDirection,
	size?:OrderDirection,
	type?:OrderDirection,
	title?:OrderDirection,
	alt?:OrderDirection,
	decorationPreset?:OrderDirection
}

export type ImageLocaleUniqueWhere = {
		id?:UUID
}

export type ImageLocaleUpdateInput = {
		url?:string,
	width?:number,
	height?:number,
	size?:number,
	type?:string,
	title?:string,
	alt?:string,
	decorationPreset?:string,
	_dummy_field_?:boolean
}

export type ImageLocaleUpdateResult = {
	__typename?: "ImageLocaleUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ImageLocale,
	validation:_ValidationResult
}

export type ImageLocaleWhere = {
		id?:UUIDCondition,
	url?:StringCondition,
	width?:IntCondition,
	height?:IntCondition,
	size?:IntCondition,
	type?:StringCondition,
	title?:StringCondition,
	alt?:StringCondition,
	decorationPreset?:StringCondition,
	and?:ImageLocaleWhere[],
	or?:ImageLocaleWhere[],
	not?:ImageLocaleWhere
}

export type ImageMeta = {
	__typename?: "ImageMeta",
	id?:FieldMeta,
	url?:FieldMeta,
	width?:FieldMeta,
	height?:FieldMeta,
	size?:FieldMeta,
	type?:FieldMeta,
	title?:FieldMeta,
	alt?:FieldMeta,
	decorationPreset?:FieldMeta
}

export type ImageOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	url?:OrderDirection,
	width?:OrderDirection,
	height?:OrderDirection,
	size?:OrderDirection,
	type?:OrderDirection,
	title?:OrderDirection,
	alt?:OrderDirection,
	decorationPreset?:OrderDirection
}

export type ImageUniqueWhere = {
		id?:UUID
}

export type ImageUpdateInput = {
		url?:string,
	width?:number,
	height?:number,
	size?:number,
	type?:string,
	title?:string,
	alt?:string,
	decorationPreset?:string,
	_dummy_field_?:boolean
}

export type ImageUpdateResult = {
	__typename?: "ImageUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Image,
	validation:_ValidationResult
}

export type ImageWhere = {
		id?:UUIDCondition,
	url?:StringCondition,
	width?:IntCondition,
	height?:IntCondition,
	size?:IntCondition,
	type?:StringCondition,
	title?:StringCondition,
	alt?:StringCondition,
	decorationPreset?:StringCondition,
	and?:ImageWhere[],
	or?:ImageWhere[],
	not?:ImageWhere
}

export type ImageXImageList = {
	__typename?: "ImageXImageList",
	_meta?:ImageXImageListMeta,
	id:UUID,
	imagePosition?:number,
	imageList?:ImageList,
	image?:Image
}

export type ImageXImageListConnection = {
	__typename?: "ImageXImageListConnection",
	pageInfo:PageInfo,
	edges:ImageXImageListEdge[]
}

export type ImageXImageListCreateImageEntityRelationInput = {
		connect?:ImageUniqueWhere,
	create?:ImageCreateInput
}

export type ImageXImageListCreateImageListEntityRelationInput = {
		connect?:ImageListUniqueWhere,
	create?:ImageListWithoutImagesCreateInput
}

export type ImageXImageListCreateInput = {
		imagePosition?:number,
	imageList?:ImageXImageListCreateImageListEntityRelationInput,
	image?:ImageXImageListCreateImageEntityRelationInput,
	_dummy_field_?:boolean
}

export type ImageXImageListCreateResult = {
	__typename?: "ImageXImageListCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ImageXImageList,
	validation:_ValidationResult
}

export type ImageXImageListDeleteResult = {
	__typename?: "ImageXImageListDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ImageXImageList
}

export type ImageXImageListEdge = {
	__typename?: "ImageXImageListEdge",
	node:ImageXImageList
}

export type ImageXImageListMeta = {
	__typename?: "ImageXImageListMeta",
	id?:FieldMeta,
	imagePosition?:FieldMeta,
	imageList?:FieldMeta,
	image?:FieldMeta
}

export type ImageXImageListOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	imagePosition?:OrderDirection,
	imageList?:ImageListOrderBy,
	image?:ImageOrderBy
}

export type ImageXImageListUniqueWhere = {
		id?:UUID
}

export type ImageXImageListUpdateImageEntityRelationInput = {
		create?:ImageCreateInput,
	update?:ImageUpdateInput,
	upsert?:ImageXImageListUpsertImageRelationInput,
	connect?:ImageUniqueWhere
}

export type ImageXImageListUpdateImageListEntityRelationInput = {
		create?:ImageListWithoutImagesCreateInput,
	update?:ImageListWithoutImagesUpdateInput,
	upsert?:ImageXImageListUpsertImageListRelationInput,
	connect?:ImageListUniqueWhere
}

export type ImageXImageListUpdateInput = {
		imagePosition?:number,
	imageList?:ImageXImageListUpdateImageListEntityRelationInput,
	image?:ImageXImageListUpdateImageEntityRelationInput,
	_dummy_field_?:boolean
}

export type ImageXImageListUpdateResult = {
	__typename?: "ImageXImageListUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ImageXImageList,
	validation:_ValidationResult
}

export type ImageXImageListUpsertImageListRelationInput = {
		update?:ImageListWithoutImagesUpdateInput,
	create?:ImageListWithoutImagesCreateInput
}

export type ImageXImageListUpsertImageRelationInput = {
		update?:ImageUpdateInput,
	create?:ImageCreateInput
}

export type ImageXImageListWhere = {
		id?:UUIDCondition,
	imagePosition?:IntCondition,
	imageList?:ImageListWhere,
	image?:ImageWhere,
	and?:ImageXImageListWhere[],
	or?:ImageXImageListWhere[],
	not?:ImageXImageListWhere
}

export type ImageXImageListWithoutImageListCreateInput = {
		imagePosition?:number,
	image?:ImageXImageListCreateImageEntityRelationInput,
	_dummy_field_?:boolean
}

export type ImageXImageListWithoutImageListUpdateInput = {
		imagePosition?:number,
	image?:ImageXImageListUpdateImageEntityRelationInput,
	_dummy_field_?:boolean
}

export type Info = {
	__typename?: "Info",
	description?:string
}

export type IntCondition = {
		and?:IntCondition[],
	or?:IntCondition[],
	not?:IntCondition,
	eq?:number,
	null?:boolean,
	isNull?:boolean,
	notEq?:number,
	in?:number[],
	notIn?:number[],
	lt?:number,
	lte?:number,
	gt?:number,
	gte?:number
}

export type Link = {
	__typename?: "Link",
	_meta?:LinkMeta,
	id:UUID,
	title?:string,
	externalLink?:string,
	internalLink?:Linkable
}

export type Linkable = {
	__typename?: "Linkable",
	_meta?:LinkableMeta,
	id:UUID,
	url:string,
	page?:PageLocale
}

export type LinkableConnection = {
	__typename?: "LinkableConnection",
	pageInfo:PageInfo,
	edges:LinkableEdge[]
}

export type LinkableCreateInput = {
		url?:string,
	page?:LinkableCreatePageEntityRelationInput,
	_dummy_field_?:boolean
}

export type LinkableCreatePageEntityRelationInput = {
		connect?:PageLocaleUniqueWhere,
	create?:PageLocaleWithoutLinkCreateInput
}

export type LinkableCreateResult = {
	__typename?: "LinkableCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Linkable,
	validation:_ValidationResult
}

export type LinkableDeleteResult = {
	__typename?: "LinkableDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Linkable
}

export type LinkableEdge = {
	__typename?: "LinkableEdge",
	node:Linkable
}

export type LinkableMeta = {
	__typename?: "LinkableMeta",
	id?:FieldMeta,
	url?:FieldMeta,
	page?:FieldMeta
}

export type LinkableOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	url?:OrderDirection,
	page?:PageLocaleOrderBy
}

export type LinkableUniqueWhere = {
		id?:UUID,
	url?:string,
	page?:PageLocaleUniqueWhere
}

export type LinkableUpdateInput = {
		url?:string,
	page?:LinkableUpdatePageEntityRelationInput,
	_dummy_field_?:boolean
}

export type LinkableUpdatePageEntityRelationInput = {
		create?:PageLocaleWithoutLinkCreateInput,
	update?:PageLocaleWithoutLinkUpdateInput,
	upsert?:LinkableUpsertPageRelationInput,
	connect?:PageLocaleUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type LinkableUpdateResult = {
	__typename?: "LinkableUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Linkable,
	validation:_ValidationResult
}

export type LinkableUpsertPageRelationInput = {
		update?:PageLocaleWithoutLinkUpdateInput,
	create?:PageLocaleWithoutLinkCreateInput
}

export type LinkableWhere = {
		id?:UUIDCondition,
	url?:StringCondition,
	page?:PageLocaleWhere,
	and?:LinkableWhere[],
	or?:LinkableWhere[],
	not?:LinkableWhere
}

export type LinkableWithoutPageCreateInput = {
		url?:string,
	_dummy_field_?:boolean
}

export type LinkableWithoutPageUpdateInput = {
		url?:string,
	_dummy_field_?:boolean
}

export type LinkConnection = {
	__typename?: "LinkConnection",
	pageInfo:PageInfo,
	edges:LinkEdge[]
}

export type LinkCreateInput = {
		title?:string,
	externalLink?:string,
	internalLink?:LinkCreateInternalLinkEntityRelationInput,
	_dummy_field_?:boolean
}

export type LinkCreateInternalLinkEntityRelationInput = {
		connect?:LinkableUniqueWhere,
	create?:LinkableCreateInput
}

export type LinkCreateResult = {
	__typename?: "LinkCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Link,
	validation:_ValidationResult
}

export type LinkDeleteResult = {
	__typename?: "LinkDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Link
}

export type LinkEdge = {
	__typename?: "LinkEdge",
	node:Link
}

export type LinkMeta = {
	__typename?: "LinkMeta",
	id?:FieldMeta,
	title?:FieldMeta,
	externalLink?:FieldMeta,
	internalLink?:FieldMeta
}

export type LinkOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	title?:OrderDirection,
	externalLink?:OrderDirection,
	internalLink?:LinkableOrderBy
}

export type LinkUniqueWhere = {
		id?:UUID
}

export type LinkUpdateInput = {
		title?:string,
	externalLink?:string,
	internalLink?:LinkUpdateInternalLinkEntityRelationInput,
	_dummy_field_?:boolean
}

export type LinkUpdateInternalLinkEntityRelationInput = {
		create?:LinkableCreateInput,
	update?:LinkableUpdateInput,
	upsert?:LinkUpsertInternalLinkRelationInput,
	connect?:LinkableUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type LinkUpdateResult = {
	__typename?: "LinkUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Link,
	validation:_ValidationResult
}

export type LinkUpsertInternalLinkRelationInput = {
		update?:LinkableUpdateInput,
	create?:LinkableCreateInput
}

export type LinkWhere = {
		id?:UUIDCondition,
	title?:StringCondition,
	externalLink?:StringCondition,
	internalLink?:LinkableWhere,
	and?:LinkWhere[],
	or?:LinkWhere[],
	not?:LinkWhere
}

export type Locale = {
	__typename?: "Locale",
	_meta?:LocaleMeta,
	id:UUID,
	code:string,
	iconGroups:IconGroupLocale[],
	icons:IconLocale[],
	productVariants:ProductVariantLocale[],
	seos:SeoLocale[],
	pages:PageLocale[],
	products:ProductLocale[],
	iconGroupsByRoot?:IconGroupLocale,
	iconsByRoot?:IconLocale,
	productVariantsByRoot?:ProductVariantLocale,
	seosByRoot?:SeoLocale,
	pagesByRoot?:PageLocale,
	pagesByLink?:PageLocale,
	pagesByContent?:PageLocale,
	productsByRoot?:ProductLocale
}

export type LocaleConnection = {
	__typename?: "LocaleConnection",
	pageInfo:PageInfo,
	edges:LocaleEdge[]
}

export type LocaleCreateIconGroupsEntityRelationInput = {
		connect?:IconGroupLocaleUniqueWhere,
	create?:IconGroupLocaleWithoutLocaleCreateInput,
	alias?:string
}

export type LocaleCreateIconsEntityRelationInput = {
		connect?:IconLocaleUniqueWhere,
	create?:IconLocaleWithoutLocaleCreateInput,
	alias?:string
}

export type LocaleCreateInput = {
		code?:string,
	iconGroups?:LocaleCreateIconGroupsEntityRelationInput[],
	icons?:LocaleCreateIconsEntityRelationInput[],
	productVariants?:LocaleCreateProductVariantsEntityRelationInput[],
	seos?:LocaleCreateSeosEntityRelationInput[],
	pages?:LocaleCreatePagesEntityRelationInput[],
	products?:LocaleCreateProductsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type LocaleCreatePagesEntityRelationInput = {
		connect?:PageLocaleUniqueWhere,
	create?:PageLocaleWithoutLocaleCreateInput,
	alias?:string
}

export type LocaleCreateProductsEntityRelationInput = {
		connect?:ProductLocaleUniqueWhere,
	create?:ProductLocaleWithoutLocaleCreateInput,
	alias?:string
}

export type LocaleCreateProductVariantsEntityRelationInput = {
		connect?:ProductVariantLocaleUniqueWhere,
	create?:ProductVariantLocaleWithoutLocaleCreateInput,
	alias?:string
}

export type LocaleCreateResult = {
	__typename?: "LocaleCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Locale,
	validation:_ValidationResult
}

export type LocaleCreateSeosEntityRelationInput = {
		connect?:SeoLocaleUniqueWhere,
	create?:SeoLocaleWithoutLocaleCreateInput,
	alias?:string
}

export type LocaleDeleteResult = {
	__typename?: "LocaleDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Locale
}

export type LocaleEdge = {
	__typename?: "LocaleEdge",
	node:Locale
}

export type LocaleIconGroupsByRootUniqueWhere = {
		root?:IconGroupUniqueWhere
}

export type LocaleIconsByRootUniqueWhere = {
		root?:IconUniqueWhere
}

export type LocaleMeta = {
	__typename?: "LocaleMeta",
	id?:FieldMeta,
	code?:FieldMeta,
	iconGroups?:FieldMeta,
	icons?:FieldMeta,
	productVariants?:FieldMeta,
	seos?:FieldMeta,
	pages?:FieldMeta,
	products?:FieldMeta
}

export type LocaleOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	code?:OrderDirection
}

export type LocalePagesByContentUniqueWhere = {
		content?:ContentUniqueWhere
}

export type LocalePagesByLinkUniqueWhere = {
		link?:LinkableUniqueWhere
}

export type LocalePagesByRootUniqueWhere = {
		root?:PageUniqueWhere
}

export type LocaleProductsByRootUniqueWhere = {
		root?:ProductUniqueWhere
}

export type LocaleProductVariantsByRootUniqueWhere = {
		root?:ProductVariantUniqueWhere
}

export type LocaleSeosByRootUniqueWhere = {
		root?:SeoUniqueWhere
}

export type LocaleUniqueWhere = {
		id?:UUID,
	code?:string,
	iconGroups?:IconGroupLocaleUniqueWhere,
	icons?:IconLocaleUniqueWhere,
	productVariants?:ProductVariantLocaleUniqueWhere,
	seos?:SeoLocaleUniqueWhere,
	pages?:PageLocaleUniqueWhere,
	products?:ProductLocaleUniqueWhere
}

export type LocaleUpdateIconGroupsEntityRelationInput = {
		create?:IconGroupLocaleWithoutLocaleCreateInput,
	update?:LocaleUpdateIconGroupsRelationInput,
	upsert?:LocaleUpsertIconGroupsRelationInput,
	connect?:IconGroupLocaleUniqueWhere,
	disconnect?:IconGroupLocaleUniqueWhere,
	delete?:IconGroupLocaleUniqueWhere,
	alias?:string
}

export type LocaleUpdateIconGroupsRelationInput = {
		by?:IconGroupLocaleUniqueWhere,
	data?:IconGroupLocaleWithoutLocaleUpdateInput
}

export type LocaleUpdateIconsEntityRelationInput = {
		create?:IconLocaleWithoutLocaleCreateInput,
	update?:LocaleUpdateIconsRelationInput,
	upsert?:LocaleUpsertIconsRelationInput,
	connect?:IconLocaleUniqueWhere,
	disconnect?:IconLocaleUniqueWhere,
	delete?:IconLocaleUniqueWhere,
	alias?:string
}

export type LocaleUpdateIconsRelationInput = {
		by?:IconLocaleUniqueWhere,
	data?:IconLocaleWithoutLocaleUpdateInput
}

export type LocaleUpdateInput = {
		code?:string,
	iconGroups?:LocaleUpdateIconGroupsEntityRelationInput[],
	icons?:LocaleUpdateIconsEntityRelationInput[],
	productVariants?:LocaleUpdateProductVariantsEntityRelationInput[],
	seos?:LocaleUpdateSeosEntityRelationInput[],
	pages?:LocaleUpdatePagesEntityRelationInput[],
	products?:LocaleUpdateProductsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type LocaleUpdatePagesEntityRelationInput = {
		create?:PageLocaleWithoutLocaleCreateInput,
	update?:LocaleUpdatePagesRelationInput,
	upsert?:LocaleUpsertPagesRelationInput,
	connect?:PageLocaleUniqueWhere,
	disconnect?:PageLocaleUniqueWhere,
	delete?:PageLocaleUniqueWhere,
	alias?:string
}

export type LocaleUpdatePagesRelationInput = {
		by?:PageLocaleUniqueWhere,
	data?:PageLocaleWithoutLocaleUpdateInput
}

export type LocaleUpdateProductsEntityRelationInput = {
		create?:ProductLocaleWithoutLocaleCreateInput,
	update?:LocaleUpdateProductsRelationInput,
	upsert?:LocaleUpsertProductsRelationInput,
	connect?:ProductLocaleUniqueWhere,
	disconnect?:ProductLocaleUniqueWhere,
	delete?:ProductLocaleUniqueWhere,
	alias?:string
}

export type LocaleUpdateProductsRelationInput = {
		by?:ProductLocaleUniqueWhere,
	data?:ProductLocaleWithoutLocaleUpdateInput
}

export type LocaleUpdateProductVariantsEntityRelationInput = {
		create?:ProductVariantLocaleWithoutLocaleCreateInput,
	update?:LocaleUpdateProductVariantsRelationInput,
	upsert?:LocaleUpsertProductVariantsRelationInput,
	connect?:ProductVariantLocaleUniqueWhere,
	disconnect?:ProductVariantLocaleUniqueWhere,
	delete?:ProductVariantLocaleUniqueWhere,
	alias?:string
}

export type LocaleUpdateProductVariantsRelationInput = {
		by?:ProductVariantLocaleUniqueWhere,
	data?:ProductVariantLocaleWithoutLocaleUpdateInput
}

export type LocaleUpdateResult = {
	__typename?: "LocaleUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Locale,
	validation:_ValidationResult
}

export type LocaleUpdateSeosEntityRelationInput = {
		create?:SeoLocaleWithoutLocaleCreateInput,
	update?:LocaleUpdateSeosRelationInput,
	upsert?:LocaleUpsertSeosRelationInput,
	connect?:SeoLocaleUniqueWhere,
	disconnect?:SeoLocaleUniqueWhere,
	delete?:SeoLocaleUniqueWhere,
	alias?:string
}

export type LocaleUpdateSeosRelationInput = {
		by?:SeoLocaleUniqueWhere,
	data?:SeoLocaleWithoutLocaleUpdateInput
}

export type LocaleUpsertIconGroupsRelationInput = {
		by?:IconGroupLocaleUniqueWhere,
	update?:IconGroupLocaleWithoutLocaleUpdateInput,
	create?:IconGroupLocaleWithoutLocaleCreateInput
}

export type LocaleUpsertIconsRelationInput = {
		by?:IconLocaleUniqueWhere,
	update?:IconLocaleWithoutLocaleUpdateInput,
	create?:IconLocaleWithoutLocaleCreateInput
}

export type LocaleUpsertPagesRelationInput = {
		by?:PageLocaleUniqueWhere,
	update?:PageLocaleWithoutLocaleUpdateInput,
	create?:PageLocaleWithoutLocaleCreateInput
}

export type LocaleUpsertProductsRelationInput = {
		by?:ProductLocaleUniqueWhere,
	update?:ProductLocaleWithoutLocaleUpdateInput,
	create?:ProductLocaleWithoutLocaleCreateInput
}

export type LocaleUpsertProductVariantsRelationInput = {
		by?:ProductVariantLocaleUniqueWhere,
	update?:ProductVariantLocaleWithoutLocaleUpdateInput,
	create?:ProductVariantLocaleWithoutLocaleCreateInput
}

export type LocaleUpsertSeosRelationInput = {
		by?:SeoLocaleUniqueWhere,
	update?:SeoLocaleWithoutLocaleUpdateInput,
	create?:SeoLocaleWithoutLocaleCreateInput
}

export type LocaleWhere = {
		id?:UUIDCondition,
	code?:StringCondition,
	iconGroups?:IconGroupLocaleWhere,
	icons?:IconLocaleWhere,
	productVariants?:ProductVariantLocaleWhere,
	seos?:SeoLocaleWhere,
	pages?:PageLocaleWhere,
	products?:ProductLocaleWhere,
	and?:LocaleWhere[],
	or?:LocaleWhere[],
	not?:LocaleWhere
}

export type LocaleWithoutIconGroupsCreateInput = {
		code?:string,
	icons?:LocaleCreateIconsEntityRelationInput[],
	productVariants?:LocaleCreateProductVariantsEntityRelationInput[],
	seos?:LocaleCreateSeosEntityRelationInput[],
	pages?:LocaleCreatePagesEntityRelationInput[],
	products?:LocaleCreateProductsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type LocaleWithoutIconGroupsUpdateInput = {
		code?:string,
	icons?:LocaleUpdateIconsEntityRelationInput[],
	productVariants?:LocaleUpdateProductVariantsEntityRelationInput[],
	seos?:LocaleUpdateSeosEntityRelationInput[],
	pages?:LocaleUpdatePagesEntityRelationInput[],
	products?:LocaleUpdateProductsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type LocaleWithoutIconsCreateInput = {
		code?:string,
	iconGroups?:LocaleCreateIconGroupsEntityRelationInput[],
	productVariants?:LocaleCreateProductVariantsEntityRelationInput[],
	seos?:LocaleCreateSeosEntityRelationInput[],
	pages?:LocaleCreatePagesEntityRelationInput[],
	products?:LocaleCreateProductsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type LocaleWithoutIconsUpdateInput = {
		code?:string,
	iconGroups?:LocaleUpdateIconGroupsEntityRelationInput[],
	productVariants?:LocaleUpdateProductVariantsEntityRelationInput[],
	seos?:LocaleUpdateSeosEntityRelationInput[],
	pages?:LocaleUpdatePagesEntityRelationInput[],
	products?:LocaleUpdateProductsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type LocaleWithoutPagesCreateInput = {
		code?:string,
	iconGroups?:LocaleCreateIconGroupsEntityRelationInput[],
	icons?:LocaleCreateIconsEntityRelationInput[],
	productVariants?:LocaleCreateProductVariantsEntityRelationInput[],
	seos?:LocaleCreateSeosEntityRelationInput[],
	products?:LocaleCreateProductsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type LocaleWithoutPagesUpdateInput = {
		code?:string,
	iconGroups?:LocaleUpdateIconGroupsEntityRelationInput[],
	icons?:LocaleUpdateIconsEntityRelationInput[],
	productVariants?:LocaleUpdateProductVariantsEntityRelationInput[],
	seos?:LocaleUpdateSeosEntityRelationInput[],
	products?:LocaleUpdateProductsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type LocaleWithoutProductsCreateInput = {
		code?:string,
	iconGroups?:LocaleCreateIconGroupsEntityRelationInput[],
	icons?:LocaleCreateIconsEntityRelationInput[],
	productVariants?:LocaleCreateProductVariantsEntityRelationInput[],
	seos?:LocaleCreateSeosEntityRelationInput[],
	pages?:LocaleCreatePagesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type LocaleWithoutProductsUpdateInput = {
		code?:string,
	iconGroups?:LocaleUpdateIconGroupsEntityRelationInput[],
	icons?:LocaleUpdateIconsEntityRelationInput[],
	productVariants?:LocaleUpdateProductVariantsEntityRelationInput[],
	seos?:LocaleUpdateSeosEntityRelationInput[],
	pages?:LocaleUpdatePagesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type LocaleWithoutProductVariantsCreateInput = {
		code?:string,
	iconGroups?:LocaleCreateIconGroupsEntityRelationInput[],
	icons?:LocaleCreateIconsEntityRelationInput[],
	seos?:LocaleCreateSeosEntityRelationInput[],
	pages?:LocaleCreatePagesEntityRelationInput[],
	products?:LocaleCreateProductsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type LocaleWithoutProductVariantsUpdateInput = {
		code?:string,
	iconGroups?:LocaleUpdateIconGroupsEntityRelationInput[],
	icons?:LocaleUpdateIconsEntityRelationInput[],
	seos?:LocaleUpdateSeosEntityRelationInput[],
	pages?:LocaleUpdatePagesEntityRelationInput[],
	products?:LocaleUpdateProductsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type LocaleWithoutSeosCreateInput = {
		code?:string,
	iconGroups?:LocaleCreateIconGroupsEntityRelationInput[],
	icons?:LocaleCreateIconsEntityRelationInput[],
	productVariants?:LocaleCreateProductVariantsEntityRelationInput[],
	pages?:LocaleCreatePagesEntityRelationInput[],
	products?:LocaleCreateProductsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type LocaleWithoutSeosUpdateInput = {
		code?:string,
	iconGroups?:LocaleUpdateIconGroupsEntityRelationInput[],
	icons?:LocaleUpdateIconsEntityRelationInput[],
	productVariants?:LocaleUpdateProductVariantsEntityRelationInput[],
	pages?:LocaleUpdatePagesEntityRelationInput[],
	products?:LocaleUpdateProductsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type Media = {
	__typename?: "Media",
	_meta?:MediaMeta,
	id:UUID,
	url?:string,
	videoUrl?:string,
	width?:number,
	height?:number,
	poster?:string,
	type?:MediaItemType,
	caption?:string,
	audio?:boolean
}

export type MediaConnection = {
	__typename?: "MediaConnection",
	pageInfo:PageInfo,
	edges:MediaEdge[]
}

export type MediaCreateInput = {
		url?:string,
	videoUrl?:string,
	width?:number,
	height?:number,
	poster?:string,
	type?:MediaItemType,
	caption?:string,
	audio?:boolean,
	_dummy_field_?:boolean
}

export type MediaCreateResult = {
	__typename?: "MediaCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Media,
	validation:_ValidationResult
}

export type MediaDeleteResult = {
	__typename?: "MediaDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Media
}

export type MediaEdge = {
	__typename?: "MediaEdge",
	node:Media
}

export enum MediaItemType {
	image = "image",
	video = "video"
}

export type MediaItemTypeEnumCondition = {
		and?:MediaItemTypeEnumCondition[],
	or?:MediaItemTypeEnumCondition[],
	not?:MediaItemTypeEnumCondition,
	eq?:MediaItemType,
	null?:boolean,
	isNull?:boolean,
	notEq?:MediaItemType,
	in?:MediaItemType[],
	notIn?:MediaItemType[],
	lt?:MediaItemType,
	lte?:MediaItemType,
	gt?:MediaItemType,
	gte?:MediaItemType
}

export type MediaMeta = {
	__typename?: "MediaMeta",
	id?:FieldMeta,
	url?:FieldMeta,
	videoUrl?:FieldMeta,
	width?:FieldMeta,
	height?:FieldMeta,
	poster?:FieldMeta,
	type?:FieldMeta,
	caption?:FieldMeta,
	audio?:FieldMeta
}

export type MediaOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	url?:OrderDirection,
	videoUrl?:OrderDirection,
	width?:OrderDirection,
	height?:OrderDirection,
	poster?:OrderDirection,
	type?:OrderDirection,
	caption?:OrderDirection,
	audio?:OrderDirection
}

export type MediaUniqueWhere = {
		id?:UUID
}

export type MediaUpdateInput = {
		url?:string,
	videoUrl?:string,
	width?:number,
	height?:number,
	poster?:string,
	type?:MediaItemType,
	caption?:string,
	audio?:boolean,
	_dummy_field_?:boolean
}

export type MediaUpdateResult = {
	__typename?: "MediaUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Media,
	validation:_ValidationResult
}

export type MediaWhere = {
		id?:UUIDCondition,
	url?:StringCondition,
	videoUrl?:StringCondition,
	width?:IntCondition,
	height?:IntCondition,
	poster?:StringCondition,
	type?:MediaItemTypeEnumCondition,
	caption?:StringCondition,
	audio?:BooleanCondition,
	and?:MediaWhere[],
	or?:MediaWhere[],
	not?:MediaWhere
}

export type Mutation = {
	__typename?: "Mutation",
	createAbandonedCheckoutUnsubscription:AbandonedCheckoutUnsubscriptionCreateResult,
	deleteAbandonedCheckoutUnsubscription:AbandonedCheckoutUnsubscriptionDeleteResult,
	updateAbandonedCheckoutUnsubscription:AbandonedCheckoutUnsubscriptionUpdateResult,
	createAbandonedCheckoutNotification:AbandonedCheckoutNotificationCreateResult,
	deleteAbandonedCheckoutNotification:AbandonedCheckoutNotificationDeleteResult,
	updateAbandonedCheckoutNotification:AbandonedCheckoutNotificationUpdateResult,
	createShopifyLineItem:ShopifyLineItemCreateResult,
	deleteShopifyLineItem:ShopifyLineItemDeleteResult,
	updateShopifyLineItem:ShopifyLineItemUpdateResult,
	createShopifyOrder:ShopifyOrderCreateResult,
	deleteShopifyOrder:ShopifyOrderDeleteResult,
	updateShopifyOrder:ShopifyOrderUpdateResult,
	createTranslationValue:TranslationValueCreateResult,
	deleteTranslationValue:TranslationValueDeleteResult,
	updateTranslationValue:TranslationValueUpdateResult,
	createTranslationKey:TranslationKeyCreateResult,
	deleteTranslationKey:TranslationKeyDeleteResult,
	updateTranslationKey:TranslationKeyUpdateResult,
	createTranslationCatalogue:TranslationCatalogueCreateResult,
	deleteTranslationCatalogue:TranslationCatalogueDeleteResult,
	updateTranslationCatalogue:TranslationCatalogueUpdateResult,
	createTranslationDomain:TranslationDomainCreateResult,
	deleteTranslationDomain:TranslationDomainDeleteResult,
	updateTranslationDomain:TranslationDomainUpdateResult,
	createProductLocale:ProductLocaleCreateResult,
	deleteProductLocale:ProductLocaleDeleteResult,
	updateProductLocale:ProductLocaleUpdateResult,
	createMedia:MediaCreateResult,
	deleteMedia:MediaDeleteResult,
	updateMedia:MediaUpdateResult,
	createIconListBlockItem:IconListBlockItemCreateResult,
	deleteIconListBlockItem:IconListBlockItemDeleteResult,
	updateIconListBlockItem:IconListBlockItemUpdateResult,
	createIconListBlock:IconListBlockCreateResult,
	deleteIconListBlock:IconListBlockDeleteResult,
	updateIconListBlock:IconListBlockUpdateResult,
	createFeatureListItem:FeatureListItemCreateResult,
	deleteFeatureListItem:FeatureListItemDeleteResult,
	updateFeatureListItem:FeatureListItemUpdateResult,
	createFeatureList:FeatureListCreateResult,
	deleteFeatureList:FeatureListDeleteResult,
	updateFeatureList:FeatureListUpdateResult,
	createFaqItem:FaqItemCreateResult,
	deleteFaqItem:FaqItemDeleteResult,
	updateFaqItem:FaqItemUpdateResult,
	createFaq:FaqCreateResult,
	deleteFaq:FaqDeleteResult,
	updateFaq:FaqUpdateResult,
	createContentBlock:ContentBlockCreateResult,
	deleteContentBlock:ContentBlockDeleteResult,
	updateContentBlock:ContentBlockUpdateResult,
	createContent:ContentCreateResult,
	deleteContent:ContentDeleteResult,
	updateContent:ContentUpdateResult,
	createLink:LinkCreateResult,
	deleteLink:LinkDeleteResult,
	updateLink:LinkUpdateResult,
	createReviewLocale:ReviewLocaleCreateResult,
	deleteReviewLocale:ReviewLocaleDeleteResult,
	updateReviewLocale:ReviewLocaleUpdateResult,
	createReview:ReviewCreateResult,
	deleteReview:ReviewDeleteResult,
	updateReview:ReviewUpdateResult,
	createGalleryItem:GalleryItemCreateResult,
	deleteGalleryItem:GalleryItemDeleteResult,
	updateGalleryItem:GalleryItemUpdateResult,
	createGallery:GalleryCreateResult,
	deleteGallery:GalleryDeleteResult,
	updateGallery:GalleryUpdateResult,
	createProductVariantStore:ProductVariantStoreCreateResult,
	deleteProductVariantStore:ProductVariantStoreDeleteResult,
	updateProductVariantStore:ProductVariantStoreUpdateResult,
	createProductStore:ProductStoreCreateResult,
	deleteProductStore:ProductStoreDeleteResult,
	updateProductStore:ProductStoreUpdateResult,
	createIconListItem:IconListItemCreateResult,
	deleteIconListItem:IconListItemDeleteResult,
	updateIconListItem:IconListItemUpdateResult,
	createIconList:IconListCreateResult,
	deleteIconList:IconListDeleteResult,
	updateIconList:IconListUpdateResult,
	createIconGroupListItem:IconGroupListItemCreateResult,
	deleteIconGroupListItem:IconGroupListItemDeleteResult,
	updateIconGroupListItem:IconGroupListItemUpdateResult,
	createIconGroupList:IconGroupListCreateResult,
	deleteIconGroupList:IconGroupListDeleteResult,
	updateIconGroupList:IconGroupListUpdateResult,
	createProductVariantLocale:ProductVariantLocaleCreateResult,
	deleteProductVariantLocale:ProductVariantLocaleDeleteResult,
	updateProductVariantLocale:ProductVariantLocaleUpdateResult,
	createProductVariant:ProductVariantCreateResult,
	deleteProductVariant:ProductVariantDeleteResult,
	updateProductVariant:ProductVariantUpdateResult,
	createProduct:ProductCreateResult,
	deleteProduct:ProductDeleteResult,
	updateProduct:ProductUpdateResult,
	createImageLocale:ImageLocaleCreateResult,
	deleteImageLocale:ImageLocaleDeleteResult,
	updateImageLocale:ImageLocaleUpdateResult,
	createStore:StoreCreateResult,
	deleteStore:StoreDeleteResult,
	updateStore:StoreUpdateResult,
	createAdminNote:AdminNoteCreateResult,
	deleteAdminNote:AdminNoteDeleteResult,
	updateAdminNote:AdminNoteUpdateResult,
	createPageLocale:PageLocaleCreateResult,
	deletePageLocale:PageLocaleDeleteResult,
	updatePageLocale:PageLocaleUpdateResult,
	createPage:PageCreateResult,
	deletePage:PageDeleteResult,
	updatePage:PageUpdateResult,
	createImageXImageList:ImageXImageListCreateResult,
	deleteImageXImageList:ImageXImageListDeleteResult,
	updateImageXImageList:ImageXImageListUpdateResult,
	createIconGroupLocale:IconGroupLocaleCreateResult,
	deleteIconGroupLocale:IconGroupLocaleDeleteResult,
	updateIconGroupLocale:IconGroupLocaleUpdateResult,
	createIconLocale:IconLocaleCreateResult,
	deleteIconLocale:IconLocaleDeleteResult,
	updateIconLocale:IconLocaleUpdateResult,
	createIconGroup:IconGroupCreateResult,
	deleteIconGroup:IconGroupDeleteResult,
	updateIconGroup:IconGroupUpdateResult,
	createIcon:IconCreateResult,
	deleteIcon:IconDeleteResult,
	updateIcon:IconUpdateResult,
	createLocale:LocaleCreateResult,
	deleteLocale:LocaleDeleteResult,
	updateLocale:LocaleUpdateResult,
	createSeoLocale:SeoLocaleCreateResult,
	deleteSeoLocale:SeoLocaleDeleteResult,
	updateSeoLocale:SeoLocaleUpdateResult,
	createSeo:SeoCreateResult,
	deleteSeo:SeoDeleteResult,
	updateSeo:SeoUpdateResult,
	createLinkable:LinkableCreateResult,
	deleteLinkable:LinkableDeleteResult,
	updateLinkable:LinkableUpdateResult,
	createImageList:ImageListCreateResult,
	deleteImageList:ImageListDeleteResult,
	updateImageList:ImageListUpdateResult,
	createImage:ImageCreateResult,
	deleteImage:ImageDeleteResult,
	updateImage:ImageUpdateResult,
	transaction:MutationTransaction,
	generateUploadUrl:S3SignedUpload,
	generateReadUrl:S3SignedRead
}

export type MutationTransaction = {
	__typename?: "MutationTransaction",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	validation:_ValidationResult,
	createAbandonedCheckoutUnsubscription:AbandonedCheckoutUnsubscriptionCreateResult,
	deleteAbandonedCheckoutUnsubscription:AbandonedCheckoutUnsubscriptionDeleteResult,
	updateAbandonedCheckoutUnsubscription:AbandonedCheckoutUnsubscriptionUpdateResult,
	createAbandonedCheckoutNotification:AbandonedCheckoutNotificationCreateResult,
	deleteAbandonedCheckoutNotification:AbandonedCheckoutNotificationDeleteResult,
	updateAbandonedCheckoutNotification:AbandonedCheckoutNotificationUpdateResult,
	createShopifyLineItem:ShopifyLineItemCreateResult,
	deleteShopifyLineItem:ShopifyLineItemDeleteResult,
	updateShopifyLineItem:ShopifyLineItemUpdateResult,
	createShopifyOrder:ShopifyOrderCreateResult,
	deleteShopifyOrder:ShopifyOrderDeleteResult,
	updateShopifyOrder:ShopifyOrderUpdateResult,
	createTranslationValue:TranslationValueCreateResult,
	deleteTranslationValue:TranslationValueDeleteResult,
	updateTranslationValue:TranslationValueUpdateResult,
	createTranslationKey:TranslationKeyCreateResult,
	deleteTranslationKey:TranslationKeyDeleteResult,
	updateTranslationKey:TranslationKeyUpdateResult,
	createTranslationCatalogue:TranslationCatalogueCreateResult,
	deleteTranslationCatalogue:TranslationCatalogueDeleteResult,
	updateTranslationCatalogue:TranslationCatalogueUpdateResult,
	createTranslationDomain:TranslationDomainCreateResult,
	deleteTranslationDomain:TranslationDomainDeleteResult,
	updateTranslationDomain:TranslationDomainUpdateResult,
	createProductLocale:ProductLocaleCreateResult,
	deleteProductLocale:ProductLocaleDeleteResult,
	updateProductLocale:ProductLocaleUpdateResult,
	createMedia:MediaCreateResult,
	deleteMedia:MediaDeleteResult,
	updateMedia:MediaUpdateResult,
	createIconListBlockItem:IconListBlockItemCreateResult,
	deleteIconListBlockItem:IconListBlockItemDeleteResult,
	updateIconListBlockItem:IconListBlockItemUpdateResult,
	createIconListBlock:IconListBlockCreateResult,
	deleteIconListBlock:IconListBlockDeleteResult,
	updateIconListBlock:IconListBlockUpdateResult,
	createFeatureListItem:FeatureListItemCreateResult,
	deleteFeatureListItem:FeatureListItemDeleteResult,
	updateFeatureListItem:FeatureListItemUpdateResult,
	createFeatureList:FeatureListCreateResult,
	deleteFeatureList:FeatureListDeleteResult,
	updateFeatureList:FeatureListUpdateResult,
	createFaqItem:FaqItemCreateResult,
	deleteFaqItem:FaqItemDeleteResult,
	updateFaqItem:FaqItemUpdateResult,
	createFaq:FaqCreateResult,
	deleteFaq:FaqDeleteResult,
	updateFaq:FaqUpdateResult,
	createContentBlock:ContentBlockCreateResult,
	deleteContentBlock:ContentBlockDeleteResult,
	updateContentBlock:ContentBlockUpdateResult,
	createContent:ContentCreateResult,
	deleteContent:ContentDeleteResult,
	updateContent:ContentUpdateResult,
	createLink:LinkCreateResult,
	deleteLink:LinkDeleteResult,
	updateLink:LinkUpdateResult,
	createReviewLocale:ReviewLocaleCreateResult,
	deleteReviewLocale:ReviewLocaleDeleteResult,
	updateReviewLocale:ReviewLocaleUpdateResult,
	createReview:ReviewCreateResult,
	deleteReview:ReviewDeleteResult,
	updateReview:ReviewUpdateResult,
	createGalleryItem:GalleryItemCreateResult,
	deleteGalleryItem:GalleryItemDeleteResult,
	updateGalleryItem:GalleryItemUpdateResult,
	createGallery:GalleryCreateResult,
	deleteGallery:GalleryDeleteResult,
	updateGallery:GalleryUpdateResult,
	createProductVariantStore:ProductVariantStoreCreateResult,
	deleteProductVariantStore:ProductVariantStoreDeleteResult,
	updateProductVariantStore:ProductVariantStoreUpdateResult,
	createProductStore:ProductStoreCreateResult,
	deleteProductStore:ProductStoreDeleteResult,
	updateProductStore:ProductStoreUpdateResult,
	createIconListItem:IconListItemCreateResult,
	deleteIconListItem:IconListItemDeleteResult,
	updateIconListItem:IconListItemUpdateResult,
	createIconList:IconListCreateResult,
	deleteIconList:IconListDeleteResult,
	updateIconList:IconListUpdateResult,
	createIconGroupListItem:IconGroupListItemCreateResult,
	deleteIconGroupListItem:IconGroupListItemDeleteResult,
	updateIconGroupListItem:IconGroupListItemUpdateResult,
	createIconGroupList:IconGroupListCreateResult,
	deleteIconGroupList:IconGroupListDeleteResult,
	updateIconGroupList:IconGroupListUpdateResult,
	createProductVariantLocale:ProductVariantLocaleCreateResult,
	deleteProductVariantLocale:ProductVariantLocaleDeleteResult,
	updateProductVariantLocale:ProductVariantLocaleUpdateResult,
	createProductVariant:ProductVariantCreateResult,
	deleteProductVariant:ProductVariantDeleteResult,
	updateProductVariant:ProductVariantUpdateResult,
	createProduct:ProductCreateResult,
	deleteProduct:ProductDeleteResult,
	updateProduct:ProductUpdateResult,
	createImageLocale:ImageLocaleCreateResult,
	deleteImageLocale:ImageLocaleDeleteResult,
	updateImageLocale:ImageLocaleUpdateResult,
	createStore:StoreCreateResult,
	deleteStore:StoreDeleteResult,
	updateStore:StoreUpdateResult,
	createAdminNote:AdminNoteCreateResult,
	deleteAdminNote:AdminNoteDeleteResult,
	updateAdminNote:AdminNoteUpdateResult,
	createPageLocale:PageLocaleCreateResult,
	deletePageLocale:PageLocaleDeleteResult,
	updatePageLocale:PageLocaleUpdateResult,
	createPage:PageCreateResult,
	deletePage:PageDeleteResult,
	updatePage:PageUpdateResult,
	createImageXImageList:ImageXImageListCreateResult,
	deleteImageXImageList:ImageXImageListDeleteResult,
	updateImageXImageList:ImageXImageListUpdateResult,
	createIconGroupLocale:IconGroupLocaleCreateResult,
	deleteIconGroupLocale:IconGroupLocaleDeleteResult,
	updateIconGroupLocale:IconGroupLocaleUpdateResult,
	createIconLocale:IconLocaleCreateResult,
	deleteIconLocale:IconLocaleDeleteResult,
	updateIconLocale:IconLocaleUpdateResult,
	createIconGroup:IconGroupCreateResult,
	deleteIconGroup:IconGroupDeleteResult,
	updateIconGroup:IconGroupUpdateResult,
	createIcon:IconCreateResult,
	deleteIcon:IconDeleteResult,
	updateIcon:IconUpdateResult,
	createLocale:LocaleCreateResult,
	deleteLocale:LocaleDeleteResult,
	updateLocale:LocaleUpdateResult,
	createSeoLocale:SeoLocaleCreateResult,
	deleteSeoLocale:SeoLocaleDeleteResult,
	updateSeoLocale:SeoLocaleUpdateResult,
	createSeo:SeoCreateResult,
	deleteSeo:SeoDeleteResult,
	updateSeo:SeoUpdateResult,
	createLinkable:LinkableCreateResult,
	deleteLinkable:LinkableDeleteResult,
	updateLinkable:LinkableUpdateResult,
	createImageList:ImageListCreateResult,
	deleteImageList:ImageListDeleteResult,
	updateImageList:ImageListUpdateResult,
	createImage:ImageCreateResult,
	deleteImage:ImageDeleteResult,
	updateImage:ImageUpdateResult
}

export enum OrderDirection {
	asc = "asc",
	desc = "desc"
}

export type Page = {
	__typename?: "Page",
	_meta?:PageMeta,
	id:UUID,
	internalName:string,
	template?:PageType,
	seo?:Seo,
	locales:PageLocale[],
	localesByLocale?:PageLocale,
	localesByLink?:PageLocale,
	localesByContent?:PageLocale
}

export type PageConnection = {
	__typename?: "PageConnection",
	pageInfo:PageInfo,
	edges:PageEdge[]
}

export type PageCreateInput = {
		internalName?:string,
	template?:PageType,
	seo?:PageCreateSeoEntityRelationInput,
	locales?:PageCreateLocalesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type PageCreateLocalesEntityRelationInput = {
		connect?:PageLocaleUniqueWhere,
	create?:PageLocaleWithoutRootCreateInput,
	alias?:string
}

export type PageCreateResult = {
	__typename?: "PageCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Page,
	validation:_ValidationResult
}

export type PageCreateSeoEntityRelationInput = {
		connect?:SeoUniqueWhere,
	create?:SeoCreateInput
}

export type PageDeleteResult = {
	__typename?: "PageDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Page
}

export type PageEdge = {
	__typename?: "PageEdge",
	node:Page
}

export type PageInfo = {
	__typename?: "PageInfo",
	totalCount:number
}

export type PageLocale = {
	__typename?: "PageLocale",
	_meta?:PageLocaleMeta,
	id:UUID,
	title?:string,
	locale?:Locale,
	link?:Linkable,
	root?:Page,
	content?:Content,
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string
}

export type PageLocaleConnection = {
	__typename?: "PageLocaleConnection",
	pageInfo:PageInfo,
	edges:PageLocaleEdge[]
}

export type PageLocaleCreateContentEntityRelationInput = {
		connect?:ContentUniqueWhere,
	create?:ContentWithoutPageCreateInput
}

export type PageLocaleCreateInput = {
		title?:string,
	locale?:PageLocaleCreateLocaleEntityRelationInput,
	link?:PageLocaleCreateLinkEntityRelationInput,
	root?:PageLocaleCreateRootEntityRelationInput,
	content?:PageLocaleCreateContentEntityRelationInput,
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
}

export type PageLocaleCreateLinkEntityRelationInput = {
		connect?:LinkableUniqueWhere,
	create?:LinkableWithoutPageCreateInput
}

export type PageLocaleCreateLocaleEntityRelationInput = {
		connect?:LocaleUniqueWhere,
	create?:LocaleWithoutPagesCreateInput
}

export type PageLocaleCreateResult = {
	__typename?: "PageLocaleCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:PageLocale,
	validation:_ValidationResult
}

export type PageLocaleCreateRootEntityRelationInput = {
		connect?:PageUniqueWhere,
	create?:PageWithoutLocalesCreateInput
}

export type PageLocaleDeleteResult = {
	__typename?: "PageLocaleDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:PageLocale
}

export type PageLocaleEdge = {
	__typename?: "PageLocaleEdge",
	node:PageLocale
}

export type PageLocaleMeta = {
	__typename?: "PageLocaleMeta",
	id?:FieldMeta,
	title?:FieldMeta,
	locale?:FieldMeta,
	link?:FieldMeta,
	root?:FieldMeta,
	content?:FieldMeta,
	bannerColor?:FieldMeta,
	bannerBgColor?:FieldMeta,
	bannerText?:FieldMeta
}

export type PageLocaleOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	title?:OrderDirection,
	locale?:LocaleOrderBy,
	link?:LinkableOrderBy,
	root?:PageOrderBy,
	content?:ContentOrderBy,
	bannerColor?:OrderDirection,
	bannerBgColor?:OrderDirection,
	bannerText?:OrderDirection
}

export type PageLocalesByContentUniqueWhere = {
		content?:ContentUniqueWhere
}

export type PageLocalesByLinkUniqueWhere = {
		link?:LinkableUniqueWhere
}

export type PageLocalesByLocaleUniqueWhere = {
		locale?:LocaleUniqueWhere
}

export type PageLocaleUniqueWhere = {
		id?:UUID,
	root?:PageUniqueWhere,
	locale?:LocaleUniqueWhere,
	link?:LinkableUniqueWhere,
	content?:ContentUniqueWhere
}

export type PageLocaleUpdateContentEntityRelationInput = {
		create?:ContentWithoutPageCreateInput,
	update?:ContentWithoutPageUpdateInput,
	upsert?:PageLocaleUpsertContentRelationInput,
	connect?:ContentUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type PageLocaleUpdateInput = {
		title?:string,
	locale?:PageLocaleUpdateLocaleEntityRelationInput,
	link?:PageLocaleUpdateLinkEntityRelationInput,
	root?:PageLocaleUpdateRootEntityRelationInput,
	content?:PageLocaleUpdateContentEntityRelationInput,
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
}

export type PageLocaleUpdateLinkEntityRelationInput = {
		create?:LinkableWithoutPageCreateInput,
	update?:LinkableWithoutPageUpdateInput,
	upsert?:PageLocaleUpsertLinkRelationInput,
	connect?:LinkableUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type PageLocaleUpdateLocaleEntityRelationInput = {
		create?:LocaleWithoutPagesCreateInput,
	update?:LocaleWithoutPagesUpdateInput,
	upsert?:PageLocaleUpsertLocaleRelationInput,
	connect?:LocaleUniqueWhere
}

export type PageLocaleUpdateResult = {
	__typename?: "PageLocaleUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:PageLocale,
	validation:_ValidationResult
}

export type PageLocaleUpdateRootEntityRelationInput = {
		create?:PageWithoutLocalesCreateInput,
	update?:PageWithoutLocalesUpdateInput,
	upsert?:PageLocaleUpsertRootRelationInput,
	connect?:PageUniqueWhere
}

export type PageLocaleUpsertContentRelationInput = {
		update?:ContentWithoutPageUpdateInput,
	create?:ContentWithoutPageCreateInput
}

export type PageLocaleUpsertLinkRelationInput = {
		update?:LinkableWithoutPageUpdateInput,
	create?:LinkableWithoutPageCreateInput
}

export type PageLocaleUpsertLocaleRelationInput = {
		update?:LocaleWithoutPagesUpdateInput,
	create?:LocaleWithoutPagesCreateInput
}

export type PageLocaleUpsertRootRelationInput = {
		update?:PageWithoutLocalesUpdateInput,
	create?:PageWithoutLocalesCreateInput
}

export type PageLocaleWhere = {
		id?:UUIDCondition,
	title?:StringCondition,
	locale?:LocaleWhere,
	link?:LinkableWhere,
	root?:PageWhere,
	content?:ContentWhere,
	bannerColor?:StringCondition,
	bannerBgColor?:StringCondition,
	bannerText?:StringCondition,
	and?:PageLocaleWhere[],
	or?:PageLocaleWhere[],
	not?:PageLocaleWhere
}

export type PageLocaleWithoutContentCreateInput = {
		title?:string,
	locale?:PageLocaleCreateLocaleEntityRelationInput,
	link?:PageLocaleCreateLinkEntityRelationInput,
	root?:PageLocaleCreateRootEntityRelationInput,
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
}

export type PageLocaleWithoutContentUpdateInput = {
		title?:string,
	locale?:PageLocaleUpdateLocaleEntityRelationInput,
	link?:PageLocaleUpdateLinkEntityRelationInput,
	root?:PageLocaleUpdateRootEntityRelationInput,
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
}

export type PageLocaleWithoutLinkCreateInput = {
		title?:string,
	locale?:PageLocaleCreateLocaleEntityRelationInput,
	root?:PageLocaleCreateRootEntityRelationInput,
	content?:PageLocaleCreateContentEntityRelationInput,
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
}

export type PageLocaleWithoutLinkUpdateInput = {
		title?:string,
	locale?:PageLocaleUpdateLocaleEntityRelationInput,
	root?:PageLocaleUpdateRootEntityRelationInput,
	content?:PageLocaleUpdateContentEntityRelationInput,
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
}

export type PageLocaleWithoutLocaleCreateInput = {
		title?:string,
	link?:PageLocaleCreateLinkEntityRelationInput,
	root?:PageLocaleCreateRootEntityRelationInput,
	content?:PageLocaleCreateContentEntityRelationInput,
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
}

export type PageLocaleWithoutLocaleUpdateInput = {
		title?:string,
	link?:PageLocaleUpdateLinkEntityRelationInput,
	root?:PageLocaleUpdateRootEntityRelationInput,
	content?:PageLocaleUpdateContentEntityRelationInput,
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
}

export type PageLocaleWithoutRootCreateInput = {
		title?:string,
	locale?:PageLocaleCreateLocaleEntityRelationInput,
	link?:PageLocaleCreateLinkEntityRelationInput,
	content?:PageLocaleCreateContentEntityRelationInput,
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
}

export type PageLocaleWithoutRootUpdateInput = {
		title?:string,
	locale?:PageLocaleUpdateLocaleEntityRelationInput,
	link?:PageLocaleUpdateLinkEntityRelationInput,
	content?:PageLocaleUpdateContentEntityRelationInput,
	bannerColor?:string,
	bannerBgColor?:string,
	bannerText?:string,
	_dummy_field_?:boolean
}

export type PageMeta = {
	__typename?: "PageMeta",
	id?:FieldMeta,
	internalName?:FieldMeta,
	template?:FieldMeta,
	seo?:FieldMeta,
	locales?:FieldMeta
}

export type PageOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	internalName?:OrderDirection,
	template?:OrderDirection,
	seo?:SeoOrderBy
}

export enum PageType {
	homepage = "homepage",
	contact = "contact",
	howitworks = "howitworks",
	regular = "regular"
}

export type PageTypeEnumCondition = {
		and?:PageTypeEnumCondition[],
	or?:PageTypeEnumCondition[],
	not?:PageTypeEnumCondition,
	eq?:PageType,
	null?:boolean,
	isNull?:boolean,
	notEq?:PageType,
	in?:PageType[],
	notIn?:PageType[],
	lt?:PageType,
	lte?:PageType,
	gt?:PageType,
	gte?:PageType
}

export type PageUniqueWhere = {
		id?:UUID,
	seo?:SeoUniqueWhere,
	locales?:PageLocaleUniqueWhere
}

export type PageUpdateInput = {
		internalName?:string,
	template?:PageType,
	seo?:PageUpdateSeoEntityRelationInput,
	locales?:PageUpdateLocalesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type PageUpdateLocalesEntityRelationInput = {
		create?:PageLocaleWithoutRootCreateInput,
	update?:PageUpdateLocalesRelationInput,
	upsert?:PageUpsertLocalesRelationInput,
	connect?:PageLocaleUniqueWhere,
	disconnect?:PageLocaleUniqueWhere,
	delete?:PageLocaleUniqueWhere,
	alias?:string
}

export type PageUpdateLocalesRelationInput = {
		by?:PageLocaleUniqueWhere,
	data?:PageLocaleWithoutRootUpdateInput
}

export type PageUpdateResult = {
	__typename?: "PageUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Page,
	validation:_ValidationResult
}

export type PageUpdateSeoEntityRelationInput = {
		create?:SeoCreateInput,
	update?:SeoUpdateInput,
	upsert?:PageUpsertSeoRelationInput,
	connect?:SeoUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type PageUpsertLocalesRelationInput = {
		by?:PageLocaleUniqueWhere,
	update?:PageLocaleWithoutRootUpdateInput,
	create?:PageLocaleWithoutRootCreateInput
}

export type PageUpsertSeoRelationInput = {
		update?:SeoUpdateInput,
	create?:SeoCreateInput
}

export type PageWhere = {
		id?:UUIDCondition,
	internalName?:StringCondition,
	template?:PageTypeEnumCondition,
	seo?:SeoWhere,
	locales?:PageLocaleWhere,
	and?:PageWhere[],
	or?:PageWhere[],
	not?:PageWhere
}

export type PageWithoutLocalesCreateInput = {
		internalName?:string,
	template?:PageType,
	seo?:PageCreateSeoEntityRelationInput,
	_dummy_field_?:boolean
}

export type PageWithoutLocalesUpdateInput = {
		internalName?:string,
	template?:PageType,
	seo?:PageUpdateSeoEntityRelationInput,
	_dummy_field_?:boolean
}

export type Product = {
	__typename?: "Product",
	_meta?:ProductMeta,
	id:UUID,
	title?:string,
	shopifyHandle:string,
	productType?:string,
	stores:ProductStore[],
	productVariants:ProductVariant[],
	locales:ProductLocale[],
	storesByStore?:ProductStore,
	storesByShopifyId?:ProductStore,
	productVariantsByShopifySku?:ProductVariant,
	productVariantsByLocales?:ProductVariant,
	productVariantsByStores?:ProductVariant,
	localesByLocale?:ProductLocale
}

export type ProductConnection = {
	__typename?: "ProductConnection",
	pageInfo:PageInfo,
	edges:ProductEdge[]
}

export type ProductCreateInput = {
		title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:ProductCreateStoresEntityRelationInput[],
	productVariants?:ProductCreateProductVariantsEntityRelationInput[],
	locales?:ProductCreateLocalesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type ProductCreateLocalesEntityRelationInput = {
		connect?:ProductLocaleUniqueWhere,
	create?:ProductLocaleWithoutRootCreateInput,
	alias?:string
}

export type ProductCreateProductVariantsEntityRelationInput = {
		connect?:ProductVariantUniqueWhere,
	create?:ProductVariantWithoutProductCreateInput,
	alias?:string
}

export type ProductCreateResult = {
	__typename?: "ProductCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Product,
	validation:_ValidationResult
}

export type ProductCreateStoresEntityRelationInput = {
		connect?:ProductStoreUniqueWhere,
	create?:ProductStoreWithoutRootCreateInput,
	alias?:string
}

export type ProductDeleteResult = {
	__typename?: "ProductDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Product
}

export type ProductEdge = {
	__typename?: "ProductEdge",
	node:Product
}

export type ProductLocale = {
	__typename?: "ProductLocale",
	_meta?:ProductLocaleMeta,
	id:UUID,
	title?:string,
	description?:string,
	descriptionSecondary?:string,
	root?:Product,
	locale?:Locale,
	gallery?:Gallery,
	titleTemplate?:string
}

export type ProductLocaleConnection = {
	__typename?: "ProductLocaleConnection",
	pageInfo:PageInfo,
	edges:ProductLocaleEdge[]
}

export type ProductLocaleCreateGalleryEntityRelationInput = {
		connect?:GalleryUniqueWhere,
	create?:GalleryCreateInput
}

export type ProductLocaleCreateInput = {
		title?:string,
	description?:string,
	descriptionSecondary?:string,
	root?:ProductLocaleCreateRootEntityRelationInput,
	locale?:ProductLocaleCreateLocaleEntityRelationInput,
	gallery?:ProductLocaleCreateGalleryEntityRelationInput,
	titleTemplate?:string,
	_dummy_field_?:boolean
}

export type ProductLocaleCreateLocaleEntityRelationInput = {
		connect?:LocaleUniqueWhere,
	create?:LocaleWithoutProductsCreateInput
}

export type ProductLocaleCreateResult = {
	__typename?: "ProductLocaleCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ProductLocale,
	validation:_ValidationResult
}

export type ProductLocaleCreateRootEntityRelationInput = {
		connect?:ProductUniqueWhere,
	create?:ProductWithoutLocalesCreateInput
}

export type ProductLocaleDeleteResult = {
	__typename?: "ProductLocaleDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ProductLocale
}

export type ProductLocaleEdge = {
	__typename?: "ProductLocaleEdge",
	node:ProductLocale
}

export type ProductLocaleMeta = {
	__typename?: "ProductLocaleMeta",
	id?:FieldMeta,
	title?:FieldMeta,
	description?:FieldMeta,
	descriptionSecondary?:FieldMeta,
	root?:FieldMeta,
	locale?:FieldMeta,
	gallery?:FieldMeta,
	titleTemplate?:FieldMeta
}

export type ProductLocaleOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	title?:OrderDirection,
	description?:OrderDirection,
	descriptionSecondary?:OrderDirection,
	root?:ProductOrderBy,
	locale?:LocaleOrderBy,
	gallery?:GalleryOrderBy,
	titleTemplate?:OrderDirection
}

export type ProductLocalesByLocaleUniqueWhere = {
		locale?:LocaleUniqueWhere
}

export type ProductLocaleUniqueWhere = {
		id?:UUID,
	root?:ProductUniqueWhere,
	locale?:LocaleUniqueWhere
}

export type ProductLocaleUpdateGalleryEntityRelationInput = {
		create?:GalleryCreateInput,
	update?:GalleryUpdateInput,
	upsert?:ProductLocaleUpsertGalleryRelationInput,
	connect?:GalleryUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type ProductLocaleUpdateInput = {
		title?:string,
	description?:string,
	descriptionSecondary?:string,
	root?:ProductLocaleUpdateRootEntityRelationInput,
	locale?:ProductLocaleUpdateLocaleEntityRelationInput,
	gallery?:ProductLocaleUpdateGalleryEntityRelationInput,
	titleTemplate?:string,
	_dummy_field_?:boolean
}

export type ProductLocaleUpdateLocaleEntityRelationInput = {
		create?:LocaleWithoutProductsCreateInput,
	update?:LocaleWithoutProductsUpdateInput,
	upsert?:ProductLocaleUpsertLocaleRelationInput,
	connect?:LocaleUniqueWhere
}

export type ProductLocaleUpdateResult = {
	__typename?: "ProductLocaleUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ProductLocale,
	validation:_ValidationResult
}

export type ProductLocaleUpdateRootEntityRelationInput = {
		create?:ProductWithoutLocalesCreateInput,
	update?:ProductWithoutLocalesUpdateInput,
	upsert?:ProductLocaleUpsertRootRelationInput,
	connect?:ProductUniqueWhere
}

export type ProductLocaleUpsertGalleryRelationInput = {
		update?:GalleryUpdateInput,
	create?:GalleryCreateInput
}

export type ProductLocaleUpsertLocaleRelationInput = {
		update?:LocaleWithoutProductsUpdateInput,
	create?:LocaleWithoutProductsCreateInput
}

export type ProductLocaleUpsertRootRelationInput = {
		update?:ProductWithoutLocalesUpdateInput,
	create?:ProductWithoutLocalesCreateInput
}

export type ProductLocaleWhere = {
		id?:UUIDCondition,
	title?:StringCondition,
	description?:StringCondition,
	descriptionSecondary?:StringCondition,
	root?:ProductWhere,
	locale?:LocaleWhere,
	gallery?:GalleryWhere,
	titleTemplate?:StringCondition,
	and?:ProductLocaleWhere[],
	or?:ProductLocaleWhere[],
	not?:ProductLocaleWhere
}

export type ProductLocaleWithoutLocaleCreateInput = {
		title?:string,
	description?:string,
	descriptionSecondary?:string,
	root?:ProductLocaleCreateRootEntityRelationInput,
	gallery?:ProductLocaleCreateGalleryEntityRelationInput,
	titleTemplate?:string,
	_dummy_field_?:boolean
}

export type ProductLocaleWithoutLocaleUpdateInput = {
		title?:string,
	description?:string,
	descriptionSecondary?:string,
	root?:ProductLocaleUpdateRootEntityRelationInput,
	gallery?:ProductLocaleUpdateGalleryEntityRelationInput,
	titleTemplate?:string,
	_dummy_field_?:boolean
}

export type ProductLocaleWithoutRootCreateInput = {
		title?:string,
	description?:string,
	descriptionSecondary?:string,
	locale?:ProductLocaleCreateLocaleEntityRelationInput,
	gallery?:ProductLocaleCreateGalleryEntityRelationInput,
	titleTemplate?:string,
	_dummy_field_?:boolean
}

export type ProductLocaleWithoutRootUpdateInput = {
		title?:string,
	description?:string,
	descriptionSecondary?:string,
	locale?:ProductLocaleUpdateLocaleEntityRelationInput,
	gallery?:ProductLocaleUpdateGalleryEntityRelationInput,
	titleTemplate?:string,
	_dummy_field_?:boolean
}

export type ProductMeta = {
	__typename?: "ProductMeta",
	id?:FieldMeta,
	title?:FieldMeta,
	shopifyHandle?:FieldMeta,
	productType?:FieldMeta,
	stores?:FieldMeta,
	productVariants?:FieldMeta,
	locales?:FieldMeta
}

export type ProductOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	title?:OrderDirection,
	shopifyHandle?:OrderDirection,
	productType?:OrderDirection
}

export type ProductProductVariantsByLocalesUniqueWhere = {
		locales?:ProductVariantLocaleUniqueWhere
}

export type ProductProductVariantsByShopifySkuUniqueWhere = {
		shopifySku?:string
}

export type ProductProductVariantsByStoresUniqueWhere = {
		stores?:ProductVariantStoreUniqueWhere
}

export type ProductStore = {
	__typename?: "ProductStore",
	_meta?:ProductStoreMeta,
	id:UUID,
	shopifyId:string,
	root?:Product,
	store?:Store
}

export type ProductStoreConnection = {
	__typename?: "ProductStoreConnection",
	pageInfo:PageInfo,
	edges:ProductStoreEdge[]
}

export type ProductStoreCreateInput = {
		shopifyId?:string,
	root?:ProductStoreCreateRootEntityRelationInput,
	store?:ProductStoreCreateStoreEntityRelationInput,
	_dummy_field_?:boolean
}

export type ProductStoreCreateResult = {
	__typename?: "ProductStoreCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ProductStore,
	validation:_ValidationResult
}

export type ProductStoreCreateRootEntityRelationInput = {
		connect?:ProductUniqueWhere,
	create?:ProductWithoutStoresCreateInput
}

export type ProductStoreCreateStoreEntityRelationInput = {
		connect?:StoreUniqueWhere,
	create?:StoreWithoutProductsCreateInput
}

export type ProductStoreDeleteResult = {
	__typename?: "ProductStoreDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ProductStore
}

export type ProductStoreEdge = {
	__typename?: "ProductStoreEdge",
	node:ProductStore
}

export type ProductStoreMeta = {
	__typename?: "ProductStoreMeta",
	id?:FieldMeta,
	shopifyId?:FieldMeta,
	root?:FieldMeta,
	store?:FieldMeta
}

export type ProductStoreOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	shopifyId?:OrderDirection,
	root?:ProductOrderBy,
	store?:StoreOrderBy
}

export type ProductStoresByShopifyIdUniqueWhere = {
		shopifyId?:string
}

export type ProductStoresByStoreUniqueWhere = {
		store?:StoreUniqueWhere
}

export type ProductStoreUniqueWhere = {
		id?:UUID,
	root?:ProductUniqueWhere,
	store?:StoreUniqueWhere,
	shopifyId?:string
}

export type ProductStoreUpdateInput = {
		shopifyId?:string,
	root?:ProductStoreUpdateRootEntityRelationInput,
	store?:ProductStoreUpdateStoreEntityRelationInput,
	_dummy_field_?:boolean
}

export type ProductStoreUpdateResult = {
	__typename?: "ProductStoreUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ProductStore,
	validation:_ValidationResult
}

export type ProductStoreUpdateRootEntityRelationInput = {
		create?:ProductWithoutStoresCreateInput,
	update?:ProductWithoutStoresUpdateInput,
	upsert?:ProductStoreUpsertRootRelationInput,
	connect?:ProductUniqueWhere
}

export type ProductStoreUpdateStoreEntityRelationInput = {
		create?:StoreWithoutProductsCreateInput,
	update?:StoreWithoutProductsUpdateInput,
	upsert?:ProductStoreUpsertStoreRelationInput,
	connect?:StoreUniqueWhere
}

export type ProductStoreUpsertRootRelationInput = {
		update?:ProductWithoutStoresUpdateInput,
	create?:ProductWithoutStoresCreateInput
}

export type ProductStoreUpsertStoreRelationInput = {
		update?:StoreWithoutProductsUpdateInput,
	create?:StoreWithoutProductsCreateInput
}

export type ProductStoreWhere = {
		id?:UUIDCondition,
	shopifyId?:StringCondition,
	root?:ProductWhere,
	store?:StoreWhere,
	and?:ProductStoreWhere[],
	or?:ProductStoreWhere[],
	not?:ProductStoreWhere
}

export type ProductStoreWithoutRootCreateInput = {
		shopifyId?:string,
	store?:ProductStoreCreateStoreEntityRelationInput,
	_dummy_field_?:boolean
}

export type ProductStoreWithoutRootUpdateInput = {
		shopifyId?:string,
	store?:ProductStoreUpdateStoreEntityRelationInput,
	_dummy_field_?:boolean
}

export type ProductStoreWithoutStoreCreateInput = {
		shopifyId?:string,
	root?:ProductStoreCreateRootEntityRelationInput,
	_dummy_field_?:boolean
}

export type ProductStoreWithoutStoreUpdateInput = {
		shopifyId?:string,
	root?:ProductStoreUpdateRootEntityRelationInput,
	_dummy_field_?:boolean
}

export type ProductUniqueWhere = {
		id?:UUID,
	shopifyHandle?:string,
	stores?:ProductStoreUniqueWhere,
	productVariants?:ProductVariantUniqueWhere,
	locales?:ProductLocaleUniqueWhere
}

export type ProductUpdateInput = {
		title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:ProductUpdateStoresEntityRelationInput[],
	productVariants?:ProductUpdateProductVariantsEntityRelationInput[],
	locales?:ProductUpdateLocalesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type ProductUpdateLocalesEntityRelationInput = {
		create?:ProductLocaleWithoutRootCreateInput,
	update?:ProductUpdateLocalesRelationInput,
	upsert?:ProductUpsertLocalesRelationInput,
	connect?:ProductLocaleUniqueWhere,
	disconnect?:ProductLocaleUniqueWhere,
	delete?:ProductLocaleUniqueWhere,
	alias?:string
}

export type ProductUpdateLocalesRelationInput = {
		by?:ProductLocaleUniqueWhere,
	data?:ProductLocaleWithoutRootUpdateInput
}

export type ProductUpdateProductVariantsEntityRelationInput = {
		create?:ProductVariantWithoutProductCreateInput,
	update?:ProductUpdateProductVariantsRelationInput,
	upsert?:ProductUpsertProductVariantsRelationInput,
	connect?:ProductVariantUniqueWhere,
	disconnect?:ProductVariantUniqueWhere,
	delete?:ProductVariantUniqueWhere,
	alias?:string
}

export type ProductUpdateProductVariantsRelationInput = {
		by?:ProductVariantUniqueWhere,
	data?:ProductVariantWithoutProductUpdateInput
}

export type ProductUpdateResult = {
	__typename?: "ProductUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Product,
	validation:_ValidationResult
}

export type ProductUpdateStoresEntityRelationInput = {
		create?:ProductStoreWithoutRootCreateInput,
	update?:ProductUpdateStoresRelationInput,
	upsert?:ProductUpsertStoresRelationInput,
	connect?:ProductStoreUniqueWhere,
	disconnect?:ProductStoreUniqueWhere,
	delete?:ProductStoreUniqueWhere,
	alias?:string
}

export type ProductUpdateStoresRelationInput = {
		by?:ProductStoreUniqueWhere,
	data?:ProductStoreWithoutRootUpdateInput
}

export type ProductUpsertLocalesRelationInput = {
		by?:ProductLocaleUniqueWhere,
	update?:ProductLocaleWithoutRootUpdateInput,
	create?:ProductLocaleWithoutRootCreateInput
}

export type ProductUpsertProductVariantsRelationInput = {
		by?:ProductVariantUniqueWhere,
	update?:ProductVariantWithoutProductUpdateInput,
	create?:ProductVariantWithoutProductCreateInput
}

export type ProductUpsertStoresRelationInput = {
		by?:ProductStoreUniqueWhere,
	update?:ProductStoreWithoutRootUpdateInput,
	create?:ProductStoreWithoutRootCreateInput
}

export type ProductVariant = {
	__typename?: "ProductVariant",
	_meta?:ProductVariantMeta,
	id:UUID,
	shopifySku:string,
	product?:Product,
	locales:ProductVariantLocale[],
	stores:ProductVariantStore[],
	title?:string,
	heurekaId?:string,
	localesByLocale?:ProductVariantLocale,
	storesByStore?:ProductVariantStore,
	storesByShopifyId?:ProductVariantStore,
	storesByStorefrontId?:ProductVariantStore
}

export type ProductVariantConnection = {
	__typename?: "ProductVariantConnection",
	pageInfo:PageInfo,
	edges:ProductVariantEdge[]
}

export type ProductVariantCreateInput = {
		shopifySku?:string,
	product?:ProductVariantCreateProductEntityRelationInput,
	locales?:ProductVariantCreateLocalesEntityRelationInput[],
	stores?:ProductVariantCreateStoresEntityRelationInput[],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
}

export type ProductVariantCreateLocalesEntityRelationInput = {
		connect?:ProductVariantLocaleUniqueWhere,
	create?:ProductVariantLocaleWithoutRootCreateInput,
	alias?:string
}

export type ProductVariantCreateProductEntityRelationInput = {
		connect?:ProductUniqueWhere,
	create?:ProductWithoutProductVariantsCreateInput
}

export type ProductVariantCreateResult = {
	__typename?: "ProductVariantCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ProductVariant,
	validation:_ValidationResult
}

export type ProductVariantCreateStoresEntityRelationInput = {
		connect?:ProductVariantStoreUniqueWhere,
	create?:ProductVariantStoreWithoutRootCreateInput,
	alias?:string
}

export type ProductVariantDeleteResult = {
	__typename?: "ProductVariantDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ProductVariant
}

export type ProductVariantEdge = {
	__typename?: "ProductVariantEdge",
	node:ProductVariant
}

export type ProductVariantLocale = {
	__typename?: "ProductVariantLocale",
	_meta?:ProductVariantLocaleMeta,
	id:UUID,
	title?:string,
	description?:string,
	root?:ProductVariant,
	locale?:Locale,
	gallery?:Gallery,
	descriptionSecondary?:string,
	titleTemplate?:string
}

export type ProductVariantLocaleConnection = {
	__typename?: "ProductVariantLocaleConnection",
	pageInfo:PageInfo,
	edges:ProductVariantLocaleEdge[]
}

export type ProductVariantLocaleCreateGalleryEntityRelationInput = {
		connect?:GalleryUniqueWhere,
	create?:GalleryCreateInput
}

export type ProductVariantLocaleCreateInput = {
		title?:string,
	description?:string,
	root?:ProductVariantLocaleCreateRootEntityRelationInput,
	locale?:ProductVariantLocaleCreateLocaleEntityRelationInput,
	gallery?:ProductVariantLocaleCreateGalleryEntityRelationInput,
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
}

export type ProductVariantLocaleCreateLocaleEntityRelationInput = {
		connect?:LocaleUniqueWhere,
	create?:LocaleWithoutProductVariantsCreateInput
}

export type ProductVariantLocaleCreateResult = {
	__typename?: "ProductVariantLocaleCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ProductVariantLocale,
	validation:_ValidationResult
}

export type ProductVariantLocaleCreateRootEntityRelationInput = {
		connect?:ProductVariantUniqueWhere,
	create?:ProductVariantWithoutLocalesCreateInput
}

export type ProductVariantLocaleDeleteResult = {
	__typename?: "ProductVariantLocaleDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ProductVariantLocale
}

export type ProductVariantLocaleEdge = {
	__typename?: "ProductVariantLocaleEdge",
	node:ProductVariantLocale
}

export type ProductVariantLocaleMeta = {
	__typename?: "ProductVariantLocaleMeta",
	id?:FieldMeta,
	title?:FieldMeta,
	description?:FieldMeta,
	root?:FieldMeta,
	locale?:FieldMeta,
	gallery?:FieldMeta,
	descriptionSecondary?:FieldMeta,
	titleTemplate?:FieldMeta
}

export type ProductVariantLocaleOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	title?:OrderDirection,
	description?:OrderDirection,
	root?:ProductVariantOrderBy,
	locale?:LocaleOrderBy,
	gallery?:GalleryOrderBy,
	descriptionSecondary?:OrderDirection,
	titleTemplate?:OrderDirection
}

export type ProductVariantLocalesByLocaleUniqueWhere = {
		locale?:LocaleUniqueWhere
}

export type ProductVariantLocaleUniqueWhere = {
		id?:UUID,
	root?:ProductVariantUniqueWhere,
	locale?:LocaleUniqueWhere
}

export type ProductVariantLocaleUpdateGalleryEntityRelationInput = {
		create?:GalleryCreateInput,
	update?:GalleryUpdateInput,
	upsert?:ProductVariantLocaleUpsertGalleryRelationInput,
	connect?:GalleryUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type ProductVariantLocaleUpdateInput = {
		title?:string,
	description?:string,
	root?:ProductVariantLocaleUpdateRootEntityRelationInput,
	locale?:ProductVariantLocaleUpdateLocaleEntityRelationInput,
	gallery?:ProductVariantLocaleUpdateGalleryEntityRelationInput,
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
}

export type ProductVariantLocaleUpdateLocaleEntityRelationInput = {
		create?:LocaleWithoutProductVariantsCreateInput,
	update?:LocaleWithoutProductVariantsUpdateInput,
	upsert?:ProductVariantLocaleUpsertLocaleRelationInput,
	connect?:LocaleUniqueWhere
}

export type ProductVariantLocaleUpdateResult = {
	__typename?: "ProductVariantLocaleUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ProductVariantLocale,
	validation:_ValidationResult
}

export type ProductVariantLocaleUpdateRootEntityRelationInput = {
		create?:ProductVariantWithoutLocalesCreateInput,
	update?:ProductVariantWithoutLocalesUpdateInput,
	upsert?:ProductVariantLocaleUpsertRootRelationInput,
	connect?:ProductVariantUniqueWhere
}

export type ProductVariantLocaleUpsertGalleryRelationInput = {
		update?:GalleryUpdateInput,
	create?:GalleryCreateInput
}

export type ProductVariantLocaleUpsertLocaleRelationInput = {
		update?:LocaleWithoutProductVariantsUpdateInput,
	create?:LocaleWithoutProductVariantsCreateInput
}

export type ProductVariantLocaleUpsertRootRelationInput = {
		update?:ProductVariantWithoutLocalesUpdateInput,
	create?:ProductVariantWithoutLocalesCreateInput
}

export type ProductVariantLocaleWhere = {
		id?:UUIDCondition,
	title?:StringCondition,
	description?:StringCondition,
	root?:ProductVariantWhere,
	locale?:LocaleWhere,
	gallery?:GalleryWhere,
	descriptionSecondary?:StringCondition,
	titleTemplate?:StringCondition,
	and?:ProductVariantLocaleWhere[],
	or?:ProductVariantLocaleWhere[],
	not?:ProductVariantLocaleWhere
}

export type ProductVariantLocaleWithoutLocaleCreateInput = {
		title?:string,
	description?:string,
	root?:ProductVariantLocaleCreateRootEntityRelationInput,
	gallery?:ProductVariantLocaleCreateGalleryEntityRelationInput,
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
}

export type ProductVariantLocaleWithoutLocaleUpdateInput = {
		title?:string,
	description?:string,
	root?:ProductVariantLocaleUpdateRootEntityRelationInput,
	gallery?:ProductVariantLocaleUpdateGalleryEntityRelationInput,
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
}

export type ProductVariantLocaleWithoutRootCreateInput = {
		title?:string,
	description?:string,
	locale?:ProductVariantLocaleCreateLocaleEntityRelationInput,
	gallery?:ProductVariantLocaleCreateGalleryEntityRelationInput,
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
}

export type ProductVariantLocaleWithoutRootUpdateInput = {
		title?:string,
	description?:string,
	locale?:ProductVariantLocaleUpdateLocaleEntityRelationInput,
	gallery?:ProductVariantLocaleUpdateGalleryEntityRelationInput,
	descriptionSecondary?:string,
	titleTemplate?:string,
	_dummy_field_?:boolean
}

export type ProductVariantMeta = {
	__typename?: "ProductVariantMeta",
	id?:FieldMeta,
	shopifySku?:FieldMeta,
	product?:FieldMeta,
	locales?:FieldMeta,
	stores?:FieldMeta,
	title?:FieldMeta,
	heurekaId?:FieldMeta
}

export type ProductVariantOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	shopifySku?:OrderDirection,
	product?:ProductOrderBy,
	title?:OrderDirection,
	heurekaId?:OrderDirection
}

export type ProductVariantStore = {
	__typename?: "ProductVariantStore",
	_meta?:ProductVariantStoreMeta,
	id:UUID,
	price?:number,
	shopifyId:string,
	root?:ProductVariant,
	store?:Store,
	compareAtPrice?:number,
	storefrontId:string
}

export type ProductVariantStoreConnection = {
	__typename?: "ProductVariantStoreConnection",
	pageInfo:PageInfo,
	edges:ProductVariantStoreEdge[]
}

export type ProductVariantStoreCreateInput = {
		price?:number,
	shopifyId?:string,
	root?:ProductVariantStoreCreateRootEntityRelationInput,
	store?:ProductVariantStoreCreateStoreEntityRelationInput,
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
}

export type ProductVariantStoreCreateResult = {
	__typename?: "ProductVariantStoreCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ProductVariantStore,
	validation:_ValidationResult
}

export type ProductVariantStoreCreateRootEntityRelationInput = {
		connect?:ProductVariantUniqueWhere,
	create?:ProductVariantWithoutStoresCreateInput
}

export type ProductVariantStoreCreateStoreEntityRelationInput = {
		connect?:StoreUniqueWhere,
	create?:StoreWithoutProductVariantsCreateInput
}

export type ProductVariantStoreDeleteResult = {
	__typename?: "ProductVariantStoreDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ProductVariantStore
}

export type ProductVariantStoreEdge = {
	__typename?: "ProductVariantStoreEdge",
	node:ProductVariantStore
}

export type ProductVariantStoreMeta = {
	__typename?: "ProductVariantStoreMeta",
	id?:FieldMeta,
	price?:FieldMeta,
	shopifyId?:FieldMeta,
	root?:FieldMeta,
	store?:FieldMeta,
	compareAtPrice?:FieldMeta,
	storefrontId?:FieldMeta
}

export type ProductVariantStoreOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	price?:OrderDirection,
	shopifyId?:OrderDirection,
	root?:ProductVariantOrderBy,
	store?:StoreOrderBy,
	compareAtPrice?:OrderDirection,
	storefrontId?:OrderDirection
}

export type ProductVariantStoresByShopifyIdUniqueWhere = {
		shopifyId?:string
}

export type ProductVariantStoresByStorefrontIdUniqueWhere = {
		storefrontId?:string
}

export type ProductVariantStoresByStoreUniqueWhere = {
		store?:StoreUniqueWhere
}

export type ProductVariantStoreUniqueWhere = {
		id?:UUID,
	root?:ProductVariantUniqueWhere,
	store?:StoreUniqueWhere,
	shopifyId?:string,
	storefrontId?:string
}

export type ProductVariantStoreUpdateInput = {
		price?:number,
	shopifyId?:string,
	root?:ProductVariantStoreUpdateRootEntityRelationInput,
	store?:ProductVariantStoreUpdateStoreEntityRelationInput,
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
}

export type ProductVariantStoreUpdateResult = {
	__typename?: "ProductVariantStoreUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ProductVariantStore,
	validation:_ValidationResult
}

export type ProductVariantStoreUpdateRootEntityRelationInput = {
		create?:ProductVariantWithoutStoresCreateInput,
	update?:ProductVariantWithoutStoresUpdateInput,
	upsert?:ProductVariantStoreUpsertRootRelationInput,
	connect?:ProductVariantUniqueWhere
}

export type ProductVariantStoreUpdateStoreEntityRelationInput = {
		create?:StoreWithoutProductVariantsCreateInput,
	update?:StoreWithoutProductVariantsUpdateInput,
	upsert?:ProductVariantStoreUpsertStoreRelationInput,
	connect?:StoreUniqueWhere
}

export type ProductVariantStoreUpsertRootRelationInput = {
		update?:ProductVariantWithoutStoresUpdateInput,
	create?:ProductVariantWithoutStoresCreateInput
}

export type ProductVariantStoreUpsertStoreRelationInput = {
		update?:StoreWithoutProductVariantsUpdateInput,
	create?:StoreWithoutProductVariantsCreateInput
}

export type ProductVariantStoreWhere = {
		id?:UUIDCondition,
	price?:FloatCondition,
	shopifyId?:StringCondition,
	root?:ProductVariantWhere,
	store?:StoreWhere,
	compareAtPrice?:FloatCondition,
	storefrontId?:StringCondition,
	and?:ProductVariantStoreWhere[],
	or?:ProductVariantStoreWhere[],
	not?:ProductVariantStoreWhere
}

export type ProductVariantStoreWithoutRootCreateInput = {
		price?:number,
	shopifyId?:string,
	store?:ProductVariantStoreCreateStoreEntityRelationInput,
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
}

export type ProductVariantStoreWithoutRootUpdateInput = {
		price?:number,
	shopifyId?:string,
	store?:ProductVariantStoreUpdateStoreEntityRelationInput,
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
}

export type ProductVariantStoreWithoutStoreCreateInput = {
		price?:number,
	shopifyId?:string,
	root?:ProductVariantStoreCreateRootEntityRelationInput,
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
}

export type ProductVariantStoreWithoutStoreUpdateInput = {
		price?:number,
	shopifyId?:string,
	root?:ProductVariantStoreUpdateRootEntityRelationInput,
	compareAtPrice?:number,
	storefrontId?:string,
	_dummy_field_?:boolean
}

export type ProductVariantUniqueWhere = {
		id?:UUID,
	shopifySku?:string,
	locales?:ProductVariantLocaleUniqueWhere,
	stores?:ProductVariantStoreUniqueWhere
}

export type ProductVariantUpdateInput = {
		shopifySku?:string,
	product?:ProductVariantUpdateProductEntityRelationInput,
	locales?:ProductVariantUpdateLocalesEntityRelationInput[],
	stores?:ProductVariantUpdateStoresEntityRelationInput[],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
}

export type ProductVariantUpdateLocalesEntityRelationInput = {
		create?:ProductVariantLocaleWithoutRootCreateInput,
	update?:ProductVariantUpdateLocalesRelationInput,
	upsert?:ProductVariantUpsertLocalesRelationInput,
	connect?:ProductVariantLocaleUniqueWhere,
	disconnect?:ProductVariantLocaleUniqueWhere,
	delete?:ProductVariantLocaleUniqueWhere,
	alias?:string
}

export type ProductVariantUpdateLocalesRelationInput = {
		by?:ProductVariantLocaleUniqueWhere,
	data?:ProductVariantLocaleWithoutRootUpdateInput
}

export type ProductVariantUpdateProductEntityRelationInput = {
		create?:ProductWithoutProductVariantsCreateInput,
	update?:ProductWithoutProductVariantsUpdateInput,
	upsert?:ProductVariantUpsertProductRelationInput,
	connect?:ProductUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type ProductVariantUpdateResult = {
	__typename?: "ProductVariantUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ProductVariant,
	validation:_ValidationResult
}

export type ProductVariantUpdateStoresEntityRelationInput = {
		create?:ProductVariantStoreWithoutRootCreateInput,
	update?:ProductVariantUpdateStoresRelationInput,
	upsert?:ProductVariantUpsertStoresRelationInput,
	connect?:ProductVariantStoreUniqueWhere,
	disconnect?:ProductVariantStoreUniqueWhere,
	delete?:ProductVariantStoreUniqueWhere,
	alias?:string
}

export type ProductVariantUpdateStoresRelationInput = {
		by?:ProductVariantStoreUniqueWhere,
	data?:ProductVariantStoreWithoutRootUpdateInput
}

export type ProductVariantUpsertLocalesRelationInput = {
		by?:ProductVariantLocaleUniqueWhere,
	update?:ProductVariantLocaleWithoutRootUpdateInput,
	create?:ProductVariantLocaleWithoutRootCreateInput
}

export type ProductVariantUpsertProductRelationInput = {
		update?:ProductWithoutProductVariantsUpdateInput,
	create?:ProductWithoutProductVariantsCreateInput
}

export type ProductVariantUpsertStoresRelationInput = {
		by?:ProductVariantStoreUniqueWhere,
	update?:ProductVariantStoreWithoutRootUpdateInput,
	create?:ProductVariantStoreWithoutRootCreateInput
}

export type ProductVariantWhere = {
		id?:UUIDCondition,
	shopifySku?:StringCondition,
	product?:ProductWhere,
	locales?:ProductVariantLocaleWhere,
	stores?:ProductVariantStoreWhere,
	title?:StringCondition,
	heurekaId?:StringCondition,
	and?:ProductVariantWhere[],
	or?:ProductVariantWhere[],
	not?:ProductVariantWhere
}

export type ProductVariantWithoutLocalesCreateInput = {
		shopifySku?:string,
	product?:ProductVariantCreateProductEntityRelationInput,
	stores?:ProductVariantCreateStoresEntityRelationInput[],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
}

export type ProductVariantWithoutLocalesUpdateInput = {
		shopifySku?:string,
	product?:ProductVariantUpdateProductEntityRelationInput,
	stores?:ProductVariantUpdateStoresEntityRelationInput[],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
}

export type ProductVariantWithoutProductCreateInput = {
		shopifySku?:string,
	locales?:ProductVariantCreateLocalesEntityRelationInput[],
	stores?:ProductVariantCreateStoresEntityRelationInput[],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
}

export type ProductVariantWithoutProductUpdateInput = {
		shopifySku?:string,
	locales?:ProductVariantUpdateLocalesEntityRelationInput[],
	stores?:ProductVariantUpdateStoresEntityRelationInput[],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
}

export type ProductVariantWithoutStoresCreateInput = {
		shopifySku?:string,
	product?:ProductVariantCreateProductEntityRelationInput,
	locales?:ProductVariantCreateLocalesEntityRelationInput[],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
}

export type ProductVariantWithoutStoresUpdateInput = {
		shopifySku?:string,
	product?:ProductVariantUpdateProductEntityRelationInput,
	locales?:ProductVariantUpdateLocalesEntityRelationInput[],
	title?:string,
	heurekaId?:string,
	_dummy_field_?:boolean
}

export type ProductWhere = {
		id?:UUIDCondition,
	title?:StringCondition,
	shopifyHandle?:StringCondition,
	productType?:StringCondition,
	stores?:ProductStoreWhere,
	productVariants?:ProductVariantWhere,
	locales?:ProductLocaleWhere,
	and?:ProductWhere[],
	or?:ProductWhere[],
	not?:ProductWhere
}

export type ProductWithoutLocalesCreateInput = {
		title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:ProductCreateStoresEntityRelationInput[],
	productVariants?:ProductCreateProductVariantsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type ProductWithoutLocalesUpdateInput = {
		title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:ProductUpdateStoresEntityRelationInput[],
	productVariants?:ProductUpdateProductVariantsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type ProductWithoutProductVariantsCreateInput = {
		title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:ProductCreateStoresEntityRelationInput[],
	locales?:ProductCreateLocalesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type ProductWithoutProductVariantsUpdateInput = {
		title?:string,
	shopifyHandle?:string,
	productType?:string,
	stores?:ProductUpdateStoresEntityRelationInput[],
	locales?:ProductUpdateLocalesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type ProductWithoutStoresCreateInput = {
		title?:string,
	shopifyHandle?:string,
	productType?:string,
	productVariants?:ProductCreateProductVariantsEntityRelationInput[],
	locales?:ProductCreateLocalesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type ProductWithoutStoresUpdateInput = {
		title?:string,
	shopifyHandle?:string,
	productType?:string,
	productVariants?:ProductUpdateProductVariantsEntityRelationInput[],
	locales?:ProductUpdateLocalesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type Query = {
	__typename?: "Query",
	getAbandonedCheckoutUnsubscription?:AbandonedCheckoutUnsubscription,
	listAbandonedCheckoutUnsubscription:AbandonedCheckoutUnsubscription[],
	paginateAbandonedCheckoutUnsubscription:AbandonedCheckoutUnsubscriptionConnection,
	validateCreateAbandonedCheckoutUnsubscription:_ValidationResult,
	validateUpdateAbandonedCheckoutUnsubscription:_ValidationResult,
	getAbandonedCheckoutNotification?:AbandonedCheckoutNotification,
	listAbandonedCheckoutNotification:AbandonedCheckoutNotification[],
	paginateAbandonedCheckoutNotification:AbandonedCheckoutNotificationConnection,
	validateCreateAbandonedCheckoutNotification:_ValidationResult,
	validateUpdateAbandonedCheckoutNotification:_ValidationResult,
	getShopifyLineItem?:ShopifyLineItem,
	listShopifyLineItem:ShopifyLineItem[],
	paginateShopifyLineItem:ShopifyLineItemConnection,
	validateCreateShopifyLineItem:_ValidationResult,
	validateUpdateShopifyLineItem:_ValidationResult,
	getShopifyOrder?:ShopifyOrder,
	listShopifyOrder:ShopifyOrder[],
	paginateShopifyOrder:ShopifyOrderConnection,
	validateCreateShopifyOrder:_ValidationResult,
	validateUpdateShopifyOrder:_ValidationResult,
	getTranslationValue?:TranslationValue,
	listTranslationValue:TranslationValue[],
	paginateTranslationValue:TranslationValueConnection,
	validateCreateTranslationValue:_ValidationResult,
	validateUpdateTranslationValue:_ValidationResult,
	getTranslationKey?:TranslationKey,
	listTranslationKey:TranslationKey[],
	paginateTranslationKey:TranslationKeyConnection,
	validateCreateTranslationKey:_ValidationResult,
	validateUpdateTranslationKey:_ValidationResult,
	getTranslationCatalogue?:TranslationCatalogue,
	listTranslationCatalogue:TranslationCatalogue[],
	paginateTranslationCatalogue:TranslationCatalogueConnection,
	validateCreateTranslationCatalogue:_ValidationResult,
	validateUpdateTranslationCatalogue:_ValidationResult,
	getTranslationDomain?:TranslationDomain,
	listTranslationDomain:TranslationDomain[],
	paginateTranslationDomain:TranslationDomainConnection,
	validateCreateTranslationDomain:_ValidationResult,
	validateUpdateTranslationDomain:_ValidationResult,
	getProductLocale?:ProductLocale,
	listProductLocale:ProductLocale[],
	paginateProductLocale:ProductLocaleConnection,
	validateCreateProductLocale:_ValidationResult,
	validateUpdateProductLocale:_ValidationResult,
	getMedia?:Media,
	listMedia:Media[],
	paginateMedia:MediaConnection,
	validateCreateMedia:_ValidationResult,
	validateUpdateMedia:_ValidationResult,
	getIconListBlockItem?:IconListBlockItem,
	listIconListBlockItem:IconListBlockItem[],
	paginateIconListBlockItem:IconListBlockItemConnection,
	validateCreateIconListBlockItem:_ValidationResult,
	validateUpdateIconListBlockItem:_ValidationResult,
	getIconListBlock?:IconListBlock,
	listIconListBlock:IconListBlock[],
	paginateIconListBlock:IconListBlockConnection,
	validateCreateIconListBlock:_ValidationResult,
	validateUpdateIconListBlock:_ValidationResult,
	getFeatureListItem?:FeatureListItem,
	listFeatureListItem:FeatureListItem[],
	paginateFeatureListItem:FeatureListItemConnection,
	validateCreateFeatureListItem:_ValidationResult,
	validateUpdateFeatureListItem:_ValidationResult,
	getFeatureList?:FeatureList,
	listFeatureList:FeatureList[],
	paginateFeatureList:FeatureListConnection,
	validateCreateFeatureList:_ValidationResult,
	validateUpdateFeatureList:_ValidationResult,
	getFaqItem?:FaqItem,
	listFaqItem:FaqItem[],
	paginateFaqItem:FaqItemConnection,
	validateCreateFaqItem:_ValidationResult,
	validateUpdateFaqItem:_ValidationResult,
	getFaq?:Faq,
	listFaq:Faq[],
	paginateFaq:FaqConnection,
	validateCreateFaq:_ValidationResult,
	validateUpdateFaq:_ValidationResult,
	getContentBlock?:ContentBlock,
	listContentBlock:ContentBlock[],
	paginateContentBlock:ContentBlockConnection,
	validateCreateContentBlock:_ValidationResult,
	validateUpdateContentBlock:_ValidationResult,
	getContent?:Content,
	listContent:Content[],
	paginateContent:ContentConnection,
	validateCreateContent:_ValidationResult,
	validateUpdateContent:_ValidationResult,
	getLink?:Link,
	listLink:Link[],
	paginateLink:LinkConnection,
	validateCreateLink:_ValidationResult,
	validateUpdateLink:_ValidationResult,
	getReviewLocale?:ReviewLocale,
	listReviewLocale:ReviewLocale[],
	paginateReviewLocale:ReviewLocaleConnection,
	validateCreateReviewLocale:_ValidationResult,
	validateUpdateReviewLocale:_ValidationResult,
	getReview?:Review,
	listReview:Review[],
	paginateReview:ReviewConnection,
	validateCreateReview:_ValidationResult,
	validateUpdateReview:_ValidationResult,
	getGalleryItem?:GalleryItem,
	listGalleryItem:GalleryItem[],
	paginateGalleryItem:GalleryItemConnection,
	validateCreateGalleryItem:_ValidationResult,
	validateUpdateGalleryItem:_ValidationResult,
	getGallery?:Gallery,
	listGallery:Gallery[],
	paginateGallery:GalleryConnection,
	validateCreateGallery:_ValidationResult,
	validateUpdateGallery:_ValidationResult,
	getProductVariantStore?:ProductVariantStore,
	listProductVariantStore:ProductVariantStore[],
	paginateProductVariantStore:ProductVariantStoreConnection,
	validateCreateProductVariantStore:_ValidationResult,
	validateUpdateProductVariantStore:_ValidationResult,
	getProductStore?:ProductStore,
	listProductStore:ProductStore[],
	paginateProductStore:ProductStoreConnection,
	validateCreateProductStore:_ValidationResult,
	validateUpdateProductStore:_ValidationResult,
	getIconListItem?:IconListItem,
	listIconListItem:IconListItem[],
	paginateIconListItem:IconListItemConnection,
	validateCreateIconListItem:_ValidationResult,
	validateUpdateIconListItem:_ValidationResult,
	getIconList?:IconList,
	listIconList:IconList[],
	paginateIconList:IconListConnection,
	validateCreateIconList:_ValidationResult,
	validateUpdateIconList:_ValidationResult,
	getIconGroupListItem?:IconGroupListItem,
	listIconGroupListItem:IconGroupListItem[],
	paginateIconGroupListItem:IconGroupListItemConnection,
	validateCreateIconGroupListItem:_ValidationResult,
	validateUpdateIconGroupListItem:_ValidationResult,
	getIconGroupList?:IconGroupList,
	listIconGroupList:IconGroupList[],
	paginateIconGroupList:IconGroupListConnection,
	validateCreateIconGroupList:_ValidationResult,
	validateUpdateIconGroupList:_ValidationResult,
	getProductVariantLocale?:ProductVariantLocale,
	listProductVariantLocale:ProductVariantLocale[],
	paginateProductVariantLocale:ProductVariantLocaleConnection,
	validateCreateProductVariantLocale:_ValidationResult,
	validateUpdateProductVariantLocale:_ValidationResult,
	getProductVariant?:ProductVariant,
	listProductVariant:ProductVariant[],
	paginateProductVariant:ProductVariantConnection,
	validateCreateProductVariant:_ValidationResult,
	validateUpdateProductVariant:_ValidationResult,
	getProduct?:Product,
	listProduct:Product[],
	paginateProduct:ProductConnection,
	validateCreateProduct:_ValidationResult,
	validateUpdateProduct:_ValidationResult,
	getImageLocale?:ImageLocale,
	listImageLocale:ImageLocale[],
	paginateImageLocale:ImageLocaleConnection,
	validateCreateImageLocale:_ValidationResult,
	validateUpdateImageLocale:_ValidationResult,
	getStore?:Store,
	listStore:Store[],
	paginateStore:StoreConnection,
	validateCreateStore:_ValidationResult,
	validateUpdateStore:_ValidationResult,
	getAdminNote?:AdminNote,
	listAdminNote:AdminNote[],
	paginateAdminNote:AdminNoteConnection,
	validateCreateAdminNote:_ValidationResult,
	validateUpdateAdminNote:_ValidationResult,
	getPageLocale?:PageLocale,
	listPageLocale:PageLocale[],
	paginatePageLocale:PageLocaleConnection,
	validateCreatePageLocale:_ValidationResult,
	validateUpdatePageLocale:_ValidationResult,
	getPage?:Page,
	listPage:Page[],
	paginatePage:PageConnection,
	validateCreatePage:_ValidationResult,
	validateUpdatePage:_ValidationResult,
	getImageXImageList?:ImageXImageList,
	listImageXImageList:ImageXImageList[],
	paginateImageXImageList:ImageXImageListConnection,
	validateCreateImageXImageList:_ValidationResult,
	validateUpdateImageXImageList:_ValidationResult,
	getIconGroupLocale?:IconGroupLocale,
	listIconGroupLocale:IconGroupLocale[],
	paginateIconGroupLocale:IconGroupLocaleConnection,
	validateCreateIconGroupLocale:_ValidationResult,
	validateUpdateIconGroupLocale:_ValidationResult,
	getIconLocale?:IconLocale,
	listIconLocale:IconLocale[],
	paginateIconLocale:IconLocaleConnection,
	validateCreateIconLocale:_ValidationResult,
	validateUpdateIconLocale:_ValidationResult,
	getIconGroup?:IconGroup,
	listIconGroup:IconGroup[],
	paginateIconGroup:IconGroupConnection,
	validateCreateIconGroup:_ValidationResult,
	validateUpdateIconGroup:_ValidationResult,
	getIcon?:Icon,
	listIcon:Icon[],
	paginateIcon:IconConnection,
	validateCreateIcon:_ValidationResult,
	validateUpdateIcon:_ValidationResult,
	getLocale?:Locale,
	listLocale:Locale[],
	paginateLocale:LocaleConnection,
	validateCreateLocale:_ValidationResult,
	validateUpdateLocale:_ValidationResult,
	getSeoLocale?:SeoLocale,
	listSeoLocale:SeoLocale[],
	paginateSeoLocale:SeoLocaleConnection,
	validateCreateSeoLocale:_ValidationResult,
	validateUpdateSeoLocale:_ValidationResult,
	getSeo?:Seo,
	listSeo:Seo[],
	paginateSeo:SeoConnection,
	validateCreateSeo:_ValidationResult,
	validateUpdateSeo:_ValidationResult,
	getLinkable?:Linkable,
	listLinkable:Linkable[],
	paginateLinkable:LinkableConnection,
	validateCreateLinkable:_ValidationResult,
	validateUpdateLinkable:_ValidationResult,
	getImageList?:ImageList,
	listImageList:ImageList[],
	paginateImageList:ImageListConnection,
	validateCreateImageList:_ValidationResult,
	validateUpdateImageList:_ValidationResult,
	getImage?:Image,
	listImage:Image[],
	paginateImage:ImageConnection,
	validateCreateImage:_ValidationResult,
	validateUpdateImage:_ValidationResult,
	transaction?:QueryTransaction,
	_info?:Info,
	schema?:_Schema,
	s3DummyQuery?:string
}

export type QueryTransaction = {
	__typename?: "QueryTransaction",
	getAbandonedCheckoutUnsubscription?:AbandonedCheckoutUnsubscription,
	listAbandonedCheckoutUnsubscription:AbandonedCheckoutUnsubscription[],
	paginateAbandonedCheckoutUnsubscription:AbandonedCheckoutUnsubscriptionConnection,
	validateCreateAbandonedCheckoutUnsubscription:_ValidationResult,
	validateUpdateAbandonedCheckoutUnsubscription:_ValidationResult,
	getAbandonedCheckoutNotification?:AbandonedCheckoutNotification,
	listAbandonedCheckoutNotification:AbandonedCheckoutNotification[],
	paginateAbandonedCheckoutNotification:AbandonedCheckoutNotificationConnection,
	validateCreateAbandonedCheckoutNotification:_ValidationResult,
	validateUpdateAbandonedCheckoutNotification:_ValidationResult,
	getShopifyLineItem?:ShopifyLineItem,
	listShopifyLineItem:ShopifyLineItem[],
	paginateShopifyLineItem:ShopifyLineItemConnection,
	validateCreateShopifyLineItem:_ValidationResult,
	validateUpdateShopifyLineItem:_ValidationResult,
	getShopifyOrder?:ShopifyOrder,
	listShopifyOrder:ShopifyOrder[],
	paginateShopifyOrder:ShopifyOrderConnection,
	validateCreateShopifyOrder:_ValidationResult,
	validateUpdateShopifyOrder:_ValidationResult,
	getTranslationValue?:TranslationValue,
	listTranslationValue:TranslationValue[],
	paginateTranslationValue:TranslationValueConnection,
	validateCreateTranslationValue:_ValidationResult,
	validateUpdateTranslationValue:_ValidationResult,
	getTranslationKey?:TranslationKey,
	listTranslationKey:TranslationKey[],
	paginateTranslationKey:TranslationKeyConnection,
	validateCreateTranslationKey:_ValidationResult,
	validateUpdateTranslationKey:_ValidationResult,
	getTranslationCatalogue?:TranslationCatalogue,
	listTranslationCatalogue:TranslationCatalogue[],
	paginateTranslationCatalogue:TranslationCatalogueConnection,
	validateCreateTranslationCatalogue:_ValidationResult,
	validateUpdateTranslationCatalogue:_ValidationResult,
	getTranslationDomain?:TranslationDomain,
	listTranslationDomain:TranslationDomain[],
	paginateTranslationDomain:TranslationDomainConnection,
	validateCreateTranslationDomain:_ValidationResult,
	validateUpdateTranslationDomain:_ValidationResult,
	getProductLocale?:ProductLocale,
	listProductLocale:ProductLocale[],
	paginateProductLocale:ProductLocaleConnection,
	validateCreateProductLocale:_ValidationResult,
	validateUpdateProductLocale:_ValidationResult,
	getMedia?:Media,
	listMedia:Media[],
	paginateMedia:MediaConnection,
	validateCreateMedia:_ValidationResult,
	validateUpdateMedia:_ValidationResult,
	getIconListBlockItem?:IconListBlockItem,
	listIconListBlockItem:IconListBlockItem[],
	paginateIconListBlockItem:IconListBlockItemConnection,
	validateCreateIconListBlockItem:_ValidationResult,
	validateUpdateIconListBlockItem:_ValidationResult,
	getIconListBlock?:IconListBlock,
	listIconListBlock:IconListBlock[],
	paginateIconListBlock:IconListBlockConnection,
	validateCreateIconListBlock:_ValidationResult,
	validateUpdateIconListBlock:_ValidationResult,
	getFeatureListItem?:FeatureListItem,
	listFeatureListItem:FeatureListItem[],
	paginateFeatureListItem:FeatureListItemConnection,
	validateCreateFeatureListItem:_ValidationResult,
	validateUpdateFeatureListItem:_ValidationResult,
	getFeatureList?:FeatureList,
	listFeatureList:FeatureList[],
	paginateFeatureList:FeatureListConnection,
	validateCreateFeatureList:_ValidationResult,
	validateUpdateFeatureList:_ValidationResult,
	getFaqItem?:FaqItem,
	listFaqItem:FaqItem[],
	paginateFaqItem:FaqItemConnection,
	validateCreateFaqItem:_ValidationResult,
	validateUpdateFaqItem:_ValidationResult,
	getFaq?:Faq,
	listFaq:Faq[],
	paginateFaq:FaqConnection,
	validateCreateFaq:_ValidationResult,
	validateUpdateFaq:_ValidationResult,
	getContentBlock?:ContentBlock,
	listContentBlock:ContentBlock[],
	paginateContentBlock:ContentBlockConnection,
	validateCreateContentBlock:_ValidationResult,
	validateUpdateContentBlock:_ValidationResult,
	getContent?:Content,
	listContent:Content[],
	paginateContent:ContentConnection,
	validateCreateContent:_ValidationResult,
	validateUpdateContent:_ValidationResult,
	getLink?:Link,
	listLink:Link[],
	paginateLink:LinkConnection,
	validateCreateLink:_ValidationResult,
	validateUpdateLink:_ValidationResult,
	getReviewLocale?:ReviewLocale,
	listReviewLocale:ReviewLocale[],
	paginateReviewLocale:ReviewLocaleConnection,
	validateCreateReviewLocale:_ValidationResult,
	validateUpdateReviewLocale:_ValidationResult,
	getReview?:Review,
	listReview:Review[],
	paginateReview:ReviewConnection,
	validateCreateReview:_ValidationResult,
	validateUpdateReview:_ValidationResult,
	getGalleryItem?:GalleryItem,
	listGalleryItem:GalleryItem[],
	paginateGalleryItem:GalleryItemConnection,
	validateCreateGalleryItem:_ValidationResult,
	validateUpdateGalleryItem:_ValidationResult,
	getGallery?:Gallery,
	listGallery:Gallery[],
	paginateGallery:GalleryConnection,
	validateCreateGallery:_ValidationResult,
	validateUpdateGallery:_ValidationResult,
	getProductVariantStore?:ProductVariantStore,
	listProductVariantStore:ProductVariantStore[],
	paginateProductVariantStore:ProductVariantStoreConnection,
	validateCreateProductVariantStore:_ValidationResult,
	validateUpdateProductVariantStore:_ValidationResult,
	getProductStore?:ProductStore,
	listProductStore:ProductStore[],
	paginateProductStore:ProductStoreConnection,
	validateCreateProductStore:_ValidationResult,
	validateUpdateProductStore:_ValidationResult,
	getIconListItem?:IconListItem,
	listIconListItem:IconListItem[],
	paginateIconListItem:IconListItemConnection,
	validateCreateIconListItem:_ValidationResult,
	validateUpdateIconListItem:_ValidationResult,
	getIconList?:IconList,
	listIconList:IconList[],
	paginateIconList:IconListConnection,
	validateCreateIconList:_ValidationResult,
	validateUpdateIconList:_ValidationResult,
	getIconGroupListItem?:IconGroupListItem,
	listIconGroupListItem:IconGroupListItem[],
	paginateIconGroupListItem:IconGroupListItemConnection,
	validateCreateIconGroupListItem:_ValidationResult,
	validateUpdateIconGroupListItem:_ValidationResult,
	getIconGroupList?:IconGroupList,
	listIconGroupList:IconGroupList[],
	paginateIconGroupList:IconGroupListConnection,
	validateCreateIconGroupList:_ValidationResult,
	validateUpdateIconGroupList:_ValidationResult,
	getProductVariantLocale?:ProductVariantLocale,
	listProductVariantLocale:ProductVariantLocale[],
	paginateProductVariantLocale:ProductVariantLocaleConnection,
	validateCreateProductVariantLocale:_ValidationResult,
	validateUpdateProductVariantLocale:_ValidationResult,
	getProductVariant?:ProductVariant,
	listProductVariant:ProductVariant[],
	paginateProductVariant:ProductVariantConnection,
	validateCreateProductVariant:_ValidationResult,
	validateUpdateProductVariant:_ValidationResult,
	getProduct?:Product,
	listProduct:Product[],
	paginateProduct:ProductConnection,
	validateCreateProduct:_ValidationResult,
	validateUpdateProduct:_ValidationResult,
	getImageLocale?:ImageLocale,
	listImageLocale:ImageLocale[],
	paginateImageLocale:ImageLocaleConnection,
	validateCreateImageLocale:_ValidationResult,
	validateUpdateImageLocale:_ValidationResult,
	getStore?:Store,
	listStore:Store[],
	paginateStore:StoreConnection,
	validateCreateStore:_ValidationResult,
	validateUpdateStore:_ValidationResult,
	getAdminNote?:AdminNote,
	listAdminNote:AdminNote[],
	paginateAdminNote:AdminNoteConnection,
	validateCreateAdminNote:_ValidationResult,
	validateUpdateAdminNote:_ValidationResult,
	getPageLocale?:PageLocale,
	listPageLocale:PageLocale[],
	paginatePageLocale:PageLocaleConnection,
	validateCreatePageLocale:_ValidationResult,
	validateUpdatePageLocale:_ValidationResult,
	getPage?:Page,
	listPage:Page[],
	paginatePage:PageConnection,
	validateCreatePage:_ValidationResult,
	validateUpdatePage:_ValidationResult,
	getImageXImageList?:ImageXImageList,
	listImageXImageList:ImageXImageList[],
	paginateImageXImageList:ImageXImageListConnection,
	validateCreateImageXImageList:_ValidationResult,
	validateUpdateImageXImageList:_ValidationResult,
	getIconGroupLocale?:IconGroupLocale,
	listIconGroupLocale:IconGroupLocale[],
	paginateIconGroupLocale:IconGroupLocaleConnection,
	validateCreateIconGroupLocale:_ValidationResult,
	validateUpdateIconGroupLocale:_ValidationResult,
	getIconLocale?:IconLocale,
	listIconLocale:IconLocale[],
	paginateIconLocale:IconLocaleConnection,
	validateCreateIconLocale:_ValidationResult,
	validateUpdateIconLocale:_ValidationResult,
	getIconGroup?:IconGroup,
	listIconGroup:IconGroup[],
	paginateIconGroup:IconGroupConnection,
	validateCreateIconGroup:_ValidationResult,
	validateUpdateIconGroup:_ValidationResult,
	getIcon?:Icon,
	listIcon:Icon[],
	paginateIcon:IconConnection,
	validateCreateIcon:_ValidationResult,
	validateUpdateIcon:_ValidationResult,
	getLocale?:Locale,
	listLocale:Locale[],
	paginateLocale:LocaleConnection,
	validateCreateLocale:_ValidationResult,
	validateUpdateLocale:_ValidationResult,
	getSeoLocale?:SeoLocale,
	listSeoLocale:SeoLocale[],
	paginateSeoLocale:SeoLocaleConnection,
	validateCreateSeoLocale:_ValidationResult,
	validateUpdateSeoLocale:_ValidationResult,
	getSeo?:Seo,
	listSeo:Seo[],
	paginateSeo:SeoConnection,
	validateCreateSeo:_ValidationResult,
	validateUpdateSeo:_ValidationResult,
	getLinkable?:Linkable,
	listLinkable:Linkable[],
	paginateLinkable:LinkableConnection,
	validateCreateLinkable:_ValidationResult,
	validateUpdateLinkable:_ValidationResult,
	getImageList?:ImageList,
	listImageList:ImageList[],
	paginateImageList:ImageListConnection,
	validateCreateImageList:_ValidationResult,
	validateUpdateImageList:_ValidationResult,
	getImage?:Image,
	listImage:Image[],
	paginateImage:ImageConnection,
	validateCreateImage:_ValidationResult,
	validateUpdateImage:_ValidationResult
}

export type Review = {
	__typename?: "Review",
	_meta?:ReviewMeta,
	id:UUID,
	username:string,
	createdAt:DateTime,
	locales:ReviewLocale[],
	localesByLocale?:ReviewLocale
}

export type ReviewConnection = {
	__typename?: "ReviewConnection",
	pageInfo:PageInfo,
	edges:ReviewEdge[]
}

export type ReviewCreateInput = {
		username?:string,
	createdAt?:DateTime,
	locales?:ReviewCreateLocalesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type ReviewCreateLocalesEntityRelationInput = {
		connect?:ReviewLocaleUniqueWhere,
	create?:ReviewLocaleWithoutReviewCreateInput,
	alias?:string
}

export type ReviewCreateResult = {
	__typename?: "ReviewCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Review,
	validation:_ValidationResult
}

export type ReviewDeleteResult = {
	__typename?: "ReviewDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Review
}

export type ReviewEdge = {
	__typename?: "ReviewEdge",
	node:Review
}

export type ReviewLocale = {
	__typename?: "ReviewLocale",
	_meta?:ReviewLocaleMeta,
	id:UUID,
	content:string,
	image?:Image,
	locale?:Locale,
	review?:Review
}

export type ReviewLocaleConnection = {
	__typename?: "ReviewLocaleConnection",
	pageInfo:PageInfo,
	edges:ReviewLocaleEdge[]
}

export type ReviewLocaleCreateImageEntityRelationInput = {
		connect?:ImageUniqueWhere,
	create?:ImageCreateInput
}

export type ReviewLocaleCreateInput = {
		content?:string,
	image?:ReviewLocaleCreateImageEntityRelationInput,
	locale?:ReviewLocaleCreateLocaleEntityRelationInput,
	review?:ReviewLocaleCreateReviewEntityRelationInput,
	_dummy_field_?:boolean
}

export type ReviewLocaleCreateLocaleEntityRelationInput = {
		connect?:LocaleUniqueWhere,
	create?:LocaleCreateInput
}

export type ReviewLocaleCreateResult = {
	__typename?: "ReviewLocaleCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ReviewLocale,
	validation:_ValidationResult
}

export type ReviewLocaleCreateReviewEntityRelationInput = {
		connect?:ReviewUniqueWhere,
	create?:ReviewWithoutLocalesCreateInput
}

export type ReviewLocaleDeleteResult = {
	__typename?: "ReviewLocaleDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ReviewLocale
}

export type ReviewLocaleEdge = {
	__typename?: "ReviewLocaleEdge",
	node:ReviewLocale
}

export type ReviewLocaleMeta = {
	__typename?: "ReviewLocaleMeta",
	id?:FieldMeta,
	content?:FieldMeta,
	image?:FieldMeta,
	locale?:FieldMeta,
	review?:FieldMeta
}

export type ReviewLocaleOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	content?:OrderDirection,
	image?:ImageOrderBy,
	locale?:LocaleOrderBy,
	review?:ReviewOrderBy
}

export type ReviewLocalesByLocaleUniqueWhere = {
		locale?:LocaleUniqueWhere
}

export type ReviewLocaleUniqueWhere = {
		id?:UUID,
	review?:ReviewUniqueWhere,
	locale?:LocaleUniqueWhere
}

export type ReviewLocaleUpdateImageEntityRelationInput = {
		create?:ImageCreateInput,
	update?:ImageUpdateInput,
	upsert?:ReviewLocaleUpsertImageRelationInput,
	connect?:ImageUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type ReviewLocaleUpdateInput = {
		content?:string,
	image?:ReviewLocaleUpdateImageEntityRelationInput,
	locale?:ReviewLocaleUpdateLocaleEntityRelationInput,
	review?:ReviewLocaleUpdateReviewEntityRelationInput,
	_dummy_field_?:boolean
}

export type ReviewLocaleUpdateLocaleEntityRelationInput = {
		create?:LocaleCreateInput,
	update?:LocaleUpdateInput,
	upsert?:ReviewLocaleUpsertLocaleRelationInput,
	connect?:LocaleUniqueWhere
}

export type ReviewLocaleUpdateResult = {
	__typename?: "ReviewLocaleUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ReviewLocale,
	validation:_ValidationResult
}

export type ReviewLocaleUpdateReviewEntityRelationInput = {
		create?:ReviewWithoutLocalesCreateInput,
	update?:ReviewWithoutLocalesUpdateInput,
	upsert?:ReviewLocaleUpsertReviewRelationInput,
	connect?:ReviewUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type ReviewLocaleUpsertImageRelationInput = {
		update?:ImageUpdateInput,
	create?:ImageCreateInput
}

export type ReviewLocaleUpsertLocaleRelationInput = {
		update?:LocaleUpdateInput,
	create?:LocaleCreateInput
}

export type ReviewLocaleUpsertReviewRelationInput = {
		update?:ReviewWithoutLocalesUpdateInput,
	create?:ReviewWithoutLocalesCreateInput
}

export type ReviewLocaleWhere = {
		id?:UUIDCondition,
	content?:StringCondition,
	image?:ImageWhere,
	locale?:LocaleWhere,
	review?:ReviewWhere,
	and?:ReviewLocaleWhere[],
	or?:ReviewLocaleWhere[],
	not?:ReviewLocaleWhere
}

export type ReviewLocaleWithoutReviewCreateInput = {
		content?:string,
	image?:ReviewLocaleCreateImageEntityRelationInput,
	locale?:ReviewLocaleCreateLocaleEntityRelationInput,
	_dummy_field_?:boolean
}

export type ReviewLocaleWithoutReviewUpdateInput = {
		content?:string,
	image?:ReviewLocaleUpdateImageEntityRelationInput,
	locale?:ReviewLocaleUpdateLocaleEntityRelationInput,
	_dummy_field_?:boolean
}

export type ReviewMeta = {
	__typename?: "ReviewMeta",
	id?:FieldMeta,
	username?:FieldMeta,
	createdAt?:FieldMeta,
	locales?:FieldMeta
}

export type ReviewOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	username?:OrderDirection,
	createdAt?:OrderDirection
}

export type ReviewUniqueWhere = {
		id?:UUID,
	locales?:ReviewLocaleUniqueWhere
}

export type ReviewUpdateInput = {
		username?:string,
	createdAt?:DateTime,
	locales?:ReviewUpdateLocalesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type ReviewUpdateLocalesEntityRelationInput = {
		create?:ReviewLocaleWithoutReviewCreateInput,
	update?:ReviewUpdateLocalesRelationInput,
	upsert?:ReviewUpsertLocalesRelationInput,
	connect?:ReviewLocaleUniqueWhere,
	disconnect?:ReviewLocaleUniqueWhere,
	delete?:ReviewLocaleUniqueWhere,
	alias?:string
}

export type ReviewUpdateLocalesRelationInput = {
		by?:ReviewLocaleUniqueWhere,
	data?:ReviewLocaleWithoutReviewUpdateInput
}

export type ReviewUpdateResult = {
	__typename?: "ReviewUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Review,
	validation:_ValidationResult
}

export type ReviewUpsertLocalesRelationInput = {
		by?:ReviewLocaleUniqueWhere,
	update?:ReviewLocaleWithoutReviewUpdateInput,
	create?:ReviewLocaleWithoutReviewCreateInput
}

export enum ReviewView {
	newest = "newest",
	selection = "selection"
}

export type ReviewViewEnumCondition = {
		and?:ReviewViewEnumCondition[],
	or?:ReviewViewEnumCondition[],
	not?:ReviewViewEnumCondition,
	eq?:ReviewView,
	null?:boolean,
	isNull?:boolean,
	notEq?:ReviewView,
	in?:ReviewView[],
	notIn?:ReviewView[],
	lt?:ReviewView,
	lte?:ReviewView,
	gt?:ReviewView,
	gte?:ReviewView
}

export type ReviewWhere = {
		id?:UUIDCondition,
	username?:StringCondition,
	createdAt?:DateTimeCondition,
	locales?:ReviewLocaleWhere,
	and?:ReviewWhere[],
	or?:ReviewWhere[],
	not?:ReviewWhere
}

export type ReviewWithoutLocalesCreateInput = {
		username?:string,
	createdAt?:DateTime,
	_dummy_field_?:boolean
}

export type ReviewWithoutLocalesUpdateInput = {
		username?:string,
	createdAt?:DateTime,
	_dummy_field_?:boolean
}

export type S3Header = {
	__typename?: "S3Header",
	key:string,
	value:string
}

export type S3SignedRead = {
	__typename?: "S3SignedRead",
	url:string,
	headers:S3Header[],
	method:string,
	/** Allowed patterns:
** */
	objectKey:string,
	bucket:string
}

export type S3SignedUpload = {
	__typename?: "S3SignedUpload",
	url:string,
	headers:S3Header[],
	method:string,
	/** Allowed patterns:
** */
	objectKey:string,
	bucket:string,
	publicUrl:string
}

export type Seo = {
	__typename?: "Seo",
	_meta?:SeoMeta,
	id:UUID,
	ogImage?:Image,
	locales:SeoLocale[],
	noindex?:boolean,
	localesByLocale?:SeoLocale
}

export type SeoConnection = {
	__typename?: "SeoConnection",
	pageInfo:PageInfo,
	edges:SeoEdge[]
}

export type SeoCreateInput = {
		ogImage?:SeoCreateOgImageEntityRelationInput,
	locales?:SeoCreateLocalesEntityRelationInput[],
	noindex?:boolean,
	_dummy_field_?:boolean
}

export type SeoCreateLocalesEntityRelationInput = {
		connect?:SeoLocaleUniqueWhere,
	create?:SeoLocaleWithoutRootCreateInput,
	alias?:string
}

export type SeoCreateOgImageEntityRelationInput = {
		connect?:ImageUniqueWhere,
	create?:ImageCreateInput
}

export type SeoCreateResult = {
	__typename?: "SeoCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Seo,
	validation:_ValidationResult
}

export type SeoDeleteResult = {
	__typename?: "SeoDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Seo
}

export type SeoEdge = {
	__typename?: "SeoEdge",
	node:Seo
}

export type SeoLocale = {
	__typename?: "SeoLocale",
	_meta?:SeoLocaleMeta,
	id:UUID,
	title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	root?:Seo,
	locale?:Locale
}

export type SeoLocaleConnection = {
	__typename?: "SeoLocaleConnection",
	pageInfo:PageInfo,
	edges:SeoLocaleEdge[]
}

export type SeoLocaleCreateInput = {
		title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	root?:SeoLocaleCreateRootEntityRelationInput,
	locale?:SeoLocaleCreateLocaleEntityRelationInput,
	_dummy_field_?:boolean
}

export type SeoLocaleCreateLocaleEntityRelationInput = {
		connect?:LocaleUniqueWhere,
	create?:LocaleWithoutSeosCreateInput
}

export type SeoLocaleCreateResult = {
	__typename?: "SeoLocaleCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:SeoLocale,
	validation:_ValidationResult
}

export type SeoLocaleCreateRootEntityRelationInput = {
		connect?:SeoUniqueWhere,
	create?:SeoWithoutLocalesCreateInput
}

export type SeoLocaleDeleteResult = {
	__typename?: "SeoLocaleDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:SeoLocale
}

export type SeoLocaleEdge = {
	__typename?: "SeoLocaleEdge",
	node:SeoLocale
}

export type SeoLocaleMeta = {
	__typename?: "SeoLocaleMeta",
	id?:FieldMeta,
	title?:FieldMeta,
	description?:FieldMeta,
	ogTitle?:FieldMeta,
	ogDescription?:FieldMeta,
	root?:FieldMeta,
	locale?:FieldMeta
}

export type SeoLocaleOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	title?:OrderDirection,
	description?:OrderDirection,
	ogTitle?:OrderDirection,
	ogDescription?:OrderDirection,
	root?:SeoOrderBy,
	locale?:LocaleOrderBy
}

export type SeoLocalesByLocaleUniqueWhere = {
		locale?:LocaleUniqueWhere
}

export type SeoLocaleUniqueWhere = {
		id?:UUID,
	root?:SeoUniqueWhere,
	locale?:LocaleUniqueWhere
}

export type SeoLocaleUpdateInput = {
		title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	root?:SeoLocaleUpdateRootEntityRelationInput,
	locale?:SeoLocaleUpdateLocaleEntityRelationInput,
	_dummy_field_?:boolean
}

export type SeoLocaleUpdateLocaleEntityRelationInput = {
		create?:LocaleWithoutSeosCreateInput,
	update?:LocaleWithoutSeosUpdateInput,
	upsert?:SeoLocaleUpsertLocaleRelationInput,
	connect?:LocaleUniqueWhere
}

export type SeoLocaleUpdateResult = {
	__typename?: "SeoLocaleUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:SeoLocale,
	validation:_ValidationResult
}

export type SeoLocaleUpdateRootEntityRelationInput = {
		create?:SeoWithoutLocalesCreateInput,
	update?:SeoWithoutLocalesUpdateInput,
	upsert?:SeoLocaleUpsertRootRelationInput,
	connect?:SeoUniqueWhere
}

export type SeoLocaleUpsertLocaleRelationInput = {
		update?:LocaleWithoutSeosUpdateInput,
	create?:LocaleWithoutSeosCreateInput
}

export type SeoLocaleUpsertRootRelationInput = {
		update?:SeoWithoutLocalesUpdateInput,
	create?:SeoWithoutLocalesCreateInput
}

export type SeoLocaleWhere = {
		id?:UUIDCondition,
	title?:StringCondition,
	description?:StringCondition,
	ogTitle?:StringCondition,
	ogDescription?:StringCondition,
	root?:SeoWhere,
	locale?:LocaleWhere,
	and?:SeoLocaleWhere[],
	or?:SeoLocaleWhere[],
	not?:SeoLocaleWhere
}

export type SeoLocaleWithoutLocaleCreateInput = {
		title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	root?:SeoLocaleCreateRootEntityRelationInput,
	_dummy_field_?:boolean
}

export type SeoLocaleWithoutLocaleUpdateInput = {
		title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	root?:SeoLocaleUpdateRootEntityRelationInput,
	_dummy_field_?:boolean
}

export type SeoLocaleWithoutRootCreateInput = {
		title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	locale?:SeoLocaleCreateLocaleEntityRelationInput,
	_dummy_field_?:boolean
}

export type SeoLocaleWithoutRootUpdateInput = {
		title?:string,
	description?:string,
	ogTitle?:string,
	ogDescription?:string,
	locale?:SeoLocaleUpdateLocaleEntityRelationInput,
	_dummy_field_?:boolean
}

export type SeoMeta = {
	__typename?: "SeoMeta",
	id?:FieldMeta,
	ogImage?:FieldMeta,
	locales?:FieldMeta,
	noindex?:FieldMeta
}

export type SeoOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	ogImage?:ImageOrderBy,
	noindex?:OrderDirection
}

export type SeoUniqueWhere = {
		id?:UUID,
	ogImage?:ImageUniqueWhere,
	locales?:SeoLocaleUniqueWhere
}

export type SeoUpdateInput = {
		ogImage?:SeoUpdateOgImageEntityRelationInput,
	locales?:SeoUpdateLocalesEntityRelationInput[],
	noindex?:boolean,
	_dummy_field_?:boolean
}

export type SeoUpdateLocalesEntityRelationInput = {
		create?:SeoLocaleWithoutRootCreateInput,
	update?:SeoUpdateLocalesRelationInput,
	upsert?:SeoUpsertLocalesRelationInput,
	connect?:SeoLocaleUniqueWhere,
	disconnect?:SeoLocaleUniqueWhere,
	delete?:SeoLocaleUniqueWhere,
	alias?:string
}

export type SeoUpdateLocalesRelationInput = {
		by?:SeoLocaleUniqueWhere,
	data?:SeoLocaleWithoutRootUpdateInput
}

export type SeoUpdateOgImageEntityRelationInput = {
		create?:ImageCreateInput,
	update?:ImageUpdateInput,
	upsert?:SeoUpsertOgImageRelationInput,
	connect?:ImageUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type SeoUpdateResult = {
	__typename?: "SeoUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Seo,
	validation:_ValidationResult
}

export type SeoUpsertLocalesRelationInput = {
		by?:SeoLocaleUniqueWhere,
	update?:SeoLocaleWithoutRootUpdateInput,
	create?:SeoLocaleWithoutRootCreateInput
}

export type SeoUpsertOgImageRelationInput = {
		update?:ImageUpdateInput,
	create?:ImageCreateInput
}

export type SeoWhere = {
		id?:UUIDCondition,
	ogImage?:ImageWhere,
	locales?:SeoLocaleWhere,
	noindex?:BooleanCondition,
	and?:SeoWhere[],
	or?:SeoWhere[],
	not?:SeoWhere
}

export type SeoWithoutLocalesCreateInput = {
		ogImage?:SeoCreateOgImageEntityRelationInput,
	noindex?:boolean,
	_dummy_field_?:boolean
}

export type SeoWithoutLocalesUpdateInput = {
		ogImage?:SeoUpdateOgImageEntityRelationInput,
	noindex?:boolean,
	_dummy_field_?:boolean
}

export type ShopifyLineItem = {
	__typename?: "ShopifyLineItem",
	_meta?:ShopifyLineItemMeta,
	id:UUID,
	store?:string,
	shopifyOrderId?:string,
	shopifyLineItemId?:string,
	customAttributes?:string
}

export type ShopifyLineItemConnection = {
	__typename?: "ShopifyLineItemConnection",
	pageInfo:PageInfo,
	edges:ShopifyLineItemEdge[]
}

export type ShopifyLineItemCreateInput = {
		store?:string,
	shopifyOrderId?:string,
	shopifyLineItemId?:string,
	customAttributes?:string,
	_dummy_field_?:boolean
}

export type ShopifyLineItemCreateResult = {
	__typename?: "ShopifyLineItemCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ShopifyLineItem,
	validation:_ValidationResult
}

export type ShopifyLineItemDeleteResult = {
	__typename?: "ShopifyLineItemDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ShopifyLineItem
}

export type ShopifyLineItemEdge = {
	__typename?: "ShopifyLineItemEdge",
	node:ShopifyLineItem
}

export type ShopifyLineItemMeta = {
	__typename?: "ShopifyLineItemMeta",
	id?:FieldMeta,
	store?:FieldMeta,
	shopifyOrderId?:FieldMeta,
	shopifyLineItemId?:FieldMeta,
	customAttributes?:FieldMeta
}

export type ShopifyLineItemOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	store?:OrderDirection,
	shopifyOrderId?:OrderDirection,
	shopifyLineItemId?:OrderDirection,
	customAttributes?:OrderDirection
}

export type ShopifyLineItemUniqueWhere = {
		id?:UUID,
	store?:string,
	shopifyOrderId?:string,
	shopifyLineItemId?:string
}

export type ShopifyLineItemUpdateInput = {
		store?:string,
	shopifyOrderId?:string,
	shopifyLineItemId?:string,
	customAttributes?:string,
	_dummy_field_?:boolean
}

export type ShopifyLineItemUpdateResult = {
	__typename?: "ShopifyLineItemUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ShopifyLineItem,
	validation:_ValidationResult
}

export type ShopifyLineItemWhere = {
		id?:UUIDCondition,
	store?:StringCondition,
	shopifyOrderId?:StringCondition,
	shopifyLineItemId?:StringCondition,
	customAttributes?:StringCondition,
	and?:ShopifyLineItemWhere[],
	or?:ShopifyLineItemWhere[],
	not?:ShopifyLineItemWhere
}

export type ShopifyOrder = {
	__typename?: "ShopifyOrder",
	_meta?:ShopifyOrderMeta,
	id:UUID,
	createdAt:DateTime,
	fulfilledAt?:DateTime,
	store?:string,
	shopifyId?:string,
	shippingType?:string,
	trackingId?:string,
	rawData?:string,
	trackingUrl?:string,
	shippingMeta?:string,
	enableResendAt?:DateTime
}

export type ShopifyOrderConnection = {
	__typename?: "ShopifyOrderConnection",
	pageInfo:PageInfo,
	edges:ShopifyOrderEdge[]
}

export type ShopifyOrderCreateInput = {
		createdAt?:DateTime,
	fulfilledAt?:DateTime,
	store?:string,
	shopifyId?:string,
	shippingType?:string,
	trackingId?:string,
	rawData?:string,
	trackingUrl?:string,
	shippingMeta?:string,
	enableResendAt?:DateTime,
	_dummy_field_?:boolean
}

export type ShopifyOrderCreateResult = {
	__typename?: "ShopifyOrderCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ShopifyOrder,
	validation:_ValidationResult
}

export type ShopifyOrderDeleteResult = {
	__typename?: "ShopifyOrderDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ShopifyOrder
}

export type ShopifyOrderEdge = {
	__typename?: "ShopifyOrderEdge",
	node:ShopifyOrder
}

export type ShopifyOrderMeta = {
	__typename?: "ShopifyOrderMeta",
	id?:FieldMeta,
	createdAt?:FieldMeta,
	fulfilledAt?:FieldMeta,
	store?:FieldMeta,
	shopifyId?:FieldMeta,
	shippingType?:FieldMeta,
	trackingId?:FieldMeta,
	rawData?:FieldMeta,
	trackingUrl?:FieldMeta,
	shippingMeta?:FieldMeta,
	enableResendAt?:FieldMeta
}

export type ShopifyOrderOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	createdAt?:OrderDirection,
	fulfilledAt?:OrderDirection,
	store?:OrderDirection,
	shopifyId?:OrderDirection,
	shippingType?:OrderDirection,
	trackingId?:OrderDirection,
	rawData?:OrderDirection,
	trackingUrl?:OrderDirection,
	shippingMeta?:OrderDirection,
	enableResendAt?:OrderDirection
}

export type ShopifyOrderUniqueWhere = {
		id?:UUID
}

export type ShopifyOrderUpdateInput = {
		createdAt?:DateTime,
	fulfilledAt?:DateTime,
	store?:string,
	shopifyId?:string,
	shippingType?:string,
	trackingId?:string,
	rawData?:string,
	trackingUrl?:string,
	shippingMeta?:string,
	enableResendAt?:DateTime,
	_dummy_field_?:boolean
}

export type ShopifyOrderUpdateResult = {
	__typename?: "ShopifyOrderUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:ShopifyOrder,
	validation:_ValidationResult
}

export type ShopifyOrderWhere = {
		id?:UUIDCondition,
	createdAt?:DateTimeCondition,
	fulfilledAt?:DateTimeCondition,
	store?:StringCondition,
	shopifyId?:StringCondition,
	shippingType?:StringCondition,
	trackingId?:StringCondition,
	rawData?:StringCondition,
	trackingUrl?:StringCondition,
	shippingMeta?:StringCondition,
	enableResendAt?:DateTimeCondition,
	and?:ShopifyOrderWhere[],
	or?:ShopifyOrderWhere[],
	not?:ShopifyOrderWhere
}

export enum SideBySideView {
	left = "left",
	right = "right"
}

export type SideBySideViewEnumCondition = {
		and?:SideBySideViewEnumCondition[],
	or?:SideBySideViewEnumCondition[],
	not?:SideBySideViewEnumCondition,
	eq?:SideBySideView,
	null?:boolean,
	isNull?:boolean,
	notEq?:SideBySideView,
	in?:SideBySideView[],
	notIn?:SideBySideView[],
	lt?:SideBySideView,
	lte?:SideBySideView,
	gt?:SideBySideView,
	gte?:SideBySideView
}

export type Store = {
	__typename?: "Store",
	_meta?:StoreMeta,
	id:UUID,
	code:string,
	shopifyDomain?:string,
	products:ProductStore[],
	productVariants:ProductVariantStore[],
	productsByRoot?:ProductStore,
	productsByShopifyId?:ProductStore,
	productVariantsByRoot?:ProductVariantStore,
	productVariantsByShopifyId?:ProductVariantStore,
	productVariantsByStorefrontId?:ProductVariantStore
}

export type StoreConnection = {
	__typename?: "StoreConnection",
	pageInfo:PageInfo,
	edges:StoreEdge[]
}

export type StoreCreateInput = {
		code?:string,
	shopifyDomain?:string,
	products?:StoreCreateProductsEntityRelationInput[],
	productVariants?:StoreCreateProductVariantsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type StoreCreateProductsEntityRelationInput = {
		connect?:ProductStoreUniqueWhere,
	create?:ProductStoreWithoutStoreCreateInput,
	alias?:string
}

export type StoreCreateProductVariantsEntityRelationInput = {
		connect?:ProductVariantStoreUniqueWhere,
	create?:ProductVariantStoreWithoutStoreCreateInput,
	alias?:string
}

export type StoreCreateResult = {
	__typename?: "StoreCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Store,
	validation:_ValidationResult
}

export type StoreDeleteResult = {
	__typename?: "StoreDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Store
}

export type StoreEdge = {
	__typename?: "StoreEdge",
	node:Store
}

export type StoreMeta = {
	__typename?: "StoreMeta",
	id?:FieldMeta,
	code?:FieldMeta,
	shopifyDomain?:FieldMeta,
	products?:FieldMeta,
	productVariants?:FieldMeta
}

export type StoreOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	code?:OrderDirection,
	shopifyDomain?:OrderDirection
}

export type StoreProductsByRootUniqueWhere = {
		root?:ProductUniqueWhere
}

export type StoreProductsByShopifyIdUniqueWhere = {
		shopifyId?:string
}

export type StoreProductVariantsByRootUniqueWhere = {
		root?:ProductVariantUniqueWhere
}

export type StoreProductVariantsByShopifyIdUniqueWhere = {
		shopifyId?:string
}

export type StoreProductVariantsByStorefrontIdUniqueWhere = {
		storefrontId?:string
}

export type StoreUniqueWhere = {
		id?:UUID,
	code?:string,
	products?:ProductStoreUniqueWhere,
	productVariants?:ProductVariantStoreUniqueWhere
}

export type StoreUpdateInput = {
		code?:string,
	shopifyDomain?:string,
	products?:StoreUpdateProductsEntityRelationInput[],
	productVariants?:StoreUpdateProductVariantsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type StoreUpdateProductsEntityRelationInput = {
		create?:ProductStoreWithoutStoreCreateInput,
	update?:StoreUpdateProductsRelationInput,
	upsert?:StoreUpsertProductsRelationInput,
	connect?:ProductStoreUniqueWhere,
	disconnect?:ProductStoreUniqueWhere,
	delete?:ProductStoreUniqueWhere,
	alias?:string
}

export type StoreUpdateProductsRelationInput = {
		by?:ProductStoreUniqueWhere,
	data?:ProductStoreWithoutStoreUpdateInput
}

export type StoreUpdateProductVariantsEntityRelationInput = {
		create?:ProductVariantStoreWithoutStoreCreateInput,
	update?:StoreUpdateProductVariantsRelationInput,
	upsert?:StoreUpsertProductVariantsRelationInput,
	connect?:ProductVariantStoreUniqueWhere,
	disconnect?:ProductVariantStoreUniqueWhere,
	delete?:ProductVariantStoreUniqueWhere,
	alias?:string
}

export type StoreUpdateProductVariantsRelationInput = {
		by?:ProductVariantStoreUniqueWhere,
	data?:ProductVariantStoreWithoutStoreUpdateInput
}

export type StoreUpdateResult = {
	__typename?: "StoreUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:Store,
	validation:_ValidationResult
}

export type StoreUpsertProductsRelationInput = {
		by?:ProductStoreUniqueWhere,
	update?:ProductStoreWithoutStoreUpdateInput,
	create?:ProductStoreWithoutStoreCreateInput
}

export type StoreUpsertProductVariantsRelationInput = {
		by?:ProductVariantStoreUniqueWhere,
	update?:ProductVariantStoreWithoutStoreUpdateInput,
	create?:ProductVariantStoreWithoutStoreCreateInput
}

export type StoreWhere = {
		id?:UUIDCondition,
	code?:StringCondition,
	shopifyDomain?:StringCondition,
	products?:ProductStoreWhere,
	productVariants?:ProductVariantStoreWhere,
	and?:StoreWhere[],
	or?:StoreWhere[],
	not?:StoreWhere
}

export type StoreWithoutProductsCreateInput = {
		code?:string,
	shopifyDomain?:string,
	productVariants?:StoreCreateProductVariantsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type StoreWithoutProductsUpdateInput = {
		code?:string,
	shopifyDomain?:string,
	productVariants?:StoreUpdateProductVariantsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type StoreWithoutProductVariantsCreateInput = {
		code?:string,
	shopifyDomain?:string,
	products?:StoreCreateProductsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type StoreWithoutProductVariantsUpdateInput = {
		code?:string,
	shopifyDomain?:string,
	products?:StoreUpdateProductsEntityRelationInput[],
	_dummy_field_?:boolean
}

export type StringCondition = {
		and?:StringCondition[],
	or?:StringCondition[],
	not?:StringCondition,
	eq?:string,
	null?:boolean,
	isNull?:boolean,
	notEq?:string,
	in?:string[],
	notIn?:string[],
	lt?:string,
	lte?:string,
	gt?:string,
	gte?:string,
	contains?:string,
	startsWith?:string,
	endsWith?:string,
	containsCI?:string,
	startsWithCI?:string,
	endsWithCI?:string
}

export type TranslationCatalogue = {
	__typename?: "TranslationCatalogue",
	_meta?:TranslationCatalogueMeta,
	id:UUID,
	identifier:string,
	name:string,
	domain?:TranslationDomain,
	fallback?:TranslationCatalogue,
	values:TranslationValue[],
	valuesByKey?:TranslationValue
}

export type TranslationCatalogueConnection = {
	__typename?: "TranslationCatalogueConnection",
	pageInfo:PageInfo,
	edges:TranslationCatalogueEdge[]
}

export type TranslationCatalogueCreateDomainEntityRelationInput = {
		connect?:TranslationDomainUniqueWhere,
	create?:TranslationDomainWithoutCataloguesCreateInput
}

export type TranslationCatalogueCreateFallbackEntityRelationInput = {
		connect?:TranslationCatalogueUniqueWhere,
	create?:TranslationCatalogueCreateInput
}

export type TranslationCatalogueCreateInput = {
		identifier?:string,
	name?:string,
	domain?:TranslationCatalogueCreateDomainEntityRelationInput,
	fallback?:TranslationCatalogueCreateFallbackEntityRelationInput,
	values?:TranslationCatalogueCreateValuesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type TranslationCatalogueCreateResult = {
	__typename?: "TranslationCatalogueCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:TranslationCatalogue,
	validation:_ValidationResult
}

export type TranslationCatalogueCreateValuesEntityRelationInput = {
		connect?:TranslationValueUniqueWhere,
	create?:TranslationValueWithoutCatalogueCreateInput,
	alias?:string
}

export type TranslationCatalogueDeleteResult = {
	__typename?: "TranslationCatalogueDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:TranslationCatalogue
}

export type TranslationCatalogueEdge = {
	__typename?: "TranslationCatalogueEdge",
	node:TranslationCatalogue
}

export type TranslationCatalogueMeta = {
	__typename?: "TranslationCatalogueMeta",
	id?:FieldMeta,
	identifier?:FieldMeta,
	name?:FieldMeta,
	domain?:FieldMeta,
	fallback?:FieldMeta,
	values?:FieldMeta
}

export type TranslationCatalogueOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	identifier?:OrderDirection,
	name?:OrderDirection,
	domain?:TranslationDomainOrderBy,
	fallback?:TranslationCatalogueOrderBy
}

export type TranslationCatalogueUniqueWhere = {
		id?:UUID,
	domain?:TranslationDomainUniqueWhere,
	identifier?:string,
	values?:TranslationValueUniqueWhere
}

export type TranslationCatalogueUpdateDomainEntityRelationInput = {
		create?:TranslationDomainWithoutCataloguesCreateInput,
	update?:TranslationDomainWithoutCataloguesUpdateInput,
	upsert?:TranslationCatalogueUpsertDomainRelationInput,
	connect?:TranslationDomainUniqueWhere
}

export type TranslationCatalogueUpdateFallbackEntityRelationInput = {
		create?:TranslationCatalogueCreateInput,
	update?:TranslationCatalogueUpdateInput,
	upsert?:TranslationCatalogueUpsertFallbackRelationInput,
	connect?:TranslationCatalogueUniqueWhere,
	disconnect?:boolean,
	delete?:boolean
}

export type TranslationCatalogueUpdateInput = {
		identifier?:string,
	name?:string,
	domain?:TranslationCatalogueUpdateDomainEntityRelationInput,
	fallback?:TranslationCatalogueUpdateFallbackEntityRelationInput,
	values?:TranslationCatalogueUpdateValuesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type TranslationCatalogueUpdateResult = {
	__typename?: "TranslationCatalogueUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:TranslationCatalogue,
	validation:_ValidationResult
}

export type TranslationCatalogueUpdateValuesEntityRelationInput = {
		create?:TranslationValueWithoutCatalogueCreateInput,
	update?:TranslationCatalogueUpdateValuesRelationInput,
	upsert?:TranslationCatalogueUpsertValuesRelationInput,
	connect?:TranslationValueUniqueWhere,
	disconnect?:TranslationValueUniqueWhere,
	delete?:TranslationValueUniqueWhere,
	alias?:string
}

export type TranslationCatalogueUpdateValuesRelationInput = {
		by?:TranslationValueUniqueWhere,
	data?:TranslationValueWithoutCatalogueUpdateInput
}

export type TranslationCatalogueUpsertDomainRelationInput = {
		update?:TranslationDomainWithoutCataloguesUpdateInput,
	create?:TranslationDomainWithoutCataloguesCreateInput
}

export type TranslationCatalogueUpsertFallbackRelationInput = {
		update?:TranslationCatalogueUpdateInput,
	create?:TranslationCatalogueCreateInput
}

export type TranslationCatalogueUpsertValuesRelationInput = {
		by?:TranslationValueUniqueWhere,
	update?:TranslationValueWithoutCatalogueUpdateInput,
	create?:TranslationValueWithoutCatalogueCreateInput
}

export type TranslationCatalogueValuesByKeyUniqueWhere = {
		key?:TranslationKeyUniqueWhere
}

export type TranslationCatalogueWhere = {
		id?:UUIDCondition,
	identifier?:StringCondition,
	name?:StringCondition,
	domain?:TranslationDomainWhere,
	fallback?:TranslationCatalogueWhere,
	values?:TranslationValueWhere,
	and?:TranslationCatalogueWhere[],
	or?:TranslationCatalogueWhere[],
	not?:TranslationCatalogueWhere
}

export type TranslationCatalogueWithoutDomainCreateInput = {
		identifier?:string,
	name?:string,
	fallback?:TranslationCatalogueCreateFallbackEntityRelationInput,
	values?:TranslationCatalogueCreateValuesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type TranslationCatalogueWithoutDomainUpdateInput = {
		identifier?:string,
	name?:string,
	fallback?:TranslationCatalogueUpdateFallbackEntityRelationInput,
	values?:TranslationCatalogueUpdateValuesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type TranslationCatalogueWithoutValuesCreateInput = {
		identifier?:string,
	name?:string,
	domain?:TranslationCatalogueCreateDomainEntityRelationInput,
	fallback?:TranslationCatalogueCreateFallbackEntityRelationInput,
	_dummy_field_?:boolean
}

export type TranslationCatalogueWithoutValuesUpdateInput = {
		identifier?:string,
	name?:string,
	domain?:TranslationCatalogueUpdateDomainEntityRelationInput,
	fallback?:TranslationCatalogueUpdateFallbackEntityRelationInput,
	_dummy_field_?:boolean
}

export enum TranslationContentType {
	textPlain = "textPlain",
	textHtml = "textHtml"
}

export type TranslationContentTypeEnumCondition = {
		and?:TranslationContentTypeEnumCondition[],
	or?:TranslationContentTypeEnumCondition[],
	not?:TranslationContentTypeEnumCondition,
	eq?:TranslationContentType,
	null?:boolean,
	isNull?:boolean,
	notEq?:TranslationContentType,
	in?:TranslationContentType[],
	notIn?:TranslationContentType[],
	lt?:TranslationContentType,
	lte?:TranslationContentType,
	gt?:TranslationContentType,
	gte?:TranslationContentType
}

export type TranslationDomain = {
	__typename?: "TranslationDomain",
	_meta?:TranslationDomainMeta,
	id:UUID,
	identifier:string,
	name:string,
	catalogues:TranslationCatalogue[],
	keys:TranslationKey[],
	cataloguesByIdentifier?:TranslationCatalogue,
	cataloguesByValues?:TranslationCatalogue,
	keysByIdentifier?:TranslationKey,
	keysByValues?:TranslationKey
}

export type TranslationDomainCataloguesByIdentifierUniqueWhere = {
		identifier?:string
}

export type TranslationDomainCataloguesByValuesUniqueWhere = {
		values?:TranslationValueUniqueWhere
}

export type TranslationDomainConnection = {
	__typename?: "TranslationDomainConnection",
	pageInfo:PageInfo,
	edges:TranslationDomainEdge[]
}

export type TranslationDomainCreateCataloguesEntityRelationInput = {
		connect?:TranslationCatalogueUniqueWhere,
	create?:TranslationCatalogueWithoutDomainCreateInput,
	alias?:string
}

export type TranslationDomainCreateInput = {
		identifier?:string,
	name?:string,
	catalogues?:TranslationDomainCreateCataloguesEntityRelationInput[],
	keys?:TranslationDomainCreateKeysEntityRelationInput[],
	_dummy_field_?:boolean
}

export type TranslationDomainCreateKeysEntityRelationInput = {
		connect?:TranslationKeyUniqueWhere,
	create?:TranslationKeyWithoutDomainCreateInput,
	alias?:string
}

export type TranslationDomainCreateResult = {
	__typename?: "TranslationDomainCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:TranslationDomain,
	validation:_ValidationResult
}

export type TranslationDomainDeleteResult = {
	__typename?: "TranslationDomainDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:TranslationDomain
}

export type TranslationDomainEdge = {
	__typename?: "TranslationDomainEdge",
	node:TranslationDomain
}

export type TranslationDomainKeysByIdentifierUniqueWhere = {
		identifier?:string
}

export type TranslationDomainKeysByValuesUniqueWhere = {
		values?:TranslationValueUniqueWhere
}

export type TranslationDomainMeta = {
	__typename?: "TranslationDomainMeta",
	id?:FieldMeta,
	identifier?:FieldMeta,
	name?:FieldMeta,
	catalogues?:FieldMeta,
	keys?:FieldMeta
}

export type TranslationDomainOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	identifier?:OrderDirection,
	name?:OrderDirection
}

export type TranslationDomainUniqueWhere = {
		id?:UUID,
	identifier?:string,
	catalogues?:TranslationCatalogueUniqueWhere,
	keys?:TranslationKeyUniqueWhere
}

export type TranslationDomainUpdateCataloguesEntityRelationInput = {
		create?:TranslationCatalogueWithoutDomainCreateInput,
	update?:TranslationDomainUpdateCataloguesRelationInput,
	upsert?:TranslationDomainUpsertCataloguesRelationInput,
	connect?:TranslationCatalogueUniqueWhere,
	disconnect?:TranslationCatalogueUniqueWhere,
	delete?:TranslationCatalogueUniqueWhere,
	alias?:string
}

export type TranslationDomainUpdateCataloguesRelationInput = {
		by?:TranslationCatalogueUniqueWhere,
	data?:TranslationCatalogueWithoutDomainUpdateInput
}

export type TranslationDomainUpdateInput = {
		identifier?:string,
	name?:string,
	catalogues?:TranslationDomainUpdateCataloguesEntityRelationInput[],
	keys?:TranslationDomainUpdateKeysEntityRelationInput[],
	_dummy_field_?:boolean
}

export type TranslationDomainUpdateKeysEntityRelationInput = {
		create?:TranslationKeyWithoutDomainCreateInput,
	update?:TranslationDomainUpdateKeysRelationInput,
	upsert?:TranslationDomainUpsertKeysRelationInput,
	connect?:TranslationKeyUniqueWhere,
	disconnect?:TranslationKeyUniqueWhere,
	delete?:TranslationKeyUniqueWhere,
	alias?:string
}

export type TranslationDomainUpdateKeysRelationInput = {
		by?:TranslationKeyUniqueWhere,
	data?:TranslationKeyWithoutDomainUpdateInput
}

export type TranslationDomainUpdateResult = {
	__typename?: "TranslationDomainUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:TranslationDomain,
	validation:_ValidationResult
}

export type TranslationDomainUpsertCataloguesRelationInput = {
		by?:TranslationCatalogueUniqueWhere,
	update?:TranslationCatalogueWithoutDomainUpdateInput,
	create?:TranslationCatalogueWithoutDomainCreateInput
}

export type TranslationDomainUpsertKeysRelationInput = {
		by?:TranslationKeyUniqueWhere,
	update?:TranslationKeyWithoutDomainUpdateInput,
	create?:TranslationKeyWithoutDomainCreateInput
}

export type TranslationDomainWhere = {
		id?:UUIDCondition,
	identifier?:StringCondition,
	name?:StringCondition,
	catalogues?:TranslationCatalogueWhere,
	keys?:TranslationKeyWhere,
	and?:TranslationDomainWhere[],
	or?:TranslationDomainWhere[],
	not?:TranslationDomainWhere
}

export type TranslationDomainWithoutCataloguesCreateInput = {
		identifier?:string,
	name?:string,
	keys?:TranslationDomainCreateKeysEntityRelationInput[],
	_dummy_field_?:boolean
}

export type TranslationDomainWithoutCataloguesUpdateInput = {
		identifier?:string,
	name?:string,
	keys?:TranslationDomainUpdateKeysEntityRelationInput[],
	_dummy_field_?:boolean
}

export type TranslationDomainWithoutKeysCreateInput = {
		identifier?:string,
	name?:string,
	catalogues?:TranslationDomainCreateCataloguesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type TranslationDomainWithoutKeysUpdateInput = {
		identifier?:string,
	name?:string,
	catalogues?:TranslationDomainUpdateCataloguesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type TranslationKey = {
	__typename?: "TranslationKey",
	_meta?:TranslationKeyMeta,
	id:UUID,
	identifier:string,
	contentType:TranslationContentType,
	note?:string,
	domain?:TranslationDomain,
	values:TranslationValue[],
	valuesByCatalogue?:TranslationValue
}

export type TranslationKeyConnection = {
	__typename?: "TranslationKeyConnection",
	pageInfo:PageInfo,
	edges:TranslationKeyEdge[]
}

export type TranslationKeyCreateDomainEntityRelationInput = {
		connect?:TranslationDomainUniqueWhere,
	create?:TranslationDomainWithoutKeysCreateInput
}

export type TranslationKeyCreateInput = {
		identifier?:string,
	contentType?:TranslationContentType,
	note?:string,
	domain?:TranslationKeyCreateDomainEntityRelationInput,
	values?:TranslationKeyCreateValuesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type TranslationKeyCreateResult = {
	__typename?: "TranslationKeyCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:TranslationKey,
	validation:_ValidationResult
}

export type TranslationKeyCreateValuesEntityRelationInput = {
		connect?:TranslationValueUniqueWhere,
	create?:TranslationValueWithoutKeyCreateInput,
	alias?:string
}

export type TranslationKeyDeleteResult = {
	__typename?: "TranslationKeyDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:TranslationKey
}

export type TranslationKeyEdge = {
	__typename?: "TranslationKeyEdge",
	node:TranslationKey
}

export type TranslationKeyMeta = {
	__typename?: "TranslationKeyMeta",
	id?:FieldMeta,
	identifier?:FieldMeta,
	contentType?:FieldMeta,
	note?:FieldMeta,
	domain?:FieldMeta,
	values?:FieldMeta
}

export type TranslationKeyOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	identifier?:OrderDirection,
	contentType?:OrderDirection,
	note?:OrderDirection,
	domain?:TranslationDomainOrderBy
}

export type TranslationKeyUniqueWhere = {
		id?:UUID,
	domain?:TranslationDomainUniqueWhere,
	identifier?:string,
	values?:TranslationValueUniqueWhere
}

export type TranslationKeyUpdateDomainEntityRelationInput = {
		create?:TranslationDomainWithoutKeysCreateInput,
	update?:TranslationDomainWithoutKeysUpdateInput,
	upsert?:TranslationKeyUpsertDomainRelationInput,
	connect?:TranslationDomainUniqueWhere
}

export type TranslationKeyUpdateInput = {
		identifier?:string,
	contentType?:TranslationContentType,
	note?:string,
	domain?:TranslationKeyUpdateDomainEntityRelationInput,
	values?:TranslationKeyUpdateValuesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type TranslationKeyUpdateResult = {
	__typename?: "TranslationKeyUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:TranslationKey,
	validation:_ValidationResult
}

export type TranslationKeyUpdateValuesEntityRelationInput = {
		create?:TranslationValueWithoutKeyCreateInput,
	update?:TranslationKeyUpdateValuesRelationInput,
	upsert?:TranslationKeyUpsertValuesRelationInput,
	connect?:TranslationValueUniqueWhere,
	disconnect?:TranslationValueUniqueWhere,
	delete?:TranslationValueUniqueWhere,
	alias?:string
}

export type TranslationKeyUpdateValuesRelationInput = {
		by?:TranslationValueUniqueWhere,
	data?:TranslationValueWithoutKeyUpdateInput
}

export type TranslationKeyUpsertDomainRelationInput = {
		update?:TranslationDomainWithoutKeysUpdateInput,
	create?:TranslationDomainWithoutKeysCreateInput
}

export type TranslationKeyUpsertValuesRelationInput = {
		by?:TranslationValueUniqueWhere,
	update?:TranslationValueWithoutKeyUpdateInput,
	create?:TranslationValueWithoutKeyCreateInput
}

export type TranslationKeyValuesByCatalogueUniqueWhere = {
		catalogue?:TranslationCatalogueUniqueWhere
}

export type TranslationKeyWhere = {
		id?:UUIDCondition,
	identifier?:StringCondition,
	contentType?:TranslationContentTypeEnumCondition,
	note?:StringCondition,
	domain?:TranslationDomainWhere,
	values?:TranslationValueWhere,
	and?:TranslationKeyWhere[],
	or?:TranslationKeyWhere[],
	not?:TranslationKeyWhere
}

export type TranslationKeyWithoutDomainCreateInput = {
		identifier?:string,
	contentType?:TranslationContentType,
	note?:string,
	values?:TranslationKeyCreateValuesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type TranslationKeyWithoutDomainUpdateInput = {
		identifier?:string,
	contentType?:TranslationContentType,
	note?:string,
	values?:TranslationKeyUpdateValuesEntityRelationInput[],
	_dummy_field_?:boolean
}

export type TranslationKeyWithoutValuesCreateInput = {
		identifier?:string,
	contentType?:TranslationContentType,
	note?:string,
	domain?:TranslationKeyCreateDomainEntityRelationInput,
	_dummy_field_?:boolean
}

export type TranslationKeyWithoutValuesUpdateInput = {
		identifier?:string,
	contentType?:TranslationContentType,
	note?:string,
	domain?:TranslationKeyUpdateDomainEntityRelationInput,
	_dummy_field_?:boolean
}

export type TranslationValue = {
	__typename?: "TranslationValue",
	_meta?:TranslationValueMeta,
	id:UUID,
	value:string,
	catalogue?:TranslationCatalogue,
	key?:TranslationKey
}

export type TranslationValueConnection = {
	__typename?: "TranslationValueConnection",
	pageInfo:PageInfo,
	edges:TranslationValueEdge[]
}

export type TranslationValueCreateCatalogueEntityRelationInput = {
		connect?:TranslationCatalogueUniqueWhere,
	create?:TranslationCatalogueWithoutValuesCreateInput
}

export type TranslationValueCreateInput = {
		value?:string,
	catalogue?:TranslationValueCreateCatalogueEntityRelationInput,
	key?:TranslationValueCreateKeyEntityRelationInput,
	_dummy_field_?:boolean
}

export type TranslationValueCreateKeyEntityRelationInput = {
		connect?:TranslationKeyUniqueWhere,
	create?:TranslationKeyWithoutValuesCreateInput
}

export type TranslationValueCreateResult = {
	__typename?: "TranslationValueCreateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:TranslationValue,
	validation:_ValidationResult
}

export type TranslationValueDeleteResult = {
	__typename?: "TranslationValueDeleteResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:TranslationValue
}

export type TranslationValueEdge = {
	__typename?: "TranslationValueEdge",
	node:TranslationValue
}

export type TranslationValueMeta = {
	__typename?: "TranslationValueMeta",
	id?:FieldMeta,
	value?:FieldMeta,
	catalogue?:FieldMeta,
	key?:FieldMeta
}

export type TranslationValueOrderBy = {
		_random?:boolean,
	_randomSeeded?:number,
	id?:OrderDirection,
	value?:OrderDirection,
	catalogue?:TranslationCatalogueOrderBy,
	key?:TranslationKeyOrderBy
}

export type TranslationValueUniqueWhere = {
		id?:UUID,
	catalogue?:TranslationCatalogueUniqueWhere,
	key?:TranslationKeyUniqueWhere
}

export type TranslationValueUpdateCatalogueEntityRelationInput = {
		create?:TranslationCatalogueWithoutValuesCreateInput,
	update?:TranslationCatalogueWithoutValuesUpdateInput,
	upsert?:TranslationValueUpsertCatalogueRelationInput,
	connect?:TranslationCatalogueUniqueWhere
}

export type TranslationValueUpdateInput = {
		value?:string,
	catalogue?:TranslationValueUpdateCatalogueEntityRelationInput,
	key?:TranslationValueUpdateKeyEntityRelationInput,
	_dummy_field_?:boolean
}

export type TranslationValueUpdateKeyEntityRelationInput = {
		create?:TranslationKeyWithoutValuesCreateInput,
	update?:TranslationKeyWithoutValuesUpdateInput,
	upsert?:TranslationValueUpsertKeyRelationInput,
	connect?:TranslationKeyUniqueWhere
}

export type TranslationValueUpdateResult = {
	__typename?: "TranslationValueUpdateResult",
	ok:boolean,
	errorMessage?:string,
	errors:_MutationError[],
	node?:TranslationValue,
	validation:_ValidationResult
}

export type TranslationValueUpsertCatalogueRelationInput = {
		update?:TranslationCatalogueWithoutValuesUpdateInput,
	create?:TranslationCatalogueWithoutValuesCreateInput
}

export type TranslationValueUpsertKeyRelationInput = {
		update?:TranslationKeyWithoutValuesUpdateInput,
	create?:TranslationKeyWithoutValuesCreateInput
}

export type TranslationValueWhere = {
		id?:UUIDCondition,
	value?:StringCondition,
	catalogue?:TranslationCatalogueWhere,
	key?:TranslationKeyWhere,
	and?:TranslationValueWhere[],
	or?:TranslationValueWhere[],
	not?:TranslationValueWhere
}

export type TranslationValueWithoutCatalogueCreateInput = {
		value?:string,
	key?:TranslationValueCreateKeyEntityRelationInput,
	_dummy_field_?:boolean
}

export type TranslationValueWithoutCatalogueUpdateInput = {
		value?:string,
	key?:TranslationValueUpdateKeyEntityRelationInput,
	_dummy_field_?:boolean
}

export type TranslationValueWithoutKeyCreateInput = {
		value?:string,
	catalogue?:TranslationValueCreateCatalogueEntityRelationInput,
	_dummy_field_?:boolean
}

export type TranslationValueWithoutKeyUpdateInput = {
		value?:string,
	catalogue?:TranslationValueUpdateCatalogueEntityRelationInput,
	_dummy_field_?:boolean
}

export type UUID = any

export type UUIDCondition = {
		and?:UUIDCondition[],
	or?:UUIDCondition[],
	not?:UUIDCondition,
	eq?:UUID,
	null?:boolean,
	isNull?:boolean,
	notEq?:UUID,
	in?:UUID[],
	notIn?:UUID[],
	lt?:UUID,
	lte?:UUID,
	gt?:UUID,
	gte?:UUID
}

export const AllTypesProps: Record<string,any> = {
	_MutationErrorType: "enum",
	AbandonedCheckoutNotificationCreateInput:{
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		sentAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		tryCount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderJson:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		metadata:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AbandonedCheckoutNotificationOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		sentAt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		tryCount:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderJson:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		metadata:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AbandonedCheckoutNotificationUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AbandonedCheckoutNotificationUpdateInput:{
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		sentAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		tryCount:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderJson:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		metadata:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AbandonedCheckoutNotificationWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTimeCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		sentAt:{
			type:"DateTimeCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		tryCount:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		orderJson:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		metadata:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		status:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"AbandonedCheckoutNotificationWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"AbandonedCheckoutNotificationWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"AbandonedCheckoutNotificationWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AbandonedCheckoutUnsubscriptionCreateInput:{
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		canceledAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AbandonedCheckoutUnsubscriptionOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		canceledAt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AbandonedCheckoutUnsubscriptionUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AbandonedCheckoutUnsubscriptionUpdateInput:{
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		canceledAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AbandonedCheckoutUnsubscriptionWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTimeCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		canceledAt:{
			type:"DateTimeCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		email:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"AbandonedCheckoutUnsubscriptionWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"AbandonedCheckoutUnsubscriptionWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"AbandonedCheckoutUnsubscriptionWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AdminNoteCreateInput:{
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AdminNoteOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AdminNoteUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AdminNoteUpdateInput:{
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	AdminNoteWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTimeCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"AdminNoteWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"AdminNoteWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"AdminNoteWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	BooleanCondition:{
		and:{
			type:"BooleanCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"BooleanCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"BooleanCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"Boolean",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"Boolean",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Content:{
		blocks:{
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentBlockOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		page:{
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ContentBlock:{
		content:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		link:{
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		image:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		media:{
			filter:{
				type:"MediaWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		product:{
			filter:{
				type:"ProductWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		featureList:{
			filter:{
				type:"FeatureListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		iconList:{
			filter:{
				type:"IconListBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		reviewList:{
			filter:{
				type:"ReviewWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ReviewOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		faq:{
			filter:{
				type:"FaqWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		productList:{
			filter:{
				type:"ProductWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		mobileImage:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ContentBlockCreateContentEntityRelationInput:{
		connect:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ContentWithoutBlocksCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockCreateFaqEntityRelationInput:{
		connect:{
			type:"FaqUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"FaqCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockCreateFeatureListEntityRelationInput:{
		connect:{
			type:"FeatureListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"FeatureListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockCreateIconListEntityRelationInput:{
		connect:{
			type:"IconListBlockUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconListBlockCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockCreateImageEntityRelationInput:{
		connect:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockCreateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"ContentBlockType",
			array:false,
			arrayRequired:false,
			required:false
		},
		primaryText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secondaryText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		jsonContent:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		headerView:{
			type:"HeaderView",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewView:{
			type:"ReviewView",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentBlockCreateContentEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"ContentBlockCreateLinkEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ContentBlockCreateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		media:{
			type:"ContentBlockCreateMediaEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		product:{
			type:"ContentBlockCreateProductEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		featureList:{
			type:"ContentBlockCreateFeatureListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconList:{
			type:"ContentBlockCreateIconListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewList:{
			type:"ContentBlockCreateReviewListEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		faq:{
			type:"ContentBlockCreateFaqEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		productList:{
			type:"ContentBlockCreateProductListEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		tertiaryText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sideBySideView:{
			type:"SideBySideView",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobileImage:{
			type:"ContentBlockCreateMobileImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		htmlId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockCreateLinkEntityRelationInput:{
		connect:{
			type:"LinkUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LinkCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockCreateMediaEntityRelationInput:{
		connect:{
			type:"MediaUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"MediaCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockCreateMobileImageEntityRelationInput:{
		connect:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockCreateProductEntityRelationInput:{
		connect:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockCreateProductListEntityRelationInput:{
		connect:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockCreateReviewListEntityRelationInput:{
		connect:{
			type:"ReviewUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ReviewCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		primaryText:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		secondaryText:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		jsonContent:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		headerView:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewView:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		media:{
			type:"MediaOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		product:{
			type:"ProductOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		featureList:{
			type:"FeatureListOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconList:{
			type:"IconListBlockOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		faq:{
			type:"FaqOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		tertiaryText:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		sideBySideView:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobileImage:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		htmlId:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockType: "enum",
	ContentBlockTypeEnumCondition:{
		and:{
			type:"ContentBlockTypeEnumCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ContentBlockTypeEnumCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ContentBlockTypeEnumCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"ContentBlockType",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"ContentBlockType",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"ContentBlockType",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"ContentBlockType",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"ContentBlockType",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"ContentBlockType",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"ContentBlockType",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"ContentBlockType",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpdateContentEntityRelationInput:{
		create:{
			type:"ContentWithoutBlocksCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ContentWithoutBlocksUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ContentBlockUpsertContentRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpdateFaqEntityRelationInput:{
		create:{
			type:"FaqCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"FaqUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ContentBlockUpsertFaqRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"FaqUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpdateFeatureListEntityRelationInput:{
		create:{
			type:"FeatureListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"FeatureListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ContentBlockUpsertFeatureListRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"FeatureListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpdateIconListEntityRelationInput:{
		create:{
			type:"IconListBlockCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconListBlockUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ContentBlockUpsertIconListRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"IconListBlockUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpdateImageEntityRelationInput:{
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ImageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ContentBlockUpsertImageRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpdateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"ContentBlockType",
			array:false,
			arrayRequired:false,
			required:false
		},
		primaryText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secondaryText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		jsonContent:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		headerView:{
			type:"HeaderView",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewView:{
			type:"ReviewView",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentBlockUpdateContentEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"ContentBlockUpdateLinkEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ContentBlockUpdateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		media:{
			type:"ContentBlockUpdateMediaEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		product:{
			type:"ContentBlockUpdateProductEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		featureList:{
			type:"ContentBlockUpdateFeatureListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconList:{
			type:"ContentBlockUpdateIconListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewList:{
			type:"ContentBlockUpdateReviewListEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		faq:{
			type:"ContentBlockUpdateFaqEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		productList:{
			type:"ContentBlockUpdateProductListEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		tertiaryText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sideBySideView:{
			type:"SideBySideView",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobileImage:{
			type:"ContentBlockUpdateMobileImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		htmlId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpdateLinkEntityRelationInput:{
		create:{
			type:"LinkCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"LinkUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ContentBlockUpsertLinkRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"LinkUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpdateMediaEntityRelationInput:{
		create:{
			type:"MediaCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"MediaUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ContentBlockUpsertMediaRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"MediaUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpdateMobileImageEntityRelationInput:{
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ImageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ContentBlockUpsertMobileImageRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpdateProductEntityRelationInput:{
		create:{
			type:"ProductCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ContentBlockUpsertProductRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpdateProductListEntityRelationInput:{
		create:{
			type:"ProductCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ContentBlockUpdateProductListRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ContentBlockUpsertProductListRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpdateProductListRelationInput:{
		by:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"ProductUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpdateReviewListEntityRelationInput:{
		create:{
			type:"ReviewCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ContentBlockUpdateReviewListRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ContentBlockUpsertReviewListRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ReviewUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"ReviewUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"ReviewUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpdateReviewListRelationInput:{
		by:{
			type:"ReviewUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"ReviewUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpsertContentRelationInput:{
		update:{
			type:"ContentWithoutBlocksUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ContentWithoutBlocksCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpsertFaqRelationInput:{
		update:{
			type:"FaqUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"FaqCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpsertFeatureListRelationInput:{
		update:{
			type:"FeatureListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"FeatureListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpsertIconListRelationInput:{
		update:{
			type:"IconListBlockUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconListBlockCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpsertImageRelationInput:{
		update:{
			type:"ImageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpsertLinkRelationInput:{
		update:{
			type:"LinkUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LinkCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpsertMediaRelationInput:{
		update:{
			type:"MediaUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"MediaCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpsertMobileImageRelationInput:{
		update:{
			type:"ImageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpsertProductListRelationInput:{
		by:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpsertProductRelationInput:{
		update:{
			type:"ProductUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockUpsertReviewListRelationInput:{
		by:{
			type:"ReviewUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ReviewUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ReviewCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"ContentBlockTypeEnumCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		primaryText:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		secondaryText:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		jsonContent:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		headerView:{
			type:"HeaderViewEnumCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewView:{
			type:"ReviewViewEnumCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		media:{
			type:"MediaWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		product:{
			type:"ProductWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		featureList:{
			type:"FeatureListWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconList:{
			type:"IconListBlockWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewList:{
			type:"ReviewWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		faq:{
			type:"FaqWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		productList:{
			type:"ProductWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		tertiaryText:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		sideBySideView:{
			type:"SideBySideViewEnumCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobileImage:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		htmlId:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ContentBlockWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ContentBlockWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ContentBlockWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockWithoutContentCreateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"ContentBlockType",
			array:false,
			arrayRequired:false,
			required:false
		},
		primaryText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secondaryText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		jsonContent:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		headerView:{
			type:"HeaderView",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewView:{
			type:"ReviewView",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"ContentBlockCreateLinkEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ContentBlockCreateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		media:{
			type:"ContentBlockCreateMediaEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		product:{
			type:"ContentBlockCreateProductEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		featureList:{
			type:"ContentBlockCreateFeatureListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconList:{
			type:"ContentBlockCreateIconListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewList:{
			type:"ContentBlockCreateReviewListEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		faq:{
			type:"ContentBlockCreateFaqEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		productList:{
			type:"ContentBlockCreateProductListEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		tertiaryText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sideBySideView:{
			type:"SideBySideView",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobileImage:{
			type:"ContentBlockCreateMobileImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		htmlId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentBlockWithoutContentUpdateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"ContentBlockType",
			array:false,
			arrayRequired:false,
			required:false
		},
		primaryText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		secondaryText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		jsonContent:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		headerView:{
			type:"HeaderView",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewView:{
			type:"ReviewView",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"ContentBlockUpdateLinkEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ContentBlockUpdateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		media:{
			type:"ContentBlockUpdateMediaEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		product:{
			type:"ContentBlockUpdateProductEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		featureList:{
			type:"ContentBlockUpdateFeatureListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconList:{
			type:"ContentBlockUpdateIconListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		reviewList:{
			type:"ContentBlockUpdateReviewListEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		faq:{
			type:"ContentBlockUpdateFaqEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		productList:{
			type:"ContentBlockUpdateProductListEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		tertiaryText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		sideBySideView:{
			type:"SideBySideView",
			array:false,
			arrayRequired:false,
			required:false
		},
		mobileImage:{
			type:"ContentBlockUpdateMobileImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		htmlId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentCreateBlocksEntityRelationInput:{
		connect:{
			type:"ContentBlockUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ContentBlockWithoutContentCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentCreateInput:{
		blocks:{
			type:"ContentCreateBlocksEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		page:{
			type:"ContentCreatePageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentCreatePageEntityRelationInput:{
		connect:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"PageLocaleWithoutContentCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"PageLocaleOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		blocks:{
			type:"ContentBlockUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentUpdateBlocksEntityRelationInput:{
		create:{
			type:"ContentBlockWithoutContentCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ContentUpdateBlocksRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ContentUpsertBlocksRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ContentBlockUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"ContentBlockUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"ContentBlockUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentUpdateBlocksRelationInput:{
		by:{
			type:"ContentBlockUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"ContentBlockWithoutContentUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentUpdateInput:{
		blocks:{
			type:"ContentUpdateBlocksEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		page:{
			type:"ContentUpdatePageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentUpdatePageEntityRelationInput:{
		create:{
			type:"PageLocaleWithoutContentCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"PageLocaleWithoutContentUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ContentUpsertPageRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentUpsertBlocksRelationInput:{
		by:{
			type:"ContentBlockUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ContentBlockWithoutContentUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ContentBlockWithoutContentCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentUpsertPageRelationInput:{
		update:{
			type:"PageLocaleWithoutContentUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"PageLocaleWithoutContentCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		blocks:{
			type:"ContentBlockWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"PageLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ContentWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ContentWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ContentWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentWithoutBlocksCreateInput:{
		page:{
			type:"ContentCreatePageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentWithoutBlocksUpdateInput:{
		page:{
			type:"ContentUpdatePageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentWithoutPageCreateInput:{
		blocks:{
			type:"ContentCreateBlocksEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ContentWithoutPageUpdateInput:{
		blocks:{
			type:"ContentUpdateBlocksEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	DateTime: "String",
	DateTimeCondition:{
		and:{
			type:"DateTimeCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"DateTimeCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"DateTimeCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"DateTime",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Faq:{
		items:{
			filter:{
				type:"FaqItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FaqItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	FaqCreateInput:{
		items:{
			type:"FaqCreateItemsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqCreateItemsEntityRelationInput:{
		connect:{
			type:"FaqItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"FaqItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqItem:{
		list:{
			filter:{
				type:"FaqWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	FaqItemCreateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		question:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		answer:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"FaqItemCreateListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqItemCreateListEntityRelationInput:{
		connect:{
			type:"FaqUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"FaqWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqItemOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		question:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		answer:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"FaqOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqItemUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqItemUpdateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		question:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		answer:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"FaqItemUpdateListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqItemUpdateListEntityRelationInput:{
		create:{
			type:"FaqWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"FaqWithoutItemsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"FaqItemUpsertListRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"FaqUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqItemUpsertListRelationInput:{
		update:{
			type:"FaqWithoutItemsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"FaqWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqItemWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		question:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		answer:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"FaqWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"FaqItemWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"FaqItemWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"FaqItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqItemWithoutListCreateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		question:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		answer:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqItemWithoutListUpdateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		question:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		answer:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"FaqItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqUpdateInput:{
		items:{
			type:"FaqUpdateItemsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqUpdateItemsEntityRelationInput:{
		create:{
			type:"FaqItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"FaqUpdateItemsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"FaqUpsertItemsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"FaqItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"FaqItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"FaqItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqUpdateItemsRelationInput:{
		by:{
			type:"FaqItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"FaqItemWithoutListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqUpsertItemsRelationInput:{
		by:{
			type:"FaqItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"FaqItemWithoutListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"FaqItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"FaqItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"FaqWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"FaqWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"FaqWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqWithoutItemsCreateInput:{
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FaqWithoutItemsUpdateInput:{
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureList:{
		items:{
			filter:{
				type:"FeatureListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FeatureListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	FeatureListCreateInput:{
		items:{
			type:"FeatureListCreateItemsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListCreateItemsEntityRelationInput:{
		connect:{
			type:"FeatureListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"FeatureListItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListItem:{
		list:{
			filter:{
				type:"FeatureListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	FeatureListItemCreateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		headline:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"FeatureListItemCreateListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListItemCreateListEntityRelationInput:{
		connect:{
			type:"FeatureListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"FeatureListWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListItemOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		headline:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"FeatureListOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListItemUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListItemUpdateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		headline:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"FeatureListItemUpdateListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListItemUpdateListEntityRelationInput:{
		create:{
			type:"FeatureListWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"FeatureListWithoutItemsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"FeatureListItemUpsertListRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"FeatureListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListItemUpsertListRelationInput:{
		update:{
			type:"FeatureListWithoutItemsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"FeatureListWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListItemWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		headline:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"FeatureListWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"FeatureListItemWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"FeatureListItemWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"FeatureListItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListItemWithoutListCreateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		headline:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListItemWithoutListUpdateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		headline:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"FeatureListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListUpdateInput:{
		items:{
			type:"FeatureListUpdateItemsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListUpdateItemsEntityRelationInput:{
		create:{
			type:"FeatureListItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"FeatureListUpdateItemsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"FeatureListUpsertItemsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"FeatureListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"FeatureListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"FeatureListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListUpdateItemsRelationInput:{
		by:{
			type:"FeatureListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"FeatureListItemWithoutListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListUpsertItemsRelationInput:{
		by:{
			type:"FeatureListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"FeatureListItemWithoutListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"FeatureListItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"FeatureListItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"FeatureListWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"FeatureListWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"FeatureListWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListWithoutItemsCreateInput:{
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FeatureListWithoutItemsUpdateInput:{
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	FloatCondition:{
		and:{
			type:"FloatCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"FloatCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"FloatCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"Float",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"Float",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Gallery:{
		items:{
			filter:{
				type:"GalleryItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GalleryItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	GalleryCreateInput:{
		items:{
			type:"GalleryCreateItemsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryCreateItemsEntityRelationInput:{
		connect:{
			type:"GalleryItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"GalleryItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryItem:{
		list:{
			filter:{
				type:"GalleryWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		image:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	GalleryItemCreateImageEntityRelationInput:{
		connect:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryItemCreateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		caption:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"GalleryItemCreateListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"GalleryItemCreateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryItemCreateListEntityRelationInput:{
		connect:{
			type:"GalleryUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"GalleryWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryItemOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		caption:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"GalleryOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryItemUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryItemUpdateImageEntityRelationInput:{
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ImageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"GalleryItemUpsertImageRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryItemUpdateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		caption:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"GalleryItemUpdateListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"GalleryItemUpdateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryItemUpdateListEntityRelationInput:{
		create:{
			type:"GalleryWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"GalleryWithoutItemsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"GalleryItemUpsertListRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"GalleryUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryItemUpsertImageRelationInput:{
		update:{
			type:"ImageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryItemUpsertListRelationInput:{
		update:{
			type:"GalleryWithoutItemsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"GalleryWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryItemWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		caption:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"GalleryWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"GalleryItemWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"GalleryItemWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"GalleryItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryItemWithoutListCreateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		caption:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"GalleryItemCreateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryItemWithoutListUpdateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		caption:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"GalleryItemUpdateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"GalleryItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryUpdateInput:{
		items:{
			type:"GalleryUpdateItemsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryUpdateItemsEntityRelationInput:{
		create:{
			type:"GalleryItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"GalleryUpdateItemsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"GalleryUpsertItemsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"GalleryItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"GalleryItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"GalleryItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryUpdateItemsRelationInput:{
		by:{
			type:"GalleryItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"GalleryItemWithoutListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryUpsertItemsRelationInput:{
		by:{
			type:"GalleryItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"GalleryItemWithoutListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"GalleryItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"GalleryItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"GalleryWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"GalleryWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"GalleryWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryWithoutItemsCreateInput:{
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	GalleryWithoutItemsUpdateInput:{
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	HeaderView: "enum",
	HeaderViewEnumCondition:{
		and:{
			type:"HeaderViewEnumCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"HeaderViewEnumCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"HeaderViewEnumCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"HeaderView",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"HeaderView",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"HeaderView",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"HeaderView",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"HeaderView",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"HeaderView",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"HeaderView",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"HeaderView",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Icon:{
		image:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		locales:{
			filter:{
				type:"IconLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		localesByLocale:{
			by:{
				type:"IconLocalesByLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	IconCreateImageEntityRelationInput:{
		connect:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconCreateInput:{
		key:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"IconCreateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"IconCreateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconCreateLocalesEntityRelationInput:{
		connect:{
			type:"IconLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroup:{
		icons:{
			filter:{
				type:"IconListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		locales:{
			filter:{
				type:"IconGroupLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		localesByLocale:{
			by:{
				type:"IconGroupLocalesByLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	IconGroupCreateIconsEntityRelationInput:{
		connect:{
			type:"IconListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupCreateInput:{
		icons:{
			type:"IconGroupCreateIconsEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"IconGroupCreateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupCreateLocalesEntityRelationInput:{
		connect:{
			type:"IconGroupLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconGroupLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupList:{
		items:{
			filter:{
				type:"IconGroupListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	IconGroupListCreateInput:{
		items:{
			type:"IconGroupListCreateItemsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListCreateItemsEntityRelationInput:{
		connect:{
			type:"IconGroupListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconGroupListItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListItem:{
		list:{
			filter:{
				type:"IconGroupListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		group:{
			filter:{
				type:"IconGroupWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	IconGroupListItemCreateGroupEntityRelationInput:{
		connect:{
			type:"IconGroupUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconGroupCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListItemCreateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"IconGroupListItemCreateListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"IconGroupListItemCreateGroupEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListItemCreateListEntityRelationInput:{
		connect:{
			type:"IconGroupListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconGroupListWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListItemOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"IconGroupListOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"IconGroupOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListItemUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListItemUpdateGroupEntityRelationInput:{
		create:{
			type:"IconGroupCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconGroupUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconGroupListItemUpsertGroupRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"IconGroupUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListItemUpdateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"IconGroupListItemUpdateListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"IconGroupListItemUpdateGroupEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListItemUpdateListEntityRelationInput:{
		create:{
			type:"IconGroupListWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconGroupListWithoutItemsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconGroupListItemUpsertListRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"IconGroupListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListItemUpsertGroupRelationInput:{
		update:{
			type:"IconGroupUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconGroupCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListItemUpsertListRelationInput:{
		update:{
			type:"IconGroupListWithoutItemsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconGroupListWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListItemWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"IconGroupListWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"IconGroupWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"IconGroupListItemWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"IconGroupListItemWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"IconGroupListItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListItemWithoutListCreateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"IconGroupListItemCreateGroupEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListItemWithoutListUpdateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		group:{
			type:"IconGroupListItemUpdateGroupEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"IconGroupListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListUpdateInput:{
		items:{
			type:"IconGroupListUpdateItemsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListUpdateItemsEntityRelationInput:{
		create:{
			type:"IconGroupListItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconGroupListUpdateItemsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconGroupListUpsertItemsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"IconGroupListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"IconGroupListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"IconGroupListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListUpdateItemsRelationInput:{
		by:{
			type:"IconGroupListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"IconGroupListItemWithoutListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListUpsertItemsRelationInput:{
		by:{
			type:"IconGroupListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconGroupListItemWithoutListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconGroupListItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"IconGroupListItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"IconGroupListWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"IconGroupListWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"IconGroupListWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListWithoutItemsCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupListWithoutItemsUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupLocale:{
		root:{
			filter:{
				type:"IconGroupWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		locale:{
			filter:{
				type:"LocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	IconGroupLocaleCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"IconGroupLocaleCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"IconGroupLocaleCreateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupLocaleCreateLocaleEntityRelationInput:{
		connect:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LocaleWithoutIconGroupsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupLocaleCreateRootEntityRelationInput:{
		connect:{
			type:"IconGroupUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconGroupWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupLocaleOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"IconGroupOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupLocalesByLocaleUniqueWhere:{
		locale:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupLocaleUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"IconGroupUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupLocaleUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"IconGroupLocaleUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"IconGroupLocaleUpdateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupLocaleUpdateLocaleEntityRelationInput:{
		create:{
			type:"LocaleWithoutIconGroupsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"LocaleWithoutIconGroupsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconGroupLocaleUpsertLocaleRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupLocaleUpdateRootEntityRelationInput:{
		create:{
			type:"IconGroupWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconGroupWithoutLocalesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconGroupLocaleUpsertRootRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"IconGroupUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupLocaleUpsertLocaleRelationInput:{
		update:{
			type:"LocaleWithoutIconGroupsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LocaleWithoutIconGroupsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupLocaleUpsertRootRelationInput:{
		update:{
			type:"IconGroupWithoutLocalesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconGroupWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupLocaleWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"IconGroupWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"IconGroupLocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"IconGroupLocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"IconGroupLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupLocaleWithoutLocaleCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"IconGroupLocaleCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupLocaleWithoutLocaleUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"IconGroupLocaleUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupLocaleWithoutRootCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"IconGroupLocaleCreateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupLocaleWithoutRootUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"IconGroupLocaleUpdateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		icons:{
			type:"IconListOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"IconGroupLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupUpdateIconsEntityRelationInput:{
		create:{
			type:"IconListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconGroupUpsertIconsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"IconListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupUpdateInput:{
		icons:{
			type:"IconGroupUpdateIconsEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"IconGroupUpdateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupUpdateLocalesEntityRelationInput:{
		create:{
			type:"IconGroupLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconGroupUpdateLocalesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconGroupUpsertLocalesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"IconGroupLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"IconGroupLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"IconGroupLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupUpdateLocalesRelationInput:{
		by:{
			type:"IconGroupLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"IconGroupLocaleWithoutRootUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupUpsertIconsRelationInput:{
		update:{
			type:"IconListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupUpsertLocalesRelationInput:{
		by:{
			type:"IconGroupLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconGroupLocaleWithoutRootUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconGroupLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		icons:{
			type:"IconListWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"IconGroupLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"IconGroupWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"IconGroupWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"IconGroupWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupWithoutLocalesCreateInput:{
		icons:{
			type:"IconGroupCreateIconsEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconGroupWithoutLocalesUpdateInput:{
		icons:{
			type:"IconGroupUpdateIconsEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconList:{
		items:{
			filter:{
				type:"IconListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	IconListBlock:{
		items:{
			filter:{
				type:"IconListBlockItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListBlockItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	IconListBlockCreateInput:{
		items:{
			type:"IconListBlockCreateItemsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockCreateItemsEntityRelationInput:{
		connect:{
			type:"IconListBlockItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconListBlockItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockItem:{
		list:{
			filter:{
				type:"IconListBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		icon:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	IconListBlockItemCreateIconEntityRelationInput:{
		connect:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockItemCreateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"IconListBlockItemCreateListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"IconListBlockItemCreateIconEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockItemCreateListEntityRelationInput:{
		connect:{
			type:"IconListBlockUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconListBlockWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockItemOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"IconListBlockOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockItemUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockItemUpdateIconEntityRelationInput:{
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ImageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconListBlockItemUpsertIconRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockItemUpdateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"IconListBlockItemUpdateListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"IconListBlockItemUpdateIconEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockItemUpdateListEntityRelationInput:{
		create:{
			type:"IconListBlockWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconListBlockWithoutItemsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconListBlockItemUpsertListRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"IconListBlockUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockItemUpsertIconRelationInput:{
		update:{
			type:"ImageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockItemUpsertListRelationInput:{
		update:{
			type:"IconListBlockWithoutItemsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconListBlockWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockItemWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"IconListBlockWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"IconListBlockItemWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"IconListBlockItemWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"IconListBlockItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockItemWithoutListCreateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"IconListBlockItemCreateIconEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockItemWithoutListUpdateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		text:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"IconListBlockItemUpdateIconEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"IconListBlockItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockUpdateInput:{
		items:{
			type:"IconListBlockUpdateItemsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockUpdateItemsEntityRelationInput:{
		create:{
			type:"IconListBlockItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconListBlockUpdateItemsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconListBlockUpsertItemsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"IconListBlockItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"IconListBlockItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"IconListBlockItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockUpdateItemsRelationInput:{
		by:{
			type:"IconListBlockItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"IconListBlockItemWithoutListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockUpsertItemsRelationInput:{
		by:{
			type:"IconListBlockItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconListBlockItemWithoutListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconListBlockItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"IconListBlockItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"IconListBlockWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"IconListBlockWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"IconListBlockWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockWithoutItemsCreateInput:{
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListBlockWithoutItemsUpdateInput:{
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListCreateInput:{
		items:{
			type:"IconListCreateItemsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListCreateItemsEntityRelationInput:{
		connect:{
			type:"IconListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconListItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListItem:{
		list:{
			filter:{
				type:"IconListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		icon:{
			filter:{
				type:"IconWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	IconListItemCreateIconEntityRelationInput:{
		connect:{
			type:"IconUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListItemCreateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"IconListItemCreateListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"IconListItemCreateIconEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListItemCreateListEntityRelationInput:{
		connect:{
			type:"IconListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconListWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListItemOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"IconListOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"IconOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListItemUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListItemUpdateIconEntityRelationInput:{
		create:{
			type:"IconCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconListItemUpsertIconRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"IconUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListItemUpdateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"IconListItemUpdateListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"IconListItemUpdateIconEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListItemUpdateListEntityRelationInput:{
		create:{
			type:"IconListWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconListWithoutItemsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconListItemUpsertListRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"IconListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListItemUpsertIconRelationInput:{
		update:{
			type:"IconUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListItemUpsertListRelationInput:{
		update:{
			type:"IconListWithoutItemsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconListWithoutItemsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListItemWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		order:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		list:{
			type:"IconListWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"IconWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"IconListItemWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"IconListItemWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"IconListItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListItemWithoutListCreateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"IconListItemCreateIconEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListItemWithoutListUpdateInput:{
		order:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		icon:{
			type:"IconListItemUpdateIconEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"IconListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListUpdateInput:{
		items:{
			type:"IconListUpdateItemsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListUpdateItemsEntityRelationInput:{
		create:{
			type:"IconListItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconListUpdateItemsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconListUpsertItemsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"IconListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"IconListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"IconListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListUpdateItemsRelationInput:{
		by:{
			type:"IconListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"IconListItemWithoutListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListUpsertItemsRelationInput:{
		by:{
			type:"IconListItemUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconListItemWithoutListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconListItemWithoutListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		items:{
			type:"IconListItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"IconListWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"IconListWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"IconListWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListWithoutItemsCreateInput:{
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconListWithoutItemsUpdateInput:{
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconLocale:{
		root:{
			filter:{
				type:"IconWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		locale:{
			filter:{
				type:"LocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	IconLocaleCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"IconLocaleCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"IconLocaleCreateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconLocaleCreateLocaleEntityRelationInput:{
		connect:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LocaleWithoutIconsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconLocaleCreateRootEntityRelationInput:{
		connect:{
			type:"IconUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconLocaleOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"IconOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconLocalesByLocaleUniqueWhere:{
		locale:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconLocaleUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"IconUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconLocaleUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"IconLocaleUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"IconLocaleUpdateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconLocaleUpdateLocaleEntityRelationInput:{
		create:{
			type:"LocaleWithoutIconsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"LocaleWithoutIconsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconLocaleUpsertLocaleRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconLocaleUpdateRootEntityRelationInput:{
		create:{
			type:"IconWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconWithoutLocalesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconLocaleUpsertRootRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"IconUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconLocaleUpsertLocaleRelationInput:{
		update:{
			type:"LocaleWithoutIconsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LocaleWithoutIconsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconLocaleUpsertRootRelationInput:{
		update:{
			type:"IconWithoutLocalesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconLocaleWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"IconWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"IconLocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"IconLocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"IconLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconLocaleWithoutLocaleCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"IconLocaleCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconLocaleWithoutLocaleUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"IconLocaleUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconLocaleWithoutRootCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"IconLocaleCreateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconLocaleWithoutRootUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"IconLocaleUpdateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"IconLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconUpdateImageEntityRelationInput:{
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ImageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconUpsertImageRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconUpdateInput:{
		key:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"IconUpdateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"IconUpdateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconUpdateLocalesEntityRelationInput:{
		create:{
			type:"IconLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconUpdateLocalesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"IconUpsertLocalesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"IconLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"IconLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"IconLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconUpdateLocalesRelationInput:{
		by:{
			type:"IconLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"IconLocaleWithoutRootUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconUpsertImageRelationInput:{
		update:{
			type:"ImageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconUpsertLocalesRelationInput:{
		by:{
			type:"IconLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconLocaleWithoutRootUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"IconLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"IconWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"IconWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"IconWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconWithoutLocalesCreateInput:{
		key:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"IconCreateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IconWithoutLocalesUpdateInput:{
		key:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"IconUpdateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageCreateInput:{
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		height:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		decorationPreset:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageList:{
		images:{
			filter:{
				type:"ImageXImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageXImageListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ImageListCreateImagesEntityRelationInput:{
		connect:{
			type:"ImageXImageListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageXImageListWithoutImageListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageListCreateInput:{
		images:{
			type:"ImageListCreateImagesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageListOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageListUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		images:{
			type:"ImageXImageListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageListUpdateImagesEntityRelationInput:{
		create:{
			type:"ImageXImageListWithoutImageListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ImageListUpdateImagesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ImageListUpsertImagesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ImageXImageListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"ImageXImageListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"ImageXImageListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageListUpdateImagesRelationInput:{
		by:{
			type:"ImageXImageListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"ImageXImageListWithoutImageListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageListUpdateInput:{
		images:{
			type:"ImageListUpdateImagesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageListUpsertImagesRelationInput:{
		by:{
			type:"ImageXImageListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ImageXImageListWithoutImageListUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageXImageListWithoutImageListCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageListWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		images:{
			type:"ImageXImageListWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ImageListWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ImageListWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ImageListWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageListWithoutImagesCreateInput:{
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageListWithoutImagesUpdateInput:{
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageLocaleCreateInput:{
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		height:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		decorationPreset:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageLocaleOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		height:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		decorationPreset:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageLocaleUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageLocaleUpdateInput:{
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		height:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		decorationPreset:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageLocaleWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		height:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		decorationPreset:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ImageLocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ImageLocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ImageLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		height:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		decorationPreset:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageUpdateInput:{
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		height:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		decorationPreset:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		height:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		size:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		alt:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		decorationPreset:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ImageWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ImageWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageXImageList:{
		imageList:{
			filter:{
				type:"ImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		image:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ImageXImageListCreateImageEntityRelationInput:{
		connect:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageXImageListCreateImageListEntityRelationInput:{
		connect:{
			type:"ImageListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageListWithoutImagesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageXImageListCreateInput:{
		imagePosition:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		imageList:{
			type:"ImageXImageListCreateImageListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageXImageListCreateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageXImageListOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		imagePosition:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		imageList:{
			type:"ImageListOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageXImageListUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageXImageListUpdateImageEntityRelationInput:{
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ImageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ImageXImageListUpsertImageRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageXImageListUpdateImageListEntityRelationInput:{
		create:{
			type:"ImageListWithoutImagesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ImageListWithoutImagesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ImageXImageListUpsertImageListRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ImageListUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageXImageListUpdateInput:{
		imagePosition:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		imageList:{
			type:"ImageXImageListUpdateImageListEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageXImageListUpdateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageXImageListUpsertImageListRelationInput:{
		update:{
			type:"ImageListWithoutImagesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageListWithoutImagesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageXImageListUpsertImageRelationInput:{
		update:{
			type:"ImageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageXImageListWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		imagePosition:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		imageList:{
			type:"ImageListWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ImageXImageListWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ImageXImageListWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ImageXImageListWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageXImageListWithoutImageListCreateInput:{
		imagePosition:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageXImageListCreateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ImageXImageListWithoutImageListUpdateInput:{
		imagePosition:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageXImageListUpdateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	IntCondition:{
		and:{
			type:"IntCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"IntCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"Int",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Link:{
		internalLink:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	Linkable:{
		page:{
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	LinkableCreateInput:{
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"LinkableCreatePageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkableCreatePageEntityRelationInput:{
		connect:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"PageLocaleWithoutLinkCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkableOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"PageLocaleOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkableUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkableUpdateInput:{
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"LinkableUpdatePageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkableUpdatePageEntityRelationInput:{
		create:{
			type:"PageLocaleWithoutLinkCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"PageLocaleWithoutLinkUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"LinkableUpsertPageRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkableUpsertPageRelationInput:{
		update:{
			type:"PageLocaleWithoutLinkUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"PageLocaleWithoutLinkCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkableWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		page:{
			type:"PageLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"LinkableWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"LinkableWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkableWithoutPageCreateInput:{
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkableWithoutPageUpdateInput:{
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		externalLink:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		internalLink:{
			type:"LinkCreateInternalLinkEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkCreateInternalLinkEntityRelationInput:{
		connect:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LinkableCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		externalLink:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		internalLink:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		externalLink:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		internalLink:{
			type:"LinkUpdateInternalLinkEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkUpdateInternalLinkEntityRelationInput:{
		create:{
			type:"LinkableCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"LinkableUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"LinkUpsertInternalLinkRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkUpsertInternalLinkRelationInput:{
		update:{
			type:"LinkableUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LinkableCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LinkWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		externalLink:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		internalLink:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"LinkWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"LinkWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"LinkWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Locale:{
		iconGroups:{
			filter:{
				type:"IconGroupLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		icons:{
			filter:{
				type:"IconLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		productVariants:{
			filter:{
				type:"ProductVariantLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		seos:{
			filter:{
				type:"SeoLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		pages:{
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PageLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		products:{
			filter:{
				type:"ProductLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		iconGroupsByRoot:{
			by:{
				type:"LocaleIconGroupsByRootUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		iconsByRoot:{
			by:{
				type:"LocaleIconsByRootUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		productVariantsByRoot:{
			by:{
				type:"LocaleProductVariantsByRootUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		seosByRoot:{
			by:{
				type:"LocaleSeosByRootUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SeoLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		pagesByRoot:{
			by:{
				type:"LocalePagesByRootUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		pagesByLink:{
			by:{
				type:"LocalePagesByLinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		pagesByContent:{
			by:{
				type:"LocalePagesByContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		productsByRoot:{
			by:{
				type:"LocaleProductsByRootUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	LocaleCreateIconGroupsEntityRelationInput:{
		connect:{
			type:"IconGroupLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconGroupLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleCreateIconsEntityRelationInput:{
		connect:{
			type:"IconLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleCreateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconGroups:{
			type:"LocaleCreateIconGroupsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		icons:{
			type:"LocaleCreateIconsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"LocaleCreateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		seos:{
			type:"LocaleCreateSeosEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		pages:{
			type:"LocaleCreatePagesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		products:{
			type:"LocaleCreateProductsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleCreatePagesEntityRelationInput:{
		connect:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"PageLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleCreateProductsEntityRelationInput:{
		connect:{
			type:"ProductLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleCreateProductVariantsEntityRelationInput:{
		connect:{
			type:"ProductVariantLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductVariantLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleCreateSeosEntityRelationInput:{
		connect:{
			type:"SeoLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"SeoLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleIconGroupsByRootUniqueWhere:{
		root:{
			type:"IconGroupUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleIconsByRootUniqueWhere:{
		root:{
			type:"IconUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		code:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocalePagesByContentUniqueWhere:{
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocalePagesByLinkUniqueWhere:{
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocalePagesByRootUniqueWhere:{
		root:{
			type:"PageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleProductsByRootUniqueWhere:{
		root:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleProductVariantsByRootUniqueWhere:{
		root:{
			type:"ProductVariantUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleSeosByRootUniqueWhere:{
		root:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconGroups:{
			type:"IconGroupLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		icons:{
			type:"IconLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		productVariants:{
			type:"ProductVariantLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seos:{
			type:"SeoLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		pages:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		products:{
			type:"ProductLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpdateIconGroupsEntityRelationInput:{
		create:{
			type:"IconGroupLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"LocaleUpdateIconGroupsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"LocaleUpsertIconGroupsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"IconGroupLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"IconGroupLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"IconGroupLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpdateIconGroupsRelationInput:{
		by:{
			type:"IconGroupLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"IconGroupLocaleWithoutLocaleUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpdateIconsEntityRelationInput:{
		create:{
			type:"IconLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"LocaleUpdateIconsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"LocaleUpsertIconsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"IconLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"IconLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"IconLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpdateIconsRelationInput:{
		by:{
			type:"IconLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"IconLocaleWithoutLocaleUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpdateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconGroups:{
			type:"LocaleUpdateIconGroupsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		icons:{
			type:"LocaleUpdateIconsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"LocaleUpdateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		seos:{
			type:"LocaleUpdateSeosEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		pages:{
			type:"LocaleUpdatePagesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		products:{
			type:"LocaleUpdateProductsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpdatePagesEntityRelationInput:{
		create:{
			type:"PageLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"LocaleUpdatePagesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"LocaleUpsertPagesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpdatePagesRelationInput:{
		by:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"PageLocaleWithoutLocaleUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpdateProductsEntityRelationInput:{
		create:{
			type:"ProductLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"LocaleUpdateProductsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"LocaleUpsertProductsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ProductLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"ProductLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"ProductLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpdateProductsRelationInput:{
		by:{
			type:"ProductLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"ProductLocaleWithoutLocaleUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpdateProductVariantsEntityRelationInput:{
		create:{
			type:"ProductVariantLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"LocaleUpdateProductVariantsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"LocaleUpsertProductVariantsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ProductVariantLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"ProductVariantLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"ProductVariantLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpdateProductVariantsRelationInput:{
		by:{
			type:"ProductVariantLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"ProductVariantLocaleWithoutLocaleUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpdateSeosEntityRelationInput:{
		create:{
			type:"SeoLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"LocaleUpdateSeosRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"LocaleUpsertSeosRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"SeoLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"SeoLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"SeoLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpdateSeosRelationInput:{
		by:{
			type:"SeoLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"SeoLocaleWithoutLocaleUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpsertIconGroupsRelationInput:{
		by:{
			type:"IconGroupLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconGroupLocaleWithoutLocaleUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconGroupLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpsertIconsRelationInput:{
		by:{
			type:"IconLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"IconLocaleWithoutLocaleUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"IconLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpsertPagesRelationInput:{
		by:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"PageLocaleWithoutLocaleUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"PageLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpsertProductsRelationInput:{
		by:{
			type:"ProductLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductLocaleWithoutLocaleUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpsertProductVariantsRelationInput:{
		by:{
			type:"ProductVariantLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductVariantLocaleWithoutLocaleUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductVariantLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleUpsertSeosRelationInput:{
		by:{
			type:"SeoLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"SeoLocaleWithoutLocaleUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"SeoLocaleWithoutLocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		code:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconGroups:{
			type:"IconGroupLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		icons:{
			type:"IconLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		productVariants:{
			type:"ProductVariantLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		seos:{
			type:"SeoLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		pages:{
			type:"PageLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		products:{
			type:"ProductLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"LocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"LocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"LocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleWithoutIconGroupsCreateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		icons:{
			type:"LocaleCreateIconsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"LocaleCreateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		seos:{
			type:"LocaleCreateSeosEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		pages:{
			type:"LocaleCreatePagesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		products:{
			type:"LocaleCreateProductsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleWithoutIconGroupsUpdateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		icons:{
			type:"LocaleUpdateIconsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"LocaleUpdateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		seos:{
			type:"LocaleUpdateSeosEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		pages:{
			type:"LocaleUpdatePagesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		products:{
			type:"LocaleUpdateProductsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleWithoutIconsCreateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconGroups:{
			type:"LocaleCreateIconGroupsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"LocaleCreateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		seos:{
			type:"LocaleCreateSeosEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		pages:{
			type:"LocaleCreatePagesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		products:{
			type:"LocaleCreateProductsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleWithoutIconsUpdateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconGroups:{
			type:"LocaleUpdateIconGroupsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"LocaleUpdateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		seos:{
			type:"LocaleUpdateSeosEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		pages:{
			type:"LocaleUpdatePagesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		products:{
			type:"LocaleUpdateProductsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleWithoutPagesCreateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconGroups:{
			type:"LocaleCreateIconGroupsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		icons:{
			type:"LocaleCreateIconsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"LocaleCreateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		seos:{
			type:"LocaleCreateSeosEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		products:{
			type:"LocaleCreateProductsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleWithoutPagesUpdateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconGroups:{
			type:"LocaleUpdateIconGroupsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		icons:{
			type:"LocaleUpdateIconsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"LocaleUpdateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		seos:{
			type:"LocaleUpdateSeosEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		products:{
			type:"LocaleUpdateProductsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleWithoutProductsCreateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconGroups:{
			type:"LocaleCreateIconGroupsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		icons:{
			type:"LocaleCreateIconsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"LocaleCreateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		seos:{
			type:"LocaleCreateSeosEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		pages:{
			type:"LocaleCreatePagesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleWithoutProductsUpdateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconGroups:{
			type:"LocaleUpdateIconGroupsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		icons:{
			type:"LocaleUpdateIconsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"LocaleUpdateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		seos:{
			type:"LocaleUpdateSeosEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		pages:{
			type:"LocaleUpdatePagesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleWithoutProductVariantsCreateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconGroups:{
			type:"LocaleCreateIconGroupsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		icons:{
			type:"LocaleCreateIconsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		seos:{
			type:"LocaleCreateSeosEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		pages:{
			type:"LocaleCreatePagesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		products:{
			type:"LocaleCreateProductsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleWithoutProductVariantsUpdateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconGroups:{
			type:"LocaleUpdateIconGroupsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		icons:{
			type:"LocaleUpdateIconsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		seos:{
			type:"LocaleUpdateSeosEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		pages:{
			type:"LocaleUpdatePagesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		products:{
			type:"LocaleUpdateProductsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleWithoutSeosCreateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconGroups:{
			type:"LocaleCreateIconGroupsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		icons:{
			type:"LocaleCreateIconsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"LocaleCreateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		pages:{
			type:"LocaleCreatePagesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		products:{
			type:"LocaleCreateProductsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	LocaleWithoutSeosUpdateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		iconGroups:{
			type:"LocaleUpdateIconGroupsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		icons:{
			type:"LocaleUpdateIconsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"LocaleUpdateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		pages:{
			type:"LocaleUpdatePagesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		products:{
			type:"LocaleUpdateProductsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MediaCreateInput:{
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		videoUrl:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		height:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		poster:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"MediaItemType",
			array:false,
			arrayRequired:false,
			required:false
		},
		caption:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		audio:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MediaItemType: "enum",
	MediaItemTypeEnumCondition:{
		and:{
			type:"MediaItemTypeEnumCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"MediaItemTypeEnumCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"MediaItemTypeEnumCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"MediaItemType",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"MediaItemType",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"MediaItemType",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"MediaItemType",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"MediaItemType",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"MediaItemType",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"MediaItemType",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"MediaItemType",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MediaOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		videoUrl:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		height:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		poster:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		caption:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		audio:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MediaUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MediaUpdateInput:{
		url:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		videoUrl:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		height:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		poster:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"MediaItemType",
			array:false,
			arrayRequired:false,
			required:false
		},
		caption:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		audio:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	MediaWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		url:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		videoUrl:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		width:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		height:{
			type:"IntCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		poster:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		type:{
			type:"MediaItemTypeEnumCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		caption:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		audio:{
			type:"BooleanCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"MediaWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"MediaWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"MediaWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Mutation:{
		createAbandonedCheckoutUnsubscription:{
			data:{
				type:"AbandonedCheckoutUnsubscriptionCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteAbandonedCheckoutUnsubscription:{
			by:{
				type:"AbandonedCheckoutUnsubscriptionUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"AbandonedCheckoutUnsubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateAbandonedCheckoutUnsubscription:{
			by:{
				type:"AbandonedCheckoutUnsubscriptionUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"AbandonedCheckoutUnsubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"AbandonedCheckoutUnsubscriptionUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createAbandonedCheckoutNotification:{
			data:{
				type:"AbandonedCheckoutNotificationCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteAbandonedCheckoutNotification:{
			by:{
				type:"AbandonedCheckoutNotificationUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"AbandonedCheckoutNotificationWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateAbandonedCheckoutNotification:{
			by:{
				type:"AbandonedCheckoutNotificationUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"AbandonedCheckoutNotificationWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"AbandonedCheckoutNotificationUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createShopifyLineItem:{
			data:{
				type:"ShopifyLineItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteShopifyLineItem:{
			by:{
				type:"ShopifyLineItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ShopifyLineItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateShopifyLineItem:{
			by:{
				type:"ShopifyLineItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ShopifyLineItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ShopifyLineItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createShopifyOrder:{
			data:{
				type:"ShopifyOrderCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteShopifyOrder:{
			by:{
				type:"ShopifyOrderUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ShopifyOrderWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateShopifyOrder:{
			by:{
				type:"ShopifyOrderUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ShopifyOrderWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ShopifyOrderUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createTranslationValue:{
			data:{
				type:"TranslationValueCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteTranslationValue:{
			by:{
				type:"TranslationValueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationValueWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateTranslationValue:{
			by:{
				type:"TranslationValueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationValueWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"TranslationValueUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createTranslationKey:{
			data:{
				type:"TranslationKeyCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteTranslationKey:{
			by:{
				type:"TranslationKeyUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationKeyWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateTranslationKey:{
			by:{
				type:"TranslationKeyUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationKeyWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"TranslationKeyUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createTranslationCatalogue:{
			data:{
				type:"TranslationCatalogueCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteTranslationCatalogue:{
			by:{
				type:"TranslationCatalogueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationCatalogueWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateTranslationCatalogue:{
			by:{
				type:"TranslationCatalogueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationCatalogueWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"TranslationCatalogueUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createTranslationDomain:{
			data:{
				type:"TranslationDomainCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteTranslationDomain:{
			by:{
				type:"TranslationDomainUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationDomainWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateTranslationDomain:{
			by:{
				type:"TranslationDomainUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationDomainWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"TranslationDomainUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createProductLocale:{
			data:{
				type:"ProductLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteProductLocale:{
			by:{
				type:"ProductLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateProductLocale:{
			by:{
				type:"ProductLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ProductLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createMedia:{
			data:{
				type:"MediaCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteMedia:{
			by:{
				type:"MediaUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"MediaWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateMedia:{
			by:{
				type:"MediaUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"MediaWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"MediaUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconListBlockItem:{
			data:{
				type:"IconListBlockItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconListBlockItem:{
			by:{
				type:"IconListBlockItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconListBlockItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconListBlockItem:{
			by:{
				type:"IconListBlockItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconListBlockItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconListBlockItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconListBlock:{
			data:{
				type:"IconListBlockCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconListBlock:{
			by:{
				type:"IconListBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconListBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconListBlock:{
			by:{
				type:"IconListBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconListBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconListBlockUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createFeatureListItem:{
			data:{
				type:"FeatureListItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteFeatureListItem:{
			by:{
				type:"FeatureListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FeatureListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateFeatureListItem:{
			by:{
				type:"FeatureListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FeatureListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"FeatureListItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createFeatureList:{
			data:{
				type:"FeatureListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteFeatureList:{
			by:{
				type:"FeatureListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FeatureListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateFeatureList:{
			by:{
				type:"FeatureListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FeatureListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"FeatureListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createFaqItem:{
			data:{
				type:"FaqItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteFaqItem:{
			by:{
				type:"FaqItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FaqItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateFaqItem:{
			by:{
				type:"FaqItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FaqItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"FaqItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createFaq:{
			data:{
				type:"FaqCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteFaq:{
			by:{
				type:"FaqUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FaqWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateFaq:{
			by:{
				type:"FaqUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FaqWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"FaqUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createContentBlock:{
			data:{
				type:"ContentBlockCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteContentBlock:{
			by:{
				type:"ContentBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateContentBlock:{
			by:{
				type:"ContentBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ContentBlockUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createContent:{
			data:{
				type:"ContentCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteContent:{
			by:{
				type:"ContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateContent:{
			by:{
				type:"ContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ContentUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createLink:{
			data:{
				type:"LinkCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteLink:{
			by:{
				type:"LinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateLink:{
			by:{
				type:"LinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"LinkUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createReviewLocale:{
			data:{
				type:"ReviewLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteReviewLocale:{
			by:{
				type:"ReviewLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ReviewLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateReviewLocale:{
			by:{
				type:"ReviewLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ReviewLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ReviewLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createReview:{
			data:{
				type:"ReviewCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteReview:{
			by:{
				type:"ReviewUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ReviewWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateReview:{
			by:{
				type:"ReviewUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ReviewWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ReviewUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createGalleryItem:{
			data:{
				type:"GalleryItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteGalleryItem:{
			by:{
				type:"GalleryItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"GalleryItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateGalleryItem:{
			by:{
				type:"GalleryItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"GalleryItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"GalleryItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createGallery:{
			data:{
				type:"GalleryCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteGallery:{
			by:{
				type:"GalleryUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"GalleryWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateGallery:{
			by:{
				type:"GalleryUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"GalleryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"GalleryUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createProductVariantStore:{
			data:{
				type:"ProductVariantStoreCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteProductVariantStore:{
			by:{
				type:"ProductVariantStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateProductVariantStore:{
			by:{
				type:"ProductVariantStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ProductVariantStoreUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createProductStore:{
			data:{
				type:"ProductStoreCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteProductStore:{
			by:{
				type:"ProductStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateProductStore:{
			by:{
				type:"ProductStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ProductStoreUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconListItem:{
			data:{
				type:"IconListItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconListItem:{
			by:{
				type:"IconListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconListItem:{
			by:{
				type:"IconListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconListItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconList:{
			data:{
				type:"IconListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconList:{
			by:{
				type:"IconListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconList:{
			by:{
				type:"IconListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconGroupListItem:{
			data:{
				type:"IconGroupListItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconGroupListItem:{
			by:{
				type:"IconGroupListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconGroupListItem:{
			by:{
				type:"IconGroupListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconGroupListItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconGroupList:{
			data:{
				type:"IconGroupListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconGroupList:{
			by:{
				type:"IconGroupListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconGroupList:{
			by:{
				type:"IconGroupListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconGroupListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createProductVariantLocale:{
			data:{
				type:"ProductVariantLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteProductVariantLocale:{
			by:{
				type:"ProductVariantLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateProductVariantLocale:{
			by:{
				type:"ProductVariantLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ProductVariantLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createProductVariant:{
			data:{
				type:"ProductVariantCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteProductVariant:{
			by:{
				type:"ProductVariantUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateProductVariant:{
			by:{
				type:"ProductVariantUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ProductVariantUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createProduct:{
			data:{
				type:"ProductCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteProduct:{
			by:{
				type:"ProductUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateProduct:{
			by:{
				type:"ProductUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ProductUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createImageLocale:{
			data:{
				type:"ImageLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteImageLocale:{
			by:{
				type:"ImageLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateImageLocale:{
			by:{
				type:"ImageLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ImageLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createStore:{
			data:{
				type:"StoreCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteStore:{
			by:{
				type:"StoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"StoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateStore:{
			by:{
				type:"StoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"StoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"StoreUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createAdminNote:{
			data:{
				type:"AdminNoteCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteAdminNote:{
			by:{
				type:"AdminNoteUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"AdminNoteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateAdminNote:{
			by:{
				type:"AdminNoteUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"AdminNoteWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"AdminNoteUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createPageLocale:{
			data:{
				type:"PageLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deletePageLocale:{
			by:{
				type:"PageLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updatePageLocale:{
			by:{
				type:"PageLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"PageLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createPage:{
			data:{
				type:"PageCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deletePage:{
			by:{
				type:"PageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updatePage:{
			by:{
				type:"PageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"PageUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createImageXImageList:{
			data:{
				type:"ImageXImageListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteImageXImageList:{
			by:{
				type:"ImageXImageListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageXImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateImageXImageList:{
			by:{
				type:"ImageXImageListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageXImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ImageXImageListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconGroupLocale:{
			data:{
				type:"IconGroupLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconGroupLocale:{
			by:{
				type:"IconGroupLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconGroupLocale:{
			by:{
				type:"IconGroupLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconGroupLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconLocale:{
			data:{
				type:"IconLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconLocale:{
			by:{
				type:"IconLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconLocale:{
			by:{
				type:"IconLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconGroup:{
			data:{
				type:"IconGroupCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconGroup:{
			by:{
				type:"IconGroupUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconGroup:{
			by:{
				type:"IconGroupUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconGroupUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIcon:{
			data:{
				type:"IconCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIcon:{
			by:{
				type:"IconUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIcon:{
			by:{
				type:"IconUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createLocale:{
			data:{
				type:"LocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteLocale:{
			by:{
				type:"LocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"LocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateLocale:{
			by:{
				type:"LocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"LocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"LocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createSeoLocale:{
			data:{
				type:"SeoLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteSeoLocale:{
			by:{
				type:"SeoLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SeoLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateSeoLocale:{
			by:{
				type:"SeoLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SeoLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"SeoLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createSeo:{
			data:{
				type:"SeoCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteSeo:{
			by:{
				type:"SeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateSeo:{
			by:{
				type:"SeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"SeoUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createLinkable:{
			data:{
				type:"LinkableCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteLinkable:{
			by:{
				type:"LinkableUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateLinkable:{
			by:{
				type:"LinkableUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"LinkableUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createImageList:{
			data:{
				type:"ImageListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteImageList:{
			by:{
				type:"ImageListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateImageList:{
			by:{
				type:"ImageListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ImageListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createImage:{
			data:{
				type:"ImageCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteImage:{
			by:{
				type:"ImageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateImage:{
			by:{
				type:"ImageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ImageUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		generateUploadUrl:{
			contentType:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			expiration:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			prefix:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		generateReadUrl:{
			objectKey:{
				type:"String",
				array:false,
				arrayRequired:false,
				required:true
			},
			expiration:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	MutationTransaction:{
		createAbandonedCheckoutUnsubscription:{
			data:{
				type:"AbandonedCheckoutUnsubscriptionCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteAbandonedCheckoutUnsubscription:{
			by:{
				type:"AbandonedCheckoutUnsubscriptionUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"AbandonedCheckoutUnsubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateAbandonedCheckoutUnsubscription:{
			by:{
				type:"AbandonedCheckoutUnsubscriptionUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"AbandonedCheckoutUnsubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"AbandonedCheckoutUnsubscriptionUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createAbandonedCheckoutNotification:{
			data:{
				type:"AbandonedCheckoutNotificationCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteAbandonedCheckoutNotification:{
			by:{
				type:"AbandonedCheckoutNotificationUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"AbandonedCheckoutNotificationWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateAbandonedCheckoutNotification:{
			by:{
				type:"AbandonedCheckoutNotificationUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"AbandonedCheckoutNotificationWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"AbandonedCheckoutNotificationUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createShopifyLineItem:{
			data:{
				type:"ShopifyLineItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteShopifyLineItem:{
			by:{
				type:"ShopifyLineItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ShopifyLineItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateShopifyLineItem:{
			by:{
				type:"ShopifyLineItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ShopifyLineItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ShopifyLineItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createShopifyOrder:{
			data:{
				type:"ShopifyOrderCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteShopifyOrder:{
			by:{
				type:"ShopifyOrderUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ShopifyOrderWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateShopifyOrder:{
			by:{
				type:"ShopifyOrderUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ShopifyOrderWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ShopifyOrderUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createTranslationValue:{
			data:{
				type:"TranslationValueCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteTranslationValue:{
			by:{
				type:"TranslationValueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationValueWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateTranslationValue:{
			by:{
				type:"TranslationValueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationValueWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"TranslationValueUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createTranslationKey:{
			data:{
				type:"TranslationKeyCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteTranslationKey:{
			by:{
				type:"TranslationKeyUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationKeyWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateTranslationKey:{
			by:{
				type:"TranslationKeyUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationKeyWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"TranslationKeyUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createTranslationCatalogue:{
			data:{
				type:"TranslationCatalogueCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteTranslationCatalogue:{
			by:{
				type:"TranslationCatalogueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationCatalogueWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateTranslationCatalogue:{
			by:{
				type:"TranslationCatalogueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationCatalogueWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"TranslationCatalogueUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createTranslationDomain:{
			data:{
				type:"TranslationDomainCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteTranslationDomain:{
			by:{
				type:"TranslationDomainUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationDomainWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateTranslationDomain:{
			by:{
				type:"TranslationDomainUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationDomainWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"TranslationDomainUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createProductLocale:{
			data:{
				type:"ProductLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteProductLocale:{
			by:{
				type:"ProductLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateProductLocale:{
			by:{
				type:"ProductLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ProductLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createMedia:{
			data:{
				type:"MediaCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteMedia:{
			by:{
				type:"MediaUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"MediaWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateMedia:{
			by:{
				type:"MediaUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"MediaWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"MediaUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconListBlockItem:{
			data:{
				type:"IconListBlockItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconListBlockItem:{
			by:{
				type:"IconListBlockItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconListBlockItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconListBlockItem:{
			by:{
				type:"IconListBlockItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconListBlockItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconListBlockItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconListBlock:{
			data:{
				type:"IconListBlockCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconListBlock:{
			by:{
				type:"IconListBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconListBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconListBlock:{
			by:{
				type:"IconListBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconListBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconListBlockUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createFeatureListItem:{
			data:{
				type:"FeatureListItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteFeatureListItem:{
			by:{
				type:"FeatureListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FeatureListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateFeatureListItem:{
			by:{
				type:"FeatureListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FeatureListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"FeatureListItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createFeatureList:{
			data:{
				type:"FeatureListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteFeatureList:{
			by:{
				type:"FeatureListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FeatureListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateFeatureList:{
			by:{
				type:"FeatureListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FeatureListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"FeatureListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createFaqItem:{
			data:{
				type:"FaqItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteFaqItem:{
			by:{
				type:"FaqItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FaqItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateFaqItem:{
			by:{
				type:"FaqItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FaqItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"FaqItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createFaq:{
			data:{
				type:"FaqCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteFaq:{
			by:{
				type:"FaqUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FaqWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateFaq:{
			by:{
				type:"FaqUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"FaqWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"FaqUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createContentBlock:{
			data:{
				type:"ContentBlockCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteContentBlock:{
			by:{
				type:"ContentBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateContentBlock:{
			by:{
				type:"ContentBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ContentBlockUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createContent:{
			data:{
				type:"ContentCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteContent:{
			by:{
				type:"ContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateContent:{
			by:{
				type:"ContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ContentUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createLink:{
			data:{
				type:"LinkCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteLink:{
			by:{
				type:"LinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateLink:{
			by:{
				type:"LinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"LinkUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createReviewLocale:{
			data:{
				type:"ReviewLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteReviewLocale:{
			by:{
				type:"ReviewLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ReviewLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateReviewLocale:{
			by:{
				type:"ReviewLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ReviewLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ReviewLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createReview:{
			data:{
				type:"ReviewCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteReview:{
			by:{
				type:"ReviewUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ReviewWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateReview:{
			by:{
				type:"ReviewUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ReviewWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ReviewUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createGalleryItem:{
			data:{
				type:"GalleryItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteGalleryItem:{
			by:{
				type:"GalleryItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"GalleryItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateGalleryItem:{
			by:{
				type:"GalleryItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"GalleryItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"GalleryItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createGallery:{
			data:{
				type:"GalleryCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteGallery:{
			by:{
				type:"GalleryUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"GalleryWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateGallery:{
			by:{
				type:"GalleryUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"GalleryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"GalleryUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createProductVariantStore:{
			data:{
				type:"ProductVariantStoreCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteProductVariantStore:{
			by:{
				type:"ProductVariantStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateProductVariantStore:{
			by:{
				type:"ProductVariantStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ProductVariantStoreUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createProductStore:{
			data:{
				type:"ProductStoreCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteProductStore:{
			by:{
				type:"ProductStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateProductStore:{
			by:{
				type:"ProductStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ProductStoreUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconListItem:{
			data:{
				type:"IconListItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconListItem:{
			by:{
				type:"IconListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconListItem:{
			by:{
				type:"IconListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconListItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconList:{
			data:{
				type:"IconListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconList:{
			by:{
				type:"IconListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconList:{
			by:{
				type:"IconListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconGroupListItem:{
			data:{
				type:"IconGroupListItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconGroupListItem:{
			by:{
				type:"IconGroupListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconGroupListItem:{
			by:{
				type:"IconGroupListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconGroupListItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconGroupList:{
			data:{
				type:"IconGroupListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconGroupList:{
			by:{
				type:"IconGroupListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconGroupList:{
			by:{
				type:"IconGroupListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconGroupListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createProductVariantLocale:{
			data:{
				type:"ProductVariantLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteProductVariantLocale:{
			by:{
				type:"ProductVariantLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateProductVariantLocale:{
			by:{
				type:"ProductVariantLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ProductVariantLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createProductVariant:{
			data:{
				type:"ProductVariantCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteProductVariant:{
			by:{
				type:"ProductVariantUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateProductVariant:{
			by:{
				type:"ProductVariantUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ProductVariantUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createProduct:{
			data:{
				type:"ProductCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteProduct:{
			by:{
				type:"ProductUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateProduct:{
			by:{
				type:"ProductUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ProductUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createImageLocale:{
			data:{
				type:"ImageLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteImageLocale:{
			by:{
				type:"ImageLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateImageLocale:{
			by:{
				type:"ImageLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ImageLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createStore:{
			data:{
				type:"StoreCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteStore:{
			by:{
				type:"StoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"StoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateStore:{
			by:{
				type:"StoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"StoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"StoreUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createAdminNote:{
			data:{
				type:"AdminNoteCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteAdminNote:{
			by:{
				type:"AdminNoteUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"AdminNoteWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateAdminNote:{
			by:{
				type:"AdminNoteUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"AdminNoteWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"AdminNoteUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createPageLocale:{
			data:{
				type:"PageLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deletePageLocale:{
			by:{
				type:"PageLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updatePageLocale:{
			by:{
				type:"PageLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"PageLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createPage:{
			data:{
				type:"PageCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deletePage:{
			by:{
				type:"PageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updatePage:{
			by:{
				type:"PageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"PageUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createImageXImageList:{
			data:{
				type:"ImageXImageListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteImageXImageList:{
			by:{
				type:"ImageXImageListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageXImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateImageXImageList:{
			by:{
				type:"ImageXImageListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageXImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ImageXImageListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconGroupLocale:{
			data:{
				type:"IconGroupLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconGroupLocale:{
			by:{
				type:"IconGroupLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconGroupLocale:{
			by:{
				type:"IconGroupLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconGroupLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconLocale:{
			data:{
				type:"IconLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconLocale:{
			by:{
				type:"IconLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconLocale:{
			by:{
				type:"IconLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIconGroup:{
			data:{
				type:"IconGroupCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIconGroup:{
			by:{
				type:"IconGroupUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIconGroup:{
			by:{
				type:"IconGroupUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconGroupWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconGroupUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createIcon:{
			data:{
				type:"IconCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteIcon:{
			by:{
				type:"IconUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateIcon:{
			by:{
				type:"IconUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"IconWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"IconUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createLocale:{
			data:{
				type:"LocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteLocale:{
			by:{
				type:"LocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"LocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateLocale:{
			by:{
				type:"LocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"LocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"LocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createSeoLocale:{
			data:{
				type:"SeoLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteSeoLocale:{
			by:{
				type:"SeoLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SeoLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateSeoLocale:{
			by:{
				type:"SeoLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SeoLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"SeoLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createSeo:{
			data:{
				type:"SeoCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteSeo:{
			by:{
				type:"SeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateSeo:{
			by:{
				type:"SeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"SeoUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createLinkable:{
			data:{
				type:"LinkableCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteLinkable:{
			by:{
				type:"LinkableUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateLinkable:{
			by:{
				type:"LinkableUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"LinkableUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createImageList:{
			data:{
				type:"ImageListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteImageList:{
			by:{
				type:"ImageListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateImageList:{
			by:{
				type:"ImageListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ImageListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		createImage:{
			data:{
				type:"ImageCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		deleteImage:{
			by:{
				type:"ImageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		updateImage:{
			by:{
				type:"ImageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			data:{
				type:"ImageUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	OrderDirection: "enum",
	Page:{
		seo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		locales:{
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PageLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		localesByLocale:{
			by:{
				type:"PageLocalesByLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		localesByLink:{
			by:{
				type:"PageLocalesByLinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		localesByContent:{
			by:{
				type:"PageLocalesByContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	PageCreateInput:{
		internalName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		template:{
			type:"PageType",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"PageCreateSeoEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"PageCreateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageCreateLocalesEntityRelationInput:{
		connect:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"PageLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageCreateSeoEntityRelationInput:{
		connect:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"SeoCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocale:{
		locale:{
			filter:{
				type:"LocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		link:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		root:{
			filter:{
				type:"PageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		content:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	PageLocaleCreateContentEntityRelationInput:{
		connect:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ContentWithoutPageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"PageLocaleCreateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"PageLocaleCreateLinkEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"PageLocaleCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"PageLocaleCreateContentEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerBgColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleCreateLinkEntityRelationInput:{
		connect:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LinkableWithoutPageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleCreateLocaleEntityRelationInput:{
		connect:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LocaleWithoutPagesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleCreateRootEntityRelationInput:{
		connect:{
			type:"PageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"PageWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"PageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerColor:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerBgColor:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerText:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocalesByContentUniqueWhere:{
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocalesByLinkUniqueWhere:{
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocalesByLocaleUniqueWhere:{
		locale:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"PageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleUpdateContentEntityRelationInput:{
		create:{
			type:"ContentWithoutPageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ContentWithoutPageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"PageLocaleUpsertContentRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ContentUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"PageLocaleUpdateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"PageLocaleUpdateLinkEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"PageLocaleUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"PageLocaleUpdateContentEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerBgColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleUpdateLinkEntityRelationInput:{
		create:{
			type:"LinkableWithoutPageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"LinkableWithoutPageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"PageLocaleUpsertLinkRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"LinkableUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleUpdateLocaleEntityRelationInput:{
		create:{
			type:"LocaleWithoutPagesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"LocaleWithoutPagesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"PageLocaleUpsertLocaleRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleUpdateRootEntityRelationInput:{
		create:{
			type:"PageWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"PageWithoutLocalesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"PageLocaleUpsertRootRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"PageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleUpsertContentRelationInput:{
		update:{
			type:"ContentWithoutPageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ContentWithoutPageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleUpsertLinkRelationInput:{
		update:{
			type:"LinkableWithoutPageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LinkableWithoutPageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleUpsertLocaleRelationInput:{
		update:{
			type:"LocaleWithoutPagesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LocaleWithoutPagesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleUpsertRootRelationInput:{
		update:{
			type:"PageWithoutLocalesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"PageWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"LinkableWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"PageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"ContentWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerColor:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerBgColor:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerText:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"PageLocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"PageLocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"PageLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleWithoutContentCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"PageLocaleCreateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"PageLocaleCreateLinkEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"PageLocaleCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerBgColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleWithoutContentUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"PageLocaleUpdateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"PageLocaleUpdateLinkEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"PageLocaleUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerBgColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleWithoutLinkCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"PageLocaleCreateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"PageLocaleCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"PageLocaleCreateContentEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerBgColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleWithoutLinkUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"PageLocaleUpdateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"PageLocaleUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"PageLocaleUpdateContentEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerBgColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleWithoutLocaleCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"PageLocaleCreateLinkEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"PageLocaleCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"PageLocaleCreateContentEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerBgColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleWithoutLocaleUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"PageLocaleUpdateLinkEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"PageLocaleUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"PageLocaleUpdateContentEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerBgColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleWithoutRootCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"PageLocaleCreateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"PageLocaleCreateLinkEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"PageLocaleCreateContentEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerBgColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageLocaleWithoutRootUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"PageLocaleUpdateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		link:{
			type:"PageLocaleUpdateLinkEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"PageLocaleUpdateContentEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerBgColor:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		bannerText:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		internalName:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		template:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageType: "enum",
	PageTypeEnumCondition:{
		and:{
			type:"PageTypeEnumCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"PageTypeEnumCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"PageTypeEnumCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"PageType",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"PageType",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"PageType",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"PageType",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"PageType",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"PageType",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"PageType",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"PageType",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageUpdateInput:{
		internalName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		template:{
			type:"PageType",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"PageUpdateSeoEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"PageUpdateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageUpdateLocalesEntityRelationInput:{
		create:{
			type:"PageLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"PageUpdateLocalesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"PageUpsertLocalesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageUpdateLocalesRelationInput:{
		by:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"PageLocaleWithoutRootUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageUpdateSeoEntityRelationInput:{
		create:{
			type:"SeoCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"SeoUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"PageUpsertSeoRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageUpsertLocalesRelationInput:{
		by:{
			type:"PageLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"PageLocaleWithoutRootUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"PageLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageUpsertSeoRelationInput:{
		update:{
			type:"SeoUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"SeoCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		internalName:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		template:{
			type:"PageTypeEnumCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"PageLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"PageWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"PageWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"PageWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageWithoutLocalesCreateInput:{
		internalName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		template:{
			type:"PageType",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"PageCreateSeoEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	PageWithoutLocalesUpdateInput:{
		internalName:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		template:{
			type:"PageType",
			array:false,
			arrayRequired:false,
			required:false
		},
		seo:{
			type:"PageUpdateSeoEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Product:{
		stores:{
			filter:{
				type:"ProductStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductStoreOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		productVariants:{
			filter:{
				type:"ProductVariantWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		locales:{
			filter:{
				type:"ProductLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		storesByStore:{
			by:{
				type:"ProductStoresByStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		storesByShopifyId:{
			by:{
				type:"ProductStoresByShopifyIdUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		productVariantsByShopifySku:{
			by:{
				type:"ProductProductVariantsByShopifySkuUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		productVariantsByLocales:{
			by:{
				type:"ProductProductVariantsByLocalesUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		productVariantsByStores:{
			by:{
				type:"ProductProductVariantsByStoresUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		localesByLocale:{
			by:{
				type:"ProductLocalesByLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ProductCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyHandle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		productType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stores:{
			type:"ProductCreateStoresEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"ProductCreateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		locales:{
			type:"ProductCreateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductCreateLocalesEntityRelationInput:{
		connect:{
			type:"ProductLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductCreateProductVariantsEntityRelationInput:{
		connect:{
			type:"ProductVariantUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductVariantWithoutProductCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductCreateStoresEntityRelationInput:{
		connect:{
			type:"ProductStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductStoreWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocale:{
		root:{
			filter:{
				type:"ProductWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		locale:{
			filter:{
				type:"LocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		gallery:{
			filter:{
				type:"GalleryWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ProductLocaleCreateGalleryEntityRelationInput:{
		connect:{
			type:"GalleryUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"GalleryCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptionSecondary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductLocaleCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"ProductLocaleCreateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		gallery:{
			type:"ProductLocaleCreateGalleryEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		titleTemplate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleCreateLocaleEntityRelationInput:{
		connect:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LocaleWithoutProductsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleCreateRootEntityRelationInput:{
		connect:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptionSecondary:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		gallery:{
			type:"GalleryOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		titleTemplate:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocalesByLocaleUniqueWhere:{
		locale:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleUpdateGalleryEntityRelationInput:{
		create:{
			type:"GalleryCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"GalleryUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ProductLocaleUpsertGalleryRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"GalleryUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptionSecondary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductLocaleUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"ProductLocaleUpdateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		gallery:{
			type:"ProductLocaleUpdateGalleryEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		titleTemplate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleUpdateLocaleEntityRelationInput:{
		create:{
			type:"LocaleWithoutProductsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"LocaleWithoutProductsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ProductLocaleUpsertLocaleRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleUpdateRootEntityRelationInput:{
		create:{
			type:"ProductWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductWithoutLocalesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ProductLocaleUpsertRootRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleUpsertGalleryRelationInput:{
		update:{
			type:"GalleryUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"GalleryCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleUpsertLocaleRelationInput:{
		update:{
			type:"LocaleWithoutProductsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LocaleWithoutProductsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleUpsertRootRelationInput:{
		update:{
			type:"ProductWithoutLocalesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptionSecondary:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		gallery:{
			type:"GalleryWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		titleTemplate:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ProductLocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ProductLocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ProductLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleWithoutLocaleCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptionSecondary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductLocaleCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		gallery:{
			type:"ProductLocaleCreateGalleryEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		titleTemplate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleWithoutLocaleUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptionSecondary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductLocaleUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		gallery:{
			type:"ProductLocaleUpdateGalleryEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		titleTemplate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleWithoutRootCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptionSecondary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"ProductLocaleCreateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		gallery:{
			type:"ProductLocaleCreateGalleryEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		titleTemplate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductLocaleWithoutRootUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptionSecondary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"ProductLocaleUpdateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		gallery:{
			type:"ProductLocaleUpdateGalleryEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		titleTemplate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyHandle:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		productType:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductProductVariantsByLocalesUniqueWhere:{
		locales:{
			type:"ProductVariantLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductProductVariantsByShopifySkuUniqueWhere:{
		shopifySku:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductProductVariantsByStoresUniqueWhere:{
		stores:{
			type:"ProductVariantStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStore:{
		root:{
			filter:{
				type:"ProductWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store:{
			filter:{
				type:"StoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ProductStoreCreateInput:{
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductStoreCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"ProductStoreCreateStoreEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStoreCreateRootEntityRelationInput:{
		connect:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductWithoutStoresCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStoreCreateStoreEntityRelationInput:{
		connect:{
			type:"StoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"StoreWithoutProductsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStoreOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"StoreOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStoresByShopifyIdUniqueWhere:{
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStoresByStoreUniqueWhere:{
		store:{
			type:"StoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStoreUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"StoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStoreUpdateInput:{
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductStoreUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"ProductStoreUpdateStoreEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStoreUpdateRootEntityRelationInput:{
		create:{
			type:"ProductWithoutStoresCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductWithoutStoresUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ProductStoreUpsertRootRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStoreUpdateStoreEntityRelationInput:{
		create:{
			type:"StoreWithoutProductsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"StoreWithoutProductsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ProductStoreUpsertStoreRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"StoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStoreUpsertRootRelationInput:{
		update:{
			type:"ProductWithoutStoresUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductWithoutStoresCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStoreUpsertStoreRelationInput:{
		update:{
			type:"StoreWithoutProductsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"StoreWithoutProductsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStoreWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"StoreWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ProductStoreWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ProductStoreWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ProductStoreWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStoreWithoutRootCreateInput:{
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"ProductStoreCreateStoreEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStoreWithoutRootUpdateInput:{
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"ProductStoreUpdateStoreEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStoreWithoutStoreCreateInput:{
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductStoreCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductStoreWithoutStoreUpdateInput:{
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductStoreUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyHandle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stores:{
			type:"ProductStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		productVariants:{
			type:"ProductVariantUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"ProductLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyHandle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		productType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stores:{
			type:"ProductUpdateStoresEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"ProductUpdateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		locales:{
			type:"ProductUpdateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductUpdateLocalesEntityRelationInput:{
		create:{
			type:"ProductLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductUpdateLocalesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ProductUpsertLocalesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ProductLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"ProductLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"ProductLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductUpdateLocalesRelationInput:{
		by:{
			type:"ProductLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"ProductLocaleWithoutRootUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductUpdateProductVariantsEntityRelationInput:{
		create:{
			type:"ProductVariantWithoutProductCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductUpdateProductVariantsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ProductUpsertProductVariantsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ProductVariantUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"ProductVariantUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"ProductVariantUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductUpdateProductVariantsRelationInput:{
		by:{
			type:"ProductVariantUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"ProductVariantWithoutProductUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductUpdateStoresEntityRelationInput:{
		create:{
			type:"ProductStoreWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductUpdateStoresRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ProductUpsertStoresRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ProductStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"ProductStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"ProductStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductUpdateStoresRelationInput:{
		by:{
			type:"ProductStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"ProductStoreWithoutRootUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductUpsertLocalesRelationInput:{
		by:{
			type:"ProductLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductLocaleWithoutRootUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductUpsertProductVariantsRelationInput:{
		by:{
			type:"ProductVariantUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductVariantWithoutProductUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductVariantWithoutProductCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductUpsertStoresRelationInput:{
		by:{
			type:"ProductStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductStoreWithoutRootUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductStoreWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariant:{
		product:{
			filter:{
				type:"ProductWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		locales:{
			filter:{
				type:"ProductVariantLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		stores:{
			filter:{
				type:"ProductVariantStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantStoreOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		localesByLocale:{
			by:{
				type:"ProductVariantLocalesByLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		storesByStore:{
			by:{
				type:"ProductVariantStoresByStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		storesByShopifyId:{
			by:{
				type:"ProductVariantStoresByShopifyIdUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		storesByStorefrontId:{
			by:{
				type:"ProductVariantStoresByStorefrontIdUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ProductVariantCreateInput:{
		shopifySku:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product:{
			type:"ProductVariantCreateProductEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"ProductVariantCreateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		stores:{
			type:"ProductVariantCreateStoresEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		heurekaId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantCreateLocalesEntityRelationInput:{
		connect:{
			type:"ProductVariantLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductVariantLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantCreateProductEntityRelationInput:{
		connect:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductWithoutProductVariantsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantCreateStoresEntityRelationInput:{
		connect:{
			type:"ProductVariantStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductVariantStoreWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocale:{
		root:{
			filter:{
				type:"ProductVariantWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		locale:{
			filter:{
				type:"LocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		gallery:{
			filter:{
				type:"GalleryWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ProductVariantLocaleCreateGalleryEntityRelationInput:{
		connect:{
			type:"GalleryUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"GalleryCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductVariantLocaleCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"ProductVariantLocaleCreateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		gallery:{
			type:"ProductVariantLocaleCreateGalleryEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptionSecondary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		titleTemplate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleCreateLocaleEntityRelationInput:{
		connect:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LocaleWithoutProductVariantsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleCreateRootEntityRelationInput:{
		connect:{
			type:"ProductVariantUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductVariantWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductVariantOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		gallery:{
			type:"GalleryOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptionSecondary:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		titleTemplate:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocalesByLocaleUniqueWhere:{
		locale:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductVariantUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleUpdateGalleryEntityRelationInput:{
		create:{
			type:"GalleryCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"GalleryUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ProductVariantLocaleUpsertGalleryRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"GalleryUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductVariantLocaleUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"ProductVariantLocaleUpdateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		gallery:{
			type:"ProductVariantLocaleUpdateGalleryEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptionSecondary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		titleTemplate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleUpdateLocaleEntityRelationInput:{
		create:{
			type:"LocaleWithoutProductVariantsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"LocaleWithoutProductVariantsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ProductVariantLocaleUpsertLocaleRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleUpdateRootEntityRelationInput:{
		create:{
			type:"ProductVariantWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductVariantWithoutLocalesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ProductVariantLocaleUpsertRootRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ProductVariantUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleUpsertGalleryRelationInput:{
		update:{
			type:"GalleryUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"GalleryCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleUpsertLocaleRelationInput:{
		update:{
			type:"LocaleWithoutProductVariantsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LocaleWithoutProductVariantsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleUpsertRootRelationInput:{
		update:{
			type:"ProductVariantWithoutLocalesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductVariantWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductVariantWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		gallery:{
			type:"GalleryWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptionSecondary:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		titleTemplate:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ProductVariantLocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ProductVariantLocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ProductVariantLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleWithoutLocaleCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductVariantLocaleCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		gallery:{
			type:"ProductVariantLocaleCreateGalleryEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptionSecondary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		titleTemplate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleWithoutLocaleUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductVariantLocaleUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		gallery:{
			type:"ProductVariantLocaleUpdateGalleryEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptionSecondary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		titleTemplate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleWithoutRootCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"ProductVariantLocaleCreateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		gallery:{
			type:"ProductVariantLocaleCreateGalleryEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptionSecondary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		titleTemplate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantLocaleWithoutRootUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"ProductVariantLocaleUpdateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		gallery:{
			type:"ProductVariantLocaleUpdateGalleryEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		descriptionSecondary:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		titleTemplate:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifySku:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		product:{
			type:"ProductOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		heurekaId:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStore:{
		root:{
			filter:{
				type:"ProductVariantWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		store:{
			filter:{
				type:"StoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ProductVariantStoreCreateInput:{
		price:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductVariantStoreCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"ProductVariantStoreCreateStoreEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		compareAtPrice:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		storefrontId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoreCreateRootEntityRelationInput:{
		connect:{
			type:"ProductVariantUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductVariantWithoutStoresCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoreCreateStoreEntityRelationInput:{
		connect:{
			type:"StoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"StoreWithoutProductVariantsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoreOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		price:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductVariantOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"StoreOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		compareAtPrice:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		storefrontId:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoresByShopifyIdUniqueWhere:{
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoresByStorefrontIdUniqueWhere:{
		storefrontId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoresByStoreUniqueWhere:{
		store:{
			type:"StoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoreUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductVariantUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"StoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		storefrontId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoreUpdateInput:{
		price:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductVariantStoreUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"ProductVariantStoreUpdateStoreEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		compareAtPrice:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		storefrontId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoreUpdateRootEntityRelationInput:{
		create:{
			type:"ProductVariantWithoutStoresCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductVariantWithoutStoresUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ProductVariantStoreUpsertRootRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ProductVariantUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoreUpdateStoreEntityRelationInput:{
		create:{
			type:"StoreWithoutProductVariantsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"StoreWithoutProductVariantsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ProductVariantStoreUpsertStoreRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"StoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoreUpsertRootRelationInput:{
		update:{
			type:"ProductVariantWithoutStoresUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductVariantWithoutStoresCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoreUpsertStoreRelationInput:{
		update:{
			type:"StoreWithoutProductVariantsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"StoreWithoutProductVariantsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoreWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		price:{
			type:"FloatCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductVariantWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"StoreWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		compareAtPrice:{
			type:"FloatCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		storefrontId:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ProductVariantStoreWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ProductVariantStoreWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ProductVariantStoreWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoreWithoutRootCreateInput:{
		price:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"ProductVariantStoreCreateStoreEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		compareAtPrice:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		storefrontId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoreWithoutRootUpdateInput:{
		price:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"ProductVariantStoreUpdateStoreEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		compareAtPrice:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		storefrontId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoreWithoutStoreCreateInput:{
		price:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductVariantStoreCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		compareAtPrice:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		storefrontId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantStoreWithoutStoreUpdateInput:{
		price:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"ProductVariantStoreUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		compareAtPrice:{
			type:"Float",
			array:false,
			arrayRequired:false,
			required:false
		},
		storefrontId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifySku:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"ProductVariantLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		stores:{
			type:"ProductVariantStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantUpdateInput:{
		shopifySku:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product:{
			type:"ProductVariantUpdateProductEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"ProductVariantUpdateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		stores:{
			type:"ProductVariantUpdateStoresEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		heurekaId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantUpdateLocalesEntityRelationInput:{
		create:{
			type:"ProductVariantLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductVariantUpdateLocalesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ProductVariantUpsertLocalesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ProductVariantLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"ProductVariantLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"ProductVariantLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantUpdateLocalesRelationInput:{
		by:{
			type:"ProductVariantLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"ProductVariantLocaleWithoutRootUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantUpdateProductEntityRelationInput:{
		create:{
			type:"ProductWithoutProductVariantsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductWithoutProductVariantsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ProductVariantUpsertProductRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantUpdateStoresEntityRelationInput:{
		create:{
			type:"ProductVariantStoreWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductVariantUpdateStoresRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ProductVariantUpsertStoresRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ProductVariantStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"ProductVariantStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"ProductVariantStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantUpdateStoresRelationInput:{
		by:{
			type:"ProductVariantStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"ProductVariantStoreWithoutRootUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantUpsertLocalesRelationInput:{
		by:{
			type:"ProductVariantLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductVariantLocaleWithoutRootUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductVariantLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantUpsertProductRelationInput:{
		update:{
			type:"ProductWithoutProductVariantsUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductWithoutProductVariantsCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantUpsertStoresRelationInput:{
		by:{
			type:"ProductVariantStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductVariantStoreWithoutRootUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductVariantStoreWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifySku:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		product:{
			type:"ProductWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"ProductVariantLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		stores:{
			type:"ProductVariantStoreWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		heurekaId:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ProductVariantWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ProductVariantWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ProductVariantWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantWithoutLocalesCreateInput:{
		shopifySku:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product:{
			type:"ProductVariantCreateProductEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		stores:{
			type:"ProductVariantCreateStoresEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		heurekaId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantWithoutLocalesUpdateInput:{
		shopifySku:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product:{
			type:"ProductVariantUpdateProductEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		stores:{
			type:"ProductVariantUpdateStoresEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		heurekaId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantWithoutProductCreateInput:{
		shopifySku:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"ProductVariantCreateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		stores:{
			type:"ProductVariantCreateStoresEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		heurekaId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantWithoutProductUpdateInput:{
		shopifySku:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"ProductVariantUpdateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		stores:{
			type:"ProductVariantUpdateStoresEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		heurekaId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantWithoutStoresCreateInput:{
		shopifySku:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product:{
			type:"ProductVariantCreateProductEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"ProductVariantCreateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		heurekaId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductVariantWithoutStoresUpdateInput:{
		shopifySku:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		product:{
			type:"ProductVariantUpdateProductEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"ProductVariantUpdateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		heurekaId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyHandle:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		productType:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		stores:{
			type:"ProductStoreWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		productVariants:{
			type:"ProductVariantWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"ProductLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ProductWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ProductWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ProductWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductWithoutLocalesCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyHandle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		productType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stores:{
			type:"ProductCreateStoresEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"ProductCreateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductWithoutLocalesUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyHandle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		productType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stores:{
			type:"ProductUpdateStoresEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"ProductUpdateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductWithoutProductVariantsCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyHandle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		productType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stores:{
			type:"ProductCreateStoresEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		locales:{
			type:"ProductCreateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductWithoutProductVariantsUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyHandle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		productType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		stores:{
			type:"ProductUpdateStoresEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		locales:{
			type:"ProductUpdateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductWithoutStoresCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyHandle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		productType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		productVariants:{
			type:"ProductCreateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		locales:{
			type:"ProductCreateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ProductWithoutStoresUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyHandle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		productType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		productVariants:{
			type:"ProductUpdateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		locales:{
			type:"ProductUpdateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Query:{
		getAbandonedCheckoutUnsubscription:{
			by:{
				type:"AbandonedCheckoutUnsubscriptionUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listAbandonedCheckoutUnsubscription:{
			filter:{
				type:"AbandonedCheckoutUnsubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AbandonedCheckoutUnsubscriptionOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateAbandonedCheckoutUnsubscription:{
			filter:{
				type:"AbandonedCheckoutUnsubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AbandonedCheckoutUnsubscriptionOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateAbandonedCheckoutUnsubscription:{
			data:{
				type:"AbandonedCheckoutUnsubscriptionCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateAbandonedCheckoutUnsubscription:{
			by:{
				type:"AbandonedCheckoutUnsubscriptionUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"AbandonedCheckoutUnsubscriptionUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getAbandonedCheckoutNotification:{
			by:{
				type:"AbandonedCheckoutNotificationUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listAbandonedCheckoutNotification:{
			filter:{
				type:"AbandonedCheckoutNotificationWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AbandonedCheckoutNotificationOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateAbandonedCheckoutNotification:{
			filter:{
				type:"AbandonedCheckoutNotificationWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AbandonedCheckoutNotificationOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateAbandonedCheckoutNotification:{
			data:{
				type:"AbandonedCheckoutNotificationCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateAbandonedCheckoutNotification:{
			by:{
				type:"AbandonedCheckoutNotificationUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"AbandonedCheckoutNotificationUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getShopifyLineItem:{
			by:{
				type:"ShopifyLineItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listShopifyLineItem:{
			filter:{
				type:"ShopifyLineItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ShopifyLineItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateShopifyLineItem:{
			filter:{
				type:"ShopifyLineItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ShopifyLineItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateShopifyLineItem:{
			data:{
				type:"ShopifyLineItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateShopifyLineItem:{
			by:{
				type:"ShopifyLineItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ShopifyLineItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getShopifyOrder:{
			by:{
				type:"ShopifyOrderUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listShopifyOrder:{
			filter:{
				type:"ShopifyOrderWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ShopifyOrderOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateShopifyOrder:{
			filter:{
				type:"ShopifyOrderWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ShopifyOrderOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateShopifyOrder:{
			data:{
				type:"ShopifyOrderCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateShopifyOrder:{
			by:{
				type:"ShopifyOrderUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ShopifyOrderUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getTranslationValue:{
			by:{
				type:"TranslationValueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listTranslationValue:{
			filter:{
				type:"TranslationValueWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationValueOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateTranslationValue:{
			filter:{
				type:"TranslationValueWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationValueOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateTranslationValue:{
			data:{
				type:"TranslationValueCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateTranslationValue:{
			by:{
				type:"TranslationValueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"TranslationValueUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getTranslationKey:{
			by:{
				type:"TranslationKeyUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listTranslationKey:{
			filter:{
				type:"TranslationKeyWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationKeyOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateTranslationKey:{
			filter:{
				type:"TranslationKeyWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationKeyOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateTranslationKey:{
			data:{
				type:"TranslationKeyCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateTranslationKey:{
			by:{
				type:"TranslationKeyUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"TranslationKeyUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getTranslationCatalogue:{
			by:{
				type:"TranslationCatalogueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listTranslationCatalogue:{
			filter:{
				type:"TranslationCatalogueWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationCatalogueOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateTranslationCatalogue:{
			filter:{
				type:"TranslationCatalogueWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationCatalogueOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateTranslationCatalogue:{
			data:{
				type:"TranslationCatalogueCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateTranslationCatalogue:{
			by:{
				type:"TranslationCatalogueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"TranslationCatalogueUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getTranslationDomain:{
			by:{
				type:"TranslationDomainUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listTranslationDomain:{
			filter:{
				type:"TranslationDomainWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationDomainOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateTranslationDomain:{
			filter:{
				type:"TranslationDomainWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationDomainOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateTranslationDomain:{
			data:{
				type:"TranslationDomainCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateTranslationDomain:{
			by:{
				type:"TranslationDomainUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"TranslationDomainUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProductLocale:{
			by:{
				type:"ProductLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listProductLocale:{
			filter:{
				type:"ProductLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateProductLocale:{
			filter:{
				type:"ProductLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateProductLocale:{
			data:{
				type:"ProductLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateProductLocale:{
			by:{
				type:"ProductLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ProductLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getMedia:{
			by:{
				type:"MediaUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listMedia:{
			filter:{
				type:"MediaWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"MediaOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateMedia:{
			filter:{
				type:"MediaWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"MediaOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateMedia:{
			data:{
				type:"MediaCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateMedia:{
			by:{
				type:"MediaUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"MediaUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconListBlockItem:{
			by:{
				type:"IconListBlockItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconListBlockItem:{
			filter:{
				type:"IconListBlockItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListBlockItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconListBlockItem:{
			filter:{
				type:"IconListBlockItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListBlockItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconListBlockItem:{
			data:{
				type:"IconListBlockItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconListBlockItem:{
			by:{
				type:"IconListBlockItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconListBlockItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconListBlock:{
			by:{
				type:"IconListBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconListBlock:{
			filter:{
				type:"IconListBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListBlockOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconListBlock:{
			filter:{
				type:"IconListBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListBlockOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconListBlock:{
			data:{
				type:"IconListBlockCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconListBlock:{
			by:{
				type:"IconListBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconListBlockUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getFeatureListItem:{
			by:{
				type:"FeatureListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listFeatureListItem:{
			filter:{
				type:"FeatureListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FeatureListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateFeatureListItem:{
			filter:{
				type:"FeatureListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FeatureListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateFeatureListItem:{
			data:{
				type:"FeatureListItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateFeatureListItem:{
			by:{
				type:"FeatureListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"FeatureListItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getFeatureList:{
			by:{
				type:"FeatureListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listFeatureList:{
			filter:{
				type:"FeatureListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FeatureListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateFeatureList:{
			filter:{
				type:"FeatureListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FeatureListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateFeatureList:{
			data:{
				type:"FeatureListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateFeatureList:{
			by:{
				type:"FeatureListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"FeatureListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getFaqItem:{
			by:{
				type:"FaqItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listFaqItem:{
			filter:{
				type:"FaqItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FaqItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateFaqItem:{
			filter:{
				type:"FaqItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FaqItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateFaqItem:{
			data:{
				type:"FaqItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateFaqItem:{
			by:{
				type:"FaqItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"FaqItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getFaq:{
			by:{
				type:"FaqUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listFaq:{
			filter:{
				type:"FaqWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FaqOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateFaq:{
			filter:{
				type:"FaqWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FaqOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateFaq:{
			data:{
				type:"FaqCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateFaq:{
			by:{
				type:"FaqUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"FaqUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getContentBlock:{
			by:{
				type:"ContentBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listContentBlock:{
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentBlockOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateContentBlock:{
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentBlockOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateContentBlock:{
			data:{
				type:"ContentBlockCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateContentBlock:{
			by:{
				type:"ContentBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ContentBlockUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getContent:{
			by:{
				type:"ContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listContent:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateContent:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateContent:{
			data:{
				type:"ContentCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateContent:{
			by:{
				type:"ContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ContentUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getLink:{
			by:{
				type:"LinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listLink:{
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LinkOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateLink:{
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LinkOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateLink:{
			data:{
				type:"LinkCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateLink:{
			by:{
				type:"LinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"LinkUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getReviewLocale:{
			by:{
				type:"ReviewLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listReviewLocale:{
			filter:{
				type:"ReviewLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ReviewLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateReviewLocale:{
			filter:{
				type:"ReviewLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ReviewLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateReviewLocale:{
			data:{
				type:"ReviewLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateReviewLocale:{
			by:{
				type:"ReviewLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ReviewLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getReview:{
			by:{
				type:"ReviewUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listReview:{
			filter:{
				type:"ReviewWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ReviewOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateReview:{
			filter:{
				type:"ReviewWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ReviewOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateReview:{
			data:{
				type:"ReviewCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateReview:{
			by:{
				type:"ReviewUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ReviewUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getGalleryItem:{
			by:{
				type:"GalleryItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listGalleryItem:{
			filter:{
				type:"GalleryItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GalleryItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateGalleryItem:{
			filter:{
				type:"GalleryItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GalleryItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateGalleryItem:{
			data:{
				type:"GalleryItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateGalleryItem:{
			by:{
				type:"GalleryItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"GalleryItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getGallery:{
			by:{
				type:"GalleryUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listGallery:{
			filter:{
				type:"GalleryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GalleryOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateGallery:{
			filter:{
				type:"GalleryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GalleryOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateGallery:{
			data:{
				type:"GalleryCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateGallery:{
			by:{
				type:"GalleryUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"GalleryUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProductVariantStore:{
			by:{
				type:"ProductVariantStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listProductVariantStore:{
			filter:{
				type:"ProductVariantStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantStoreOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateProductVariantStore:{
			filter:{
				type:"ProductVariantStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantStoreOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateProductVariantStore:{
			data:{
				type:"ProductVariantStoreCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateProductVariantStore:{
			by:{
				type:"ProductVariantStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ProductVariantStoreUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProductStore:{
			by:{
				type:"ProductStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listProductStore:{
			filter:{
				type:"ProductStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductStoreOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateProductStore:{
			filter:{
				type:"ProductStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductStoreOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateProductStore:{
			data:{
				type:"ProductStoreCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateProductStore:{
			by:{
				type:"ProductStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ProductStoreUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconListItem:{
			by:{
				type:"IconListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconListItem:{
			filter:{
				type:"IconListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconListItem:{
			filter:{
				type:"IconListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconListItem:{
			data:{
				type:"IconListItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconListItem:{
			by:{
				type:"IconListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconListItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconList:{
			by:{
				type:"IconListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconList:{
			filter:{
				type:"IconListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconList:{
			filter:{
				type:"IconListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconList:{
			data:{
				type:"IconListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconList:{
			by:{
				type:"IconListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconGroupListItem:{
			by:{
				type:"IconGroupListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconGroupListItem:{
			filter:{
				type:"IconGroupListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconGroupListItem:{
			filter:{
				type:"IconGroupListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconGroupListItem:{
			data:{
				type:"IconGroupListItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconGroupListItem:{
			by:{
				type:"IconGroupListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconGroupListItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconGroupList:{
			by:{
				type:"IconGroupListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconGroupList:{
			filter:{
				type:"IconGroupListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconGroupList:{
			filter:{
				type:"IconGroupListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconGroupList:{
			data:{
				type:"IconGroupListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconGroupList:{
			by:{
				type:"IconGroupListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconGroupListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProductVariantLocale:{
			by:{
				type:"ProductVariantLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listProductVariantLocale:{
			filter:{
				type:"ProductVariantLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateProductVariantLocale:{
			filter:{
				type:"ProductVariantLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateProductVariantLocale:{
			data:{
				type:"ProductVariantLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateProductVariantLocale:{
			by:{
				type:"ProductVariantLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ProductVariantLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProductVariant:{
			by:{
				type:"ProductVariantUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listProductVariant:{
			filter:{
				type:"ProductVariantWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateProductVariant:{
			filter:{
				type:"ProductVariantWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateProductVariant:{
			data:{
				type:"ProductVariantCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateProductVariant:{
			by:{
				type:"ProductVariantUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ProductVariantUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProduct:{
			by:{
				type:"ProductUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listProduct:{
			filter:{
				type:"ProductWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateProduct:{
			filter:{
				type:"ProductWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateProduct:{
			data:{
				type:"ProductCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateProduct:{
			by:{
				type:"ProductUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ProductUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getImageLocale:{
			by:{
				type:"ImageLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listImageLocale:{
			filter:{
				type:"ImageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateImageLocale:{
			filter:{
				type:"ImageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateImageLocale:{
			data:{
				type:"ImageLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateImageLocale:{
			by:{
				type:"ImageLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ImageLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getStore:{
			by:{
				type:"StoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listStore:{
			filter:{
				type:"StoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"StoreOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateStore:{
			filter:{
				type:"StoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"StoreOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateStore:{
			data:{
				type:"StoreCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateStore:{
			by:{
				type:"StoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"StoreUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getAdminNote:{
			by:{
				type:"AdminNoteUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listAdminNote:{
			filter:{
				type:"AdminNoteWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AdminNoteOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateAdminNote:{
			filter:{
				type:"AdminNoteWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AdminNoteOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateAdminNote:{
			data:{
				type:"AdminNoteCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateAdminNote:{
			by:{
				type:"AdminNoteUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"AdminNoteUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getPageLocale:{
			by:{
				type:"PageLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listPageLocale:{
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PageLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePageLocale:{
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PageLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreatePageLocale:{
			data:{
				type:"PageLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdatePageLocale:{
			by:{
				type:"PageLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"PageLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getPage:{
			by:{
				type:"PageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listPage:{
			filter:{
				type:"PageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePage:{
			filter:{
				type:"PageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreatePage:{
			data:{
				type:"PageCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdatePage:{
			by:{
				type:"PageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"PageUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getImageXImageList:{
			by:{
				type:"ImageXImageListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listImageXImageList:{
			filter:{
				type:"ImageXImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageXImageListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateImageXImageList:{
			filter:{
				type:"ImageXImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageXImageListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateImageXImageList:{
			data:{
				type:"ImageXImageListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateImageXImageList:{
			by:{
				type:"ImageXImageListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ImageXImageListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconGroupLocale:{
			by:{
				type:"IconGroupLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconGroupLocale:{
			filter:{
				type:"IconGroupLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconGroupLocale:{
			filter:{
				type:"IconGroupLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconGroupLocale:{
			data:{
				type:"IconGroupLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconGroupLocale:{
			by:{
				type:"IconGroupLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconGroupLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconLocale:{
			by:{
				type:"IconLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconLocale:{
			filter:{
				type:"IconLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconLocale:{
			filter:{
				type:"IconLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconLocale:{
			data:{
				type:"IconLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconLocale:{
			by:{
				type:"IconLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconGroup:{
			by:{
				type:"IconGroupUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconGroup:{
			filter:{
				type:"IconGroupWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconGroup:{
			filter:{
				type:"IconGroupWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconGroup:{
			data:{
				type:"IconGroupCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconGroup:{
			by:{
				type:"IconGroupUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconGroupUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIcon:{
			by:{
				type:"IconUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIcon:{
			filter:{
				type:"IconWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIcon:{
			filter:{
				type:"IconWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIcon:{
			data:{
				type:"IconCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIcon:{
			by:{
				type:"IconUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getLocale:{
			by:{
				type:"LocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listLocale:{
			filter:{
				type:"LocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateLocale:{
			filter:{
				type:"LocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateLocale:{
			data:{
				type:"LocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateLocale:{
			by:{
				type:"LocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"LocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getSeoLocale:{
			by:{
				type:"SeoLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listSeoLocale:{
			filter:{
				type:"SeoLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateSeoLocale:{
			filter:{
				type:"SeoLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateSeoLocale:{
			data:{
				type:"SeoLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateSeoLocale:{
			by:{
				type:"SeoLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"SeoLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getSeo:{
			by:{
				type:"SeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listSeo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateSeo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateSeo:{
			data:{
				type:"SeoCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateSeo:{
			by:{
				type:"SeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"SeoUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getLinkable:{
			by:{
				type:"LinkableUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listLinkable:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LinkableOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateLinkable:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LinkableOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateLinkable:{
			data:{
				type:"LinkableCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateLinkable:{
			by:{
				type:"LinkableUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"LinkableUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getImageList:{
			by:{
				type:"ImageListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listImageList:{
			filter:{
				type:"ImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateImageList:{
			filter:{
				type:"ImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateImageList:{
			data:{
				type:"ImageListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateImageList:{
			by:{
				type:"ImageListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ImageListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getImage:{
			by:{
				type:"ImageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listImage:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateImage:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateImage:{
			data:{
				type:"ImageCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateImage:{
			by:{
				type:"ImageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ImageUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	QueryTransaction:{
		getAbandonedCheckoutUnsubscription:{
			by:{
				type:"AbandonedCheckoutUnsubscriptionUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listAbandonedCheckoutUnsubscription:{
			filter:{
				type:"AbandonedCheckoutUnsubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AbandonedCheckoutUnsubscriptionOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateAbandonedCheckoutUnsubscription:{
			filter:{
				type:"AbandonedCheckoutUnsubscriptionWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AbandonedCheckoutUnsubscriptionOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateAbandonedCheckoutUnsubscription:{
			data:{
				type:"AbandonedCheckoutUnsubscriptionCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateAbandonedCheckoutUnsubscription:{
			by:{
				type:"AbandonedCheckoutUnsubscriptionUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"AbandonedCheckoutUnsubscriptionUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getAbandonedCheckoutNotification:{
			by:{
				type:"AbandonedCheckoutNotificationUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listAbandonedCheckoutNotification:{
			filter:{
				type:"AbandonedCheckoutNotificationWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AbandonedCheckoutNotificationOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateAbandonedCheckoutNotification:{
			filter:{
				type:"AbandonedCheckoutNotificationWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AbandonedCheckoutNotificationOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateAbandonedCheckoutNotification:{
			data:{
				type:"AbandonedCheckoutNotificationCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateAbandonedCheckoutNotification:{
			by:{
				type:"AbandonedCheckoutNotificationUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"AbandonedCheckoutNotificationUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getShopifyLineItem:{
			by:{
				type:"ShopifyLineItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listShopifyLineItem:{
			filter:{
				type:"ShopifyLineItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ShopifyLineItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateShopifyLineItem:{
			filter:{
				type:"ShopifyLineItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ShopifyLineItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateShopifyLineItem:{
			data:{
				type:"ShopifyLineItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateShopifyLineItem:{
			by:{
				type:"ShopifyLineItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ShopifyLineItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getShopifyOrder:{
			by:{
				type:"ShopifyOrderUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listShopifyOrder:{
			filter:{
				type:"ShopifyOrderWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ShopifyOrderOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateShopifyOrder:{
			filter:{
				type:"ShopifyOrderWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ShopifyOrderOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateShopifyOrder:{
			data:{
				type:"ShopifyOrderCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateShopifyOrder:{
			by:{
				type:"ShopifyOrderUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ShopifyOrderUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getTranslationValue:{
			by:{
				type:"TranslationValueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listTranslationValue:{
			filter:{
				type:"TranslationValueWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationValueOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateTranslationValue:{
			filter:{
				type:"TranslationValueWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationValueOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateTranslationValue:{
			data:{
				type:"TranslationValueCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateTranslationValue:{
			by:{
				type:"TranslationValueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"TranslationValueUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getTranslationKey:{
			by:{
				type:"TranslationKeyUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listTranslationKey:{
			filter:{
				type:"TranslationKeyWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationKeyOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateTranslationKey:{
			filter:{
				type:"TranslationKeyWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationKeyOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateTranslationKey:{
			data:{
				type:"TranslationKeyCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateTranslationKey:{
			by:{
				type:"TranslationKeyUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"TranslationKeyUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getTranslationCatalogue:{
			by:{
				type:"TranslationCatalogueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listTranslationCatalogue:{
			filter:{
				type:"TranslationCatalogueWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationCatalogueOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateTranslationCatalogue:{
			filter:{
				type:"TranslationCatalogueWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationCatalogueOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateTranslationCatalogue:{
			data:{
				type:"TranslationCatalogueCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateTranslationCatalogue:{
			by:{
				type:"TranslationCatalogueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"TranslationCatalogueUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getTranslationDomain:{
			by:{
				type:"TranslationDomainUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listTranslationDomain:{
			filter:{
				type:"TranslationDomainWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationDomainOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateTranslationDomain:{
			filter:{
				type:"TranslationDomainWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationDomainOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateTranslationDomain:{
			data:{
				type:"TranslationDomainCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateTranslationDomain:{
			by:{
				type:"TranslationDomainUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"TranslationDomainUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProductLocale:{
			by:{
				type:"ProductLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listProductLocale:{
			filter:{
				type:"ProductLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateProductLocale:{
			filter:{
				type:"ProductLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateProductLocale:{
			data:{
				type:"ProductLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateProductLocale:{
			by:{
				type:"ProductLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ProductLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getMedia:{
			by:{
				type:"MediaUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listMedia:{
			filter:{
				type:"MediaWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"MediaOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateMedia:{
			filter:{
				type:"MediaWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"MediaOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateMedia:{
			data:{
				type:"MediaCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateMedia:{
			by:{
				type:"MediaUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"MediaUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconListBlockItem:{
			by:{
				type:"IconListBlockItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconListBlockItem:{
			filter:{
				type:"IconListBlockItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListBlockItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconListBlockItem:{
			filter:{
				type:"IconListBlockItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListBlockItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconListBlockItem:{
			data:{
				type:"IconListBlockItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconListBlockItem:{
			by:{
				type:"IconListBlockItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconListBlockItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconListBlock:{
			by:{
				type:"IconListBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconListBlock:{
			filter:{
				type:"IconListBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListBlockOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconListBlock:{
			filter:{
				type:"IconListBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListBlockOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconListBlock:{
			data:{
				type:"IconListBlockCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconListBlock:{
			by:{
				type:"IconListBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconListBlockUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getFeatureListItem:{
			by:{
				type:"FeatureListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listFeatureListItem:{
			filter:{
				type:"FeatureListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FeatureListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateFeatureListItem:{
			filter:{
				type:"FeatureListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FeatureListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateFeatureListItem:{
			data:{
				type:"FeatureListItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateFeatureListItem:{
			by:{
				type:"FeatureListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"FeatureListItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getFeatureList:{
			by:{
				type:"FeatureListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listFeatureList:{
			filter:{
				type:"FeatureListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FeatureListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateFeatureList:{
			filter:{
				type:"FeatureListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FeatureListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateFeatureList:{
			data:{
				type:"FeatureListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateFeatureList:{
			by:{
				type:"FeatureListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"FeatureListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getFaqItem:{
			by:{
				type:"FaqItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listFaqItem:{
			filter:{
				type:"FaqItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FaqItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateFaqItem:{
			filter:{
				type:"FaqItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FaqItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateFaqItem:{
			data:{
				type:"FaqItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateFaqItem:{
			by:{
				type:"FaqItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"FaqItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getFaq:{
			by:{
				type:"FaqUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listFaq:{
			filter:{
				type:"FaqWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FaqOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateFaq:{
			filter:{
				type:"FaqWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"FaqOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateFaq:{
			data:{
				type:"FaqCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateFaq:{
			by:{
				type:"FaqUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"FaqUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getContentBlock:{
			by:{
				type:"ContentBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listContentBlock:{
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentBlockOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateContentBlock:{
			filter:{
				type:"ContentBlockWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentBlockOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateContentBlock:{
			data:{
				type:"ContentBlockCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateContentBlock:{
			by:{
				type:"ContentBlockUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ContentBlockUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getContent:{
			by:{
				type:"ContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listContent:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateContent:{
			filter:{
				type:"ContentWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ContentOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateContent:{
			data:{
				type:"ContentCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateContent:{
			by:{
				type:"ContentUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ContentUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getLink:{
			by:{
				type:"LinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listLink:{
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LinkOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateLink:{
			filter:{
				type:"LinkWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LinkOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateLink:{
			data:{
				type:"LinkCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateLink:{
			by:{
				type:"LinkUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"LinkUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getReviewLocale:{
			by:{
				type:"ReviewLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listReviewLocale:{
			filter:{
				type:"ReviewLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ReviewLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateReviewLocale:{
			filter:{
				type:"ReviewLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ReviewLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateReviewLocale:{
			data:{
				type:"ReviewLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateReviewLocale:{
			by:{
				type:"ReviewLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ReviewLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getReview:{
			by:{
				type:"ReviewUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listReview:{
			filter:{
				type:"ReviewWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ReviewOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateReview:{
			filter:{
				type:"ReviewWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ReviewOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateReview:{
			data:{
				type:"ReviewCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateReview:{
			by:{
				type:"ReviewUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ReviewUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getGalleryItem:{
			by:{
				type:"GalleryItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listGalleryItem:{
			filter:{
				type:"GalleryItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GalleryItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateGalleryItem:{
			filter:{
				type:"GalleryItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GalleryItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateGalleryItem:{
			data:{
				type:"GalleryItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateGalleryItem:{
			by:{
				type:"GalleryItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"GalleryItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getGallery:{
			by:{
				type:"GalleryUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listGallery:{
			filter:{
				type:"GalleryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GalleryOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateGallery:{
			filter:{
				type:"GalleryWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"GalleryOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateGallery:{
			data:{
				type:"GalleryCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateGallery:{
			by:{
				type:"GalleryUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"GalleryUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProductVariantStore:{
			by:{
				type:"ProductVariantStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listProductVariantStore:{
			filter:{
				type:"ProductVariantStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantStoreOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateProductVariantStore:{
			filter:{
				type:"ProductVariantStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantStoreOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateProductVariantStore:{
			data:{
				type:"ProductVariantStoreCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateProductVariantStore:{
			by:{
				type:"ProductVariantStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ProductVariantStoreUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProductStore:{
			by:{
				type:"ProductStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listProductStore:{
			filter:{
				type:"ProductStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductStoreOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateProductStore:{
			filter:{
				type:"ProductStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductStoreOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateProductStore:{
			data:{
				type:"ProductStoreCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateProductStore:{
			by:{
				type:"ProductStoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ProductStoreUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconListItem:{
			by:{
				type:"IconListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconListItem:{
			filter:{
				type:"IconListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconListItem:{
			filter:{
				type:"IconListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconListItem:{
			data:{
				type:"IconListItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconListItem:{
			by:{
				type:"IconListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconListItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconList:{
			by:{
				type:"IconListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconList:{
			filter:{
				type:"IconListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconList:{
			filter:{
				type:"IconListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconList:{
			data:{
				type:"IconListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconList:{
			by:{
				type:"IconListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconGroupListItem:{
			by:{
				type:"IconGroupListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconGroupListItem:{
			filter:{
				type:"IconGroupListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconGroupListItem:{
			filter:{
				type:"IconGroupListItemWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupListItemOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconGroupListItem:{
			data:{
				type:"IconGroupListItemCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconGroupListItem:{
			by:{
				type:"IconGroupListItemUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconGroupListItemUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconGroupList:{
			by:{
				type:"IconGroupListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconGroupList:{
			filter:{
				type:"IconGroupListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconGroupList:{
			filter:{
				type:"IconGroupListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconGroupList:{
			data:{
				type:"IconGroupListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconGroupList:{
			by:{
				type:"IconGroupListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconGroupListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProductVariantLocale:{
			by:{
				type:"ProductVariantLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listProductVariantLocale:{
			filter:{
				type:"ProductVariantLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateProductVariantLocale:{
			filter:{
				type:"ProductVariantLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateProductVariantLocale:{
			data:{
				type:"ProductVariantLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateProductVariantLocale:{
			by:{
				type:"ProductVariantLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ProductVariantLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProductVariant:{
			by:{
				type:"ProductVariantUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listProductVariant:{
			filter:{
				type:"ProductVariantWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateProductVariant:{
			filter:{
				type:"ProductVariantWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateProductVariant:{
			data:{
				type:"ProductVariantCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateProductVariant:{
			by:{
				type:"ProductVariantUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ProductVariantUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getProduct:{
			by:{
				type:"ProductUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listProduct:{
			filter:{
				type:"ProductWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateProduct:{
			filter:{
				type:"ProductWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateProduct:{
			data:{
				type:"ProductCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateProduct:{
			by:{
				type:"ProductUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ProductUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getImageLocale:{
			by:{
				type:"ImageLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listImageLocale:{
			filter:{
				type:"ImageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateImageLocale:{
			filter:{
				type:"ImageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateImageLocale:{
			data:{
				type:"ImageLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateImageLocale:{
			by:{
				type:"ImageLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ImageLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getStore:{
			by:{
				type:"StoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listStore:{
			filter:{
				type:"StoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"StoreOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateStore:{
			filter:{
				type:"StoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"StoreOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateStore:{
			data:{
				type:"StoreCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateStore:{
			by:{
				type:"StoreUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"StoreUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getAdminNote:{
			by:{
				type:"AdminNoteUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listAdminNote:{
			filter:{
				type:"AdminNoteWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AdminNoteOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateAdminNote:{
			filter:{
				type:"AdminNoteWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"AdminNoteOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateAdminNote:{
			data:{
				type:"AdminNoteCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateAdminNote:{
			by:{
				type:"AdminNoteUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"AdminNoteUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getPageLocale:{
			by:{
				type:"PageLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listPageLocale:{
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PageLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePageLocale:{
			filter:{
				type:"PageLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PageLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreatePageLocale:{
			data:{
				type:"PageLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdatePageLocale:{
			by:{
				type:"PageLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"PageLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getPage:{
			by:{
				type:"PageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listPage:{
			filter:{
				type:"PageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginatePage:{
			filter:{
				type:"PageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"PageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreatePage:{
			data:{
				type:"PageCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdatePage:{
			by:{
				type:"PageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"PageUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getImageXImageList:{
			by:{
				type:"ImageXImageListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listImageXImageList:{
			filter:{
				type:"ImageXImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageXImageListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateImageXImageList:{
			filter:{
				type:"ImageXImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageXImageListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateImageXImageList:{
			data:{
				type:"ImageXImageListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateImageXImageList:{
			by:{
				type:"ImageXImageListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ImageXImageListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconGroupLocale:{
			by:{
				type:"IconGroupLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconGroupLocale:{
			filter:{
				type:"IconGroupLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconGroupLocale:{
			filter:{
				type:"IconGroupLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconGroupLocale:{
			data:{
				type:"IconGroupLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconGroupLocale:{
			by:{
				type:"IconGroupLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconGroupLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconLocale:{
			by:{
				type:"IconLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconLocale:{
			filter:{
				type:"IconLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconLocale:{
			filter:{
				type:"IconLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconLocale:{
			data:{
				type:"IconLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconLocale:{
			by:{
				type:"IconLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIconGroup:{
			by:{
				type:"IconGroupUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIconGroup:{
			filter:{
				type:"IconGroupWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIconGroup:{
			filter:{
				type:"IconGroupWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconGroupOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIconGroup:{
			data:{
				type:"IconGroupCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIconGroup:{
			by:{
				type:"IconGroupUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconGroupUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getIcon:{
			by:{
				type:"IconUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listIcon:{
			filter:{
				type:"IconWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateIcon:{
			filter:{
				type:"IconWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"IconOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateIcon:{
			data:{
				type:"IconCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateIcon:{
			by:{
				type:"IconUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"IconUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getLocale:{
			by:{
				type:"LocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listLocale:{
			filter:{
				type:"LocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateLocale:{
			filter:{
				type:"LocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateLocale:{
			data:{
				type:"LocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateLocale:{
			by:{
				type:"LocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"LocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getSeoLocale:{
			by:{
				type:"SeoLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listSeoLocale:{
			filter:{
				type:"SeoLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateSeoLocale:{
			filter:{
				type:"SeoLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateSeoLocale:{
			data:{
				type:"SeoLocaleCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateSeoLocale:{
			by:{
				type:"SeoLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"SeoLocaleUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getSeo:{
			by:{
				type:"SeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listSeo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateSeo:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateSeo:{
			data:{
				type:"SeoCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateSeo:{
			by:{
				type:"SeoUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"SeoUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getLinkable:{
			by:{
				type:"LinkableUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listLinkable:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LinkableOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateLinkable:{
			filter:{
				type:"LinkableWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"LinkableOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateLinkable:{
			data:{
				type:"LinkableCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateLinkable:{
			by:{
				type:"LinkableUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"LinkableUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getImageList:{
			by:{
				type:"ImageListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listImageList:{
			filter:{
				type:"ImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateImageList:{
			filter:{
				type:"ImageListWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageListOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateImageList:{
			data:{
				type:"ImageListCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateImageList:{
			by:{
				type:"ImageListUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ImageListUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		getImage:{
			by:{
				type:"ImageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		listImage:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		paginateImage:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ImageOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			skip:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			first:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		validateCreateImage:{
			data:{
				type:"ImageCreateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		},
		validateUpdateImage:{
			by:{
				type:"ImageUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			data:{
				type:"ImageUpdateInput",
				array:false,
				arrayRequired:false,
				required:true
			}
		}
	},
	Review:{
		locales:{
			filter:{
				type:"ReviewLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ReviewLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		localesByLocale:{
			by:{
				type:"ReviewLocalesByLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ReviewLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ReviewCreateInput:{
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"ReviewCreateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewCreateLocalesEntityRelationInput:{
		connect:{
			type:"ReviewLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ReviewLocaleWithoutReviewCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocale:{
		image:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		locale:{
			filter:{
				type:"LocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		review:{
			filter:{
				type:"ReviewWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	ReviewLocaleCreateImageEntityRelationInput:{
		connect:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocaleCreateInput:{
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ReviewLocaleCreateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"ReviewLocaleCreateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		review:{
			type:"ReviewLocaleCreateReviewEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocaleCreateLocaleEntityRelationInput:{
		connect:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocaleCreateReviewEntityRelationInput:{
		connect:{
			type:"ReviewUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ReviewWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocaleOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		review:{
			type:"ReviewOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocalesByLocaleUniqueWhere:{
		locale:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocaleUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		review:{
			type:"ReviewUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocaleUpdateImageEntityRelationInput:{
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ImageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ReviewLocaleUpsertImageRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocaleUpdateInput:{
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ReviewLocaleUpdateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"ReviewLocaleUpdateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		review:{
			type:"ReviewLocaleUpdateReviewEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocaleUpdateLocaleEntityRelationInput:{
		create:{
			type:"LocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"LocaleUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ReviewLocaleUpsertLocaleRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocaleUpdateReviewEntityRelationInput:{
		create:{
			type:"ReviewWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ReviewWithoutLocalesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ReviewLocaleUpsertReviewRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ReviewUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocaleUpsertImageRelationInput:{
		update:{
			type:"ImageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocaleUpsertLocaleRelationInput:{
		update:{
			type:"LocaleUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LocaleCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocaleUpsertReviewRelationInput:{
		update:{
			type:"ReviewWithoutLocalesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ReviewWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocaleWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		content:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		review:{
			type:"ReviewWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ReviewLocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ReviewLocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ReviewLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocaleWithoutReviewCreateInput:{
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ReviewLocaleCreateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"ReviewLocaleCreateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewLocaleWithoutReviewUpdateInput:{
		content:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		image:{
			type:"ReviewLocaleUpdateImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"ReviewLocaleUpdateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"ReviewLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewUpdateInput:{
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"ReviewUpdateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewUpdateLocalesEntityRelationInput:{
		create:{
			type:"ReviewLocaleWithoutReviewCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ReviewUpdateLocalesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"ReviewUpsertLocalesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ReviewLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"ReviewLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"ReviewLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewUpdateLocalesRelationInput:{
		by:{
			type:"ReviewLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"ReviewLocaleWithoutReviewUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewUpsertLocalesRelationInput:{
		by:{
			type:"ReviewLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ReviewLocaleWithoutReviewUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ReviewLocaleWithoutReviewCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewView: "enum",
	ReviewViewEnumCondition:{
		and:{
			type:"ReviewViewEnumCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ReviewViewEnumCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ReviewViewEnumCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"ReviewView",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"ReviewView",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"ReviewView",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"ReviewView",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"ReviewView",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"ReviewView",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"ReviewView",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"ReviewView",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		username:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTimeCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"ReviewLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ReviewWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ReviewWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ReviewWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewWithoutLocalesCreateInput:{
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ReviewWithoutLocalesUpdateInput:{
		username:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Seo:{
		ogImage:{
			filter:{
				type:"ImageWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		locales:{
			filter:{
				type:"SeoLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"SeoLocaleOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		localesByLocale:{
			by:{
				type:"SeoLocalesByLocaleUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"SeoLocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	SeoCreateInput:{
		ogImage:{
			type:"SeoCreateOgImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"SeoCreateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		noindex:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoCreateLocalesEntityRelationInput:{
		connect:{
			type:"SeoLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"SeoLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoCreateOgImageEntityRelationInput:{
		connect:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoLocale:{
		root:{
			filter:{
				type:"SeoWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		locale:{
			filter:{
				type:"LocaleWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	SeoLocaleCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogTitle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogDescription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"SeoLocaleCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"SeoLocaleCreateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoLocaleCreateLocaleEntityRelationInput:{
		connect:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LocaleWithoutSeosCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoLocaleCreateRootEntityRelationInput:{
		connect:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"SeoWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoLocaleOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogTitle:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogDescription:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"SeoOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoLocalesByLocaleUniqueWhere:{
		locale:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoLocaleUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoLocaleUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogTitle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogDescription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"SeoLocaleUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"SeoLocaleUpdateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoLocaleUpdateLocaleEntityRelationInput:{
		create:{
			type:"LocaleWithoutSeosCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"LocaleWithoutSeosUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"SeoLocaleUpsertLocaleRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"LocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoLocaleUpdateRootEntityRelationInput:{
		create:{
			type:"SeoWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"SeoWithoutLocalesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"SeoLocaleUpsertRootRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"SeoUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoLocaleUpsertLocaleRelationInput:{
		update:{
			type:"LocaleWithoutSeosUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"LocaleWithoutSeosCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoLocaleUpsertRootRelationInput:{
		update:{
			type:"SeoWithoutLocalesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"SeoWithoutLocalesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoLocaleWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		title:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogTitle:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogDescription:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"LocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"SeoLocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"SeoLocaleWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"SeoLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoLocaleWithoutLocaleCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogTitle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogDescription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"SeoLocaleCreateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoLocaleWithoutLocaleUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogTitle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogDescription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		root:{
			type:"SeoLocaleUpdateRootEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoLocaleWithoutRootCreateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogTitle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogDescription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"SeoLocaleCreateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoLocaleWithoutRootUpdateInput:{
		title:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		description:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogTitle:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogDescription:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		locale:{
			type:"SeoLocaleUpdateLocaleEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogImage:{
			type:"ImageOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		noindex:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogImage:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"SeoLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoUpdateInput:{
		ogImage:{
			type:"SeoUpdateOgImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"SeoUpdateLocalesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		noindex:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoUpdateLocalesEntityRelationInput:{
		create:{
			type:"SeoLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"SeoUpdateLocalesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"SeoUpsertLocalesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"SeoLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"SeoLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"SeoLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoUpdateLocalesRelationInput:{
		by:{
			type:"SeoLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"SeoLocaleWithoutRootUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoUpdateOgImageEntityRelationInput:{
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ImageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"SeoUpsertOgImageRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ImageUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoUpsertLocalesRelationInput:{
		by:{
			type:"SeoLocaleUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"SeoLocaleWithoutRootUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"SeoLocaleWithoutRootCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoUpsertOgImageRelationInput:{
		update:{
			type:"ImageUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ImageCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		ogImage:{
			type:"ImageWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		locales:{
			type:"SeoLocaleWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		noindex:{
			type:"BooleanCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"SeoWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"SeoWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"SeoWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoWithoutLocalesCreateInput:{
		ogImage:{
			type:"SeoCreateOgImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		noindex:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SeoWithoutLocalesUpdateInput:{
		ogImage:{
			type:"SeoUpdateOgImageEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		noindex:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ShopifyLineItemCreateInput:{
		store:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyOrderId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyLineItemId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customAttributes:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ShopifyLineItemOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyOrderId:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyLineItemId:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		customAttributes:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ShopifyLineItemUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyOrderId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyLineItemId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ShopifyLineItemUpdateInput:{
		store:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyOrderId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyLineItemId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		customAttributes:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ShopifyLineItemWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyOrderId:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyLineItemId:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		customAttributes:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ShopifyLineItemWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ShopifyLineItemWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ShopifyLineItemWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ShopifyOrderCreateInput:{
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		fulfilledAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shippingType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		trackingId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rawData:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		trackingUrl:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shippingMeta:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		enableResendAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ShopifyOrderOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		fulfilledAt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		shippingType:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		trackingId:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		rawData:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		trackingUrl:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		shippingMeta:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		enableResendAt:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ShopifyOrderUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ShopifyOrderUpdateInput:{
		createdAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		fulfilledAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shippingType:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		trackingId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		rawData:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		trackingUrl:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shippingMeta:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		enableResendAt:{
			type:"DateTime",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	ShopifyOrderWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		createdAt:{
			type:"DateTimeCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		fulfilledAt:{
			type:"DateTimeCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		store:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyId:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		shippingType:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		trackingId:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		rawData:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		trackingUrl:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		shippingMeta:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		enableResendAt:{
			type:"DateTimeCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"ShopifyOrderWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"ShopifyOrderWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"ShopifyOrderWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	SideBySideView: "enum",
	SideBySideViewEnumCondition:{
		and:{
			type:"SideBySideViewEnumCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"SideBySideViewEnumCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"SideBySideViewEnumCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"SideBySideView",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"SideBySideView",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"SideBySideView",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"SideBySideView",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"SideBySideView",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"SideBySideView",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"SideBySideView",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"SideBySideView",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	Store:{
		products:{
			filter:{
				type:"ProductStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductStoreOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		productVariants:{
			filter:{
				type:"ProductVariantStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"ProductVariantStoreOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		productsByRoot:{
			by:{
				type:"StoreProductsByRootUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		productsByShopifyId:{
			by:{
				type:"StoreProductsByShopifyIdUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		productVariantsByRoot:{
			by:{
				type:"StoreProductVariantsByRootUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		productVariantsByShopifyId:{
			by:{
				type:"StoreProductVariantsByShopifyIdUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		productVariantsByStorefrontId:{
			by:{
				type:"StoreProductVariantsByStorefrontIdUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"ProductVariantStoreWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	StoreCreateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyDomain:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		products:{
			type:"StoreCreateProductsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"StoreCreateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreCreateProductsEntityRelationInput:{
		connect:{
			type:"ProductStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductStoreWithoutStoreCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreCreateProductVariantsEntityRelationInput:{
		connect:{
			type:"ProductVariantStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductVariantStoreWithoutStoreCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		code:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyDomain:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreProductsByRootUniqueWhere:{
		root:{
			type:"ProductUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreProductsByShopifyIdUniqueWhere:{
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreProductVariantsByRootUniqueWhere:{
		root:{
			type:"ProductVariantUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreProductVariantsByShopifyIdUniqueWhere:{
		shopifyId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreProductVariantsByStorefrontIdUniqueWhere:{
		storefrontId:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		products:{
			type:"ProductStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		productVariants:{
			type:"ProductVariantStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreUpdateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyDomain:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		products:{
			type:"StoreUpdateProductsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		productVariants:{
			type:"StoreUpdateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreUpdateProductsEntityRelationInput:{
		create:{
			type:"ProductStoreWithoutStoreCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"StoreUpdateProductsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"StoreUpsertProductsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ProductStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"ProductStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"ProductStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreUpdateProductsRelationInput:{
		by:{
			type:"ProductStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"ProductStoreWithoutStoreUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreUpdateProductVariantsEntityRelationInput:{
		create:{
			type:"ProductVariantStoreWithoutStoreCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"StoreUpdateProductVariantsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"StoreUpsertProductVariantsRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"ProductVariantStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"ProductVariantStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"ProductVariantStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreUpdateProductVariantsRelationInput:{
		by:{
			type:"ProductVariantStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"ProductVariantStoreWithoutStoreUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreUpsertProductsRelationInput:{
		by:{
			type:"ProductStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductStoreWithoutStoreUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductStoreWithoutStoreCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreUpsertProductVariantsRelationInput:{
		by:{
			type:"ProductVariantStoreUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"ProductVariantStoreWithoutStoreUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"ProductVariantStoreWithoutStoreCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		code:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyDomain:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		products:{
			type:"ProductStoreWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		productVariants:{
			type:"ProductVariantStoreWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"StoreWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"StoreWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"StoreWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreWithoutProductsCreateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyDomain:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		productVariants:{
			type:"StoreCreateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreWithoutProductsUpdateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyDomain:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		productVariants:{
			type:"StoreUpdateProductVariantsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreWithoutProductVariantsCreateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyDomain:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		products:{
			type:"StoreCreateProductsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StoreWithoutProductVariantsUpdateInput:{
		code:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		shopifyDomain:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		products:{
			type:"StoreUpdateProductsEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	StringCondition:{
		and:{
			type:"StringCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"StringCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"String",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		contains:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		startsWith:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsWith:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		containsCI:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		startsWithCI:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		endsWithCI:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogue:{
		domain:{
			filter:{
				type:"TranslationDomainWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		fallback:{
			filter:{
				type:"TranslationCatalogueWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		values:{
			filter:{
				type:"TranslationValueWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationValueOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		valuesByKey:{
			by:{
				type:"TranslationCatalogueValuesByKeyUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationValueWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	TranslationCatalogueCreateDomainEntityRelationInput:{
		connect:{
			type:"TranslationDomainUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationDomainWithoutCataloguesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueCreateFallbackEntityRelationInput:{
		connect:{
			type:"TranslationCatalogueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationCatalogueCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueCreateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		domain:{
			type:"TranslationCatalogueCreateDomainEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		fallback:{
			type:"TranslationCatalogueCreateFallbackEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		values:{
			type:"TranslationCatalogueCreateValuesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueCreateValuesEntityRelationInput:{
		connect:{
			type:"TranslationValueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationValueWithoutCatalogueCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		identifier:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		domain:{
			type:"TranslationDomainOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		fallback:{
			type:"TranslationCatalogueOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		domain:{
			type:"TranslationDomainUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		values:{
			type:"TranslationValueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueUpdateDomainEntityRelationInput:{
		create:{
			type:"TranslationDomainWithoutCataloguesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"TranslationDomainWithoutCataloguesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"TranslationCatalogueUpsertDomainRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"TranslationDomainUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueUpdateFallbackEntityRelationInput:{
		create:{
			type:"TranslationCatalogueCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"TranslationCatalogueUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"TranslationCatalogueUpsertFallbackRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"TranslationCatalogueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueUpdateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		domain:{
			type:"TranslationCatalogueUpdateDomainEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		fallback:{
			type:"TranslationCatalogueUpdateFallbackEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		values:{
			type:"TranslationCatalogueUpdateValuesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueUpdateValuesEntityRelationInput:{
		create:{
			type:"TranslationValueWithoutCatalogueCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"TranslationCatalogueUpdateValuesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"TranslationCatalogueUpsertValuesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"TranslationValueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"TranslationValueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"TranslationValueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueUpdateValuesRelationInput:{
		by:{
			type:"TranslationValueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"TranslationValueWithoutCatalogueUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueUpsertDomainRelationInput:{
		update:{
			type:"TranslationDomainWithoutCataloguesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationDomainWithoutCataloguesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueUpsertFallbackRelationInput:{
		update:{
			type:"TranslationCatalogueUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationCatalogueCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueUpsertValuesRelationInput:{
		by:{
			type:"TranslationValueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"TranslationValueWithoutCatalogueUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationValueWithoutCatalogueCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueValuesByKeyUniqueWhere:{
		key:{
			type:"TranslationKeyUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		identifier:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		domain:{
			type:"TranslationDomainWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		fallback:{
			type:"TranslationCatalogueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		values:{
			type:"TranslationValueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"TranslationCatalogueWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"TranslationCatalogueWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"TranslationCatalogueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueWithoutDomainCreateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fallback:{
			type:"TranslationCatalogueCreateFallbackEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		values:{
			type:"TranslationCatalogueCreateValuesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueWithoutDomainUpdateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		fallback:{
			type:"TranslationCatalogueUpdateFallbackEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		values:{
			type:"TranslationCatalogueUpdateValuesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueWithoutValuesCreateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		domain:{
			type:"TranslationCatalogueCreateDomainEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		fallback:{
			type:"TranslationCatalogueCreateFallbackEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationCatalogueWithoutValuesUpdateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		domain:{
			type:"TranslationCatalogueUpdateDomainEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		fallback:{
			type:"TranslationCatalogueUpdateFallbackEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationContentType: "enum",
	TranslationContentTypeEnumCondition:{
		and:{
			type:"TranslationContentTypeEnumCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"TranslationContentTypeEnumCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"TranslationContentTypeEnumCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"TranslationContentType",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"TranslationContentType",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"TranslationContentType",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"TranslationContentType",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"TranslationContentType",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"TranslationContentType",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"TranslationContentType",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"TranslationContentType",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomain:{
		catalogues:{
			filter:{
				type:"TranslationCatalogueWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationCatalogueOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		keys:{
			filter:{
				type:"TranslationKeyWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationKeyOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		cataloguesByIdentifier:{
			by:{
				type:"TranslationDomainCataloguesByIdentifierUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationCatalogueWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		cataloguesByValues:{
			by:{
				type:"TranslationDomainCataloguesByValuesUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationCatalogueWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		keysByIdentifier:{
			by:{
				type:"TranslationDomainKeysByIdentifierUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationKeyWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		keysByValues:{
			by:{
				type:"TranslationDomainKeysByValuesUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationKeyWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	TranslationDomainCataloguesByIdentifierUniqueWhere:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainCataloguesByValuesUniqueWhere:{
		values:{
			type:"TranslationValueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainCreateCataloguesEntityRelationInput:{
		connect:{
			type:"TranslationCatalogueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationCatalogueWithoutDomainCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainCreateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		catalogues:{
			type:"TranslationDomainCreateCataloguesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		keys:{
			type:"TranslationDomainCreateKeysEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainCreateKeysEntityRelationInput:{
		connect:{
			type:"TranslationKeyUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationKeyWithoutDomainCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainKeysByIdentifierUniqueWhere:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainKeysByValuesUniqueWhere:{
		values:{
			type:"TranslationValueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		identifier:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		catalogues:{
			type:"TranslationCatalogueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		keys:{
			type:"TranslationKeyUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainUpdateCataloguesEntityRelationInput:{
		create:{
			type:"TranslationCatalogueWithoutDomainCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"TranslationDomainUpdateCataloguesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"TranslationDomainUpsertCataloguesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"TranslationCatalogueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"TranslationCatalogueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"TranslationCatalogueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainUpdateCataloguesRelationInput:{
		by:{
			type:"TranslationCatalogueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"TranslationCatalogueWithoutDomainUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainUpdateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		catalogues:{
			type:"TranslationDomainUpdateCataloguesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		keys:{
			type:"TranslationDomainUpdateKeysEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainUpdateKeysEntityRelationInput:{
		create:{
			type:"TranslationKeyWithoutDomainCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"TranslationDomainUpdateKeysRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"TranslationDomainUpsertKeysRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"TranslationKeyUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"TranslationKeyUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"TranslationKeyUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainUpdateKeysRelationInput:{
		by:{
			type:"TranslationKeyUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"TranslationKeyWithoutDomainUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainUpsertCataloguesRelationInput:{
		by:{
			type:"TranslationCatalogueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"TranslationCatalogueWithoutDomainUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationCatalogueWithoutDomainCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainUpsertKeysRelationInput:{
		by:{
			type:"TranslationKeyUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"TranslationKeyWithoutDomainUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationKeyWithoutDomainCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		identifier:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		catalogues:{
			type:"TranslationCatalogueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		keys:{
			type:"TranslationKeyWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"TranslationDomainWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"TranslationDomainWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"TranslationDomainWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainWithoutCataloguesCreateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		keys:{
			type:"TranslationDomainCreateKeysEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainWithoutCataloguesUpdateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		keys:{
			type:"TranslationDomainUpdateKeysEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainWithoutKeysCreateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		catalogues:{
			type:"TranslationDomainCreateCataloguesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationDomainWithoutKeysUpdateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		name:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		catalogues:{
			type:"TranslationDomainUpdateCataloguesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKey:{
		domain:{
			filter:{
				type:"TranslationDomainWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		values:{
			filter:{
				type:"TranslationValueWhere",
				array:false,
				arrayRequired:false,
				required:false
			},
			orderBy:{
				type:"TranslationValueOrderBy",
				array:true,
				arrayRequired:false,
				required:true
			},
			offset:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			},
			limit:{
				type:"Int",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		valuesByCatalogue:{
			by:{
				type:"TranslationKeyValuesByCatalogueUniqueWhere",
				array:false,
				arrayRequired:false,
				required:true
			},
			filter:{
				type:"TranslationValueWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	TranslationKeyCreateDomainEntityRelationInput:{
		connect:{
			type:"TranslationDomainUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationDomainWithoutKeysCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKeyCreateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		contentType:{
			type:"TranslationContentType",
			array:false,
			arrayRequired:false,
			required:false
		},
		note:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		domain:{
			type:"TranslationKeyCreateDomainEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		values:{
			type:"TranslationKeyCreateValuesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKeyCreateValuesEntityRelationInput:{
		connect:{
			type:"TranslationValueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationValueWithoutKeyCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKeyOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		identifier:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		contentType:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		note:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		domain:{
			type:"TranslationDomainOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKeyUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		domain:{
			type:"TranslationDomainUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		values:{
			type:"TranslationValueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKeyUpdateDomainEntityRelationInput:{
		create:{
			type:"TranslationDomainWithoutKeysCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"TranslationDomainWithoutKeysUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"TranslationKeyUpsertDomainRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"TranslationDomainUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKeyUpdateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		contentType:{
			type:"TranslationContentType",
			array:false,
			arrayRequired:false,
			required:false
		},
		note:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		domain:{
			type:"TranslationKeyUpdateDomainEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		values:{
			type:"TranslationKeyUpdateValuesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKeyUpdateValuesEntityRelationInput:{
		create:{
			type:"TranslationValueWithoutKeyCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"TranslationKeyUpdateValuesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"TranslationKeyUpsertValuesRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"TranslationValueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		disconnect:{
			type:"TranslationValueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		delete:{
			type:"TranslationValueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		alias:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKeyUpdateValuesRelationInput:{
		by:{
			type:"TranslationValueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		data:{
			type:"TranslationValueWithoutKeyUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKeyUpsertDomainRelationInput:{
		update:{
			type:"TranslationDomainWithoutKeysUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationDomainWithoutKeysCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKeyUpsertValuesRelationInput:{
		by:{
			type:"TranslationValueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"TranslationValueWithoutKeyUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationValueWithoutKeyCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKeyValuesByCatalogueUniqueWhere:{
		catalogue:{
			type:"TranslationCatalogueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKeyWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		identifier:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		contentType:{
			type:"TranslationContentTypeEnumCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		note:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		domain:{
			type:"TranslationDomainWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		values:{
			type:"TranslationValueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"TranslationKeyWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"TranslationKeyWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"TranslationKeyWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKeyWithoutDomainCreateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		contentType:{
			type:"TranslationContentType",
			array:false,
			arrayRequired:false,
			required:false
		},
		note:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		values:{
			type:"TranslationKeyCreateValuesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKeyWithoutDomainUpdateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		contentType:{
			type:"TranslationContentType",
			array:false,
			arrayRequired:false,
			required:false
		},
		note:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		values:{
			type:"TranslationKeyUpdateValuesEntityRelationInput",
			array:true,
			arrayRequired:false,
			required:true
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKeyWithoutValuesCreateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		contentType:{
			type:"TranslationContentType",
			array:false,
			arrayRequired:false,
			required:false
		},
		note:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		domain:{
			type:"TranslationKeyCreateDomainEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationKeyWithoutValuesUpdateInput:{
		identifier:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		contentType:{
			type:"TranslationContentType",
			array:false,
			arrayRequired:false,
			required:false
		},
		note:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		domain:{
			type:"TranslationKeyUpdateDomainEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationValue:{
		catalogue:{
			filter:{
				type:"TranslationCatalogueWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		},
		key:{
			filter:{
				type:"TranslationKeyWhere",
				array:false,
				arrayRequired:false,
				required:false
			}
		}
	},
	TranslationValueCreateCatalogueEntityRelationInput:{
		connect:{
			type:"TranslationCatalogueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationCatalogueWithoutValuesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationValueCreateInput:{
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		catalogue:{
			type:"TranslationValueCreateCatalogueEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"TranslationValueCreateKeyEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationValueCreateKeyEntityRelationInput:{
		connect:{
			type:"TranslationKeyUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationKeyWithoutValuesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationValueOrderBy:{
		_random:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		_randomSeeded:{
			type:"Int",
			array:false,
			arrayRequired:false,
			required:false
		},
		id:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"OrderDirection",
			array:false,
			arrayRequired:false,
			required:false
		},
		catalogue:{
			type:"TranslationCatalogueOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"TranslationKeyOrderBy",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationValueUniqueWhere:{
		id:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		catalogue:{
			type:"TranslationCatalogueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"TranslationKeyUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationValueUpdateCatalogueEntityRelationInput:{
		create:{
			type:"TranslationCatalogueWithoutValuesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"TranslationCatalogueWithoutValuesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"TranslationValueUpsertCatalogueRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"TranslationCatalogueUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationValueUpdateInput:{
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		catalogue:{
			type:"TranslationValueUpdateCatalogueEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"TranslationValueUpdateKeyEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationValueUpdateKeyEntityRelationInput:{
		create:{
			type:"TranslationKeyWithoutValuesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		update:{
			type:"TranslationKeyWithoutValuesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		upsert:{
			type:"TranslationValueUpsertKeyRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		connect:{
			type:"TranslationKeyUniqueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationValueUpsertCatalogueRelationInput:{
		update:{
			type:"TranslationCatalogueWithoutValuesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationCatalogueWithoutValuesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationValueUpsertKeyRelationInput:{
		update:{
			type:"TranslationKeyWithoutValuesUpdateInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		create:{
			type:"TranslationKeyWithoutValuesCreateInput",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationValueWhere:{
		id:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		value:{
			type:"StringCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		catalogue:{
			type:"TranslationCatalogueWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"TranslationKeyWhere",
			array:false,
			arrayRequired:false,
			required:false
		},
		and:{
			type:"TranslationValueWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"TranslationValueWhere",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"TranslationValueWhere",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationValueWithoutCatalogueCreateInput:{
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"TranslationValueCreateKeyEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationValueWithoutCatalogueUpdateInput:{
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		key:{
			type:"TranslationValueUpdateKeyEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationValueWithoutKeyCreateInput:{
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		catalogue:{
			type:"TranslationValueCreateCatalogueEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	TranslationValueWithoutKeyUpdateInput:{
		value:{
			type:"String",
			array:false,
			arrayRequired:false,
			required:false
		},
		catalogue:{
			type:"TranslationValueUpdateCatalogueEntityRelationInput",
			array:false,
			arrayRequired:false,
			required:false
		},
		_dummy_field_:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		}
	},
	UUID: "String",
	UUIDCondition:{
		and:{
			type:"UUIDCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		or:{
			type:"UUIDCondition",
			array:true,
			arrayRequired:false,
			required:true
		},
		not:{
			type:"UUIDCondition",
			array:false,
			arrayRequired:false,
			required:false
		},
		eq:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		null:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		isNull:{
			type:"Boolean",
			array:false,
			arrayRequired:false,
			required:false
		},
		notEq:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		in:{
			type:"UUID",
			array:true,
			arrayRequired:false,
			required:true
		},
		notIn:{
			type:"UUID",
			array:true,
			arrayRequired:false,
			required:true
		},
		lt:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		lte:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		gt:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		},
		gte:{
			type:"UUID",
			array:false,
			arrayRequired:false,
			required:false
		}
	}
}

export const ReturnTypes: Record<string,any> = {
	_AnyValue:{
		"...on _IntValue":"_IntValue",
		"...on _StringValue":"_StringValue",
		"...on _BooleanValue":"_BooleanValue",
		"...on _FloatValue":"_FloatValue",
		"...on _UndefinedValue":"_UndefinedValue"
	},
	_Argument:{
		"...on _ValidatorArgument":"_ValidatorArgument",
		"...on _PathArgument":"_PathArgument",
		"...on _LiteralArgument":"_LiteralArgument"
	},
	_BooleanValue:{
		booleanValue:"Boolean"
	},
	_Entity:{
		name:"String",
		fields:"_Field"
	},
	_Enum:{
		name:"String",
		values:"String"
	},
	_Field:{
		name:"String",
		type:"String",
		rules:"_Rule",
		validators:"_Validator"
	},
	_FieldPathFragment:{
		field:"String"
	},
	_FloatValue:{
		floatValue:"Float"
	},
	_IndexPathFragment:{
		index:"Int",
		alias:"String"
	},
	_IntValue:{
		intValue:"Int"
	},
	_LiteralArgument:{
		value:"_AnyValue"
	},
	_MutationError:{
		path:"_PathFragment",
		type:"_MutationErrorType",
		message:"String"
	},
	_PathArgument:{
		path:"String"
	},
	_PathFragment:{
		"...on _FieldPathFragment":"_FieldPathFragment",
		"...on _IndexPathFragment":"_IndexPathFragment"
	},
	_Rule:{
		message:"_RuleMessage",
		validator:"Int"
	},
	_RuleMessage:{
		text:"String"
	},
	_Schema:{
		enums:"_Enum",
		entities:"_Entity"
	},
	_StringValue:{
		stringValue:"String"
	},
	_UndefinedValue:{
		undefinedValue:"Boolean"
	},
	_ValidationError:{
		path:"_PathFragment",
		message:"_ValidationMessage"
	},
	_ValidationMessage:{
		text:"String"
	},
	_ValidationResult:{
		valid:"Boolean",
		errors:"_ValidationError"
	},
	_Validator:{
		operation:"String",
		arguments:"_Argument"
	},
	_ValidatorArgument:{
		validator:"Int"
	},
	AbandonedCheckoutNotification:{
		_meta:"AbandonedCheckoutNotificationMeta",
		id:"UUID",
		createdAt:"DateTime",
		sentAt:"DateTime",
		tryCount:"Int",
		store:"String",
		shopifyId:"String",
		orderJson:"String",
		metadata:"String",
		status:"String"
	},
	AbandonedCheckoutNotificationConnection:{
		pageInfo:"PageInfo",
		edges:"AbandonedCheckoutNotificationEdge"
	},
	AbandonedCheckoutNotificationCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"AbandonedCheckoutNotification",
		validation:"_ValidationResult"
	},
	AbandonedCheckoutNotificationDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"AbandonedCheckoutNotification"
	},
	AbandonedCheckoutNotificationEdge:{
		node:"AbandonedCheckoutNotification"
	},
	AbandonedCheckoutNotificationMeta:{
		id:"FieldMeta",
		createdAt:"FieldMeta",
		sentAt:"FieldMeta",
		tryCount:"FieldMeta",
		store:"FieldMeta",
		shopifyId:"FieldMeta",
		orderJson:"FieldMeta",
		metadata:"FieldMeta",
		status:"FieldMeta"
	},
	AbandonedCheckoutNotificationUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"AbandonedCheckoutNotification",
		validation:"_ValidationResult"
	},
	AbandonedCheckoutUnsubscription:{
		_meta:"AbandonedCheckoutUnsubscriptionMeta",
		id:"UUID",
		createdAt:"DateTime",
		canceledAt:"DateTime",
		email:"String"
	},
	AbandonedCheckoutUnsubscriptionConnection:{
		pageInfo:"PageInfo",
		edges:"AbandonedCheckoutUnsubscriptionEdge"
	},
	AbandonedCheckoutUnsubscriptionCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"AbandonedCheckoutUnsubscription",
		validation:"_ValidationResult"
	},
	AbandonedCheckoutUnsubscriptionDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"AbandonedCheckoutUnsubscription"
	},
	AbandonedCheckoutUnsubscriptionEdge:{
		node:"AbandonedCheckoutUnsubscription"
	},
	AbandonedCheckoutUnsubscriptionMeta:{
		id:"FieldMeta",
		createdAt:"FieldMeta",
		canceledAt:"FieldMeta",
		email:"FieldMeta"
	},
	AbandonedCheckoutUnsubscriptionUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"AbandonedCheckoutUnsubscription",
		validation:"_ValidationResult"
	},
	AdminNote:{
		_meta:"AdminNoteMeta",
		id:"UUID",
		createdAt:"DateTime",
		name:"String",
		data:"String"
	},
	AdminNoteConnection:{
		pageInfo:"PageInfo",
		edges:"AdminNoteEdge"
	},
	AdminNoteCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"AdminNote",
		validation:"_ValidationResult"
	},
	AdminNoteDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"AdminNote"
	},
	AdminNoteEdge:{
		node:"AdminNote"
	},
	AdminNoteMeta:{
		id:"FieldMeta",
		createdAt:"FieldMeta",
		name:"FieldMeta",
		data:"FieldMeta"
	},
	AdminNoteUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"AdminNote",
		validation:"_ValidationResult"
	},
	Content:{
		_meta:"ContentMeta",
		id:"UUID",
		blocks:"ContentBlock",
		page:"PageLocale"
	},
	ContentBlock:{
		_meta:"ContentBlockMeta",
		id:"UUID",
		order:"Int",
		type:"ContentBlockType",
		primaryText:"String",
		secondaryText:"String",
		jsonContent:"String",
		headerView:"HeaderView",
		reviewView:"ReviewView",
		content:"Content",
		link:"Link",
		image:"Image",
		media:"Media",
		product:"Product",
		featureList:"FeatureList",
		iconList:"IconListBlock",
		reviewList:"Review",
		faq:"Faq",
		productList:"Product",
		tertiaryText:"String",
		sideBySideView:"SideBySideView",
		mobileImage:"Image",
		htmlId:"String"
	},
	ContentBlockConnection:{
		pageInfo:"PageInfo",
		edges:"ContentBlockEdge"
	},
	ContentBlockCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ContentBlock",
		validation:"_ValidationResult"
	},
	ContentBlockDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ContentBlock"
	},
	ContentBlockEdge:{
		node:"ContentBlock"
	},
	ContentBlockMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		type:"FieldMeta",
		primaryText:"FieldMeta",
		secondaryText:"FieldMeta",
		jsonContent:"FieldMeta",
		headerView:"FieldMeta",
		reviewView:"FieldMeta",
		content:"FieldMeta",
		link:"FieldMeta",
		image:"FieldMeta",
		media:"FieldMeta",
		product:"FieldMeta",
		featureList:"FieldMeta",
		iconList:"FieldMeta",
		reviewList:"FieldMeta",
		faq:"FieldMeta",
		productList:"FieldMeta",
		tertiaryText:"FieldMeta",
		sideBySideView:"FieldMeta",
		mobileImage:"FieldMeta",
		htmlId:"FieldMeta"
	},
	ContentBlockUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ContentBlock",
		validation:"_ValidationResult"
	},
	ContentConnection:{
		pageInfo:"PageInfo",
		edges:"ContentEdge"
	},
	ContentCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Content",
		validation:"_ValidationResult"
	},
	ContentDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Content"
	},
	ContentEdge:{
		node:"Content"
	},
	ContentMeta:{
		id:"FieldMeta",
		blocks:"FieldMeta",
		page:"FieldMeta"
	},
	ContentUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Content",
		validation:"_ValidationResult"
	},
	Faq:{
		_meta:"FaqMeta",
		id:"UUID",
		items:"FaqItem"
	},
	FaqConnection:{
		pageInfo:"PageInfo",
		edges:"FaqEdge"
	},
	FaqCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Faq",
		validation:"_ValidationResult"
	},
	FaqDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Faq"
	},
	FaqEdge:{
		node:"Faq"
	},
	FaqItem:{
		_meta:"FaqItemMeta",
		id:"UUID",
		order:"Int",
		question:"String",
		answer:"String",
		list:"Faq"
	},
	FaqItemConnection:{
		pageInfo:"PageInfo",
		edges:"FaqItemEdge"
	},
	FaqItemCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"FaqItem",
		validation:"_ValidationResult"
	},
	FaqItemDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"FaqItem"
	},
	FaqItemEdge:{
		node:"FaqItem"
	},
	FaqItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		question:"FieldMeta",
		answer:"FieldMeta",
		list:"FieldMeta"
	},
	FaqItemUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"FaqItem",
		validation:"_ValidationResult"
	},
	FaqMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	FaqUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Faq",
		validation:"_ValidationResult"
	},
	FeatureList:{
		_meta:"FeatureListMeta",
		id:"UUID",
		items:"FeatureListItem"
	},
	FeatureListConnection:{
		pageInfo:"PageInfo",
		edges:"FeatureListEdge"
	},
	FeatureListCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"FeatureList",
		validation:"_ValidationResult"
	},
	FeatureListDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"FeatureList"
	},
	FeatureListEdge:{
		node:"FeatureList"
	},
	FeatureListItem:{
		_meta:"FeatureListItemMeta",
		id:"UUID",
		order:"Int",
		headline:"String",
		text:"String",
		list:"FeatureList"
	},
	FeatureListItemConnection:{
		pageInfo:"PageInfo",
		edges:"FeatureListItemEdge"
	},
	FeatureListItemCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"FeatureListItem",
		validation:"_ValidationResult"
	},
	FeatureListItemDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"FeatureListItem"
	},
	FeatureListItemEdge:{
		node:"FeatureListItem"
	},
	FeatureListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		headline:"FieldMeta",
		text:"FieldMeta",
		list:"FieldMeta"
	},
	FeatureListItemUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"FeatureListItem",
		validation:"_ValidationResult"
	},
	FeatureListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	FeatureListUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"FeatureList",
		validation:"_ValidationResult"
	},
	FieldMeta:{
		readable:"Boolean",
		updatable:"Boolean"
	},
	Gallery:{
		_meta:"GalleryMeta",
		id:"UUID",
		items:"GalleryItem"
	},
	GalleryConnection:{
		pageInfo:"PageInfo",
		edges:"GalleryEdge"
	},
	GalleryCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Gallery",
		validation:"_ValidationResult"
	},
	GalleryDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Gallery"
	},
	GalleryEdge:{
		node:"Gallery"
	},
	GalleryItem:{
		_meta:"GalleryItemMeta",
		id:"UUID",
		order:"Int",
		caption:"String",
		list:"Gallery",
		image:"Image"
	},
	GalleryItemConnection:{
		pageInfo:"PageInfo",
		edges:"GalleryItemEdge"
	},
	GalleryItemCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"GalleryItem",
		validation:"_ValidationResult"
	},
	GalleryItemDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"GalleryItem"
	},
	GalleryItemEdge:{
		node:"GalleryItem"
	},
	GalleryItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		caption:"FieldMeta",
		list:"FieldMeta",
		image:"FieldMeta"
	},
	GalleryItemUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"GalleryItem",
		validation:"_ValidationResult"
	},
	GalleryMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	GalleryUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Gallery",
		validation:"_ValidationResult"
	},
	Icon:{
		_meta:"IconMeta",
		id:"UUID",
		key:"String",
		image:"Image",
		locales:"IconLocale",
		localesByLocale:"IconLocale"
	},
	IconConnection:{
		pageInfo:"PageInfo",
		edges:"IconEdge"
	},
	IconCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Icon",
		validation:"_ValidationResult"
	},
	IconDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Icon"
	},
	IconEdge:{
		node:"Icon"
	},
	IconGroup:{
		_meta:"IconGroupMeta",
		id:"UUID",
		icons:"IconList",
		locales:"IconGroupLocale",
		title:"String",
		localesByLocale:"IconGroupLocale"
	},
	IconGroupConnection:{
		pageInfo:"PageInfo",
		edges:"IconGroupEdge"
	},
	IconGroupCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconGroup",
		validation:"_ValidationResult"
	},
	IconGroupDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconGroup"
	},
	IconGroupEdge:{
		node:"IconGroup"
	},
	IconGroupList:{
		_meta:"IconGroupListMeta",
		id:"UUID",
		items:"IconGroupListItem",
		title:"String"
	},
	IconGroupListConnection:{
		pageInfo:"PageInfo",
		edges:"IconGroupListEdge"
	},
	IconGroupListCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconGroupList",
		validation:"_ValidationResult"
	},
	IconGroupListDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconGroupList"
	},
	IconGroupListEdge:{
		node:"IconGroupList"
	},
	IconGroupListItem:{
		_meta:"IconGroupListItemMeta",
		id:"UUID",
		order:"Int",
		list:"IconGroupList",
		group:"IconGroup"
	},
	IconGroupListItemConnection:{
		pageInfo:"PageInfo",
		edges:"IconGroupListItemEdge"
	},
	IconGroupListItemCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconGroupListItem",
		validation:"_ValidationResult"
	},
	IconGroupListItemDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconGroupListItem"
	},
	IconGroupListItemEdge:{
		node:"IconGroupListItem"
	},
	IconGroupListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		group:"FieldMeta"
	},
	IconGroupListItemUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconGroupListItem",
		validation:"_ValidationResult"
	},
	IconGroupListMeta:{
		id:"FieldMeta",
		items:"FieldMeta",
		title:"FieldMeta"
	},
	IconGroupListUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconGroupList",
		validation:"_ValidationResult"
	},
	IconGroupLocale:{
		_meta:"IconGroupLocaleMeta",
		id:"UUID",
		title:"String",
		root:"IconGroup",
		locale:"Locale"
	},
	IconGroupLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"IconGroupLocaleEdge"
	},
	IconGroupLocaleCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconGroupLocale",
		validation:"_ValidationResult"
	},
	IconGroupLocaleDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconGroupLocale"
	},
	IconGroupLocaleEdge:{
		node:"IconGroupLocale"
	},
	IconGroupLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	IconGroupLocaleUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconGroupLocale",
		validation:"_ValidationResult"
	},
	IconGroupMeta:{
		id:"FieldMeta",
		icons:"FieldMeta",
		locales:"FieldMeta",
		title:"FieldMeta"
	},
	IconGroupUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconGroup",
		validation:"_ValidationResult"
	},
	IconList:{
		_meta:"IconListMeta",
		id:"UUID",
		items:"IconListItem"
	},
	IconListBlock:{
		_meta:"IconListBlockMeta",
		id:"UUID",
		items:"IconListBlockItem"
	},
	IconListBlockConnection:{
		pageInfo:"PageInfo",
		edges:"IconListBlockEdge"
	},
	IconListBlockCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconListBlock",
		validation:"_ValidationResult"
	},
	IconListBlockDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconListBlock"
	},
	IconListBlockEdge:{
		node:"IconListBlock"
	},
	IconListBlockItem:{
		_meta:"IconListBlockItemMeta",
		id:"UUID",
		order:"Int",
		text:"String",
		list:"IconListBlock",
		icon:"Image"
	},
	IconListBlockItemConnection:{
		pageInfo:"PageInfo",
		edges:"IconListBlockItemEdge"
	},
	IconListBlockItemCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconListBlockItem",
		validation:"_ValidationResult"
	},
	IconListBlockItemDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconListBlockItem"
	},
	IconListBlockItemEdge:{
		node:"IconListBlockItem"
	},
	IconListBlockItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		text:"FieldMeta",
		list:"FieldMeta",
		icon:"FieldMeta"
	},
	IconListBlockItemUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconListBlockItem",
		validation:"_ValidationResult"
	},
	IconListBlockMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	IconListBlockUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconListBlock",
		validation:"_ValidationResult"
	},
	IconListConnection:{
		pageInfo:"PageInfo",
		edges:"IconListEdge"
	},
	IconListCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconList",
		validation:"_ValidationResult"
	},
	IconListDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconList"
	},
	IconListEdge:{
		node:"IconList"
	},
	IconListItem:{
		_meta:"IconListItemMeta",
		id:"UUID",
		order:"Int",
		list:"IconList",
		icon:"Icon"
	},
	IconListItemConnection:{
		pageInfo:"PageInfo",
		edges:"IconListItemEdge"
	},
	IconListItemCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconListItem",
		validation:"_ValidationResult"
	},
	IconListItemDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconListItem"
	},
	IconListItemEdge:{
		node:"IconListItem"
	},
	IconListItemMeta:{
		id:"FieldMeta",
		order:"FieldMeta",
		list:"FieldMeta",
		icon:"FieldMeta"
	},
	IconListItemUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconListItem",
		validation:"_ValidationResult"
	},
	IconListMeta:{
		id:"FieldMeta",
		items:"FieldMeta"
	},
	IconListUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconList",
		validation:"_ValidationResult"
	},
	IconLocale:{
		_meta:"IconLocaleMeta",
		id:"UUID",
		title:"String",
		alt:"String",
		root:"Icon",
		locale:"Locale"
	},
	IconLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"IconLocaleEdge"
	},
	IconLocaleCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconLocale",
		validation:"_ValidationResult"
	},
	IconLocaleDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconLocale"
	},
	IconLocaleEdge:{
		node:"IconLocale"
	},
	IconLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		alt:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	IconLocaleUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"IconLocale",
		validation:"_ValidationResult"
	},
	IconMeta:{
		id:"FieldMeta",
		key:"FieldMeta",
		image:"FieldMeta",
		locales:"FieldMeta"
	},
	IconUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Icon",
		validation:"_ValidationResult"
	},
	Image:{
		_meta:"ImageMeta",
		id:"UUID",
		url:"String",
		width:"Int",
		height:"Int",
		size:"Int",
		type:"String",
		title:"String",
		alt:"String",
		decorationPreset:"String"
	},
	ImageConnection:{
		pageInfo:"PageInfo",
		edges:"ImageEdge"
	},
	ImageCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Image",
		validation:"_ValidationResult"
	},
	ImageDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Image"
	},
	ImageEdge:{
		node:"Image"
	},
	ImageList:{
		_meta:"ImageListMeta",
		id:"UUID",
		images:"ImageXImageList"
	},
	ImageListConnection:{
		pageInfo:"PageInfo",
		edges:"ImageListEdge"
	},
	ImageListCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ImageList",
		validation:"_ValidationResult"
	},
	ImageListDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ImageList"
	},
	ImageListEdge:{
		node:"ImageList"
	},
	ImageListMeta:{
		id:"FieldMeta",
		images:"FieldMeta"
	},
	ImageListUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ImageList",
		validation:"_ValidationResult"
	},
	ImageLocale:{
		_meta:"ImageLocaleMeta",
		id:"UUID",
		url:"String",
		width:"Int",
		height:"Int",
		size:"Int",
		type:"String",
		title:"String",
		alt:"String",
		decorationPreset:"String"
	},
	ImageLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ImageLocaleEdge"
	},
	ImageLocaleCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ImageLocale",
		validation:"_ValidationResult"
	},
	ImageLocaleDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ImageLocale"
	},
	ImageLocaleEdge:{
		node:"ImageLocale"
	},
	ImageLocaleMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		width:"FieldMeta",
		height:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		title:"FieldMeta",
		alt:"FieldMeta",
		decorationPreset:"FieldMeta"
	},
	ImageLocaleUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ImageLocale",
		validation:"_ValidationResult"
	},
	ImageMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		width:"FieldMeta",
		height:"FieldMeta",
		size:"FieldMeta",
		type:"FieldMeta",
		title:"FieldMeta",
		alt:"FieldMeta",
		decorationPreset:"FieldMeta"
	},
	ImageUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Image",
		validation:"_ValidationResult"
	},
	ImageXImageList:{
		_meta:"ImageXImageListMeta",
		id:"UUID",
		imagePosition:"Int",
		imageList:"ImageList",
		image:"Image"
	},
	ImageXImageListConnection:{
		pageInfo:"PageInfo",
		edges:"ImageXImageListEdge"
	},
	ImageXImageListCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ImageXImageList",
		validation:"_ValidationResult"
	},
	ImageXImageListDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ImageXImageList"
	},
	ImageXImageListEdge:{
		node:"ImageXImageList"
	},
	ImageXImageListMeta:{
		id:"FieldMeta",
		imagePosition:"FieldMeta",
		imageList:"FieldMeta",
		image:"FieldMeta"
	},
	ImageXImageListUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ImageXImageList",
		validation:"_ValidationResult"
	},
	Info:{
		description:"String"
	},
	Link:{
		_meta:"LinkMeta",
		id:"UUID",
		title:"String",
		externalLink:"String",
		internalLink:"Linkable"
	},
	Linkable:{
		_meta:"LinkableMeta",
		id:"UUID",
		url:"String",
		page:"PageLocale"
	},
	LinkableConnection:{
		pageInfo:"PageInfo",
		edges:"LinkableEdge"
	},
	LinkableCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Linkable",
		validation:"_ValidationResult"
	},
	LinkableDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Linkable"
	},
	LinkableEdge:{
		node:"Linkable"
	},
	LinkableMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		page:"FieldMeta"
	},
	LinkableUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Linkable",
		validation:"_ValidationResult"
	},
	LinkConnection:{
		pageInfo:"PageInfo",
		edges:"LinkEdge"
	},
	LinkCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Link",
		validation:"_ValidationResult"
	},
	LinkDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Link"
	},
	LinkEdge:{
		node:"Link"
	},
	LinkMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		externalLink:"FieldMeta",
		internalLink:"FieldMeta"
	},
	LinkUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Link",
		validation:"_ValidationResult"
	},
	Locale:{
		_meta:"LocaleMeta",
		id:"UUID",
		code:"String",
		iconGroups:"IconGroupLocale",
		icons:"IconLocale",
		productVariants:"ProductVariantLocale",
		seos:"SeoLocale",
		pages:"PageLocale",
		products:"ProductLocale",
		iconGroupsByRoot:"IconGroupLocale",
		iconsByRoot:"IconLocale",
		productVariantsByRoot:"ProductVariantLocale",
		seosByRoot:"SeoLocale",
		pagesByRoot:"PageLocale",
		pagesByLink:"PageLocale",
		pagesByContent:"PageLocale",
		productsByRoot:"ProductLocale"
	},
	LocaleConnection:{
		pageInfo:"PageInfo",
		edges:"LocaleEdge"
	},
	LocaleCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Locale",
		validation:"_ValidationResult"
	},
	LocaleDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Locale"
	},
	LocaleEdge:{
		node:"Locale"
	},
	LocaleMeta:{
		id:"FieldMeta",
		code:"FieldMeta",
		iconGroups:"FieldMeta",
		icons:"FieldMeta",
		productVariants:"FieldMeta",
		seos:"FieldMeta",
		pages:"FieldMeta",
		products:"FieldMeta"
	},
	LocaleUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Locale",
		validation:"_ValidationResult"
	},
	Media:{
		_meta:"MediaMeta",
		id:"UUID",
		url:"String",
		videoUrl:"String",
		width:"Int",
		height:"Int",
		poster:"String",
		type:"MediaItemType",
		caption:"String",
		audio:"Boolean"
	},
	MediaConnection:{
		pageInfo:"PageInfo",
		edges:"MediaEdge"
	},
	MediaCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Media",
		validation:"_ValidationResult"
	},
	MediaDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Media"
	},
	MediaEdge:{
		node:"Media"
	},
	MediaMeta:{
		id:"FieldMeta",
		url:"FieldMeta",
		videoUrl:"FieldMeta",
		width:"FieldMeta",
		height:"FieldMeta",
		poster:"FieldMeta",
		type:"FieldMeta",
		caption:"FieldMeta",
		audio:"FieldMeta"
	},
	MediaUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Media",
		validation:"_ValidationResult"
	},
	Mutation:{
		createAbandonedCheckoutUnsubscription:"AbandonedCheckoutUnsubscriptionCreateResult",
		deleteAbandonedCheckoutUnsubscription:"AbandonedCheckoutUnsubscriptionDeleteResult",
		updateAbandonedCheckoutUnsubscription:"AbandonedCheckoutUnsubscriptionUpdateResult",
		createAbandonedCheckoutNotification:"AbandonedCheckoutNotificationCreateResult",
		deleteAbandonedCheckoutNotification:"AbandonedCheckoutNotificationDeleteResult",
		updateAbandonedCheckoutNotification:"AbandonedCheckoutNotificationUpdateResult",
		createShopifyLineItem:"ShopifyLineItemCreateResult",
		deleteShopifyLineItem:"ShopifyLineItemDeleteResult",
		updateShopifyLineItem:"ShopifyLineItemUpdateResult",
		createShopifyOrder:"ShopifyOrderCreateResult",
		deleteShopifyOrder:"ShopifyOrderDeleteResult",
		updateShopifyOrder:"ShopifyOrderUpdateResult",
		createTranslationValue:"TranslationValueCreateResult",
		deleteTranslationValue:"TranslationValueDeleteResult",
		updateTranslationValue:"TranslationValueUpdateResult",
		createTranslationKey:"TranslationKeyCreateResult",
		deleteTranslationKey:"TranslationKeyDeleteResult",
		updateTranslationKey:"TranslationKeyUpdateResult",
		createTranslationCatalogue:"TranslationCatalogueCreateResult",
		deleteTranslationCatalogue:"TranslationCatalogueDeleteResult",
		updateTranslationCatalogue:"TranslationCatalogueUpdateResult",
		createTranslationDomain:"TranslationDomainCreateResult",
		deleteTranslationDomain:"TranslationDomainDeleteResult",
		updateTranslationDomain:"TranslationDomainUpdateResult",
		createProductLocale:"ProductLocaleCreateResult",
		deleteProductLocale:"ProductLocaleDeleteResult",
		updateProductLocale:"ProductLocaleUpdateResult",
		createMedia:"MediaCreateResult",
		deleteMedia:"MediaDeleteResult",
		updateMedia:"MediaUpdateResult",
		createIconListBlockItem:"IconListBlockItemCreateResult",
		deleteIconListBlockItem:"IconListBlockItemDeleteResult",
		updateIconListBlockItem:"IconListBlockItemUpdateResult",
		createIconListBlock:"IconListBlockCreateResult",
		deleteIconListBlock:"IconListBlockDeleteResult",
		updateIconListBlock:"IconListBlockUpdateResult",
		createFeatureListItem:"FeatureListItemCreateResult",
		deleteFeatureListItem:"FeatureListItemDeleteResult",
		updateFeatureListItem:"FeatureListItemUpdateResult",
		createFeatureList:"FeatureListCreateResult",
		deleteFeatureList:"FeatureListDeleteResult",
		updateFeatureList:"FeatureListUpdateResult",
		createFaqItem:"FaqItemCreateResult",
		deleteFaqItem:"FaqItemDeleteResult",
		updateFaqItem:"FaqItemUpdateResult",
		createFaq:"FaqCreateResult",
		deleteFaq:"FaqDeleteResult",
		updateFaq:"FaqUpdateResult",
		createContentBlock:"ContentBlockCreateResult",
		deleteContentBlock:"ContentBlockDeleteResult",
		updateContentBlock:"ContentBlockUpdateResult",
		createContent:"ContentCreateResult",
		deleteContent:"ContentDeleteResult",
		updateContent:"ContentUpdateResult",
		createLink:"LinkCreateResult",
		deleteLink:"LinkDeleteResult",
		updateLink:"LinkUpdateResult",
		createReviewLocale:"ReviewLocaleCreateResult",
		deleteReviewLocale:"ReviewLocaleDeleteResult",
		updateReviewLocale:"ReviewLocaleUpdateResult",
		createReview:"ReviewCreateResult",
		deleteReview:"ReviewDeleteResult",
		updateReview:"ReviewUpdateResult",
		createGalleryItem:"GalleryItemCreateResult",
		deleteGalleryItem:"GalleryItemDeleteResult",
		updateGalleryItem:"GalleryItemUpdateResult",
		createGallery:"GalleryCreateResult",
		deleteGallery:"GalleryDeleteResult",
		updateGallery:"GalleryUpdateResult",
		createProductVariantStore:"ProductVariantStoreCreateResult",
		deleteProductVariantStore:"ProductVariantStoreDeleteResult",
		updateProductVariantStore:"ProductVariantStoreUpdateResult",
		createProductStore:"ProductStoreCreateResult",
		deleteProductStore:"ProductStoreDeleteResult",
		updateProductStore:"ProductStoreUpdateResult",
		createIconListItem:"IconListItemCreateResult",
		deleteIconListItem:"IconListItemDeleteResult",
		updateIconListItem:"IconListItemUpdateResult",
		createIconList:"IconListCreateResult",
		deleteIconList:"IconListDeleteResult",
		updateIconList:"IconListUpdateResult",
		createIconGroupListItem:"IconGroupListItemCreateResult",
		deleteIconGroupListItem:"IconGroupListItemDeleteResult",
		updateIconGroupListItem:"IconGroupListItemUpdateResult",
		createIconGroupList:"IconGroupListCreateResult",
		deleteIconGroupList:"IconGroupListDeleteResult",
		updateIconGroupList:"IconGroupListUpdateResult",
		createProductVariantLocale:"ProductVariantLocaleCreateResult",
		deleteProductVariantLocale:"ProductVariantLocaleDeleteResult",
		updateProductVariantLocale:"ProductVariantLocaleUpdateResult",
		createProductVariant:"ProductVariantCreateResult",
		deleteProductVariant:"ProductVariantDeleteResult",
		updateProductVariant:"ProductVariantUpdateResult",
		createProduct:"ProductCreateResult",
		deleteProduct:"ProductDeleteResult",
		updateProduct:"ProductUpdateResult",
		createImageLocale:"ImageLocaleCreateResult",
		deleteImageLocale:"ImageLocaleDeleteResult",
		updateImageLocale:"ImageLocaleUpdateResult",
		createStore:"StoreCreateResult",
		deleteStore:"StoreDeleteResult",
		updateStore:"StoreUpdateResult",
		createAdminNote:"AdminNoteCreateResult",
		deleteAdminNote:"AdminNoteDeleteResult",
		updateAdminNote:"AdminNoteUpdateResult",
		createPageLocale:"PageLocaleCreateResult",
		deletePageLocale:"PageLocaleDeleteResult",
		updatePageLocale:"PageLocaleUpdateResult",
		createPage:"PageCreateResult",
		deletePage:"PageDeleteResult",
		updatePage:"PageUpdateResult",
		createImageXImageList:"ImageXImageListCreateResult",
		deleteImageXImageList:"ImageXImageListDeleteResult",
		updateImageXImageList:"ImageXImageListUpdateResult",
		createIconGroupLocale:"IconGroupLocaleCreateResult",
		deleteIconGroupLocale:"IconGroupLocaleDeleteResult",
		updateIconGroupLocale:"IconGroupLocaleUpdateResult",
		createIconLocale:"IconLocaleCreateResult",
		deleteIconLocale:"IconLocaleDeleteResult",
		updateIconLocale:"IconLocaleUpdateResult",
		createIconGroup:"IconGroupCreateResult",
		deleteIconGroup:"IconGroupDeleteResult",
		updateIconGroup:"IconGroupUpdateResult",
		createIcon:"IconCreateResult",
		deleteIcon:"IconDeleteResult",
		updateIcon:"IconUpdateResult",
		createLocale:"LocaleCreateResult",
		deleteLocale:"LocaleDeleteResult",
		updateLocale:"LocaleUpdateResult",
		createSeoLocale:"SeoLocaleCreateResult",
		deleteSeoLocale:"SeoLocaleDeleteResult",
		updateSeoLocale:"SeoLocaleUpdateResult",
		createSeo:"SeoCreateResult",
		deleteSeo:"SeoDeleteResult",
		updateSeo:"SeoUpdateResult",
		createLinkable:"LinkableCreateResult",
		deleteLinkable:"LinkableDeleteResult",
		updateLinkable:"LinkableUpdateResult",
		createImageList:"ImageListCreateResult",
		deleteImageList:"ImageListDeleteResult",
		updateImageList:"ImageListUpdateResult",
		createImage:"ImageCreateResult",
		deleteImage:"ImageDeleteResult",
		updateImage:"ImageUpdateResult",
		transaction:"MutationTransaction",
		generateUploadUrl:"S3SignedUpload",
		generateReadUrl:"S3SignedRead"
	},
	MutationTransaction:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		validation:"_ValidationResult",
		createAbandonedCheckoutUnsubscription:"AbandonedCheckoutUnsubscriptionCreateResult",
		deleteAbandonedCheckoutUnsubscription:"AbandonedCheckoutUnsubscriptionDeleteResult",
		updateAbandonedCheckoutUnsubscription:"AbandonedCheckoutUnsubscriptionUpdateResult",
		createAbandonedCheckoutNotification:"AbandonedCheckoutNotificationCreateResult",
		deleteAbandonedCheckoutNotification:"AbandonedCheckoutNotificationDeleteResult",
		updateAbandonedCheckoutNotification:"AbandonedCheckoutNotificationUpdateResult",
		createShopifyLineItem:"ShopifyLineItemCreateResult",
		deleteShopifyLineItem:"ShopifyLineItemDeleteResult",
		updateShopifyLineItem:"ShopifyLineItemUpdateResult",
		createShopifyOrder:"ShopifyOrderCreateResult",
		deleteShopifyOrder:"ShopifyOrderDeleteResult",
		updateShopifyOrder:"ShopifyOrderUpdateResult",
		createTranslationValue:"TranslationValueCreateResult",
		deleteTranslationValue:"TranslationValueDeleteResult",
		updateTranslationValue:"TranslationValueUpdateResult",
		createTranslationKey:"TranslationKeyCreateResult",
		deleteTranslationKey:"TranslationKeyDeleteResult",
		updateTranslationKey:"TranslationKeyUpdateResult",
		createTranslationCatalogue:"TranslationCatalogueCreateResult",
		deleteTranslationCatalogue:"TranslationCatalogueDeleteResult",
		updateTranslationCatalogue:"TranslationCatalogueUpdateResult",
		createTranslationDomain:"TranslationDomainCreateResult",
		deleteTranslationDomain:"TranslationDomainDeleteResult",
		updateTranslationDomain:"TranslationDomainUpdateResult",
		createProductLocale:"ProductLocaleCreateResult",
		deleteProductLocale:"ProductLocaleDeleteResult",
		updateProductLocale:"ProductLocaleUpdateResult",
		createMedia:"MediaCreateResult",
		deleteMedia:"MediaDeleteResult",
		updateMedia:"MediaUpdateResult",
		createIconListBlockItem:"IconListBlockItemCreateResult",
		deleteIconListBlockItem:"IconListBlockItemDeleteResult",
		updateIconListBlockItem:"IconListBlockItemUpdateResult",
		createIconListBlock:"IconListBlockCreateResult",
		deleteIconListBlock:"IconListBlockDeleteResult",
		updateIconListBlock:"IconListBlockUpdateResult",
		createFeatureListItem:"FeatureListItemCreateResult",
		deleteFeatureListItem:"FeatureListItemDeleteResult",
		updateFeatureListItem:"FeatureListItemUpdateResult",
		createFeatureList:"FeatureListCreateResult",
		deleteFeatureList:"FeatureListDeleteResult",
		updateFeatureList:"FeatureListUpdateResult",
		createFaqItem:"FaqItemCreateResult",
		deleteFaqItem:"FaqItemDeleteResult",
		updateFaqItem:"FaqItemUpdateResult",
		createFaq:"FaqCreateResult",
		deleteFaq:"FaqDeleteResult",
		updateFaq:"FaqUpdateResult",
		createContentBlock:"ContentBlockCreateResult",
		deleteContentBlock:"ContentBlockDeleteResult",
		updateContentBlock:"ContentBlockUpdateResult",
		createContent:"ContentCreateResult",
		deleteContent:"ContentDeleteResult",
		updateContent:"ContentUpdateResult",
		createLink:"LinkCreateResult",
		deleteLink:"LinkDeleteResult",
		updateLink:"LinkUpdateResult",
		createReviewLocale:"ReviewLocaleCreateResult",
		deleteReviewLocale:"ReviewLocaleDeleteResult",
		updateReviewLocale:"ReviewLocaleUpdateResult",
		createReview:"ReviewCreateResult",
		deleteReview:"ReviewDeleteResult",
		updateReview:"ReviewUpdateResult",
		createGalleryItem:"GalleryItemCreateResult",
		deleteGalleryItem:"GalleryItemDeleteResult",
		updateGalleryItem:"GalleryItemUpdateResult",
		createGallery:"GalleryCreateResult",
		deleteGallery:"GalleryDeleteResult",
		updateGallery:"GalleryUpdateResult",
		createProductVariantStore:"ProductVariantStoreCreateResult",
		deleteProductVariantStore:"ProductVariantStoreDeleteResult",
		updateProductVariantStore:"ProductVariantStoreUpdateResult",
		createProductStore:"ProductStoreCreateResult",
		deleteProductStore:"ProductStoreDeleteResult",
		updateProductStore:"ProductStoreUpdateResult",
		createIconListItem:"IconListItemCreateResult",
		deleteIconListItem:"IconListItemDeleteResult",
		updateIconListItem:"IconListItemUpdateResult",
		createIconList:"IconListCreateResult",
		deleteIconList:"IconListDeleteResult",
		updateIconList:"IconListUpdateResult",
		createIconGroupListItem:"IconGroupListItemCreateResult",
		deleteIconGroupListItem:"IconGroupListItemDeleteResult",
		updateIconGroupListItem:"IconGroupListItemUpdateResult",
		createIconGroupList:"IconGroupListCreateResult",
		deleteIconGroupList:"IconGroupListDeleteResult",
		updateIconGroupList:"IconGroupListUpdateResult",
		createProductVariantLocale:"ProductVariantLocaleCreateResult",
		deleteProductVariantLocale:"ProductVariantLocaleDeleteResult",
		updateProductVariantLocale:"ProductVariantLocaleUpdateResult",
		createProductVariant:"ProductVariantCreateResult",
		deleteProductVariant:"ProductVariantDeleteResult",
		updateProductVariant:"ProductVariantUpdateResult",
		createProduct:"ProductCreateResult",
		deleteProduct:"ProductDeleteResult",
		updateProduct:"ProductUpdateResult",
		createImageLocale:"ImageLocaleCreateResult",
		deleteImageLocale:"ImageLocaleDeleteResult",
		updateImageLocale:"ImageLocaleUpdateResult",
		createStore:"StoreCreateResult",
		deleteStore:"StoreDeleteResult",
		updateStore:"StoreUpdateResult",
		createAdminNote:"AdminNoteCreateResult",
		deleteAdminNote:"AdminNoteDeleteResult",
		updateAdminNote:"AdminNoteUpdateResult",
		createPageLocale:"PageLocaleCreateResult",
		deletePageLocale:"PageLocaleDeleteResult",
		updatePageLocale:"PageLocaleUpdateResult",
		createPage:"PageCreateResult",
		deletePage:"PageDeleteResult",
		updatePage:"PageUpdateResult",
		createImageXImageList:"ImageXImageListCreateResult",
		deleteImageXImageList:"ImageXImageListDeleteResult",
		updateImageXImageList:"ImageXImageListUpdateResult",
		createIconGroupLocale:"IconGroupLocaleCreateResult",
		deleteIconGroupLocale:"IconGroupLocaleDeleteResult",
		updateIconGroupLocale:"IconGroupLocaleUpdateResult",
		createIconLocale:"IconLocaleCreateResult",
		deleteIconLocale:"IconLocaleDeleteResult",
		updateIconLocale:"IconLocaleUpdateResult",
		createIconGroup:"IconGroupCreateResult",
		deleteIconGroup:"IconGroupDeleteResult",
		updateIconGroup:"IconGroupUpdateResult",
		createIcon:"IconCreateResult",
		deleteIcon:"IconDeleteResult",
		updateIcon:"IconUpdateResult",
		createLocale:"LocaleCreateResult",
		deleteLocale:"LocaleDeleteResult",
		updateLocale:"LocaleUpdateResult",
		createSeoLocale:"SeoLocaleCreateResult",
		deleteSeoLocale:"SeoLocaleDeleteResult",
		updateSeoLocale:"SeoLocaleUpdateResult",
		createSeo:"SeoCreateResult",
		deleteSeo:"SeoDeleteResult",
		updateSeo:"SeoUpdateResult",
		createLinkable:"LinkableCreateResult",
		deleteLinkable:"LinkableDeleteResult",
		updateLinkable:"LinkableUpdateResult",
		createImageList:"ImageListCreateResult",
		deleteImageList:"ImageListDeleteResult",
		updateImageList:"ImageListUpdateResult",
		createImage:"ImageCreateResult",
		deleteImage:"ImageDeleteResult",
		updateImage:"ImageUpdateResult"
	},
	Page:{
		_meta:"PageMeta",
		id:"UUID",
		internalName:"String",
		template:"PageType",
		seo:"Seo",
		locales:"PageLocale",
		localesByLocale:"PageLocale",
		localesByLink:"PageLocale",
		localesByContent:"PageLocale"
	},
	PageConnection:{
		pageInfo:"PageInfo",
		edges:"PageEdge"
	},
	PageCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Page",
		validation:"_ValidationResult"
	},
	PageDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Page"
	},
	PageEdge:{
		node:"Page"
	},
	PageInfo:{
		totalCount:"Int"
	},
	PageLocale:{
		_meta:"PageLocaleMeta",
		id:"UUID",
		title:"String",
		locale:"Locale",
		link:"Linkable",
		root:"Page",
		content:"Content",
		bannerColor:"String",
		bannerBgColor:"String",
		bannerText:"String"
	},
	PageLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"PageLocaleEdge"
	},
	PageLocaleCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"PageLocale",
		validation:"_ValidationResult"
	},
	PageLocaleDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"PageLocale"
	},
	PageLocaleEdge:{
		node:"PageLocale"
	},
	PageLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		locale:"FieldMeta",
		link:"FieldMeta",
		root:"FieldMeta",
		content:"FieldMeta",
		bannerColor:"FieldMeta",
		bannerBgColor:"FieldMeta",
		bannerText:"FieldMeta"
	},
	PageLocaleUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"PageLocale",
		validation:"_ValidationResult"
	},
	PageMeta:{
		id:"FieldMeta",
		internalName:"FieldMeta",
		template:"FieldMeta",
		seo:"FieldMeta",
		locales:"FieldMeta"
	},
	PageUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Page",
		validation:"_ValidationResult"
	},
	Product:{
		_meta:"ProductMeta",
		id:"UUID",
		title:"String",
		shopifyHandle:"String",
		productType:"String",
		stores:"ProductStore",
		productVariants:"ProductVariant",
		locales:"ProductLocale",
		storesByStore:"ProductStore",
		storesByShopifyId:"ProductStore",
		productVariantsByShopifySku:"ProductVariant",
		productVariantsByLocales:"ProductVariant",
		productVariantsByStores:"ProductVariant",
		localesByLocale:"ProductLocale"
	},
	ProductConnection:{
		pageInfo:"PageInfo",
		edges:"ProductEdge"
	},
	ProductCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Product",
		validation:"_ValidationResult"
	},
	ProductDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Product"
	},
	ProductEdge:{
		node:"Product"
	},
	ProductLocale:{
		_meta:"ProductLocaleMeta",
		id:"UUID",
		title:"String",
		description:"String",
		descriptionSecondary:"String",
		root:"Product",
		locale:"Locale",
		gallery:"Gallery",
		titleTemplate:"String"
	},
	ProductLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductLocaleEdge"
	},
	ProductLocaleCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ProductLocale",
		validation:"_ValidationResult"
	},
	ProductLocaleDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ProductLocale"
	},
	ProductLocaleEdge:{
		node:"ProductLocale"
	},
	ProductLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		description:"FieldMeta",
		descriptionSecondary:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		gallery:"FieldMeta",
		titleTemplate:"FieldMeta"
	},
	ProductLocaleUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ProductLocale",
		validation:"_ValidationResult"
	},
	ProductMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		shopifyHandle:"FieldMeta",
		productType:"FieldMeta",
		stores:"FieldMeta",
		productVariants:"FieldMeta",
		locales:"FieldMeta"
	},
	ProductStore:{
		_meta:"ProductStoreMeta",
		id:"UUID",
		shopifyId:"String",
		root:"Product",
		store:"Store"
	},
	ProductStoreConnection:{
		pageInfo:"PageInfo",
		edges:"ProductStoreEdge"
	},
	ProductStoreCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ProductStore",
		validation:"_ValidationResult"
	},
	ProductStoreDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ProductStore"
	},
	ProductStoreEdge:{
		node:"ProductStore"
	},
	ProductStoreMeta:{
		id:"FieldMeta",
		shopifyId:"FieldMeta",
		root:"FieldMeta",
		store:"FieldMeta"
	},
	ProductStoreUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ProductStore",
		validation:"_ValidationResult"
	},
	ProductUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Product",
		validation:"_ValidationResult"
	},
	ProductVariant:{
		_meta:"ProductVariantMeta",
		id:"UUID",
		shopifySku:"String",
		product:"Product",
		locales:"ProductVariantLocale",
		stores:"ProductVariantStore",
		title:"String",
		heurekaId:"String",
		localesByLocale:"ProductVariantLocale",
		storesByStore:"ProductVariantStore",
		storesByShopifyId:"ProductVariantStore",
		storesByStorefrontId:"ProductVariantStore"
	},
	ProductVariantConnection:{
		pageInfo:"PageInfo",
		edges:"ProductVariantEdge"
	},
	ProductVariantCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ProductVariant",
		validation:"_ValidationResult"
	},
	ProductVariantDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ProductVariant"
	},
	ProductVariantEdge:{
		node:"ProductVariant"
	},
	ProductVariantLocale:{
		_meta:"ProductVariantLocaleMeta",
		id:"UUID",
		title:"String",
		description:"String",
		root:"ProductVariant",
		locale:"Locale",
		gallery:"Gallery",
		descriptionSecondary:"String",
		titleTemplate:"String"
	},
	ProductVariantLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ProductVariantLocaleEdge"
	},
	ProductVariantLocaleCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ProductVariantLocale",
		validation:"_ValidationResult"
	},
	ProductVariantLocaleDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ProductVariantLocale"
	},
	ProductVariantLocaleEdge:{
		node:"ProductVariantLocale"
	},
	ProductVariantLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		description:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta",
		gallery:"FieldMeta",
		descriptionSecondary:"FieldMeta",
		titleTemplate:"FieldMeta"
	},
	ProductVariantLocaleUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ProductVariantLocale",
		validation:"_ValidationResult"
	},
	ProductVariantMeta:{
		id:"FieldMeta",
		shopifySku:"FieldMeta",
		product:"FieldMeta",
		locales:"FieldMeta",
		stores:"FieldMeta",
		title:"FieldMeta",
		heurekaId:"FieldMeta"
	},
	ProductVariantStore:{
		_meta:"ProductVariantStoreMeta",
		id:"UUID",
		price:"Float",
		shopifyId:"String",
		root:"ProductVariant",
		store:"Store",
		compareAtPrice:"Float",
		storefrontId:"String"
	},
	ProductVariantStoreConnection:{
		pageInfo:"PageInfo",
		edges:"ProductVariantStoreEdge"
	},
	ProductVariantStoreCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ProductVariantStore",
		validation:"_ValidationResult"
	},
	ProductVariantStoreDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ProductVariantStore"
	},
	ProductVariantStoreEdge:{
		node:"ProductVariantStore"
	},
	ProductVariantStoreMeta:{
		id:"FieldMeta",
		price:"FieldMeta",
		shopifyId:"FieldMeta",
		root:"FieldMeta",
		store:"FieldMeta",
		compareAtPrice:"FieldMeta",
		storefrontId:"FieldMeta"
	},
	ProductVariantStoreUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ProductVariantStore",
		validation:"_ValidationResult"
	},
	ProductVariantUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ProductVariant",
		validation:"_ValidationResult"
	},
	Query:{
		getAbandonedCheckoutUnsubscription:"AbandonedCheckoutUnsubscription",
		listAbandonedCheckoutUnsubscription:"AbandonedCheckoutUnsubscription",
		paginateAbandonedCheckoutUnsubscription:"AbandonedCheckoutUnsubscriptionConnection",
		validateCreateAbandonedCheckoutUnsubscription:"_ValidationResult",
		validateUpdateAbandonedCheckoutUnsubscription:"_ValidationResult",
		getAbandonedCheckoutNotification:"AbandonedCheckoutNotification",
		listAbandonedCheckoutNotification:"AbandonedCheckoutNotification",
		paginateAbandonedCheckoutNotification:"AbandonedCheckoutNotificationConnection",
		validateCreateAbandonedCheckoutNotification:"_ValidationResult",
		validateUpdateAbandonedCheckoutNotification:"_ValidationResult",
		getShopifyLineItem:"ShopifyLineItem",
		listShopifyLineItem:"ShopifyLineItem",
		paginateShopifyLineItem:"ShopifyLineItemConnection",
		validateCreateShopifyLineItem:"_ValidationResult",
		validateUpdateShopifyLineItem:"_ValidationResult",
		getShopifyOrder:"ShopifyOrder",
		listShopifyOrder:"ShopifyOrder",
		paginateShopifyOrder:"ShopifyOrderConnection",
		validateCreateShopifyOrder:"_ValidationResult",
		validateUpdateShopifyOrder:"_ValidationResult",
		getTranslationValue:"TranslationValue",
		listTranslationValue:"TranslationValue",
		paginateTranslationValue:"TranslationValueConnection",
		validateCreateTranslationValue:"_ValidationResult",
		validateUpdateTranslationValue:"_ValidationResult",
		getTranslationKey:"TranslationKey",
		listTranslationKey:"TranslationKey",
		paginateTranslationKey:"TranslationKeyConnection",
		validateCreateTranslationKey:"_ValidationResult",
		validateUpdateTranslationKey:"_ValidationResult",
		getTranslationCatalogue:"TranslationCatalogue",
		listTranslationCatalogue:"TranslationCatalogue",
		paginateTranslationCatalogue:"TranslationCatalogueConnection",
		validateCreateTranslationCatalogue:"_ValidationResult",
		validateUpdateTranslationCatalogue:"_ValidationResult",
		getTranslationDomain:"TranslationDomain",
		listTranslationDomain:"TranslationDomain",
		paginateTranslationDomain:"TranslationDomainConnection",
		validateCreateTranslationDomain:"_ValidationResult",
		validateUpdateTranslationDomain:"_ValidationResult",
		getProductLocale:"ProductLocale",
		listProductLocale:"ProductLocale",
		paginateProductLocale:"ProductLocaleConnection",
		validateCreateProductLocale:"_ValidationResult",
		validateUpdateProductLocale:"_ValidationResult",
		getMedia:"Media",
		listMedia:"Media",
		paginateMedia:"MediaConnection",
		validateCreateMedia:"_ValidationResult",
		validateUpdateMedia:"_ValidationResult",
		getIconListBlockItem:"IconListBlockItem",
		listIconListBlockItem:"IconListBlockItem",
		paginateIconListBlockItem:"IconListBlockItemConnection",
		validateCreateIconListBlockItem:"_ValidationResult",
		validateUpdateIconListBlockItem:"_ValidationResult",
		getIconListBlock:"IconListBlock",
		listIconListBlock:"IconListBlock",
		paginateIconListBlock:"IconListBlockConnection",
		validateCreateIconListBlock:"_ValidationResult",
		validateUpdateIconListBlock:"_ValidationResult",
		getFeatureListItem:"FeatureListItem",
		listFeatureListItem:"FeatureListItem",
		paginateFeatureListItem:"FeatureListItemConnection",
		validateCreateFeatureListItem:"_ValidationResult",
		validateUpdateFeatureListItem:"_ValidationResult",
		getFeatureList:"FeatureList",
		listFeatureList:"FeatureList",
		paginateFeatureList:"FeatureListConnection",
		validateCreateFeatureList:"_ValidationResult",
		validateUpdateFeatureList:"_ValidationResult",
		getFaqItem:"FaqItem",
		listFaqItem:"FaqItem",
		paginateFaqItem:"FaqItemConnection",
		validateCreateFaqItem:"_ValidationResult",
		validateUpdateFaqItem:"_ValidationResult",
		getFaq:"Faq",
		listFaq:"Faq",
		paginateFaq:"FaqConnection",
		validateCreateFaq:"_ValidationResult",
		validateUpdateFaq:"_ValidationResult",
		getContentBlock:"ContentBlock",
		listContentBlock:"ContentBlock",
		paginateContentBlock:"ContentBlockConnection",
		validateCreateContentBlock:"_ValidationResult",
		validateUpdateContentBlock:"_ValidationResult",
		getContent:"Content",
		listContent:"Content",
		paginateContent:"ContentConnection",
		validateCreateContent:"_ValidationResult",
		validateUpdateContent:"_ValidationResult",
		getLink:"Link",
		listLink:"Link",
		paginateLink:"LinkConnection",
		validateCreateLink:"_ValidationResult",
		validateUpdateLink:"_ValidationResult",
		getReviewLocale:"ReviewLocale",
		listReviewLocale:"ReviewLocale",
		paginateReviewLocale:"ReviewLocaleConnection",
		validateCreateReviewLocale:"_ValidationResult",
		validateUpdateReviewLocale:"_ValidationResult",
		getReview:"Review",
		listReview:"Review",
		paginateReview:"ReviewConnection",
		validateCreateReview:"_ValidationResult",
		validateUpdateReview:"_ValidationResult",
		getGalleryItem:"GalleryItem",
		listGalleryItem:"GalleryItem",
		paginateGalleryItem:"GalleryItemConnection",
		validateCreateGalleryItem:"_ValidationResult",
		validateUpdateGalleryItem:"_ValidationResult",
		getGallery:"Gallery",
		listGallery:"Gallery",
		paginateGallery:"GalleryConnection",
		validateCreateGallery:"_ValidationResult",
		validateUpdateGallery:"_ValidationResult",
		getProductVariantStore:"ProductVariantStore",
		listProductVariantStore:"ProductVariantStore",
		paginateProductVariantStore:"ProductVariantStoreConnection",
		validateCreateProductVariantStore:"_ValidationResult",
		validateUpdateProductVariantStore:"_ValidationResult",
		getProductStore:"ProductStore",
		listProductStore:"ProductStore",
		paginateProductStore:"ProductStoreConnection",
		validateCreateProductStore:"_ValidationResult",
		validateUpdateProductStore:"_ValidationResult",
		getIconListItem:"IconListItem",
		listIconListItem:"IconListItem",
		paginateIconListItem:"IconListItemConnection",
		validateCreateIconListItem:"_ValidationResult",
		validateUpdateIconListItem:"_ValidationResult",
		getIconList:"IconList",
		listIconList:"IconList",
		paginateIconList:"IconListConnection",
		validateCreateIconList:"_ValidationResult",
		validateUpdateIconList:"_ValidationResult",
		getIconGroupListItem:"IconGroupListItem",
		listIconGroupListItem:"IconGroupListItem",
		paginateIconGroupListItem:"IconGroupListItemConnection",
		validateCreateIconGroupListItem:"_ValidationResult",
		validateUpdateIconGroupListItem:"_ValidationResult",
		getIconGroupList:"IconGroupList",
		listIconGroupList:"IconGroupList",
		paginateIconGroupList:"IconGroupListConnection",
		validateCreateIconGroupList:"_ValidationResult",
		validateUpdateIconGroupList:"_ValidationResult",
		getProductVariantLocale:"ProductVariantLocale",
		listProductVariantLocale:"ProductVariantLocale",
		paginateProductVariantLocale:"ProductVariantLocaleConnection",
		validateCreateProductVariantLocale:"_ValidationResult",
		validateUpdateProductVariantLocale:"_ValidationResult",
		getProductVariant:"ProductVariant",
		listProductVariant:"ProductVariant",
		paginateProductVariant:"ProductVariantConnection",
		validateCreateProductVariant:"_ValidationResult",
		validateUpdateProductVariant:"_ValidationResult",
		getProduct:"Product",
		listProduct:"Product",
		paginateProduct:"ProductConnection",
		validateCreateProduct:"_ValidationResult",
		validateUpdateProduct:"_ValidationResult",
		getImageLocale:"ImageLocale",
		listImageLocale:"ImageLocale",
		paginateImageLocale:"ImageLocaleConnection",
		validateCreateImageLocale:"_ValidationResult",
		validateUpdateImageLocale:"_ValidationResult",
		getStore:"Store",
		listStore:"Store",
		paginateStore:"StoreConnection",
		validateCreateStore:"_ValidationResult",
		validateUpdateStore:"_ValidationResult",
		getAdminNote:"AdminNote",
		listAdminNote:"AdminNote",
		paginateAdminNote:"AdminNoteConnection",
		validateCreateAdminNote:"_ValidationResult",
		validateUpdateAdminNote:"_ValidationResult",
		getPageLocale:"PageLocale",
		listPageLocale:"PageLocale",
		paginatePageLocale:"PageLocaleConnection",
		validateCreatePageLocale:"_ValidationResult",
		validateUpdatePageLocale:"_ValidationResult",
		getPage:"Page",
		listPage:"Page",
		paginatePage:"PageConnection",
		validateCreatePage:"_ValidationResult",
		validateUpdatePage:"_ValidationResult",
		getImageXImageList:"ImageXImageList",
		listImageXImageList:"ImageXImageList",
		paginateImageXImageList:"ImageXImageListConnection",
		validateCreateImageXImageList:"_ValidationResult",
		validateUpdateImageXImageList:"_ValidationResult",
		getIconGroupLocale:"IconGroupLocale",
		listIconGroupLocale:"IconGroupLocale",
		paginateIconGroupLocale:"IconGroupLocaleConnection",
		validateCreateIconGroupLocale:"_ValidationResult",
		validateUpdateIconGroupLocale:"_ValidationResult",
		getIconLocale:"IconLocale",
		listIconLocale:"IconLocale",
		paginateIconLocale:"IconLocaleConnection",
		validateCreateIconLocale:"_ValidationResult",
		validateUpdateIconLocale:"_ValidationResult",
		getIconGroup:"IconGroup",
		listIconGroup:"IconGroup",
		paginateIconGroup:"IconGroupConnection",
		validateCreateIconGroup:"_ValidationResult",
		validateUpdateIconGroup:"_ValidationResult",
		getIcon:"Icon",
		listIcon:"Icon",
		paginateIcon:"IconConnection",
		validateCreateIcon:"_ValidationResult",
		validateUpdateIcon:"_ValidationResult",
		getLocale:"Locale",
		listLocale:"Locale",
		paginateLocale:"LocaleConnection",
		validateCreateLocale:"_ValidationResult",
		validateUpdateLocale:"_ValidationResult",
		getSeoLocale:"SeoLocale",
		listSeoLocale:"SeoLocale",
		paginateSeoLocale:"SeoLocaleConnection",
		validateCreateSeoLocale:"_ValidationResult",
		validateUpdateSeoLocale:"_ValidationResult",
		getSeo:"Seo",
		listSeo:"Seo",
		paginateSeo:"SeoConnection",
		validateCreateSeo:"_ValidationResult",
		validateUpdateSeo:"_ValidationResult",
		getLinkable:"Linkable",
		listLinkable:"Linkable",
		paginateLinkable:"LinkableConnection",
		validateCreateLinkable:"_ValidationResult",
		validateUpdateLinkable:"_ValidationResult",
		getImageList:"ImageList",
		listImageList:"ImageList",
		paginateImageList:"ImageListConnection",
		validateCreateImageList:"_ValidationResult",
		validateUpdateImageList:"_ValidationResult",
		getImage:"Image",
		listImage:"Image",
		paginateImage:"ImageConnection",
		validateCreateImage:"_ValidationResult",
		validateUpdateImage:"_ValidationResult",
		transaction:"QueryTransaction",
		_info:"Info",
		schema:"_Schema",
		s3DummyQuery:"String"
	},
	QueryTransaction:{
		getAbandonedCheckoutUnsubscription:"AbandonedCheckoutUnsubscription",
		listAbandonedCheckoutUnsubscription:"AbandonedCheckoutUnsubscription",
		paginateAbandonedCheckoutUnsubscription:"AbandonedCheckoutUnsubscriptionConnection",
		validateCreateAbandonedCheckoutUnsubscription:"_ValidationResult",
		validateUpdateAbandonedCheckoutUnsubscription:"_ValidationResult",
		getAbandonedCheckoutNotification:"AbandonedCheckoutNotification",
		listAbandonedCheckoutNotification:"AbandonedCheckoutNotification",
		paginateAbandonedCheckoutNotification:"AbandonedCheckoutNotificationConnection",
		validateCreateAbandonedCheckoutNotification:"_ValidationResult",
		validateUpdateAbandonedCheckoutNotification:"_ValidationResult",
		getShopifyLineItem:"ShopifyLineItem",
		listShopifyLineItem:"ShopifyLineItem",
		paginateShopifyLineItem:"ShopifyLineItemConnection",
		validateCreateShopifyLineItem:"_ValidationResult",
		validateUpdateShopifyLineItem:"_ValidationResult",
		getShopifyOrder:"ShopifyOrder",
		listShopifyOrder:"ShopifyOrder",
		paginateShopifyOrder:"ShopifyOrderConnection",
		validateCreateShopifyOrder:"_ValidationResult",
		validateUpdateShopifyOrder:"_ValidationResult",
		getTranslationValue:"TranslationValue",
		listTranslationValue:"TranslationValue",
		paginateTranslationValue:"TranslationValueConnection",
		validateCreateTranslationValue:"_ValidationResult",
		validateUpdateTranslationValue:"_ValidationResult",
		getTranslationKey:"TranslationKey",
		listTranslationKey:"TranslationKey",
		paginateTranslationKey:"TranslationKeyConnection",
		validateCreateTranslationKey:"_ValidationResult",
		validateUpdateTranslationKey:"_ValidationResult",
		getTranslationCatalogue:"TranslationCatalogue",
		listTranslationCatalogue:"TranslationCatalogue",
		paginateTranslationCatalogue:"TranslationCatalogueConnection",
		validateCreateTranslationCatalogue:"_ValidationResult",
		validateUpdateTranslationCatalogue:"_ValidationResult",
		getTranslationDomain:"TranslationDomain",
		listTranslationDomain:"TranslationDomain",
		paginateTranslationDomain:"TranslationDomainConnection",
		validateCreateTranslationDomain:"_ValidationResult",
		validateUpdateTranslationDomain:"_ValidationResult",
		getProductLocale:"ProductLocale",
		listProductLocale:"ProductLocale",
		paginateProductLocale:"ProductLocaleConnection",
		validateCreateProductLocale:"_ValidationResult",
		validateUpdateProductLocale:"_ValidationResult",
		getMedia:"Media",
		listMedia:"Media",
		paginateMedia:"MediaConnection",
		validateCreateMedia:"_ValidationResult",
		validateUpdateMedia:"_ValidationResult",
		getIconListBlockItem:"IconListBlockItem",
		listIconListBlockItem:"IconListBlockItem",
		paginateIconListBlockItem:"IconListBlockItemConnection",
		validateCreateIconListBlockItem:"_ValidationResult",
		validateUpdateIconListBlockItem:"_ValidationResult",
		getIconListBlock:"IconListBlock",
		listIconListBlock:"IconListBlock",
		paginateIconListBlock:"IconListBlockConnection",
		validateCreateIconListBlock:"_ValidationResult",
		validateUpdateIconListBlock:"_ValidationResult",
		getFeatureListItem:"FeatureListItem",
		listFeatureListItem:"FeatureListItem",
		paginateFeatureListItem:"FeatureListItemConnection",
		validateCreateFeatureListItem:"_ValidationResult",
		validateUpdateFeatureListItem:"_ValidationResult",
		getFeatureList:"FeatureList",
		listFeatureList:"FeatureList",
		paginateFeatureList:"FeatureListConnection",
		validateCreateFeatureList:"_ValidationResult",
		validateUpdateFeatureList:"_ValidationResult",
		getFaqItem:"FaqItem",
		listFaqItem:"FaqItem",
		paginateFaqItem:"FaqItemConnection",
		validateCreateFaqItem:"_ValidationResult",
		validateUpdateFaqItem:"_ValidationResult",
		getFaq:"Faq",
		listFaq:"Faq",
		paginateFaq:"FaqConnection",
		validateCreateFaq:"_ValidationResult",
		validateUpdateFaq:"_ValidationResult",
		getContentBlock:"ContentBlock",
		listContentBlock:"ContentBlock",
		paginateContentBlock:"ContentBlockConnection",
		validateCreateContentBlock:"_ValidationResult",
		validateUpdateContentBlock:"_ValidationResult",
		getContent:"Content",
		listContent:"Content",
		paginateContent:"ContentConnection",
		validateCreateContent:"_ValidationResult",
		validateUpdateContent:"_ValidationResult",
		getLink:"Link",
		listLink:"Link",
		paginateLink:"LinkConnection",
		validateCreateLink:"_ValidationResult",
		validateUpdateLink:"_ValidationResult",
		getReviewLocale:"ReviewLocale",
		listReviewLocale:"ReviewLocale",
		paginateReviewLocale:"ReviewLocaleConnection",
		validateCreateReviewLocale:"_ValidationResult",
		validateUpdateReviewLocale:"_ValidationResult",
		getReview:"Review",
		listReview:"Review",
		paginateReview:"ReviewConnection",
		validateCreateReview:"_ValidationResult",
		validateUpdateReview:"_ValidationResult",
		getGalleryItem:"GalleryItem",
		listGalleryItem:"GalleryItem",
		paginateGalleryItem:"GalleryItemConnection",
		validateCreateGalleryItem:"_ValidationResult",
		validateUpdateGalleryItem:"_ValidationResult",
		getGallery:"Gallery",
		listGallery:"Gallery",
		paginateGallery:"GalleryConnection",
		validateCreateGallery:"_ValidationResult",
		validateUpdateGallery:"_ValidationResult",
		getProductVariantStore:"ProductVariantStore",
		listProductVariantStore:"ProductVariantStore",
		paginateProductVariantStore:"ProductVariantStoreConnection",
		validateCreateProductVariantStore:"_ValidationResult",
		validateUpdateProductVariantStore:"_ValidationResult",
		getProductStore:"ProductStore",
		listProductStore:"ProductStore",
		paginateProductStore:"ProductStoreConnection",
		validateCreateProductStore:"_ValidationResult",
		validateUpdateProductStore:"_ValidationResult",
		getIconListItem:"IconListItem",
		listIconListItem:"IconListItem",
		paginateIconListItem:"IconListItemConnection",
		validateCreateIconListItem:"_ValidationResult",
		validateUpdateIconListItem:"_ValidationResult",
		getIconList:"IconList",
		listIconList:"IconList",
		paginateIconList:"IconListConnection",
		validateCreateIconList:"_ValidationResult",
		validateUpdateIconList:"_ValidationResult",
		getIconGroupListItem:"IconGroupListItem",
		listIconGroupListItem:"IconGroupListItem",
		paginateIconGroupListItem:"IconGroupListItemConnection",
		validateCreateIconGroupListItem:"_ValidationResult",
		validateUpdateIconGroupListItem:"_ValidationResult",
		getIconGroupList:"IconGroupList",
		listIconGroupList:"IconGroupList",
		paginateIconGroupList:"IconGroupListConnection",
		validateCreateIconGroupList:"_ValidationResult",
		validateUpdateIconGroupList:"_ValidationResult",
		getProductVariantLocale:"ProductVariantLocale",
		listProductVariantLocale:"ProductVariantLocale",
		paginateProductVariantLocale:"ProductVariantLocaleConnection",
		validateCreateProductVariantLocale:"_ValidationResult",
		validateUpdateProductVariantLocale:"_ValidationResult",
		getProductVariant:"ProductVariant",
		listProductVariant:"ProductVariant",
		paginateProductVariant:"ProductVariantConnection",
		validateCreateProductVariant:"_ValidationResult",
		validateUpdateProductVariant:"_ValidationResult",
		getProduct:"Product",
		listProduct:"Product",
		paginateProduct:"ProductConnection",
		validateCreateProduct:"_ValidationResult",
		validateUpdateProduct:"_ValidationResult",
		getImageLocale:"ImageLocale",
		listImageLocale:"ImageLocale",
		paginateImageLocale:"ImageLocaleConnection",
		validateCreateImageLocale:"_ValidationResult",
		validateUpdateImageLocale:"_ValidationResult",
		getStore:"Store",
		listStore:"Store",
		paginateStore:"StoreConnection",
		validateCreateStore:"_ValidationResult",
		validateUpdateStore:"_ValidationResult",
		getAdminNote:"AdminNote",
		listAdminNote:"AdminNote",
		paginateAdminNote:"AdminNoteConnection",
		validateCreateAdminNote:"_ValidationResult",
		validateUpdateAdminNote:"_ValidationResult",
		getPageLocale:"PageLocale",
		listPageLocale:"PageLocale",
		paginatePageLocale:"PageLocaleConnection",
		validateCreatePageLocale:"_ValidationResult",
		validateUpdatePageLocale:"_ValidationResult",
		getPage:"Page",
		listPage:"Page",
		paginatePage:"PageConnection",
		validateCreatePage:"_ValidationResult",
		validateUpdatePage:"_ValidationResult",
		getImageXImageList:"ImageXImageList",
		listImageXImageList:"ImageXImageList",
		paginateImageXImageList:"ImageXImageListConnection",
		validateCreateImageXImageList:"_ValidationResult",
		validateUpdateImageXImageList:"_ValidationResult",
		getIconGroupLocale:"IconGroupLocale",
		listIconGroupLocale:"IconGroupLocale",
		paginateIconGroupLocale:"IconGroupLocaleConnection",
		validateCreateIconGroupLocale:"_ValidationResult",
		validateUpdateIconGroupLocale:"_ValidationResult",
		getIconLocale:"IconLocale",
		listIconLocale:"IconLocale",
		paginateIconLocale:"IconLocaleConnection",
		validateCreateIconLocale:"_ValidationResult",
		validateUpdateIconLocale:"_ValidationResult",
		getIconGroup:"IconGroup",
		listIconGroup:"IconGroup",
		paginateIconGroup:"IconGroupConnection",
		validateCreateIconGroup:"_ValidationResult",
		validateUpdateIconGroup:"_ValidationResult",
		getIcon:"Icon",
		listIcon:"Icon",
		paginateIcon:"IconConnection",
		validateCreateIcon:"_ValidationResult",
		validateUpdateIcon:"_ValidationResult",
		getLocale:"Locale",
		listLocale:"Locale",
		paginateLocale:"LocaleConnection",
		validateCreateLocale:"_ValidationResult",
		validateUpdateLocale:"_ValidationResult",
		getSeoLocale:"SeoLocale",
		listSeoLocale:"SeoLocale",
		paginateSeoLocale:"SeoLocaleConnection",
		validateCreateSeoLocale:"_ValidationResult",
		validateUpdateSeoLocale:"_ValidationResult",
		getSeo:"Seo",
		listSeo:"Seo",
		paginateSeo:"SeoConnection",
		validateCreateSeo:"_ValidationResult",
		validateUpdateSeo:"_ValidationResult",
		getLinkable:"Linkable",
		listLinkable:"Linkable",
		paginateLinkable:"LinkableConnection",
		validateCreateLinkable:"_ValidationResult",
		validateUpdateLinkable:"_ValidationResult",
		getImageList:"ImageList",
		listImageList:"ImageList",
		paginateImageList:"ImageListConnection",
		validateCreateImageList:"_ValidationResult",
		validateUpdateImageList:"_ValidationResult",
		getImage:"Image",
		listImage:"Image",
		paginateImage:"ImageConnection",
		validateCreateImage:"_ValidationResult",
		validateUpdateImage:"_ValidationResult"
	},
	Review:{
		_meta:"ReviewMeta",
		id:"UUID",
		username:"String",
		createdAt:"DateTime",
		locales:"ReviewLocale",
		localesByLocale:"ReviewLocale"
	},
	ReviewConnection:{
		pageInfo:"PageInfo",
		edges:"ReviewEdge"
	},
	ReviewCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Review",
		validation:"_ValidationResult"
	},
	ReviewDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Review"
	},
	ReviewEdge:{
		node:"Review"
	},
	ReviewLocale:{
		_meta:"ReviewLocaleMeta",
		id:"UUID",
		content:"String",
		image:"Image",
		locale:"Locale",
		review:"Review"
	},
	ReviewLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"ReviewLocaleEdge"
	},
	ReviewLocaleCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ReviewLocale",
		validation:"_ValidationResult"
	},
	ReviewLocaleDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ReviewLocale"
	},
	ReviewLocaleEdge:{
		node:"ReviewLocale"
	},
	ReviewLocaleMeta:{
		id:"FieldMeta",
		content:"FieldMeta",
		image:"FieldMeta",
		locale:"FieldMeta",
		review:"FieldMeta"
	},
	ReviewLocaleUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ReviewLocale",
		validation:"_ValidationResult"
	},
	ReviewMeta:{
		id:"FieldMeta",
		username:"FieldMeta",
		createdAt:"FieldMeta",
		locales:"FieldMeta"
	},
	ReviewUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Review",
		validation:"_ValidationResult"
	},
	S3Header:{
		key:"String",
		value:"String"
	},
	S3SignedRead:{
		url:"String",
		headers:"S3Header",
		method:"String",
		objectKey:"String",
		bucket:"String"
	},
	S3SignedUpload:{
		url:"String",
		headers:"S3Header",
		method:"String",
		objectKey:"String",
		bucket:"String",
		publicUrl:"String"
	},
	Seo:{
		_meta:"SeoMeta",
		id:"UUID",
		ogImage:"Image",
		locales:"SeoLocale",
		noindex:"Boolean",
		localesByLocale:"SeoLocale"
	},
	SeoConnection:{
		pageInfo:"PageInfo",
		edges:"SeoEdge"
	},
	SeoCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Seo",
		validation:"_ValidationResult"
	},
	SeoDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Seo"
	},
	SeoEdge:{
		node:"Seo"
	},
	SeoLocale:{
		_meta:"SeoLocaleMeta",
		id:"UUID",
		title:"String",
		description:"String",
		ogTitle:"String",
		ogDescription:"String",
		root:"Seo",
		locale:"Locale"
	},
	SeoLocaleConnection:{
		pageInfo:"PageInfo",
		edges:"SeoLocaleEdge"
	},
	SeoLocaleCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"SeoLocale",
		validation:"_ValidationResult"
	},
	SeoLocaleDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"SeoLocale"
	},
	SeoLocaleEdge:{
		node:"SeoLocale"
	},
	SeoLocaleMeta:{
		id:"FieldMeta",
		title:"FieldMeta",
		description:"FieldMeta",
		ogTitle:"FieldMeta",
		ogDescription:"FieldMeta",
		root:"FieldMeta",
		locale:"FieldMeta"
	},
	SeoLocaleUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"SeoLocale",
		validation:"_ValidationResult"
	},
	SeoMeta:{
		id:"FieldMeta",
		ogImage:"FieldMeta",
		locales:"FieldMeta",
		noindex:"FieldMeta"
	},
	SeoUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Seo",
		validation:"_ValidationResult"
	},
	ShopifyLineItem:{
		_meta:"ShopifyLineItemMeta",
		id:"UUID",
		store:"String",
		shopifyOrderId:"String",
		shopifyLineItemId:"String",
		customAttributes:"String"
	},
	ShopifyLineItemConnection:{
		pageInfo:"PageInfo",
		edges:"ShopifyLineItemEdge"
	},
	ShopifyLineItemCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ShopifyLineItem",
		validation:"_ValidationResult"
	},
	ShopifyLineItemDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ShopifyLineItem"
	},
	ShopifyLineItemEdge:{
		node:"ShopifyLineItem"
	},
	ShopifyLineItemMeta:{
		id:"FieldMeta",
		store:"FieldMeta",
		shopifyOrderId:"FieldMeta",
		shopifyLineItemId:"FieldMeta",
		customAttributes:"FieldMeta"
	},
	ShopifyLineItemUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ShopifyLineItem",
		validation:"_ValidationResult"
	},
	ShopifyOrder:{
		_meta:"ShopifyOrderMeta",
		id:"UUID",
		createdAt:"DateTime",
		fulfilledAt:"DateTime",
		store:"String",
		shopifyId:"String",
		shippingType:"String",
		trackingId:"String",
		rawData:"String",
		trackingUrl:"String",
		shippingMeta:"String",
		enableResendAt:"DateTime"
	},
	ShopifyOrderConnection:{
		pageInfo:"PageInfo",
		edges:"ShopifyOrderEdge"
	},
	ShopifyOrderCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ShopifyOrder",
		validation:"_ValidationResult"
	},
	ShopifyOrderDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ShopifyOrder"
	},
	ShopifyOrderEdge:{
		node:"ShopifyOrder"
	},
	ShopifyOrderMeta:{
		id:"FieldMeta",
		createdAt:"FieldMeta",
		fulfilledAt:"FieldMeta",
		store:"FieldMeta",
		shopifyId:"FieldMeta",
		shippingType:"FieldMeta",
		trackingId:"FieldMeta",
		rawData:"FieldMeta",
		trackingUrl:"FieldMeta",
		shippingMeta:"FieldMeta",
		enableResendAt:"FieldMeta"
	},
	ShopifyOrderUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"ShopifyOrder",
		validation:"_ValidationResult"
	},
	Store:{
		_meta:"StoreMeta",
		id:"UUID",
		code:"String",
		shopifyDomain:"String",
		products:"ProductStore",
		productVariants:"ProductVariantStore",
		productsByRoot:"ProductStore",
		productsByShopifyId:"ProductStore",
		productVariantsByRoot:"ProductVariantStore",
		productVariantsByShopifyId:"ProductVariantStore",
		productVariantsByStorefrontId:"ProductVariantStore"
	},
	StoreConnection:{
		pageInfo:"PageInfo",
		edges:"StoreEdge"
	},
	StoreCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Store",
		validation:"_ValidationResult"
	},
	StoreDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Store"
	},
	StoreEdge:{
		node:"Store"
	},
	StoreMeta:{
		id:"FieldMeta",
		code:"FieldMeta",
		shopifyDomain:"FieldMeta",
		products:"FieldMeta",
		productVariants:"FieldMeta"
	},
	StoreUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"Store",
		validation:"_ValidationResult"
	},
	TranslationCatalogue:{
		_meta:"TranslationCatalogueMeta",
		id:"UUID",
		identifier:"String",
		name:"String",
		domain:"TranslationDomain",
		fallback:"TranslationCatalogue",
		values:"TranslationValue",
		valuesByKey:"TranslationValue"
	},
	TranslationCatalogueConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationCatalogueEdge"
	},
	TranslationCatalogueCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"TranslationCatalogue",
		validation:"_ValidationResult"
	},
	TranslationCatalogueDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"TranslationCatalogue"
	},
	TranslationCatalogueEdge:{
		node:"TranslationCatalogue"
	},
	TranslationCatalogueMeta:{
		id:"FieldMeta",
		identifier:"FieldMeta",
		name:"FieldMeta",
		domain:"FieldMeta",
		fallback:"FieldMeta",
		values:"FieldMeta"
	},
	TranslationCatalogueUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"TranslationCatalogue",
		validation:"_ValidationResult"
	},
	TranslationDomain:{
		_meta:"TranslationDomainMeta",
		id:"UUID",
		identifier:"String",
		name:"String",
		catalogues:"TranslationCatalogue",
		keys:"TranslationKey",
		cataloguesByIdentifier:"TranslationCatalogue",
		cataloguesByValues:"TranslationCatalogue",
		keysByIdentifier:"TranslationKey",
		keysByValues:"TranslationKey"
	},
	TranslationDomainConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationDomainEdge"
	},
	TranslationDomainCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"TranslationDomain",
		validation:"_ValidationResult"
	},
	TranslationDomainDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"TranslationDomain"
	},
	TranslationDomainEdge:{
		node:"TranslationDomain"
	},
	TranslationDomainMeta:{
		id:"FieldMeta",
		identifier:"FieldMeta",
		name:"FieldMeta",
		catalogues:"FieldMeta",
		keys:"FieldMeta"
	},
	TranslationDomainUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"TranslationDomain",
		validation:"_ValidationResult"
	},
	TranslationKey:{
		_meta:"TranslationKeyMeta",
		id:"UUID",
		identifier:"String",
		contentType:"TranslationContentType",
		note:"String",
		domain:"TranslationDomain",
		values:"TranslationValue",
		valuesByCatalogue:"TranslationValue"
	},
	TranslationKeyConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationKeyEdge"
	},
	TranslationKeyCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"TranslationKey",
		validation:"_ValidationResult"
	},
	TranslationKeyDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"TranslationKey"
	},
	TranslationKeyEdge:{
		node:"TranslationKey"
	},
	TranslationKeyMeta:{
		id:"FieldMeta",
		identifier:"FieldMeta",
		contentType:"FieldMeta",
		note:"FieldMeta",
		domain:"FieldMeta",
		values:"FieldMeta"
	},
	TranslationKeyUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"TranslationKey",
		validation:"_ValidationResult"
	},
	TranslationValue:{
		_meta:"TranslationValueMeta",
		id:"UUID",
		value:"String",
		catalogue:"TranslationCatalogue",
		key:"TranslationKey"
	},
	TranslationValueConnection:{
		pageInfo:"PageInfo",
		edges:"TranslationValueEdge"
	},
	TranslationValueCreateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"TranslationValue",
		validation:"_ValidationResult"
	},
	TranslationValueDeleteResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"TranslationValue"
	},
	TranslationValueEdge:{
		node:"TranslationValue"
	},
	TranslationValueMeta:{
		id:"FieldMeta",
		value:"FieldMeta",
		catalogue:"FieldMeta",
		key:"FieldMeta"
	},
	TranslationValueUpdateResult:{
		ok:"Boolean",
		errorMessage:"String",
		errors:"_MutationError",
		node:"TranslationValue",
		validation:"_ValidationResult"
	}
}

export class GraphQLError extends Error {
    constructor(public response: GraphQLResponse) {
      super("");
      console.error(response);
    }
    toString() {
      return "GraphQL Response Error";
    }
  }



export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<
  UnwrapPromise<ReturnType<T>>
>;
export type ZeusHook<
  T extends (
    ...args: any[]
  ) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>
> = ZeusState<ReturnType<T>[N]>;

type Func<P extends any[], R> = (...args: P) => R;
type AnyFunc = Func<any, any>;

type WithTypeNameValue<T> = T & {
  __typename?: true;
};

type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};

type NotUndefined<T> = T extends undefined ? never : T;

export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type ArgsType<F extends AnyFunc> = F extends Func<infer P, any> ? P : never;

interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}

export type ValuesOf<T> = T[keyof T];

export type MapResolve<SRC, DST> = SRC extends {
    __interface: infer INTERFACE;
    __resolve: Record<string, { __typename?: string }> & infer IMPLEMENTORS;
  }
  ?
  ValuesOf<{
    [k in (keyof SRC['__resolve'] & keyof DST)]: ({
      [rk in (keyof SRC['__resolve'][k] & keyof DST[k])]: LastMapTypeSRCResolver<SRC['__resolve'][k][rk], DST[k][rk]>
    } & {
      __typename: SRC['__resolve'][k]['__typename']
    })
  }>
  :
  never;

export type MapInterface<SRC, DST> = SRC extends {
    __interface: infer INTERFACE;
    __resolve: Record<string, { __typename?: string }> & infer IMPLEMENTORS;
  }
  ?
  (MapResolve<SRC, DST> extends never ? {} : MapResolve<SRC, DST>) & {
  [k in (keyof SRC['__interface'] & keyof DST)]: LastMapTypeSRCResolver<SRC['__interface'][k], DST[k]>
} : never;

export type ValueToUnion<T> = T extends {
  __typename: infer R;
}
  ? {
      [P in keyof Omit<T, '__typename'>]: T[P] & {
        __typename: R;
      };
    }
  : T;

export type ObjectToUnion<T> = {
  [P in keyof T]: T[P];
}[keyof T];

type Anify<T> = { [P in keyof T]?: any };


type LastMapTypeSRCResolver<SRC, DST> = SRC extends undefined
  ? undefined
  : SRC extends Array<infer AR>
  ? LastMapTypeSRCResolver<AR, DST>[]
  : SRC extends { __interface: any; __resolve: any }
  ? MapInterface<SRC, DST>
  : SRC extends { __union: any; __resolve: infer RESOLVE }
  ? ObjectToUnion<MapType<RESOLVE, ValueToUnion<DST>>>
  : DST extends boolean
  ? SRC
  : MapType<SRC, DST>;

export type MapType<SRC extends Anify<DST>, DST> = DST extends boolean
  ? SRC
  : DST extends {
      __alias: any;
    }
  ? {
      [A in keyof DST["__alias"]]: Required<SRC> extends Anify<
        DST["__alias"][A]
      >
        ? MapType<Required<SRC>, DST["__alias"][A]>
        : never;
    } &
      {
        [Key in keyof Omit<DST, "__alias">]: DST[Key] extends [
          any,
          infer PAYLOAD
        ]
          ? LastMapTypeSRCResolver<SRC[Key], PAYLOAD>
          : LastMapTypeSRCResolver<SRC[Key], DST[Key]>;
      }
  : {
      [Key in keyof DST]: DST[Key] extends [any, infer PAYLOAD]
        ? LastMapTypeSRCResolver<SRC[Key], PAYLOAD>
        : LastMapTypeSRCResolver<SRC[Key], DST[Key]>;
    };

type OperationToGraphQL<V, T> = <Z extends V>(o: Z | V, variables?: Record<string, any>) => Promise<MapType<T, Z>>;

type CastToGraphQL<V, T> = (
  resultOfYourQuery: any
) => <Z extends V>(o: Z | V) => MapType<T, Z>;

type fetchOptions = ArgsType<typeof fetch>;

export type SelectionFunction<V> = <T>(t: T | V) => T;
type FetchFunction = (query: string, variables?: Record<string, any>) => Promise<any>;



export const ZeusSelect = <T>() => ((t: any) => t) as SelectionFunction<T>;

export const ScalarResolver = (scalar: string, value: any) => {
  switch (scalar) {
    case 'String':
      return  `${JSON.stringify(value)}`;
    case 'Int':
      return `${value}`;
    case 'Float':
      return `${value}`;
    case 'Boolean':
      return `${value}`;
    case 'ID':
      return `"${value}"`;
    case 'enum':
      return `${value}`;
    case 'scalar':
      return `${value}`;
    default:
      return false;
  }
};


export const TypesPropsResolver = ({
    value,
    type,
    name,
    key,
    blockArrays
}: {
    value: any;
    type: string;
    name: string;
    key?: string;
    blockArrays?: boolean;
}): string => {
    if (value === null) {
        return `null`;
    }
    let resolvedValue = AllTypesProps[type][name];
    if (key) {
        resolvedValue = resolvedValue[key];
    }
    if (!resolvedValue) {
        throw new Error(`Cannot resolve ${type} ${name}${key ? ` ${key}` : ''}`)
    }
    const typeResolved = resolvedValue.type;
    const isArray = resolvedValue.array;
    const isArrayRequired = resolvedValue.arrayRequired;
    if (typeof value === 'string' && value.startsWith(`ZEUS_VAR$`)) {
        const isRequired = resolvedValue.required ? '!' : '';
        let t = `${typeResolved}`;
        if (isArray) {
          if (isRequired) {
              t = `${t}!`;
          }
          t = `[${t}]`;
          if(isArrayRequired){
            t = `${t}!`;
          }
        }else{
          if (isRequired) {
                t = `${t}!`;
          }
        }
        return `\$${value.split(`ZEUS_VAR$`)[1]}__ZEUS_VAR__${t}`;
    }
    if (isArray && !blockArrays) {
        return `[${value
        .map((v: any) => TypesPropsResolver({ value: v, type, name, key, blockArrays: true }))
        .join(',')}]`;
    }
    const reslovedScalar = ScalarResolver(typeResolved, value);
    if (!reslovedScalar) {
        const resolvedType = AllTypesProps[typeResolved];
        if (typeof resolvedType === 'object') {
        const argsKeys = Object.keys(resolvedType);
        return `{${argsKeys
            .filter((ak) => value[ak] !== undefined)
            .map(
            (ak) => `${ak}:${TypesPropsResolver({ value: value[ak], type: typeResolved, name: ak })}`
            )}}`;
        }
        return ScalarResolver(AllTypesProps[typeResolved], value) as string;
    }
    return reslovedScalar;
};


const isArrayFunction = (
  parent: string[],
  a: any[]
) => {
  const [values, r] = a;
  const [mainKey, key, ...keys] = parent;
  const keyValues = Object.keys(values).filter((k) => typeof values[k] !== 'undefined');

  if (!keys.length) {
      return keyValues.length > 0
        ? `(${keyValues
            .map(
              (v) =>
                `${v}:${TypesPropsResolver({
                  value: values[v],
                  type: mainKey,
                  name: key,
                  key: v
                })}`
            )
            .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
        : traverseToSeekArrays(parent, r);
    }

  const [typeResolverKey] = keys.splice(keys.length - 1, 1);
  let valueToResolve = ReturnTypes[mainKey][key];
  for (const k of keys) {
    valueToResolve = ReturnTypes[valueToResolve][k];
  }

  const argumentString =
    keyValues.length > 0
      ? `(${keyValues
          .map(
            (v) =>
              `${v}:${TypesPropsResolver({
                value: values[v],
                type: valueToResolve,
                name: typeResolverKey,
                key: v
              })}`
          )
          .join(',')})${r ? traverseToSeekArrays(parent, r) : ''}`
      : traverseToSeekArrays(parent, r);
  return argumentString;
};


const resolveKV = (k: string, v: boolean | string | { [x: string]: boolean | string }) =>
  typeof v === 'boolean' ? k : typeof v === 'object' ? `${k}{${objectToTree(v)}}` : `${k}${v}`;


const objectToTree = (o: { [x: string]: boolean | string }): string =>
  `{${Object.keys(o).map((k) => `${resolveKV(k, o[k])}`).join(' ')}}`;


const traverseToSeekArrays = (parent: string[], a?: any): string => {
  if (!a) return '';
  if (Object.keys(a).length === 0) {
    return '';
  }
  let b: Record<string, any> = {};
  if (Array.isArray(a)) {
    return isArrayFunction([...parent], a);
  } else {
    if (typeof a === 'object') {
      Object.keys(a)
        .filter((k) => typeof a[k] !== 'undefined')
        .map((k) => {
        if (k === '__alias') {
          Object.keys(a[k]).map((aliasKey) => {
            const aliasOperations = a[k][aliasKey];
            const aliasOperationName = Object.keys(aliasOperations)[0];
            const aliasOperation = aliasOperations[aliasOperationName];
            b[
              `${aliasOperationName}__alias__${aliasKey}: ${aliasOperationName}`
            ] = traverseToSeekArrays([...parent, aliasOperationName], aliasOperation);
          });
        } else {
          b[k] = traverseToSeekArrays([...parent, k], a[k]);
        }
      });
    } else {
      return '';
    }
  }
  return objectToTree(b);
};  


const buildQuery = (type: string, a?: Record<any, any>) => 
  traverseToSeekArrays([type], a);


const inspectVariables = (query: string) => {
  const regex = /\$\b\w*__ZEUS_VAR__\[?[^!^\]^\s^,^\)^\}]*[!]?[\]]?[!]?/g;
  let result;
  const AllVariables: string[] = [];
  while ((result = regex.exec(query))) {
    if (AllVariables.includes(result[0])) {
      continue;
    }
    AllVariables.push(result[0]);
  }
  if (!AllVariables.length) {
    return query;
  }
  let filteredQuery = query;
  AllVariables.forEach((variable) => {
    while (filteredQuery.includes(variable)) {
      filteredQuery = filteredQuery.replace(variable, variable.split('__ZEUS_VAR__')[0]);
    }
  });
  return `(${AllVariables.map((a) => a.split('__ZEUS_VAR__'))
    .map(([variableName, variableType]) => `${variableName}:${variableType}`)
    .join(', ')})${filteredQuery}`;
};


const queryConstruct = (t: 'query' | 'mutation' | 'subscription', tName: string) => (o: Record<any, any>) =>
  `${t.toLowerCase()}${inspectVariables(buildQuery(tName, o))}`;
  

const fullChainConstruct = (fn: FetchFunction) => (t: 'query' | 'mutation' | 'subscription', tName: string) => (
  o: Record<any, any>,
  variables?: Record<string, any>,
) => fn(queryConstruct(t, tName)(o), variables).then((r:any) => { 
  seekForAliases(r)
  return r
});


const seekForAliases = (response: any) => {
  const traverseAlias = (value: any) => {
    if (Array.isArray(value)) {
      value.forEach(seekForAliases);
    } else {
      if (typeof value === 'object') {
        seekForAliases(value);
      }
    }
  };
  if (typeof response === 'object' && response) {
    const keys = Object.keys(response);
    if (keys.length < 1) {
      return;
    }
    keys.forEach((k) => {
      const value = response[k];
      if (k.indexOf('__alias__') !== -1) {
        const [operation, alias] = k.split('__alias__');
        response[alias] = {
          [operation]: value,
        };
        delete response[k];
      }
      traverseAlias(value);
    });
  }
};


export const $ = (t: TemplateStringsArray): any => `ZEUS_VAR$${t.join('')}`;


const handleFetchResponse = (
  response: Parameters<Extract<Parameters<ReturnType<typeof fetch>['then']>[0], Function>>[0]
): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response.text().then(text => {
        try { reject(JSON.parse(text)); }
        catch (err) { reject(text); }
      }).catch(reject);
    });
  }
  return response.json();
};

const apiFetch = (options: fetchOptions) => (query: string, variables: Record<string, any> = {}) => {
    let fetchFunction = fetch;
    let queryString = query;
    let fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      queryString = encodeURIComponent(query);
      return fetchFunction(`${options[0]}?query=${queryString}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetchFunction(`${options[0]}`, {
      body: JSON.stringify({ query: queryString, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      ...fetchOptions
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };
  


export const Thunder = (fn: FetchFunction) => ({
  query: ((o: any, variables) =>
    fullChainConstruct(fn)('query', 'Query')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Query"],Query>,
mutation: ((o: any, variables) =>
    fullChainConstruct(fn)('mutation', 'Mutation')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Mutation"],Mutation>
});

export const Chain = (...options: fetchOptions) => ({
  query: ((o: any, variables) =>
    fullChainConstruct(apiFetch(options))('query', 'Query')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Query"],Query>,
mutation: ((o: any, variables) =>
    fullChainConstruct(apiFetch(options))('mutation', 'Mutation')(o, variables).then(
      (response: any) => response
    )) as OperationToGraphQL<ValueTypes["Mutation"],Mutation>
});
export const Zeus = {
  query: (o:ValueTypes["Query"]) => queryConstruct('query', 'Query')(o),
mutation: (o:ValueTypes["Mutation"]) => queryConstruct('mutation', 'Mutation')(o)
};
export const Cast = {
  query: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["Query"],
  Query
>,
mutation: ((o: any) => (_: any) => o) as CastToGraphQL<
  ValueTypes["Mutation"],
  Mutation
>
};
export const Selectors = {
  query: ZeusSelect<ValueTypes["Query"]>(),
mutation: ZeusSelect<ValueTypes["Mutation"]>()
};
  