import { useQuery } from 'react-query'
import { checkoutQuery } from '../utils/shopify/checkoutQuery'
import { useCustomStorefrontClient } from '../utils/shopify/customStorefront'
import { useClotino } from './useClotino'
import { useStorefrontCheckoutId } from './useStorefrontCheckout'

export type CustomStorefrontCart = NonNullable<ReturnType<typeof useCustomShopifyCart>['data']>

export function useCustomShopifyCart() {
	const checkoutId = useStorefrontCheckoutId()
	const clotino = useClotino()
	const customStorefront = useCustomStorefrontClient(clotino.store, clotino.locale)
	return useQuery(['cart', 'customStorefrontCart', checkoutId], async () => {
		return !checkoutId || !customStorefront ? null : checkoutQuery(customStorefront, checkoutId)
	})
}
