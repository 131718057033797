import { keyBy } from 'lodash'
import { ProductsInfoSource } from '../pages/[...fallback]'

export function prepareProductsInfo(productsInfo?: ProductsInfoSource) {
	return {
		byHandle: keyBy(productsInfo?.products ?? [], 'shopifyHandle'),
		bySku: keyBy(productsInfo?.variants ?? [], 'shopifySku'),
		repo: productsInfo,
	}
}
